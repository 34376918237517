import { get } from "lodash";
import moment from "moment";
import React, { FunctionComponent } from "react";
import { Icon } from "semantic-ui-react";

import { useFormatMessage } from "src/admin-portal/texts/utils";
import { apiShortDate, formatNumber } from "src/common/utils/utils";
import { TimelineTrancheEvent } from "src/employee-portal/timeline/events-timeline-reducer";
import { DateFormatState } from "src/reducers/date-format-reducer";
import "src/employee-portal/timeline/timeline.less";

interface Props {
  event: TimelineTrancheEvent;
  dateFormat: DateFormatState;
}

const TrancheTimelineEvent: FunctionComponent<Props> = ({
  event,
  dateFormat,
}) => {
  const formatMessage = useFormatMessage();
  const instrumentTypeText = formatMessage({
    id: `instrument.${event.instrumentType}.plural`,
  });

  const originalQuantity = event.tranche.quantity;
  const performanceFactor = get(
    event,
    "tranche.performance_rules[0].last_performance_rule_entry.probability_factor"
  );
  const quantity = performanceFactor
    ? Math.floor(parseFloat(performanceFactor) * originalQuantity)
    : originalQuantity;
  return (
    <div className="timeline-event">
      <div className="timeline-event-title">
        {formatMessage(
          { id: `timelineEvent.tranche.${event.eventType}.title` },
          {
            instrumentTypeText,
            quantity: formatNumber(quantity),
          }
        )}
      </div>
      <div className="timeline-event-meta">
        <Icon name="clock outline" />
        {formatMessage(
          { id: `timelineEvent.tranche.${event.eventType}.meta` },
          {
            eventDate: moment(event.eventTime).format(
              dateFormat.normalDateFormat
            ),
            numberOfDays: moment(event.eventTime).diff(
              moment().format(apiShortDate),
              "days"
            ),
          }
        )}
      </div>
    </div>
  );
};

export default TrancheTimelineEvent;
