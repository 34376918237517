import React, { FunctionComponent } from "react";
import { Button } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { IStringStringMap } from "src/admin-portal/texts/employee-portal-texts-reducer";

interface Props {
  goBack: () => void;
  goForward: () => void;
  contentValues: IStringStringMap;
  videoUrl?: string;
}

const Info: FunctionComponent<Props> = ({
  goBack,
  goForward,
  contentValues,
  videoUrl,
}) => (
  <div>
    <div className="block-m">
      <div className="text-center block-m">
        <h2 className="block-m">
          <Content id="purchase.info.header" />
        </h2>
      </div>

      {videoUrl && (
        <div className="text-center block-m">
          <iframe
            width="640"
            height="360"
            src={videoUrl}
            frameBorder="0"
            allowFullScreen={true}
          />
        </div>
      )}
      <div className="block-m purchase-narrow-width margin-center">
        <Content id="purchase.info.content" values={contentValues} />
      </div>
    </div>
    <div className="page-action-container text-center">
      <Button
        content={<Content id="global.backBtnTxt" />}
        size="big"
        onClick={goBack}
      />
      <Button
        positive={true}
        content={<Content id="global.nextBtnTxt" />}
        icon="right arrow"
        labelPosition="right"
        size="big"
        onClick={goForward}
      />
    </div>
  </div>
);

export default Info;
