import { createAction } from "redux-act";

import { AnnualVolatilityData } from "src/reducers/share-price-reducer";

export const fetchPrimarySharePriceTicker = createAction(
  "FETCH_PRIMARY_SHARE_PRICE_TICKER"
);

export const fetchPrimarySharePriceTickerSucceeded = createAction(
  "FETCH_PRIMARY_SHARE_PRICE_TICKER_SUCCEEDED",
  (payload: Api.V1.SharePriceTicker) => payload
);

export const putPrimarySharePriceTicker = createAction(
  "PUT_PRIMARY_SHARE_PRICE_TICKER",
  (payload: Api.V1.SharePriceTicker) => payload
);

export const putPrimarySharePriceTickerSucceeded = createAction(
  "PUT_PRIMARY_SHARE_PRICE_TICKER_SUCCEEDED",
  (payload: Api.V1.SharePriceTicker) => payload
);

export const postSharePrice = createAction(
  "POST_SHARE_PRICE",
  (payload: Api.V1.SharePrice) => payload
);

export const postSharePriceSucceeded = createAction(
  "POST_SHARE_PRICE_SUCCEEDED",
  (payload: Api.V1.SharePrice) => payload
);

export const fetchSharePrices = createAction(
  "FETCH_SHARE_PRICES",
  (payload: Api.V1.SharePriceQueryParams) => payload
);

export const fetchSharePricesSucceeded = createAction(
  "FETCH_SHARE_PRICES_SUCCEEDED",
  (payload: { sharePrices: Api.V1.SharePrice[]; date: string }) => payload
);

export const deleteSharePrice = createAction(
  "DELETE_SHARE_PRICE",
  (payload: Api.V1.SharePrice["id"]) => payload
);

export const deleteSharePriceSucceeded = createAction(
  "DELETE_SHARE_PRICE_SUCCEEDED",
  (payload: Api.V1.SharePrice["id"]) => payload
);

export const resetSharePrices = createAction("RESET_SHARE_PRICES");

export const fetchAnnualVolatility = createAction(
  "FETCH_ANNUAL_VOLATILITY",
  (payload: Api.V1.AnnualVolatilityQueryParams) => payload
);

export const fetchAnnualVolatilitySucceeded = createAction(
  "FETCH_ANNUAL_VOLATILITY_SUCCEEDED",
  (payload: { dates: string; data: AnnualVolatilityData }) => payload
);

export const fetchAnnualVolatilityFailed = createAction(
  "FETCH_ANNUAL_VOLATILITY_FAILED",
  (payload: { dates: string; message: string }) => payload
);

export const fetchAnnualVolatilityBulk = createAction(
  "FETCH_ANNUAL_VOLATILITY_BULK",
  (payload: Api.V1.AnnualVolatilityQueryParams[]) => payload
);

export const fetchAnnualVolatilityBulkSucceeded = createAction(
  "FETCH_ANNUAL_VOLATILITY_BULK_SUCCEEDED",
  (payload: Array<{ dates: string; data: AnnualVolatilityData[] }>) => payload
);

export const fetchAnnualVolatilityBulkFailed = createAction(
  "FETCH_ANNUAL_VOLATILITY_BULK_FAILED",
  (payload: { message: string }) => payload
);

export const fetchAverageSharePrice = createAction(
  "FETCH_AVERAGE_SHAREPRICE",
  (payload: Api.V1.AverageSharePriceQueryParams) => payload
);

export const fetchAverageSharePriceSucceeded = createAction(
  "FETCH_AVERAGE_SHAREPRICE_SUCCEEDED",
  (payload: {
    averageSharePrices: { averagePeriod: string; averageYtd: string };
    dates: string;
  }) => payload
);
