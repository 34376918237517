import { Moment } from "moment";
import React, { StatelessComponent } from "react";

import { Message } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { CurrencyFormatterProps } from "src/common/components/currency/currency-formatter";
import { TextTerm } from "src/common/utils/text-mappings";
import { formatNumber } from "src/common/utils/utils";

interface OverviewInfoProps {
  totalCostOfExercise: number;
  totalQuantity: number;
  totalSharesEquivalent: number;
  averageCost: number;
  type: Api.V1.ExerciseOrder["exerciseType"];
  instrumentTerm: TextTerm;
  bankAccountNumber?: string;
  bicNumber?: string;
  ibanNumber?: string;
  sharePrice;
  paymentDeadline: Moment;
  payment_address?: string;
  cappedInstrumentsExists?: boolean;
  formatter: CurrencyFormatterProps;
}

const OverviewInfo: StatelessComponent<OverviewInfoProps> = ({
  totalQuantity,
  totalCostOfExercise,
  averageCost,
  type,
  instrumentTerm,
  bankAccountNumber,
  bicNumber,
  ibanNumber,
  sharePrice,
  paymentDeadline,
  payment_address,
  formatter: { formatSharePrice, formatCurrency },
  cappedInstrumentsExists,
  totalSharesEquivalent,
}) => {
  const CappedInstruments = () =>
    cappedInstrumentsExists ? (
      <>
        <span className="block-s" />
        <Message
          warning={true}
          content={<Content id="exercise.confirm.capped.description" />}
        />
      </>
    ) : null;

  if (type === "EXERCISE_AND_HOLD") {
    return (
      <div>
        <p className="text-content text-content-center">
          <Content
            id="exercise.confirm.exerciseandhold.body"
            values={{
              totalQuantity: formatNumber(totalQuantity),
              totalSharesEquivalent: formatNumber(totalSharesEquivalent),
              instrumentTerm: instrumentTerm.plural,
              averageCost: formatSharePrice(averageCost),
              totalCostOfExercise: formatCurrency(totalCostOfExercise, 2),
            }}
          />
          <CappedInstruments />
        </p>
      </div>
    );
  } else if (type === "EXERCISE_AND_SELL") {
    return (
      <div>
        <p className="text-content text-content-center">
          <Content
            id="exercise.confirm.exerciseandsell.body"
            values={{
              totalQuantity: formatNumber(totalQuantity),
              instrumentTerm: instrumentTerm.plural,
              averageCost: formatSharePrice(averageCost),
              totalCostOfExercise: formatCurrency(totalCostOfExercise, 2),
            }}
          />
        </p>
        <p className="text-content text-content-center block-s">
          <Content
            id="exercise.confirm.exerciseandsell.body.2"
            values={{
              sharePrice: formatSharePrice(sharePrice),
            }}
          />
          <CappedInstruments />
        </p>
      </div>
    );
  }

  return (
    <div>
      <p className="text-content text-content-center">
        <Content
          id="exercise.confirm.exerciseandselltocover.body"
          values={{
            totalQuantity: formatNumber(totalQuantity),
            instrumentTerm: instrumentTerm.plural,
            averageCost: formatSharePrice(averageCost),
            totalCostOfExercise: formatCurrency(totalCostOfExercise, 2),
          }}
        />
      </p>
      <p className="text-content text-content-center block-s">
        <Content
          id="exercise.confirm.exerciseandselltocover.body.2"
          values={{
            sharePrice: formatSharePrice(sharePrice),
          }}
        />
        <CappedInstruments />
      </p>
    </div>
  );
};

export default OverviewInfo;
