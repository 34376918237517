import { Moment } from "moment";
import React, { FunctionComponent } from "react";
import { match } from "react-router";

import Content from "src/admin-portal/texts/content";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import { InstrumentsAgreement, Window } from "src/common/data/data";
import { ExerciseNavigationButtons } from "src/employee-portal/exercise/common/exercise-navigation-buttons";
import {
  brokerMinimumAmount,
  OrderExerciseTypeComponent,
} from "src/employee-portal/exercise/exercise-router";
import BrokerTerms from "src/employee-portal/exercise/terms/exercise-broker-terms";

interface Props {
  goBack: () => void;
  goForward: () => void;
  nextPath: string;
  userInstruments: InstrumentsAgreement[];
  window: Window;
  match: match<any>;
  orderExerciseType: Api.V1.ExerciseOrder["exerciseType"];
  employeeName: string;
  companyName: string;
  dateOfAgreement: Moment;
  instrumentsToExercise: number;
  averageStrikePrice: number;
  commission: string;
  bankAccount: string;
  shareDepositoryAccount?: string;
  currencyCode: string;
}

const Terms: FunctionComponent<Props> = ({
  employeeName,
  commission,
  companyName,
  dateOfAgreement,
  window,
  instrumentsToExercise,
  averageStrikePrice,
  bankAccount,
  shareDepositoryAccount,
  currencyCode,
  goBack,
  goForward,
  orderExerciseType,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      <div className="block-m">
        <h2 className="text-center">
          <Content id="exercise.step.terms.termsfor" />{" "}
          <OrderExerciseTypeComponent type={orderExerciseType} />
        </h2>
        {orderExerciseType === "EXERCISE_AND_HOLD" && (
          <p className="text-content text-content-center">
            <Content id={"exercise.type.exerciseandhold.terms"} />
          </p>
        )}
        {orderExerciseType === "EXERCISE_AND_SELL" &&
          (window.is_release_process ? (
            <p className="text-content text-content-center">
              <Content id={"exercise.type.exerciseandsell.terms"} />
            </p>
          ) : (
            <BrokerTerms
              averageStrikePrice={averageStrikePrice}
              employeeName={employeeName}
              companyName={companyName}
              dateOfAgreement={dateOfAgreement}
              exerciseWindow={window}
              instrumentsToExercise={instrumentsToExercise}
              bankAccount={bankAccount}
              commission={commission}
              brokerMinimumAmount={brokerMinimumAmount}
              bufferPercentage={"30 %"}
              currencyCode={currencyCode}
            />
          ))}
        {orderExerciseType === "EXERCISE_AND_SELL_TO_COVER" &&
          (window.is_release_process ? (
            <p className="text-content text-content-center">
              <Content id={"exercise.type.exerciseandselltocover.terms"} />
            </p>
          ) : (
            <BrokerTerms
              averageStrikePrice={averageStrikePrice}
              employeeName={employeeName}
              companyName={companyName}
              dateOfAgreement={dateOfAgreement}
              exerciseWindow={window}
              instrumentsToExercise={instrumentsToExercise}
              bankAccount={bankAccount}
              commission={commission}
              brokerMinimumAmount={brokerMinimumAmount}
              bufferPercentage={"30 %"}
              currencyCode={currencyCode}
              sellToCoverSection={
                <li>
                  <p>
                    The Employee may choose to use all of the consideration to
                    purchase shares in {companyName} by indicating this in the
                    system. DNB will carry out the share purchase on behalf of
                    the Employee at best possible price. DNB will not charge
                    commission for this transaction. The purchase sum the
                    Employee shall pay for the shares will be set off against
                    the consideration ref. 4. above. The residual sum
                    (consideration less purchase sum for the shares and related
                    fees) will be transferred to the Employee ref. 6. above. The
                    shares will be transferred to the Employee's VPS account no:{" "}
                    {shareDepositoryAccount} no later than ten business days
                    after they have been purchased or DNB has received newly
                    issued shares. If DNB is not account operator for the VPS
                    account (i.e. not starting with 7, 5 or 1) the Employee must
                    fill out and submit a signed a customer registration form .
                    If the Employee does not have a share deposit account (VPS),
                    such can be established in a DNB branch office. The Employee
                    will receive contract note from DNB and/or registrar
                    documenting the share purchase. The Employee must inform of
                    any errors in such contract note immediately.
                  </p>
                </li>
              }
            />
          ))}
      </div>
      <ExerciseNavigationButtons
        forwardDisabled={false}
        goBack={goBack}
        goForward={goForward}
        overrideForwardButtonText={formatMessage({
          id: "exercise.confirm.termsAcceptanceBtnText",
        })}
      />
    </div>
  );
};

export default Terms;
