import moment from "moment";
import numeral from "numeral";
import React, { FunctionComponent } from "react";
import { Label, Table } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import CurrencyConversionView from "src/common/components/currency/currency-conversion-view";
import { CurrencyFormatterProps } from "src/common/components/currency/currency-formatter";
import { NO_VALUE } from "src/constants";
import { SettlementTypeCell } from "src/employee-portal/instrument-page/common/settlement-type-cell";
import { isFullyReleased } from "src/employee-portal/instrument-page/common/utils";
import {
  AwardGainFunction,
  FlatAward,
} from "src/employee-portal/instrument-page/instruments-reducer";
import ThresholdsCell from "src/employee-portal/instrument-page/psu-like/thresholds-cell";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface Props {
  award: FlatAward;
  stockPriceToday: number;
  showPerformance: boolean;
  hasExpired: boolean;
  showSharePrice: boolean;
  isReleasedAtVesting: boolean;
  showOriginalQuantity: boolean;
  showThresholds: boolean;
  showPrognosis: boolean;
  showStrike: boolean;
  showReleased: boolean;
  showCapOnGain: boolean;
  formatter: CurrencyFormatterProps;
  dateFormat: DateFormatState;
  overrideCurrency?: string;
  awardGain: AwardGainFunction;
  showSettlementType: boolean;
}

export const calcStrikeValues = (award: FlatAward) => {
  if (!award.strike) {
    return {
      strike: 0,
      totalMax: 0,
      totalEstimated: 0,
    };
  }

  const performanceFactor = award.performanceCriteria.performanceFactor;
  const performanceAdjQuantity = Math.floor(award.quantity * performanceFactor);

  return {
    strike: award.strike,
    totalMax: award.strike * award.quantity,
    totalEstimated: award.strike * performanceAdjQuantity,
  };
};

const PSUTableRow: FunctionComponent<Props> = ({
  award,
  stockPriceToday,
  showPerformance,
  showReleased,
  hasExpired,
  showSharePrice,
  isReleasedAtVesting,
  showOriginalQuantity,
  showCapOnGain,
  showThresholds,
  showPrognosis,
  showStrike,
  formatter: {
    formatCurrency,
    formatSharePrice,
    formatCurrencyAbbriviated,
    formatCurrencyWithCurrencyCode,
    formatCurrencyAbbriviatedWithCurrencyCode,
  },
  formatter,
  dateFormat: { normalDateFormat },
  overrideCurrency,
  awardGain,
  showSettlementType,
}) => {
  const performanceFactor = award.performanceCriteria.performanceFactor;
  const maximumQuantity = award.quantity;
  const performanceAdjQuantity = Math.floor(
    maximumQuantity * performanceFactor
  );
  const calculatedGain = awardGain(stockPriceToday, award);
  const capped = calculatedGain.capped;
  const totalValue = calculatedGain.gain;
  const performanceAdjValue =
    (performanceAdjQuantity * totalValue) / maximumQuantity;

  const strikeValues =
    showStrike && award.strike ? calcStrikeValues(award) : null;
  const isConcluded =
    award.performanceCriteria &&
    award.performanceCriteria.performanceStatus === "PERFORMANCE_CONCLUDED";
  return (
    <Table.Row positive={isFullyReleased(award)}>
      <Table.Cell textAlign="center">
        {award.performanceCriteria.name}
      </Table.Cell>
      {showSettlementType && <SettlementTypeCell award={award} />}
      <Table.Cell singleLine={true}>
        {moment(award.grantDate).format(normalDateFormat)}
      </Table.Cell>
      {/*{showOriginalQuantity && (*/}
      <Table.Cell textAlign="right">
        {award.originalQuantity > 0
          ? numeral(award.originalQuantity).format()
          : NO_VALUE}
      </Table.Cell>
      {showReleased && (
        <Table.Cell textAlign="right">
          {award.releasedQuantity < 0
            ? numeral(Math.abs(award.releasedQuantity)).format()
            : NO_VALUE}
        </Table.Cell>
      )}
      {/*)}*/}
      {/*<Table.Cell textAlign="right">*/}
      {/*  {isConcluded ? NO_VALUE : numeral(maximumQuantity).format()}*/}
      {/*</Table.Cell>*/}
      {showThresholds && (
        <Table.Cell textAlign="right" singleLine={true}>
          {isConcluded ? (
            NO_VALUE
          ) : (
            <ThresholdsCell
              award={award}
              maximumQuantity={maximumQuantity}
              formatter={formatter}
            />
          )}
        </Table.Cell>
      )}
      <Table.Cell singleLine={true}>
        {award.performanceStartDate &&
          award.performanceStartDate.format(normalDateFormat)}
      </Table.Cell>
      <Table.Cell singleLine={true}>
        {award.performanceEndDate &&
          award.performanceEndDate.format(normalDateFormat)}
      </Table.Cell>
      <Table.Cell singleLine={true}>
        {isConcluded ? (
          <Label color="green">
            <Content id="instruments.psu.table.performancestatus.concluded" />
          </Label>
        ) : (
          <Label color="yellow">
            <Content id="instruments.psu.table.performancestatus.open" />
          </Label>
        )}
      </Table.Cell>
      <Table.Cell singleLine={true}>
        {moment(award.performanceCriteria.date).format(normalDateFormat)}
      </Table.Cell>
      {showPrognosis && (
        <Table.Cell>
          {award.performanceCriteria.estimatedPerformanceProgress
            ? formatCurrencyAbbriviatedWithCurrencyCode(
                award.performanceCriteria.currencyCode
              )(
                award.performanceCriteria.estimatedPerformanceProgress,
                1
              ).toUpperCase()
            : NO_VALUE}
        </Table.Cell>
      )}
      {/*<Table.Cell textAlign="right">*/}
      {/*  {formatPercentage(*/}
      {/*    performanceFactor * (award.performanceCriteria.displayFactor || 1)*/}
      {/*  )}*/}
      {/*</Table.Cell>*/}
      <Table.Cell textAlign="right">
        {numeral(performanceAdjQuantity).format()}
      </Table.Cell>
      <Table.Cell singleLine={true}>
        {award.vestedDate.format(normalDateFormat)}
      </Table.Cell>
      {showCapOnGain && (
        <Table.Cell singleLine={true} warning={capped}>
          {award.capOnGain ? (
            <CurrencyConversionView value={award.capOnGain} decimals={2} />
          ) : (
            NO_VALUE
          )}
        </Table.Cell>
      )}
      {showStrike && (
        <>
          <Table.Cell singleLine={true} textAlign="right">
            {strikeValues ? (
              <CurrencyConversionView
                value={strikeValues.strike}
                decimals={2}
              />
            ) : (
              NO_VALUE
            )}
          </Table.Cell>
          <Table.Cell singleLine={true} textAlign="right">
            {strikeValues ? (
              <CurrencyConversionView
                value={strikeValues.totalMax}
                decimals={2}
              />
            ) : (
              NO_VALUE
            )}
          </Table.Cell>
          <Table.Cell singleLine={true} textAlign="right">
            {strikeValues ? (
              <CurrencyConversionView
                value={strikeValues.totalEstimated}
                decimals={2}
              />
            ) : (
              NO_VALUE
            )}
          </Table.Cell>
        </>
      )}
      {/*<Table.Cell singleLine={true} textAlign="right">*/}
      {/*  {isConcluded ? NO_VALUE : <CurrencyConversionView value={totalValue} decimals={0} />}*/}
      {/*</Table.Cell>*/}
      <Table.Cell singleLine={true} textAlign="right">
        <CurrencyConversionView value={performanceAdjValue} decimals={0} />
      </Table.Cell>
    </Table.Row>
  );
};

export default PSUTableRow;
