import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, DropdownItemProps } from "semantic-ui-react";

import { importAllEmployees } from "src/admin-portal/employees/employee-actions";
import EmployeeImportEmailError from "src/admin-portal/employees/employee-import-email-error";
import EmployeesImportPreview from "src/admin-portal/employees/employee-import-preview";
import { Entity } from "src/admin-portal/entity/entity-reducer";
import { SupportedLocale } from "src/admin-portal/texts/text-reducer";
import { SheetJSApp } from "src/common/components/file-parse/sheetjs";
import { countryOptions } from "src/common/data/common";
import { changeCommaForPunctuation } from "src/common/utils/utils";

export interface EmployeeSheetImport {
  firstName: string;
  lastName: string;
  email: string;
  residence: string;
  entityName: string;
  insider: number;
  socSec?: number;
  internalIdentification?: string;
  shareDepositoryAccount?: string;
  shareDepositoryBank?: string;
  shareDepositoryContact?: string;
  shareDepositoryDescription?: string;
  locale?: string;
  labels: string;
}

interface Props {
  entities: Entity[];
  entityOptions: DropdownItemProps[];
  closeImportEmployeesForm: () => void;
  oldEmployees: Api.V1.Employee[];
}

interface State {
  employees: EmployeeSheetImport[];
  employeesToBeCreated: Api.V1.Employee[];
  error?: string;
}

interface DispatchProps {
  importAllEmployees: (employees: Api.V1.Employee[]) => void;
}

const createDefaultMobilityEntry = (entityId?: string, socSec?: string) => ({
  id: new Date().getTime().toString(),
  fromDate: "",
  toDate: "",
  entityId,
  overrideEntitySocSec: socSec,
});

class EmployeeImport extends Component<Props & DispatchProps, State> {
  public state = {
    employees: [],
    employeesToBeCreated: [],
    error: null,
  };

  public render() {
    return (
      <div>
        <div className="block-xs">
          <SheetJSApp handleData={this.handleData} />
        </div>
        {this.state.error && <p>{this.state.error}</p>}
        <EmployeeImportEmailError
          employees={[...this.props.oldEmployees, ...this.state.employees]}
        />
        {this.state.employees.length > 0 && (
          <EmployeesImportPreview
            employees={this.state.employees}
            entities={this.props.entities}
          />
        )}
        {this.state.employees.length > 0 && (
          <div className="text-center">
            <Button primary={true} basic={true} onClick={this.importEmployees}>
              Import Employees
            </Button>
          </div>
        )}
      </div>
    );
  }

  private importEmployees = () => {
    this.props.importAllEmployees(this.state.employeesToBeCreated);
    this.props.closeImportEmployeesForm();
  };

  private handleData = (data: string[][], cols: any) => {
    const [header, ...importData] = data;
    try {
      const employees: EmployeeSheetImport[] = importData.map(
        toEmployeeSheetImport
      );
      const employeesToBeCreated: Api.V1.Employee[] = importData.map(
        toEmployee(this.props.entities)
      );

      this.setState({ employees, employeesToBeCreated, error: null });
    } catch (e) {
      this.setState({ error: e.message });
    }
  };
}

export const toEmployeeSheetImport = (employeeLine): EmployeeSheetImport => {
  return {
    firstName: employeeLine[0],
    lastName: employeeLine[1],
    email: employeeLine[2].replace(/ /g, "").toLowerCase(),
    residence: employeeLine[3],
    insider: parseInt(employeeLine[4], 10),
    entityName: employeeLine[5],
    socSec: parseSocSec(employeeLine[6]),
    internalIdentification: employeeLine[7],
    shareDepositoryAccount: employeeLine[8],
    shareDepositoryBank: employeeLine[9],
    shareDepositoryContact: employeeLine[10],
    shareDepositoryDescription: employeeLine[11],
    locale: employeeLine[12] || "en",
    labels: employeeLine[13],
  };
};

export const parseSocSec = (socSec: string | undefined): number | null =>
  socSec ? parseFloat(changeCommaForPunctuation(socSec)) : null;

export const toEmployee = entities => employeeLine => {
  const entityId = employeeLine[5];
  const residenceCode = countryCodeForCountryName(employeeLine[3]);

  const socSec =
    typeof parseSocSec(employeeLine[6]) === "number"
      ? parseSocSec(employeeLine[6]).toString()
      : null;
  return {
    firstName: employeeLine[0],
    lastName: employeeLine[1],
    email: employeeLine[2].replace(/ /g, "").toLowerCase(),
    residence: residenceCode,
    insider: parseInt(employeeLine[4], 10) === 1,
    entityId,
    socSec,
    internalIdentification: employeeLine[7],
    shareDepositoryAccount: employeeLine[8],
    shareDepositoryBank: employeeLine[9],
    shareDepositoryContact: employeeLine[10],
    shareDepositoryDescription: employeeLine[11],
    mobilityEntries: [createDefaultMobilityEntry(entityId, socSec)],
    locale: employeeLine[12] || "en",
    labels: employeeLine[13],
  };
};

export const sheetImportToEmployee = (entities: Entity[]) => (
  e: EmployeeSheetImport
): Api.V1.Employee => {
  const entityId = entityIdForName(entities, e.entityName);
  const residenceCode = countryCodeForCountryName(e.residence);

  const socSec = e.socSec ? e.socSec.toString() : null;
  return {
    firstName: e.firstName,
    lastName: e.lastName,
    email: e.email,
    residence: residenceCode,
    insider: e.insider === 1,
    entityId,
    socSec,
    internalIdentification: e.internalIdentification,
    mobilityEntries: [createDefaultMobilityEntry(entityId, socSec)],
    locale: e.locale as SupportedLocale,
  };
};

const entityIdForName = (entities: Entity[], entityName: string): string => {
  const entity = entities.filter(e => e.name === entityName)[0];

  if (!entity) {
    throw new Error(
      `Error parsing entity name: ${entityName}. It does not match any of stored entity names.`
    );
  }

  return entity.id;
};

export const entityNameForId = (
  entities: Entity[],
  entityId: string
): string => {
  const entity = entities.filter(e => e.id === entityId)[0];
  if (!entity) {
    throw new Error(
      `Error parsing entity name: ${entityId}. It does not match any of stored entity names.`
    );
  }

  return entity.name;
};

const countryCodeForCountryName = (countryName: string): string => {
  const country = countryOptions.filter(c => c.text === countryName)[0];

  if (!country) {
    throw new Error(
      `Error parsing country name: ${countryName}. It does not match any of stored countries.`
    );
  }

  return country.key;
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      importAllEmployees,
    },
    dispatch
  ),
});

export default connect<null, DispatchProps>(
  null,
  mapDispatchToProps
)(EmployeeImport);
