import Jsona from "jsona";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  deleteWindow,
  deleteWindowSucceeded,
  fetchWindow,
  fetchWindowDetails,
  fetchWindowDetailsSucceeded,
  fetchWindowOrders,
  fetchWindowOrdersSucceeded,
  fetchWindowSucceeded,
  postWindow,
  postWindowSucceeded,
  putWindow,
  putWindowSucceeded,
  windowCompleteCashlessOrders,
  windowCompleteExerciseAndHoldOrders,
  windowCompleteSaleCashlessOrders,
  windowStateStartProcessing,
  windowStateStartProcessingSucceeded,
} from "src/admin-portal/exercise-windows/window-actions";
import { Window } from "src/admin-portal/exercise-windows/window-reducer";
import * as selectors from "src/admin-portal/exercise-windows/window-selectors";
import { callApi, Method, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { buildURL } from "src/common/utils/utils";

const dataFormatter = new Jsona();

const EXERCISE_WINDOW_URL = "/windows?tenantId=";
const OPTIONS_EXERCISE_WINDOW_URL = "/windows/";
const windowOrdersUrl = (windowId: string, tenantId: string) => {
  const baseUrl = `/tenants/${tenantId}/orders`;
  const filter = { windowId };
  const include = [
    "employee",
    "exerciseOrder.exerciseOrderLines.tranche",
    "purchaseOrder.purchaseOpportunity",
  ].join(",");
  return buildURL(baseUrl, { filter, include });
};
const windowStartProcessingUrl = (windowId: string, tenantId: string) =>
  `/windows/${windowId}/start_processing_orders?tenantId=${tenantId}`;

function* fetchExerciseWindowRequested(action: ReturnType<typeof fetchWindow>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const windowResponse = yield call(() =>
      callApi(EXERCISE_WINDOW_URL + tenantId, token)
    );
    yield put(fetchWindowSucceeded(windowResponse.data as Window[]));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchExerciseWindow() {
  yield takeEvery(fetchWindow.getType(), fetchExerciseWindowRequested);
}

function* fetchExerciseWindowDetailsRequested(
  action: ReturnType<typeof fetchWindowDetails>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const windowId = action.payload;

    const windowResponse = yield call(() =>
      callApi(
        OPTIONS_EXERCISE_WINDOW_URL + windowId + "?tenantId=" + tenantId,
        token
      )
    );
    yield put(fetchWindowDetailsSucceeded(windowResponse.data as Window));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchExerciseWindowDetails() {
  yield takeEvery(
    fetchWindowDetails.getType(),
    fetchExerciseWindowDetailsRequested
  );
}

function* fetchWindowOrdersRequested(
  action: ReturnType<typeof fetchWindowOrders>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const ordersResponse = yield call(() =>
      callApi(windowOrdersUrl(action.payload, tenantId), token)
    );
    yield put(
      fetchWindowOrdersSucceeded(
        dataFormatter.deserialize(ordersResponse) as Api.V1.Order[]
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchWindowOrdersRequested() {
  yield takeEvery(fetchWindowOrders.getType(), fetchWindowOrdersRequested);
}

function* postExerciseWindowRequested(action: ReturnType<typeof postWindow>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";

    const windowResponse = yield call(() =>
      callApi(EXERCISE_WINDOW_URL + tenantId, token, method, action.payload)
    );
    yield put(postWindowSucceeded(windowResponse.data as Window));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPostExerciseWindow() {
  yield takeEvery(postWindow.getType(), postExerciseWindowRequested);
}

function* deleteExerciseWindowRequested(
  action: ReturnType<typeof deleteWindow>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const windowId = action.payload;
    const method = "DELETE";

    yield call(() =>
      callApi(
        OPTIONS_EXERCISE_WINDOW_URL + windowId + "?tenantId=" + tenantId,
        token,
        method
      )
    );
    yield put(deleteWindowSucceeded(windowId as Window["id"]));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchDeleteExerciseWindow() {
  yield takeEvery(deleteWindow.getType(), deleteExerciseWindowRequested);
}

function* putExerciseWindowRequested(action: ReturnType<typeof putWindow>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const windowId = action.payload.windowId;
    const method = "PUT";

    const windowResponse = yield call(() =>
      callApi(
        OPTIONS_EXERCISE_WINDOW_URL + windowId + "?tenantId=" + tenantId,
        token,
        method,
        action.payload.window
      )
    );
    yield put(putWindowSucceeded(windowResponse.data as Window));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPutExerciseWindow() {
  yield takeEvery(putWindow.getType(), putExerciseWindowRequested);
}

function* windowStateStartProcessingRequested(
  action: ReturnType<typeof windowStateStartProcessing>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);
    const { transactionDate } = action.payload;

    yield call(() =>
      callApi(
        windowStartProcessingUrl(action.payload.windowId, tenantId),
        token,
        Method.POST,
        { transactionDate }
      )
    );
    yield put(
      windowStateStartProcessingSucceeded(
        action.payload.windowId as Window["id"]
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchWindowStateStartProcessingRequested() {
  yield takeEvery(
    windowStateStartProcessing.getType(),
    windowStateStartProcessingRequested
  );
}

const windowCompleteExerciseAndHoldOrdersUrl = (
  windowId: string,
  tenantId: string
) =>
  `/windows/${windowId}/complete_exercise_and_hold_orders?tenantId=${tenantId}`;

function* windowCompleteExerciseAndHoldOrdersRequested(
  action: ReturnType<typeof windowCompleteExerciseAndHoldOrders>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);

    yield call(() =>
      callApi(
        windowCompleteExerciseAndHoldOrdersUrl(
          action.payload.windowId,
          tenantId
        ),
        token,
        Method.POST,
        {
          sharePrice: action.payload.sharePrice,
        }
      )
    );
    yield put(fetchWindow());
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchWindowCompleteExerciseAndHoldOrders() {
  yield takeEvery(
    windowCompleteExerciseAndHoldOrders.getType(),
    windowCompleteExerciseAndHoldOrdersRequested
  );
}

const windowCompleteCashlessOrdersUrl = (windowId: string, tenantId: string) =>
  `/windows/${windowId}/complete_cashless_orders?tenantId=${tenantId}`;

function* windowCompleteCashlessOrdersRequested(
  action: ReturnType<typeof windowCompleteCashlessOrders>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);

    yield call(() =>
      callApi(
        windowCompleteCashlessOrdersUrl(action.payload, tenantId),
        token,
        Method.POST
      )
    );
    yield put(fetchWindow());
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchWindowCompleteCashlessOrders() {
  yield takeEvery(
    windowCompleteCashlessOrders.getType(),
    windowCompleteCashlessOrdersRequested
  );
}

const windowCompleteSaleCashlessOrdersUrl = (
  windowId: string,
  tenantId: string
) => `/windows/${windowId}/complete_sale_cashless_orders?tenantId=${tenantId}`;

function* windowCompleteSaleCashlessOrdersRequested(
  action: ReturnType<typeof windowCompleteSaleCashlessOrders>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);

    yield call(() =>
      callApi(
        windowCompleteSaleCashlessOrdersUrl(action.payload.windowId, tenantId),
        token,
        Method.POST,
        {
          sharePrice: action.payload.sharePrice,
        }
      )
    );
    yield put(fetchWindow());
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchWindowCompleteSaleCashlessOrders() {
  yield takeEvery(
    windowCompleteSaleCashlessOrders.getType(),
    windowCompleteSaleCashlessOrdersRequested
  );
}
