import Jsona from "jsona";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  deleteReportConfig,
  deleteReportConfigSucceeded,
  fetchReportConfigs,
  fetchReportConfigsSucceeded,
  patchReportConfig,
  patchReportConfigSucceeded,
  postReportConfig,
  postReportConfigSucceeded,
  USER_NOT_AUTHORIZED,
} from "src/admin-portal/reports/reports-config/reports-config-actions";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import * as selectors from "src/selectors";

const reportConfigsUrl = tenantId => `/tenants/${tenantId}/report-configs`;
const reportConfigsUrlPatchAndDeleteUrl = configId =>
  `/report-configs/${configId}`;
const dataFormatter = new Jsona();

function* fetchReportConfigsRequested() {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);
    const response = yield call(() =>
      callApi(reportConfigsUrl(tenantId), token)
    );

    yield put(
      fetchReportConfigsSucceeded({
        configs: dataFormatter.deserialize(response) as Api.V1.ReportConfig[],
      })
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: USER_NOT_AUTHORIZED });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchReportConfigs() {
  yield takeEvery(fetchReportConfigs.getType(), fetchReportConfigsRequested);
}

function* postReportConfigRequested(
  action: ReturnType<typeof postReportConfig>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);
    const method = "POST";
    const body = dataFormatter.serialize({
      stuff: { ...action.payload, type: "reportConfigs" },
    });

    const response = yield call(() =>
      callApi(reportConfigsUrl(tenantId), token, method, body)
    );

    yield put(
      postReportConfigSucceeded(
        dataFormatter.deserialize(response) as Api.V1.ReportConfig
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: USER_NOT_AUTHORIZED });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPostReportConfig() {
  yield takeEvery(postReportConfig.getType(), postReportConfigRequested);
}

const trimReportConfigsInputPayload = payload => {
  const { links, relationshipNames, tenant, ...trimmedPayload } = payload;
  return trimmedPayload;
};

function* patchReportConfigRequested(
  action: ReturnType<typeof patchReportConfig>
) {
  try {
    const token = yield select(selectors.token);
    const configId = action.payload.id;
    const method = "PATCH";
    const body = dataFormatter.serialize({
      stuff: trimReportConfigsInputPayload(action.payload),
    });

    const response = yield call(() =>
      callApi(reportConfigsUrlPatchAndDeleteUrl(configId), token, method, body)
    );

    yield put(
      patchReportConfigSucceeded(
        dataFormatter.deserialize(response) as Api.V1.ReportConfig
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: USER_NOT_AUTHORIZED });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPatchReportConfig() {
  yield takeEvery(patchReportConfig.getType(), patchReportConfigRequested);
}

function* deleteReportConfigRequested(
  action: ReturnType<typeof deleteReportConfig>
) {
  try {
    const token = yield select(selectors.token);
    const method = "DELETE";
    const body = dataFormatter.serialize({
      stuff: {
        id: action.payload,
        type: "reportConfigs",
      },
    });
    yield call(() =>
      callApi(
        reportConfigsUrlPatchAndDeleteUrl(action.payload),
        token,
        method,
        body
      )
    );
    yield put(deleteReportConfigSucceeded(action.payload));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: USER_NOT_AUTHORIZED });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchDeleteReportConfig() {
  yield takeEvery(deleteReportConfig.getType(), deleteReportConfigRequested);
}
