import Jsona from "jsona";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  fetchContracts,
  fetchContractsSucceeded,
  fetchContractsFailed,
  postContract,
  postContractSucceeded,
  postContractFailed,
} from "src/actions/contract-actions";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import * as selectors from "src/selectors";

const dataFormatter = new Jsona();

const contractsUrl = (tenantId: string) => `/tenants/${tenantId}/contracts`;

function* fetchContractsRequested(action: ReturnType<typeof fetchContracts>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const response = yield call(() => callApi(contractsUrl(tenantId), token));
    yield put(
      fetchContractsSucceeded(
        dataFormatter.deserialize(response) as Api.V1.Contract[]
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put(fetchContractsFailed(e.message));
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchContracts() {
  yield takeEvery(fetchContracts.getType(), fetchContractsRequested);
}

function* postContractRequested(action: ReturnType<typeof postContract>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";

    const body = {
      data: {
        attributes: action.payload,
        type: "contracts",
      },
    };

    const response = yield call(() =>
      callApi(contractsUrl(tenantId), token, method, body)
    );
    yield put(
      postContractSucceeded(
        dataFormatter.deserialize(response) as Api.V1.Contract
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put(postContractFailed(e.message));
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPostContracts() {
  yield takeEvery(postContract.getType(), postContractRequested);
}
