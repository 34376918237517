import { Moment } from "moment";
import React, { FunctionComponent } from "react";

import { Message } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { DateFormatState } from "src/reducers/date-format-reducer";

export const ExerciseOrderSuccessMessage: FunctionComponent<{
  windowEndDate: Moment;
  dateFormat: DateFormatState;
}> = ({ windowEndDate, dateFormat }) => (
  <Message success={true}>
    <Message.Header>
      <Content id="exercise.complete.header" />
    </Message.Header>
    <Content
      id="exercise.complete.description"
      values={{
        windowEndDate: windowEndDate.format(dateFormat.dateTimeFormat),
      }}
    />
  </Message>
);

export const PurchaseOrderSuccessMessage: FunctionComponent<{
  windowEndDate: Moment;
  dateFormat: DateFormatState;
}> = ({ windowEndDate, dateFormat }) => (
  <Message success={true}>
    <Message.Header>
      <Content id="purchase.complete.header" />
    </Message.Header>
    <Content
      id="purchase.complete.description"
      values={{
        windowEndDate: windowEndDate.format(dateFormat.dateTimeFormat),
      }}
    />
  </Message>
);
