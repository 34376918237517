import React, { FunctionComponent } from "react";
import { Button, Checkbox, Form } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { IStringStringMap } from "src/admin-portal/texts/employee-portal-texts-reducer";
import {
  formatMessageWithDebugger,
  useFormatMessage,
} from "src/admin-portal/texts/utils";

interface Props {
  goBack: () => void;
  goForward: () => void;
  contentValues: IStringStringMap;
  bankAccountNumber: string;
  bankAccountNumberValid: boolean;
  handleBankAccountChange: (event: any) => void;
}

const BankAccount: FunctionComponent<Props> = ({
  goBack,
  goForward,
  contentValues,
  bankAccountNumber,
  bankAccountNumberValid,
  handleBankAccountChange,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div>
      <div className="block-m">
        <div className="text-center block-m">
          <h2 className="block-m">
            <Content id="purchase.bankaccount.header" />
          </h2>
        </div>
        <div className="block-m purchase-narrow-width margin-center">
          <Content id="purchase.bankaccount.content" values={contentValues} />
        </div>
      </div>
      <div className="center-center block-m">
        <div className="box purchase-box">
          <Form size={"large"}>
            <Form.Field>
              <label>
                <Content id="purchase.bankaccount.label" />
              </label>
              <input
                placeholder={formatMessageWithDebugger(
                  "purchase.bankaccount.placeholder",
                  formatMessage
                )}
                value={bankAccountNumber}
                onChange={handleBankAccountChange}
                pattern="[0-9]*"
              />
            </Form.Field>
          </Form>
        </div>
      </div>
      <div className="page-action-container text-center">
        <Button
          content={<Content id="global.backBtnTxt" />}
          size="big"
          onClick={goBack}
        />
        <Button
          positive={true}
          content={<Content id="global.nextBtnTxt" />}
          icon="right arrow"
          labelPosition="right"
          size="big"
          disabled={!bankAccountNumberValid}
          onClick={goForward}
        />
      </div>
    </div>
  );
};

export default BankAccount;
