import {
  changeCommaForPunctuation,
  changePunctuationForComma,
} from "src/common/utils/utils";
import { NO_VALUE } from "src/constants";

export interface ObjWithAnySetOfKeys {
  [key: string]: any;
}

export const excelNumber = (
  number: string | number | null
): string | number => {
  if (typeof number === "number" && !isNaN(number)) {
    return number;
  } else if (typeof number === "string") {
    return parseFloat(changeCommaForPunctuation(number));
  }

  return NO_VALUE;
};

export const excelExportFloat = (n: number | string) => {
  if (typeof n === "number") {
    return changePunctuationForComma(n.toString());
  }
  return changePunctuationForComma(n);
};

export const isAdded = (
  old: ObjWithAnySetOfKeys,
  imported: ObjWithAnySetOfKeys,
  key: string
): boolean => {
  if (typeof imported[key] === "boolean") {
    return false;
  }
  return !old[key] && Boolean(imported[key]);
};

export const isEdited = (
  old: ObjWithAnySetOfKeys,
  imported: ObjWithAnySetOfKeys,
  key: string
): boolean => {
  if (typeof imported[key] === "boolean") {
    return imported[key] !== old[key];
  }
  return (
    Boolean(old[key]) && imported[key] !== old[key] && Boolean(imported[key])
  );
};

export const isDeleted = (
  old: ObjWithAnySetOfKeys,
  imported: ObjWithAnySetOfKeys,
  key: string
): boolean => {
  return !imported[key] && Boolean(old[key]);
};

export const keepTouched = (
  imported: ObjWithAnySetOfKeys[],
  old: ObjWithAnySetOfKeys[],
  keys: string[]
): ObjWithAnySetOfKeys[] =>
  imported.filter(ie => {
    const match = old.find(e => e.id === ie.id);
    return keys.some(
      k =>
        isAdded(ie, match, k) ||
        isEdited(ie, match, k) ||
        isDeleted(ie, match, k)
    );
  });

export const removeUnchangedKeysButKeepId = (
  imported: ObjWithAnySetOfKeys,
  old: ObjWithAnySetOfKeys,
  keys: string[]
): ObjWithAnySetOfKeys =>
  imported.map(imp => {
    const match = old.find(o => o.id === imp.id);
    return keys.reduce(
      (acc, key) => {
        if (
          isAdded(match, imp, key) ||
          isEdited(match, imp, key) ||
          isDeleted(match, imp, key)
        ) {
          return { ...acc, [key]: imp[key] };
        }
        return acc;
      },
      { id: imp.id }
    );
  });

export const generateCount = (
  imported: ObjWithAnySetOfKeys[],
  old: ObjWithAnySetOfKeys[],
  keys: string[]
): { added: number; edited: number; deleted: number } =>
  imported.reduce(
    (acc, importedObj) => {
      const match = old.find(e => e.id === importedObj.id);
      const importedLineCount = keys.reduce(
        (accumulator, key) => {
          const isBeenAdded = isAdded(match, importedObj, key);
          const isBeenEdited = isEdited(match, importedObj, key);
          const isBeenDeleted = isDeleted(match, importedObj, key);

          if (isBeenAdded) {
            return { ...accumulator, a: accumulator.a += 1 };
          } else if (isBeenEdited) {
            return { ...accumulator, e: accumulator.e += 1 };
          } else if (isBeenDeleted) {
            return { ...accumulator, d: accumulator.d += 1 };
          }
          return accumulator;
        },
        { a: 0, e: 0, d: 0 }
      );

      return {
        ...acc,
        added: acc.added += importedLineCount.a,
        edited: acc.edited += importedLineCount.e,
        deleted: acc.deleted += importedLineCount.d,
      };
    },
    { added: 0, edited: 0, deleted: 0 }
  ) as { added: number; edited: number; deleted: number };
