import { push } from "connected-react-router";
import Jsona from "jsona";
import { pickBy, snakeCase, mapKeys } from "lodash";
import { call, debounce, put, select, takeEvery } from "redux-saga/effects";

import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { buildURL } from "src/common/utils/utils";
import { DEBOUNCE_TIME_500_MS } from "src/constants";
import {
  putExerciseOrder,
  putExerciseOrderSucceeded,
  putPurchaseOrder,
  putPurchaseOrderSucceeded,
  placeExerciseOrder,
  fetchOrders,
  putPurchaseOrderFailed,
  putExerciseOrderFailed,
  placeExerciseOrderFailed,
  fetchOrdersFailed,
  placeExerciseOrderSucceeded,
  fetchOrdersSucceeded,
} from "src/employee-portal/actions/order";
import { TYPES } from "src/employee-portal/employee-portal-profile-actions";

const dataFormatter = new Jsona();

export interface ExerciseOrder {
  exerciseType: string;
  exercise_order_lines: ExerciseOrderLine[];
  share_depository_account?: string;
  bank_account?: string;
  bic_swift_number?: string;
  share_depository_bank?: string;
  share_depository_clearing_code?: string;
  share_depository_contact?: string;
  share_depository_description?: string;
  tax_percentage?: number;
}

export interface ExerciseOrderLine {
  trancheId: string;
  exerciseQuantity: number;
}

function* fetchUsersOrdersRequested(action) {
  try {
    const state = yield select();
    const { token, tenant } = state.user;

    const url = buildURL(`/tenants/${tenant.id}/orders`, {
      include: [
        "exerciseOrder.exerciseOrderLines.tranche",
        "purchaseOrder.purchaseOpportunity.purchaseConfig",
        "employee",
        "window",
      ].join(","),
      filter: {
        "employee.account_id": state.user.decodedToken.sub.split("|")[1],
      },
    });

    const response = yield call(() => callApi(url, token));
    yield put(
      fetchOrdersSucceeded({
        orders: dataFormatter.deserialize(response) as Api.V1.Order[],
      })
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      yield put(fetchOrdersFailed(e.message));
    }
  }
}

export function* watchFetchUsersOrders() {
  yield takeEvery(fetchOrders.getType(), fetchUsersOrdersRequested);
}

function* placeExerciseOrderRequested(
  action: ReturnType<typeof placeExerciseOrder>
) {
  try {
    const state = yield select();
    const { token, tenant } = state.user;
    const method = "POST";
    const body = {
      data: {
        type: "orders",
        attributes: {
          orderType: "EXERCISE",
          windowId: action.payload.window.id,
        },
      },
      payload: action.payload.order,
    };

    yield call(() =>
      callApi(`/tenants/${tenant.id}/orders`, token, method, body)
    );
    yield put(placeExerciseOrderSucceeded());
    yield put({ type: "FETCH_EMPLOYEE_PORTAL_WELCOME" });
    yield put(
      push(
        `/orders/${
          action.payload.window.is_release_process ? "release" : "exercise"
        }complete`
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      yield put(placeExerciseOrderFailed(e.message));
    }
  }
}

export function* watchPlaceExerciseOrder() {
  yield debounce(
    DEBOUNCE_TIME_500_MS,
    placeExerciseOrder.getType(),
    placeExerciseOrderRequested
  );
}

function* putExerciseOrderRequested(
  action: ReturnType<typeof putExerciseOrder>
) {
  try {
    const state = yield select();
    const { token } = state.user;

    const method = "PUT";
    const { orderId, data } = action.payload;

    const url = buildURL(`/exercise-orders/${orderId}`, {
      include: "exerciseOrderLines.tranche",
    });

    const body = {
      data: {
        type: "exerciseOrders",
        id: orderId,
        attributes: data,
      },
    };

    const response = yield call(() => callApi(url, token, method, body));

    yield put(
      putExerciseOrderSucceeded(
        dataFormatter.deserialize(response) as Api.V1.ExerciseOrder
      )
    );

    yield put({
      type: TYPES.PUT_EMPLOYEE_PORTAL_PROFILE_SUCCEEDED,
      employeeData: mapKeys(
        pickBy(
          data,
          (value, key) => value !== null && key.startsWith("shareDepository")
        ),
        (_, key) => snakeCase(key)
      ),
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      yield put(putExerciseOrderFailed(e.message));
    }
  }
}

export function* watchPutExerciseOrder() {
  yield takeEvery(putExerciseOrder.getType(), putExerciseOrderRequested);
}

function* putPurchaseOrderRequested(
  action: ReturnType<typeof putPurchaseOrder>
) {
  try {
    const state = yield select();
    const { token } = state.user;

    const method = "PUT";
    const { orderId, data } = action.payload;

    const url = buildURL(`/purchase-orders/${orderId}`, {
      include: "purchaseOpportunity.purchaseConfig",
    });

    const body = {
      data: {
        type: "purchaseOrders",
        id: orderId,
        attributes: data,
      },
    };

    const response = yield call(() => callApi(url, token, method, body));

    yield put(
      putPurchaseOrderSucceeded(
        dataFormatter.deserialize(response) as Api.V1.PurchaseOrder
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      yield put(putPurchaseOrderFailed(e.message));
    }
  }
}

export function* watchPutPurchaseOrder() {
  yield takeEvery(putPurchaseOrder.getType(), putPurchaseOrderRequested);
}
