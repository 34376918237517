import React, { FunctionComponent } from "react";
import { Form } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";

export enum HasNorwegianAccountAnswer {
  YES = "YES",
  NO = "NO",
  NO_BUT_IN_PROGRESS = "NO_BUT_IN_PROGRESS",
}

interface Props {
  answer?: HasNorwegianAccountAnswer;
  allowInProgress: boolean;
  allowForeignShareDepo: boolean;
  setHasNorwegianAccount: (value: HasNorwegianAccountAnswer) => void;
}

const NorwegianShareDepoRadio: FunctionComponent<Props> = ({
  answer,
  allowInProgress,
  allowForeignShareDepo,
  setHasNorwegianAccount,
}) => {
  return (
    <div>
      <div className="block-xs">
        <label>
          <Content id="purchase.type.sharedepository.norwegian.label" />
        </label>
      </div>
      <div className="block-xs">
        <Form.Radio
          label={
            <label>
              <Content id="purchase.type.sharedepository.norwegian.yes.label" />
            </label>
          }
          value="yes"
          checked={answer === HasNorwegianAccountAnswer.YES}
          onChange={() => setHasNorwegianAccount(HasNorwegianAccountAnswer.YES)}
        />
      </div>
      {allowInProgress && (
        <div className="block-xs">
          <Form.Radio
            label={
              <label>
                <Content id="purchase.type.sharedepository.norwegian.inProgress.label" />
              </label>
            }
            value="no"
            checked={answer === HasNorwegianAccountAnswer.NO_BUT_IN_PROGRESS}
            onChange={() =>
              setHasNorwegianAccount(
                HasNorwegianAccountAnswer.NO_BUT_IN_PROGRESS
              )
            }
          />
        </div>
      )}
      {allowForeignShareDepo && (
        <div className="block-xs">
          <Form.Radio
            label={
              <label>
                <Content id="purchase.type.sharedepository.norwegian.no.label" />
              </label>
            }
            value="no"
            checked={answer === HasNorwegianAccountAnswer.NO}
            onChange={() =>
              setHasNorwegianAccount(HasNorwegianAccountAnswer.NO)
            }
          />
        </div>
      )}
    </div>
  );
};

export default NorwegianShareDepoRadio;
