import { createAction } from "redux-act";

import { Dividend } from "src/admin-portal/dividend/dividend-reducer";

export const fetchDividends = createAction("FETCH_DIVIDENTS");

export const fetchDividendsSucceeded = createAction(
  "FETCH_DIVIDENTS_SUCCEEDED",
  (payload: Dividend[]) => payload
);

export const createDividend = createAction(
  "CREATE_DIVIDENT",
  (payload: Dividend) => payload
);

export const createDividendSucceeded = createAction(
  "CREATE_DIVIDENT_SUCCEEDED",
  (payload: Dividend) => payload
);
