import { createAction } from "redux-act";

import { Window } from "src/admin-portal/exercise-windows/window-reducer";

export const fetchWindow = createAction("FETCH_WINDOW");

export const fetchWindowSucceeded = createAction(
  "FETCH_WINDOW_SUCCEEDED",
  (payload: Window[]) => payload
);

export const fetchWindowDetails = createAction(
  "FETCH_WINDOW_DETAILS",
  (payload: Window["id"]) => payload
);

export const fetchWindowDetailsSucceeded = createAction(
  "FETCH_WINDOW_DETAILS_SUCCEEDED",
  (payload: Window) => payload
);

export const fetchWindowOrders = createAction(
  "FETCH_WINDOW_ORDERS",
  (payload: Window["id"]) => payload
);

export const fetchWindowOrdersSucceeded = createAction(
  "FETCH_WINDOW_ORDERS_SUCCEEDED",
  (payload: Api.V1.Order[]) => payload
);

export const postWindow = createAction(
  "POST_WINDOW",
  (payload: Window) => payload
);

export const postWindowSucceeded = createAction(
  "POST_WINDOW_SUCCEEDED",
  (payload: Window) => payload
);

export const putWindow = createAction(
  "PUT_WINDOW",
  (payload: { windowId: Window["id"]; window: Window }) => payload
);

export const putWindowSucceeded = createAction(
  "PUT_WINDOW_SUCCEEDED",
  (payload: Window) => payload
);

export const deleteWindow = createAction(
  "DELETE_WINDOW",
  (payload: Window["id"]) => payload
);

export const deleteWindowSucceeded = createAction(
  "DELETE_WINDOW_SUCCEEDED",
  (payload: Window["id"]) => payload
);

export const selectWindow = createAction(
  "SELECT_WINDOW",
  (payload: Window["id"]) => payload
);

export const unselectWindow = createAction("UNSELECT_WINDOW");

export const windowStateStartProcessing = createAction(
  "WINDOW_STATE_START_PROCESSING",
  (payload: { transactionDate: string | null; windowId: Window["id"] }) =>
    payload
);

export const windowStateStartProcessingSucceeded = createAction(
  "WINDOW_STATE_START_PROCESSING_SUCCEEDED",
  (payload: Window["id"]) => payload
);

export const windowCompleteExerciseAndHoldOrders = createAction(
  "WINDOW_COMPLETE_EXERCISE_AND_HOLD_ORDERS",
  (payload: { sharePrice: string; windowId: Window["id"] }) => payload
);

export const windowCompleteExerciseAndHoldOrdersSucceeded = createAction(
  "WINDOW_COMPLETE_EXERCISE_AND_HOLD_ORDERS_SUCCEEDED",
  payload => payload
);

export const windowCompleteCashlessOrders = createAction(
  "WINDOW_COMPLETE_CASHLESS_ORDERS",
  (payload: Window["id"]) => payload
);

export const windowCompleteCashlessOrdersSucceeded = createAction(
  "WINDOW_COMPLETE_CASHLESS_ORDERS_SUCCEEDED",
  payload => payload
);

export const windowCompleteSaleCashlessOrders = createAction(
  "WINDOW_COMPLETE_SALE_CASHLESS_ORDERS",
  (payload: { sharePrice: string; windowId: Window["id"] }) => payload
);

export const windowCompleteSaleCashlessOrdersSucceeded = createAction(
  "WINDOW_COMPLETE_SALE_CASHLESS_ORDERS_SUCCEEDED",
  payload => payload
);

export const updateWindowOrder = createAction(
  "UPDATE_WINDOW_ORDER",
  (payload: { orderId: Api.V1.Order["id"]; values: Api.V1.Order }) => payload
);

export const unsetWindowOrder = createAction(
  "UNSET_WINDOW_ORDER",
  (payload: Api.V1.Order["id"]) => payload
);

export const unsetWindowOrders = createAction("UNSET_WINDOW_ORDERS");
