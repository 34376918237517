import moment, { Moment } from "moment";

import {
  ExcelSheetAwardLine,
  Tranche,
} from "src/admin-portal/awards/award-reducer";
import { countryEntry } from "src/common/data/common";
import { excelExportFloat } from "src/common/utils/excel";
import { sortMultipleLevels } from "src/common/utils/sort";
import {
  apiShortDate,
  employeeName,
  flatten,
  flattenOneLevel,
  norwegianShortDateLongYear,
  toNumberOrDefault,
} from "src/common/utils/utils";
import {
  NO_VALUE,
  OPTIO_INCENTIVES_BEGINNING_OF_TIME,
  OPTIO_INCENTIVES_END_OF_TIME,
  TransactionType,
} from "src/constants";

export const token = state => state.user.token;
export const isSysadmin = state => state.user.isSysadmin;
export const tenantId = state =>
  state.tenant.selectedTenant && state.tenant.selectedTenant.id;

export const entityAtDate = (
  mobiltyEntries: Api.V1.MobilityEntry[],
  date: Moment
): Api.V1.Entity => {
  const currentEntry = mobiltyEntries.find(me =>
    date.isBetween(me.fromDate, me.toDate, null, "[]")
  );
  if (!currentEntry) {
    throw new Error("Could not find an entity.");
  }

  return currentEntry.entity;
};

export const toExcelSheetAwardLine = (
  rawTranches: Tranche[]
): ExcelSheetAwardLine[] => {
  const tranches = sortedTranches(rawTranches);
  const lines: ExcelSheetAwardLine[] = flatten(
    tranches.map((tranche, trancheIndex) =>
      tranche.transactions.map(
        (transaction: Api.V1.Transaction, transactionIndex) => {
          const trancheIntegerId = (trancheIndex + 1) * 1000;
          const integerId = trancheIntegerId + transactionIndex + 1;
          return {
            id: transaction.id,
            tranche_id: tranche.id,
            programId: tranche.programId,
            programName: tranche.programName,
            subProgramName: tranche.subProgramName,
            employeeName: tranche.employeeName,
            employee: tranche.employee,
            entity: tranche.entity,
            entityAtTransactionDate: entityAtDate(
              tranche.mobility,
              moment(transaction.transactionDate, apiShortDate)
            ),
            country: tranche.country,
            entityName: tranche.entityName,
            instrumentName: tranche.instrumentName,
            settlementName: tranche.settlementName,
            performance: tranche.performance,
            grantDate: transaction.grantDate && moment(transaction.grantDate),
            vestedDate:
              transaction.vestedDate && moment(transaction.vestedDate),
            expiryDate:
              transaction.expiryDate && moment(transaction.expiryDate),
            quantity: transaction.quantity,
            strike: isNaN(parseFloat(transaction.strike))
              ? null
              : parseFloat(transaction.strike),
            exercisedQuantity: tranche.exercisedQuantity,
            purchase_price: transaction.purchasePrice,
            fair_value: transaction.fairValue,
            transaction_type: transaction.transactionType,
            transaction_date: moment(transaction.transactionDate, apiShortDate),
            is_dividend: tranche.is_dividend,
            integerId,
            mobility: tranche.mobility,
            fv_valuation_method: transaction.fvValuationMethod,
            fv_share_price_at_grant: parseDecimalNumberOrNull(
              transaction.fvSharePriceAtGrant
            ),
            fv_strike_price: parseDecimalNumberOrNull(
              transaction.fvStrikePrice
            ),
            fv_expected_lifetime: parseDecimalNumberOrNull(
              transaction.fvExpectedLifetime
            ),
            fv_volatility: parseDecimalNumberOrNull(transaction.fvVolatility),
            fv_interest_rate: parseDecimalNumberOrNull(
              transaction.fvInterestRate
            ),
            fv_dividend: parseDecimalNumberOrNull(transaction.fvDividend),
            comment: transaction.comment,
            newQuantityFactor: toNumberOrDefault(
              transaction.newQuantityFactor,
              1
            ),
            performanceRule:
              tranche.tranchePerformanceRules[0] &&
              tranche.tranchePerformanceRules[0].performanceRule,
          } as ExcelSheetAwardLine;
        }
      )
    )
  );

  return lines.map(line => {
    if (
      TransactionType[line.transaction_type] === TransactionType.TERMINATION
    ) {
      return {
        ...line,
        transactions_to_terminate: transactionsToTerminate(lines, line),
      };
    }
    return line;
  });
};

export const toOldTranchesFormat = (tranche: Api.V1.VestingEvent): Tranche => {
  return {
    id: tranche.id,
    internal_employee_id: tranche.award.employee.internalIdentification,
    programId: tranche.award.incentiveSubProgram.incentiveProgram.id,
    programName: tranche.award.incentiveSubProgram.incentiveProgram.name,
    subProgramId: tranche.award.incentiveSubProgram.id,
    subProgramName: tranche.award.incentiveSubProgram.name,
    subProgram: tranche.award.incentiveSubProgram,
    employeeName: `${tranche.award.employee.firstName} ${tranche.award.employee.lastName}`,
    employee: tranche.award.employee,
    entity: tranche.award.employee.entity,
    country: tranche.award.employee.residence,
    entityName: tranche.award.employee.entity.name,
    instrumentName: tranche.award.incentiveSubProgram.instrumentTypeId,
    settlementName: tranche.award.incentiveSubProgram.settlementTypeId,
    performance: tranche.award.incentiveSubProgram.performance,
    grantDate: moment(tranche.grantDate),
    vestedDate: moment(tranche.vestedDate),
    expiryDate: moment(tranche.expiryDate),
    quantity: tranche.quantity,
    strike: isNaN(parseFloat(tranche.strike))
      ? null
      : parseFloat(tranche.strike),
    exercisedQuantity: tranche.exercisedQuantity,
    termination_quantity: tranche.terminationQuantity,
    purchase_price: tranche.purchasePrice,
    is_dividend: tranche.isDividend,
    fair_value: tranche.fairValue,
    transactions: tranche.transactions,
    mobility: tranche.award.employee.mobilityEntries.map(me => ({
      ...me,
      fromDate: me.fromDate || OPTIO_INCENTIVES_BEGINNING_OF_TIME,
      toDate: me.toDate || OPTIO_INCENTIVES_END_OF_TIME,
    })),
    tranchePerformanceRules: tranche.tranchePerformanceRules,
    annualTurnover: tranche.award.incentiveSubProgram.accountingTurnoverRate,
    isCashSettled:
      tranche.award.incentiveSubProgram.settlementTypeId === "cash",
  };
};

const parseDecimalNumberOrNull = (numberString: string): number | null =>
  isNaN(parseFloat(numberString)) ? null : parseFloat(numberString);

const transactionsToTerminate = (
  lines: ExcelSheetAwardLine[],
  line: ExcelSheetAwardLine
) => lines.filter(l => l.tranche_id === line.tranche_id && line.id !== l.id);

export const trancheDataArray = (
  tranches: Api.V1.VestingEvent[]
): Array<Array<string | number>> => {
  const headers = [
    "Name",
    "Email",
    "Entity",
    "Country",
    "Program",
    "Subprogram",
    "Instrument",
    "Settlement",
    "Performance",
    "Purchase price",
    "Input Value",
    "Strike",
    "Quantity",
    "Exercised",
    "Released",
    "Terminated",
    "Expired",
    "Grant Date",
    "Vested Date",
    "Expiry Date",
    "Is dividend?",
    "Fair value",
    "Share Depo Account",
    "Share Depo Bank",
    "Share Depo Contact",
    "Share Depo Desc.",
  ];

  const rows = tranches.map((tranche: Api.V1.VestingEvent) => [
    `${tranche.award.employee.firstName} ${tranche.award.employee.lastName}`,
    tranche.award.employee.email,
    tranche.award.employee.entity.name,
    countryEntry(tranche.award.employee.residence).text,
    tranche.award.incentiveSubProgram.incentiveProgram.name,
    tranche.award.incentiveSubProgram.name,
    tranche.award.incentiveSubProgram.instrumentTypeId,
    tranche.award.incentiveSubProgram.settlementTypeId,
    tranche.award.incentiveSubProgram ? "Yes" : "No",
    tranche.purchasePrice ? excelExportFloat(tranche.purchasePrice) : NO_VALUE,
    tranche.taxableInputValuation
      ? excelExportFloat(tranche.taxableInputValuation)
      : NO_VALUE,
    tranche.strike ? excelExportFloat(tranche.strike) : NO_VALUE,
    tranche.quantity + tranche.expiredQuantity,
    -1 * tranche.exercisedQuantity,
    -1 * tranche.releasedQuantity,
    -1 * tranche.terminationQuantity,
    -1 * tranche.expiredQuantity,
    tranche.grantDate
      ? moment(tranche.grantDate).format(norwegianShortDateLongYear)
      : NO_VALUE,
    tranche.vestedDate
      ? moment(tranche.vestedDate).format(norwegianShortDateLongYear)
      : NO_VALUE,
    tranche.expiryDate
      ? moment(tranche.expiryDate).format(norwegianShortDateLongYear)
      : NO_VALUE,
    tranche.isDividend ? "Yes" : "No",
    tranche.fairValue ? excelExportFloat(tranche.fairValue) : NO_VALUE,
    tranche.award.employee.shareDepositoryAccount
      ? tranche.award.employee.shareDepositoryAccount
      : NO_VALUE,
    tranche.award.employee.shareDepositoryBank
      ? tranche.award.employee.shareDepositoryBank
      : NO_VALUE,
    tranche.award.employee.shareDepositoryContact
      ? tranche.award.employee.shareDepositoryContact
      : NO_VALUE,
    tranche.award.employee.shareDepositoryDescription
      ? tranche.award.employee.shareDepositoryDescription
      : NO_VALUE,
  ]);

  return [headers, ...rows];
};

export const transactionDataArray = (
  tranches: Api.V1.VestingEvent[]
): Array<Array<string | number>> => {
  const headers = [
    "Name",
    "Email",
    "Entity",
    "Program",
    "Subprogram",
    "Instrument",
    "Transaction Type",
    "Tranche-ID",
    "Transaction-ID",
    "Transaction Date",
    "Grant Date",
    "Vested Date",
    "Expiry Date",
    "Strike Price",
    "Quantity",
    "Fair Value",
    "Valuation Method",
    "Share Price",
    "Strike (fvStrikePrice)",
    "Life",
    "Vol",
    "IR",
    "Div",
    "Comment",
  ];

  const rows = flattenOneLevel(
    tranches.map(tranche =>
      tranche.transactions.map(transaction => transactionData(transaction))
    )
  );

  return [headers, ...rows];
};

export const transactionDataArrayFromTransactions = (
  transactions: Api.V1.Transaction[]
): Array<Array<string | number>> => {
  const headers = [
    "Name",
    "Email",
    "Entity",
    "Program",
    "Subprogram",
    "Transaction Type",
    "Tranche-ID",
    "Transaction-ID",
    "Transaction Date",
    "Grant Date",
    "Vested Date",
    "Expiry Date",
    "Strike Price",
    "Quantity",
    "Fair Value",
    "Valuation Method",
    "Share Price",
    "Strike (fvStrikePrice)",
    "Life",
    "Vol",
    "IR",
    "Div",
    "Comment",
  ];

  const rows = transactions.map(transaction => transactionData(transaction));

  return [headers, ...rows];
};

export const transactionData = (
  transaction: Api.V1.Transaction
): Array<string | number> => [
  employeeName(transaction.tranche.award.employee),
  transaction.tranche.award.employee.email,
  transaction.tranche.award.employee.entity.name,
  transaction.tranche.award.incentiveSubProgram.incentiveProgram.name,
  transaction.tranche.award.incentiveSubProgram.name,
  transaction.tranche.award.incentiveSubProgram.instrumentTypeId,
  transaction.transactionType,
  transaction.tranche.id,
  transaction.id,
  transaction.transactionDate
    ? moment(transaction.transactionDate, apiShortDate).format(
        norwegianShortDateLongYear
      )
    : NO_VALUE,
  transaction.grantDate
    ? moment(transaction.grantDate, apiShortDate).format(
        norwegianShortDateLongYear
      )
    : NO_VALUE,
  transaction.vestedDate
    ? moment(transaction.vestedDate, apiShortDate).format(
        norwegianShortDateLongYear
      )
    : NO_VALUE,
  transaction.expiryDate
    ? moment(transaction.expiryDate, apiShortDate).format(
        norwegianShortDateLongYear
      )
    : NO_VALUE,
  transaction.strike ? excelExportFloat(transaction.strike) : NO_VALUE,
  transaction.quantity,
  transaction.fairValue ? excelExportFloat(transaction.fairValue) : NO_VALUE,
  transaction.fvValuationMethod,
  transaction.fvSharePriceAtGrant,
  transaction.fvStrikePrice
    ? excelExportFloat(transaction.fvStrikePrice)
    : NO_VALUE,
  transaction.fvExpectedLifetime
    ? excelExportFloat(transaction.fvExpectedLifetime)
    : NO_VALUE,
  transaction.fvVolatility
    ? excelExportFloat(transaction.fvVolatility)
    : NO_VALUE,
  transaction.fvInterestRate
    ? excelExportFloat(transaction.fvInterestRate)
    : NO_VALUE,
  transaction.fvDividend ? excelExportFloat(transaction.fvDividend) : NO_VALUE,
  transaction.comment,
];

export const sortedTranches = (tranches: Tranche[]): Tranche[] =>
  sortMultipleLevels(tranches)("employeeName", "grantDate", "vestedDate");
