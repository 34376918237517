import React, { FunctionComponent } from "react";

import { Popup } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import IconQuestionmark from "src/common/components/icon-questionmark";

const TextWithHelperText: FunctionComponent<{
  textKey: string;
  popupTextKey: string;
  values?: any;
}> = ({ textKey, popupTextKey, values }) => (
  <Popup
    trigger={
      <span>
        <Content id={textKey} values={values} /> <IconQuestionmark />
      </span>
    }
  >
    <Content id={popupTextKey} values={values} />
  </Popup>
);

export default TextWithHelperText;
