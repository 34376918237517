import { createAction } from "redux-act";

export const postDocumentAcceptance = createAction(
  "POST_DOCUMENT_ACCEPTANCE",
  (payload: Pick<Api.V1.DocumentAcceptance, "documentsDocumentableId">) =>
    payload
);

export const postDocumentAcceptanceSucceeded = createAction(
  "POST_DOCUMENT_ACCEPTANCE_SUCCEEDED",
  (payload: Api.V1.DocumentAcceptance) => payload
);

export const postDocumentAcceptanceFailed = createAction(
  "POST_DOCUMENT_ACCEPTANCE_FAILED",
  (payload: string) => payload
);
