import { createAction } from "redux-act";

export const fetchCustomRelationshipTypes = createAction(
  "FETCH_CUSTOM_RELATIONSHIP_TYPES"
);

export const fetchCustomRelationshipTypesSucceeded = createAction(
  "FETCH_CUSTOM_RELATIONSHIP_TYPES_SUCCEEDED",
  (payload: Api.V1.CustomRelationshipType[]) => payload
);

export const fetchCustomRelationshipTypesFailed = createAction(
  "FETCH_CUSTOM_RELATIONSHIP_TYPES_FAILED",
  (payload: string) => payload
);

export const postCustomRelationshipType = createAction(
  "POST_CUSTOM_RELATIONSHIP_TYPE",
  (payload: Api.V1.CustomRelationshipTypeUpdate) => payload
);

export const postCustomRelationshipTypeSucceeded = createAction(
  "POST_CUSTOM_RELATIONSHIP_TYPE_SUCCEEDED",
  (payload: Api.V1.CustomRelationshipType) => payload
);

export const postCustomRelationshipTypeFailed = createAction(
  "POST_CUSTOM_RELATIONSHIP_TYPE_FAILED",
  (payload: string) => payload
);

export const putCustomRelationshipType = createAction(
  "PUT_CUSTOM_RELATIONSHIP_TYPE",
  (payload: {
    id: Api.V1.CustomRelationshipType["id"];
    data: Api.V1.CustomRelationshipTypeUpdate;
  }) => payload
);

export const putCustomRelationshipTypeSucceeded = createAction(
  "PUT_CUSTOM_RELATIONSHIP_TYPE_SUCCEEDED",
  (payload: Api.V1.CustomRelationshipType) => payload
);

export const putCustomRelationshipTypeFailed = createAction(
  "PUT_CUSTOM_RELATIONSHIP_TYPE_FAILED",
  (payload: string) => payload
);

export const deleteCustomRelationshipType = createAction(
  "DELETE_CUSTOM_RELATIONSHIP_TYPE",
  (payload: Api.V1.CustomRelationshipType["id"]) => payload
);

export const deleteCustomRelationshipTypeSucceeded = createAction(
  "DELETE_CUSTOM_RELATIONSHIP_TYPE_SUCCEEDED",
  (payload: Api.V1.CustomRelationshipType["id"]) => payload
);

export const deleteCustomRelationshipTypeFailed = createAction(
  "DELETE_CUSTOM_RELATIONSHIP_TYPE_FAILED",
  (payload: string) => payload
);

export const putCustomRelationshipTypes = createAction(
  "PUT_CUSTOM_RELATIONSHIP_TYPES",
  (payload: Api.V1.CustomRelationshipTypesUpdate[]) => payload
);

export const putCustomRelationshipTypesSucceeded = createAction(
  "PUT_CUSTOM_RELATIONSHIP_TYPES_SUCCEEDED",
  (payload: Api.V1.CustomRelationshipType[]) => payload
);

export const putCustomRelationshipTypesFailed = createAction(
  "PUT_CUSTOM_RELATIONSHIP_TYPES_FAILED",
  (payload: string) => payload
);
