import React, { FunctionComponent } from "react";
import { Message } from "semantic-ui-react";

import { useFormatMessage } from "src/admin-portal/texts/utils";

const DocumentsEmptyState: FunctionComponent = () => {
  const formatMessage = useFormatMessage();
  return (
    <div className="text-content-center">
      <Message
        header={formatMessage({ id: "documentsPage.empty.header" })}
        content={formatMessage({ id: "documentsPage.empty.content" })}
      />
    </div>
  );
};

export default DocumentsEmptyState;
