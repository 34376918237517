/*
  PutCallFlag: Either "put" or "call"
  S: Stock Price
  X: Strike Price
  T: Time to expiration (in years)
  r: Risk-free rate
  v: Volatility
  This is the same one found in http://www.espenhaug.com/black_scholes.html
  but written with proper indentation and a === instead of == because it's
  faster, and it doesn't declare 5 useless variables (although if you really
  want to do it to have more elegant code I left a commented CND function in
  the end)
*/

function blackScholes(PutCallFlag, S, X, T, r, v) {
  const d1 = (Math.log(S / X) + (r + (v * v) / 2) * T) / (v * Math.sqrt(T));
  const d2 = d1 - v * Math.sqrt(T);
  if (PutCallFlag === "call") {
    return S * CND(d1) - X * Math.exp(-r * T) * CND(d2);
  } else {
    return X * Math.exp(-r * T) * CND(-d2) - S * CND(-d1);
  }
}

/* The cummulative Normal distribution function: */
function CND(x) {
  if (x < 0) {
    return 1 - CND(-x);
  } else {
    const k = 1 / (1 + 0.2316419 * x);
    return (
      1 -
      (Math.exp((-x * x) / 2) / Math.sqrt(2 * Math.PI)) *
        k *
        (0.31938153 +
          k *
            (-0.356563782 +
              k * (1.781477937 + k * (-1.821255978 + k * 1.330274429))))
    );
  }
}

// Cumulative distribution function in Javascript
// https://stackoverflow.com/questions/5259421/cumulative-distribution-function-in-javascript
function stdNormal(z) {
  let k, m, total, item, a, b;

  // Power series is not stable at these extreme tail scenarios
  if (z < -6) {
    return 0;
  }
  if (z > 6) {
    return 1;
  }

  m = 1; // m(k) == (2**k)/factorial(k)
  b = z; // b(k) == z ** (2*k + 1)
  const z2 = z * z; // cache of z squared
  const z4 = z2 * z2; // cache of z to the 4th
  const values = [];

  // Compute the power series in groups of two terms.
  // This reduces floating point errors because the series
  // alternates between positive and negative.
  for (k = 0; k < 100; k += 2) {
    a = 2 * k + 1;
    item = b / (a * m);
    item *= 1 - (a * z2) / ((a + 1) * (a + 2));
    values.push(item);
    m *= 4 * (k + 1) * (k + 2);
    b *= z4;
  }

  // Add the smallest terms to the total first that
  // way we minimize the floating point errors.
  total = 0;
  for (k = 49; k >= 0; k--) {
    total += values[k];
  }

  // Multiply total by 1/sqrt(2*PI)
  // Then add 0.5 so that stdNormal(0) === 0.5
  return 0.5 + 0.3989422804014327 * total;
}

// javascript translation of the excel formula
// https://gitlab.com/optioincentives/development/uploads/c068a7818d9b39c98d24766bc00d8163/Black-Scholes-Merton_calculator_with_dividend_yield_FH.xlsx
export function blackScholesExcel(
  expectedLifetime: number, // C5
  sharePrice: number, // C6
  strike: number, // C7
  interestRate: number, // C8
  volatility: number, // C9
  dividend: number // C10
): number {
  return (
    sharePrice *
      Math.exp(-dividend * expectedLifetime) *
      stdNormal(
        (Math.log(sharePrice / strike) +
          (interestRate - dividend + Math.pow(volatility, 2) / 2) *
            expectedLifetime) /
          (volatility * Math.sqrt(expectedLifetime))
      ) -
    strike *
      Math.exp(-interestRate * expectedLifetime) *
      stdNormal(
        (Math.log(sharePrice / strike) +
          (interestRate - dividend - Math.pow(volatility, 2) / 2) *
            expectedLifetime) /
          (volatility * Math.sqrt(expectedLifetime))
      )
  );
}

export default blackScholes;
