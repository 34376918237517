import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Button,
  Dimmer,
  Dropdown,
  Header,
  Loader,
  Message,
  Modal,
} from "semantic-ui-react";

import { PerformanceRulesState } from "src/admin-portal/performance-rules/performance-rules-page/performance-rules-reducer";

interface Props {
  performanceRulesState: PerformanceRulesState;
  fetchPerformanceRules: () => void;
  selectedTranchesCount: number;
  handlePerformanceCriteria: (performanceRuleId: string) => void;
  closePatchTranchePerformanceRuleSucceededNotification: () => void;
  isSysadmin: boolean;
}

const AttachPerformanceCriteriaModal: FunctionComponent<Props> = ({
  performanceRulesState,
  fetchPerformanceRules,
  selectedTranchesCount,
  handlePerformanceCriteria,
  closePatchTranchePerformanceRuleSucceededNotification,
  isSysadmin,
}) => {
  const [selectedPerformanceRuleId, setSelectedPerformanceRuleId] = useState<
    string
  >("");
  useEffect(() => {
    if (!performanceRulesState.performanceRules) {
      fetchPerformanceRules();
    }
  }, []);

  useEffect(() => {
    if (performanceRulesState.isAttachingToTranchesSucceeded) {
      setSelectedPerformanceRuleId("");
    }
  }, [performanceRulesState]);

  const {
    performanceRules,
    isFetching,
    isAttachingToTranches,
    isAttachingToTranchesSucceeded,
  } = performanceRulesState;

  const showLoader = isAttachingToTranches || isFetching;
  return (
    <Modal
      trigger={
        <Button basic={true} style={{ display: !isSysadmin && "none" }}>
          Attach Performance Criteria
        </Button>
      }
      closeIcon={true}
      size="tiny"
      onClose={() => {
        setSelectedPerformanceRuleId(""),
          closePatchTranchePerformanceRuleSucceededNotification();
      }}
    >
      <Header content="Select performance rule which you would like to apply" />
      <Modal.Content>
        {showLoader && (
          <Dimmer active={true} inverted={true}>
            <Loader inverted={true} inline={true} />
          </Dimmer>
        )}
        {!isFetching && !isAttachingToTranchesSucceeded && (
          <>
            <Dropdown
              placeholder="search for performance criteria"
              search={true}
              selection={true}
              options={
                performanceRules &&
                performanceRules.map(pr => ({
                  key: pr.id,
                  value: pr.id,
                  text: pr.name,
                }))
              }
              onChange={(e, { value }) =>
                setSelectedPerformanceRuleId("" + value)
              }
            />
            <p className="space-vertical">
              You are about to update {selectedTranchesCount} tranches
            </p>
          </>
        )}

        {isAttachingToTranchesSucceeded && (
          <Message
            positive={true}
            header="Success!"
            content="Performance criteria has been attached."
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic={true}
          color="green"
          content="Attach Performance Rule"
          disabled={!selectedPerformanceRuleId}
          onClick={() => handlePerformanceCriteria(selectedPerformanceRuleId)}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default AttachPerformanceCriteriaModal;
