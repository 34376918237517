import React, { FunctionComponent } from "react";

import ShareDepositoryAccount from "src/employee-portal/order/table/UpdateModal/Form/fields/ShareDepositoryAccount";
import ShareDepositoryBank from "src/employee-portal/order/table/UpdateModal/Form/fields/ShareDepositoryBank";
import ShareDepositoryClearingCode from "src/employee-portal/order/table/UpdateModal/Form/fields/ShareDepositoryClearingCode";
import ShareDepositoryContact from "src/employee-portal/order/table/UpdateModal/Form/fields/ShareDepositoryContact";
import ShareDepositoryDescription from "src/employee-portal/order/table/UpdateModal/Form/fields/ShareDepositoryDescription";
import { FieldsVisibility } from "src/employee-portal/order/table/UpdateModal/Form/UseFieldsVisibilityCheck";

interface Props {
  visibility: FieldsVisibility;
}

const CommonShareDepositoryFields: FunctionComponent<Props> = ({
  visibility,
}) => {
  return (
    <>
      <ShareDepositoryAccount
        visible={visibility.isShareDepositoryAccountVisible}
      />
      <ShareDepositoryClearingCode
        visible={visibility.isShareDepositoryClearingCodeVisible}
      />
      <ShareDepositoryBank visible={visibility.isShareDepositoryBankVisible} />
      <ShareDepositoryContact
        visible={visibility.isShareDepositoryContactVisible}
      />
      <ShareDepositoryDescription
        visible={visibility.isShareDepositoryDescriptionVisible}
      />
    </>
  );
};

export default CommonShareDepositoryFields;
