import moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Button,
  Dropdown,
  Icon,
  Input,
  Message,
  Modal,
  Popup,
} from "semantic-ui-react";

import {
  fetchAnnualVolatility,
  fetchAnnualVolatilityBulk,
  fetchSharePrices,
  resetSharePrices,
} from "src/admin-portal/actions/share-price-actions";
import { genConnectedDates } from "src/admin-portal/reports/generate-report/report-utils";
import FairValueManangementGroup from "src/admin-portal/tranches-page/actions/fair-value-management/fair-value-management-group";
import FairValueManagementSummary from "src/admin-portal/tranches-page/actions/fair-value-management/fair-value-management-summary";
import {
  calcGroupedTranches,
  calcInterestRate,
  ExpectedLifetimeTypeEnum,
  expectedLifetimeTypes,
  findAvailableIR,
  generateFairValueAdjustmentForCostTransactions,
  generateFairValueAdjustmentForSocSecTransactions,
  generateFairValueGrantForCostTransactions,
  generateFairValueGrantForSocSecTransactions,
  getGroupAnnualVolatilityState,
  InterestRatesState,
  isFairValueValid,
  managementTypesOptions,
  MngTypeEnum,
  TrancheGroup,
  TransactionKeysAndValues,
  updateFairValueForGroups,
  updateGroupedTranchesWithFairValues,
  updateGroupedTranchesWithInterestRates,
} from "src/admin-portal/tranches-page/actions/fair-value-management/utils";
import { TrancheDataEntry } from "src/admin-portal/tranches-page/types";
import ClickableLink from "src/common/components/clickable-link";
import SpinnerFullScreen from "src/common/components/spinner-full-screen";
import { fetchInterestRates } from "src/common/data/interest-rates";
import { sortMultipleLevels } from "src/common/utils/sort";
import {
  apiShortDate,
  changePunctuationForComma,
  norwegianShortDateLongYear,
  toNumber,
} from "src/common/utils/utils";
import { RootState } from "src/reducers/all-reducers";

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
interface PassedProps {
  tranches: TrancheDataEntry[];
  isFairValueMngModalOpen: boolean;
  normalDateFormat: string;
  createTransactions: (
    transactions: Array<{
      trancheId: string;
      transaction: Api.V1.Transaction;
    }>
  ) => void;
  updateTransactions: (transactions: Api.V1.Transaction[]) => void;
  close: () => void;
  recordCount: number;
}
type Props = StateProps & DispatchProps & PassedProps;

const FairValueManangement: FunctionComponent<Props> = ({
  tranches,
  normalDateFormat,
  createTransactions,
  updateTransactions,
  isCreatingTransactions,
  isFairValueMngModalOpen,
  close,
  sharePrices,
  fetchSharePrices,
  resetSharePrices,
  isFetchingSharePrices,
  isFetchingBulkVolatilities,
  recordCount,
  fetchAnnualVolatility,
  fetchAnnualVolatilityBulk,
  annualVolatilities,
}) => {
  const [transactionDate, setTransactionDate] = useState<string>(
    moment().format(normalDateFormat)
  );
  const [managementType, setManagementType] = useState<MngTypeEnum>(
    MngTypeEnum.fairValueGrantForCost
  );
  const [expectedLifetimeType, setExpectedLifetimeType] = useState<
    ExpectedLifetimeTypeEnum
  >(ExpectedLifetimeTypeEnum.vestingPlusOneYear);
  const [interestRatesState, setInterestRatesState] = useState<
    InterestRatesState
  >(null);

  const isGrantTypeActive = [
    MngTypeEnum.fairValueGrantForCost,
    MngTypeEnum.fairValueGrantForSocSec,
  ].includes(managementType);

  const isAdjustment = !isGrantTypeActive;

  const isTransactionDateValid = moment(
    transactionDate,
    normalDateFormat,
    true
  ).isValid();

  const overrideEstimatedLiftimeStart = isAdjustment
    ? moment(transactionDate, normalDateFormat, true)
    : undefined;

  const [groupedTranches, setGroupedTranches] = useState<TrancheGroup[]>(
    sortMultipleLevels(
      calcGroupedTranches(
        tranches,
        normalDateFormat,
        expectedLifetimeType,
        overrideEstimatedLiftimeStart
      )
    )("grantDate", "vestedDate")
  );

  useEffect(() => {
    setInterestRatesState({ ...interestRatesState, isFetching: true });
    fetchSP();
    fetchInterestRates()
      .then(ir => {
        setInterestRatesState({
          ...interestRatesState,
          interestRates: ir,
          isFetching: false,
          hasError: false,
        });
      })
      .catch(e => {
        console.log(e);
        setInterestRatesState({
          ...interestRatesState,
          hasError: true,
          isFetching: false,
        });
      });
  }, []);

  useEffect(() => {
    if (isGrantTypeActive) {
      setGroupedTranches(
        sortMultipleLevels(
          calcGroupedTranches(
            tranches,
            normalDateFormat,
            expectedLifetimeType,
            overrideEstimatedLiftimeStart
          )
        )("grantDate", "vestedDate").map(group => ({
          ...group,
          forActionDate: moment(group.grantDate).format(normalDateFormat),
        }))
      );
    } else if (isAdjustment && isTransactionDateValid) {
      setGroupedTranches(
        sortMultipleLevels(
          calcGroupedTranches(
            tranches,
            normalDateFormat,
            expectedLifetimeType,
            overrideEstimatedLiftimeStart
          )
        )("grantDate", "vestedDate").map(group => ({
          ...group,
          forActionDate: transactionDate,
        }))
      );
    }
  }, [managementType, transactionDate, expectedLifetimeType]);

  const fetchSharePricesForDate = originalDate => {
    const date = moment(originalDate, normalDateFormat);
    if (!date.isValid()) {
      return;
    }

    fetchSharePrices({
      filter: {
        date: genConnectedDates(date, 5, "day"),
      },
      page: {
        size: 5,
      },
      sort: "-date,-createdAt",
    });
  };

  const updateGroup = (
    groupIndex: number,
    prop: string,
    value: number | boolean | TransactionKeysAndValues
  ) =>
    setGroupedTranches(
      groupedTranches.map(g =>
        groupIndex === g.groupIndex ? { ...g, [prop]: value } : g
      )
    );

  const includedGroups =
    isAdjustment && !isTransactionDateValid
      ? []
      : groupedTranches.filter(
          group =>
            isFairValueValid(group.transactionKeysAndValues.fairValue) &&
            group.isActive
        );

  const generateTransactions = (
    includedGroups,
    managementType,
    transactionDate,
    normalDateFormat,
    apiShortDate
  ) => {
    if (managementType === MngTypeEnum.fairValueGrantForCost) {
      const fairValueGrantForCostTransactions: Api.V1.Transaction[] = generateFairValueGrantForCostTransactions(
        includedGroups,
        apiShortDate
      );

      updateTransactions(fairValueGrantForCostTransactions);
    } else if (managementType === MngTypeEnum.fairValueAdjustmentForCost) {
      const fairValueAdjustmentForCostTransactions: Array<{
        trancheId: string;
        transaction: Api.V1.Transaction;
      }> = generateFairValueAdjustmentForCostTransactions(
        includedGroups,
        transactionDate,
        normalDateFormat
      );

      createTransactions(fairValueAdjustmentForCostTransactions);
    } else if (managementType === MngTypeEnum.fairValueGrantForSocSec) {
      const fairValueGrantForSocSecTransactions: Api.V1.Transaction[] = generateFairValueGrantForSocSecTransactions(
        includedGroups,
        apiShortDate
      );

      updateTransactions(fairValueGrantForSocSecTransactions);
    } else if (managementType === MngTypeEnum.fairValueAdjustmentForSocSec) {
      const fairValueAdjustmentForSocSecTransactions: Array<{
        trancheId: string;
        transaction: Api.V1.Transaction;
      }> = generateFairValueAdjustmentForSocSecTransactions(
        includedGroups,
        transactionDate,
        normalDateFormat
      );

      createTransactions(fairValueAdjustmentForSocSecTransactions);
    }
  };

  const fetchSP = () => {
    const uniqueDates: string[] = Array.from(
      new Set(groupedTranches.map(group => group.forActionDate))
    );

    resetSharePrices();
    uniqueDates.map(d => fetchSharePricesForDate(d));
  };

  useEffect(() => {
    updateGroupedTranchesWithFairValues(
      groupedTranches,
      sharePrices,
      setGroupedTranches
    );
  }, [sharePrices]);

  useEffect(() => {
    if (interestRatesState && interestRatesState.interestRates) {
      updateGroupedTranchesWithInterestRates(
        interestRatesState.interestRates,
        normalDateFormat,
        groupedTranches,
        setGroupedTranches
      );
    }
  }, [interestRatesState]);

  useEffect(() => {
    setGroupedTranches(
      groupedTranches.map(group => {
        const { startDate, endDate } = group.volatilityPeriod;
        const annualVolatilityData =
          annualVolatilities[[startDate, endDate].join("-")]?.data;
        const annualVolatility = annualVolatilityData
          ? annualVolatilityData.annualVolatility
          : null;
        const volatility =
          annualVolatility || group.transactionKeysAndValues.volatility;
        return {
          ...group,
          transactionKeysAndValues: {
            ...group.transactionKeysAndValues,
            volatility,
          },
        };
      })
    );
  }, [annualVolatilities]);

  const updateGroupInterestRate = (group: TrancheGroup) => {
    if (!interestRatesState.interestRates) {
      return;
    }
    const firstAvailableIRDate = interestRatesState.interestRates[0].date;
    const interestRates = findAvailableIR(
      interestRatesState.interestRates,
      firstAvailableIRDate,
      group.forActionDate,
      normalDateFormat
    );

    updateGroup(group.groupIndex, "transactionKeysAndValues", {
      ...group.transactionKeysAndValues,
      interestRate: changePunctuationForComma(
        calcInterestRate(
          interestRates,
          group.transactionKeysAndValues.expectedLifetime
        ).toString()
      ),
    });
  };

  if (
    isFetchingSharePrices ||
    !interestRatesState ||
    (interestRatesState && interestRatesState.isFetching)
  ) {
    return <SpinnerFullScreen active={true} />;
  }

  const currentQuarterEnd = moment()
    .endOf("quarter")
    .format(norwegianShortDateLongYear);
  const lastQuarterEnd = moment()
    .subtract(1, "quarter")
    .endOf("quarter")
    .format(norwegianShortDateLongYear);
  return (
    <Modal
      open={isFairValueMngModalOpen}
      size="large"
      onClose={close}
      closeIcon={true}
    >
      <Modal.Header className="text-center">
        Fair Value Management | {tranches.length} of {recordCount} tranches
      </Modal.Header>
      <Modal.Content>
        <div className="block-m text-center">
          <Dropdown
            placeholder="Management types"
            style={{ minWidth: 250 }}
            search={true}
            selection={true}
            value={managementType}
            options={managementTypesOptions}
            onChange={(e, { value }) => setManagementType(value as MngTypeEnum)}
          />{" "}
          <Popup
            trigger={
              <Icon name="question circle outline" color="olive" size="large" />
            }
            position="bottom center"
          >
            <p>
              Do not forget to refresh share prices and interest rates after
              changing dropdown option or transaction date.
            </p>
            <p>
              You might need to wait few moments to get share price and interest
              rate inputs prefilled (if available for valuation date and
              expected life-time period).
            </p>
            <p>
              n.b. interest rate is usually not available for today, weekends
              and holidays.
            </p>
          </Popup>
          <div className="space-vertical" />
          {isAdjustment && (
            <>
              <Input
                value={transactionDate}
                onChange={(e, { value }) => setTransactionDate(value)}
                style={{ visibility: isGrantTypeActive && "hidden" }}
                error={!isTransactionDateValid}
              />
              <ul
                style={{
                  display: "inline-block",
                  margin: 0,
                  position: "absolute",
                }}
              >
                <li>
                  <ClickableLink
                    onClick={() => setTransactionDate(currentQuarterEnd)}
                  >
                    {currentQuarterEnd}
                  </ClickableLink>
                </li>
                <li>
                  <ClickableLink
                    onClick={() => setTransactionDate(lastQuarterEnd)}
                  >
                    {lastQuarterEnd}
                  </ClickableLink>
                </li>
              </ul>
            </>
          )}
          <div className="space-vertical" />
          <Dropdown
            style={{ minWidth: 250 }}
            search={true}
            selection={true}
            value={expectedLifetimeType}
            options={expectedLifetimeTypes}
            onChange={(e, { value }) =>
              setExpectedLifetimeType(value as ExpectedLifetimeTypeEnum)
            }
          />
          <div className="space-vertical" />
          <Button
            basic={true}
            icon="refresh"
            content="Refetch share prices"
            loading={isFetchingSharePrices}
            onClick={() => fetchSP()}
          />
          <Button
            basic={true}
            content="Update interest rates"
            style={{
              display:
                interestRatesState && interestRatesState.isFetching && "none",
            }}
            onClick={() =>
              updateGroupedTranchesWithInterestRates(
                interestRatesState.interestRates,
                normalDateFormat,
                groupedTranches,
                setGroupedTranches
              )
            }
          />
          <Button
            basic={true}
            icon="refresh"
            content="Calculate volatility for all"
            loading={isFetchingBulkVolatilities}
            onClick={() =>
              fetchAnnualVolatilityBulk(
                groupedTranches.map(group => ({
                  startDate: group.volatilityPeriod.startDate,
                  endDate: group.volatilityPeriod.endDate,
                  timePeriodInYears: toNumber(
                    group.transactionKeysAndValues.expectedLifetime
                  ),
                }))
              )
            }
          />
          <Button
            basic={true}
            content="Calculate FV for all"
            onClick={() =>
              setGroupedTranches(updateFairValueForGroups(groupedTranches))
            }
          />
        </div>
        {interestRatesState && interestRatesState.hasError && (
          <Message
            compact={true}
            negative={true}
            content="Error while fetching interest rates :("
          />
        )}
        {groupedTranches.map(group => (
          <FairValueManangementGroup
            key={group.groupIndex}
            group={group}
            updateGroup={updateGroup}
            normalDateFormat={normalDateFormat}
            isGrantTypeActive={isGrantTypeActive}
            fetchAnnualVolatility={fetchAnnualVolatility}
            annualVolatilityState={getGroupAnnualVolatilityState(
              group,
              annualVolatilities
            )}
            updateGroupInterestRate={updateGroupInterestRate}
          />
        ))}

        <FairValueManagementSummary
          groupedTranches={groupedTranches}
          includedGroups={includedGroups}
          isGrantTypeActive={isGrantTypeActive}
        />
      </Modal.Content>

      <Modal.Actions className="text-center">
        <Button basic={true} onClick={close} content="Cancel" />
        <Button
          positive={true}
          basic={true}
          onClick={() =>
            generateTransactions(
              includedGroups,
              managementType,
              transactionDate,
              normalDateFormat,
              apiShortDate
            )
          }
          content="Save"
          loading={isCreatingTransactions}
          disabled={!includedGroups.length}
        />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps = ({ award, sharePrice }: RootState) => ({
  isCreatingTransactions: award.isCreatingTransactions,
  sharePrices: sharePrice.filtered,
  isFetchingSharePrices: sharePrice.isFetching,
  isFetchingBulkVolatilities: sharePrice.isFetchingBulkVolatilities,
  annualVolatilities: sharePrice.annualVolatilities,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      fetchSharePrices,
      resetSharePrices,
      fetchAnnualVolatility,
      fetchAnnualVolatilityBulk,
    },
    dispatch
  ),
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(FairValueManangement);
