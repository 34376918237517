import { SharePrice } from "src/employee-portal/exercise/exercise-router";
import { Features } from "src/employee-portal/features/features-reducer";
import { AUTH0_LOGOUT_URL } from "src/env";

const documentsRoute = "/documents";
const sharesTransferRoute = "/transfer";
const orderRoute = "/orders";
const employeePortalHomeRoute = "/overview";
const helpRoute = "/help";
const selfServiceRoute = "/selfservice";
const profileRoute = "/profile";

export interface MenuConfig {
  to: string;
  textKey: string;
  showIfPropIsTrue?: string;
  hideIfPropIsTrue?: string;
  showInDesktopUserMenu?: boolean;
  alwaysShow?: boolean;
  icon?: string;
}

export const menuConfigValues: MenuConfig[] = [
  {
    to: employeePortalHomeRoute,
    textKey: "menu.overview",
    hideIfPropIsTrue: "isAdminUserWithoutInstruments",
    icon: "home",
  },
  {
    to: helpRoute,
    textKey: "menu.support",
    hideIfPropIsTrue: "isAdminUserWithoutInstruments",
    icon: "help",
  },
  {
    to: orderRoute,
    textKey: "menu.orders",
    hideIfPropIsTrue: "isAdminUserWithoutInstruments",
    icon: "numbered list",
  },
  {
    to: documentsRoute,
    textKey: "menu.documents",
    hideIfPropIsTrue: "isAdminUserWithoutInstruments",
    icon: "file alternate",
  },
  {
    to: sharesTransferRoute,
    textKey: "menu.transfer",
    showIfPropIsTrue: "shares_transfer",
    icon: "exchange",
  },
  {
    to: selfServiceRoute,
    textKey: "menu.admin",
    showIfPropIsTrue: "showAdminMenu",
    icon: "dashboard",
  },
  {
    to: profileRoute,
    textKey: "menu.profile",
    hideIfPropIsTrue: "isAdminUserWithoutInstruments",
    showInDesktopUserMenu: true,
    icon: "user",
  },
  {
    to: AUTH0_LOGOUT_URL,
    textKey: "menu.logout",
    showInDesktopUserMenu: true,
    icon: "sign out",
    alwaysShow: true,
  },
];

export interface Args {
  showAdminMenu: boolean;
  adminUserWithoutInstruments: boolean;
  sharePrice: SharePrice;
  logoUrl: string;
  features: Features;
  dateFormat: string;
  userName: string;
  title: string;
}

const useMenuConfig = (props: Args): MenuConfig[] => {
  const keepIfTrue = (menuItem, props) =>
    ("showIfPropIsTrue" in menuItem &&
      props.features[menuItem.showIfPropIsTrue]) ||
    props[menuItem.showIfPropIsTrue] ||
    menuItem.alwaysShow;

  const filerOutIfTrue = (menuItem, props) =>
    "hideIfPropIsTrue" in menuItem && !props[menuItem.hideIfPropIsTrue];

  return menuConfigValues.filter(
    menuItem => keepIfTrue(menuItem, props) || filerOutIfTrue(menuItem, props)
  );
};

export default useMenuConfig;
