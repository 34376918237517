import { createReducer } from "redux-act";

import {
  deleteTenant,
  deleteTenantSucceeded,
  fetchLiveTenantsOverview,
  fetchLiveTenantsOverviewSucceeded,
  fetchTenants,
  fetchTenantsSucceeded,
  postTenantSucceeded,
  putTenant,
  putTenantSucceeded,
  selectTenant,
} from "src/admin-portal/tenant/tenant-actions";

export interface Tenant {
  id?: string;
  name: string;
  employee_portal_title: string;
  logo_url: string;
  bank_account_number?: string;
  bic_number?: string;
  iban_number?: string;
  currency_code: string;
  payment_address?: string;
  comment?: string;
  is_live: boolean;
  feature: TenantFeature;
  report_config_id?: string;
  primary_share_price_ticker_id?: string;
  num_share_price_decimals: number;
}

export interface TenantFeature {
  exercise: boolean;
  documents: boolean;
  purchase: boolean;
  multiple_languages: boolean;
  currency: boolean;
  faq: boolean;
  shares_transfer: boolean;
  share_price_simulation: boolean;
}

export interface UpdateTenant {
  id?: string;
  name?: string;
  logo_url?: string;
  bic_number?: string;
  iban_number?: string;
  feature?: {
    exercise?: boolean;
    documents: boolean;
    purchase?: boolean;
    multiple_languages?: boolean;
    currency?: boolean;
    share_price_simulation?: boolean;
  };
}

export interface TenantState {
  readonly allTenants: Tenant[];
  readonly liveTenantsOverview: Api.V1.TenantOverview[];
  readonly selectedTenant?: Tenant;
  readonly isFetching: boolean;
  readonly isFetchingLiveTenantsOverview: boolean;
}

const tenantFromLocalStorage = (): Tenant | null =>
  JSON.parse(localStorage.getItem("selectedTenant"));

const initialState: TenantState = {
  allTenants: null,
  isFetching: false,
  isFetchingLiveTenantsOverview: false,
  liveTenantsOverview: null,
  selectedTenant: tenantFromLocalStorage(),
};

export default createReducer(on => {
  on(fetchTenants, state => ({ ...state, ...{ isFetching: true } }));
  on(fetchTenantsSucceeded, (state, payload) => ({
    ...state,
    ...{ allTenants: payload },
    isFetching: false,
  }));
  on(fetchLiveTenantsOverview, state => ({
    ...state,
    isFetchingLiveTenantsOverview: true,
  }));
  on(fetchLiveTenantsOverviewSucceeded, (state, payload) => ({
    ...state,
    isFetchingLiveTenantsOverview: false,
    liveTenantsOverview: payload,
  }));
  on(selectTenant, (state, payload) => {
    const tenant = state.allTenants.filter(t => t.id === payload)[0];
    return { ...state, ...{ selectedTenant: tenant } };
  });
  on(postTenantSucceeded, (state, payload) => ({
    ...state,
    allTenants: [...state.allTenants, payload],
  }));
  on(putTenant, state => ({ ...state, ...{ isFetching: true } }));
  on(putTenantSucceeded, (state, payload) => {
    if (state.selectedTenant.id) {
      const allTenants = state.allTenants.map(tenant =>
        tenant.id === payload.id ? payload : tenant
      );
      return {
        ...state,
        allTenants,
        isFetching: false,
        selectedTenant: payload,
      };
    }
  });
  on(deleteTenant, state => ({ ...state, ...{ isFetching: true } }));
  on(deleteTenantSucceeded, (state, payload) => {
    const allTenants = state.allTenants.filter(tenant => tenant.id !== payload);
    return { ...state, allTenants: [...allTenants], isFetching: false };
  });
}, initialState);
