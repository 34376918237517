import React, { Component } from "react";
import { connect, MapStateToProps } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import "src/common/less/banner.less";
import { PurchaseWindow, Window } from "src/common/data/data";
import { purchaseRoute } from "src/employee-portal/employee-portal-router";
import {
  getPurchaseInfoForCurrentWindow,
  getPurchaseInfoForNextWindow,
  PurchaseInfo,
} from "src/employee-portal/purchase/duck/purchase-selectors";
import { RootState } from "src/reducers/all-reducers";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface Props {
  currentWindow: PurchaseWindow;
  nextWindow: Window;
  hasFeature: boolean;
  dateFormat: DateFormatState;
  purchaseInfo: PurchaseInfo;
  purchaseInfoNextWindow: PurchaseInfo;
}

class PurchaseBanner extends Component<Props, null> {
  public render() {
    const {
      currentWindow,
      nextWindow,
      hasFeature,
      dateFormat,
      purchaseInfo,
      purchaseInfoNextWindow,
    } = this.props;

    if (!hasFeature) {
      return null;
    }

    if (!purchaseInfo || !this.hasInstrumentsToPurchase()) {
      if (purchaseInfoNextWindow && nextWindow) {
        return (
          <div className="banner">
            <Icon
              name="info circle"
              inverted={true}
              className="banner-icon"
              size="big"
            />
            <Content
              id="banner.purchase.next.message"
              values={{
                fromDate: nextWindow.from.format(dateFormat.dateTimeFormat),
                toDate: nextWindow.to.format(dateFormat.dateTimeFormat),
              }}
            />
          </div>
        );
      }

      return null;
    }

    return (
      <div className="banner">
        <Icon
          name="info circle"
          inverted={true}
          className="banner-icon"
          size="big"
        />
        <Content
          id="banner.purchase.current.message"
          values={{ date: currentWindow.to.format(dateFormat.dateTimeFormat) }}
        />
        <Button
          className="banner-button"
          color={"green"}
          as={Link}
          to={purchaseRoute}
        >
          <Content id="banner.purchase.current.button" />
        </Button>
      </div>
    );
  }

  private hasInstrumentsToPurchase = () =>
    this.props.purchaseInfo &&
    (this.props.purchaseInfo.purchasableQuantity > 0 ||
      this.props.purchaseInfo.purchasableCashAmount > 0);
}

const mapStateToProps: MapStateToProps<Props, {}, RootState> = (
  state
): Props => ({
  currentWindow: state.user.currentPurchaseWindow,
  nextWindow: state.user.nextPurchaseWindow,
  hasFeature: state.features.purchase,
  purchaseInfo: getPurchaseInfoForCurrentWindow(state),
  purchaseInfoNextWindow: getPurchaseInfoForNextWindow(state),
  dateFormat: state.dateFormat,
});

export default connect(mapStateToProps)(PurchaseBanner);
