import React, { FunctionComponent } from "react";

import Content from "src/admin-portal/texts/content";
import ClickableLink from "src/common/components/clickable-link";

export const PurchaseSetMinMax: FunctionComponent<{
  min: number;
  max: number;
  onChange: (newValue: string) => void;
}> = ({ min, max, onChange }) => (
  <div className="text-center purchase-box-input-action-panel">
    <ul className="horizontal-list">
      <li>
        <ClickableLink onClick={() => onChange(min.toString())}>
          <Content id={"purchase.quantity.setmin.label"} />
        </ClickableLink>
      </li>
      <li>
        <ClickableLink onClick={() => onChange(max.toString())}>
          <Content id={"purchase.quantity.setmax.label"} />
        </ClickableLink>
      </li>
    </ul>
  </div>
);
