import stringify from "jquery-param";
import { round, pickBy, get } from "lodash";
import moment, { DurationInputArg2, Moment } from "moment";
import numeral from "numeral";
import { match } from "react-router";

import { Employee } from "src/admin-portal/employees/employee-reducer";
import { API_ROOT } from "src/env";

const stringOfZeros = (numberOfZeros: number) =>
  Array(numberOfZeros)
    .fill("0")
    .join("");

export const toFixedAndRemoveScientificNotation = (x): string | number => {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
};

const createCurrencyFormat = (decimals: number) =>
  decimals > 0 ? `0,0.${stringOfZeros(decimals)}` : "0,0";

export const createFormatCurrencyFunction = (
  currencyCode: string,
  abbriviate = false
) => (number: number, decimals?: number): string =>
  `${currencyCode} ${numeral(number).format(
    `${createCurrencyFormat(decimals || 0)}${abbriviate ? "a" : ""}`
  )}`;
export const createFormatSharePriceFunction = (currencyCode: string) => (
  number: number
): string =>
  `${currencyCode} ${numeral(number).format(createCurrencyFormat(3))}`;

export const formatNumber = (number: number, decimal?: number) =>
  decimal
    ? `${numeral(number).format("0,0")} ${decimal}`
    : numeral(number).format();

export const formatCurrencyConversionFactor = (number: number) =>
  numeral(number).format("0.000000");

export const formatPercentage = (number: number | string, decimals = 0) =>
  numeral(typeof number === "number" ? number : parseFloat(number)).format(
    `0.${stringOfZeros(decimals)} %`
  );
export const formatCurrency = (number: number, currency?: string) =>
  currency
    ? `${numeral(number).format("0,0.000")} ${currency}`
    : numeral(number).format("0,0.000 $");
export const formatCurrency2Decimals = (number: number, currency?: string) =>
  currency
    ? `${numeral(number).format("0,0.00")} ${currency}`
    : numeral(number).format("0,0.00 $");
export const formatSharePrice = (number: number, currency?: string) =>
  currency
    ? `${numeral(number).format("0,0.000")} ${currency}`
    : numeral(number).format("0,0.000 $");

export const formatDate = (date: string | Moment, dateFormat: string) =>
  moment(date, dateFormat).format(dateFormat);
export const formatShortDate = (date: string | Moment) =>
  moment(date).format(norwegianShortDate);
export const formatLongDate = (date: string | Moment) =>
  moment(date).format("ll");

// For use in reduce functions to sum the value of a property
export const sum = propertyKey => (accumulator, previous) =>
  get(previous, propertyKey) + accumulator;
export const sumNumbers = (accumulator, previous) => previous + accumulator;

export const flatten = (list: any[]) =>
  list.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);

export const flattenOneLevel = (list: any[]) =>
  list.reduce((a, b) => a.concat(b), []);

export const setAllValues = (keys: string[], value: any): object =>
  keys.reduce((acc, key) => ({ ...acc, [key]: value }), {});

export const samePropValCounter = (
  arr,
  prop: string
): { [key: string]: number } =>
  arr.reduce((acc, next) => {
    if (!acc[next[prop]]) {
      acc[next[prop]] = 1;
    } else {
      acc[next[prop]]++;
    }
    return acc;
  }, {});

export const norwegianShortDate = "DD.MM.YY";
export const norwegianShortDateLongYear = "DD.MM.YYYY";
export const norwegianShortDateLongYearHours = "DD.MM.YYYY HH:mm";
export const norwegianShortDateLongYearHoursSeconds = "DD.MM.YYYY HH:mm:ss";
export const apiShortDate = "YYYY-MM-DD";

export const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const removeDuplicates = (array, property) =>
  array.filter(
    (obj, pos, arr) =>
      arr.map(mapObj => mapObj[property]).indexOf(obj[property]) === pos
  );

export const removeNulls = (element: any): boolean => element !== null;
export const removeEmptyArrays = (array: [any]): boolean => array.length > 0;

export const notNullOrUndefined = (element: any): boolean =>
  !nullOrUndefined(element);
export const nullOrUndefined = (element: any): boolean =>
  element === null || element === undefined;

export const changePunctuationForComma = (src: string | number) =>
  `${src}`.replace(".", ",");
export const changeCommaForPunctuation = (src: string | number) =>
  `${src}`.replace(",", ".");

export const maxTwoDecimals = (value: string) =>
  value.includes(".")
    ? value.substr(0, value.indexOf(".")) + value.substr(value.indexOf("."), 3)
    : value;

export const prepareDateForBackend = (
  date: string | Moment,
  dateFormat?: string
) => moment(date, dateFormat).format(apiShortDate);

export const getDateDiff = (
  dateA: Moment | string,
  dateB: Moment | string,
  timeUnit: DurationInputArg2
) => {
  if (moment.isMoment(dateA) && moment.isMoment(dateB)) {
    return dateA.diff(dateB, timeUnit, true);
  } else {
    return moment(dateA).diff(moment(dateB), timeUnit, true);
  }
};

const ROUNDING_PRECISION = 0.000001;
export const checkCloseToZeroValue = (value: number) =>
  Math.abs(value) < ROUNDING_PRECISION ? 0 : value;

// To round numbers like 1.44000000001 to 1.44
export const simpleRound = (value: number, decimals = 3) => {
  return round(value, decimals);
};

export const decimalToString = (number: number, decimals: number) =>
  changePunctuationForComma(number ? number.toFixed(decimals) : "");
export const twoDecimals = (number: number) => decimalToString(number, 2);

export const employeeName = (employee: Employee | Api.V1.Employee) =>
  `${employee.firstName} ${employee.lastName}`;

export const employeeNameForSnakeCaseAttr = (employee: {
  first_name: string;
  last_name: string;
}) => `${employee.first_name} ${employee.last_name}`;

export const routedEmployeeFromList = (
  match: match<{ employeeId: string }>,
  employees: Api.V1.Employee[]
) => employees.filter(e => e.id === match.params.employeeId)[0];

export const sortAlphabetically = (property: string) => (
  objectA: any,
  objectB: any
) => {
  const a = objectA[property];
  const b = objectB[property];

  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const replaceAll = (string, search, replacement) =>
  string.split(search).join(replacement);

export const yesOrNo = (value: boolean): string => (value ? "Yes" : "No");

export const buildURL = (url: string, params: Record<string, any> = {}) => {
  const searchParams = stringify(pickBy(params));
  const searchParamsResult = searchParams == "" ? "" : `?${searchParams}`;
  return `${url}${searchParamsResult}`;
};

export const toNumber = (numberString: string): number =>
  Number(changeCommaForPunctuation(numberString));

export const toNumberOrNull = (numberString: string): number | null => {
  const maybeNumber = parseFloat(changeCommaForPunctuation(numberString));
  return isNaN(maybeNumber) ? null : maybeNumber;
};

export const toNumberOrDefault = (
  numberString: string,
  defaultNumber: number
): number =>
  isNaN(parseFloat(numberString)) ? defaultNumber : parseFloat(numberString);

export const cloneDeepSimple = (
  objectOrArray: any[] | object
): any[] | object => JSON.parse(JSON.stringify(objectOrArray));

export const validateWholeNumber = (numberString: string) =>
  /^\d+$/.test(numberString);

export const approximately = (value: string) => `≈ ${value}`;

export const filterByField = (
  data: any[],
  field: string,
  filter: string,
  options?: { ignoreCase?: boolean; preserveNulls?: boolean }
) => {
  const filteredWithNulls = data.map(d => {
    return options.ignoreCase
      ? d[field]
          .toString()
          .toLowerCase()
          .includes(filter.toLowerCase())
        ? d
        : null
      : d[field].toString().includes(filter)
      ? d
      : null;
  });

  return options.preserveNulls
    ? filteredWithNulls
    : filteredWithNulls.filter(removeNulls);
};

export const uploadedFileDownloadUrl = (document: Api.V1.Document) =>
  `${API_ROOT}/uploaded-files/${document.id}/download?download_token=${document.downloadToken}`;

export const washAccountNumber = (shareDepo: string) =>
  replaceAll(replaceAll(shareDepo, " ", ""), ".", "");
