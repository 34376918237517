import { History } from "history";
import { Moment } from "moment";
import queryString from "query-string";
import React, { FunctionComponent } from "react";
import { Button, Dropdown, Form, Input, Transition } from "semantic-ui-react";

import { Entity } from "src/admin-portal/entity/entity-reducer";
import { instrumentOptions } from "src/admin-portal/programs/program-utils";
import { State as TranchesPageState } from "src/admin-portal/tranches-page/tranches-page";
import {
  emptyOptionForDropdown,
  generateEntitiesOptions,
  generateProgramsOptions,
  generateStatusOptions,
  generateSubProgramsOptions,
} from "src/admin-portal/tranches-page/utils";
import DateRange from "src/common/components/date-range";
import NumberRange from "src/common/components/number-range";
import { generateSupportedLanguagesOptions } from "src/common/utils/locale-utils";
import { apiShortDate } from "src/common/utils/utils";

interface Props {
  fromTranchesPageState: TranchesPageState;
  programs: Api.V1.IncentiveProgram[];
  isSysadmin: boolean;
  dateFormat: string;
  entities: Entity[];
  history: History;
  baseUrl: string;
  searchFromUrl;
  handleDateRangeChangeStart: (whichStateKey: string, m: Moment) => void;
  handleDateRangeChangeEnd: (whichStateKey: string, m: Moment) => void;
  handleNumberRangeChange: (
    whichStateKey: string,
    from: number,
    to: number
  ) => void;
  handleChange: (event, object: { value: string; name: string }) => void;
  resetFilter: () => void;
}

const TrancheFilters: FunctionComponent<Props> = ({
  fromTranchesPageState,
  isSysadmin,
  programs,
  entities,
  dateFormat,
  handleNumberRangeChange,
  handleDateRangeChangeStart,
  handleDateRangeChangeEnd,
  handleChange,
  resetFilter,
  history,
  baseUrl,
  searchFromUrl,
}) => {
  const filters = {
    status: fromTranchesPageState.status,
    fairValue: fromTranchesPageState.fair_value,
    "award.employee.name": fromTranchesPageState.name,
    "award.employee.entityId": fromTranchesPageState.entity_id,
    "award.employee.locale": fromTranchesPageState.locale,
    "award.incentiveSubProgramId": fromTranchesPageState.subProgramId,
    "award.incentiveSubProgram.incentiveProgramId":
      fromTranchesPageState.program_id,
    "award.incentiveSubProgram.instrumentTypeId":
      fromTranchesPageState.instrumentType,
    strikeGteq: fromTranchesPageState.strikeNumberRange.from,
    strikeLteq: fromTranchesPageState.strikeNumberRange.to,
    quantityGteq: fromTranchesPageState.quantityNumberRange.from,
    quantityLteq: fromTranchesPageState.quantityNumberRange.to,
    exercisedQuantityGteq: fromTranchesPageState.exercisedQuantityNumberRange.to
      ? fromTranchesPageState.exercisedQuantityNumberRange.to * -1
      : null,
    exercisedQuantityLteq: fromTranchesPageState.exercisedQuantityNumberRange
      .from
      ? -1 * fromTranchesPageState.exercisedQuantityNumberRange.from
      : null,
    releasedQuantityGteq: fromTranchesPageState.releasedQuantityNumberRange.to
      ? fromTranchesPageState.releasedQuantityNumberRange.to * -1
      : null,
    releasedQuantityLteq: fromTranchesPageState.releasedQuantityNumberRange.from
      ? -1 * fromTranchesPageState.releasedQuantityNumberRange.from
      : null,
    expiredQuantityGteq: fromTranchesPageState.expiredQuantityNumberRange.to
      ? -1 * fromTranchesPageState.expiredQuantityNumberRange.to
      : null,
    expiredQuantityLteq: fromTranchesPageState.expiredQuantityNumberRange.from
      ? -1 * fromTranchesPageState.expiredQuantityNumberRange.from
      : null,
    terminationQuantityGteq: fromTranchesPageState
      .terminationQuantityNumberRange.to
      ? -1 * fromTranchesPageState.terminationQuantityNumberRange.to
      : null,
    terminationQuantityLteq: fromTranchesPageState
      .terminationQuantityNumberRange.from
      ? -1 * fromTranchesPageState.terminationQuantityNumberRange.from
      : null,
    grantDateGteq:
      fromTranchesPageState.grantDateRange.startDate &&
      fromTranchesPageState.grantDateRange.startDate.format(apiShortDate),
    grantDateLteq:
      fromTranchesPageState.grantDateRange.endDate &&
      fromTranchesPageState.grantDateRange.endDate.format(apiShortDate),
    vestedDateGteq:
      fromTranchesPageState.vestingDateRange.startDate &&
      fromTranchesPageState.vestingDateRange.startDate.format(apiShortDate),
    vestedDateLteq:
      fromTranchesPageState.vestingDateRange.endDate &&
      fromTranchesPageState.vestingDateRange.endDate.format(apiShortDate),
    expiryDateGteq:
      fromTranchesPageState.expiryDateRange.startDate &&
      fromTranchesPageState.expiryDateRange.startDate.format(apiShortDate),
    expiryDateLteq:
      fromTranchesPageState.expiryDateRange.endDate &&
      fromTranchesPageState.expiryDateRange.endDate.format(apiShortDate),
  };

  return (
    <Transition
      visible={fromTranchesPageState.areFiltersVisible}
      animation="slide down"
      duration={350}
    >
      <div className="filters-container">
        <Form
          onSubmit={() => {
            history.push(
              `${baseUrl}?${queryString.stringify({
                ...filters,
                pageNum: 1,
                pageSize: 50,
                sortBy: searchFromUrl.sortBy,
              })}`
            );
          }}
        >
          <div className="filter-box" key="firstRow">
            <div className="item-box">
              <label>Name</label>
              <Input
                fluid={true}
                onChange={handleChange}
                placeholder="Name"
                name="name"
                value={fromTranchesPageState.name}
                autoComplete="off"
                style={{ marginTop: 5 }}
              />
            </div>
            <div className="item-box">
              <label>Entity</label>
              <Dropdown
                placeholder="Select Entity"
                fluid={true}
                search={true}
                selection={true}
                options={generateEntitiesOptions(entities)}
                value={fromTranchesPageState.entity_id}
                onChange={handleChange}
                name="entity_id"
              />
            </div>
            <div className="item-box">
              <label>Locale</label>
              <Dropdown
                placeholder="Select Locale"
                fluid={true}
                search={true}
                selection={true}
                options={[
                  emptyOptionForDropdown,
                  ...generateSupportedLanguagesOptions(),
                ]}
                value={fromTranchesPageState.locale}
                onChange={handleChange}
                name="locale"
              />
            </div>
            <div className="item-box">
              <label>Program</label>
              <Dropdown
                placeholder="Select Program"
                fluid={true}
                search={true}
                selection={true}
                options={generateProgramsOptions(programs || [])}
                value={fromTranchesPageState.program_id}
                onChange={handleChange}
                name="program_id"
              />
            </div>
            <div className="item-box">
              <label>Subprogram</label>
              <Dropdown
                placeholder="Select Subprogram"
                fluid={true}
                search={true}
                selection={true}
                options={generateSubProgramsOptions(
                  programs || [],
                  fromTranchesPageState.program_id
                )}
                value={fromTranchesPageState.subProgramId}
                onChange={handleChange}
                name="subProgramId"
              />
            </div>
          </div>

          <div className="filter-box" key="secondRow">
            <div className="item-box">
              {isSysadmin && (
                <>
                  <label>Fair Value</label>
                  <Input
                    fluid={true}
                    onChange={handleChange}
                    placeholder="Fair Value"
                    name="fair_value"
                    value={fromTranchesPageState.fair_value}
                    autoComplete="off"
                    style={{ marginTop: 5, marginBottom: 8 }}
                  />
                </>
              )}
              <label>Instrument Type</label>
              <Dropdown
                placeholder="Select Instrument T."
                fluid={true}
                search={true}
                selection={true}
                options={[emptyOptionForDropdown, ...instrumentOptions]}
                value={fromTranchesPageState.instrumentType}
                onChange={handleChange}
                name="instrumentType"
              />
            </div>

            <div className="item-box">
              <DateRange
                whichStateKey="grantDateRange"
                startLabel="Grant Date Start"
                endLabel="Grant Date End"
                startDate={fromTranchesPageState.grantDateRange.startDate}
                endDate={fromTranchesPageState.grantDateRange.endDate}
                dateFormat={dateFormat}
                handleDateRangeChangeStart={handleDateRangeChangeStart}
                handleDateRangeChangeEnd={handleDateRangeChangeEnd}
              />
            </div>

            <div className="item-box">
              <DateRange
                whichStateKey="vestingDateRange"
                startLabel="Vesting Date Start"
                endLabel="Vesting Date End"
                startDate={fromTranchesPageState.vestingDateRange.startDate}
                endDate={fromTranchesPageState.vestingDateRange.endDate}
                dateFormat={dateFormat}
                handleDateRangeChangeStart={handleDateRangeChangeStart}
                handleDateRangeChangeEnd={handleDateRangeChangeEnd}
              />
            </div>

            <div className="item-box">
              <DateRange
                whichStateKey="expiryDateRange"
                startLabel="Expiry Date Start"
                endLabel="Expiry Date End"
                startDate={fromTranchesPageState.expiryDateRange.startDate}
                endDate={fromTranchesPageState.expiryDateRange.endDate}
                dateFormat={dateFormat}
                handleDateRangeChangeStart={handleDateRangeChangeStart}
                handleDateRangeChangeEnd={handleDateRangeChangeEnd}
              />
            </div>

            <div className="item-box">
              <label>Status</label>
              <Dropdown
                placeholder="Select Status"
                fluid={true}
                search={true}
                selection={true}
                options={generateStatusOptions()}
                value={fromTranchesPageState.status}
                onChange={handleChange}
                name="status"
              />
            </div>

            <div className="item-box">
              <NumberRange
                whichStateKey="strikeNumberRange"
                fromLabel="Strike From"
                toLabel="Strike To"
                from={fromTranchesPageState.strikeNumberRange.from}
                to={fromTranchesPageState.strikeNumberRange.to}
                handleNumberRangeChange={handleNumberRangeChange}
              />
            </div>

            <div className="item-box">
              <NumberRange
                whichStateKey="quantityNumberRange"
                fromLabel="Quantity From"
                toLabel="Quantity To"
                from={fromTranchesPageState.quantityNumberRange.from}
                to={fromTranchesPageState.quantityNumberRange.to}
                handleNumberRangeChange={handleNumberRangeChange}
              />
            </div>

            <div className="item-box">
              <NumberRange
                whichStateKey="exercisedQuantityNumberRange"
                fromLabel="Exercised Quantity From"
                toLabel="Exercised Quantity To"
                from={fromTranchesPageState.exercisedQuantityNumberRange.from}
                to={fromTranchesPageState.exercisedQuantityNumberRange.to}
                handleNumberRangeChange={handleNumberRangeChange}
              />
            </div>

            <div className="item-box">
              <NumberRange
                whichStateKey="releasedQuantityNumberRange"
                fromLabel="Released Quantity From"
                toLabel="Released Quantity To"
                from={fromTranchesPageState.releasedQuantityNumberRange.from}
                to={fromTranchesPageState.releasedQuantityNumberRange.to}
                handleNumberRangeChange={handleNumberRangeChange}
              />
            </div>

            <div className="item-box">
              <NumberRange
                whichStateKey="expiredQuantityNumberRange"
                fromLabel="Expired Quantity From"
                toLabel="Expired Quantity To"
                from={fromTranchesPageState.expiredQuantityNumberRange.from}
                to={fromTranchesPageState.expiredQuantityNumberRange.to}
                handleNumberRangeChange={handleNumberRangeChange}
              />
            </div>

            <div className="item-box">
              <NumberRange
                whichStateKey="terminationQuantityNumberRange"
                fromLabel="Termination Quantity From"
                toLabel="Termination Quantity To"
                from={fromTranchesPageState.terminationQuantityNumberRange.from}
                to={fromTranchesPageState.terminationQuantityNumberRange.to}
                handleNumberRangeChange={handleNumberRangeChange}
              />
            </div>

            <div className="item-box">
              <Button
                type="submit"
                style={{ marginTop: 25, marginBottom: 10 }}
                icon="filter"
                basic={true}
                color="green"
                content="Apply Filters"
                disabled={!fromTranchesPageState.hasFilter}
              />
              <Button
                icon="close"
                basic={true}
                color="yellow"
                onClick={resetFilter}
                content="Reset Filters"
              />
            </div>
          </div>
        </Form>
      </div>
    </Transition>
  );
};

export default TrancheFilters;
