import React, { FunctionComponent, useEffect } from "react";
import Loadable from "react-loadable";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { match, Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";

import { Tenant } from "src/admin-portal/tenant/tenant-reducer";
import { IStringStringMap } from "src/admin-portal/texts/employee-portal-texts-reducer";
import { SupportedLocale } from "src/admin-portal/texts/text-reducer";
import { KEEP_ALIVE_ACTION } from "src/common/auth/keep-alive-saga";
import IntlProviderWithContext from "src/common/components/intl-provider-with-context";
import InvertedRoute from "src/common/components/inverted-route";
import SessionExpirationModal from "src/common/components/session-expiration-modal";
import SpinnerFullScreen from "src/common/components/spinner-full-screen";
import SpinnerInline from "src/common/components/spinner-inline.tsx";
import { Window } from "src/common/data/data";
import { useMomentEffect } from "src/common/hooks/use-moment-effect";
import AcceptDocumentsModal from "src/employee-portal/documents/accept-documents/accept-documents-modal";
import DocumentsPage from "src/employee-portal/documents/view-documents/documents-page";
import DownloadFilePage from "src/employee-portal/download";
import { currentExerciseWindow } from "src/employee-portal/employee-portal-selectors";
import ErrorFetchingWelcomeData from "src/employee-portal/error/error-fetching-welcome-data";
import NoSharePriceError from "src/employee-portal/error/no-share-price-error";
import ExerciseBanner from "src/employee-portal/exercise/common/exercise-banner";
import {
  default as ExerciseRouter,
  SharePrice,
} from "src/employee-portal/exercise/exercise-router";
import { Features } from "src/employee-portal/features/features-reducer";
import Overview from "src/employee-portal/home-page/overview";
import InstrumentsPage from "src/employee-portal/instrument-page/instruments-page";
import EmployeePortalMenu from "src/employee-portal/menu";
import OrderPage from "src/employee-portal/order/order-page";
import ProfilePage from "src/employee-portal/profile/profile-page";
import PurchaseAvailableModal from "src/employee-portal/purchase/purchase-available-modal";
import PurchaseBanner from "src/employee-portal/purchase/purchase-banner";
import PurchaseRouter from "src/employee-portal/purchase/purchase-router";
import SharePriceSimulationModal from "src/employee-portal/SharePriceSimulation";
import FAQPage from "src/employee-portal/support/faq-page";
import EventsTimelinePage from "src/employee-portal/timeline/events-timeline-page";
import TransferPage from "src/employee-portal/transfer/transfer-page";
import { RootState } from "src/reducers/all-reducers";

export const helpRoute = `/help`;
export const documentsRoute = "/documents";
export const exerciseRoute = "/exercise";
export const purchaseRoute = "/purchase";
export const purchasePopupRoute = "/purchasepopup";
export const orderRoute = "/orders";
export const sharePriceSimulationRoute = "/simulation";

const LoadableSelfServiceRouter = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'self-service-portal' */ "src/self-service-portal/self-service-router"
    ),
  LoadingComponent: () => <SpinnerFullScreen active={true} />,
});
interface StateProps {
  pathname: string;
  isFetchingInitialData: boolean;
  currentExerciseWindow?: Window;
  currentPurchaseWindow?: Window;
  features: Features;
  errorFetchingWelcomeData?: boolean;
  sharePrice: SharePrice;
  tenant: Tenant;
  messages: IStringStringMap;
  locale: SupportedLocale;
}

interface DispatchProps {
  fetchWelcomeData: () => void;
  resetSession: () => void;
}

interface Props {
  companyName: string;
  match: match<{}>;
  InjectedIntl;
}

const EmployeePortalRouter: FunctionComponent<StateProps &
  Props &
  DispatchProps> = ({
  isFetchingInitialData,
  errorFetchingWelcomeData,
  pathname,
  companyName,
  sharePrice,
  fetchWelcomeData,
  currentExerciseWindow,
  currentPurchaseWindow,
  features,
  locale,
  messages,
  tenant,
}) => {
  useMomentEffect(locale);

  useEffect(() => {
    if (!sharePrice) {
      fetchWelcomeData();
    }
  }, []);

  if (errorFetchingWelcomeData) {
    return (
      <ErrorFetchingWelcomeData
        pathname={pathname}
        refreshHandler={fetchWelcomeData}
      />
    );
  }

  if (isFetchingInitialData) {
    return <SpinnerInline active={true} />;
  }

  if (!sharePrice) {
    return <NoSharePriceError />;
  }

  return (
    <IntlProviderWithContext locale={locale} messages={messages}>
      <SessionExpirationModal />
      <EmployeePortalMenu />
      <InvertedRoute
        showOnEveryRouteExcept={[exerciseRoute, helpRoute]}
        render={() => <ExerciseBanner />}
      />
      <InvertedRoute
        showOnEveryRouteExcept={[purchaseRoute, purchasePopupRoute, helpRoute]}
        render={() => <PurchaseBanner />}
      />
      <Switch>
        <Route
          path={`/timeline`}
          render={({ match }) => <EventsTimelinePage />}
        />
        <Route
          path={`/download/:documentId`}
          render={routeProps => (
            <DownloadFilePage routeComponentProps={routeProps} />
          )}
        />
        <Route
          path={orderRoute}
          render={({ match, location }) => <OrderPage />}
        />
        <Route
          path={`/instruments`}
          render={({ match }) => <InstrumentsPage match={match} />}
        />
        <Route
          path={exerciseRoute}
          render={({ match }) => (
            <ExerciseRouter
              window={currentExerciseWindow}
              match={match}
              sharePrice={sharePrice}
            />
          )}
        />
        <Route
          path={purchaseRoute}
          render={({ match }) => (
            <PurchaseRouter window={currentPurchaseWindow} match={match} />
          )}
        />
        <Route
          path={helpRoute}
          render={({ match }) => <FAQPage tenant={tenant} />}
        />
        <Route path={`/transfer`} render={({ match }) => <TransferPage />} />
        <Route
          path={`/selfservice`}
          render={routeComponentProps => (
            <LoadableSelfServiceRouter {...routeComponentProps} />
          )}
        />
        <Route path={`/profile`} render={({ match }) => <ProfilePage />} />
        {features.documents && (
          <Route
            path={documentsRoute}
            render={({ match }) => <DocumentsPage />}
          />
        )}
        <Route
          path="/purchasepopup"
          render={({ match }) => <PurchaseAvailableModal />}
        />
        <Route
          path="/acceptdocuments"
          render={({ match }) => <AcceptDocumentsModal />}
        />
        <Route
          path="/overview"
          render={() => <Overview companyName={companyName} />}
        />
        <Route
          path={sharePriceSimulationRoute}
          exact={true}
          render={({ history }) => (
            <SharePriceSimulationModal goBack={history.goBack} />
          )}
        />
        <Redirect to="/overview" />
      </Switch>
    </IntlProviderWithContext>
  );
};

const mapStateToProps: MapStateToProps<StateProps, Props, RootState> = (
  state
): StateProps => ({
  isFetchingInitialData:
    state.instrument.isFetchingWelcomeData ||
    !state.employeePortalTexts.messages,
  errorFetchingWelcomeData: state.instrument.errorFetchingWelcomeData,
  currentExerciseWindow: currentExerciseWindow(state),
  currentPurchaseWindow: state.user.currentPurchaseWindow,
  sharePrice: state.instrument.sharePrice,
  tenant: state.user.tenant,
  features: state.features,
  locale: state.employeePortalProfile.locale,
  messages: state.employeePortalTexts.messages,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, Props> = (
  dispatch
): DispatchProps => ({
  fetchWelcomeData: () => dispatch({ type: "FETCH_EMPLOYEE_PORTAL_WELCOME" }),
  resetSession: () => dispatch({ type: KEEP_ALIVE_ACTION }),
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(EmployeePortalRouter);
