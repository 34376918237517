import React, { FunctionComponent } from "react";
import { Form, Icon, Popup } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import {
  formatMessageWithDebugger,
  useFormatMessage,
} from "src/admin-portal/texts/utils";

interface Props {
  share_depository_account?: string;
  share_depository_bank?: string;
  share_depository_contact?: string;
  share_depository_description?: string;
  handleChange: (event) => void;
}

const ShareDepoForm: FunctionComponent<Props> = ({
  share_depository_account,
  share_depository_bank,
  share_depository_contact,
  share_depository_description,
  handleChange,
}) => {
  const formatMessage = useFormatMessage();
  return (
    <>
      <Form.Input
        label={
          <label>
            <Content id="profile.share.depository.account.label" />
          </label>
        }
        name="share_depository_account"
        value={share_depository_account || ""}
        placeholder={formatMessageWithDebugger(
          "profile.share.depository.account.placeholder",
          formatMessage
        )}
        onChange={handleChange}
      />
      <Form.Input
        label={
          <label>
            <Content id="profile.share.depository.bank.label" />
            <Popup
              trigger={<Icon name="question circle outline" />}
              content={
                <Content id="profile.share.depository.bank.label.popup" />
              }
            />
          </label>
        }
        name="share_depository_bank"
        value={share_depository_bank || ""}
        placeholder={formatMessageWithDebugger(
          "profile.share.depository.bank.placeholder",
          formatMessage
        )}
        onChange={handleChange}
      />
      <Form.TextArea
        label={
          <label>
            <Content id="profile.share.depository.contact.label" />
            <Popup
              trigger={<Icon name="question circle outline" />}
              content={
                <Content id="profile.share.depository.contact.label.popup" />
              }
            />
          </label>
        }
        name="share_depository_contact"
        value={share_depository_contact || ""}
        placeholder={formatMessageWithDebugger(
          "profile.share.depository.contact.placeholder",
          formatMessage
        )}
        onChange={handleChange}
      />
      <Form.TextArea
        label={
          <label>
            <Content id="profile.share.depository.description.label" />
            <Popup
              trigger={<Icon name="question circle outline" />}
              content={
                <Content id="profile.share.depository.description.label.popup" />
              }
            />
          </label>
        }
        name="share_depository_description"
        value={share_depository_description || ""}
        placeholder={formatMessageWithDebugger(
          "profile.share.depository.description.placeholder",
          formatMessage
        )}
        onChange={handleChange}
      />
    </>
  );
};

export default ShareDepoForm;
