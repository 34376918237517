import React, { FunctionComponent } from "react";

import { Window } from "src/common/data/data";
import { Features } from "src/employee-portal/features/features-reducer";
import ConvertibleLoanSection from "src/employee-portal/home-page/instrument-overview-box/convertible-loans-section";
import OptionSection from "src/employee-portal/home-page/instrument-overview-box/option-section";
import PSUSection from "src/employee-portal/home-page/instrument-overview-box/psu-section";
import RSASection from "src/employee-portal/home-page/instrument-overview-box/rsa-section";
import RSUSection from "src/employee-portal/home-page/instrument-overview-box/rsu-section";
import ShareSection from "src/employee-portal/home-page/instrument-overview-box/shares-section";
import SubscriptionRightsSection from "src/employee-portal/home-page/instrument-overview-box/subscription-rights-section";
import WarrantSection from "src/employee-portal/home-page/instrument-overview-box/warrant-section";
import {
  IndividualInstrumentState,
  InstrumentState,
} from "src/employee-portal/instrument-page/instruments-reducer";

interface Props {
  canExerciseCurrentWindow: boolean;
  features: Features;
  warrant: IndividualInstrumentState;
  rsa: IndividualInstrumentState;
  rsu: IndividualInstrumentState;
  psu: IndividualInstrumentState;
  option: IndividualInstrumentState;
  subscriptionRights: IndividualInstrumentState;
  convertibleLoan: IndividualInstrumentState;
  share: IndividualInstrumentState;
  nextWindow: Window;
  currentWindow: Window;
  hideNavigation?: boolean;
}

const OverviewSections: FunctionComponent<Props> = ({
  convertibleLoan,
  share,
  features,
  warrant,
  rsa,
  rsu,
  psu,
  option,
  subscriptionRights,
  canExerciseCurrentWindow,
  nextWindow,
  currentWindow,
  hideNavigation,
}) => (
  <>
    {option.allAwards.length > 0 && (
      <OptionSection
        option={option}
        exerciseFeature={features.exercise}
        canExerciseCurrentWindow={canExerciseCurrentWindow}
        currentWindow={currentWindow}
        nextWindow={nextWindow}
        startExpanded={false}
        hideNavigation={hideNavigation}
      />
    )}
    {warrant.allAwards.length > 0 && (
      <WarrantSection
        warrant={warrant}
        exerciseFeature={features.exercise}
        canExerciseCurrentWindow={canExerciseCurrentWindow}
        currentWindow={currentWindow}
        nextWindow={nextWindow}
        startExpanded={false}
        hideNavigation={hideNavigation}
      />
    )}
    {psu.allAwards.length > 0 && (
      <PSUSection
        psu={psu}
        startExpanded={false}
        hideNavigation={hideNavigation}
      />
    )}
    {rsu.allAwards.length > 0 && (
      <RSUSection
        rsu={rsu}
        exerciseFeature={features.exercise}
        canExerciseCurrentWindow={canExerciseCurrentWindow}
        currentWindow={currentWindow}
        nextWindow={nextWindow}
        startExpanded={false}
        hideNavigation={hideNavigation}
      />
    )}
    {rsa.allAwards.length > 0 && (
      <RSASection
        rsa={rsa}
        startExpanded={false}
        hideNavigation={hideNavigation}
      />
    )}
    {subscriptionRights.allAwards.length > 0 && (
      <SubscriptionRightsSection
        subscriptionRights={subscriptionRights}
        exerciseFeature={features.exercise}
        canExerciseCurrentWindow={canExerciseCurrentWindow}
        currentWindow={currentWindow}
        nextWindow={nextWindow}
        startExpanded={false}
        hideNavigation={hideNavigation}
      />
    )}
    {convertibleLoan.allAwards.length > 0 && (
      <ConvertibleLoanSection
        convertibleLoanUnits={convertibleLoan}
        exerciseFeature={features.exercise}
        canExerciseCurrentWindow={canExerciseCurrentWindow}
        currentWindow={currentWindow}
        nextWindow={nextWindow}
        startExpanded={false}
        hideNavigation={hideNavigation}
      />
    )}
    {share.allAwards.length > 0 && (
      <ShareSection
        share={share}
        exerciseFeature={features.exercise}
        canExerciseCurrentWindow={canExerciseCurrentWindow}
        currentWindow={currentWindow}
        nextWindow={nextWindow}
        startExpanded={false}
        hideNavigation={hideNavigation}
      />
    )}
  </>
);

export default OverviewSections;
