import { Input } from "formik-semantic-ui";
import React, { FunctionComponent } from "react";

import Content from "src/admin-portal/texts/content";
import {
  useFormatMessage,
  formatMessageWithDebugger,
} from "src/admin-portal/texts/utils";

interface Props {
  visible: boolean;
}

const ShareDepositoryClearingCode: FunctionComponent<Props> = ({ visible }) => {
  const formatMessage = useFormatMessage();

  if (!visible) {
    return null;
  }

  return (
    <Input
      label={<Content id="exercise.type.sharedepositoryclearingcode.label" />}
      name="shareDepositoryClearingCode"
      inputProps={{
        placeholder: formatMessageWithDebugger(
          "exercise.type.sharedepositoryclearingcode.placeholder",
          formatMessage
        ),
        fluid: true,
      }}
    />
  );
};

export default ShareDepositoryClearingCode;
