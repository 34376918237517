import { orderBy } from "lodash";

export function sortRelationships(model: any) {
  model.relationshipNames.forEach(relationshipName => {
    if (sortingFunctions[relationshipName]) {
      model[relationshipName] = sortingFunctions[relationshipName](
        model[relationshipName]
      );
    }
  });
  return model;
}

const sortingFunctions = {
  transactions: (transactions: Api.V1.Transaction[]) => {
    return orderBy(transactions, ["transactionDate", "createdAt"]);
  },
};
