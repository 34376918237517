import Jsona from "jsona";
import Raven from "raven-js";
import { all, call, put, select, takeEvery } from "redux-saga/effects";

import {
  deleteTenant,
  deleteTenantSucceeded,
  fetchLiveTenantsOverview,
  fetchLiveTenantsOverviewSucceeded,
  fetchTenants,
  fetchTenantsSucceeded,
  postTenant,
  postTenantSucceeded,
  putTenant,
  putTenantSucceeded,
  selectTenant,
} from "src/admin-portal/tenant/tenant-actions";
import * as selectors from "src/admin-portal/tenant/tenant-selectors";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";

const dataFormatter = new Jsona();
const TENANTS_REQUEST_URL = "/tenants";

function* fetchTenantsRequested() {
  try {
    const token = yield select(selectors.token);

    const tenantResponse = yield call(() =>
      callApi(TENANTS_REQUEST_URL, token)
    );
    yield put(fetchTenantsSucceeded(tenantResponse.data));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchTenants() {
  yield takeEvery(fetchTenants.getType(), fetchTenantsRequested);
}

function* postTenantRequested(action: ReturnType<typeof postTenant>) {
  try {
    const token = yield select(selectors.token);
    const method = "POST";

    const tenantResponse = yield call(() =>
      callApi(TENANTS_REQUEST_URL, token, method, action.payload)
    );
    yield put(postTenantSucceeded(tenantResponse.data));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPostTenant() {
  yield takeEvery(postTenant.getType(), postTenantRequested);
}

function* putTenantRequested(action: ReturnType<typeof putTenant>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId) || action.payload.id;
    const method = "PUT";

    const tenantResponse = yield call(() =>
      callApi(
        TENANTS_REQUEST_URL + "/" + tenantId + "?tenantId=" + tenantId,
        token,
        method,
        action.payload
      )
    );
    yield put(putTenantSucceeded(tenantResponse.data));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPutTenant() {
  yield takeEvery(putTenant.getType(), putTenantRequested);
}

function* storeInLocalStorage() {
  const tenant = yield select(selectors.tenant);
  localStorage.setItem("selectedTenant", JSON.stringify(tenant));
}

export function* watchSelectTenant() {
  yield takeEvery(selectTenant.getType(), storeInLocalStorage);
}

export function* watchPutTenantSucceeded() {
  yield takeEvery(putTenantSucceeded.getType(), storeInLocalStorage);
}

function* deleteTenantRequested(action: ReturnType<typeof deleteTenant>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId) || action.payload;
    const method = "DELETE";

    yield call(() =>
      callApi(TENANTS_REQUEST_URL + "/" + tenantId, token, method)
    );
    yield put(deleteTenantSucceeded(tenantId));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchDeleteTenant() {
  yield takeEvery(deleteTenant.getType(), deleteTenantRequested);
}

function deleteInLocalStorage() {
  localStorage.removeItem("selectedTenant");
}

export function* watchDeleteTenantSucceeded() {
  yield takeEvery(deleteTenantSucceeded.getType(), deleteInLocalStorage);
}

const fetchLiveTenantOverviewUrl = tenantId => `/tenants/${tenantId}/overview`;

function* fetchLiveTenantsOverviewRequested(
  action: ReturnType<typeof fetchLiveTenantsOverview>
) {
  try {
    const token = yield select(selectors.token);

    const liveTenantsOverviewResponse = yield all(
      action.payload.map(tenantId =>
        call(() => callApi(fetchLiveTenantOverviewUrl(tenantId), token))
      )
    );

    yield put(
      fetchLiveTenantsOverviewSucceeded(
        liveTenantsOverviewResponse.map(
          r => dataFormatter.deserialize(r) as Api.V1.TenantOverview
        )
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchLiveTenantsOverview() {
  yield takeEvery(
    fetchLiveTenantsOverview.getType(),
    fetchLiveTenantsOverviewRequested
  );
}
