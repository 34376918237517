import React, { FunctionComponent } from "react";
import {
  Checkbox,
  Dropdown,
  DropdownItemProps,
  Form,
  Icon,
  Popup,
} from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import {
  formatMessageWithDebugger,
  useFormatMessage,
} from "src/admin-portal/texts/utils";
import { Window } from "src/common/data/data";

interface Props {
  shareDepositoryAccountNumber: string;
  shareDepoBank?: string;
  shareDepoClearingCode?: string;
  shareDepoContact?: string;
  shareDepoDescription?: string;
  onShareDepositoryAccountNumberChanged: (event) => void;
  onShareDepoBankChanged: (event) => void;
  onShareDepoClearingCodeChange: (event) => void;
  onShareDepoContactChange: (event) => void;
  onShareDepoDescriptionChange: (event) => void;
  handleShareDepositoryNotAvailableToggle: () => void;
  window: Window;
  shareDepositoryNotAvailable: boolean;
  shareDepositoryError: boolean;
  allowBypassing: boolean;
}

const ShareDepositoryNonNorwegianForm: FunctionComponent<Props> = ({
  shareDepoBank,
  shareDepoClearingCode,
  shareDepoContact,
  shareDepoDescription,
  onShareDepositoryAccountNumberChanged,
  onShareDepoBankChanged,
  onShareDepoClearingCodeChange,
  onShareDepoContactChange,
  onShareDepoDescriptionChange,
  handleShareDepositoryNotAvailableToggle,
  shareDepositoryAccountNumber,
  shareDepositoryNotAvailable,
  shareDepositoryError,
  window,
  allowBypassing,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <Form.Field>
        <label>
          <Content id="exercise.type.sharedepositoryaccount.label" />
        </label>
        <input
          placeholder={formatMessage({
            id: "exercise.type.sharedepositoryaccount.placeholder",
          })}
          value={shareDepositoryAccountNumber}
          disabled={shareDepositoryNotAvailable}
          onChange={onShareDepositoryAccountNumberChanged}
        />
      </Form.Field>
      {window.require_share_depository_bank &&
        !window.share_depository_bank_options && (
          <Form.Field>
            <label>
              <Content id="exercise.type.sharedepositorybank.label" />
              <Popup
                trigger={<Icon name="question circle outline" />}
                content={
                  <Content id="exercise.type.sharedepositorybank.label.popup" />
                }
              />
            </label>
            <input
              placeholder={formatMessage({
                id: "exercise.type.sharedepositorybank.placeholder",
              })}
              value={shareDepoBank}
              disabled={shareDepositoryNotAvailable}
              onChange={onShareDepoBankChanged}
            />
          </Form.Field>
        )}
      {window.require_share_depository_bank &&
        window.share_depository_bank_options && (
          <Form.Field>
            <Form.Field>
              <label>
                <Content id="exercise.type.sharedepositorybank.label" />
                <Popup
                  trigger={<Icon name="question circle outline" />}
                  content={
                    <Content id="exercise.type.sharedepositorybank.label.popup" />
                  }
                />
              </label>
              <Dropdown
                placeholder="Select share depository bank"
                fluid={true}
                selection={true}
                options={shareDepoBankOptions(
                  window.share_depository_bank_options
                )}
                value={shareDepoBank || undefined}
                onChange={(event, { value }) =>
                  onShareDepoBankChanged({ target: { value } })
                }
              />
            </Form.Field>
          </Form.Field>
        )}
      {window.require_share_depository_clearing_code && (
        <Form.Field>
          <label>
            <Content id="exercise.type.sharedepositoryclearingcode.label" />
            <Popup
              trigger={<Icon name="question circle outline" />}
              content={
                <Content id="exercise.type.sharedepositoryclearingcode.popup" />
              }
            />
          </label>
          <input
            placeholder={formatMessageWithDebugger(
              "exercise.type.sharedepositoryclearingcode.placeholder",
              formatMessage
            )}
            value={shareDepoClearingCode}
            disabled={shareDepositoryNotAvailable}
            onChange={onShareDepoClearingCodeChange}
          />
        </Form.Field>
      )}
      {window.require_share_depository_contact && (
        <Form.Field>
          <label>
            <Content id="exercise.type.sharedepositorycontact.label" />
            <Popup
              trigger={<Icon name="question circle outline" />}
              content={
                <Content id="exercise.type.sharedepositorycontact.popup" />
              }
            />
          </label>
          <textarea
            placeholder={formatMessageWithDebugger(
              "exercise.type.sharedepositorycontact.placeholder",
              formatMessage
            )}
            value={shareDepoContact}
            disabled={shareDepositoryNotAvailable}
            onChange={onShareDepoContactChange}
            rows={3}
          />
        </Form.Field>
      )}
      {window.require_share_depository_description && (
        <Form.Field>
          <label>
            <Content id="exercise.type.sharedepositorydescription.label" />
            <Popup
              trigger={<Icon name="question circle outline" />}
              content={
                <Content id="exercise.type.sharedepositorydescription.label.popup" />
              }
            />
          </label>
          <textarea
            placeholder={formatMessageWithDebugger(
              "exercise.type.sharedepositorydescription.placeholder",
              formatMessage
            )}
            value={shareDepoDescription}
            disabled={shareDepositoryNotAvailable}
            onChange={onShareDepoDescriptionChange}
            rows={3}
          />
        </Form.Field>
      )}
      {allowBypassing && (
        <Form.Field>
          <Checkbox
            label={
              <label>
                <Content
                  id={"exercise.type.sharedepositoryaccount.checkbox.label"}
                />
              </label>
            }
            checked={shareDepositoryNotAvailable}
            onChange={handleShareDepositoryNotAvailableToggle}
          />
        </Form.Field>
      )}
    </>
  );
};

const shareDepoBankOptions = (options: string): DropdownItemProps[] =>
  options.split(",").map(option => ({
    key: option,
    value: option,
    text: option,
  }));

export default ShareDepositoryNonNorwegianForm;
