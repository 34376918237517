import moment, { Moment } from "moment";
import { createReducer } from "redux-act";

import {
  deleteWindow,
  deleteWindowSucceeded,
  fetchWindow,
  fetchWindowDetails,
  fetchWindowDetailsSucceeded,
  fetchWindowOrders,
  fetchWindowOrdersSucceeded,
  fetchWindowSucceeded,
  postWindow,
  postWindowSucceeded,
  putWindow,
  putWindowSucceeded,
  selectWindow,
  unselectWindow,
  unsetWindowOrder,
  unsetWindowOrders,
  updateWindowOrder,
  windowStateStartProcessing,
  windowStateStartProcessingSucceeded,
} from "src/admin-portal/exercise-windows/window-actions";
import { WindowProcessingState } from "src/admin-portal/exercise-windows/window-details-home";
import { selectTenant } from "src/admin-portal/tenant/tenant-actions";

export interface Window {
  id?: string;
  name: string;
  start_time: Moment;
  end_time: Moment;
  payment_deadline: Moment;
  window_type: WindowType;
  restricted?: boolean;
  require_bank_account: boolean;
  require_share_depository: boolean;
  require_share_depository_bank: boolean;
  share_depository_bank_options?: string;
  require_share_depository_clearing_code: boolean;
  require_share_depository_contact: boolean;
  require_share_depository_description: boolean;
  require_tax_percentage?: boolean;
  require_bank_account_for_tax_percentage?: boolean;
  restricted_employees: string[];
  allowed_exercise_types: string[];
  commission_percentage?: number;
  processing_status?: string;
  exercise_and_hold_orders_completed?: boolean;
  cashless_orders_sale_completed?: boolean;
  cashless_orders_completed?: boolean;
  is_release_process?: boolean;
  dont_use_dnb_agreement?: boolean;
}

export enum WindowType {
  EXERCISE = "EXERCISE",
  PURCHASE = "PURCHASE",
}

export interface WindowState {
  allWindows: Window[];
  window?: Window;
  windowOrders: Api.V1.Order[];
  isFetching: boolean;
  isFetchingOrders: boolean;
  isUpdatingWindowState: boolean;
}

const initialState: WindowState = {
  allWindows: null,
  windowOrders: null,
  window: null,
  isFetching: false,
  isFetchingOrders: false,
  isUpdatingWindowState: false,
};

const toWindow = (w): Window => ({
  id: w.id,
  name: w.name,
  start_time: moment(w.start_time),
  end_time: moment(w.end_time),
  payment_deadline: moment(w.payment_deadline),
  window_type: w.window_type,
  restricted_employees:
    w.window_restriction && w.window_restriction.employee_restriction
      ? w.window_restriction.window_employee_restrictions.map(
          er => er.employee_id
        )
      : [],
  allowed_exercise_types: w.allowed_exercise_types,
  require_share_depository: w.require_share_depository,
  require_share_depository_bank: w.require_share_depository_bank,
  share_depository_bank_options: w.share_depository_bank_options,
  require_share_depository_clearing_code:
    w.require_share_depository_clearing_code,
  require_bank_account: w.require_bank_account,
  commission_percentage: w.commission_percentage
    ? parseFloat(w.commission_percentage)
    : null,
  processing_status: w.processing_status,
  exercise_and_hold_orders_completed: w.exercise_and_hold_orders_completed,
  cashless_orders_sale_completed: w.cashless_orders_sale_completed,
  cashless_orders_completed: w.cashless_orders_completed,
  is_release_process: w.is_release_process,
  require_share_depository_description: w.require_share_depository_description,
  require_share_depository_contact: w.require_share_depository_contact,
  dont_use_dnb_agreement: w.dont_use_dnb_agreement,
  require_tax_percentage: w.require_tax_percentage,
  require_bank_account_for_tax_percentage:
    w.require_bank_account_for_tax_percentage,
});

const latestWindowFirst = (windowA: Window, windowB: Window): number =>
  moment(windowA.end_time).isSameOrBefore(windowB.end_time) ? 1 : -1;

const setIsFetching = (state: WindowState) => ({
  ...state,
  isFetching: true,
});

export default createReducer(on => {
  on(selectTenant, () => initialState);
  on(fetchWindow, setIsFetching);
  on(postWindow, setIsFetching);
  on(putWindow, setIsFetching);
  on(deleteWindow, setIsFetching);
  on(fetchWindowDetails, setIsFetching);

  on(fetchWindowOrders, (state, _) => ({
    ...state,
    ...{ isFetchingOrders: true },
  }));

  on(windowStateStartProcessing, (state, _) => ({
    ...state,
    ...{ isUpdatingWindowState: true },
  }));

  on(fetchWindowSucceeded, (state, payload) => ({
    ...state,
    allWindows: payload.map(toWindow).sort(latestWindowFirst),
    isFetching: false,
  }));

  on(fetchWindowDetailsSucceeded, (state, payload) => ({
    ...state,
    isFetching: false,
    window: toWindow(payload),
  }));

  on(fetchWindowOrdersSucceeded, (state, payload) => ({
    ...state,
    windowOrders: payload,
    isFetchingOrders: false,
  }));

  on(postWindowSucceeded, (state, payload) => {
    if (state.allWindows && state.allWindows.length > 0) {
      const sortedExerciseWindow = [
        ...state.allWindows,
        toWindow(payload),
      ].sort(latestWindowFirst);
      return {
        ...state,
        allWindows: sortedExerciseWindow,
        isFetching: false,
      };
    } else {
      return {
        ...state,
        allWindows: [toWindow(payload)],
        isFetching: false,
      };
    }
  });

  on(putWindowSucceeded, (state, payload) => {
    if (state.allWindows && state.allWindows.length > 0) {
      const windowIndex = state.allWindows.findIndex(
        window => window.id === payload.id
      );
      const windows = [...state.allWindows];
      windows[windowIndex] = toWindow(payload);
      return {
        ...state,
        allWindows: windows,
        isFetching: false,
        window: toWindow(payload),
      };
    } else {
      return { ...state, isFetching: false, window: toWindow(payload) };
    }
  });

  on(deleteWindowSucceeded, (state, payload) => {
    const exerciseWindow = state.allWindows.filter(
      exerciseWindow => exerciseWindow.id !== payload
    );
    return {
      ...state,
      allWindows: exerciseWindow,
      isFetching: false,
    };
  });

  on(selectWindow, (state, payload) => {
    const windowIndex = state.allWindows.findIndex(
      window => window.id === payload
    );

    return {
      ...state,
      window: state.allWindows[windowIndex],
    };
  });

  on(unselectWindow, (state, _) => ({
    ...state,
    window: null,
  }));

  on(windowStateStartProcessingSucceeded, (state, payload) => {
    const window = state.window;
    window.processing_status = WindowProcessingState.IN_PROCESS;

    if (state.allWindows && state.allWindows.length > 0) {
      const windows = state.allWindows.map((window: Window) =>
        window.id === payload
          ? { ...window, processing_status: WindowProcessingState.IN_PROCESS }
          : window
      );

      return {
        ...state,
        allWindows: windows,
        isUpdatingWindowState: false,
        window,
      };
    } else {
      return { ...state, window, isUpdatingWindowState: false };
    }
  });

  on(updateWindowOrder, (state, payload) => {
    const orderIndex = state.windowOrders.findIndex(
      order => order.id === payload.orderId
    );
    const order = { ...state.windowOrders[orderIndex], ...payload.values };
    state.windowOrders[orderIndex] = order;
    return { ...state, windowOrders: [...state.windowOrders] };
  });

  on(unsetWindowOrder, (state, payload) => {
    const windowOrders = (state.windowOrders || []).filter(
      order => order.id !== payload
    );
    return { ...state, windowOrders };
  });

  on(unsetWindowOrders, state => ({
    ...state,
    windowOrders: null,
  }));
}, initialState);
