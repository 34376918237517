import React, { FunctionComponent } from "react";
import { Table } from "semantic-ui-react";

import CurrencyConversionView from "src/common/components/currency/currency-conversion-view";
import {
  changePunctuationForComma,
  sumNumbers,
  twoDecimals,
} from "src/common/utils/utils";
import { NO_VALUE } from "src/constants";

export interface TableDataEntry<T, K> {
  title: string | React.ReactElement<any>;
  value: (obj: T, index: number, array: T[]) => K;
  displayString: (value: K) => string;
  displayComponent?: (obj: T) => React.ReactElement<any>;
  sortValue?: (obj: T) => any;
  sumInFooter?: boolean;
  hidden?: boolean;
  displayOptions?: {
    textAlign?: "left" | "right" | "center";
    showCurrencyConversion?: boolean;
    currencyConversionDecimals?: number;
  };
}
export type TableData<T> = Array<TableDataEntry<T, any>>;

export const sortByDisplayString = function(...args) {
  return this.displayString(...args);
};
export const sortByValue = function(...args) {
  return this.value(...args);
};
export const useToString = function(...args) {
  return this.value(...args).toString();
};

export const useValue = function(callback?: (value) => any) {
  return function(...args) {
    return callback ? callback(this.value(...args)) : this.value(...args);
  };
};

export const number = function(value: number | null) {
  return value ? changePunctuationForComma(value.toString()) : NO_VALUE;
};
export const numberTwoDecimals = function(value: number | null) {
  return value ? twoDecimals(value) : NO_VALUE;
};

interface OptioTableProps<T = any> {
  data: T[];
  initialSortParameter?: (entry: T) => any;
  mapper: (data: T[]) => TableData<T>;
  showFooter: boolean;
  sortable: boolean;
}
type OptioTableComponent<T = any> = FunctionComponent<OptioTableProps<T>>;

const OptioTable: OptioTableComponent = ({
  data,
  mapper,
  showFooter,
  sortable,
}) => {
  const columnSpecs = mapper(data).filter(c => !c.hidden);
  const columnSum = column =>
    data
      .map((dataEntry, index, array) => column.value(dataEntry, index, array))
      .reduce(sumNumbers, 0);

  return (
    <Table
      celled={true}
      padded={true}
      sortable={sortable}
      compact={"very"}
      textAlign={"center"}
    >
      <Table.Header>
        <Table.Row>
          {columnSpecs.map((column, index) => (
            <Table.HeaderCell key={index}>{column.title}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map((dataEntry, index, array) => (
          <Table.Row key={index}>
            {columnSpecs.map((column, index) => (
              <Table.Cell
                key={index}
                singleLine={true}
                textAlign={
                  column.displayOptions
                    ? column.displayOptions.textAlign
                    : undefined
                }
              >
                {column.displayOptions &&
                column.displayOptions.showCurrencyConversion ? (
                  <CurrencyConversionView
                    value={column.value(dataEntry, index, array)}
                    decimals={column.displayOptions.currencyConversionDecimals}
                  />
                ) : column.displayComponent ? (
                  column.displayComponent(dataEntry)
                ) : (
                  column.displayString(column.value(dataEntry, index, array))
                )}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
      {showFooter && (
        <Table.Footer>
          <Table.Row>
            {columnSpecs.map((column, index) => (
              <Table.HeaderCell
                key={index}
                textAlign={
                  column.displayOptions
                    ? column.displayOptions.textAlign
                    : undefined
                }
              >
                {column.sumInFooter ? (
                  column.displayOptions &&
                  column.displayOptions.showCurrencyConversion ? (
                    <CurrencyConversionView
                      value={columnSum(column)}
                      decimals={
                        column.displayOptions.currencyConversionDecimals
                      }
                    />
                  ) : (
                    column.displayString(columnSum(column))
                  )
                ) : null}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Footer>
      )}
    </Table>
  );
};

export default OptioTable;
