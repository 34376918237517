import { createAction } from "redux-act";

export const fetchPerformanceRuleEntries = createAction(
  "FETCH_PERFORMANCE_RULE_ENTRIES",
  (payload: { performanceRuleId: string }) => payload
);

export const fetchPerformanceRuleEntriesSucceeded = createAction(
  "FETCH_PERFORMANCE_RULE_ENTRIES_SUCCEEDED",
  (payload: { performanceRuleEntries }) => payload
);

export const performanceRuleEntriesDestroy = createAction(
  "PERFORMANCE_RULE_ENTRIES_DESTROY"
);

export const postPerformanceRuleEntry = createAction(
  "POST_PERFORMANCE_RULE_ENTRY",
  (payload: {
    performanceRuleId: string;
    performanceRuleEntry: Api.V1.PerformanceRuleEntry;
  }) => payload
);

export const postPerformanceRuleEntrySucceeded = createAction(
  "POST_PERFORMANCE_RULE_ENTRY_SUCCEEDED",
  (payload: Api.V1.PerformanceRuleEntry) => payload
);

export const patchPerformanceRuleEntry = createAction(
  "PATCH_PERFORMANCE_RULE_ENTRY",
  (payload: {
    performanceRuleEntryId: string;
    performanceRuleEntry: Api.V1.PerformanceRuleEntry;
  }) => payload
);

export const patchPerformanceRuleEntrySucceeded = createAction(
  "PATCH_PERFORMANCE_RULE_ENTRY_SUCCEEDED",
  (payload: Api.V1.PerformanceRuleEntry) => payload
);

export const deletePerformanceRuleEntry = createAction(
  "DELETE_PERFORMANCE_RULE_ENTRY",
  (payload: string) => payload
);

export const deletePerformanceRuleEntrySucceeded = createAction(
  "DELETE_PERFORMANCE_RULE_ENTRY_SUCCEEDED",
  (payload: string) => payload
);
