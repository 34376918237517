import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";

import { useFormatMessage } from "src/admin-portal/texts/utils";
import Logo from "src/employee-portal/menu/Logo";
import { MenuConfig } from "src/employee-portal/menu/useMenuConfig";

interface Props {
  children: JSX.Element;
  menuItems: MenuConfig[];
  userName: string;
  logoUrl: string;
  isAdminUserWithoutInstruments: boolean;
  title: string;
}

const DesktopMenu: FunctionComponent<Props> = ({
  children,
  menuItems,
  userName,
  logoUrl,
  isAdminUserWithoutInstruments,
  title,
}) => {
  const mainMenuItems = menuItems.filter(
    menuItem => !menuItem.showInDesktopUserMenu
  );
  const userMenuItems = menuItems.filter(
    menuItem => menuItem.showInDesktopUserMenu
  );

  const formatMessage = useFormatMessage();

  return (
    <div className="menu-employee-portal-desktop">
      <Menu size="massive" className="main-menu" borderless>
        <Logo
          logoUrl={logoUrl}
          isAdminUserWithoutInstruments={isAdminUserWithoutInstruments}
          title={title}
        />

        <Menu.Menu position="right">
          {mainMenuItems.map(menuItem => (
            <Menu.Item
              key={menuItem.to}
              active={location.pathname.startsWith(menuItem.to)}
              as={Link}
              to={menuItem.to}
              content={formatMessage({ id: menuItem.textKey })}
            />
          ))}

          <Dropdown item={true} text={userName} className="profile">
            <Dropdown.Menu className="profile-sub-menu">
              {userMenuItems.map(menuItem => (
                <Dropdown.Item
                  key={menuItem.to}
                  active={location.pathname.startsWith(menuItem.to)}
                  as={menuItem.textKey !== "menu.logout" ? Link : "a"}
                  to={menuItem.to}
                  href={menuItem.to}
                  text={formatMessage({ id: menuItem.textKey })}
                  icon={menuItem.icon}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>

      <div className="sub-menu">{children}</div>
    </div>
  );
};

export default DesktopMenu;
