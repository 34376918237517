import { push } from "connected-react-router";
import { Moment } from "moment";
import React, { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Button } from "semantic-ui-react";

import { ContentContext } from "src/common/components/content/content-contex";
import CurrencyFormatter, {
  CurrencyContext,
} from "src/common/components/currency/currency-formatter";
import SpinnerFullScreen from "src/common/components/spinner-full-screen";
import SpinnerInline from "src/common/components/spinner-inline";
import { Window } from "src/common/data/data";
import { ErrorModal } from "src/common/error/error-modal";
import {
  InstrumentType,
  instrumentTypeText,
} from "src/common/utils/text-mappings";
import {
  changeCommaForPunctuation,
  changePunctuationForComma,
  formatNumber,
  formatPercentage,
  maxTwoDecimals,
  simpleRound,
} from "src/common/utils/utils";
import { PurchaseType } from "src/constants";
import {
  CREATE_PURCHASE_ORDER,
  FETCH_PURCHASE_DOCUMENT,
  REMOVE_PURCHASE_ORDER_ERROR,
} from "src/employee-portal/purchase/duck/purchase-actions";
import {
  getPurchaseInfoForCurrentWindow,
  PurchaseCurrencyConfig,
} from "src/employee-portal/purchase/duck/purchase-selectors";
import { PurchaseByQuantityContext } from "src/employee-portal/purchase/purchase-by-quantity-contex";
import PurchaseNotPossiblePage, {
  PurchaseNotPossibleReason,
} from "src/employee-portal/purchase/purchase-not-possible-page";
import {
  default as StepIndicator,
  PurchaseStep,
  purchaseStepHandler,
  purchaseSteps,
} from "src/employee-portal/purchase/purchase-step-indicator";
import {
  companyDataSchema,
  personalDataSchema,
  validBankAccount,
} from "src/employee-portal/purchase/purchase-utils";
import AcceptDocument from "src/employee-portal/purchase/steps/accept-document";
import BankAccount from "src/employee-portal/purchase/steps/bank-account";
import CashAmount from "src/employee-portal/purchase/steps/cash-amount";
import ConfirmPurchase from "src/employee-portal/purchase/steps/confirm";
import ConfirmQuantityPurchase from "src/employee-portal/purchase/steps/confirm-quantity-purchase";
import Info from "src/employee-portal/purchase/steps/info";
import InvestmentEntity, {
  InvestmentEntityType,
} from "src/employee-portal/purchase/steps/investment-entity";
import Quantity from "src/employee-portal/purchase/steps/quantity";
import { HasNorwegianAccountAnswer } from "src/employee-portal/purchase/steps/share-depository/norwegian-share-depo-radio";
import ShareDepository, {
  setShareDepoFromUserProfile,
  ShareDepositoryFormState,
} from "src/employee-portal/purchase/steps/share-depository/share-depository";
import { RootState } from "src/reducers/all-reducers";

interface PassedProps {
  window: Window;
  match: any;
}

export interface PaymentInfo {
  bankAccountNumber?: string;
  bic_number?: string;
  iban_number?: string;
  address?: string;
  paymentDeadline?: Moment;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface State {
  currentPage: PurchaseStep;
  quantityError: boolean;
  cashAmountError: boolean;
  purchaseQuantity: string;
  loanQuantity: string;
  loanError: boolean;
  purchaseCashAmount: string;
  hasDownloadedDocument: boolean;
  shareDepoState?: ShareDepositoryFormState;
  bankAccountNumber: string;
  bankAccountNumberValid: boolean;
  investmentEntityType?: InvestmentEntityType;
  investmentEntityValid: boolean;
  companyIdentifier: string;
  companyAddress: string;
  companyEmail: string;
  personalIdentifier: string;
  personalAddress: string;
  investmentCurrencyCode: string;
  minimumCashAmount: number;
}

type Props = PassedProps & StateProps & DispatchProps;

class PurchaseRouter extends Component<Props & InjectedIntlProps, State> {
  constructor(props: Props & InjectedIntlProps) {
    super(props);
    const { type, purchaseCurrencyConfigs } = this.props.purchaseInfo || {};
    this.state = {
      currentPage: PurchaseStep.INFO,
      quantityError: null,
      cashAmountError: false,
      purchaseQuantity: "",
      purchaseCashAmount: "",
      loanQuantity: "",
      loanError: false,
      hasDownloadedDocument: false,
      shareDepoState: setShareDepoFromUserProfile(
        props.userProfile,
        props.purchaseInfo.showNorwegianShareDepositoryQuestion
      ),
      bankAccountNumber: "",
      bankAccountNumberValid: false,
      companyIdentifier: "",
      companyAddress: "",
      companyEmail: "",
      personalIdentifier: "",
      personalAddress: "",
      investmentEntityValid: false,
      investmentCurrencyCode: purchaseCurrencyConfigs
        ? purchaseCurrencyConfigs[0].currencyCode
        : this.props.clientCurrencyCode,
      minimumCashAmount: purchaseCurrencyConfigs
        ? purchaseCurrencyConfigs[0].minimumCashAmount
        : 0,
    };
  }

  public componentDidMount() {
    if (this.props.purchaseInfo) {
      this.props.fetchDocumentInfo(this.props.purchaseInfo.documentId);
    }
  }

  public render() {
    const {
      window,
      isFetchingPurchaseDocument,
      purchaseDocument,
      paymentInfo,
      isPlacingOrder,
      purchaseInfo,
      tenantId,
      dateFormat,
    } = this.props;

    if (!window) {
      return (
        <PurchaseNotPossiblePage
          reason={PurchaseNotPossibleReason.NOT_IN_A_PURCHASE_WINDOW}
        />
      );
    }

    if (!purchaseInfo) {
      return (
        <PurchaseNotPossiblePage
          reason={PurchaseNotPossibleReason.NO_PURCHASABLE_INSTRUMENTS}
        />
      );
    }

    if (isFetchingPurchaseDocument || !purchaseDocument) {
      return <SpinnerInline active={true} />;
    }

    const purchasePrice = purchaseInfo.purchasePrice;
    const instrument =
      InstrumentType[purchaseInfo.instrumentType.toUpperCase()];
    const { formatMessage } = this.props.intl;
    const instrumentTerm = instrumentTypeText(instrument, formatMessage);

    const { currentPage } = this.state;
    const steps = purchaseSteps(
      currentPage,
      purchaseInfo.showShareDepositoryStep,
      purchaseInfo.showQuantityStep,
      purchaseInfo.showCashAmountStep,
      this.showBankAccountInput(),
      purchaseInfo.showInvestmentEntityStep
    );
    const stepHandler = purchaseStepHandler(steps);

    const renderContent = () => (
      <CurrencyFormatter>
        {formatter => {
          const contentValues = {
            windowCloseDate: window.to.format(dateFormat.dateTimeFormat),
            instrumentTermPlural: instrumentTerm.plural,
            maximumQuantity: formatNumber(purchaseInfo.purchasableQuantity),
            minimumQuantity: formatNumber(
              purchaseInfo.minimumPurchasableQuantity
            ),
            minimumCashAmount: formatNumber(this.state.minimumCashAmount),
            maximumCashAmount: formatNumber(purchaseInfo.purchasableCashAmount),
            discount: formatPercentage(purchaseInfo.discount),
            minimumLoan:
              purchaseInfo.loan &&
              formatter.formatCurrency(purchaseInfo.loan.min, 0),
            maximumLoan:
              purchaseInfo.loan &&
              formatter.formatCurrency(purchaseInfo.loan.max, 0),
            price: formatter.formatCurrency(purchaseInfo.purchasePrice, 2),
            basePrice: formatter.formatCurrency(
              purchaseInfo.purchaseBasePrice,
              2
            ),
            currency: formatter.currencyCode,
            currencyFactor:
              purchaseInfo.purchaseCurrencyFactor &&
              purchaseInfo.purchaseCurrencyFactor !== 1
                ? changePunctuationForComma(
                    simpleRound(purchaseInfo.purchaseCurrencyFactor, 5).toFixed(
                      4
                    )
                  )
                : "1",
          };

          return (
            <div className="main-content">
              {this.props.purchaseError && (
                <ErrorModal
                  message={
                    "An error occurred while placing your order. Please contact us if the issue persists"
                  }
                  renderActions={() => (
                    <Button
                      primary={true}
                      basic={true}
                      size={"big"}
                      content="OK"
                      onClick={this.props.removeOrderErrorMessage}
                    />
                  )}
                />
              )}
              <div className="col-center block-l">
                <StepIndicator
                  data={steps}
                  onClick={this.handleStepIndicatorClick(stepHandler)}
                  activeIndex={this.state.currentPage}
                />
              </div>
              {isPlacingOrder && (
                <SpinnerFullScreen
                  text={formatMessage({ id: "purchase.placing.order" })}
                  active={true}
                />
              )}
              {currentPage === PurchaseStep.INFO && (
                <Info
                  goForward={() =>
                    this.setState({
                      currentPage: stepHandler.next(currentPage),
                    })
                  }
                  videoUrl={purchaseInfo.videoUrl}
                  goBack={this.props.backToFrontPage}
                  contentValues={contentValues}
                />
              )}

              {currentPage === PurchaseStep.DOCUMENT && (
                <AcceptDocument
                  goForward={() =>
                    this.setState({
                      currentPage: stepHandler.next(currentPage),
                    })
                  }
                  goBack={() =>
                    this.setState({
                      currentPage: stepHandler.previous(currentPage),
                    })
                  }
                  // goBack={this.props.backToFrontPage}
                  downloadClicked={() =>
                    this.setState({ hasDownloadedDocument: true })
                  }
                  document={this.props.purchaseDocument}
                  enableProceedButton={this.state.hasDownloadedDocument}
                  instrumentTerm={instrumentTerm}
                />
              )}
              {this.state.currentPage === PurchaseStep.QUANTITY && (
                <Quantity
                  quantityError={this.state.quantityError}
                  discount={purchaseInfo.discount}
                  maximumQuantity={purchaseInfo.purchasableQuantity}
                  minimumQuantity={purchaseInfo.minimumPurchasableQuantity}
                  quantity={this.state.purchaseQuantity}
                  quantityChanged={this.quantityChanged}
                  goForward={() =>
                    this.setState({
                      currentPage: stepHandler.next(currentPage),
                    })
                  }
                  goBack={() =>
                    this.setState({
                      currentPage: stepHandler.previous(currentPage),
                    })
                  }
                  price={purchasePrice}
                  basePrice={purchaseInfo.purchaseBasePrice}
                  instrument={instrument}
                  sharePrice={this.props.sharePrice}
                  dateFormat={this.props.dateFormat}
                  loanConfig={purchaseInfo.loan}
                  loan={this.state.loanQuantity}
                  loanPeriods={
                    purchaseInfo.loan && purchaseInfo.loan.loanPeriods
                  }
                  loanChanged={this.loanChanged}
                  loanError={this.state.loanError}
                  purchaseCurrencyFactor={purchaseInfo.purchaseCurrencyFactor}
                  showTaxExplanation={!!purchaseInfo.showTaxExplanation}
                  putDiscount={parseFloat(purchaseInfo.putDiscount || "0")}
                />
              )}
              {this.state.currentPage === PurchaseStep.CASH_AMOUNT && (
                <CashAmount
                  amountError={
                    this.state.cashAmountError &&
                    this.state.purchaseCashAmount !== ""
                  }
                  minimumCashAmount={this.state.minimumCashAmount}
                  maximumCashAmount={purchaseInfo.purchasableCashAmount}
                  cashAmount={this.state.purchaseCashAmount}
                  discount={purchaseInfo.discount}
                  onChanged={this.cashAmountChanged}
                  goForward={() =>
                    this.setState({
                      currentPage: stepHandler.next(currentPage),
                    })
                  }
                  goBack={() =>
                    this.setState({
                      currentPage: stepHandler.previous(currentPage),
                    })
                  }
                  sharePrice={this.props.sharePrice}
                  instrument={instrument}
                  dateFormat={this.props.dateFormat}
                  loanConfig={purchaseInfo.loan}
                  loan={this.state.loanQuantity}
                  loanChanged={this.loanChanged}
                  loanError={this.state.loanError}
                  estMarketValue={this.estMarketValue()}
                  estNumInstruments={this.estNumInstruments()}
                  enterCashAmountBeforeDiscount={
                    purchaseInfo.enterCashAmountBeforeDiscount
                  }
                  investmentCurrencyCode={this.state.investmentCurrencyCode}
                  purchaseCurrencyConfigs={
                    this.props.purchaseInfo.purchaseCurrencyConfigs
                  }
                  investmentCurrencyChanged={code =>
                    this.setState(
                      {
                        investmentCurrencyCode: code,
                        minimumCashAmount: currencyCodeConfig(
                          this.props.purchaseInfo.purchaseCurrencyConfigs,
                          code
                        )?.minimumCashAmount,
                      },
                      () =>
                        this.setState({
                          cashAmountError: this.cashAmountInvalid(
                            this.state.purchaseCashAmount
                          ),
                        })
                    )
                  }
                  showInstrumentEstimation={
                    this.props.purchaseInfo.showInstrumentEstimation
                  }
                />
              )}
              {this.state.currentPage === PurchaseStep.BANK_ACCOUNT && (
                <BankAccount
                  goForward={() =>
                    this.setState({
                      currentPage: stepHandler.next(currentPage),
                    })
                  }
                  goBack={() =>
                    this.setState({
                      currentPage: stepHandler.previous(currentPage),
                    })
                  }
                  contentValues={contentValues}
                  bankAccountNumber={this.state.bankAccountNumber}
                  bankAccountNumberValid={this.state.bankAccountNumberValid}
                  handleBankAccountChange={this.handleBankAccountChange}
                />
              )}
              {this.state.currentPage === PurchaseStep.INVESTMENT_ENTITY && (
                <InvestmentEntity
                  goForward={() =>
                    this.setState({
                      currentPage: stepHandler.next(currentPage),
                    })
                  }
                  goBack={() =>
                    this.setState({
                      currentPage: stepHandler.previous(currentPage),
                    })
                  }
                  contentValues={contentValues}
                  investmentEntityType={this.state.investmentEntityType}
                  setInvestmentEntityType={this.setInvestmentEntity}
                  personalIdentifier={this.state.personalIdentifier}
                  companyIdentifier={this.state.companyIdentifier}
                  companyAddress={this.state.companyAddress}
                  companyEmail={this.state.companyEmail}
                  personalAddress={this.state.personalAddress}
                  handleChange={this.handleInvestmentEntityChange}
                  valid={this.state.investmentEntityValid}
                />
              )}
              {this.state.currentPage === PurchaseStep.SHARE_DEPOSITORY && (
                <ShareDepository
                  goForward={() =>
                    this.setState({
                      currentPage: stepHandler.next(currentPage),
                    })
                  }
                  goBack={() =>
                    this.setState({
                      currentPage: stepHandler.previous(currentPage),
                    })
                  }
                  instrument={instrument}
                  window={window}
                  userProfile={this.props.userProfile}
                  onChange={shareDepoState => this.setState({ shareDepoState })}
                  shareDepoFormState={this.state.shareDepoState}
                  showNorwegianShareDepoQuestion={
                    purchaseInfo.showNorwegianShareDepositoryQuestion
                  }
                  enforceShareDepo={purchaseInfo.enforceShareDepo}
                  hideForeignAccountQuestion={
                    purchaseInfo.hideForeignAccountQuestion
                  }
                />
              )}
              {this.state.currentPage === PurchaseStep.CONFIRM &&
                purchaseInfo.type !==
                  PurchaseType.PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY && (
                  <ConfirmPurchase
                    quantity={parseFloat(this.state.purchaseQuantity)}
                    cashAmount={this.cashAmount()}
                    purchaseInfo={purchaseInfo}
                    goBack={() =>
                      this.setState({
                        currentPage: stepHandler.previous(currentPage),
                      })
                    }
                    isPlacingOrder={false}
                    placeOrder={this.placeOrder}
                    pricePerInstrument={purchasePrice}
                    paymentInfo={paymentInfo}
                    instrument={instrument}
                    estMarketValue={this.estMarketValue()}
                    estDiscount={this.estDiscount()}
                    estNumInstruments={this.estNumInstruments()}
                    discountRate={purchaseInfo.discount}
                    dateFormat={this.props.dateFormat}
                    investmentCurrencyCode={this.state.investmentCurrencyCode}
                  />
                )}

              {this.state.currentPage === PurchaseStep.CONFIRM &&
                purchaseInfo.type ===
                  PurchaseType.PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY && (
                  <ConfirmQuantityPurchase
                    quantity={parseFloat(this.state.purchaseQuantity)}
                    cashAmount={this.cashAmount()}
                    purchaseInfo={purchaseInfo}
                    goBack={() =>
                      this.setState({
                        currentPage: stepHandler.previous(currentPage),
                      })
                    }
                    placeOrder={this.placeOrder}
                    pricePerInstrument={purchasePrice}
                    paymentInfo={paymentInfo}
                    instrument={instrument}
                    discountRate={purchaseInfo.discount}
                    dateFormat={this.props.dateFormat}
                    loan={purchaseInfo.loan ? this.actualLoan() : null}
                    loanPerPeriod={
                      purchaseInfo.loan && purchaseInfo.loan.loanPeriods
                        ? this.actualLoan() / purchaseInfo.loan.loanPeriods
                        : null
                    }
                  />
                )}
            </div>
          );
        }}
      </CurrencyFormatter>
    );

    return (
      <ContentContext.Provider
        value={{ contentPrefix: purchaseInfo.purchaseConfigId }}
      >
        {purchaseInfo.type ===
        PurchaseType.PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY ? (
          <PurchaseByQuantityContext.Provider
            value={{
              purchaseInfo,
              currencies: purchaseInfo.otherCurrencies,
            }}
          >
            <CurrencyContext.Provider
              value={{ currencyCode: purchaseInfo.purchaseCurrency }}
            >
              {renderContent()}
            </CurrencyContext.Provider>
          </PurchaseByQuantityContext.Provider>
        ) : (
          renderContent()
        )}
      </ContentContext.Provider>
    );
  }

  private setInvestmentEntity = value => {
    this.setState(
      {
        investmentEntityType: value,
      },
      () => this.validateInvestmentEntity()
    );
  };

  private isPersonalDataValid = () => {
    const {
      personalIdentifier,
      investmentEntityType,
      personalAddress,
    } = this.state;
    return this.props.purchaseInfo.showInvestmentEntityStep &&
      investmentEntityType === InvestmentEntityType.INDIVIDUAL
      ? personalDataSchema.isValidSync({ personalIdentifier, personalAddress })
      : true;
  };

  private handleInvestmentEntityChange = (event, { name, value }) => {
    this.setState({ [name]: value } as Pick<State, keyof State>, () => {
      this.validateInvestmentEntity();
    });
  };

  private validateInvestmentEntity = () => {
    if (this.props.purchaseInfo.showInvestmentEntityStep) {
      const valid = this.isCompanyDataValid() && this.isPersonalDataValid();
      this.setState({ investmentEntityValid: valid });
    } else {
      this.setState({ investmentEntityValid: true });
    }
  };

  private isCompanyDataValid = () => {
    const {
      companyIdentifier,
      investmentEntityType,
      companyEmail,
      companyAddress,
      personalIdentifier,
    } = this.state;
    return this.props.purchaseInfo.showInvestmentEntityStep &&
      investmentEntityType === InvestmentEntityType.COMPANY
      ? companyDataSchema(this.props.intl.formatMessage).isValidSync({
          companyIdentifier,
          companyEmail,
          companyAddress,
          personalIdentifier,
        })
      : true;
  };

  private handleBankAccountChange = event => {
    const bankAccountNumber = event.target.value;
    this.setState({
      bankAccountNumber,
      bankAccountNumberValid: !this.isBankAccountNotValid(bankAccountNumber),
    });
  };

  private isBankAccountNotValid = (bankAccountNumber: string) => {
    return this.showBankAccountInput() && !validBankAccount(bankAccountNumber);
  };

  private showBankAccountInput = (): boolean => {
    return (
      this.props.purchaseInfo.showBankAccountStep && this.hasPayableAmount()
    );
  };

  private handleStepIndicatorClick = stepHandler => (index: number) => {
    this.setState({ currentPage: stepHandler.toIndex(index) });
  };
  private quantityChanged = newValue => {
    const value = newValue || "0";
    const { loan, purchasePrice } = this.props.purchaseInfo;
    this.setState(
      {
        purchaseQuantity: newValue,
        quantityError: this.quantityInvalid(value),
      },
      () => {
        const hasBinaryLoan =
          loan &&
          loan.min === loan.max &&
          parseFloat(this.state.loanQuantity) > 0;

        if (hasBinaryLoan) {
          const totalPrice = simpleRound(
            purchasePrice * parseInt(this.state.purchaseQuantity, 10),
            2
          );
          this.loanChanged(Math.min(loan.max, totalPrice));
        }
      }
    );
  };
  private loanChanged = newValue => {
    const value = newValue || "0";
    this.setState({
      loanQuantity: newValue
        ? maxTwoDecimals(changeCommaForPunctuation(newValue))
        : newValue,
      loanError: this.loanInvalid(value),
    });
  };
  private cashAmountChanged = newValue => {
    const value = newValue || "0";
    this.setState({
      purchaseCashAmount: value,
      cashAmountError: this.cashAmountInvalid(value),
    });
  };

  private hasPayableAmount = () => {
    const { purchaseInfo } = this.props;
    const { loanQuantity, purchaseQuantity } = this.state;
    const price = purchaseInfo.purchasePrice || 0;
    const quantity = purchaseQuantity ? parseFloat(purchaseQuantity) : 0;
    const totalPrice = simpleRound(price * quantity, 2);
    const loanAmount = purchaseInfo.loan ? parseFloat(loanQuantity || "0") : 0;
    const actualLoan = Math.min(loanAmount, totalPrice);
    const toPay = Math.max(simpleRound(totalPrice - actualLoan), 0);
    return toPay > 0;
  };

  private cashAmountInvalid = (value: string) => {
    const {
      purchaseInfo: { purchaseCurrencyConfigs },
    } = this.props;
    const minmum = currencyCodeConfig(
      purchaseCurrencyConfigs,
      this.state.investmentCurrencyCode
    )?.minimumCashAmount;
    return (
      isNaN(parseInt(value)) ||
      parseInt(value) > this.props.purchaseInfo.purchasableCashAmount ||
      parseInt(value) < minmum
    );
  };

  private quantityInvalid = (value: string) =>
    isNaN(parseInt(value)) ||
    parseInt(value) > this.props.purchaseInfo.purchasableQuantity ||
    parseInt(value) < this.props.purchaseInfo.minimumPurchasableQuantity;

  private loanInvalid = (value: string) => {
    if (this.props.purchaseInfo.loan.min === 0 && !value) {
      // Empty field is okay if minimum loan amount is 0
      return false;
    }

    if (this.props.purchaseInfo.loan.max === this.props.purchaseInfo.loan.min) {
      // This means the UI shows a checkbox for loan. The actual loan value is controlled by the min(max possible loan, value/total price of the purchase) and will thus never be invalid
      return false;
    }

    return (
      isNaN(parseFloat(value)) ||
      parseFloat(value) > this.props.purchaseInfo.loan.max ||
      (parseFloat(value) < this.props.purchaseInfo.loan.min &&
        parseFloat(value) !== 0)
    );
  };

  private placeOrder = () =>
    this.props.placeOrder(this.props.window.id, {
      purchase_amount: parseInt(this.state.purchaseQuantity),
      loan: this.props.purchaseInfo.loan ? this.actualLoan() : null,
      purchase_cash_amount: this.cashAmount(),
      purchase_opportunity_id: this.props.purchaseInfo.purchaseOpportunityId,
      award_id: this.props.purchaseInfo.awardId,
      bank_account_number: this.props.purchaseInfo.showBankAccountStep
        ? this.state.bankAccountNumber
        : null,
      currency_code:
        this.props.purchaseInfo.type === PurchaseType.PURCHASE_OPPORTUNITY_CASH
          ? this.state.investmentCurrencyCode
          : null,
      ...this.shareDepoOrderData(),
      ...this.investmentEntityData(),
    });

  private shareDepoOrderData = () => {
    const shareDepoState = finalizeForm(
      this.state.shareDepoState,
      this.props.window
    );

    return {
      share_depository_norwegian: null, // shareDepoState.shareDepositoryNorwegian,
      share_depository_account:
        this.props.purchaseInfo.showNorwegianShareDepositoryQuestion &&
        this.state.shareDepoState.norwegianAccount ===
          HasNorwegianAccountAnswer.YES
          ? shareDepoState.shareDepositoryNorwegian
          : shareDepoState.shareDepositoryAccountNumber,
      share_depository_bank: shareDepoState.shareDepoBank,
      share_depository_clearing_code: shareDepoState.shareDepoClearingCode,
      share_depository_contact: shareDepoState.shareDepoContact,
      share_depository_description: shareDepoState.shareDepoDescription,
    };
  };

  private investmentEntityData = () => {
    if (!this.props.purchaseInfo.showInvestmentEntityStep) {
      return {};
    }

    if (this.state.investmentEntityType === InvestmentEntityType.COMPANY) {
      return {
        personal_identifier: this.state.personalIdentifier,
        company_identifier: this.state.companyIdentifier,
        company_email: this.state.companyEmail,
        company_address: this.state.companyAddress,
      };
    }

    return {
      personal_identifier: this.state.personalIdentifier,
      personal_address: this.state.personalAddress,
    };
  };

  private estMarketValue = (): number => {
    const { sharePrice, purchaseInfo } = this.props;
    const { enterCashAmountBeforeDiscount } = purchaseInfo;

    return enterCashAmountBeforeDiscount
      ? parseInt(this.state.purchaseCashAmount)
      : this.estNumInstruments() * sharePrice.sharePrice;
  };

  private actualLoan = (): number => {
    const { purchaseCashAmount, loanQuantity, purchaseQuantity } = this.state;
    const {
      purchaseInfo: { type },
      purchaseInfo,
    } = this.props;
    const priceBeforeLoan =
      type === PurchaseType.PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY
        ? simpleRound(
            parseInt(purchaseQuantity) * purchaseInfo.purchasePrice,
            2
          )
        : parseInt(purchaseCashAmount);
    const loanAmount = this.props.purchaseInfo.loan
      ? parseFloat(loanQuantity || "0")
      : 0;
    return Math.min(loanAmount, priceBeforeLoan);
  };

  private estDiscount = (): number => {
    if (this.props.purchaseInfo.enterCashAmountBeforeDiscount) {
      return parseInt(this.state.purchaseCashAmount) - this.cashAmount();
    }
    return this.estMarketValue() - parseInt(this.state.purchaseCashAmount);
  };

  private cashAmount = (): number => {
    const {
      purchaseInfo: { enterCashAmountBeforeDiscount, discount },
    } = this.props;

    const typedAmount = parseInt(this.state.purchaseCashAmount);
    if (isNaN(typedAmount)) {
      return null;
    }
    return enterCashAmountBeforeDiscount
      ? typedAmount * (1 - discount)
      : typedAmount;
  };

  private estNumInstruments = (): number => {
    const { sharePrice, purchaseInfo } = this.props;
    const { discount, enterCashAmountBeforeDiscount } = purchaseInfo;
    const { purchaseCashAmount } = this.state;

    const estSharePrice = enterCashAmountBeforeDiscount
      ? sharePrice.sharePrice
      : sharePrice.sharePrice * (1 - discount);
    return Math.floor(parseInt(purchaseCashAmount) / estSharePrice);
  };
}

const finalizeForm = (
  form: ShareDepositoryFormState,
  window: Window
): ShareDepositoryFormState => {
  const { norwegianAccount } = form;
  if (norwegianAccount === HasNorwegianAccountAnswer.YES) {
    return {
      shareDepositoryNorwegian: form.shareDepositoryAccountNumber,
    };
  } else if (
    form.shareDepositoryNotAvailable ||
    norwegianAccount === HasNorwegianAccountAnswer.NO_BUT_IN_PROGRESS
  ) {
    return {
      shareDepositoryNorwegian: null,
      shareDepositoryAccountNumber: null,
      shareDepoBank: null,
      shareDepoContact: null,
      shareDepoDescription: null,
      shareDepoClearingCode: null,
    };
  } else {
    return {
      shareDepositoryAccountNumber: form.shareDepositoryAccountNumber,
      shareDepoBank: window.require_share_depository_bank
        ? form.shareDepoBank
        : null,
      shareDepoContact: window.require_share_depository_contact
        ? form.shareDepoContact
        : null,
      shareDepoDescription: window.require_share_depository_description
        ? form.shareDepoDescription
        : null,
      shareDepoClearingCode: window.require_share_depository_clearing_code
        ? form.shareDepoClearingCode
        : null,
    };
  }
};

const currencyCodeConfig = (configs: PurchaseCurrencyConfig[], code: string) =>
  configs.filter(config => config.currencyCode === code)[0];

const mapDispatchToProps = (dispatch: Dispatch) => ({
  backToFrontPage: () => dispatch(push("/")),
  removeOrderErrorMessage: () =>
    dispatch({ type: REMOVE_PURCHASE_ORDER_ERROR }),
  fetchDocumentInfo: (documentId: string) =>
    dispatch({ type: FETCH_PURCHASE_DOCUMENT, documentId }),
  placeOrder: (
    window_id: string,
    data: {
      purchase_amount: number;
      purchase_cash_amount: number;
      loan?: number;
      purchase_opportunity_id: string | undefined;
      award_id: string | undefined;
      share_depository_account: string | undefined;
      share_depository_norwegian: string | undefined;
      share_depository_bank: string | undefined;
      share_depository_clearing_code: string | undefined;
      share_depository_contact: string | undefined;
      share_depository_description: string | undefined;
      bank_account_number?: string | null;
      company_identifier?: string | null;
      company_email?: string | null;
      company_address?: string | null;
      personal_identifier?: string | null;
      personal_address?: string | null;
      currency_code?: string | null;
    }
  ) =>
    dispatch({
      type: CREATE_PURCHASE_ORDER,
      data,
      window_id,
    }),
});

const mapStateToProps = (state: RootState) => {
  const purchaseInfo = getPurchaseInfoForCurrentWindow(state);
  return {
    purchaseInfo,
    userProfile: state.employeePortalProfile,
    isFetchingPurchaseDocument: state.purchase.isFetchingPurchaseDocument,
    purchaseDocument: state.purchase.purchaseDocument,
    purchaseError: state.purchase.purchaseError,
    paymentInfo: {
      bankAccountNumber:
        state.user.tenant && state.user.tenant.bank_account_number,
      bic_number: state.user.tenant && state.user.tenant.bic_number,
      iban_number: state.user.tenant && state.user.tenant.iban_number,
      address: state.user.tenant && state.user.tenant.payment_address,
      paymentDeadline:
        state.user.currentPurchaseWindow &&
        state.user.currentPurchaseWindow.paymentDeadline,
    },
    tenantId: state.user.tenant.id,
    isPlacingOrder: state.purchase.isPlacingOrder,
    sharePrice: purchaseInfo.overrideSharePrice || state.instrument.sharePrice,
    dateFormat: state.dateFormat,
    clientCurrencyCode: state.user.tenant.currency_code,
  };
};

export default connect<StateProps, DispatchProps, PassedProps>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl<Props>(PurchaseRouter));
