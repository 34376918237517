import React, { FunctionComponent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import "src/employee-portal/order/table/UpdateModal/style.less";
import {
  useFormatMessage,
  formatMessageWithDebugger,
} from "src/admin-portal/texts/utils";
import { ErrorModal } from "src/common/error/error-modal";
import { removeOrderError } from "src/employee-portal/actions/order";
import UpdateModalContents from "src/employee-portal/order/table/UpdateModal/Contents";

interface Props {
  order: Api.V1.Order;
  onClose: () => void;
}

const UpdateModal: FunctionComponent<Props> = ({ order, onClose }) => {
  const dispatch = useDispatch();

  const isError = useSelector(({ order }) => order.errorUpdatingRelatedOrder);
  const isLoading = useSelector(
    ({ order }) =>
      order.isUpdatingExerciseOrder || order.isUpdatingPurchaseOrder
  );

  const onCloseClearState = () => {
    dispatch(removeOrderError());
    onClose();
  };

  const formatMessage = useFormatMessage();

  if (isError)
    return (
      <ErrorModal
        message={formatMessageWithDebugger(
          "order.employeeUpdateModal.error",
          formatMessage
        )}
        renderActions={() => (
          <Button
            primary={true}
            basic={true}
            size={"big"}
            content="OK"
            onClick={() => dispatch(removeOrderError())}
          />
        )}
      />
    );

  return (
    <Modal
      open={true}
      onClose={onCloseClearState}
      closeIcon={true}
      closeOnDimmerClick={true}
      className="update-employee-order-modal"
    >
      <Modal.Header>
        <Content id="order.employeeUpdateModal.header" />
      </Modal.Header>
      <Modal.Content>
        <UpdateModalContents
          order={order}
          isError={isError}
          isLoading={isLoading}
          onClose={onCloseClearState}
        />
      </Modal.Content>
    </Modal>
  );
};

export default UpdateModal;
