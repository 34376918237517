import React, { StatelessComponent } from "react";

interface Props {
  bic?: string;
  iban?: string;
}

const BICAndIBAN: StatelessComponent<Props> = ({ bic, iban }) => (
  <div className="block-xxs">
    <div>BIC: {bic}</div>
    <div>IBAN: {iban}</div>
  </div>
);

export default BICAndIBAN;
