import { get } from "lodash";

import { groupBy } from "src/admin-portal/reports/generate-report/common/utils";
import { Window } from "src/common/data/data";
import { sortMultipleLevels } from "src/common/utils/sort";
import {
  InstrumentType,
  instrumentTypesText,
} from "src/common/utils/text-mappings";
import { flatten, nullOrUndefined } from "src/common/utils/utils";
import {
  removeExpiredAwards,
  vestedBeforeOrSame,
} from "src/employee-portal/instrument-page/instruments-page";
import { FlatAward } from "src/employee-portal/instrument-page/instruments-reducer";
import { canPurchaseWindow } from "src/employee-portal/purchase/duck/purchase-selectors";
import {
  TimelineEvent,
  TimelineWindowEvent,
} from "src/employee-portal/timeline/events-timeline-reducer";
import { RootState } from "src/reducers/all-reducers";

export const exercisibleInstrumentTerm = (
  tranches: FlatAward[],
  formatMessage
) => {
  const instrumentObject = groupBy(tranches, t => t.instrumentType);
  const exercisibleInstrumentTypes = Object.keys(instrumentObject).map(
    instrumentTypeString => InstrumentType[instrumentTypeString.toUpperCase()]
  );
  return instrumentTypesText(exercisibleInstrumentTypes, formatMessage);
};

export const canExercise = (state: RootState): boolean =>
  findExercisibleTranches(state).length > 0;
export const canRelease = (state: RootState): boolean =>
  findReleasableTranches(state).length > 0;

export const canExerciseCurrentWindow = (state: RootState): boolean =>
  state.user.currentExerciseWindow && canExercise(state);

export const canReleaseCurrentWindow = (state: RootState): boolean =>
  state.user.currentReleaseWindow && canRelease(state);

export const currentExerciseWindow = (state: RootState): Window => {
  if (canExercise(state)) {
    return state.user.currentExerciseWindow;
  }

  if (canRelease(state)) {
    return state.user.currentReleaseWindow;
  }

  return null;
};

export const exercisibleTranches = (state: RootState): FlatAward[] => {
  const exercisibleTranches = findExercisibleTranches(state);
  const releasableTranches = findReleasableTranches(state);

  if (exercisibleTranches.length > 0) {
    return exercisibleTranches;
  } else if (releasableTranches.length > 0) {
    return releasableTranches;
  } else {
    return [];
  }
};

export const sortedExercisibleTranches = (state: RootState): FlatAward[] =>
  sortMultipleLevels(exercisibleTranches(state))("grantDate", "vestedDate");

export const userCurrencyCode = (state: RootState): string | undefined => {
  const sharePriceCurrency = tenantCurrencyCode(state);
  const overrideCurrency = overrideCurrencyCode(state);
  return overrideCurrency || sharePriceCurrency;
};

export const tenantCurrencyCode = (state: RootState): string | undefined =>
  get(state, "user.tenant.currency_code");

export const overrideCurrencyCode = (state: RootState): string | undefined =>
  get(state, "instrument.currency.currencyCode");

export const currencyConversionFactor = (state: RootState) =>
  get(state, "instrument.currency.conversionFactor") || 1;

export const availableEvents = (state: RootState): TimelineEvent[] => {
  const windowEvents: TimelineWindowEvent[] = state.eventsTimeline.events.filter(
    event => event.type === "window"
  ) as TimelineWindowEvent[];

  const filteredWindowEventIds = windowEvents
    .filter(
      event =>
        (event.processType === "exercise" && !canExercise(state)) ||
        (event.processType === "release" && !canRelease(state)) ||
        (event.processType === "purchase" && !canPurchaseWindow(event.window))
    )
    .map(e => e.eventId);

  return state.eventsTimeline.events.filter(
    event => !filteredWindowEventIds.includes(event.eventId)
  );
};

const findExercisibleTranches = (state: RootState) => {
  const sharePrice = get(state, "instrument.sharePrice.sharePrice");
  const window = state.user.currentExerciseWindow;
  if (nullOrUndefined(sharePrice) || nullOrUndefined(window)) {
    return [];
  }

  const exercisibleInstruments = flatten([
    state.instrument.option.allAwards,
    state.instrument.warrant.allAwards,
    state.instrument.subscriptionRightState.allAwards,
  ]);
  return findExercisibleAndReleasableInstrumentsForWindow(
    exercisibleInstruments,
    sharePrice,
    window
  );
};

const findReleasableTranches = (state: RootState) => {
  const sharePrice = get(state, "instrument.sharePrice.sharePrice");
  const window = state.user.currentReleaseWindow;
  if (nullOrUndefined(sharePrice) || nullOrUndefined(window)) {
    return [];
  }

  const releasableInstruments = flatten([
    state.instrument.rsu.allAwards,
    state.instrument.psu.allAwards,
    state.instrument.share.allAwards,
  ]);
  return findExercisibleAndReleasableInstrumentsForWindow(
    releasableInstruments,
    sharePrice,
    window
  );
};

const findExercisibleAndReleasableInstrumentsForWindow = (
  instruments: FlatAward[],
  sharePrice: number,
  window: Window
) =>
  instruments
    .filter(vestedBeforeOrSame(window.to))
    .filter(removeExpiredAwards)
    .filter(award => award.quantity > 0)
    .filter(inTheMoney.bind(this, sharePrice));

const inTheMoney = (sharePrice: number, award: FlatAward): boolean =>
  sharePrice * award.sharesConversionFactor >= award.strike;
