import { Input } from "formik-semantic-ui";
import React, { FunctionComponent } from "react";

import Content from "src/admin-portal/texts/content";
import {
  useFormatMessage,
  formatMessageWithDebugger,
} from "src/admin-portal/texts/utils";

interface Props {
  visible: boolean;
}

const BankAccount: FunctionComponent<Props> = ({ visible }) => {
  const formatMessage = useFormatMessage();

  if (!visible) {
    return null;
  }

  return (
    <Input
      label={<Content id="exercise.type.bankaccount.label" />}
      name="bankAccount"
      inputProps={{
        placeholder: formatMessageWithDebugger(
          "exercise.type.bankaccount.placeholder",
          formatMessage
        ),
        fluid: true,
      }}
    />
  );
};

export default BankAccount;
