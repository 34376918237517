import luhn from "luhn-alg";

import { washAccountNumber } from "src/common/utils/utils";

export const validShareDepoNumber = (shareDepo: string) =>
  shareDepo && washAccountNumber(shareDepo).length >= 3;

export const validShareDepoBank = (
  shareDepoBank: string,
  shareDepoBankOptions?: string
) => {
  if (shareDepoBank && shareDepoBankOptions) {
    return shareDepoBankOptions
      .split(",")
      .some(value => shareDepoBank === value);
  }

  if (shareDepoBank) {
    return washAccountNumber(shareDepoBank).length >= 3;
  }

  return false;
};

export const validNorwegianShareDepoNumber = (shareDepo: string) =>
  shareDepo &&
  washAccountNumber(shareDepo).length === 12 &&
  luhn(washAccountNumber(shareDepo));
