import React, { FunctionComponent } from "react";

import {
  TimelineEvent,
  TimelineTrancheEvent,
  TimelineWindowEvent,
} from "src/employee-portal/timeline/events-timeline-reducer";
import TrancheTimelineEvent from "src/employee-portal/timeline/tranche-timeline-event";
import WindowTimelineEvent from "src/employee-portal/timeline/window-timeline-event";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface PassedProps {
  timelineEvents: TimelineEvent[];
  dateFormat: DateFormatState;
}

const TimelineEventsList: FunctionComponent<PassedProps> = ({
  timelineEvents,
  dateFormat,
}) => (
  <>
    {timelineEvents.map(event => {
      if (event.type === "tranche") {
        return (
          <TrancheTimelineEvent
            key={event.eventId}
            event={event as TimelineTrancheEvent}
            dateFormat={dateFormat}
          />
        );
      } else if (event.type === "window") {
        return (
          <WindowTimelineEvent
            key={event.eventId}
            event={event as TimelineWindowEvent}
            dateFormat={dateFormat}
          />
        );
      }
    })}
  </>
);

export default TimelineEventsList;
