import { Award, VestingEvent } from "src/admin-portal/awards/award-reducer";
import { ProgramAndSubProgramImport } from "src/admin-portal/programs/program-import";
import { Program } from "src/admin-portal/programs/program-reducer";
import { SubProgram } from "src/admin-portal/subprograms/subprogram-reducer";
import { flatten } from "src/common/utils/utils";
import { RootState } from "src/reducers/all-reducers";

export const token = state => state.user.token;
export const isSysadmin = state => state.user.isSysadmin;
export const tenantId = state =>
  state.tenant.selectedTenant && state.tenant.selectedTenant.id;

export const allPrograms = state => state.program.allPrograms;
export const allProgramsIncludingSubPrograms = (state: RootState) =>
  state.program.allProgramsIncludingSubPrograms;

export const allSubprograms = (programs: Program[]): SubProgram[] =>
  flatten(
    programs.map((p: Program) =>
      p.incentive_sub_programs.map(sp => ({ ...sp, program: p }))
    )
  );
export const allSubprogramsJsonapi = (
  programs: Api.V1.IncentiveProgram[]
): Api.V1.IncentiveSubProgram[] =>
  programs.reduce(
    (acc, program) => [...acc, ...program.incentiveSubPrograms],
    []
  );
export const subprogramById = (subprogramId: string) => (
  state: RootState
): SubProgram | undefined =>
  allSubprograms(state.program.allPrograms).filter(
    sp => sp.id === subprogramId
  )[0];

const allAwards = (programs: Program[]): Award[] =>
  flatten(allSubprograms(programs).map(sp => sp.awards));
const allTranches = (programs: Program[]): VestingEvent[] =>
  flatten(allAwards(programs).map(a => a.tranches));

export const awardById = (awardId: string) => (state: RootState): Award =>
  allAwards(state.program.allPrograms).filter(a => a.id === awardId)[0];

export const trancheById = (
  trancheId: string,
  tranches: Api.V1.VestingEvent[]
): Api.V1.VestingEvent | undefined =>
  tranches.filter(tranche => tranche.id === trancheId)[0];

export const extractNewSubProgramsFromImport = (
  allPrograms: Api.V1.IncentiveProgram[],
  oldProgramsImport: ProgramAndSubProgramImport[]
) =>
  oldProgramsImport.reduce((acc, oldProgramImport) => {
    const matchSubprograms = allPrograms.find(
      p => p.name === oldProgramImport.name
    ).incentiveSubPrograms;
    const newSubprograms = oldProgramImport.incentive_sub_programs.filter(
      sp => !matchSubprograms.find(msp => sp.name === msp.name)
    );

    return [...acc, ...newSubprograms];
  }, []);
