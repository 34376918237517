import numeral from "numeral";
import { useState } from "react";
import {
  SemanticCOLORS,
  SemanticICONS,
} from "semantic-ui-react/dist/commonjs/generic";

type SimulateSharePrice = (value: number) => void;

type UseSimulatedSharePrice = (
  sharePrice: number
) => [
  SimulateSharePrice,
  {
    simulatedSharePrice: number;
    label: string;
    labelColor: SemanticCOLORS;
    labelIcon: SemanticICONS;
  }
];

const useSimulatedSharePrice: UseSimulatedSharePrice = sharePrice => {
  const [simulatedSharePrice, setSimulatedSharePrice] = useState(sharePrice);
  const [label, setLabel] = useState("0,00");
  const [labelColor, setLabelColor] = useState("green" as SemanticCOLORS);
  const [labelIcon, setLabelIcon] = useState("arrow right" as SemanticICONS);

  const simulateSharePrice = (newSharePrice: number) => {
    setSimulatedSharePrice(newSharePrice);

    if (sharePrice > newSharePrice) {
      setLabel(`- ${numeral(100 - (newSharePrice * 100) / sharePrice).format(
        "0.00"
      )}
      %`);
      setLabelColor("red");
      setLabelIcon("arrow down");
    }
    if (sharePrice === newSharePrice) {
      setLabel("0,00");
      setLabelColor("green");
      setLabelIcon("arrow right");
    }
    if (newSharePrice > sharePrice && newSharePrice < sharePrice * 2) {
      setLabel(
        `+ ${numeral((newSharePrice * 100) / sharePrice - 100).format(
          "0.00"
        )} %`
      );
      setLabelColor("green");
      setLabelIcon("arrow up");
    }
    if (newSharePrice >= sharePrice * 2) {
      setLabel(`+ ${numeral(newSharePrice / sharePrice).format("0.00")}x`);
      setLabelColor("green");
      setLabelIcon("arrow up");
    }
  };

  return [
    simulateSharePrice,
    { simulatedSharePrice, label, labelColor, labelIcon },
  ];
};

export default useSimulatedSharePrice;
