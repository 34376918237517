import { createReducer } from "redux-act";

import {
  createEntities,
  createEntitiesSucceded,
  deleteEntities,
  deleteEntitiesSucceded,
  deleteEntity,
  deleteEntitySucceded,
  fetchEntities,
  fetchEntitiesSucceded,
  postEntity,
  postEntitySucceded,
  putEntity,
  putEntitySucceded,
  selectEntity,
  unselectEntity,
} from "src/admin-portal/entity/entity-actions";
import { selectTenant } from "src/admin-portal/tenant/tenant-actions";

export interface Entity {
  id?: string;
  name: string;
  identification: string;
  countryCode: string;
  soc_sec?: string;
  currency?: string;
  payment_account?: PaymentAccount;
}

export interface EntityState {
  readonly allEntities: Entity[];
  readonly entity: Entity;
  readonly isFetching: boolean;
}

const initialState: EntityState = {
  allEntities: null,
  entity: null,
  isFetching: false,
};

const setIsFetching = (state: EntityState) => ({
  ...state,
  isFetching: true,
});

export default createReducer(on => {
  on(selectTenant, () => initialState);
  on(fetchEntities, setIsFetching);
  on(createEntities, setIsFetching);
  on(deleteEntities, setIsFetching);
  on(postEntity, setIsFetching);
  on(putEntity, setIsFetching);
  on(deleteEntity, setIsFetching);
  on(selectEntity, (state, payload) => {
    const entityIndex = state.allEntities.findIndex(
      entity => entity.id === payload
    );

    return {
      ...state,
      entity: state.allEntities[entityIndex],
    };
  });
  on(unselectEntity, (state, _) => {
    return {
      ...state,
      entity: null,
    };
  });

  on(fetchEntitiesSucceded, (state, payload) => ({
    ...state,
    ...{ allEntities: payload, isFetching: false },
  }));

  on(postEntitySucceded, (state, payload) => {
    if (!state.allEntities) {
      return {
        ...state,
        isFetching: false,
      };
    } else {
      return {
        ...state,
        allEntities: [...state.allEntities, payload],
        isFetching: false,
      };
    }
  });

  on(putEntitySucceded, (state, payload) => {
    const entityIndex = state.allEntities.findIndex(
      entity => entity.id === payload.id
    );
    const entity = { ...state.allEntities[entityIndex], ...payload };
    const entities = [...state.allEntities];
    entities[entityIndex] = entity;
    return { ...state, allEntities: entities, isFetching: false };
  });

  on(deleteEntitySucceded, (state, payload) => {
    const allEntities = state.allEntities.filter(
      entity => entity.id !== payload
    );
    return { ...state, allEntities: [...allEntities], isFetching: false };
  });

  on(createEntitiesSucceded, (state, payload) => {
    return {
      ...state,
      allEntities: [...state.allEntities, ...payload],
      isFetching: false,
    };
  });

  on(deleteEntitiesSucceded, (state, _) => {
    return {
      ...state,
      ...{ allEntities: [] },
      isFetching: false,
    };
  });
}, initialState);
