import { Moment } from "moment";
import { Reducer } from "redux";

import {
  CREATE_PURCHASE_CONFIG,
  CREATE_PURCHASE_CONFIG_FAILED,
  CREATE_PURCHASE_CONFIG_SUCCEEDED,
  DELETE_PURCHASE_CONFIG,
  DELETE_PURCHASE_CONFIG_FAILED,
  DELETE_PURCHASE_CONFIG_SUCCEEDED,
  UPDATE_PURCHASE_CONFIG,
  UPDATE_PURCHASE_CONFIG_FAILED,
  UPDATE_PURCHASE_CONFIG_SUCCEEDED,
} from "src/admin-portal/actions/purchase-actions";
import {
  DELETE_AWARD_SUCCEEDED,
  POST_AWARD_SUCCEEDED,
  PUT_AWARD_SUCCEEDED,
} from "src/admin-portal/awards/award-actions";
import {
  ADD_PROGRAM,
  DELETE_ALL_PROGRAMS,
  DELETE_ALL_PROGRAMS_SUCCEEDED,
  DELETE_PROGRAM,
  DELETE_PROGRAM_SUCCEEDED,
  FETCH_PROGRAMS,
  FETCH_PROGRAMS_AND_SUBPROGRAMS,
  FETCH_PROGRAMS_AND_SUBPROGRAMS_SUCCEEDED,
  FETCH_PROGRAMS_SUCCEEDED,
  IMPORT_ALL_PROGRAM_AWARDS,
  IMPORT_ALL_PROGRAM_AWARDS_SUCCEEDED,
  IMPORT_ALL_PROGRAMS_SUCCEEDED,
  POST_PROGRAM,
  POST_PROGRAM_SUCCEEDED,
  PUT_PROGRAM,
  PUT_PROGRAM_SUCCEEDED,
} from "src/admin-portal/programs/program-actions";
import {
  ADD_SUBPROGRAM,
  DELETE_SUBPROGRAM_SUCCEEDED,
  POST_SUBPROGRAM_SUCCEEDED,
  PUT_SUBPROGRAM_SUCCEEDED,
} from "src/admin-portal/subprograms/subprogram-actions";
import { SubProgram } from "src/admin-portal/subprograms/subprogram-reducer";
import { selectTenant } from "src/admin-portal/tenant/tenant-actions";

export interface Program {
  id?: string;
  name: string;
  created_at?: string;
  startDate: Moment;
  endDate: Moment;
  capacity: number;
  incentive_sub_programs: SubProgram[];
}

export interface ProgramToApi {
  name: string;
  startDate: string;
  endDate: string;
  capacity: number;
  incentive_sub_programs: SubProgram[];
}

export interface ProgramState {
  readonly allPrograms: Program[];
  readonly allProgramsIncludingSubPrograms: Api.V1.IncentiveProgram[];
  readonly program?: Program;
  readonly subProgram?: SubProgram;
  readonly isFetching: boolean;
  readonly isImportingProgramsAndAwards: boolean;
  readonly isCreatingPurchaseConfig: boolean;
  readonly isDeletingPurchaseConfig: boolean;
  readonly isCreatingPurchaseOpportunity: boolean;
  readonly isCreatedPurchaseOpportunity: boolean;
}

const initialState: ProgramState = {
  allPrograms: null,
  allProgramsIncludingSubPrograms: null,
  program: null,
  subProgram: null,
  isFetching: false,
  isImportingProgramsAndAwards: false,
  isCreatingPurchaseConfig: false,
  isDeletingPurchaseConfig: false,
  isCreatingPurchaseOpportunity: false,
  isCreatedPurchaseOpportunity: null,
};

const programReducer: Reducer<ProgramState> = (
  state = initialState,
  action
) => {
  if (action.type === ADD_PROGRAM) {
    return { ...state, ...{ program: action.program }, isFetching: false };
  }

  if (action.type === ADD_SUBPROGRAM) {
    if (state.program) {
      state.program.incentive_sub_programs.push(action.subProgram);
      return {
        ...state,
        ...{ program: { ...state.program } },
        isFetching: false,
      };
    }

    return state;
  }

  if (
    action.type === FETCH_PROGRAMS ||
    action.type === FETCH_PROGRAMS_AND_SUBPROGRAMS
  ) {
    return { ...state, ...{ isFetching: true } };
  }

  if (action.type === FETCH_PROGRAMS_SUCCEEDED) {
    return {
      ...state,
      ...{ allPrograms: action.programs, isFetching: false },
    };
  }

  if (action.type === FETCH_PROGRAMS_AND_SUBPROGRAMS_SUCCEEDED) {
    return {
      ...state,
      allProgramsIncludingSubPrograms: action.programs,
      isFetching: false,
    };
  }

  if (action.type === POST_PROGRAM) {
    return { ...state, ...{ isFetching: true } };
  }

  if (action.type === POST_PROGRAM_SUCCEEDED) {
    return {
      ...state,
      allPrograms: [...(state.allPrograms || []), action.program],
      program: null,
      isFetching: false,
    };
  }

  if (action.type === PUT_PROGRAM) {
    return { ...state, ...{ isFetching: true } };
  }

  if (action.type === PUT_PROGRAM_SUCCEEDED) {
    const programIndex = state.allPrograms.findIndex(
      program => program.id === action.program.id
    );
    const program = {
      ...state.allPrograms[programIndex],
      ...action.program,
    };
    const programs = [...state.allPrograms];
    programs[programIndex] = program;
    return { ...state, allPrograms: programs, isFetching: false };
  }

  if (action.type === DELETE_PROGRAM) {
    return { ...state, ...{ isFetching: true } };
  }

  if (action.type === DELETE_PROGRAM_SUCCEEDED) {
    const programs = state.allProgramsIncludingSubPrograms.filter(
      program => program.id !== action.programId
    );
    return {
      ...state,
      allProgramsIncludingSubPrograms: [...programs],
      isFetching: false,
    };
  }

  if (action.type === DELETE_ALL_PROGRAMS) {
    return { ...state, ...{ isFetching: true } };
  }

  if (action.type === DELETE_ALL_PROGRAMS_SUCCEEDED) {
    while (action.programs.length > 0) {
      action.programs.pop();
    }
    return {
      ...state,
      ...{ allPrograms: action.programs },
      isFetching: false,
    };
  }

  if (action.type === IMPORT_ALL_PROGRAM_AWARDS) {
    return { ...state, isImportingProgramsAndAwards: true };
  }

  if (action.type === IMPORT_ALL_PROGRAM_AWARDS_SUCCEEDED) {
    return { ...state, isImportingProgramsAndAwards: false };
  }

  if (action.type === IMPORT_ALL_PROGRAMS_SUCCEEDED) {
    return {
      ...state,
      allPrograms: state.allPrograms
        ? state.allPrograms.concat(action.programs)
        : action.programs,
    };
  }

  if (action.type === POST_SUBPROGRAM_SUCCEEDED) {
    const programIndex = state.allProgramsIncludingSubPrograms.findIndex(
      p => p.id === action.subProgram.incentiveProgramId
    );
    const program = { ...state.allProgramsIncludingSubPrograms[programIndex] };
    program.incentiveSubPrograms = [
      ...program.incentiveSubPrograms,
      action.subProgram,
    ];
    const subProgram = program.incentiveSubPrograms.find(
      sp => sp.id === action.subProgram.id
    );
    subProgram.awards = [];
    state.allProgramsIncludingSubPrograms[programIndex] = program;
    return {
      ...state,
      allProgramsIncludingSubPrograms: [
        ...state.allProgramsIncludingSubPrograms,
      ],
      isFetching: false,
    };
  }

  if (action.type === PUT_SUBPROGRAM_SUCCEEDED) {
    const allProgramsIncludingSubPrograms = state.allProgramsIncludingSubPrograms.map(
      program =>
        program.id === action.subProgram.incentiveProgramId
          ? {
              ...program,
              incentive_sub_programs: program.incentiveSubPrograms.map(
                subProgram =>
                  subProgram.id === action.subProgram.id
                    ? { ...action.subProgram, awards: subProgram.awards }
                    : subProgram
              ),
            }
          : program
    );
    return {
      ...state,
      allProgramsIncludingSubPrograms,
      isFetching: false,
    };
  }

  if (action.type === DELETE_SUBPROGRAM_SUCCEEDED) {
    const programIndex = state.allProgramsIncludingSubPrograms.findIndex(
      p => p.id === action.programId
    );
    const program = state.allProgramsIncludingSubPrograms[programIndex];
    program.incentiveSubPrograms = program.incentiveSubPrograms.filter(
      subProgram => subProgram.id !== action.subProgramId
    );
    state.allProgramsIncludingSubPrograms[programIndex] = program;
    return {
      ...state,
      allProgramsIncludingSubPrograms: [
        ...state.allProgramsIncludingSubPrograms,
      ],
      isFetching: false,
    };
  }

  if (action.type === POST_AWARD_SUCCEEDED) {
    const programIndex = (state.allPrograms || []).findIndex(program =>
      program.incentive_sub_programs.some(
        sub_program => sub_program.id === action.award.incentive_sub_program_id
      )
    );
    const program = state.allPrograms[programIndex];
    const subProgram = program.incentive_sub_programs.find(
      subProgram => subProgram.id === action.award.incentive_sub_program_id
    );
    subProgram.awards = [...subProgram.awards, action.award];
    state.allPrograms[programIndex] = program;
    return {
      ...state,
      allPrograms: [...state.allPrograms],
      isFetching: false,
    };
  }

  if (action.type === PUT_AWARD_SUCCEEDED) {
    const programIndex = state.allPrograms.findIndex(program =>
      program.incentive_sub_programs.some(
        sub_program => sub_program.id === action.award.incentive_sub_program_id
      )
    );
    const program = state.allPrograms[programIndex];
    const subProgram = program.incentive_sub_programs.find(
      subProgram => subProgram.id === action.award.incentive_sub_program_id
    );
    subProgram.awards = [
      action.award,
      ...subProgram.awards.filter(award => award.id !== action.award.id),
    ];
    state.allPrograms[programIndex] = program;
    return {
      ...state,
      allPrograms: [...state.allPrograms],
      isFetching: false,
    };
  }

  if (action.type === DELETE_AWARD_SUCCEEDED) {
    const programIndex = state.allPrograms.findIndex(program =>
      program.incentive_sub_programs.some(
        subProgram => subProgram.id === action.award.incentive_sub_program_id
      )
    );
    const program = state.allPrograms[programIndex];
    const subProgram = program.incentive_sub_programs.find(
      subProgram => subProgram.id === action.award.incentive_sub_program_id
    );
    subProgram.awards = [
      ...subProgram.awards.filter(award => award.id !== action.award.id),
    ];
    state.allPrograms[programIndex] = program;
    return {
      ...state,
      allPrograms: [...state.allPrograms],
      isFetching: false,
    };
  }

  if (action.type === DELETE_PURCHASE_CONFIG) {
    return { ...state, isDeletingPurchaseConfig: true };
  }

  if (action.type === DELETE_PURCHASE_CONFIG_FAILED) {
    return { ...state, isDeletingPurchaseConfig: false };
  }

  if (action.type === DELETE_PURCHASE_CONFIG_SUCCEEDED) {
    const deletedPurchaseConfigId = action.id;
    const purchaseConfigPredicate = subProgram =>
      subProgram.purchaseConfig &&
      subProgram.purchaseConfig.id === deletedPurchaseConfigId;
    const newPrograms = state.allProgramsIncludingSubPrograms.map(program => {
      if (program.incentiveSubPrograms.some(purchaseConfigPredicate)) {
        const withoutPurchaseConfig = program.incentiveSubPrograms.map(
          subProgram => {
            if (
              subProgram.purchaseConfig &&
              subProgram.purchaseConfig.id === deletedPurchaseConfigId
            ) {
              return { ...subProgram, purchaseConfig: null };
            }
            return { ...subProgram };
          }
        );
        return {
          ...program,
          incentiveSubPrograms: withoutPurchaseConfig,
        };
      }

      return { ...program };
    });

    return {
      ...state,
      allProgramsIncludingSubPrograms: newPrograms,
      isDeletingPurchaseConfig: false,
    };
  }

  if (
    action.type === CREATE_PURCHASE_CONFIG ||
    action.type === UPDATE_PURCHASE_CONFIG
  ) {
    return { ...state, isCreatingPurchaseConfig: true };
  }

  if (
    action.type === CREATE_PURCHASE_CONFIG_FAILED ||
    action.type === UPDATE_PURCHASE_CONFIG_FAILED
  ) {
    return { ...state, isCreatingPurchaseConfig: false };
  }

  if (
    action.type === CREATE_PURCHASE_CONFIG_SUCCEEDED ||
    action.type === UPDATE_PURCHASE_CONFIG_SUCCEEDED
  ) {
    const sub_program_id = action.purchase_config.incentive_sub_program_id;
    const newPrograms = state.allPrograms.map(program => {
      if (
        program.incentive_sub_programs.some(
          subProgram => subProgram.id === sub_program_id
        )
      ) {
        const withPurchaseConfig = program.incentive_sub_programs.map(
          sub_program => {
            if (sub_program.id === sub_program_id) {
              return {
                ...sub_program,
                purchase_config: action.purchase_config,
              };
            }
            return { ...sub_program };
          }
        );
        return {
          ...program,
          incentive_sub_programs: withPurchaseConfig,
        };
      }

      return { ...program };
    });

    return {
      ...state,
      isCreatingPurchaseConfig: false,
    };
  }

  if (action.type === selectTenant.getType()) {
    return initialState;
  }

  return state;
};

export default programReducer;
