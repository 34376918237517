import { push } from "connected-react-router";
import Raven from "raven-js";
import { all, call, put, select, takeEvery } from "redux-saga/effects";

import { TrancheImport } from "src/admin-portal/awards/award-reducer";
import { sheetImportToEmployee } from "src/admin-portal/employees/employee-import";
import { Employee } from "src/admin-portal/employees/employee-reducer";
import { Entity } from "src/admin-portal/entity/entity-reducer";
import * as selectors from "src/admin-portal/entity/entity-selectors";
import { SingleAwardImport } from "src/admin-portal/import/all-models-import";
import {
  importAllModels,
  importAllModelsFailed,
  importAllModelsSucceeded,
} from "src/admin-portal/import/import-actions";
import { Program } from "src/admin-portal/programs/program-reducer";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { prepareDateForBackend, sumNumbers } from "src/common/utils/utils";

const ENTITIES_REQUEST_URL = "/entities?tenantId=";
const EMPLOYEES_REQUEST_URL = "/employees?tenantId=";
const PROGRAM_REQUEST_URL = "/incentive_programs?tenantId=";
const AWARDS_REQUEST_URL = "/awards?tenantId=";

export const toVestingEventAPI = (trancheImport: TrancheImport) => ({
  ...trancheImport,
  vestedDate: prepareDateForBackend(trancheImport.vestedDate),
  grant_date: prepareDateForBackend(trancheImport.grant_date),
  expiry_date: prepareDateForBackend(trancheImport.expiry_date),
});

function* importModels(action: ReturnType<typeof importAllModels>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";

    const entitiesResponse = yield all(
      action.payload.entities.map(entity =>
        call(() =>
          callApi(ENTITIES_REQUEST_URL + tenantId, token, method, entity)
        )
      )
    );
    const entities: Entity[] = entitiesResponse.map(r => r.data);
    console.log(entities);

    const employeesResponse = yield all(
      action.payload.employees
        .map(sheetImportToEmployee(entities))
        .map(body =>
          call(callApi, EMPLOYEES_REQUEST_URL + tenantId, token, method, body)
        )
    );
    const employees: Employee[] = employeesResponse.map(r => r.data);
    console.log(employees);

    const programBodies: Program[] = action.payload.programs.map(program => ({
      name: program.name,
      startDate: program.startDate,
      endDate: program.endDate,
      capacity: program.capacity,
      incentive_sub_programs: program.incentive_sub_programs.map(
        subProgram => ({
          name: subProgram.name,
          instrument_type_id: subProgram.instrument_type_id,
          settlement_type_id: subProgram.settlement_type_id,
          performance: subProgram.performance,
          use_fair_value_for_soc_sec: subProgram.use_fair_value_for_soc_sec,
          employee_pays_soc_sec: subProgram.employee_pays_soc_sec,
          incentive_sub_program_template: {
            tranche_templates: [],
          },
          awards: [],
        })
      ),
    }));

    const programsResponse = yield all(
      programBodies.map(program =>
        call(() =>
          callApi(PROGRAM_REQUEST_URL + tenantId, token, method, program)
        )
      )
    );
    const subprograms = programsResponse
      .map(r => r.data)
      .reduce(
        (accumulator, program) => [
          ...accumulator,
          ...program.incentive_sub_programs,
        ],
        []
      );
    const awards = action.payload.awards.map((a: SingleAwardImport) => {
      const subProgram = subprograms.filter(
        sp => sp.name === a.subProgramName
      )[0];
      // Map fra SingleAwardImport    til Award
      return {
        employee_id: employeeIdFromEmail(employees, a.employeeEmail),
        incentive_sub_program_id: subProgram.id,
        quantity: a.tranches.map(ve => ve.quantity).reduce(sumNumbers, 0),
        tranches: a.tranches.map(toVestingEventAPI),
      };
    });

    yield all(
      awards.map(award =>
        call(() => callApi(AWARDS_REQUEST_URL + tenantId, token, method, award))
      )
    );

    yield put(importAllModelsSucceeded());
    yield put(push("/admin/awards"));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put(importAllModelsFailed(e.message));
    }
  }
}

const employeeIdFromEmail = (employees: Employee[], email: string) => {
  const employee = employees.filter(e => e.email === email)[0];

  if (!employee) {
    throw new Error(
      `Error parsing employee with email: ${email}. It does not match any of the imported employee emails.`
    );
  }

  return employee.id;
};

export function* watchImportAllModels() {
  yield takeEvery(importAllModels.getType(), importModels);
}
