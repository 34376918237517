import React, { Component, StatelessComponent } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { Checkbox, Form, Icon, Popup } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { formatMessageWithDebugger } from "src/admin-portal/texts/utils";
import { InstrumentsAgreement, Window } from "src/common/data/data";
import { TextTerm } from "src/common/utils/text-mappings";
import {
  changeCommaForPunctuation,
  maxTwoDecimals,
} from "src/common/utils/utils";
import { ExerciseNavigationButtons } from "src/employee-portal/exercise/common/exercise-navigation-buttons";

interface Props {
  userInstruments: InstrumentsAgreement[];
  orderExerciseType: Api.V1.ExerciseOrder["exerciseType"];
  instrumentTerm: TextTerm;
  selectOrderExerciseType: (type: Api.V1.ExerciseOrder["exerciseType"]) => void;
  shareDepositoryAccountNumber?: string;
  onshareDepositoryAccountNumberChanged: (value: string) => void;
  shareDepoBank?: string;
  shareDepoClearingCode?: string;
  shareDepoContact?: string;
  shareDepoDescription?: string;
  handleshareDepoBankChange: (value: string) => void;
  handleShareDepoClearingCodeChange: (value: string) => void;
  handleShareDepoContactChange: (value: string) => void;
  handleShareDepoDescriptionChange: (value: string) => void;
  require_share_depository: boolean;
  require_share_depository_bank: boolean;
  require_share_depository_contact: boolean;
  require_share_depository_description: boolean;
  require_share_depository_clearing_code: boolean;
  onBankAccountNumberChanged: (value: string) => void;
  onBicChanged: (value: string) => void;
  window: Window;
  goBack: () => void;
  goForward: () => void;
  nextPath: string;
  hasErrors: boolean;
  handleVpsToggle: () => void;
  handleBankAccountToggle: () => void;
  vpsNotReady: boolean;
  bankAccountNotReady: boolean;
  bankAccountNumber: string;
  require_bank_account: boolean;
  bicNumber: string;
  require_tax_percentage: boolean;
  taxPercentage: number;
  handleTaxPercentageChange: (value: number) => void;
}

interface State {
  taxPercentageInput: string;
}

class ExerciseTypeRoute extends Component<Props & InjectedIntlProps, State> {
  constructor(props: Props & InjectedIntlProps) {
    super(props);

    this.state = {
      taxPercentageInput: this.props.taxPercentage.toString(),
    };
  }

  public render() {
    const { formatMessage } = this.props.intl;
    console.log("TYPE", this.props.hasErrors);

    const bankAccountHeaderText =
      this.props.orderExerciseType === "EXERCISE_AND_SELL"
        ? "exercise.type.bankaccount.header"
        : "exercise.type.bankaccountfortaxpercentage.header";
    const bankAccountDescriptionText =
      this.props.orderExerciseType === "EXERCISE_AND_SELL"
        ? "exercise.type.bankaccount.description"
        : "exercise.type.bankaccountfortaxpercentage.description";

    return (
      <div>
        <div className="exercise-type-container">
          <h2 className="text-center block-m">
            <Content id="exercise.type.title" />
          </h2>
          <ExerciseType
            selected={this.props.orderExerciseType}
            onChange={this.props.selectOrderExerciseType}
            onshareDepositoryAccountNumberChanged={
              this.props.onshareDepositoryAccountNumberChanged
            }
            instrumentTerm={this.props.instrumentTerm}
            allowedExerciseTypes={this.props.window.allowed_exercise_types}
            shareDepositoryAccountNumber={
              this.props.shareDepositoryAccountNumber
            }
          />
        </div>
        {this.props.require_tax_percentage && (
          <div className="block-l">
            <h2 className="text-center block-s">
              <Content id="exercise.type.taxpercentage.header" />
            </h2>
            <p className="text-center block-s">
              <Content id="exercise.type.taxpercentage.description" />
            </p>
            <div className="vps-number">
              <Form size={"large"}>
                <Form.Field>
                  <label>
                    <Content id="exercise.type.taxpercentage.label" />
                  </label>
                  <Form.Input
                    name={"taxPercentage"}
                    value={this.state.taxPercentageInput}
                    onChange={this.handleTaxPercentageChanged}
                    placeholder={formatMessageWithDebugger(
                      "exercise.type.taxpercentage.placeholder",
                      formatMessage
                    )}
                  />
                </Form.Field>
              </Form>
            </div>
          </div>
        )}
        {this.props.require_share_depository && (
          <div className="block-l">
            <h2 className="text-center block-s">
              <Content id="exercise.type.sharedepositoryaccount.header" />
            </h2>
            <p className="text-center block-s">
              <Content id="exercise.type.sharedepositoryaccount.description" />
            </p>
            <div className="vps-number">
              <Form size={"large"}>
                <Form.Field>
                  <label>
                    <Content id="exercise.type.sharedepositoryaccount.label" />
                  </label>
                  <input
                    placeholder={formatMessageWithDebugger(
                      "exercise.type.sharedepositoryaccount.placeholder",
                      formatMessage
                    )}
                    value={this.props.shareDepositoryAccountNumber}
                    disabled={this.props.vpsNotReady}
                    onChange={this.handleVPSChange}
                  />
                </Form.Field>
                {this.props.require_share_depository_bank && (
                  <Form.Field>
                    <label>
                      <Content id="exercise.type.sharedepositorybank.label" />
                      <Popup
                        trigger={<Icon name="question circle outline" />}
                        content={
                          <Content id="exercise.type.sharedepositorybank.label.popup" />
                        }
                      />
                    </label>
                    <input
                      placeholder={formatMessageWithDebugger(
                        "exercise.type.sharedepositorybank.placeholder",
                        formatMessage
                      )}
                      value={this.props.shareDepoBank}
                      disabled={this.props.vpsNotReady}
                      onChange={this.handleshareDepoBankChange}
                    />
                  </Form.Field>
                )}
                {this.props.require_share_depository_clearing_code && (
                  <Form.Field>
                    <label>
                      <Content id="exercise.type.sharedepositoryclearingcode.label" />
                      <Popup
                        trigger={<Icon name="question circle outline" />}
                        content={
                          <Content id="exercise.type.sharedepositoryclearingcode.popup" />
                        }
                      />
                    </label>
                    <input
                      placeholder={formatMessageWithDebugger(
                        "exercise.type.sharedepositoryclearingcode.placeholder",
                        formatMessage
                      )}
                      value={this.props.shareDepoClearingCode}
                      disabled={this.props.vpsNotReady}
                      onChange={this.handleShareDepoClearingCodeChange}
                    />
                  </Form.Field>
                )}
                {this.props.require_share_depository_contact && (
                  <Form.Field>
                    <label>
                      <Content id="exercise.type.sharedepositorycontact.label" />
                      <Popup
                        trigger={<Icon name="question circle outline" />}
                        content={
                          <Content id="exercise.type.sharedepositorycontact.popup" />
                        }
                      />
                    </label>
                    <textarea
                      placeholder={formatMessageWithDebugger(
                        "exercise.type.sharedepositorycontact.placeholder",
                        formatMessage
                      )}
                      value={this.props.shareDepoContact}
                      disabled={this.props.vpsNotReady}
                      onChange={this.handleShareDepoContactChange}
                      rows={3}
                    />
                  </Form.Field>
                )}
                {this.props.require_share_depository_description && (
                  <Form.Field>
                    <label>
                      <Content id="exercise.type.sharedepositorydescription.label" />
                      <Popup
                        trigger={<Icon name="question circle outline" />}
                        content={
                          <Content id="exercise.type.sharedepositorydescription.label.popup" />
                        }
                      />
                    </label>
                    <textarea
                      placeholder={formatMessageWithDebugger(
                        "exercise.type.sharedepositorydescription.placeholder",
                        formatMessage
                      )}
                      value={this.props.shareDepoDescription}
                      disabled={this.props.vpsNotReady}
                      onChange={this.handleShareDepoDescriptionChange}
                      rows={3}
                    />
                  </Form.Field>
                )}
                <Form.Field>
                  <Checkbox
                    label={
                      <label>
                        <Content
                          id={
                            "exercise.type.sharedepositoryaccount.checkbox.label"
                          }
                        />
                      </label>
                    }
                    checked={this.props.vpsNotReady}
                    onChange={this.props.handleVpsToggle}
                  />
                </Form.Field>
              </Form>
            </div>
          </div>
        )}
        {this.props.require_bank_account && (
          <div className="block-l">
            <h2 className="text-center block-s">
              <Content id={bankAccountHeaderText} />
            </h2>
            <p className="text-center block-s">
              <Content id={bankAccountDescriptionText} />
            </p>
            <div className="vps-number">
              <Form size={"large"}>
                <Form.Field>
                  <label>
                    <Content id="exercise.type.bankaccount.label" />
                  </label>
                  <input
                    placeholder={formatMessageWithDebugger(
                      "exercise.type.bankaccount.placeholder",
                      formatMessage
                    )}
                    value={this.props.bankAccountNumber}
                    disabled={this.props.bankAccountNotReady}
                    onChange={this.handleBankAccountChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    <Content id="exercise.type.bic.label" />
                  </label>
                  <input
                    placeholder={formatMessageWithDebugger(
                      "exercise.type.bic.placeholder",
                      formatMessage
                    )}
                    value={this.props.bicNumber}
                    disabled={this.props.bankAccountNotReady}
                    onChange={this.handleBICChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label={formatMessageWithDebugger(
                      "exercise.type.bankaccount.checkbox.label",
                      formatMessage
                    )}
                    checked={this.props.bankAccountNotReady}
                    onChange={this.props.handleBankAccountToggle}
                  />
                </Form.Field>
              </Form>
            </div>
          </div>
        )}
        <ExerciseNavigationButtons
          forwardDisabled={this.props.hasErrors}
          goBack={this.props.goBack}
          goForward={this.props.goForward}
        />
      </div>
    );
  }

  private handleVPSChange = event =>
    this.props.onshareDepositoryAccountNumberChanged(event.target.value);
  private handleshareDepoBankChange = event =>
    this.props.handleshareDepoBankChange(event.target.value);
  private handleShareDepoClearingCodeChange = event =>
    this.props.handleShareDepoClearingCodeChange(event.target.value);
  private handleShareDepoContactChange = event =>
    this.props.handleShareDepoContactChange(event.target.value);
  private handleShareDepoDescriptionChange = event =>
    this.props.handleShareDepoDescriptionChange(event.target.value);
  private handleBankAccountChange = event =>
    this.props.onBankAccountNumberChanged(event.target.value);
  private handleBICChange = event =>
    this.props.onBicChanged(event.target.value);
  private handleTaxPercentageChanged = event => {
    const value = maxTwoDecimals(changeCommaForPunctuation(event.target.value));
    const onlyNumbers = value.replace(/[^\d|.|,]/gi, "");
    const parsed = parseFloat(onlyNumbers);

    if (!isNaN(parsed) || value === "") {
      this.props.handleTaxPercentageChange(parsed);
      this.setState({ taxPercentageInput: onlyNumbers });
    }
  };
}

interface ExerciseTypeProps {
  selected: Api.V1.ExerciseOrder["exerciseType"];
  onChange: (selected: Api.V1.ExerciseOrder["exerciseType"]) => void;
  onshareDepositoryAccountNumberChanged: (value: string) => void;
  shareDepositoryAccountNumber?: string;
  instrumentTerm: TextTerm;
  allowedExerciseTypes: string[];
}

class ExerciseType extends Component<ExerciseTypeProps, {}> {
  public render() {
    const { instrumentTerm, allowedExerciseTypes } = this.props;
    return (
      <div className="excercise-box-container">
        {allowedExerciseTypes.includes("EXERCISE_AND_HOLD") && (
          <ExerciseTypeBox
            headerId="exercise.type.exerciseandhold.header"
            descriptionId="exercise.type.exerciseandhold.description"
            selected={this.props.selected === "EXERCISE_AND_HOLD"}
            onClick={this.props.onChange.bind(this, "EXERCISE_AND_HOLD")}
            instrumentName={instrumentTerm}
          />
        )}
        {allowedExerciseTypes.includes("EXERCISE_AND_SELL") && (
          <ExerciseTypeBox
            headerId="exercise.type.exerciseandsell.header"
            descriptionId="exercise.type.exerciseandsell.description"
            selected={this.props.selected === "EXERCISE_AND_SELL"}
            onClick={this.props.onChange.bind(this, "EXERCISE_AND_SELL")}
            instrumentName={instrumentTerm}
          />
        )}
        {allowedExerciseTypes.includes("EXERCISE_AND_SELL_TO_COVER") && (
          <ExerciseTypeBox
            headerId="exercise.type.exerciseandselltocover.header"
            descriptionId="exercise.type.exerciseandselltocover.description"
            selected={this.props.selected === "EXERCISE_AND_SELL_TO_COVER"}
            onClick={this.props.onChange.bind(
              this,
              "EXERCISE_AND_SELL_TO_COVER"
            )}
            instrumentName={instrumentTerm}
          />
        )}
      </div>
    );
  }
}

const ExerciseTypeBox: StatelessComponent<{
  headerId: string;
  descriptionId: string;
  selected: boolean;
  onClick: () => void;
  instrumentName: TextTerm;
}> = ({ headerId, descriptionId, selected, onClick, instrumentName }) => (
  <div
    className={`excercise-box block-l ${
      selected ? "excercise-box-selected" : ""
    }`}
    onClick={onClick}
  >
    <h3 className="excercise-box-header">
      <Content id={headerId} />
    </h3>
    <p className="excercise-box-description">
      <Content
        id={descriptionId}
        values={{ instrumentName: instrumentName.plural }}
      />
    </p>
  </div>
);

export default injectIntl<Props>(ExerciseTypeRoute);
