import * as Yup from "yup";

import { FormatMessageFuntion } from "src/common/utils/text-mappings";
import { washAccountNumber } from "src/common/utils/utils";
import { InvestmentEntityType } from "src/employee-portal/purchase/steps/investment-entity";

export const validBankAccount = (bankAccount: string) =>
  bankAccount && washAccountNumber(bankAccount).length === 11;

const personalIdentifier = Yup.string()
  .required()
  .min(10)
  .max(10);

const address = Yup.string()
  .required()
  .min(10);

export const companyDataSchema = (formatMessage: FormatMessageFuntion) =>
  Yup.object().shape({
    personalIdentifier,
    companyIdentifier: Yup.string()
      .required(
        formatMessage({ id: "purchase.formErrors.companyIdentifier.required" })
      )
      .min(
        8,
        formatMessage({ id: "purchase.formErrors.companyIdentifier.min" })
      )
      .max(
        12,
        formatMessage({ id: "purchase.formErrors.companyIdentifier.max" })
      ),
    companyAddress: address,
    companyEmail: Yup.string()
      .required()
      .email(),
  });

export const investmentEntitySchema = (formatMessage: FormatMessageFuntion) =>
  Yup.object().shape({
    personalIdentifier: Yup.string()
      .required(
        formatMessage({ id: "purchase.formErrors.personalIdentifier.required" })
      )
      .min(
        10,
        formatMessage({ id: "purchase.formErrors.personalIdentifier.min" })
      )
      .max(
        10,
        formatMessage({ id: "purchase.formErrors.personalIdentifier.max" })
      ),
    personalAddress: Yup.string().when("$investmentEntityType", {
      is: InvestmentEntityType.INDIVIDUAL,
      then: Yup.string()
        .required(
          formatMessage({ id: "purchase.formErrors.personalAddress.required" })
        )
        .min(
          10,
          formatMessage({ id: "purchase.formErrors.personalAddress.min" })
        ),
      otherwise: Yup.string().nullable(),
    }),
    companyIdentifier: Yup.string().when("$investmentEntityType", {
      is: InvestmentEntityType.COMPANY,
      then: Yup.string()
        .required(
          formatMessage({
            id: "purchase.formErrors.companyIdentifier.required",
          })
        )
        .min(
          8,
          formatMessage({ id: "purchase.formErrors.companyIdentifier.min" })
        )
        .max(
          12,
          formatMessage({ id: "purchase.formErrors.companyIdentifier.max" })
        ),
      otherwise: Yup.string().nullable(),
    }),
    companyAddress: Yup.string().when("$investmentEntityType", {
      is: InvestmentEntityType.COMPANY,
      then: Yup.string()
        .required(
          formatMessage({ id: "purchase.formErrors.companyAddress.required" })
        )
        .min(
          10,
          formatMessage({ id: "purchase.formErrors.companyAddress.min" })
        ),
      otherwise: Yup.string().nullable(),
    }),
    companyEmail: Yup.string().when("$investmentEntityType", {
      is: InvestmentEntityType.COMPANY,
      then: Yup.string()
        .required(
          formatMessage({ id: "purchase.formErrors.companyEmail.required" })
        )
        .email(
          formatMessage({ id: "purchase.formErrors.companyEmail.invalidEmail" })
        ),
      otherwise: Yup.string().nullable(),
    }),
  });

export const personalDataSchema = Yup.object().shape({
  personalIdentifier,
  personalAddress: address,
});
