import { push } from "connected-react-router";
import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { Button, Icon } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { sharePriceSimulationRoute } from "src/employee-portal/employee-portal-router";

interface Props {
  pathName: string;
  sharePriceSimulation: boolean;
}

const SharePriceSimulationButton: FunctionComponent<Props> = ({
  pathName,
  sharePriceSimulation,
}) => {
  const dispatch = useDispatch();

  if (!sharePriceSimulation) {
    return null;
  }

  return (
    <Button
      className="share-price-simulation"
      onClick={() => dispatch(push(sharePriceSimulationRoute))}
    >
      <Content id="sharePriceSimulation.headerButton.title" />
      <Icon
        name={
          pathName === sharePriceSimulationRoute ? "arrow up" : "arrow down"
        }
      />
    </Button>
  );
};

export default SharePriceSimulationButton;
