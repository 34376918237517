import React, { StatelessComponent } from "react";

import Content from "src/admin-portal/texts/content";

export const PaymentAddress: StatelessComponent<{ addressString?: string }> = ({
  addressString,
}) => (
  <div>
    {addressString && (
      <div>
        <div className="block-xxxs">
          <Content id={"payment.address.label"} />
        </div>
        {addressString.split("\n").map((addressLine, index) => (
          <div key={index}>{addressLine}</div>
        ))}
      </div>
    )}
  </div>
);

export default PaymentAddress;
