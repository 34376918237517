import React, { FunctionComponent } from "react";

import Content from "src/admin-portal/texts/content";
import { useMessageExists } from "src/admin-portal/texts/utils";
import TextWithHelperText from "src/common/components/text-with-helper-text";

const Text: FunctionComponent<{
  textKey: string;
  popupKey?: string;
  values?: any;
}> = ({ textKey, values, popupKey }) => {
  const messageExists = useMessageExists();
  const popupTextKey = popupKey || `${textKey}.popup`;
  const helpTextExists = messageExists(popupTextKey);

  return helpTextExists ? (
    <TextWithHelperText textKey={textKey} popupTextKey={popupTextKey} />
  ) : (
    <Content id={textKey} values={values} />
  );
};

export default Text;
