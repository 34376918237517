import { createAction } from "redux-act";

import { Window } from "src/common/data/data";
import { ExerciseOrder } from "src/employee-portal/sagas/order";

export const fetchOrders = createAction("FETCH_ORDERS");

export const fetchOrdersSucceeded = createAction(
  "FETCH_ORDERS_SUCCEEDED",
  (payload: { orders: Api.V1.Order[] }) => payload
);

export const fetchOrdersFailed = createAction(
  "FETCH_ORDERS_FAILED",
  (payload: string) => payload
);

export const placeExerciseOrder = createAction(
  "PLACE_EXERCISE_ORDER",
  (payload: { window: Window; order: ExerciseOrder }) => payload
);

export const placeExerciseOrderSucceeded = createAction(
  "PLACE_EXERCISE_ORDER_SUCCEEDED"
);

export const placeExerciseOrderFailed = createAction(
  "PLACE_EXERCISE_ORDER_FAILED",
  (payload: string) => payload
);

export const putExerciseOrder = createAction(
  "PUT_EXERCISE_ORDER",
  (payload: {
    orderId: Api.V1.ExerciseOrder["id"];
    data: Api.V1.ExerciseOrder;
  }) => payload
);

export const putExerciseOrderSucceeded = createAction(
  "PUT_EXERCISE_ORDER_SUCCEEDED",
  (payload: Api.V1.ExerciseOrder) => payload
);

export const putExerciseOrderFailed = createAction(
  "PUT_EXERCISE_ORDER_FAILED",
  (payload: string) => payload
);

export const putPurchaseOrder = createAction(
  "PUT_PURCHASE_ORDER",
  (payload: {
    orderId: Api.V1.PurchaseOrder["id"];
    data: Api.V1.PurchaseOrder;
  }) => payload
);

export const putPurchaseOrderSucceeded = createAction(
  "PUT_PURCHASE_ORDER_SUCCEEDED",
  (payload: Api.V1.PurchaseOrder) => payload
);

export const putPurchaseOrderFailed = createAction(
  "PUT_PURCHASE_ORDER_FAILED",
  (payload: string) => payload
);

export const removeOrderError = createAction("REMOVE_ORDER_ERROR");
