import moment from "moment";
import React, { FunctionComponent } from "react";

import { Flag, FlagProps, Table } from "semantic-ui-react";

import { countryEntry } from "src/common/data/common";
import { apiShortDate, formatNumber } from "src/common/utils/utils";
import { NO_VALUE, TransactionType } from "src/constants";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface Props {
  transaction: Api.V1.Transaction;
  dateFormat: DateFormatState;
}

const TransactionRow: FunctionComponent<Props> = ({
  transaction,
  dateFormat,
}) => (
  <Table.Row key={transaction.id} active={true}>
    <Table.Cell />
    <Table.Cell />
    <Table.Cell>
      {transaction.tranche.award.employee.firstName}{" "}
      {transaction.tranche.award.employee.lastName}
    </Table.Cell>
    <Table.Cell>{transaction.tranche.award.employee.entity.name}</Table.Cell>
    <Table.Cell>
      <Flag
        {...({
          name: countryEntry(transaction.tranche.award.employee.residence).flag,
        } as FlagProps)}
      />
      {countryEntry(transaction.tranche.award.employee.residence).text}
    </Table.Cell>
    <Table.Cell>
      {transaction.tranche.award.incentiveSubProgram.incentiveProgram.name}
    </Table.Cell>
    <Table.Cell>
      {transaction.tranche.award.incentiveSubProgram.name}
    </Table.Cell>
    <Table.Cell>{transaction.transactionType}</Table.Cell>
    <Table.Cell>
      {moment(transaction.transactionDate).format(dateFormat.normalDateFormat)}
    </Table.Cell>
    <Table.Cell>
      {transaction.tranche.award.incentiveSubProgram.instrumentTypeId}
    </Table.Cell>
    <Table.Cell>
      {transaction.tranche.award.incentiveSubProgram.settlementTypeId}
    </Table.Cell>
    <Table.Cell>
      {transaction.tranche.award.incentiveSubProgram.performance}
    </Table.Cell>
    <Table.Cell>
      {transaction.purchasePrice
        ? transaction.purchasePrice.replace(".", ",")
        : "N/A"}
    </Table.Cell>
    <Table.Cell>
      {transaction.taxableInputValuation
        ? transaction.taxableInputValuation.replace(".", ",")
        : NO_VALUE}
    </Table.Cell>
    <Table.Cell>
      {transaction.strike
        ? transaction.strike.toString().replace(".", ",")
        : NO_VALUE}
    </Table.Cell>
    <Table.Cell>{formatNumber(transaction.quantity)}</Table.Cell>
    <Table.Cell />
    <Table.Cell>
      {transaction.transactionType === TransactionType.EXERCISE
        ? -1 * transaction.quantity
        : NO_VALUE}
    </Table.Cell>
    <Table.Cell>
      {transaction.transactionType === TransactionType.RELEASE
        ? -1 * transaction.quantity
        : NO_VALUE}
    </Table.Cell>
    <Table.Cell>
      {transaction.transactionType === TransactionType.TERMINATION
        ? -1 * transaction.quantity
        : NO_VALUE}
    </Table.Cell>
    <Table.Cell>
      {transaction.transactionType === TransactionType.EXPIRY
        ? -1 * transaction.quantity
        : NO_VALUE}
    </Table.Cell>
    <Table.Cell>
      {transaction.grantDate
        ? moment(transaction.grantDate).format(dateFormat.normalDateFormat)
        : NO_VALUE}
    </Table.Cell>
    <Table.Cell>
      {transaction.vestedDate
        ? moment(transaction.vestedDate).format(dateFormat.normalDateFormat)
        : NO_VALUE}
    </Table.Cell>
    <Table.Cell>
      {transaction.expiryDate
        ? moment(transaction.expiryDate).format(dateFormat.normalDateFormat)
        : NO_VALUE}
    </Table.Cell>
    <Table.Cell>{NO_VALUE}</Table.Cell>
    <Table.Cell>{transaction.fairValue || NO_VALUE}</Table.Cell>
    <Table.Cell>{transaction.fairValueForSocSec || NO_VALUE}</Table.Cell>
    <Table.Cell>
      {transaction.capOnGain
        ? transaction.capOnGain.replace(".", ",")
        : NO_VALUE}
    </Table.Cell>
    <Table.Cell>
      {transaction.performanceStartDate
        ? moment(transaction.performanceStartDate, apiShortDate).format(
            dateFormat.normalDateFormat
          )
        : NO_VALUE}
    </Table.Cell>
    <Table.Cell>
      {transaction.performanceEndDate
        ? moment(transaction.performanceEndDate, apiShortDate).format(
            dateFormat.normalDateFormat
          )
        : NO_VALUE}
    </Table.Cell>
    <Table.Cell>{NO_VALUE}</Table.Cell>
  </Table.Row>
);

export default TransactionRow;
