import { Moment } from "moment";
import { createAction } from "redux-act";

interface GenerateReportPayload {
  overviewData: any[][];
  ifrsData: any[][];
  ifrsYtdData?: any[][];
  socSecData: any[][];
  socSecYtdData?: any[][];
  dilutionData: any[][];
  notesData: any[][];
  employeeNotesData: any[][];
  fromDate: Moment;
  toDate: Moment;
  filename: string;
}

export const fetchReports = createAction("FETCH_REPORTS");

export const fetchReportsSucceeded = createAction(
  "FETCH_REPORTS_SUCCEEDED",
  (payload: Api.V1.Report[]) => payload
);

export const postReport = createAction(
  "POST_REPORT",
  (payload: { report: Api.V1.Report; redirectUrl?: string }) => payload
);

export const postReportSucceeded = createAction(
  "POST_REPORT_SUCCEEDED",
  (payload: Api.V1.Report) => payload
);

export const updateReport = createAction(
  "UPDATE_REPORT",
  (payload: Api.V1.Report) => payload
);

export const updateReportSucceeded = createAction(
  "UPDATE_REPORT_SUCCEEDED",
  (payload: Api.V1.Report) => payload
);

export const deleteReport = createAction(
  "DELETE_REPORT",
  (payload: Api.V1.Report["id"]) => payload
);

export const deleteReportSucceeded = createAction(
  "DELETE_REPORT_SUCCEEDED",
  (payload: Api.V1.Report["id"]) => payload
);

export const fetchReportsAllAwards = createAction("FETCH_REPORTS_ALL_AWARDS");

export const fetchReportsAllAwardsSucceeded = createAction(
  "FETCH_REPORTS_ALL_AWARDS_SUCCEEDED",
  (payload: Api.V1.VestingEvent[]) => payload
);

export const generateReport = createAction(
  "GENERATE_REPORT",
  (payload: GenerateReportPayload) => payload
);
