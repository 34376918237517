import { push } from "connected-react-router";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  createDividend,
  createDividendSucceeded,
  fetchDividends,
  fetchDividendsSucceeded,
} from "src/admin-portal/dividend/dividend-actions";
import { Dividend } from "src/admin-portal/dividend/dividend-reducer";
import { dividendsRoute } from "src/admin-portal/menu/admin-portal-menu";
import { callApi, Method, NOT_AUTHORIZED } from "src/common/api/api-helper";
import * as selectors from "src/selectors";

const dividendsUrl = (tenantId: string) => `/dividends?tenantId=${tenantId}`;

function* fetchDividendsRequested() {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);

    const response = yield call(() => callApi(dividendsUrl(tenantId), token));
    yield put(fetchDividendsSucceeded(response.data as Dividend[]));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
    }
  }
}

export function* watchFetchDividends() {
  yield takeEvery(fetchDividends.getType(), fetchDividendsRequested);
}

function* createDividendRequested(action: ReturnType<typeof createDividend>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);

    const response = yield call(() =>
      callApi(dividendsUrl(tenantId), token, Method.POST, action.payload)
    );
    yield put(createDividendSucceeded(response.data as Dividend));
    yield put(push(dividendsRoute));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
    }
  }
}

export function* watchCreateDividend() {
  yield takeEvery(createDividend.getType(), createDividendRequested);
}
