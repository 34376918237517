import React, { FunctionComponent } from "react";
import { Message } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import BICAndIBAN from "src/common/components/payment/bic-iban";

interface PaymentInfoProps {
  bankAccountNumber?: string;
  bicNumber?: string;
  ibanNumber?: string;
  address?: React.ReactElement<any>;
  description?: React.ReactElement<any>;
}

const PaymentInfo: FunctionComponent<PaymentInfoProps> = ({
  bankAccountNumber,
  bicNumber,
  ibanNumber,
  address,
  description,
}) => (
  <div>
    <Message info={true}>
      <div className="block-s">
        <Message.Header>
          <Content id="payment.header" />
        </Message.Header>
      </div>
      <Message.Content>
        {description && <div className="block-s">{description}</div>}
        <div className="block-s">
          <div className="block-xxs">
            <strong>
              <Content id="payment.domestic.account.header" />
            </strong>
          </div>
          <div>
            <Content
              id={
                bankAccountNumber
                  ? "payment.domestic.account.description"
                  : "payment.domestic.noaccount.description"
              }
              values={{ bankAccountNumber }}
            />
          </div>
        </div>

        <div className="block-s">
          <div className="block-xxs">
            <strong>
              <Content id="payment.foreign.account.header" />
            </strong>
          </div>
          {bicNumber && ibanNumber ? (
            <BICAndIBAN bic={bicNumber} iban={ibanNumber} />
          ) : (
            <div className="block-xxs">
              <Content id="payment.foreign.noaccount.description" />
            </div>
          )}
          {address}
        </div>
      </Message.Content>
    </Message>
  </div>
);

export default PaymentInfo;
