import Jsona from "jsona";
import Raven from "raven-js";

import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  deleteContentTemplateValues,
  deleteContentTemplateValuesSucceeded,
  fetchContents,
  fetchContentsSucceded,
  patchContentTemplateValues,
  patchContentTemplateValuesSucceeded,
  postContentTemplateValues,
  postContentTemplateValuesSucceeded,
  updateContent,
  updateContentSucceeded,
} from "src/admin-portal/content/content-actions";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import * as selectors from "src/selectors";

const dataFormatter = new Jsona();

const fetchContentsUrl = "/content-templates?include=contentTemplateValues";
const updateContentUrl = (contentId: string) =>
  `/content-template-values/${contentId}`;

function* fetchContentsRequested() {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);

    const response = yield call(() => callApi(fetchContentsUrl, token));
    yield put(
      fetchContentsSucceded(
        dataFormatter.deserialize(response) as Api.V1.ContentTemplate[]
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
    }
  }
}

export function* watchFetchContent() {
  yield takeEvery(fetchContents.getType(), fetchContentsRequested);
}

function* updateContentRequested(action: ReturnType<typeof updateContent>) {
  try {
    const token = yield select(selectors.token);

    const response = yield call(() =>
      callApi(updateContentUrl(action.payload.contentId), token, "PUT", {
        data: {
          id: action.payload.contentId,
          type: "contentTemplateValues",
          attributes: {
            value: action.payload.content.raw_content,
            locale: action.payload.content.locale,
          },
        },
      })
    );
    yield put(
      updateContentSucceeded(
        dataFormatter.deserialize(response) as Api.V1.ContentTemplate
      )
    );
    yield put(fetchContents());
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
    }
  }
}

export function* watchUpdateContent() {
  yield takeEvery(updateContent.getType(), updateContentRequested);
}

const postContentTemplateValuesUrl = (contentTemplatesId: string) =>
  `/content-templates/${contentTemplatesId}/content-template-values`;

function* postContentTemplateValuesRequested(
  action: ReturnType<typeof postContentTemplateValues>
) {
  try {
    const token = yield select(selectors.token);
    const method = "POST";
    const body = dataFormatter.serialize({
      stuff: {
        ...action.payload.contentTemplateValue,
        type: "contentTemplateValues",
      },
    });

    const response = yield call(() =>
      callApi(
        postContentTemplateValuesUrl(action.payload.contentId),
        token,
        method,
        body
      )
    );

    yield put(
      postContentTemplateValuesSucceeded(
        dataFormatter.deserialize(response) as Api.V1.ContentTemplateValue
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
    }
  }
}

export function* watchPostContentTemplateValues() {
  yield takeEvery(
    postContentTemplateValues.getType(),
    postContentTemplateValuesRequested
  );
}

const patchDeleteContentTemplateValuesUrl = (contentTemplateValuesId: string) =>
  `/content-template-values/${contentTemplateValuesId}`;

function* patchContentTemplateValuesRequested(
  action: ReturnType<typeof patchContentTemplateValues>
) {
  try {
    const token = yield select(selectors.token);
    const method = "PATCH";

    const body = dataFormatter.serialize({
      stuff: {
        ...action.payload.contentTemplateValue,
        type: "contentTemplateValues",
      },
    });

    const response = yield call(() =>
      callApi(
        patchDeleteContentTemplateValuesUrl(
          action.payload.contentTemplateValueId
        ),
        token,
        method,
        body
      )
    );

    yield put(
      patchContentTemplateValuesSucceeded(
        dataFormatter.deserialize(response) as Api.V1.ContentTemplateValue
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
    }
  }
}

export function* watchPatchContentTemplateValues() {
  yield takeEvery(
    patchContentTemplateValues.getType(),
    patchContentTemplateValuesRequested
  );
}

function* deleteContentTemplateValuesRequested(
  action: ReturnType<typeof deleteContentTemplateValues>
) {
  try {
    const token = yield select(selectors.token);
    const method = "DELETE";
    yield call(() =>
      callApi(
        patchDeleteContentTemplateValuesUrl(
          action.payload.contentTemplateValueId
        ),
        token,
        method
      )
    );

    yield put(
      deleteContentTemplateValuesSucceeded({
        contentTemplateValueId: action.payload.contentTemplateValueId,
        contentTemplateId: action.payload.contentTemplateId,
      })
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
    }
  }
}

export function* watchDeleteContentTemplateValues() {
  yield takeEvery(
    deleteContentTemplateValues.getType(),
    deleteContentTemplateValuesRequested
  );
}
