import { useDispatch } from "react-redux";

import {
  putExerciseOrder,
  putPurchaseOrder,
} from "src/employee-portal/actions/order";
import { FormValues } from "src/employee-portal/order/table/UpdateModal/Form/useValidationSchema";
import { HasNorwegianAccountAnswer } from "src/employee-portal/purchase/steps/share-depository/norwegian-share-depo-radio";

type FormSubmitFunction = (values: FormValues) => void;

const processPurchaseValues = (
  values: FormValues,
  hasNorwegianAccount: HasNorwegianAccountAnswer
): FormValues => {
  if (hasNorwegianAccount === HasNorwegianAccountAnswer.YES) {
    return Object.assign(
      {},
      ...Object.entries(values).map(([key, value]) =>
        key === "shareDepositoryNorwegian"
          ? { [key]: value }
          : { [key]: key.startsWith("shareDepository") ? null : value }
      )
    );
  }

  if (hasNorwegianAccount === HasNorwegianAccountAnswer.NO) {
    return Object.assign(
      {},
      ...Object.entries(values).map(([key, value]) =>
        key === "shareDepositoryNorwegian" ? { [key]: null } : { [key]: value }
      )
    );
  }
};

interface Props {
  order: Api.V1.Order;
  hasNorwegianAccount: HasNorwegianAccountAnswer;
}

const useFormSubmit = ({
  order,
  hasNorwegianAccount,
}: Props): FormSubmitFunction => {
  const dispatch = useDispatch();

  if (order.orderType === "EXERCISE") {
    return (values: FormValues) => {
      dispatch(
        putExerciseOrder({
          orderId: order.exerciseOrder.id,
          data: values as Api.V1.ExerciseOrder,
        })
      );
    };
  }

  return (values: FormValues) => {
    const processedValues = processPurchaseValues(values, hasNorwegianAccount);

    dispatch(
      putPurchaseOrder({
        orderId: order.purchaseOrder.id,
        data: processedValues as Api.V1.PurchaseOrder,
      })
    );
  };
};

export default useFormSubmit;
