import Jsona from "jsona";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  fetchAuditLogs,
  fetchAuditLogsSucceeded,
} from "src/admin-portal/audit-logs/audit-logs-actions";
import * as selectors from "src/admin-portal/tenant/tenant-selectors";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { generatePaginationPagesWithNumbers } from "src/common/utils/pagination";
import { USER_NOT_AUTHORIZED } from "src/reducers/user";

const dataFormatter = new Jsona();

const auditLogsUrl = (tenantId: string, pageNumber: string) =>
  `/tenants/${tenantId}/audit-logs?page[number]=${pageNumber}&page[size]=100`;

function* fetchAuditLogsRequested(action: ReturnType<typeof fetchAuditLogs>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);
    const response = yield call(() =>
      callApi(auditLogsUrl(tenantId, action.payload.pageNumber), token)
    );

    yield put(
      fetchAuditLogsSucceeded({
        auditLogs: {
          [action.payload.pageNumber]: dataFormatter.deserialize(
            response
          ) as Api.V1.AuditLog[],
        },
        auditLogsPaginationLinks: generatePaginationPagesWithNumbers(
          response.links
        ),
      })
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: USER_NOT_AUTHORIZED });
    } else if (e.errorMessage) {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchAuditLogs() {
  yield takeEvery(fetchAuditLogs.getType(), fetchAuditLogsRequested);
}
