import { Moment } from "moment";

export function turnoverEffectFromEarnedRate(
  totalTurnoverRate: number,
  earnedRate: number
) {
  return totalTurnoverRate - totalTurnoverRate * earnedRate;
}

export function calcTotalTurnoverRate(
  from: Moment,
  to: Moment,
  turnoverRate: number
) {
  const years = Math.max(0, to.diff(from, "years", true));
  return Math.pow(1 + turnoverRate, years) - 1;
}

export function calcCostIncTurnover(turnoverEffect: number, cost: number) {
  return (1 - turnoverEffect) * cost;
}
