import React, { FunctionComponent } from "react";
import { Icon, Popup } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import FormatSharePrice from "src/common/components/format-share-price";
import { SharePrice } from "src/employee-portal/exercise/exercise-router";

interface Props {
  sharePrice: SharePrice;
  normalDateFormat: string;
}

const SharePriceMessage: FunctionComponent<Props> = ({
  sharePrice,
  normalDateFormat,
}) => {
  const formatMessage = useFormatMessage();

  if (!sharePrice) {
    return null;
  }

  return (
    <span className="share-price-message">
      <Content id="sharePrice.message.title" />
      <FormatSharePrice
        number={sharePrice.sharePrice}
        className={"share-price-value"}
      />
      <Popup
        trigger={<Icon name="question circle outline" />}
        header={formatMessage({ id: "sharePrice.message.popup.header" })}
        content={
          sharePrice.manual ? (
            <Content
              id="sharePrice.message.popup.content.lastAvailablePrice"
              values={{
                date: sharePrice.sharePriceDate.format(normalDateFormat),
              }}
            />
          ) : (
            <Content
              id="sharePrice.message.popup.content.endOfDayPrice"
              values={{
                date: sharePrice.sharePriceDate.format(normalDateFormat),
              }}
            />
          )
        }
      />
    </span>
  );
};

export default SharePriceMessage;
