import moment, { Moment } from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Button, Label } from "semantic-ui-react";

import XLSX from "xlsx";

import { FETCH_TENANT_VESTING_EVENTS } from "src/admin-portal/awards/award-actions";
import {
  trancheDataArray,
  transactionDataArray,
} from "src/admin-portal/awards/award-selectors";
import {
  CREATE_TRANSACTIONS,
  UPDATE_TRANSACTIONS,
} from "src/admin-portal/awards/transaction/transaction-actions";
import {
  closePostEmailSucceededNotification,
  postEmail,
} from "src/admin-portal/email/email-actions";
import EmailEmployeesModal from "src/admin-portal/email/email-employees-modal";
import { fetchEntities } from "src/admin-portal/entity/entity-actions";
import {
  initSearchObj,
  initSearchQuery,
  SearchObj,
} from "src/admin-portal/menu/admin-portal-menu";
import {
  closePatchTranchePerformanceRuleSucceededNotification,
  fetchPerformanceRules,
  patchTranchePerformanceRule,
} from "src/admin-portal/performance-rules/performance-rules-page/performance-rules-actions";
import { FETCH_PROGRAMS_AND_SUBPROGRAMS } from "src/admin-portal/programs/program-actions";
import AttachPerformanceCriteriaModal from "src/admin-portal/tranches-page/actions/attach-performance-criteria-modal";
import EditTransactionsModal from "src/admin-portal/tranches-page/actions/edit-transactions-modal";
import FairValueManangement from "src/admin-portal/tranches-page/actions/fair-value-management";
import ReverseQuantityModal from "src/admin-portal/tranches-page/actions/reverse-quantity/reverse-quantity-modal";
import TrancheFilters from "src/admin-portal/tranches-page/filter/tranche-filters";
import TrancheTable from "src/admin-portal/tranches-page/tranche-table";
import { TrancheDataEntry } from "src/admin-portal/tranches-page/types";
import {
  attachStatusKeyToPaginatedTranches,
  extractGrants,
  genTranchesPaginationLinks,
  getRecipients,
  parseSearchData,
} from "src/admin-portal/tranches-page/utils";
import Pagination, { pageSizeOptions } from "src/common/components/pagination";
import SpinnerFullScreen from "src/common/components/spinner-full-screen";
import { RootState } from "src/reducers/all-reducers";
import { selectedTenant } from "src/selectors";

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps & RouteComponentProps;

export interface State {
  data: TrancheDataEntry[];
  hasFilter: boolean;
  name: string;
  locale: string;
  instrumentType: string;
  entity_id: string;
  program_id: string;
  subProgramId: string;
  fair_value: string;
  status: string;
  strikeNumberRange: NumberRangeState;
  quantityNumberRange: NumberRangeState;
  exercisedQuantityNumberRange: NumberRangeState;
  releasedQuantityNumberRange: NumberRangeState;
  expiredQuantityNumberRange: NumberRangeState;
  terminationQuantityNumberRange: NumberRangeState;
  grantDateRange: DateRangeState;
  vestingDateRange: DateRangeState;
  expiryDateRange: DateRangeState;
  isEditTransactionsModalOpen: boolean;
  isFairValueMngModalOpen: boolean;
  areFiltersVisible: boolean;
  userWantToEditFairValues: boolean;
  userWantToExportEverything: boolean;
  userWantToExportTransactions: boolean;
}

interface NumberRangeState {
  from: null | number;
  to: null | number;
}

const numberRangeState = {
  strikeNumberRange: {
    from: null,
    to: null,
  },
  quantityNumberRange: {
    from: null,
    to: null,
  },
  exercisedQuantityNumberRange: {
    from: null,
    to: null,
  },
  releasedQuantityNumberRange: {
    from: null,
    to: null,
  },
  expiredQuantityNumberRange: {
    from: null,
    to: null,
  },
  terminationQuantityNumberRange: {
    from: null,
    to: null,
  },
};

interface DateRangeState {
  startDate: null | Moment;
  endDate: null | Moment;
}

const dateRangeState = {
  grantDateRange: {
    startDate: null,
    endDate: null,
  },
  vestingDateRange: {
    startDate: null,
    endDate: null,
  },
  expiryDateRange: {
    startDate: null,
    endDate: null,
  },
};

export const defaultPageSize = 50;

class TranchesPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: props.allTranches,
      hasFilter: false,
      ...parseSearchData(props.searchFromUrl),
      isEditTransactionsModalOpen: false,
      isFairValueMngModalOpen: false,
      areFiltersVisible: false,
      userWantToEditFairValues: false,
      userWantToExportEverything: false,
      userWantToExportTransactions: false,
    };
  }

  public componentDidMount() {
    const { programs, entities, fetchPrograms, fetchEntities } = this.props;

    this.fetchTranches();
    programs || fetchPrograms();
    entities || fetchEntities();
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      allTranches,
      recordCount,
      isFetchingTranches,
      isCreatingTransactions,
      locationKey,
      searchFromUrl,
    } = this.props;
    const {
      isEditTransactionsModalOpen,
      isFairValueMngModalOpen,
      userWantToEditFairValues,
      userWantToExportEverything,
      userWantToExportTransactions,
      hasFilter,
    } = this.state;
    const pageDidChange = locationKey !== prevProps.locationKey;
    const haveBeenPaginatedTrachesUpdated =
      prevProps.allTranches !== allTranches;
    const shouldCloseFairValueModal =
      isFairValueMngModalOpen &&
      ((!isFetchingTranches && prevProps.isFetchingTranches) ||
        (!isCreatingTransactions && prevProps.isCreatingTransactions));

    if (pageDidChange) {
      this.fetchTranches();
      if (
        hasFilter &&
        initSearchQuery === queryString.stringify(searchFromUrl)
      ) {
        this.resetFilterState();
      }
    }

    if (haveBeenPaginatedTrachesUpdated) {
      this.setState(
        {
          data: allTranches,
        },
        () => {
          const allTranchesAreFetched = recordCount === allTranches.length;
          if (allTranchesAreFetched && userWantToExportEverything) {
            this.toExcel();
          } else if (allTranchesAreFetched && userWantToExportTransactions) {
            this.transactionsToExcel();
          } else if (allTranchesAreFetched && userWantToEditFairValues) {
            this.handleFairValueMngModal();
          } else if (isEditTransactionsModalOpen) {
            this.closeModal();
          }
        }
      );
    }
    if (shouldCloseFairValueModal) {
      this.setState({ isFairValueMngModalOpen: false });
    }
  }

  public handleSort = clickedColumn => () => {
    const { history, baseUrl, searchFromUrl } = this.props;

    const sortBy =
      clickedColumn === searchFromUrl.sortBy
        ? "-" + clickedColumn
        : clickedColumn;

    history.push(
      `${baseUrl}?${queryString.stringify({
        ...searchFromUrl,
        pageNum: 1,
        sortBy,
      })}`
    );
  };

  public handleNumberRangeChange = (key: string, from: number, to: number) => {
    this.setState({
      [key]: { from, to },
      hasFilter: true,
    } as Pick<State, "strikeNumberRange" | "quantityNumberRange" | "exercisedQuantityNumberRange" | "releasedQuantityNumberRange" | "expiredQuantityNumberRange" | "terminationQuantityNumberRange" | "hasFilter">);
  };

  public handleDateRangeChange = (
    key: string,
    startDate: Moment,
    endDate: Moment
  ) => {
    startDate = startDate || this.state[key].startDate;
    endDate = endDate || this.state[key].endDate;

    this.setState({
      [key]: { startDate, endDate },
      hasFilter: true,
    } as Pick<State, "grantDateRange" | "vestingDateRange" | "expiryDateRange" | "hasFilter">);
  };

  public handleDateRangeChangeStart = (key: string, startDate: Moment) => {
    this.handleDateRangeChange(key, startDate, null);
  };

  public handleDateRangeChangeEnd = (key: string, endDate: Moment) => {
    this.handleDateRangeChange(key, null, endDate);
  };

  public toggleFilters = () => {
    this.setState(state => ({
      areFiltersVisible: !state.areFiltersVisible,
    }));
  };

  public render() {
    const {
      data,
      areFiltersVisible,
      isEditTransactionsModalOpen,
      isFairValueMngModalOpen,
    } = this.state;
    const {
      allTranches,
      programs,
      entities,
      isSysadmin,
      createTransactions,
      updateTransactions,
      isCreatingTransactions,
      dateFormat,
      performanceRules,
      fetchPerformanceRules,
      closePatchTranchePerformanceRuleSucceededNotification,
      postEmail,
      email,
      closeEmailNotification,
      history,
      recordCount,
      baseUrl,
      searchFromUrl,
      isFetchingTranches,
    } = this.props;

    if (isFetchingTranches || isCreatingTransactions || !this.state.data) {
      return <SpinnerFullScreen active={true} />;
    }

    const grantTransactions = extractGrants(data, dateFormat.normalDateFormat);

    return (
      <div className="flex-1-0-auto">
        <TrancheFilters
          fromTranchesPageState={this.state}
          dateFormat={dateFormat.normalDateFormat}
          programs={programs}
          entities={entities}
          isSysadmin={isSysadmin}
          resetFilter={this.resetFilter}
          handleChange={this.handleChange}
          handleNumberRangeChange={this.handleNumberRangeChange}
          handleDateRangeChangeEnd={this.handleDateRangeChangeEnd}
          handleDateRangeChangeStart={this.handleDateRangeChangeStart}
          history={history}
          baseUrl={baseUrl}
          searchFromUrl={searchFromUrl}
        />

        <div className="sideways-scrollable">
          <div className="block-m" />
          <div className="text-center">
            {isEditTransactionsModalOpen && (
              <EditTransactionsModal
                grantTransactions={grantTransactions}
                updateTransactions={updateTransactions}
                closeModal={this.closeModal}
                handleAdjustmentTransaction={this.handleAdjustmentTransaction}
                dateFormat={dateFormat.normalDateFormat}
                tranches={data}
              />
            )}
            <AttachPerformanceCriteriaModal
              performanceRulesState={performanceRules}
              fetchPerformanceRules={fetchPerformanceRules}
              selectedTranchesCount={data.length}
              handlePerformanceCriteria={this.handlePerformanceCriteria}
              closePatchTranchePerformanceRuleSucceededNotification={
                closePatchTranchePerformanceRuleSucceededNotification
              }
              isSysadmin={isSysadmin}
            />
            {isSysadmin && <ReverseQuantityModal tranches={data} />}
            <Button
              basic={true}
              style={{ display: !isSysadmin && "none" }}
              content="Edit Transactions"
              name="isEditTransactionsModalOpen"
              value={true}
              onClick={this.openEditTransactionsModal}
            />
            <Button
              basic={true}
              onClick={this.toExcel}
              content="Excel export"
            />
            <Button
              basic={true}
              onClick={this.transactionsToExcel}
              style={{ display: !isSysadmin && "none" }}
              content="Excel export transactions"
            />
            {isSysadmin && (
              <EmailEmployeesModal
                recipients={getRecipients(data)}
                postEmail={postEmail}
                email={email}
                closeEmailNotification={closeEmailNotification}
              />
            )}
            <Button
              basic={true}
              style={{ display: !isSysadmin && "none" }}
              content="Manage Fair Values"
              value={true}
              onClick={this.handleFairValueMngModal}
            />
            {isFairValueMngModalOpen && (
              <FairValueManangement
                tranches={data}
                createTransactions={createTransactions}
                updateTransactions={updateTransactions}
                normalDateFormat={dateFormat.normalDateFormat}
                isFairValueMngModalOpen={isFairValueMngModalOpen}
                close={() => this.setState({ isFairValueMngModalOpen: false })}
                recordCount={recordCount}
              />
            )}
            <Button
              icon="filter"
              basic={true}
              onClick={this.toggleFilters}
              content={areFiltersVisible ? "Hide Filters" : "Show Filters"}
            />
            <Label basic={true} color="blue">
              {data.length} of {recordCount} tranches
            </Label>
          </div>
          <Pagination
            paginationLinks={genTranchesPaginationLinks(
              searchFromUrl.pageSize,
              recordCount
            )}
            currentPageNumber={searchFromUrl.pageNum.toString()}
            baseUrl={baseUrl}
            history={history}
            hide={false}
            searchFromUrl={searchFromUrl}
            showPgSzDropdown={true}
          />
          <TrancheTable
            dateFormat={dateFormat}
            data={data}
            handleSort={this.handleSort}
            isSysadmin={isSysadmin}
            toggleRowSelect={this.toggleSelect}
            sortedBy={searchFromUrl.sortBy}
          />
          <Pagination
            paginationLinks={genTranchesPaginationLinks(
              searchFromUrl.pageSize,
              recordCount
            )}
            currentPageNumber={searchFromUrl.pageNum.toString()}
            baseUrl={baseUrl}
            history={history}
            hide={false}
            searchFromUrl={searchFromUrl}
          />
        </div>
      </div>
    );
  }

  private handleFairValueMngModal = () => {
    const { history, recordCount, baseUrl, searchFromUrl } = this.props;
    const { data } = this.state;
    const maxPageSize = Math.max(...pageSizeOptions.map(item => item.value));

    if (recordCount === data.length) {
      this.setState({
        isFairValueMngModalOpen: true,
        userWantToEditFairValues: false,
      });
    } else {
      this.setState({ userWantToEditFairValues: true });

      history.push(
        `${baseUrl}?${queryString.stringify({
          ...searchFromUrl,
          pageNum: 1,
          pageSize: maxPageSize,
        })}`
      );
    }
  };

  private handlePerformanceCriteria = performanceRuleId => {
    const dataForBackend = this.state.data.map(tranche => ({
      trancheId: tranche.id,
      weightFactor: "1",
    }));
    this.props.patchTranchePerformanceRules(performanceRuleId, dataForBackend);
  };

  private handleAdjustmentTransaction = adjustmentTransaction => {
    const transactions = this.state.data.map(tranche => ({
      transaction: { type: "transactions", ...adjustmentTransaction },
      trancheId: tranche.id,
    }));
    this.props.createTransactions(transactions);
  };

  private closeModal = () =>
    this.setState({ isEditTransactionsModalOpen: false } as Pick<
      State,
      keyof State
    >);
  private openEditTransactionsModal = () =>
    this.setState({ isEditTransactionsModalOpen: true } as Pick<
      State,
      keyof State
    >);

  private handleChange = (event, { value, name }) => {
    this.setState({
      [name]: value,
      hasFilter: true,
    } as Pick<State, keyof State>);
  };

  private resetFilterState = () => {
    this.setState({
      hasFilter: false,
      name: "",
      instrumentType: "",
      entity_id: "",
      program_id: "",
      subProgramId: "",
      fair_value: "",
      ...numberRangeState,
      ...dateRangeState,
      locale: "",
      status: "",
    });
  };

  private resetFilter = () => {
    const { history, baseUrl } = this.props;

    this.resetFilterState();
    history.push(`${baseUrl}?${queryString.stringify(initSearchObj)}`);
  };

  private toggleSelect = id => {
    const newSelectedState = this.state.data.map(d =>
      d.id === id ? { ...d, selected: !d.selected } : d
    );

    this.setState({ data: newSelectedState });
  };

  private toExcel = () => {
    const { history, recordCount, baseUrl, searchFromUrl } = this.props;
    const maxPageSize = Math.max(...pageSizeOptions.map(item => item.value));

    if (this.state.data.length !== recordCount) {
      this.setState({ userWantToExportEverything: true });

      history.push(
        `${baseUrl}?${queryString.stringify({
          ...searchFromUrl,
          pageNum: 1,
          pageSize: maxPageSize,
        })}`
      );

      return null;
    }

    const wb = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(
      trancheDataArray(this.state.data)
    );

    XLSX.utils.book_append_sheet(wb, workSheet, "Tranches");
    XLSX.writeFile(
      wb,
      `Optio-Tranches-${this.props.companyName}-${moment().format(
        this.props.dateFormat.normalDateFormat
      )}.xlsx`
    );

    this.setState({ userWantToExportEverything: false });
  };

  private transactionsToExcel = () => {
    const { history, baseUrl, searchFromUrl, recordCount } = this.props;
    const maxPageSize = Math.max(...pageSizeOptions.map(item => item.value));

    if (this.state.data.length !== recordCount) {
      this.setState({ userWantToExportTransactions: true });
      history.push(
        `${baseUrl}?${queryString.stringify({
          ...searchFromUrl,
          pageNum: 1,
          pageSize: maxPageSize,
        })}`
      );

      return null;
    }

    const wb = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(
      transactionDataArray(this.state.data)
    );

    XLSX.utils.book_append_sheet(wb, workSheet, "Transactions");
    XLSX.writeFile(
      wb,
      `Optio-Transactions-${this.props.companyName}-${moment().format(
        this.props.dateFormat.normalDateFormat
      )}.xlsx`
    );

    this.setState({ userWantToExportTransactions: false });
  };

  private fetchTranches = () => {
    const { searchFromUrl, fetchTranches } = this.props;

    const { pageNum, pageSize, sortBy, ...filters } = searchFromUrl;

    fetchTranches(pageNum, filters, sortBy, pageSize);
  };
}

const mapStateToProps = (state: RootState) => ({
  isFetchingTranches: state.award.isFetching,
  isCreatingTransactions: state.award.isCreatingTransactions,
  companyName: selectedTenant(state),
  isSysadmin: state.user.isSysadmin,
  entities: state.entity.allEntities,
  programs: state.program.allProgramsIncludingSubPrograms,
  hasPutTransactionErr: state.award.hasPutTransactionErr,
  dateFormat: state.dateFormat,
  allTranches: attachStatusKeyToPaginatedTranches(state.award.allTranches),
  performanceRules: state.performanceRules,
  email: state.email,
  recordCount: state.award.recordCount,
  baseUrl: state.router.location.pathname,
  searchFromUrl: queryString.parse(state.router.location.search) as SearchObj,
  locationKey: state.router.location.key,
});

const mapDispatchToProps = dispatch => ({
  fetchTranches: (page, filters, sort, pageSize) =>
    dispatch({
      type: FETCH_TENANT_VESTING_EVENTS,
      page,
      filters,
      sort,
      pageSize,
    }),
  fetchPrograms: () => dispatch({ type: FETCH_PROGRAMS_AND_SUBPROGRAMS }),
  fetchEntities: () => dispatch(fetchEntities()),
  createTransactions: transactions =>
    dispatch({ type: CREATE_TRANSACTIONS, transactions }),
  updateTransactions: transactions =>
    dispatch({ type: UPDATE_TRANSACTIONS, transactions }),
  fetchPerformanceRules: () => dispatch(fetchPerformanceRules()),
  patchTranchePerformanceRules: (performanceRuleId, dataForBackend) =>
    dispatch(
      patchTranchePerformanceRule({
        performanceRuleId,
        tranches: dataForBackend,
      })
    ),
  closePatchTranchePerformanceRuleSucceededNotification: () =>
    dispatch(closePatchTranchePerformanceRuleSucceededNotification()),
  postEmail: payload => dispatch(postEmail(payload)),
  closeEmailNotification: () => dispatch(closePostEmailSucceededNotification()),
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(TranchesPage);
