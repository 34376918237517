import React, { Fragment, FunctionComponent } from "react";

interface OverviewInstrumentContent {
  title: string | React.ReactNode;
  value: string | React.ReactNode;
}

interface Props {
  sections: OverviewInstrumentContent[][];
}

interface InstrumentOverviewSectionProps {
  section: OverviewInstrumentContent[];
}

const InstrumentOverviewSection: FunctionComponent<InstrumentOverviewSectionProps> = ({
  section,
}) => (
  <>
    {section.map((content, index) => (
      <InstrumentOverviewSectionRow {...content} key={index} />
    ))}
  </>
);

const InstrumentOverviewSectionRow: FunctionComponent<OverviewInstrumentContent> = ({
  title,
  value,
}) => (
  <div className="overview-instrument-content-table-row">
    <div>{title}</div>
    <div>{value}</div>
  </div>
);

const InstrumentOverviewContent: FunctionComponent<Props> = ({ sections }) => (
  <div className="overview-instrument-content">
    {sections.map((section, index, array) => (
      <Fragment key={index}>
        <InstrumentOverviewSection section={section} />
        {index !== array.length - 1 && <div className="divider-s" />}
      </Fragment>
    ))}
  </div>
);

export default InstrumentOverviewContent;
