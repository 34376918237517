import React, { FunctionComponent } from "react";
import { Form } from "semantic-ui-react";

import "src/common/components/Slider/style.less";
import Ruler from "src/common/components/Slider/Ruler";

interface Props {
  min?: number;
  max: number;
  value: number;
  step?: number;
  color?: string;
  rulerDivisions?: number;
  onChange: (newValue: number) => void;
}

const Slider: FunctionComponent<Props> = ({
  min,
  max,
  value,
  step,
  color,
  rulerDivisions,
  onChange,
}) => (
  <div className="slider">
    <Form.Input
      type="range"
      className={`${color}`}
      fluid={true}
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={(_, { value }) => onChange(parseFloat(value))}
    />

    {rulerDivisions && (
      <Ruler min={min} max={max} rulerDivisions={rulerDivisions} />
    )}
  </div>
);

export default Slider;
