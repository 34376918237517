import React, { FunctionComponent } from "react";
import { Icon, IconProps } from "semantic-ui-react";

const getFileExtension = (fileName: string) => {
  const split = fileName.split(".");
  return split[split.length - 1];
};

const excelFileExtensions = ["xls", "xlsx", "ods"];
const powerPointFileExtensions = ["ppt", "pptx"];

export const FileIcon: FunctionComponent<{
  fileName: string;
  iconProps?: IconProps;
}> = ({ fileName, iconProps = {} }) => {
  const fileExtension = getFileExtension(fileName);
  if (excelFileExtensions.includes(fileExtension)) {
    return <Icon name={"file excel outline"} {...iconProps} />;
  }

  if (powerPointFileExtensions.includes(fileExtension)) {
    return <Icon name={"file powerpoint outline"} {...iconProps} />;
  }

  if (fileExtension === "pdf") {
    return <Icon name={"file pdf outline"} {...iconProps} />;
  }

  return <Icon name={"file text outline"} {...iconProps} />;
};
