import React, { StatelessComponent } from "react";

import Content from "src/admin-portal/texts/content";

const OrderCancelled = () => (
  <span className="order-status order-status_wider order-status-cancelled">
    <Content id={"orders.status.cancelled"} />
  </span>
);
const OrderPlaced = () => (
  <span className="order-status order-status_wider order-status-created">
    <Content id={"orders.status.placed"} />
  </span>
);
const OrderCompleted = () => (
  <span className="order-status order-status_wider order-status-completed">
    <Content id={"orders.status.completed"} />
  </span>
);

const OrderStatusView: StatelessComponent<{
  status: Api.V1.Order["status"];
}> = ({ status }) => {
  if (status === "CANCELLED") {
    return <OrderCancelled />;
  }

  if (status === "COMPLETED") {
    return <OrderCompleted />;
  }

  if (status === "CREATED") {
    return <OrderPlaced />;
  }

  return <span className="status-normal">{status}</span>;
};

export default OrderStatusView;
