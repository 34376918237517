import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

import { useFormatMessage } from "src/admin-portal/texts/utils";
import CurrencyConversionView from "src/common/components/currency/currency-conversion-view";
import TextWithHelperText from "src/common/components/text-with-helper-text";
import { formatNumber } from "src/common/utils/utils";
import ExpandableSection from "src/employee-portal/home-page/instrument-overview-box/expandable-header";
import InstrumentOverviewContent from "src/employee-portal/home-page/instrument-overview-box/instrument-overview-content.tsx";
import { IndividualInstrumentState } from "src/employee-portal/instrument-page/instruments-reducer";

const PSUSection: FunctionComponent<{
  psu: IndividualInstrumentState;
  startExpanded?: boolean;
  hideNavigation?: boolean;
}> = ({ psu, startExpanded, hideNavigation }) => {
  const formatMessage = useFormatMessage();

  return (
    <ExpandableSection
      headerText={formatMessage({ id: "instruments.frontpagebox.psu.header" })}
      headerValue={psu.performanceAdjustedGain}
      startExpanded={startExpanded}
      compact={true}
    >
      <InstrumentOverviewContent
        sections={[
          // [
          //   {
          //     title: (
          //       <TextWithHelperText
          //         textKey={"instruments.frontpagebox.psu.maxgain"}
          //         popupTextKey={"instruments.frontpagebox.psu.maxgain.popup"}
          //       />
          //     ),
          //     value: (
          //       <CurrencyConversionView
          //         value={psu.gain.totalGain}
          //         decimals={0}
          //         showOverrideCurrencyOnly={true}
          //       />
          //     ),
          //   },
          //   {
          //     title: (
          //       <TextWithHelperText
          //         textKey={"instruments.frontpagebox.psu.maxinstruments"}
          //         popupTextKey={
          //           "instruments.frontpagebox.psu.maxinstruments.popup"
          //         }
          //       />
          //     ),
          //     value: formatNumber(psu.instrumentQuantity),
          //   },
          // ],
          [
            {
              title: (
                <TextWithHelperText
                  textKey={"instruments.frontpagebox.psu.perfadjgain"}
                  popupTextKey={
                    "instruments.frontpagebox.psu.perfadjgain.popup"
                  }
                />
              ),
              value: (
                <CurrencyConversionView
                  value={psu.performanceAdjustedGain}
                  decimals={0}
                  showOverrideCurrencyOnly={true}
                />
              ),
            },
            {
              title: (
                <TextWithHelperText
                  textKey={"instruments.frontpagebox.psu.perfadjinstruments"}
                  popupTextKey={
                    "instruments.frontpagebox.psu.perfadjinstruments.popup"
                  }
                />
              ),
              value: formatNumber(psu.performanceAdjustedQuantity),
            },
          ],
        ]}
      />
      {!hideNavigation && (
        <div className="text-center">
          <Button
            content={formatMessage({ id: "global.viewDetailsBtn" })}
            as={Link}
            to="/instruments/psus"
            basic={true}
            primary={true}
          />
        </div>
      )}
    </ExpandableSection>
  );
};
export default PSUSection;
