import Raven from "raven-js";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
  deleteSysadmin,
  deleteSysadminFailed,
  deleteSysadminSucceeded,
  fetchSysadmins,
  fetchSysadminsFailed,
  fetchSysadminsSucceeded,
  postSysadmin,
  postSysadminFailed,
  postSysadminSucceeded,
} from "src/admin-portal/sysadmin/sysadmin-actions";
import * as selectors from "src/admin-portal/sysadmin/sysadmin-selectors";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";

const SYSADMINS_REQUEST_URL = "/users";

function* fetchSysadminsRequested() {
  try {
    const token = yield select(selectors.token);
    const sysadminResponse = yield call(() =>
      callApi(SYSADMINS_REQUEST_URL, token)
    );

    const sysadmins = sysadminResponse.data.map(sysadmin => ({
      id: sysadmin.user_id,
      email: sysadmin.email,
      firstName: sysadmin.user_metadata.first_name,
      lastName: sysadmin.user_metadata.last_name,
      role: sysadmin.app_metadata.roles.includes("sysadmin")
        ? "sysadmin"
        : "noooope, not a sysadmin",
      avatar: sysadmin.picture,
    }));

    yield put(fetchSysadminsSucceeded(sysadmins));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put(fetchSysadminsFailed(e.message));
    }
  }
}

export function* watchFetchSysadmins() {
  yield takeEvery(fetchSysadmins.getType(), fetchSysadminsRequested);
}

function* postSysadminRequested(action: ReturnType<typeof postSysadmin>) {
  try {
    const token = yield select(selectors.token);
    const method = "POST";

    const sysadminResponse = yield call(() =>
      callApi(SYSADMINS_REQUEST_URL, token, method, action.payload)
    );

    const sysadmin = {
      id: sysadminResponse.user.user_id,
      email: sysadminResponse.user.email,
      firstName: sysadminResponse.user.user_metadata.first_name,
      lastName: sysadminResponse.user.user_metadata.last_name,
      avatar: sysadminResponse.user.picture,
    };

    yield put(postSysadminSucceeded(sysadmin));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put(postSysadminFailed(e.message));
    }
  }
}

export function* watchPostSysadmin() {
  yield takeLatest(postSysadmin.getType(), postSysadminRequested);
}

function* deleteSysadminRequested(action: ReturnType<typeof deleteSysadmin>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);
    const method = "DELETE";

    yield call(() =>
      callApi(
        `${SYSADMINS_REQUEST_URL}/${action.payload}?tenantId=${tenantId}`,
        token,
        method
      )
    );

    yield put(deleteSysadminSucceeded(action.payload));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put(deleteSysadminFailed(e.message));
    }
  }
}

export function* watchDeleteSysadmin() {
  yield takeEvery(deleteSysadmin.getType(), deleteSysadminRequested);
}
