import React, { FunctionComponent } from "react";
import { Button } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import CurrencyFormatter, {
  CurrencyFormatterProps,
} from "src/common/components/currency/currency-formatter";
import HelpPopup from "src/common/components/help-popup";
import {
  InstrumentType,
  instrumentTypeText,
} from "src/common/utils/text-mappings";
import {
  formatNumber,
  formatPercentage,
  simpleRound,
} from "src/common/utils/utils";

import { PurchaseType, NO_VALUE } from "src/constants";
import { PurchaseInfo } from "src/employee-portal/purchase/duck/purchase-selectors";
import { PaymentInfo } from "src/employee-portal/purchase/purchase-router";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface Props {
  placeOrder: () => void;
  goBack: () => void;
  quantity: number;
  cashAmount: number;
  pricePerInstrument: number;
  paymentInfo: PaymentInfo;
  purchaseInfo: PurchaseInfo;
  instrument: InstrumentType;
  discountRate?: number;
  loan?: number;
  loanPerPeriod?: number;
  dateFormat: DateFormatState;
}

const ConfirmQuantityPurchase: FunctionComponent<Props> = ({
  goBack,
  placeOrder,
  quantity,
  pricePerInstrument,
  paymentInfo,
  purchaseInfo,
  instrument,
  cashAmount,
  discountRate,
  dateFormat,
  loan,
  loanPerPeriod,
}) => {
  const formatMessage = useFormatMessage();
  const instrumentTerm = instrumentTypeText(instrument, formatMessage);
  const paymentPrice =
    purchaseInfo.type !== PurchaseType.PURCHASE_OPPORTUNITY_CASH
      ? pricePerInstrument * quantity
      : cashAmount;
  const hasLoan = typeof loan === "number";
  const hasLoanTerms = typeof loanPerPeriod === "number";
  const toPay = simpleRound(paymentPrice - (loan || 0));
  return (
    <CurrencyFormatter>
      {(formatter: CurrencyFormatterProps) => (
        <div>
          <div className="block-l">
            <h2 className="text-center block-m">
              <Content id="purchase.confirm.header" />
            </h2>
            <div className="block-m">
              <p className="purchase-narrow-width margin-center block-s">
                <Content
                  id="purchase.confirm.body.1"
                  values={{
                    pricePerInstrument: formatter.formatCurrency(
                      pricePerInstrument,
                      2
                    ),
                    numberOfInstruments: formatNumber(quantity),
                    totalAmount: formatter.formatCurrency(paymentPrice, 2),
                    instrumentTermPlural: instrumentTerm.plural,
                    instrumentTermSingular: instrumentTerm.singular,
                  }}
                />
              </p>
              <p className="purchase-narrow-width margin-center">
                <Content
                  id="purchase.confirm.body.2"
                  values={{
                    paymentDeadline: paymentInfo.paymentDeadline
                      ? paymentInfo.paymentDeadline.format(
                          dateFormat.normalDateFormat
                        )
                      : undefined,
                    pricePerInstrument: formatter.formatCurrency(
                      pricePerInstrument,
                      2
                    ),
                    numberOfInstruments: formatNumber(quantity),
                    totalAmount: formatter.formatCurrency(paymentPrice, 2),
                    instrumentTermPlural: instrumentTerm.plural,
                    instrumentTermSingular: instrumentTerm.singular,
                  }}
                />
              </p>
            </div>

            <div className="center-center">
              <div className="purchase-box">
                <div className="purchase-box-section">
                  <div
                    className={`purchase-box-section-item ${
                      hasLoan ? "block-xxs" : ""
                    }`}
                  >
                    <div>
                      <strong>
                        <Content
                          id={"purchase.quantity.totalPurchasePrice"}
                          values={{
                            instrumentTermPlural: instrumentTerm.plural,
                          }}
                        />
                      </strong>
                    </div>
                    <strong>
                      {toPay ? formatter.formatCurrency(toPay, 2) : NO_VALUE}
                    </strong>
                  </div>
                  {hasLoan ? (
                    <>
                      <div className="purchase-box-section-item">
                        <div>
                          <strong>
                            <Content
                              id={"purchase.quantity.summary.loan.label"}
                              values={{
                                instrumentTermPlural: instrumentTerm.plural,
                              }}
                            />
                          </strong>
                          <HelpPopup
                            textKey="purchase.quantity.summary.loan.popup"
                            values={{
                              instrumentTermPlural: instrumentTerm.plural,
                            }}
                          />
                        </div>
                        <strong>
                          <span>
                            {loan === 0
                              ? NO_VALUE
                              : formatter.formatCurrency(loan, 2)}
                          </span>
                        </strong>
                      </div>
                      {loanPerPeriod ? (
                        <>
                          <div className="block-xxs" />
                          <div className="purchase-box-section-item">
                            <div>
                              <Content
                                id={
                                  "purchase.quantity.summary.loanperiod.label"
                                }
                                values={{
                                  instrumentTermPlural: instrumentTerm.plural,
                                }}
                              />
                              <HelpPopup
                                textKey="purchase.quantity.summary.loanperiod.popup"
                                values={{
                                  instrumentTermPlural: instrumentTerm.plural,
                                }}
                              />
                            </div>
                            <span>
                              {loan === 0
                                ? NO_VALUE
                                : formatter.formatCurrency(loanPerPeriod, 2)}
                            </span>
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </div>
                <div className="divider" />
                <div className="purchase-box-section">
                  <Content
                    id={"purchase.quantity.confirm.content"}
                    values={{
                      pricePerInstrument: formatter.formatCurrency(
                        pricePerInstrument,
                        2
                      ),
                      numberOfInstruments: formatNumber(quantity),
                      totalPrice: formatter.formatCurrency(paymentPrice, 2),
                      instrumentTermPlural: instrumentTerm.plural,
                      instrumentTermSingular: instrumentTerm.singular,
                      discountPercent: formatPercentage(discountRate),
                      ...(hasLoan
                        ? { loan: formatter.formatCurrency(loan, 2) }
                        : {}),
                      ...(hasLoanTerms
                        ? {
                            loanPerPeriod: formatter.formatCurrency(
                              loanPerPeriod,
                              2
                            ),
                          }
                        : {}),
                      paymentDeadline: paymentInfo.paymentDeadline.format(
                        dateFormat.normalDateFormat
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="page-action-container text-center">
            <Button
              content={formatMessage({ id: "global.backBtnTxt" })}
              size="big"
              onClick={goBack}
            />
            <Button
              positive={true}
              content={<Content id={"purchase.confirm.btnText"} />}
              icon="right arrow"
              labelPosition="right"
              size="big"
              onClick={placeOrder}
            />
          </div>
        </div>
      )}
    </CurrencyFormatter>
  );
};

export default ConfirmQuantityPurchase;
