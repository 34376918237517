import React, { Component, StatelessComponent } from "react";
import { Modal } from "semantic-ui-react";

export const ErrorModal: StatelessComponent<{
  message: string;
  renderActions?: () => React.ReactNode;
}> = ({ message, renderActions }) => (
  <div className="text-center">
    <Modal open={true} size={"tiny"} closeIcon={true}>
      <Modal.Header className="text-center error-modal-header">
        Oops...
      </Modal.Header>
      <Modal.Content className="text-center">
        <div className="block-m error-modal-description">
          <Modal.Description>{message}</Modal.Description>
        </div>
        <div className="block-s">{renderActions && renderActions()}</div>
      </Modal.Content>
    </Modal>
  </div>
);
