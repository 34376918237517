import { createReducer } from "redux-act";

import {
  importAllModels,
  importAllModelsFailed,
  importAllModelsSucceeded,
} from "src/admin-portal/import/import-actions";

export interface ImportState {
  isLoading: boolean;
}

const initialState: ImportState = {
  isLoading: false,
};

export default createReducer(on => {
  on(importAllModels, (state, _) => ({ ...state, isLoading: true }));
  on(importAllModelsSucceeded, (state, _) => ({ ...state, isLoading: false }));
  on(importAllModelsFailed, (state, _) => ({ ...state, isLoading: false }));
}, initialState);
