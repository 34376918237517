export const FETCH_PURCHASE_DOCUMENT = "FETCH_PURCHASE_DOCUMENT";
export const FETCH_PURCHASE_DOCUMENT_SUCCEEDED =
  "FETCH_PURCHASE_DOCUMENT_SUCCEEDED";
export const FETCH_PURCHASE_DOCUMENT_FAILED = "FETCH_PURCHASE_DOCUMENT_FAILED";

export const CREATE_PURCHASE_ORDER = "CREATE_PURCHASE_ORDER";
export const CREATE_PURCHASE_ORDER_SUCCEEDED =
  "CREATE_PURCHASE_ORDER_SUCCEEDED";
export const CREATE_PURCHASE_ORDER_FAILED = "CREATE_PURCHASE_ORDER_FAILED";

export const REMOVE_PURCHASE_ORDER_ERROR = "REMOVE_PURCHASE_ORDER_ERROR";
