import Jsona from "jsona";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  fetchDailySummaries,
  fetchDailySummariesSucceeded,
  fetchDailySummariesFailed,
} from "src/admin-portal/summary/summary-actions";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { generatePaginationPagesWithNumbers } from "src/common/utils/pagination";
import * as selectors from "src/selectors";

const dataFormatter = new Jsona();

const dailySummariesUrl = (
  tenantId: string,
  pageNumber: string,
  sort: string
) =>
  `/tenants/${tenantId}/daily-summaries?page[number]=${pageNumber}&page[size]=20&sort=${sort}`;

function* fetchDailySummariesRequested(
  action: ReturnType<typeof fetchDailySummaries>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const response = yield call(() =>
      callApi(
        dailySummariesUrl(
          tenantId,
          action.payload.pageNumber,
          action.payload.sort || ""
        ),
        token
      )
    );
    yield put(
      fetchDailySummariesSucceeded({
        dailySummaries: {
          [action.payload.pageNumber]: dataFormatter.deserialize(
            response
          ) as Api.V1.DailySummary[],
        },
        dailySummariesPaginationLinks: generatePaginationPagesWithNumbers(
          response.links
        ),
      })
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put(fetchDailySummariesFailed(e.message));
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchDailySummaries() {
  yield takeEvery(fetchDailySummaries.getType(), fetchDailySummariesRequested);
}
