import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Message } from "semantic-ui-react";

import { useFormatMessage } from "src/admin-portal/texts/utils";
import "src/common/less/box.less";
import {
  canExerciseCurrentWindow,
  canReleaseCurrentWindow,
  userCurrencyCode,
  currentExerciseWindow,
  availableEvents,
} from "src/employee-portal/employee-portal-selectors";
import OverviewBox from "src/employee-portal/home-page/instrument-overview-box/overview-box";
import OverviewSections from "src/employee-portal/home-page/OverviewSections";
import TimelineBox from "src/employee-portal/timeline/timeline-box";
import { RootState } from "src/reducers/all-reducers";
import "src/employee-portal/home-page/overview.less";

interface PassedProps {
  companyName: string;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = PassedProps & StateProps;

const Home: FunctionComponent<Props> = ({
  convertibleLoan,
  share,
  features,
  warrant,
  rsa,
  rsu,
  psu,
  option,
  funds,
  deferredCash,
  subscriptionRights,
  totalGainsAllInstruments,
  currentWindow,
  nextWindow,
  canExerciseCurrentWindow,
  dateFormat,
  hasPurchased,
  timelineEvents,
}) => {
  const formatMessage = useFormatMessage();

  const numberOfInstrumentTypes = () => {
    const fundsAvailable = funds.allAwards.length > 0 ? 1 : 0;
    const deferredCashAvailable = deferredCash.allAwards.length > 0 ? 1 : 0;
    const optionsAvailable = option.allAwards.length > 0 ? 1 : 0;
    const rsusAvailable = rsu.allAwards.length > 0 ? 1 : 0;
    const psusAvailable = psu.allAwards.length > 0 ? 1 : 0;
    const rsasAvailable = rsa.allAwards.length > 0 ? 1 : 0;
    const warrantsAvailable = warrant.allAwards.length ? 1 : 0;
    const convertibleAvailable = convertibleLoan.allAwards.length ? 1 : 0;
    const sharesAvailable = share.allAwards.length ? 1 : 0;
    const subscriptionRightsAvailable = subscriptionRights.allAwards.length
      ? 1
      : 0;
    return (
      fundsAvailable +
      deferredCashAvailable +
      optionsAvailable +
      rsusAvailable +
      psusAvailable +
      rsasAvailable +
      warrantsAvailable +
      subscriptionRightsAvailable +
      convertibleAvailable +
      sharesAvailable
    );
  };

  if (numberOfInstrumentTypes() === 0) {
    return (
      <div className="overview-section text-content-center">
        {hasPurchased ? (
          <Message
            header={formatMessage({
              id: "instruments.frontpage.noInstrumentsMessage.purchase.header",
            })}
            content={formatMessage({
              id: "instruments.frontpage.noInstrumentsMessage.purchase.content",
            })}
          />
        ) : (
          <Message
            header={formatMessage({
              id: "instruments.frontpage.noInstrumentsMessage.header",
            })}
            content={formatMessage({
              id: "instruments.frontpage.noInstrumentsMessage.content",
            })}
          />
        )}
        <TimelineBox timelineEvents={timelineEvents} dateFormat={dateFormat} />
      </div>
    );
  }

  const hasMultipleInstruments = numberOfInstrumentTypes() > 1;

  const DetailsLink = hasMultipleInstruments && (
    <Link to={"/instruments"}>
      {formatMessage({ id: "global.viewDetailsBtn" })}
    </Link>
  );

  return (
    <div>
      <div className="overview-section-container">
        <OverviewBox
          totalGains={totalGainsAllInstruments}
          detailsLink={DetailsLink}
        >
          <OverviewSections
            canExerciseCurrentWindow={canExerciseCurrentWindow}
            features={features}
            option={option}
            convertibleLoan={convertibleLoan}
            share={share}
            rsa={rsa}
            rsu={rsu}
            psu={psu}
            subscriptionRights={subscriptionRights}
            warrant={warrant}
            nextWindow={nextWindow}
            currentWindow={currentWindow}
          />
        </OverviewBox>
        <TimelineBox timelineEvents={timelineEvents} dateFormat={dateFormat} />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  canExerciseCurrentWindow: canExerciseCurrentWindow(state),
  canReleaseCurrentWindow: canReleaseCurrentWindow(state),
  isFetchingInitialData: state.instrument.isFetchingWelcomeData,
  errorFetchingWelcomeData: state.instrument.errorFetchingWelcomeData,
  currentWindow: currentExerciseWindow(state),
  nextWindow: state.user.nextExerciseWindow,
  logoUrl: state.user.tenant && state.user.tenant.logo_url,
  convertibleLoan: state.instrument.convertibleLoan,
  share: state.instrument.share,
  warrant: state.instrument.warrant,
  rsa: state.instrument.rsa,
  funds: state.instrument.fundsState,
  deferredCash: state.instrument.cashState,
  rsu: state.instrument.rsu,
  psu: state.instrument.psu,
  option: state.instrument.option,
  subscriptionRights: state.instrument.subscriptionRightState,
  features: state.features,
  totalGainsAllInstruments: state.instrument.totalGain,
  totalGainsVestedInstruments: state.instrument.totalVestedGain,
  totalNumberOfInstruments: state.instrument.totalQuantity,
  hasPerformance: state.instrument.hasPerformance,
  currencyCode: userCurrencyCode(state),
  dateFormat: state.dateFormat,
  hasPurchased: state.user.hasPurchased,
  timelineEvents: availableEvents(state),
});

export default connect<StateProps>(mapStateToProps)(Home);
