import moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Form, Button, Message } from "semantic-ui-react";

import { Window } from "src/admin-portal/exercise-windows/window-reducer";
import SpinnerFullScreen from "src/common/components/spinner-full-screen";
import { changeCommaForPunctuation } from "src/common/utils/utils";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface PassedProps {
  window: Window;
  startProcessing: (transactionDate: string | null) => void;
  completeExerciseAndHoldOrders: (sharePrice: string) => void;
  completeSaleCashlessOrders: (sharePrice: string) => void;
  completeCashlessOrders: () => void;
  isLoading: boolean;
  dateFormat: DateFormatState;
}

export enum WindowProcessingState {
  IN_PROCESS = "IN_PROCESS",
}

const WindowDetailsHome: FunctionComponent<PassedProps> = ({
  window,
  startProcessing,
  completeExerciseAndHoldOrders,
  completeSaleCashlessOrders,
  completeCashlessOrders,
  isLoading,
  dateFormat,
}) => {
  const [cashlessOrdersPrice, setCashlessOrdersPrice] = useState("");
  const [exerciseAndHoldOrdersPrice, setExerciseAndHoldOrdersPrice] = useState(
    ""
  );
  const [transactionDate, setTransactionDate] = useState("");
  const [useOrderDate, setUseOrderDate] = useState(true);

  const windowHasEnded = moment().isAfter(window.end_time);

  useEffect(() => {
    if (!transactionDate) {
      setTransactionDate(window.end_time.format(dateFormat.normalDateFormat));
    }
  }, []);

  const onCompleteExerciseAndHoldOrders = () =>
    completeExerciseAndHoldOrders(
      changeCommaForPunctuation(exerciseAndHoldOrdersPrice)
    );

  const onCompleteSaleCashlessOrders = () =>
    completeSaleCashlessOrders(changeCommaForPunctuation(cashlessOrdersPrice));

  const onStartProcessing = () =>
    startProcessing(useOrderDate ? null : transactionDate);

  const renderStartProcessingOrders = () => {
    if (window.processing_status) {
      return null;
    }

    const hasError: boolean = useOrderDate
      ? false
      : !moment(transactionDate, dateFormat.normalDateFormat).isValid();

    return (
      <>
        <div className="block-s">
          <Form.Radio
            label={"Use Order Date for Transaction Date"}
            checked={useOrderDate}
            onChange={() => setUseOrderDate(!useOrderDate)}
          />
          <Form.Radio
            label={"Set New Transaction Date"}
            checked={!useOrderDate}
            onChange={() => setUseOrderDate(!useOrderDate)}
          />
        </div>
        {!useOrderDate && (
          <div className="block-s">
            <label>Transaction date ({dateFormat.normalDateFormat})</label>

            <Form.Input
              value={transactionDate}
              onChange={(_, { value }) => setTransactionDate(value)}
              placeholder="Transaction date"
              fluid={true}
              error={hasError}
            />

            {hasError && <div>Invalid Date</div>}
          </div>
        )}

        <Button
          primary={true}
          fluid={true}
          onClick={onStartProcessing}
          disabled={hasError}
        >
          Start processing all orders
        </Button>
      </>
    );
  };

  const renderExerciseAndHoldOrdersStatus = () => {
    if (window.exercise_and_hold_orders_completed) {
      return (
        <div className="block-xs">
          <Message size="mini">Exercise and Hold orders are completed</Message>
        </div>
      );
    }

    const hasError =
      exerciseAndHoldOrdersPrice !== "" &&
      isNaN(parseFloat(changeCommaForPunctuation(exerciseAndHoldOrdersPrice)));

    return (
      <div className="block-xs">
        <div className="block-xs">
          <label>Exercise And Hold Orders Price</label>
          <Form.Input
            value={exerciseAndHoldOrdersPrice}
            onChange={(_, { value }) => setExerciseAndHoldOrdersPrice(value)}
            placeholder="Exercise and Hold Price"
            fluid={true}
            error={hasError}
          />

          {hasError && <div>Should be a number</div>}
        </div>
        <Button
          primary={true}
          fluid={true}
          disabled={exerciseAndHoldOrdersPrice === "" || hasError}
          onClick={onCompleteExerciseAndHoldOrders}
        >
          Complete all Exercise and Hold orders
        </Button>
      </div>
    );
  };

  const renderCashlessOrdersStatus = () => {
    if (window.cashless_orders_completed) {
      return (
        <div className="block-xs">
          <Message size="mini">Exercise and Sell orders are completed</Message>
        </div>
      );
    }

    if (window.cashless_orders_sale_completed) {
      return (
        <>
          <div className="block-xs">
            <Message size="mini">
              Exercise and Sell orders are sale completed.
            </Message>
          </div>
          <div className="block-xs">
            <Button
              primary={true}
              onClick={completeCashlessOrders}
              fluid={true}
            >
              Complete all Exercise and Sale orders
            </Button>
          </div>
        </>
      );
    }

    const hasError: boolean =
      cashlessOrdersPrice !== "" &&
      isNaN(parseFloat(changeCommaForPunctuation(cashlessOrdersPrice)));

    return (
      <div className="block-xs">
        <div className="block-xs">
          <label>Cashless Orders Price</label>
          <Form.Input
            value={cashlessOrdersPrice}
            onChange={(_, { value }) => setCashlessOrdersPrice(value)}
            placeholder="Cashless Orders Price"
            fluid={true}
          />

          {hasError && <div>Should be a number</div>}
        </div>

        <Button
          primary={true}
          onClick={onCompleteSaleCashlessOrders}
          disabled={cashlessOrdersPrice === "" || hasError}
        >
          Complete sale on all Exercise and Sale orders
        </Button>

        {hasError}
      </div>
    );
  };

  return (
    <div className="margin-center page-space">
      <SpinnerFullScreen active={isLoading} />

      <div className="block-l">
        <h2>Window progress status</h2>
      </div>

      <div className="block-m">
        <span>Status: </span>
        <b>
          {moment().isBefore(window.start_time) && "Before opening"}
          {moment().isBetween(window.start_time, window.end_time) &&
            "Window open"}
          {windowHasEnded && "Window Closed"}
        </b>
      </div>

      {windowHasEnded && (
        <div className="block-m">
          <Form>
            {renderStartProcessingOrders()}
            {window.processing_status === WindowProcessingState.IN_PROCESS && (
              <div className="block-m">
                {renderExerciseAndHoldOrdersStatus()}
                {renderCashlessOrdersStatus()}
              </div>
            )}
          </Form>
        </div>
      )}
    </div>
  );
};

export default WindowDetailsHome;
