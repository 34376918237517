import { Moment } from "moment";

import {
  Interval,
  overlappingDays,
} from "src/admin-portal/reports/generate-report/common/date";

interface PeriodLengths {
  obDays: number;
  periodDays: number;
  cbDays: number;
  unamortizedDays: number;
  totalDaysForMobility: number;
}

export const calcPeriodLengths = (
  grantDate: Moment,
  vestingDate: Moment,
  dayBeforeOB: Moment,
  cbDate: Moment,
  mobilityInterval: Interval
): PeriodLengths => {
  const obEndDate = dayBeforeOB.isBefore(vestingDate)
    ? dayBeforeOB
    : vestingDate;
  const grantToOBInterval: Interval = { start: grantDate, end: obEndDate };
  const obDays = overlappingDays(grantToOBInterval, mobilityInterval);

  const endOfCostForThisPeriod = cbDate.isBefore(vestingDate)
    ? cbDate
    : vestingDate;
  const grantToEnd: Interval = {
    start: grantDate,
    end: endOfCostForThisPeriod,
  };
  const cbDays = overlappingDays(grantToEnd, mobilityInterval);
  const periodDays = cbDays - obDays;

  const grantToVesting: Interval = { start: grantDate, end: vestingDate };
  const totalDaysForMobility = overlappingDays(
    grantToVesting,
    mobilityInterval
  );

  const unamortizedDays = totalDaysForMobility - cbDays;

  return {
    obDays,
    periodDays,
    cbDays,
    unamortizedDays,
    totalDaysForMobility,
  };
};
