import moment, { Moment } from "moment";
import Papa from "papaparse";

import { store } from "src/app";
import { callApi } from "src/common/api/api-helper";
import { apiShortDate } from "src/common/utils/utils";

Papa.SCRIPT_PATH = "../node_modules/papaparse/papaparse.js";

export interface InterestRates {
  date: Moment;
  rates: { [key: number]: number };
}

export interface InterestRatesGBON {
  date: Moment;
  rate3Year: number;
  rate5Year: number;
  rate10Year: number;
}

export interface InterestRatesTBIL {
  date: Moment;
  rate3Months: number;
  rate6Months: number;
  rate9Months: number;
  rate12Months: number;
}

interface GBONndexes {
  date: number;
  year3: number;
  year5: number;
  year10: number;
}

const getGBONndexes = (row: string[]): GBONndexes =>
  row.reduce(
    (acc, cell: string, index) => {
      if (cell.includes("Time Period")) {
        acc.date = index;
      } else if (cell.includes("3Y")) {
        acc.year3 = index;
      } else if (cell.includes("5Y")) {
        acc.year5 = index;
      } else if (cell.includes("10Y")) {
        acc.year10 = index;
      }

      return acc;
    },
    { date: -1, year3: -1, year5: -1, year10: -1 }
  );

interface TBILIndexes {
  date: number;
  months3: number;
  months6: number;
  months9: number;
  months12: number;
}

const getTBILIndexes = (row: string[]): TBILIndexes =>
  row.reduce(
    (acc, cell: string, index) => {
      if (cell.includes("Time Period")) {
        acc.date = index;
      } else if (cell.includes("3M")) {
        acc.months3 = index;
      } else if (cell.includes("6M")) {
        acc.months6 = index;
      } else if (cell.includes("9M")) {
        acc.months9 = index;
      } else if (cell.includes("12M")) {
        acc.months12 = index;
      }

      return acc;
    },
    { date: -1, months3: -1, months6: -1, months9: -1, months12: -1 }
  );

export const fetchInterestRatesGBON = (): Promise<InterestRatesGBON[]> => {
  return new Promise((resolve, reject) => {
    Papa.parse(
      "https://data.norges-bank.no/api/data/IR/B.GBON..R?format=csv-:-comma-true-y&startPeriod=2015",
      {
        download: true,
        complete(data) {
          const indexes = getGBONndexes(data.data[6]);

          const validEntries = data.data.filter(entry =>
            moment(entry[0], apiShortDate).isValid()
          );
          const sanitized = validEntries.map(entry => ({
            date: moment(entry[indexes.date], apiShortDate),
            rate3Year: parseFloat(entry[indexes.year3]),
            rate5Year: parseFloat(entry[indexes.year5]),
            rate10Year: parseFloat(entry[indexes.year10]),
          }));
          resolve(sanitized);
        },
        error(err) {
          reject(new Error("Error while fetching fetchInterestRatesGBON"));
        },
      }
    );
  });
};

export const fetchInterestRatesTBIL = (): Promise<InterestRatesTBIL[]> => {
  return new Promise((resolve, reject) => {
    Papa.parse(
      "https://data.norges-bank.no/api/data/IR/B.TBIL..R?format=csv-:-comma-true-y&startPeriod=2015",
      {
        download: true,
        complete(data) {
          const indexes = getTBILIndexes(data.data[6]);

          const validEntries = data.data.filter(entry =>
            moment(entry[0], apiShortDate).isValid()
          );
          const sanitized = validEntries.map(entry => ({
            date: moment(entry[indexes.date], apiShortDate),
            rate3Months: parseFloat(entry[indexes.months3]),
            rate6Months: parseFloat(entry[indexes.months6]),
            rate9Months: parseFloat(entry[indexes.months9]),
            rate12Months: parseFloat(entry[indexes.months12]),
          }));
          resolve(sanitized);
        },
        error(err) {
          reject(new Error("Error while fetching fetchInterestRatesTBIL"));
        },
      }
    );
  });
};

export const fetchSwedishInterestRates = (): Promise<InterestRates[]> => {
  const token = store.getState().user?.token;
  const tenantId = store.getState().tenant.selectedTenant?.id;
  return callApi(
    `/tenants/${tenantId}/stock-prices/interest-rates`,
    token,
    "GET"
  )
    .then((res: { data: InterestRates[] }) =>
      res.data.map(ir => ({ ...ir, date: moment(ir.date, apiShortDate) }))
    )
    .catch(error => {
      console.log(error);
      return [];
    });
};

export const fetchInterestRates = (): Promise<InterestRates[]> => {
  const currency = store.getState().tenant.selectedTenant?.currency_code;
  if (currency === "SEK") {
    return fetchSwedishInterestRates();
  }
  return Promise.all([fetchInterestRatesTBIL(), fetchInterestRatesGBON()]).then(
    ([tbil, gbon]) => {
      return gbon.map((gbonEntry, index) => {
        const rates = {
          0.25: tbil[index].rate3Months,
          0.5: tbil[index].rate6Months,
          0.75: tbil[index].rate9Months,
          1: tbil[index].rate12Months,
          3: gbonEntry.rate3Year,
          5: gbonEntry.rate5Year,
          10: gbonEntry.rate10Year,
        };

        if (gbonEntry.date.diff(tbil[index].date, "days") === 0) {
          return { ...gbonEntry, ...tbil[index], rates };
        } else {
          return { ...gbonEntry, ...tbil[index], rates };
        }
      });
    }
  );
};
