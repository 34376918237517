import React, { FunctionComponent } from "react";

import { Table } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import CurrencyConversionView from "src/common/components/currency/currency-conversion-view";
import CurrencyFormatter, {
  CurrencyFormatterProps,
} from "src/common/components/currency/currency-formatter";
import { sortMultipleLevels } from "src/common/utils/sort";
import ConvertibleLoanLikeTableRow from "src/employee-portal/instrument-page/convertible-loan-like/convertible-loan-like-table-row";
import {
  AwardGainFunction,
  FlatAward,
} from "src/employee-portal/instrument-page/instruments-reducer";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface Props {
  convertibles: FlatAward[];
  sharePriceToday: number;
  totalGain: number;
  totalQuantity: number;
  dateFormat: DateFormatState;
  awardGain: AwardGainFunction;
}

const ConvertibleLoanLikeTable: FunctionComponent<Props> = ({
  convertibles,
  sharePriceToday,
  totalGain,
  totalQuantity,
  awardGain,
  dateFormat,
}) => {
  const sorted = sortMultipleLevels(convertibles)("grantDate", "lockedTo");
  const showLockedTo = convertibles.some(a => !!a.lockedTo);
  return (
    <div className="block-l">
      <CurrencyFormatter>
        {(formatter: CurrencyFormatterProps) => (
          <Table celled={true} unstackable={true} textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Content id="global.tableLabel.program" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Content id="convertible.tableLabel.grantDate" />
                </Table.HeaderCell>
                {showLockedTo && (
                  <Table.HeaderCell>
                    <Content id="global.tableLabel.lockedTo" />
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell>
                  <Content id="convertible.tableLabel.totalValue" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {sorted.map(tranche => (
                <ConvertibleLoanLikeTableRow
                  award={tranche}
                  key={tranche.trancheId}
                  dateFormat={dateFormat}
                  totalGain={awardGain(sharePriceToday, tranche).gain}
                  showLockedTo={showLockedTo}
                />
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell>
                  <Content id="global.tableLabel.sum" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right" />
                {showLockedTo && <Table.HeaderCell textAlign="right" />}
                <Table.HeaderCell textAlign="right">
                  <CurrencyConversionView value={totalGain} decimals={0} />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </CurrencyFormatter>
    </div>
  );
};

export default ConvertibleLoanLikeTable;
