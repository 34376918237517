import { replace } from "connected-react-router";
import React, { FunctionComponent } from "react";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { Button, Header, Icon, Message, Modal } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { ContentContext } from "src/common/components/content/content-contex";
import CurrencyFormatter, {
  CurrencyFormatterProps,
} from "src/common/components/currency/currency-formatter";
import { PurchaseWindow } from "src/common/data/data";
import {
  InstrumentType,
  useInstrumentTypeText,
} from "src/common/utils/text-mappings";
import { formatNumber, formatPercentage } from "src/common/utils/utils";
import { PurchaseType } from "src/constants";
import CongratzImg from "src/employee-portal/purchase/congratulations.svg";
import {
  getPurchaseInfoForCurrentWindow,
  purchaseAvailable,
  PurchaseInfo,
} from "src/employee-portal/purchase/duck/purchase-selectors";
import { RootState } from "src/reducers/all-reducers";
import { DateFormatState } from "src/reducers/date-format-reducer";
import "src/employee-portal/purchase/purchase.less";
import { purchaseRoute } from "src/employee-portal/employee-portal-router";

interface DispatchProps {
  redirectToRoot: () => void;
}

interface StateProps {
  purchaseWindow?: PurchaseWindow;
  purchaseUnavailable: boolean;
  companyName: string;
  tenantId: string;
  purchaseInfo: PurchaseInfo;
  dateFormat: DateFormatState;
}

type Props = DispatchProps & StateProps;

const PurchaseAvailableModal: FunctionComponent<Props> = props => {
  const {
    purchaseWindow,
    purchaseUnavailable,
    purchaseInfo,
    companyName,
    tenantId,
    redirectToRoot,
    dateFormat,
  } = props;
  const instrument = InstrumentType[purchaseInfo.instrumentType.toUpperCase()];
  const instrumentTypeTerm = useInstrumentTypeText(instrument);

  if (purchaseUnavailable) {
    return <Redirect to={"/overview"} />;
  }

  const instrumentTerm = instrumentTypeTerm.plural;
  const instrumentTermSingular = instrumentTypeTerm.singular;
  const showIcon = !purchaseInfo.hideCongratsIcon;
  return (
    <ContentContext.Provider
      value={{ contentPrefix: purchaseInfo.purchaseConfigId }}
    >
      <Modal
        open={true}
        closeIcon={<Icon name="close" />}
        onClose={redirectToRoot}
        size={"small"}
      >
        <Header>
          <div className="congratulations-box-container">
            <div
              className={`congratulations-box ${
                showIcon ? "congratulations-box-shifted" : ""
              }`}
            >
              <div className="congratulations-box-inner">
                <div className="block-xs congratulations-box-header">
                  <Content id={"purchase.popup.congratz.header"} />
                </div>
                <div className="congratulations-box-message">
                  <Content
                    id={"purchase.popup.congratz.subheader"}
                    values={{ companyName }}
                  />
                </div>
              </div>
              {showIcon && (
                <div
                  className="congratulations-box-img"
                  dangerouslySetInnerHTML={{ __html: CongratzImg }}
                />
              )}
            </div>
          </div>
        </Header>
        <Modal.Content>
          <CurrencyFormatter code={purchaseInfo.purchaseCurrency}>
            {(formatter: CurrencyFormatterProps) => (
              <div className="purchase-popup-content">
                {purchaseInfo.type ===
                PurchaseType.PURCHASE_OPPORTUNITY_CASH ? (
                  <Content
                    id={"purchase.popup.description.cashpurchase"}
                    values={{
                      purchasableCashAmount: formatter.formatCurrency(
                        purchaseInfo.purchasableCashAmount,
                        0
                      ),
                      minimumPurchasableCashAmount: formatter.formatCurrency(
                        purchaseInfo.minimumPurchasableCashAmount,
                        0
                      ),
                      instrumentTerm,
                      instrumentTermSingular,
                      discount: formatPercentage(purchaseInfo.discount, 0),
                      windowCloseDate: purchaseWindow.to.format(
                        dateFormat.dateTimeFormat
                      ),
                      companyName,
                      ...(purchaseInfo.loan
                        ? {
                            minLoan: formatter.formatCurrency(
                              purchaseInfo.loan.min,
                              0
                            ),
                            maxLoan: formatter.formatCurrency(
                              purchaseInfo.loan.max,
                              0
                            ),
                          }
                        : {}),
                    }}
                  />
                ) : (
                  <Content
                    id={"purchase.popup.description"}
                    values={{
                      purchasableQuantity: formatNumber(
                        purchaseInfo.purchasableQuantity
                      ),
                      minimumPurchasableQuantity: formatter.formatCurrency(
                        purchaseInfo.minimumPurchasableQuantity,
                        0
                      ),
                      instrumentTerm,
                      instrumentTermSingular,
                      price: formatter.formatCurrency(
                        purchaseInfo.purchasePrice,
                        2
                      ),
                      ...(purchaseInfo.loan
                        ? {
                            minLoan: formatter.formatCurrency(
                              purchaseInfo.loan.min,
                              0
                            ),
                            maxLoan: formatter.formatCurrency(
                              purchaseInfo.loan.max,
                              0
                            ),
                          }
                        : {}),
                      discount: formatPercentage(purchaseInfo.discount, 0),
                      windowCloseDate: purchaseWindow.to.format(
                        dateFormat.dateTimeFormat
                      ),
                      companyName,
                    }}
                  />
                )}
              </div>
            )}
          </CurrencyFormatter>
          {tenantId && tenantId === "d1d001c5-f818-41e6-92f4-b1727eef8f36" && (
            <div className="block-xs">
              <Message>
                <Message.Header>
                  With reference to the Long Term Incentive Programme agreement
                </Message.Header>
                <Message.Content>
                  <div className="block-xs">
                    {`Personal data submitted to ${companyName}, e.g. contact details and personal identity number, or otherwise registered in connection with the administration of the Plan, is processed by ${companyName}, as the data controller, for the administration of the Plan. The processing of personal data is necessary for ${companyName} in order to fulfill the agreement concerning the Plan and to enable ${companyName} to fulfill its statutory obligations. As a tool for processing these data, ${companyName} might use the services of a Third Party. Please read the Long Term Incentive Programme 2019 Document in full for all details regarding the Plan and Personal Data Information.`}
                  </div>
                  <div>
                    <a
                      href="https://www.tobii.com/group/privacy-policy/"
                      target="_blank"
                    >
                      Link to the Tobii privacy policy
                    </a>
                  </div>
                </Message.Content>
              </Message>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <div className="text-center">
            <Link to={purchaseRoute}>
              <Button
                positive={true}
                icon="right arrow"
                labelPosition="right"
                content={<Content id="purchase.popup.button.start" />}
              />
            </Link>
          </div>
        </Modal.Actions>
      </Modal>
    </ContentContext.Provider>
  );
};

const mapStateToProps: MapStateToProps<StateProps, {}, RootState> = state => ({
  purchaseUnavailable:
    !purchaseAvailable(state) || !getPurchaseInfoForCurrentWindow(state),
  purchaseInfo: getPurchaseInfoForCurrentWindow(state),
  purchaseWindow: state.user.currentPurchaseWindow,
  companyName: state.user.tenant && state.user.tenant.name,
  tenantId: state.user.tenant && state.user.tenant.id,
  dateFormat: state.dateFormat,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = dispatch => ({
  redirectToRoot: () => dispatch(replace("/")),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseAvailableModal);
