import { createAction } from "redux-act";

export const fetchContracts = createAction("FETCH_CONTRACTS");

export const fetchContractsSucceeded = createAction(
  "FETCH_CONTRACTS_SUCCEEDED",
  (payload: Api.V1.Contract[]) => payload
);

export const fetchContractsFailed = createAction(
  "FETCH_CONTRACTS_FAILED",
  (payload: string) => payload
);

export const postContract = createAction(
  "POST_CONTRACT",
  (payload: Api.V1.ContractCreate) => payload
);

export const postContractSucceeded = createAction(
  "POST_CONTRACT_SUCCEEDED",
  (payload: Api.V1.Contract) => payload
);

export const postContractFailed = createAction(
  "POST_CONTRACT_FAILED",
  (payload: string) => payload
);
