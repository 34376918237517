import React, { FunctionComponent, ReactNode, useState } from "react";

import { Icon, Transition } from "semantic-ui-react";

import CurrencyConversionView from "src/common/components/currency/currency-conversion-view";

export const ExpandableHeader: FunctionComponent<{
  headerText: string | ReactNode;
  headerValue: number | undefined;
  startExpanded?: boolean;
  compact?: boolean;
}> = ({ headerText, headerValue, children, startExpanded, compact }) => {
  const [visible, setVisible] = useState(Boolean(startExpanded));
  return (
    <div className="overview-instrument-container">
      <div
        className="overview-instrument-header"
        onClick={() => setVisible(!visible)}
      >
        <span>{headerText}</span>
        <div>
          {headerValue && (
            <CurrencyConversionView
              value={headerValue}
              decimals={0}
              showOverrideCurrencyOnly={true}
            />
          )}
          <Icon name="caret down" className="position-absolute" />
        </div>
      </div>
      <Transition visible={visible} animation="slide down" duration={250}>
        <div
          className={
            compact
              ? "overview-instrument-animation-container-compact"
              : "overview-instrument-animation-container"
          }
        >
          {children}
        </div>
      </Transition>
    </div>
  );
};

export default ExpandableHeader;
