import { createAction } from "redux-act";

import { Sysadmin } from "src/admin-portal/sysadmin/sysadmin-reducer";

export const fetchSysadmins = createAction("FETCH_SYSADMINS");

export const fetchSysadminsSucceeded = createAction(
  "FETCH_SYSADMINS_SUCCEEDED",
  (payload: Sysadmin[]) => payload
);

export const fetchSysadminsFailed = createAction(
  "FETCH_SYSADMINS_FAILED",
  (payload: string) => payload
);

export const postSysadmin = createAction(
  "POST_SYSADMINS",
  (payload: Sysadmin) => payload
);

export const postSysadminSucceeded = createAction(
  "POST_SYSADMINS_SUCCEEDED",
  (payload: Sysadmin) => payload
);

export const postSysadminFailed = createAction(
  "POST_SYSADMINS_FAILED",
  (payload: string) => payload
);

export const selectSysadmin = createAction(
  "SELECT_SYSADMINS",
  (payload: Sysadmin) => payload
);

export const deleteSysadmin = createAction(
  "DELETE_SYSADMINS",
  (payload: Sysadmin["id"]) => payload
);

export const deleteSysadminSucceeded = createAction(
  "DELETE_SYSADMINS_SUCCEEDED",
  (payload: Sysadmin["id"]) => payload
);

export const deleteSysadminFailed = createAction(
  "DELETE_SYSADMINS_FAILED",
  (payload: string) => payload
);
