import { Reducer } from "redux";

import { SupportedLocale } from "src/admin-portal/texts/text-reducer";
import { TYPES } from "src/employee-portal/employee-portal-profile-actions";

export interface EmployeePortalProfileState {
  email: string;
  share_depository_norwegian?: string;
  share_depository_account?: string;
  share_depository_bank?: string;
  share_depository_contact?: string;
  share_depository_description?: string;
  share_depository_clearing_code?: string;
  currency_code?: string;
  currency_exchange_ratio?: number;
  locale?: SupportedLocale;
  full_name: string;
  user_id: string;
  error_put_profile: boolean;
  soc_sec_rate?: number;
}

const initialState: EmployeePortalProfileState = {
  email: "",
  share_depository_account: "",
  full_name: "",
  user_id: "",
  error_put_profile: false,
  locale: "en",
};

const employeePortalProfileReducer: Reducer<EmployeePortalProfileState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case TYPES.FETCH_EMPLOYEE_PORTAL_PROFILE_SUCCEEDED:
    case TYPES.PUT_EMPLOYEE_PORTAL_PROFILE_SUCCEEDED:
      return {
        ...state,
        ...action.employeeData,
        error_put_profile: false,
      };
    case TYPES.PUT_EMPLOYEE_PORTAL_PROFILE_FAILED:
      return {
        ...state,
        error_put_profile: true,
      };
    default:
      return state;
  }
};

export default employeePortalProfileReducer;
