import React, { Component, StatelessComponent } from "react";
import { Modal } from "semantic-ui-react";

export class ErrorModalClosable extends Component<
  { message: string; renderActions?: () => React.ReactNode },
  {}
> {
  public state = {
    isOpen: true,
  };

  public handleClose = () => {
    this.setState({ isOpen: false });
  };

  public render() {
    const { message, renderActions } = this.props;
    return (
      <div className="text-center">
        <Modal
          open={this.state.isOpen}
          size="tiny"
          closeIcon={{
            className: "close icon",
            style: { color: "#fff" },
          }}
          onClose={this.handleClose}
          closeOnDocumentClick={true}
        >
          <Modal.Header className="text-center error-modal-header">
            Oops...
          </Modal.Header>
          <Modal.Content className="text-center">
            <div className="block-m error-modal-description">
              <Modal.Description>{message}</Modal.Description>
            </div>
            <div className="block-s">{renderActions && renderActions()}</div>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
