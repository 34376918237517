import moment from "moment";
import React, { FormEvent, FunctionComponent } from "react";
import { Button, Form, Icon, Input, List, Popup } from "semantic-ui-react";

import { generateVolatilityObservations } from "src/admin-portal/tranches-page/actions/fair-value-management/generate-volatility-observations";
import {
  isFairValueValid,
  TrancheGroup,
  TransactionKeysAndValues,
} from "src/admin-portal/tranches-page/actions/fair-value-management/utils";
import ClickableLink from "src/common/components/clickable-link";
import { changePunctuationForComma } from "src/common/utils/utils";
import { AnnualVolatilityState } from "src/reducers/share-price-reducer";

export interface Props {
  group: TrancheGroup;
  transactionKeysAndValues: TransactionKeysAndValues;
  updateGroup: (
    groupIndex: number,
    prop: string,
    value: number | boolean | TransactionKeysAndValues
  ) => void;
  onSubmit: (e: FormEvent) => void;
  normalDateFormat: string;
  fetchAnnualVolatilityForGroup: () => void;
  updateGroupInterestRate: (group: TrancheGroup) => void;
  annualVolatilityState?: AnnualVolatilityState;
}

const FairValueManagementForm: FunctionComponent<Props> = ({
  group,
  transactionKeysAndValues,
  updateGroup,
  onSubmit,
  normalDateFormat,
  fetchAnnualVolatilityForGroup,
  updateGroupInterestRate,
  annualVolatilityState,
}) => (
  <Form onSubmit={onSubmit}>
    <div className="display-flex">
      <div style={{ width: "80%" }}>
        <Form.Group widths="equal">
          <Form.Field>
            <label>
              Share Price At Valuation{" "}
              {group.sharePriceObj && (
                <Popup
                  hoverable={true}
                  trigger={<Icon name="question circle outline" color="blue" />}
                  position="top center"
                  header={`Share price on ${moment(
                    group.sharePriceObj.date
                  ).format(normalDateFormat)}`}
                  content={changePunctuationForComma(group.sharePriceObj.price)}
                />
              )}
            </label>
            <Input
              value={transactionKeysAndValues.sharePrice}
              onChange={(e, { value }) =>
                updateGroup(group.groupIndex, "transactionKeysAndValues", {
                  ...group.transactionKeysAndValues,
                  sharePrice: value,
                })
              }
              placeholder="Share Price At Valuation"
              disabled={!group.isActive}
              fluid={true}
            />
          </Form.Field>
          <Form.Input
            value={transactionKeysAndValues.expectedLifetime}
            onChange={(e, { value }) =>
              updateGroup(group.groupIndex, "transactionKeysAndValues", {
                ...group.transactionKeysAndValues,
                expectedLifetime: value,
              })
            }
            label="Expected Lifetime (in years)"
            placeholder="Expected Lifetime"
            disabled={!group.isActive}
            fluid={true}
          />
          <Form.Input
            value={transactionKeysAndValues.strike}
            onChange={(e, { value }) =>
              updateGroup(group.groupIndex, "transactionKeysAndValues", {
                ...group.transactionKeysAndValues,
                strike: value,
              })
            }
            label="Strike Price"
            placeholder="Strike Price"
            disabled={!group.isActive}
            fluid={true}
          />
        </Form.Group>

        <Form.Group widths="equal">
          <Form.Field>
            <Form.Input
              value={transactionKeysAndValues.volatility}
              onChange={(e, { value }) =>
                updateGroup(group.groupIndex, "transactionKeysAndValues", {
                  ...group.transactionKeysAndValues,
                  volatility: value,
                })
              }
              label={
                <label>
                  Volatility{" "}
                  {annualVolatilityState?.data?.observations && (
                    <span style={{ marginLeft: "2rem" }}>
                      <ClickableLink
                        onClick={() =>
                          generateVolatilityObservations(
                            annualVolatilityState?.data
                          )
                        }
                      >
                        Get observations data
                      </ClickableLink>
                      {annualVolatilityState?.data?.isTimePeriodShortened ||
                      annualVolatilityState?.data?.removedFromCalculation >
                        0 ? (
                        <Popup
                          hoverable={true}
                          trigger={
                            <Icon
                              name="question circle outline"
                              color="yellow"
                            />
                          }
                          position="top center"
                          style={{ minWidth: 400 }}
                        >
                          <Popup.Content>
                            {annualVolatilityState?.data
                              ?.isTimePeriodShortened && (
                              <div className={"block-s"}>
                                We don't have share price history for all the
                                requested time period. Evaluate if the number of
                                observations is big enough to be used.
                              </div>
                            )}
                            {annualVolatilityState?.data
                              ?.removedFromCalculation > 0 && (
                              <div className={"block-s"}>
                                {
                                  annualVolatilityState?.data
                                    ?.removedFromCalculation
                                }{" "}
                                observations have been removed because they have
                                a daily return bigger than 50%
                              </div>
                            )}
                            <div>
                              Download the observations data set for details
                            </div>
                          </Popup.Content>
                        </Popup>
                      ) : null}
                    </span>
                  )}
                </label>
              }
              placeholder="e.g. 0.5 (50%)"
              disabled={!group.isActive}
              fluid={true}
              loading={annualVolatilityState?.isFetching}
            />
            {annualVolatilityState?.isFetching ? (
              <span>Fetching... This will take a few seconds</span>
            ) : (
              <ClickableLink onClick={fetchAnnualVolatilityForGroup}>
                Calculate volatility
              </ClickableLink>
            )}
          </Form.Field>

          <Form.Field>
            <label>
              Interest Rate{" "}
              {group.interestRates && (
                <Popup
                  hoverable={true}
                  trigger={<Icon name="question circle outline" color="blue" />}
                  position="top center"
                  style={{ minWidth: 300 }}
                >
                  <Popup.Header
                    content={`Interest Rates in % on ${group.interestRates.date.format(
                      normalDateFormat
                    )}`}
                  />
                  <hr />
                  <Popup.Content>
                    {Object.keys(group.interestRates.rates).map(key => (
                      <List key={key} bulleted={true}>
                        {key} Years: {group.interestRates.rates[key]}
                      </List>
                    ))}
                  </Popup.Content>
                </Popup>
              )}
              {group.interestRates &&
                group.interestRates.date.format(normalDateFormat) !==
                  group.forActionDate && (
                  <span className="text-warning">
                    valuation and IR date mismatch
                  </span>
                )}
            </label>
            <Input
              value={transactionKeysAndValues.interestRate}
              onChange={(e, { value }) =>
                updateGroup(group.groupIndex, "transactionKeysAndValues", {
                  ...group.transactionKeysAndValues,
                  interestRate: value,
                })
              }
              placeholder="e.g. 0,01285 (1,285%)"
              disabled={!group.isActive}
              fluid={true}
            />
            <a onClick={() => updateGroupInterestRate(group)}>
              Calculate interest rate
            </a>
          </Form.Field>

          <Form.Input
            value={transactionKeysAndValues.dividend}
            onChange={(e, { value }) =>
              updateGroup(group.groupIndex, "transactionKeysAndValues", {
                ...group.transactionKeysAndValues,
                dividend: value,
              })
            }
            label="Dividend"
            placeholder="e.g. 0,006 (0,6%)"
            disabled={!group.isActive}
            fluid={true}
          />
        </Form.Group>

        <div className="margin-top-m" />

        <Form.Group inline={true} className="display-flex center-center">
          <Form.Input
            value={transactionKeysAndValues.fairValue}
            onChange={(e, { value }) =>
              updateGroup(group.groupIndex, "transactionKeysAndValues", {
                ...group.transactionKeysAndValues,
                fairValue: value,
              })
            }
            error={
              !isFairValueValid(transactionKeysAndValues.fairValue) &&
              transactionKeysAndValues.fairValue !== ""
            }
            label="Fair Value"
            placeholder="Fair Value"
            disabled={!group.isActive}
          />
          <Form.Field>
            <Button basic={true} type="submit" disabled={!group.isActive}>
              Calculate fair value
            </Button>
          </Form.Field>
        </Form.Group>
      </div>

      <div style={{ width: "20%", paddingLeft: 15 }}>
        <Form.Input
          value={transactionKeysAndValues.valuationMethod}
          onChange={(e, { value }) =>
            updateGroup(group.groupIndex, "transactionKeysAndValues", {
              ...group.transactionKeysAndValues,
              valuationMethod: value,
            })
          }
          label="Valuation Method"
          placeholder="e.g. Black-Scholes-Merton"
          disabled={!group.isActive}
          fluid={true}
        />
        <Form.TextArea
          value={transactionKeysAndValues.comment}
          rows={6}
          onChange={(e, { value }) =>
            updateGroup(group.groupIndex, "transactionKeysAndValues", {
              ...group.transactionKeysAndValues,
              comment: value as string,
            })
          }
          label="Comment"
          placeholder="Tell us more"
          disabled={!group.isActive}
        />
      </div>
    </div>
  </Form>
);

export default FairValueManagementForm;
