import moment from "moment";
import { Reducer } from "redux";

import {
  postDocumentAcceptance,
  postDocumentAcceptanceSucceeded,
} from "src/admin-portal/actions/document-acceptances-actions";
import { WindowType } from "src/admin-portal/exercise-windows/window-reducer";
import { Tenant } from "src/admin-portal/tenant/tenant-reducer";
import * as auth from "src/common/auth/auth";
import { PurchaseWindow, Window } from "src/common/data/data";
import { PurchaseType } from "src/constants";
import { APIAward } from "src/employee-portal/instrument-page/instruments-reducer";
import { canPurchaseWindow } from "src/employee-portal/purchase/duck/purchase-selectors";
import { AUTH0_NAMESPACE } from "src/env";

const decodedIdToken = auth.decodedToken();
export const USER_NOT_AUTHORIZED = "USER_NOT_AUTHORIZED";

export interface APIEmployeeDocument {
  id: string;
  fileName: string;
  document_header?: string;
  message_header?: string;
  message_body?: string;
  downloadLink: string;
  requires_acceptance: boolean;
  accepted_at?: string;
  documents_documentable_id?: string;
}

export interface UserState {
  readonly token: string;
  readonly decodedToken: any;
  readonly isSysadmin: boolean;
  readonly isAdmin: boolean;
  readonly loggedIn: boolean;
  readonly tenant?: Tenant;
  readonly name?: string;
  readonly windows?: Window[];
  readonly purchaseWindows?: Window[];
  readonly loginError: boolean;
  readonly currentExerciseWindow?: Window;
  readonly currentReleaseWindow?: Window;
  readonly nextExerciseWindow?: Window;
  readonly currentPurchaseWindow?: PurchaseWindow;
  readonly nextPurchaseWindow?: PurchaseWindow;
  readonly expiresIn?: number;
  readonly countDownTimer?: number;
  readonly isUpdatingSession?: boolean;
  readonly documents?: APIEmployeeDocument[];
  readonly documentsNeedingAcceptance?: APIEmployeeDocument[];
  readonly isAcceptingDocument?: boolean;
  readonly currentDocumentIndex?: number;
  readonly errorFetchingWelcomeData: boolean;
  readonly hasPurchased: boolean;
  readonly paymentAccount: PaymentAccount;
}

const appMetadata = (decodedToken: any) =>
  decodedToken[`${AUTH0_NAMESPACE}app_metadata`];
export const userMetadata = (decodedToken: any) =>
  decodedToken[`${AUTH0_NAMESPACE}user_metadata`];

const initialState: UserState = {
  token: auth.token(),
  name: decodedIdToken && userMetadata(decodedIdToken).name,
  decodedToken: decodedIdToken,
  isSysadmin:
    decodedIdToken && appMetadata(decodedIdToken).roles.includes("sysadmin"),
  isAdmin:
    decodedIdToken && appMetadata(decodedIdToken).roles.includes("admin"),
  loggedIn: auth.isAuthenticated(),
  loginError: false,
  isUpdatingSession: false,
  tenant: null,
  windows: null,
  purchaseWindows: [],
  errorFetchingWelcomeData: false,
  hasPurchased: false,
  paymentAccount: {},
};

export const findCurrentWindows = (windows: Window[]): Window[] => {
  const today = moment();
  return windows
    .filter(w => today.isBetween(w.from, w.to))
    .sort((w1, w2) => (w1.from.isSameOrBefore(w2.from) ? 1 : 0));
};

const findCurrentExerciseWindows = (windows: Window[]): Window[] =>
  findCurrentWindows(windows).filter(w => !w.is_release_process);

const findCurrentReleaseWindows = (windows: Window[]): Window[] =>
  findCurrentWindows(windows).filter(w => w.is_release_process);

const findCurrentPurchaseWindow = (
  windows: Window[],
  purchase_opportunities: any[],
  awards: APIAward[]
): Window | undefined => {
  const currentWindows = findCurrentWindows(windows);
  return currentWindows
    .map(window =>
      createPurchaseWindowFromApiWindow(window, purchase_opportunities, awards)
    )
    .filter(canPurchaseWindow)[0];
};

const findNextPurchaseWindow = (
  windows: Window[],
  purchase_opportunities: any[],
  awards: APIAward[]
): Window | undefined => {
  const futureWindows = findFutureWindows(windows);
  return futureWindows
    .map(window =>
      createPurchaseWindowFromApiWindow(window, purchase_opportunities, awards)
    )
    .filter(canPurchaseWindow)[0];
};

const findCurrentExerciseWindow = (windows: Window[]): Window | undefined =>
  findCurrentExerciseWindows(windows)[0];

const findCurrentReleaseWindow = (windows: Window[]): Window | undefined =>
  findCurrentReleaseWindows(windows)[0];

const findNextWindow = (windows: Window[]): Window | undefined => {
  return findFutureWindows(windows)[0];
};

const findFutureWindows = (windows: Window[]): Window[] | undefined => {
  const today = moment();
  return windows
    .filter(w => today.isBefore(w.from))
    .sort((w1, w2) => (w1.from.isSameOrBefore(w2.from) ? 1 : 0));
};

const userReducer: Reducer<UserState> = (state = initialState, action) => {
  if (action.type === "PARSE_AUTH_HASH_SUCCEEDED") {
    const { idToken, idTokenPayload, expiresIn } = action.authResult;
    const updateObj = {
      token: idToken,
      decodedToken: idTokenPayload,
      isSysadmin:
        idTokenPayload &&
        appMetadata(idTokenPayload).roles.includes("sysadmin"),
      isAdmin:
        idTokenPayload && appMetadata(idTokenPayload).roles.includes("admin"),
      name: idTokenPayload && userMetadata(idTokenPayload).name,
      expiresIn,
      isUpdateSessionCanceled: false,
      isUpdatingSession: false,
      loggedIn: true,
    };
    return { ...state, ...updateObj };
  } else if (action.type === "KEEP_ALIVE") {
    return { ...state, isUpdatingSession: true };
  } else if (action.type === "PARSE_AUTH_HASH_FAILED") {
    return { ...state, loginError: true };
  } else if (action.type === "FETCH_EMPLOYEE_PORTAL_WELCOME_SUCCEEDED") {
    const {
      tenant,
      windows,
      documents,
      purchase_opportunities,
      awards,
      hasPurchased,
      paymentAccount,
    } = action.welcomeData;

    const exerciseWindows: Window[] = windows
      .filter(keepType(WindowType.EXERCISE))
      .map(toWindow);
    const currentExerciseWindow = findCurrentExerciseWindow(exerciseWindows);
    const currentReleaseWindow = findCurrentReleaseWindow(exerciseWindows);
    const nextExerciseWindow = findNextWindow(exerciseWindows);

    const purchaseWindows: Window[] = windows
      .filter(keepType(WindowType.PURCHASE))
      .map(toWindow);
    const currentPurchaseWindow = findCurrentPurchaseWindow(
      purchaseWindows,
      purchase_opportunities,
      awards
    );
    const nextPurchaseWindow = findNextPurchaseWindow(
      purchaseWindows,
      purchase_opportunities,
      awards
    );

    return {
      ...state,
      tenant,
      windows: exerciseWindows,
      currentExerciseWindow,
      currentReleaseWindow,
      documents,
      documentsNeedingAcceptance: documents.filter(
        (d: APIEmployeeDocument) => d.requires_acceptance && !d.accepted_at
      ),
      currentDocumentIndex: 0,
      nextExerciseWindow,
      currentPurchaseWindow: currentPurchaseWindow
        ? createPurchaseWindowFromApiWindow(
            currentPurchaseWindow,
            purchase_opportunities,
            awards
          )
        : null,
      nextPurchaseWindow: nextPurchaseWindow
        ? createPurchaseWindowFromApiWindow(
            nextPurchaseWindow,
            purchase_opportunities,
            awards
          )
        : null,
      purchaseWindows: purchaseWindows.map(w =>
        createPurchaseWindowFromApiWindow(w, purchase_opportunities, awards)
      ),
      errorFetchingWelcomeData: false,
      hasPurchased,
      paymentAccount,
    };
  } else if (action.type === "USER_NOT_AUTHORIZED") {
    return { ...state, loggedIn: auth.isAuthenticated() };
  } else if (action.type === postDocumentAcceptance.getType()) {
    return { ...state, isAcceptingDocument: true };
  } else if (action.type === postDocumentAcceptanceSucceeded.getType()) {
    const {
      documentsDocumentable: { documentId },
      acceptedAt,
    } = action.payload;

    const documents = state.documents.map(d => {
      if (d.id === documentId) {
        return {
          ...d,
          accepted_at: acceptedAt,
        };
      }
      return { ...d };
    });

    return {
      ...state,
      documents,
      isAcceptingDocument: false,
      currentDocumentIndex: state.currentDocumentIndex + 1,
    };
  }

  return state;
};

export const createPurchaseWindowFromApiWindow = (
  window: Window,
  purchase_opportunities: any[],
  awards: APIAward[]
): PurchaseWindow => ({
  ...window,
  purchase_opportunity:
    purchase_opportunities &&
    purchase_opportunities
      .filter(o => o.windowId === window.id)
      .filter(o =>
        o.purchase_type === PurchaseType.PURCHASE_OPPORTUNITY_CASH
          ? Number(o.cash_amount_used) === 0
          : Number(o.purchasedAmount) === 0
      )
      .sort((a, b) => {
        // Make sure that if multiple purchase opportunities in the same window is possble, the best discount is selected first (amount is used if discount is the same)
        if (a.purchase_type.localeCompare(b.purchase_type) !== 0) {
          return a.purchase_type.localeCompare(b.purchase_type);
        }
        const type = a.purchase_type;
        const discountDiff = Number(b.discount) - Number(a.discount);
        const aAmount =
          type === PurchaseType.PURCHASE_OPPORTUNITY_CASH
            ? a.maximum_cash_amount
            : a.maximumAmount;
        const bAmount =
          type === PurchaseType.PURCHASE_OPPORTUNITY_CASH
            ? b.maximum_cash_amount
            : b.maximumAmount;
        return discountDiff === 0
          ? Number(aAmount) - Number(bAmount)
          : discountDiff;
      })[0],
  purchasable_award:
    awards &&
    awards.filter(
      a =>
        a.is_purchasable &&
        a.incentive_sub_program.purchase_config.window_id === window.id
    )[0],
});

const keepType = (type: WindowType) => w => w.window_type === type;
export const toWindow = (w): Window => ({
  id: w.id,
  from: moment(w.start_time),
  to: moment(w.end_time),
  paymentDeadline: moment(w.payment_deadline),
  type: w.window_type,
  allowed_exercise_types: w.allowed_exercise_types,
  require_share_depository: w.require_share_depository,
  require_share_depository_bank: w.require_share_depository_bank,
  share_depository_bank_options: w.share_depository_bank_options,
  require_share_depository_contact: w.require_share_depository_contact,
  require_share_depository_description: w.require_share_depository_description,
  require_share_depository_clearing_code:
    w.require_share_depository_clearing_code,
  require_bank_account: w.require_bank_account,
  require_tax_percentage: w.require_tax_percentage,
  require_bank_account_for_tax_percentage:
    w.require_bank_account_for_tax_percentage,
  commission_percentage: w.commission_percentage
    ? parseFloat(w.commission_percentage)
    : null,
  is_release_process: w.is_release_process,
  dont_use_dnb_agreement: w.dont_use_dnb_agreement,
});

export default userReducer;
