import { createAction } from "redux-act";

import { EmployeesPaginated } from "src/admin-portal/employees/employee-reducer";
import { PaginationLinks } from "src/common/utils/pagination";

export const fetchEmployee = createAction(
  "FETCH_EMPLOYEE",
  (payload: Api.V1.Employee["id"]) => payload
);
export const fetchEmployeeSucceeded = createAction(
  "FETCH_EMPLOYEE_SUCCEEDED",
  (payload: Api.V1.Employee) => payload
);

export const fetchEmployeeAndEntities = createAction(
  "FETCH_EMPLOYEE_AND_ENTITIES",
  (payload: Api.V1.Employee["id"]) => payload
);
export const fetchEmployeeAndEntitiesSucceeded = createAction(
  "FETCH_EMPLOYEE_AND_ENTITIES_SUCCEEDED",
  payload => payload
);

export const fetchEmployeesAndEntities = createAction(
  "FETCH_EMPLOYEES_AND_ENTITIES"
);
export const fetchEmployeesAndEntitiesSucceeded = createAction(
  "FETCH_EMPLOYEES_AND_ENTITIES_SUCCEEDED"
);

export const postEmployee = createAction(
  "POST_EMPLOYEE",
  (payload: Api.V1.PostEmployeeParams) => payload
);

export const postEmployeeSucceeded = createAction(
  "POST_EMPLOYEE_SUCCEEDED",
  (payload: Api.V1.Employee) => payload
);

export const putEmployee = createAction(
  "PUT_EMPLOYEE",
  (payload: Api.V1.PutEmployeeParams) => payload
);

export const putEmployeeSucceeded = createAction(
  "PUT_EMPLOYEE_SUCCEEDED",
  (payload: Api.V1.Employee) => payload
);

export const deleteEmployee = createAction(
  "DELETE_EMPLOYEE",
  (payload: Api.V1.Employee["id"]) => payload
);
export const deleteEmployeeSucceeded = createAction(
  "DELETE_EMPLOYEE_SUCCEEDED",
  (payload: Api.V1.Employee["id"]) => payload
);

export const selectEmployee = createAction(
  "SELECT_EMPLOYEE",
  (payload: Api.V1.Employee) => payload
);

export const selectEmployeeSucceeded = createAction(
  "SELECT_EMPLOYEE_SUCCEEDED",
  (payload: Api.V1.Employee) => payload
);

export const fetchEmployeesPaginated = createAction(
  "FETCH_EMPLOYEES_PAGINATED",
  (payload: {
    pageNumber?: number;
    pageSize?: number;
    filters?: any;
    sort?: string;
  }) => payload
);

export const fetchEmployeesPaginatedSucceeded = createAction(
  "FETCH_EMPLOYEES_PAGINATED_SUCCEEDED",
  (payload: {
    employeesPaginated: EmployeesPaginated;
    employeesPaginationLinks: PaginationLinks;
    recordsCount: number;
    employeesPaginationCurrentPage?: number;
  }) => payload
);

export const fetchEmployeesForExport = createAction(
  "FETCH_EMPLOYEES_FOR_EXPORT",
  (payload: { filters: any }) => payload
);

export const fetchEmployeesForExportSucceeded = createAction(
  "FETCH_EMPLOYEES_FOR_EXPORT_SUCCEEDED",
  (payload: Api.V1.Employee[]) => payload
);

export const clearEmployeesForExport = createAction(
  "CLEAR_EMPLOYEES_FOR_EXPORT"
);

export const fetchEmployees = createAction("FETCH_EMPLOYEES");
export const fetchEmployeesSucceeded = createAction(
  "FETCH_EMPLOYEES_SUCCEEDED",
  (payload: Api.V1.Employee[]) => payload
);

export const deleteEmployees = createAction(
  "DELETE_EMPLOYEES",
  (payload: Array<Api.V1.Employee["id"]>) => payload
);
export const deleteEmployeesSucceeded = createAction(
  "DELETE_EMPLOYEES_SUCCEEDED",
  (payload: Array<Api.V1.Employee["id"]>) => payload
);

export const deleteAllEmployees = createAction("DELETE_ALL_EMPLOYEES");
export const deleteAllEmployeesSucceeded = createAction(
  "DELETE_ALL_EMPLOYEES_SUCCEEDED",
  payload => payload
);

export const terminateEmployee = createAction(
  "TERMINATE_EMPLOYEE",
  (payload: Api.V1.TerminateEmployeeParams) => payload
);
export const terminateEmployeeSucceeded = createAction(
  "TERMINATE_EMPLOYEE_SUCCEEDED",
  (payload: { employeeId: Api.V1.Employee["id"]; terminationDate: string }) =>
    payload
);

export const terminateEmployeeCustom = createAction(
  "TERMINATE_EMPLOYEE_CUSTOM",
  (payload: { employeeId: Api.V1.Employee["id"]; payload: any }) => payload
);
export const terminateEmployeeCustomSucceeded = createAction(
  "TERMINATE_EMPLOYEE_CUSTOM_SUCCEEDED",
  payload => payload
);

export const reverseTerminateEmployee = createAction(
  "REVERSE_TERMINATE_EMPLOYEE",
  (payload: Api.V1.Employee["id"]) => payload
);
export const reverseTerminateEmployeeSucceeded = createAction(
  "REVERSE_TERMINATE_EMPLOYEE_SUCCEEDED",
  (payload: Api.V1.Employee["id"]) => payload
);

export const reverseTerminateEmployeeFailed = createAction(
  "REVERSE_TERMINATE_EMPLOYEE_FAILED",
  (payload: string) => payload
);

export const importEditedEmployees = createAction(
  "IMPORT_EDITED_EMPLOYEES",
  (payload: Api.V1.PutEmployeeParams[]) => payload
);

export const importEditedEmployeesSucceeded = createAction(
  "IMPORT_EDITED_EMPLOYEES_SUCCEEDED",
  (payload: Api.V1.Employee[]) => payload
);

export const importAllEmployees = createAction(
  "IMPORT_ALL_EMPLOYEES",
  (payload: Api.V1.Employee[]) => payload
);
export const importAllEmployeesSucceeded = createAction(
  "IMPORT_ALL_EMPLOYEES_SUCCEEDED",
  (payload: Api.V1.Employee[]) => payload
);

export const importAllEmployeesProgress = createAction(
  "IMPORT_ALL_EMPLOYEES_Progress",
  (payload: { current: number; total: number }) => payload
);

export const fetchEmployeesWithPagination = createAction(
  "FETCH_EMPLOYEES_WITH_PAGINATION",
  (payload: Api.V1.QueryParams.FetchEmployees) => payload
);

export const fetchEmployeesWithPaginationSucceeded = createAction(
  "FETCH_EMPLOYEES_WITH_PAGINATION_SUCCEEDED",
  (payload: { records: Api.V1.Employee[]; meta: any }) => payload
);

export const fetchEmployeesWithPaginationFailed = createAction(
  "FETCH_EMPLOYEES_WITH_PAGINATION_FAILED",
  (payload: string) => payload
);
