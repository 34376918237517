import React, { FunctionComponent, useState } from "react";
import { Button, Form } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import HelpPopup from "src/common/components/help-popup";
import { Window } from "src/common/data/data";
import {
  validNorwegianShareDepoNumber,
  validShareDepoBank,
  validShareDepoNumber,
} from "src/common/utils/share-depo";
import {
  InstrumentType,
  useInstrumentTypeText,
} from "src/common/utils/text-mappings";
import { EmployeePortalProfileState } from "src/employee-portal/employee-portal-profile-reducer";
import NorwegianShareDepoRadio, {
  HasNorwegianAccountAnswer,
} from "src/employee-portal/purchase/steps/share-depository/norwegian-share-depo-radio";
import ShareDepositoryNonNorwegianForm from "src/employee-portal/purchase/steps/share-depository/share-depository-non-norwegian-form";

export interface ShareDepositoryFormState {
  shareDepositoryNorwegian?: string;
  shareDepositoryAccountNumber?: string;
  shareDepoBank?: string;
  shareDepoClearingCode?: string;
  shareDepoContact?: string;
  shareDepoDescription?: string;
  shareDepositoryNotAvailable?: boolean;
  norwegianAccount?: HasNorwegianAccountAnswer;
}

interface Props {
  userProfile: EmployeePortalProfileState;
  window: Window;
  goBack: () => void;
  goForward: () => void;
  onChange: (value: ShareDepositoryFormState) => void;
  instrument: InstrumentType;
  shareDepoFormState?: ShareDepositoryFormState;
  showNorwegianShareDepoQuestion: boolean;
  enforceShareDepo: boolean;
  hideForeignAccountQuestion?: boolean;
}

const ShareDepository: FunctionComponent<Props> = ({
  instrument,
  window,
  goBack,
  goForward,
  userProfile,
  onChange,
  shareDepoFormState,
  showNorwegianShareDepoQuestion,
  enforceShareDepo,
  hideForeignAccountQuestion,
}) => {
  const formatMessage = useFormatMessage();
  const [shareDepoState, setShareDepoState] = useState<
    ShareDepositoryFormState
  >(
    shareDepoFormState ||
      setShareDepoFromUserProfile(userProfile, showNorwegianShareDepoQuestion)
  );
  const {
    shareDepoBank,
    shareDepoClearingCode,
    shareDepoContact,
    shareDepoDescription,
    shareDepositoryAccountNumber,
    shareDepositoryNotAvailable,
    shareDepositoryNorwegian,
    norwegianAccount,
  } = shareDepoState;
  const [error, setError] = useState<boolean>(
    hasErrors(shareDepoState, showNorwegianShareDepoQuestion, window)
  );

  // useEffect(() => setShareDepoState(setShareDepoFromUserProfile(userProfile)), [
  //   userProfile,
  // ]);

  const updateShareDepoState = (
    partialState: Partial<ShareDepositoryFormState>
  ) => {
    const newState = { ...shareDepoState, ...partialState };
    setShareDepoState(newState);
    onChange(newState);
    setError(hasErrors(newState, showNorwegianShareDepoQuestion, window));
  };

  const inputChangeHandler = (key: keyof ShareDepositoryFormState) => event =>
    updateShareDepoState({ [key]: event.target.value });

  const instrumentTerm = useInstrumentTypeText(instrument);

  const renderNorwegianBooleanContent = () => (
    <Form size={"large"}>
      <NorwegianShareDepoRadio
        answer={norwegianAccount}
        allowInProgress={!enforceShareDepo}
        allowForeignShareDepo={!hideForeignAccountQuestion}
        setHasNorwegianAccount={value => {
          updateShareDepoState({ norwegianAccount: value });
        }}
      />

      {norwegianAccount === HasNorwegianAccountAnswer.YES && (
        <>
          <div className="block-m" />
          <Form.Field>
            <label>
              <Content id="exercise.type.sharedepositoryaccount.norwegian.label" />
              <HelpPopup textKey="exercise.type.sharedepositoryaccount.norwegian.label.popup" />
            </label>
            <input
              placeholder={formatMessage({
                id:
                  "exercise.type.sharedepositoryaccount.norwegian.placeholder",
              })}
              value={shareDepositoryAccountNumber}
              disabled={shareDepositoryNotAvailable}
              onChange={inputChangeHandler("shareDepositoryAccountNumber")}
            />
          </Form.Field>
        </>
      )}

      {norwegianAccount === HasNorwegianAccountAnswer.NO && (
        <>
          <div className="block-m" />
          <ShareDepositoryNonNorwegianForm
            handleShareDepositoryNotAvailableToggle={() =>
              updateShareDepoState({
                shareDepositoryNotAvailable: !shareDepoState.shareDepositoryNotAvailable,
              })
            }
            onShareDepoBankChanged={inputChangeHandler("shareDepoBank")}
            onShareDepositoryAccountNumberChanged={inputChangeHandler(
              "shareDepositoryAccountNumber"
            )}
            onShareDepoClearingCodeChange={inputChangeHandler(
              "shareDepoClearingCode"
            )}
            onShareDepoContactChange={inputChangeHandler("shareDepoContact")}
            onShareDepoDescriptionChange={inputChangeHandler(
              "shareDepoDescription"
            )}
            shareDepoBank={shareDepoBank}
            shareDepositoryAccountNumber={shareDepositoryAccountNumber}
            shareDepoClearingCode={shareDepoClearingCode}
            shareDepoContact={shareDepoContact}
            shareDepoDescription={shareDepoDescription}
            shareDepositoryError={error}
            shareDepositoryNotAvailable={shareDepositoryNotAvailable}
            window={window}
            allowBypassing={!enforceShareDepo}
          />
        </>
      )}
    </Form>
  );
  const renderNonNorwegianBooleanContent = () => (
    <Form size={"large"}>
      <ShareDepositoryNonNorwegianForm
        handleShareDepositoryNotAvailableToggle={() =>
          updateShareDepoState({
            shareDepositoryNotAvailable: !shareDepoState.shareDepositoryNotAvailable,
          })
        }
        onShareDepoBankChanged={inputChangeHandler("shareDepoBank")}
        onShareDepositoryAccountNumberChanged={inputChangeHandler(
          "shareDepositoryAccountNumber"
        )}
        onShareDepoClearingCodeChange={inputChangeHandler(
          "shareDepoClearingCode"
        )}
        onShareDepoContactChange={inputChangeHandler("shareDepoContact")}
        onShareDepoDescriptionChange={inputChangeHandler(
          "shareDepoDescription"
        )}
        shareDepoBank={shareDepoBank}
        shareDepositoryAccountNumber={shareDepositoryAccountNumber}
        shareDepoClearingCode={shareDepoClearingCode}
        shareDepoContact={shareDepoContact}
        shareDepoDescription={shareDepoDescription}
        shareDepositoryError={error}
        shareDepositoryNotAvailable={shareDepositoryNotAvailable}
        window={window}
        allowBypassing={!enforceShareDepo}
      />
    </Form>
  );
  return (
    <div>
      <div className="block-l">
        <h2 className="text-center block-m">
          <Content
            id="purchase.sharedepository.header"
            values={{
              instrumentTermPlural: instrumentTerm.plural,
            }}
          />
        </h2>
        <p className="purchase-narrow-width margin-center block-m">
          <Content id="purchase.sharedepository.body" />
        </p>
        <div className="center-center">
          <div className="box purchase-box">
            {showNorwegianShareDepoQuestion
              ? renderNorwegianBooleanContent()
              : renderNonNorwegianBooleanContent()}
          </div>
        </div>
      </div>

      <div className="page-action-container text-center">
        <Button
          size="big"
          onClick={goBack}
          content={formatMessage({ id: "global.backBtnTxt" })}
        />
        <Button
          positive={true}
          content={formatMessage({ id: "global.nextBtnTxt" })}
          icon="right arrow"
          labelPosition="right"
          size="big"
          disabled={error}
          onClick={() => {
            goForward();
          }}
        />
      </div>
    </div>
  );
};

const hasErrors = (
  form: ShareDepositoryFormState,
  showNorwegianShareDepoQuestion: boolean,
  window: Window
): boolean => {
  const notAnsweredRadio =
    showNorwegianShareDepoQuestion && form.norwegianAccount === null;
  const norwegianHasErrors =
    form.norwegianAccount === HasNorwegianAccountAnswer.YES &&
    !validNorwegianShareDepoNumber(form.shareDepositoryAccountNumber);
  const nonNorwegianHasErrors =
    form.norwegianAccount === HasNorwegianAccountAnswer.NO &&
    nonNorwegianFormHasErrors(form, window.share_depository_bank_options);
  const hasErrors =
    notAnsweredRadio || norwegianHasErrors || nonNorwegianHasErrors;
  return form.shareDepositoryNotAvailable ||
    form.norwegianAccount === HasNorwegianAccountAnswer.NO_BUT_IN_PROGRESS
    ? false
    : hasErrors;
};

const nonNorwegianFormHasErrors = (
  form: ShareDepositoryFormState,
  shareDepoBankOptions?: string
) => {
  return (
    !validShareDepoNumber(form.shareDepositoryAccountNumber) ||
    !validShareDepoBank(form.shareDepoBank, shareDepoBankOptions)
  );
};

export const setShareDepoFromUserProfile = (
  userProfile: EmployeePortalProfileState,
  showNorwegianShareDepoQuestion: boolean
): ShareDepositoryFormState => ({
  shareDepositoryNorwegian: userProfile.share_depository_account || "",
  shareDepositoryAccountNumber: userProfile.share_depository_account || "",
  shareDepoBank: userProfile.share_depository_bank || "",
  shareDepoClearingCode: userProfile.share_depository_clearing_code || "",
  shareDepoContact: userProfile.share_depository_contact || "",
  shareDepoDescription: userProfile.share_depository_description || "",
  shareDepositoryNotAvailable: false,
  norwegianAccount:
    showNorwegianShareDepoQuestion && userProfile.share_depository_account
      ? HasNorwegianAccountAnswer.YES
      : null,
});

export default ShareDepository;
