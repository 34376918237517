import moment from "moment";

import {
  Window,
  WindowType,
} from "src/admin-portal/exercise-windows/window-reducer";
import { RootState } from "src/reducers/all-reducers";

export const token = (state: RootState) => state.user.token;
export const isSysadmin = (state: RootState) => state.user.isSysadmin;
export const tenantId = (state: RootState) => state.tenant.selectedTenant.id;
export const purchaseWindows = (state: RootState) =>
  (state.window.allWindows || []).filter(
    w => w.window_type === WindowType.PURCHASE && w.end_time.isAfter(moment())
  );

export const windowById = (
  windowId: string,
  state: RootState
): Window | undefined =>
  (state.window.allWindows || []).filter(w => w.id === windowId)[0];
