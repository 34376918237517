import { Fade as Hamburger } from "hamburger-react";
import React, { FunctionComponent, useState } from "react";
import { Menu } from "semantic-ui-react";

import Logo from "src/employee-portal/menu/Logo";
import MenuModal from "src/employee-portal/menu/MobileMenu/MenuModal";
import { MenuConfig } from "src/employee-portal/menu/useMenuConfig";

interface Props {
  children: JSX.Element;
  menuItems: MenuConfig[];
  logoUrl: string;
  isAdminUserWithoutInstruments: boolean;
  title: string;
}

const MobileMenu: FunctionComponent<Props> = ({
  children,
  menuItems,
  logoUrl,
  isAdminUserWithoutInstruments,
  title,
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <div className="menu-employee-portal-mobile">
      <Menu size="huge" className="main-menu" borderless>
        <Logo
          logoUrl={logoUrl}
          isAdminUserWithoutInstruments={isAdminUserWithoutInstruments}
          title={title}
        />

        <Menu.Menu position="right">
          <Hamburger
            direction="left"
            size={20}
            toggled={isMobileMenuOpen}
            toggle={setIsMobileMenuOpen}
            label={isMobileMenuOpen ? "hide-menu" : "open-menu"}
          />
        </Menu.Menu>
      </Menu>

      <MenuModal
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        menuItems={menuItems}
      />

      <div className="sub-menu">{children}</div>
    </div>
  );
};

export default MobileMenu;
