import moment from "moment";
import "moment/locale/nb.js";
import numeral from "numeral";
import "numeral/locales/no.js";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom";
import { hot } from "react-hot-loader/root";

import App from "src/app";
import "src/common/less/flexbox-utils.less";
import "src/common/less/support-widget.less";
import { decodedToken } from "src/common/auth/auth";

const veidekkeTenantIds = [
  "a5535748-1958-48d3-a5da-4adbe477ddf6",
  "db9613a5-ddb5-49f9-9c3d-8657e06efd99",
  "57dc7011-a507-4c30-a386-d9cb7a2853ea",
  "a79c4a07-8de0-4b8f-b6b0-35d9566627a7",
  "a4a03741-3711-4dd1-b2f5-bfa25166ab3b",
];
//if (decodedToken() && veidekkeTenantIds.includes(decodedToken().tenants[0])) {
//require("src/veidekke.less");
//} else {
require("src/index.less");
//}

numeral.locale("no");
moment.locale("nb");

export const CONTACT_EMAIL = "support@optioincentives.no";
// export const CONTACT_PHONE = "+47 22 34 33 32";

const PotentiallyHotApp = hot(App);

ReactDOM.render(<PotentiallyHotApp />, document.getElementById("root"));
