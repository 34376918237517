import React, { FunctionComponent } from "react";
import { Label } from "semantic-ui-react";

import { statusToTitle } from "src/admin-portal/tranches-page/utils";

interface Props {
  status: string[];
}

const TrancheStatus: FunctionComponent<Props> = ({ status }) => (
  <>
    {status.includes("active") && (
      <Label content={statusToTitle("active")} color="blue" />
    )}
    {status.includes("exercised") && (
      <Label content={statusToTitle("exercised")} color="green" />
    )}
    {status.includes("expired") && (
      <Label content={statusToTitle("expired")} color="red" />
    )}
    {status.includes("notActive") && (
      <Label content={statusToTitle("notActive")} />
    )}
    {status.includes("released") && (
      <Label content={statusToTitle("released")} color="green" />
    )}
    {status.includes("terminated") && (
      <Label content={statusToTitle("terminated")} color="yellow" />
    )}
  </>
);

export default TrancheStatus;
