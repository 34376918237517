import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Form, Message } from "semantic-ui-react";

import { ValidationError } from "yup";

import Content from "src/admin-portal/texts/content";
import { IStringStringMap } from "src/admin-portal/texts/employee-portal-texts-reducer";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import { FormatMessageFuntion } from "src/common/utils/text-mappings";
import {
  companyDataSchema,
  investmentEntitySchema,
} from "src/employee-portal/purchase/purchase-utils";

export enum InvestmentEntityType {
  INDIVIDUAL = "INDIVIDUAL",
  COMPANY = "COMPANY",
}

const performValidation = (
  formatMessage: FormatMessageFuntion,
  toValidate: object,
  investmentEntityType: InvestmentEntityType
): ValidationError | null => {
  let ve = null;
  try {
    const validation = investmentEntitySchema(formatMessage).validateSync(
      toValidate,
      {
        abortEarly: false,
        context: { investmentEntityType },
      }
    );
    console.log(validation);
  } catch (error) {
    console.log(error);
    ve = error;
  }

  return ve;
};

interface Props {
  goBack: () => void;
  goForward: () => void;
  contentValues: IStringStringMap;
  investmentEntityType: InvestmentEntityType;
  setInvestmentEntityType: (type: InvestmentEntityType) => void;
  companyIdentifier: string;
  companyAddress: string;
  companyEmail: string;
  personalIdentifier: string;
  personalAddress: string;
  handleChange: (event: any, arg2: any) => void;
  valid: boolean;
}

const InvestmentEntity: FunctionComponent<Props> = ({
  goBack,
  goForward,
  contentValues,
  companyIdentifier,
  companyAddress,
  companyEmail,
  personalIdentifier,
  personalAddress,
  investmentEntityType,
  setInvestmentEntityType,
  handleChange,
  valid,
}) => {
  const [validationError, setValidationError] = useState<ValidationError>(null);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const formatMessage = useFormatMessage();

  useEffect(() => {
    const ve = performValidation(
      formatMessage,
      {
        companyIdentifier,
        companyEmail,
        companyAddress,
        personalIdentifier,
        personalAddress,
      },
      investmentEntityType
    );

    setValidationError(ve);
  }, [
    companyAddress,
    companyEmail,
    personalAddress,
    personalIdentifier,
    companyIdentifier,
  ]);

  const onNext = () => {
    const ve = performValidation(
      formatMessage,
      {
        companyIdentifier,
        companyEmail,
        companyAddress,
        personalIdentifier,
        personalAddress,
      },
      investmentEntityType
    );

    if (ve) {
      setValidationError(ve);
      setShowValidationErrors(true);
    } else {
      setShowValidationErrors(false);
      goForward();
    }
  };

  const renderPersonalIdentifierField = () => (
    <Form.Field>
      <label>
        <Content id="purchase.investmententity.personal.label" />
      </label>
      <Form.Input
        name="personalIdentifier"
        value={personalIdentifier}
        onChange={handleChange}
        error={
          showValidationErrors &&
          validationError &&
          validationErrorForField(validationError, "personalIdentifier")
        }
      />
    </Form.Field>
  );

  return (
    <div>
      <div className="block-m">
        <div className="text-center block-m">
          <h2 className="block-m">
            <Content id="purchase.investmententity.header" />
          </h2>
        </div>
        <div className="block-m purchase-narrow-width margin-center">
          <Content
            id="purchase.investmententity.content"
            values={contentValues}
          />
        </div>
      </div>
      <div className="center-center block-m">
        <div className="box purchase-box">
          <Form
            size={"large"}
            error={showValidationErrors && !!validationError}
          >
            <div className="block-xs">
              <label>
                <Content id="purchase.investmententity.question" />
              </label>
            </div>
            <div className="block-xs">
              <Form.Radio
                label={
                  <label>
                    <Content id="purchase.investmententity.answer.individual" />
                  </label>
                }
                value="yes"
                checked={
                  investmentEntityType === InvestmentEntityType.INDIVIDUAL
                }
                onChange={() => {
                  setValidationError(null);
                  setInvestmentEntityType(InvestmentEntityType.INDIVIDUAL);
                }}
              />
            </div>
            <div className="block-xs">
              <Form.Radio
                label={
                  <label>
                    <Content id="purchase.investmententity.answer.company" />
                  </label>
                }
                value="yes"
                checked={investmentEntityType === InvestmentEntityType.COMPANY}
                onChange={() => {
                  setValidationError(null);
                  setInvestmentEntityType(InvestmentEntityType.COMPANY);
                }}
              />
            </div>
            {showValidationErrors && validationError && (
              <div className="">
                <div className="block-m" />
                <Message
                  error={true}
                  header={"Errors"}
                  list={validationError.errors}
                />
              </div>
            )}
            {investmentEntityType === InvestmentEntityType.INDIVIDUAL && (
              <div>
                <div className="block-m" />
                {renderPersonalIdentifierField()}
                <div className="block-xs" />
                <Form.Field>
                  <label>
                    <Content id="purchase.investmententity.personal.address.label" />
                  </label>
                  <Form.TextArea
                    name="personalAddress"
                    value={personalAddress}
                    onChange={handleChange}
                    error={
                      showValidationErrors &&
                      validationError &&
                      validationErrorForField(
                        validationError,
                        "personalAddress"
                      )
                    }
                  />
                </Form.Field>
              </div>
            )}
            {investmentEntityType === InvestmentEntityType.COMPANY && (
              <div>
                <div className="block-m" />
                {renderPersonalIdentifierField()}
                <div className="block-xs" />
                <Form.Field>
                  <label>
                    <Content id="purchase.investmententity.company.identifier.label" />
                  </label>
                  <Form.Input
                    name="companyIdentifier"
                    value={companyIdentifier}
                    onChange={handleChange}
                    error={
                      showValidationErrors &&
                      validationError &&
                      validationErrorForField(
                        validationError,
                        "companyIdentifier"
                      )
                    }
                  />
                </Form.Field>
                <div className="block-xs" />
                <Form.Field>
                  <label>
                    <Content id="purchase.investmententity.company.email.label" />
                  </label>
                  <Form.Input
                    name="companyEmail"
                    value={companyEmail}
                    onChange={handleChange}
                    error={
                      showValidationErrors &&
                      validationError &&
                      validationErrorForField(validationError, "companyEmail")
                    }
                  />
                </Form.Field>
                <div className="block-xs" />
                <Form.Field>
                  <label>
                    <Content id="purchase.investmententity.company.address.label" />
                  </label>
                  <Form.TextArea
                    name="companyAddress"
                    value={companyAddress}
                    onChange={handleChange}
                    error={
                      showValidationErrors &&
                      validationError &&
                      validationErrorForField(validationError, "companyAddress")
                    }
                  />
                </Form.Field>
              </div>
            )}
          </Form>
        </div>
      </div>
      <div className="page-action-container text-center">
        <Button
          content={<Content id="global.backBtnTxt" />}
          size="big"
          onClick={() => {
            setShowValidationErrors(false);
            goBack();
          }}
        />
        <Button
          positive={true}
          content={<Content id="global.nextBtnTxt" />}
          icon="right arrow"
          labelPosition="right"
          size="big"
          disabled={!investmentEntityType}
          onClick={onNext}
        />
      </div>
    </div>
  );
};

const validationErrorForField = (
  validationError: ValidationError,
  fieldName: string
) => {
  if (!validationError) {
    return false;
  }

  const field = validationError.inner.filter(
    inner => inner.path === fieldName
  )[0];
  return !!field;
};

export default InvestmentEntity;
