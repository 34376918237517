import React, { FunctionComponent } from "react";

import CommonShareDepositoryFields from "src/employee-portal/order/table/UpdateModal/Form/CommonShareDepositoryFields";
import BankAccount from "src/employee-portal/order/table/UpdateModal/Form/fields/BankAccount";
import BICSwiftNumber from "src/employee-portal/order/table/UpdateModal/Form/fields/BICSwiftNumber";
import { FieldsVisibility } from "src/employee-portal/order/table/UpdateModal/Form/UseFieldsVisibilityCheck";

interface Props {
  exerciseOrder: Api.V1.ExerciseOrder;
  visibility: FieldsVisibility;
}

const ExerciseFields: FunctionComponent<Props> = ({
  exerciseOrder,
  visibility,
}) => {
  if (!exerciseOrder) {
    return null;
  }

  return (
    <>
      <BankAccount visible={visibility.isBankAccountVisible} />
      <BICSwiftNumber visible={visibility.isBICSwiftNumberVisible} />

      <CommonShareDepositoryFields visibility={visibility} />
    </>
  );
};

export default ExerciseFields;
