import { createAction } from "redux-act";

export const CREATE_PURCHASE_CONFIG_SUCCEEDED =
  "CREATE_PURCHASE_CONFIG_SUCCEEDED";
export const CREATE_PURCHASE_CONFIG_FAILED = "CREATE_PURCHASE_CONFIG_FAILED";
export const CREATE_PURCHASE_CONFIG = "CREATE_PURCHASE_CONFIG";

export const DELETE_PURCHASE_CONFIG_SUCCEEDED =
  "DELETE_PURCHASE_CONFIG_SUCCEEDED";
export const DELETE_PURCHASE_CONFIG_FAILED = "DELETE_PURCHASE_CONFIG_FAILED";
export const DELETE_PURCHASE_CONFIG = "DELETE_PURCHASE_CONFIG";

export const UPDATE_PURCHASE_CONFIG_SUCCEEDED =
  "UPDATE_PURCHASE_CONFIG_SUCCEEDED";
export const UPDATE_PURCHASE_CONFIG_FAILED = "UPDATE_PURCHASE_CONFIG_FAILED";
export const UPDATE_PURCHASE_CONFIG = "UPDATE_PURCHASE_CONFIG";
