import { createAction } from "redux-act";

export const fetchTenantOrder = createAction(
  "FETCH_TENANT_ORDER",
  (payload: Api.V1.QueryParams.FetchOrder) => payload
);

export const fetchTenantOrderSucceeded = createAction(
  "FETCH_TENANT_ORDER_SUCCEEDED",
  (payload: Api.V1.Order) => payload
);

export const putTenantOrder = createAction(
  "PUT_TENANT_ORDER",
  (payload: {
    data: Pick<Api.V1.Order, "id" | "status">;
    relationships?: Api.V1.OrderShareDepositoryUpdate;
    include?: Api.V1.QueryParams.FetchOrders["include"];
  }) => payload
);

export const putTenantOrderSucceeded = createAction(
  "PUT_TENANT_ORDER_SUCCEEDED",
  (payload: Api.V1.Order) => payload
);
