import React, { FunctionComponent } from "react";
import { Step } from "semantic-ui-react";

import { useFormatMessage } from "src/admin-portal/texts/utils";

const clickHandler = onClick => ({ onClick });

interface StepIndicatorProps {
  data: StepIndicatorData[];
  onClick: (clickedIndex: number) => void;
  activeIndex: number;
}

export const StepIndicator: FunctionComponent<StepIndicatorProps> = ({
  onClick,
  data,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <Step.Group ordered={true}>
      {data.map((step, index) => (
        <Step completed={step.completed} active={step.active} key={index}>
          <div
            className={step.clickable ? "clickable" : ""}
            {...(step.clickable ? clickHandler(onClick.bind(this, index)) : {})}
          >
            <Step.Content>
              <Step.Title>
                {formatMessage({ id: step.titleTextKey })}
              </Step.Title>
              <Step.Description>
                {step.descriptionTextKey &&
                  formatMessage({ id: step.descriptionTextKey })}
              </Step.Description>
            </Step.Content>
          </div>
        </Step>
      ))}
    </Step.Group>
  );
};

export interface StepIndicatorData {
  key: PurchaseStep;
  titleTextKey: string;
  descriptionTextKey?: string;
  clickable?: boolean;
  active: boolean;
  completed: boolean;
}

export enum PurchaseStep {
  INFO = 0,
  DOCUMENT = 1,
  QUANTITY = 2,
  CASH_AMOUNT = 3,
  BANK_ACCOUNT = 4,
  INVESTMENT_ENTITY = 5,
  SHARE_DEPOSITORY = 6,
  CONFIRM = 7,
}

export const purchaseStepHandler = (steps: StepIndicatorData[]) => ({
  next: (currentStep: PurchaseStep) => {
    const index = steps.findIndex(step => step.key === currentStep);
    const step = steps[index + 1];
    return step ? step.key : null;
  },
  previous: (currentStep: PurchaseStep) => {
    const index = steps.findIndex(step => step.key === currentStep);
    const step = steps[index - 1];
    return step ? step.key : null;
  },
  toIndex: (index: number) => (steps[index] ? steps[index].key : null),
});

export const purchaseSteps = (
  activeStep: number,
  showShareDepositoryStep: boolean,
  showQuantityStep: boolean,
  showCashAmountStep: boolean,
  showBankAccountStep: boolean,
  showInvestmentEntityStep: boolean
): StepIndicatorData[] =>
  [
    {
      key: PurchaseStep.INFO,
      titleTextKey: "purchase.steps.info.title",
    },
    {
      key: PurchaseStep.DOCUMENT,
      titleTextKey: "purchase.steps.01.title",
      descriptionTextKey: "purchase.steps.01.description",
    },
    {
      key: PurchaseStep.QUANTITY,
      titleTextKey: "purchase.steps.02.title",
      descriptionTextKey: "purchase.steps.02.description",
    },
    {
      key: PurchaseStep.CASH_AMOUNT,
      titleTextKey: "purchase.steps.cash.title",
      descriptionTextKey: "purchase.steps.cash.description",
    },
    {
      key: PurchaseStep.BANK_ACCOUNT,
      titleTextKey: "purchase.steps.bankaccount.title",
    },
    {
      key: PurchaseStep.INVESTMENT_ENTITY,
      titleTextKey: "purchase.steps.investmententity.title",
    },
    {
      key: PurchaseStep.SHARE_DEPOSITORY,
      titleTextKey: "purchase.steps.03.title",
    },
    {
      key: PurchaseStep.CONFIRM,
      titleTextKey: "purchase.steps.04.title",
    },
  ]
    .map((x, index) => ({
      ...x,
      clickable: index < activeStep,
      active: index === activeStep,
      completed: index < activeStep,
    }))
    .filter(
      (step, index) =>
        // index !== PurchaseStep.INFO &&
        (showShareDepositoryStep || index !== PurchaseStep.SHARE_DEPOSITORY) &&
        (showCashAmountStep || index !== PurchaseStep.CASH_AMOUNT) &&
        (showBankAccountStep || index !== PurchaseStep.BANK_ACCOUNT) &&
        (showQuantityStep || index !== PurchaseStep.QUANTITY) &&
        (showInvestmentEntityStep || index !== PurchaseStep.INVESTMENT_ENTITY)
    );

export default StepIndicator;
