import React, { FunctionComponent, StatelessComponent } from "react";
import { Table } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import { CurrencyFormatterProps } from "src/common/components/currency/currency-formatter";
import HelpPopup from "src/common/components/help-popup";
import IfContentExists from "src/common/texts/if-content-exists";
import { formatNumber, removeNulls, sumNumbers } from "src/common/utils/utils";
import { NO_VALUE } from "src/constants";
import ExerciseQuantityTableRow from "src/employee-portal/exercise/quantity/exercise-quantity-table-row";
import { FlatAward } from "src/employee-portal/instrument-page/instruments-reducer";
import { DateFormatState } from "src/reducers/date-format-reducer";

const tableHeaderKeys = (
  showCapOnGain: boolean,
  showVariableCost: boolean,
  showStrike: boolean,
  showExpiryDate: boolean
) =>
  [
    "exercise.quantity.table.program",
    showStrike ? "exercise.quantity.table.strike" : null,
    showCapOnGain ? "exercise.quantity.table.capped" : null,
    "exercise.quantity.table.grant",
    "exercise.quantity.table.vested",
    showExpiryDate ? "exercise.quantity.table.expiry" : null,
    "exercise.quantity.table.exercisiblequantity",
    "exercise.quantity.table.orderquantity",
    "exercise.quantity.table.sharesquantity",
    showVariableCost ? "exercise.quantity.table.variablecost" : null,
    showVariableCost ? "exercise.quantity.table.totalvariablecost" : null,
    "exercise.quantity.table.gain",
  ].filter(removeNulls);

interface Props {
  formatter: CurrencyFormatterProps;
  estimatedGainSum: number;
  vestedOptions: FlatAward[];
  quantityChangedHandler: (index: number, event) => void;
  quantities: string[];
  quantitiesValid: boolean[];
  dateFormat: DateFormatState;
  sharePrice: number;
  showCapOnGain: boolean;
  showVariableCost: boolean;
}

const ExerciseQuantityTable: FunctionComponent<Props> = ({
  formatter,
  estimatedGainSum,
  vestedOptions,
  quantityChangedHandler,
  quantities,
  quantitiesValid,
  dateFormat,
  sharePrice,
  showCapOnGain,
  showVariableCost,
}) => {
  const footerColSpan = () => {
    let count = showCapOnGain ? 1 : 0;
    count += showStrike ? 1 : 0;
    count += showExpiryDate ? 1 : 0;
    return count + 4;
  };

  const sumSelectedQuantities = quantities
    .map(string => (isNaN(parseInt(string)) ? 0 : parseInt(string)))
    .reduce(sumNumbers);

  const showStrike = vestedOptions.some(a => a.strike !== 0);
  const showExpiryDate = vestedOptions.some(a => a.instrumentType !== "share");

  return (
    <div>
      <Table
        celled={true}
        textAlign="center"
        className="block-m"
        collapsing={true}
        padded={true}
        unstackable={true}
      >
        <Table.Header>
          <Table.Row>
            {tableHeaderKeys(
              showCapOnGain,
              showVariableCost,
              showStrike,
              showExpiryDate
            ).map(key => (
              <Table.HeaderCell key={key}>
                <Content id={key} />
                <IfContentExists id={`${key}.popup`}>
                  <HelpPopup textKey={`${key}.popup`} />
                </IfContentExists>
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {vestedOptions.map((vested, index) => (
            <ExerciseQuantityTableRow
              key={index}
              vested={vested}
              changeHandler={quantityChangedHandler.bind(this, index)}
              quantityInputState={quantities[index]}
              quantityInputStateValid={quantitiesValid[index]}
              dateFormat={dateFormat}
              sharePrice={sharePrice}
              showCapOnGain={showCapOnGain}
              showVariableCost={showVariableCost}
              showStrike={showStrike}
              showExpiryDate={showExpiryDate}
            />
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={footerColSpan()} textAlign="left">
              <Content id={"exercise.quantity.table.totalEstimatedgain"} />
            </Table.HeaderCell>
            <Table.HeaderCell>
              {formatNumber(sumSelectedQuantities)}
            </Table.HeaderCell>
            <Table.HeaderCell />
            {showVariableCost && <Table.HeaderCell colSpan="2" />}
            <Table.HeaderCell textAlign="right" colSpan="1">
              ≈ {formatter.formatCurrency(estimatedGainSum)}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      <div className="required text-right">
        <Content id="exercise.exercise.note" />
      </div>
    </div>
  );
};

export default ExerciseQuantityTable;
