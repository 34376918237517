import * as queryString from "querystring";

import { push } from "connected-react-router";
import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "react-router";

import useJsonApiQuery from "src/common/api/useJsonApiQuery";
import SpinnerFullScreen from "src/common/components/spinner-full-screen";
import FileDownloadModal from "src/employee-portal/download/Modal";

type Props = {
  routeComponentProps: RouteComponentProps<{ documentId: string }>;
};

const DownloadFilePage: FunctionComponent<Props> = ({
  routeComponentProps: { match, location },
}) => {
  const onClose = () => push("/");

  const { sameTab } = queryString.parse(location.search.replace("?", ""));
  const documentId = match.params.documentId;

  const { data, loading } = useJsonApiQuery<Api.V1.Document, {}, {}>(
    `/uploaded-files/${documentId}`
  );

  if (!data || loading) {
    return <SpinnerFullScreen active={true} />;
  }

  return (
    <div>
      <FileDownloadModal
        file={data}
        handleClose={onClose}
        sameTab={sameTab === "true"}
      />
    </div>
  );
};

export default DownloadFilePage;
