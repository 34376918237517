import moment from "moment";
import React, { FunctionComponent, useEffect } from "react";
import { connect } from "react-redux";
import { Link, Route, RouteComponentProps, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Button, Icon } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import SpinnerInline from "src/common/components/spinner-inline";
import { ErrorModalClosable } from "src/common/error/error-modal-closable";
import { apiShortDate } from "src/common/utils/utils";
import { fetchOrders } from "src/employee-portal/actions/order";
import NoOrdersMessage from "src/employee-portal/order/no-orders-message";
import {
  ExerciseOrderSuccessMessage,
  PurchaseOrderSuccessMessage,
} from "src/employee-portal/order/order-success";
import OrderTable from "src/employee-portal/order/table/order-table";
import { RootState } from "src/reducers/all-reducers";

interface PassedProps {
  refreshWelcomeData: () => void;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = PassedProps & StateProps & DispatchProps & RouteComponentProps<{}>;

export const purchaseTypeTextKeys = (purchasedInstrument: string): string => {
  switch (purchasedInstrument) {
    case "warrant":
      return "purchase.type.warrant";
    case "option":
      return "purchase.type.option";
    case "rsu":
      return "purchase.type.rsu";
    case "rsa":
      return "purchase.type.rsa";
    case "psu":
      return "purchase.type.psu";
    case "convertible_loan":
      return "purchase.type.convertibleloan";
    case "share":
      return "purchase.type.share";
  }
};

const OrderPage: FunctionComponent<Props> = ({
  orders,
  currentExerciseWindow,
  currentReleaseWindow,
  currentPurchaseWindow,
  fetchOrders,
  errorFetchingOrders,
  dateFormat,
  isFetchingOrders,
  match,
  paymentAccount,
}) => {
  const formatMessage = useFormatMessage();

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div className="main-content">
      <div className="text-content-center">
        {errorFetchingOrders && (
          <div>
            <ErrorModalClosable
              message="We're sorry, an error occurred while fetching your orders"
              renderActions={() => (
                <div>
                  <Link to={"/"} className="ui primary button">
                    <Icon name="home" /> Home
                  </Link>
                  <Button onClick={fetchOrders} color="blue">
                    <Icon name="refresh" /> Try Again
                  </Button>
                </div>
              )}
            />
          </div>
        )}

        {isFetchingOrders && <SpinnerInline active={true} />}

        {!errorFetchingOrders && orders && orders.length === 0 && (
          <NoOrdersMessage />
        )}

        {orders && orders.length > 0 && (
          <div>
            <h1 className="block-m">
              <Content id={"orders.title"} />
            </h1>
            <Route
              path={`${match.path}/exercisecomplete`}
              render={() => (
                <div className="text-content-center block-m">
                  <ExerciseOrderSuccessMessage
                    windowEndDate={currentExerciseWindow.to}
                    dateFormat={dateFormat}
                  />
                </div>
              )}
            />
            <Route
              path={`${match.path}/releasecomplete`}
              render={() => (
                <div className="text-content-center block-m">
                  <ExerciseOrderSuccessMessage
                    windowEndDate={currentReleaseWindow.to}
                    dateFormat={dateFormat}
                  />
                </div>
              )}
            />
            <Route
              path={`${match.path}/purchasecomplete`}
              render={() =>
                orders[0]?.window?.endTime && (
                  <div className="text-content-center block-m">
                    <PurchaseOrderSuccessMessage
                      windowEndDate={moment(orders[0]?.window?.endTime)}
                      dateFormat={dateFormat}
                    />
                  </div>
                )
              }
            />
            <div className="block-m row-center">
              <OrderTable
                dateFormat={dateFormat}
                orders={orders}
                paymentAccount={paymentAccount}
              />
            </div>
            <p className="text-content-center text-center">
              <Content
                id={"orders.contact.us"}
                values={{
                  email: formatMessage({ id: "global.contactEmail" }),
                }}
              />
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isFetchingOrders: state.order.isFetchingOrders,
  orders: state.order.orders,
  errorFetchingOrders: state.order.errorFetchingOrders,
  currentPurchaseWindow: state.user.currentPurchaseWindow,
  currentExerciseWindow: state.user.currentExerciseWindow,
  currentReleaseWindow: state.user.currentReleaseWindow,
  dateFormat: state.dateFormat,
  paymentAccount: state.user.paymentAccount,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      fetchOrders,
    },
    dispatch
  ),
});

const ConnectedComponent = connect<StateProps, DispatchProps, PassedProps>(
  mapStateToProps,
  mapDispatchToProps
)(OrderPage);

export default withRouter<RouteComponentProps<{}>>(ConnectedComponent);
