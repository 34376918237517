import { createAction } from "redux-act";

import { Entity } from "src/admin-portal/entity/entity-reducer";

export const fetchEntities = createAction("FETCH_ENTITIES");

export const fetchEntitiesSucceded = createAction(
  "FETCH_ENTITIES_SUCCEEDED",
  (payload: Api.V1.Entity[]) => payload
);

export const createEntities = createAction(
  "CREATE_ENTITIES",
  (payload: Entity[]) => payload
);

export const createEntitiesSucceded = createAction(
  "CREATE_ENTITIES_SUCCEEDED",
  (payload: Api.V1.Entity[]) => payload
);

export const deleteEntities = createAction(
  "DELETE_ENTITIES",
  (payload: Entity[]) => payload
);

export const deleteEntitiesSucceded = createAction(
  "DELETE_ENTITIES_SUCCEEDED",
  (payload: Array<Api.V1.Entity["id"]>) => payload
);

export const postEntity = createAction(
  "POST_ENTITY",
  (payload: Api.V1.Entity) => payload
);

export const postEntitySucceded = createAction(
  "POST_ENTITY_SUCCEEDED",
  (payload: Api.V1.Entity) => payload
);

export const putEntity = createAction(
  "PUT_ENTITY",
  (payload: { entityId: Api.V1.Entity["id"]; entity: Entity }) => payload
);

export const putEntitySucceded = createAction(
  "PUT_ENTITY_SUCCEEDED",
  (payload: Api.V1.Entity) => payload
);

export const deleteEntity = createAction(
  "DELETE_ENTITY",
  (payload: Api.V1.Entity["id"]) => payload
);

export const deleteEntitySucceded = createAction(
  "DELETE_ENTITY_SUCCEEDED",
  (payload: Api.V1.Entity["id"]) => payload
);

export const selectEntity = createAction(
  "SELECT_ENTITY",
  (payload: Api.V1.Entity["id"]) => payload
);

export const unselectEntity = createAction("UNSELECT_ENTITY");
