import moment, { Moment } from "moment";

import { TrancheDataEntry } from "src/admin-portal/tranches-page/types";
import { extractGrants } from "src/admin-portal/tranches-page/utils";
import { blackScholesExcel } from "src/common/data/black-scholes";
import { InterestRates } from "src/common/data/interest-rates";
import {
  apiShortDate,
  changeCommaForPunctuation,
  changePunctuationForComma,
  getDateDiff,
  prepareDateForBackend,
  toFixedAndRemoveScientificNotation,
  toNumber,
} from "src/common/utils/utils";

import { TransactionType } from "src/constants";
import { GlobalAnnualVolatilityState } from "src/reducers/share-price-reducer";

export interface InterestRatesState {
  interestRates: InterestRates[];
  isFetching: boolean;
  hasError: boolean;
}

export interface TrancheGroup {
  groupIndex: number;
  isActive: boolean;
  forActionDate: string;
  grantDate: string;
  vestedDate: string;
  expiryDate: string;
  strike: string;
  sharePriceObj: Api.V1.SharePrice;
  interestRates: InterestRates;
  tranches: TrancheDataEntry[];
  transactionKeysAndValues: TransactionKeysAndValues;
  volatilityPeriod: {
    startDate: string;
    endDate: string;
  };
}

export interface TransactionKeysAndValues {
  sharePrice: string;
  expectedLifetime: string;
  strike: string;
  volatility: string;
  interestRate: string;
  fairValue: string;
  dividend: string;
  comment: string;
  valuationMethod: string;
}

export const calcGroupedTranches = (
  tranches: TrancheDataEntry[],
  normalDateFormat: string,
  expectedLifetimeType: ExpectedLifetimeTypeEnum,
  overrideEstimatedLiftimeStart?: Moment
): TrancheGroup[] => {
  return tranches.reduce<TrancheGroup[]>((accu, currentTranche) => {
    const expiryDate = currentTranche.expiryDate;
    const existingIndex = accu.findIndex(
      x =>
        x.grantDate === currentTranche.grantDate &&
        x.vestedDate === currentTranche.vestedDate &&
        x.expiryDate === expiryDate &&
        x.strike === currentTranche.strike
    );

    if (existingIndex >= 0) {
      return accu.map((item, index) =>
        index === existingIndex
          ? {
              ...accu[existingIndex],
              tranches: accu[existingIndex].tranches.concat(currentTranche),
            }
          : { groupIndex: existingIndex, isActive: true, ...item }
      );
    } else {
      const lifetimeStart = overrideEstimatedLiftimeStart
        ? overrideEstimatedLiftimeStart
        : currentTranche.grantDate;

      const expiryDateMoment = moment(expiryDate, apiShortDate);
      const lifetimeEnd =
        expectedLifetimeType === ExpectedLifetimeTypeEnum.vestingPlusOneYear
          ? moment.min([
              moment(currentTranche.vestedDate, apiShortDate).add(1, "year"),
              expiryDateMoment,
            ])
          : expiryDateMoment;

      const expectedLifetime = changePunctuationForComma(
        getDateDiff(lifetimeEnd, lifetimeStart, "years").toString()
      );
      return accu.concat({
        groupIndex: accu.length,
        isActive: true,
        forActionDate: moment(currentTranche.grantDate).format(
          normalDateFormat
        ),
        grantDate: currentTranche.grantDate,
        vestedDate: currentTranche.vestedDate,
        expiryDate: expiryDate,
        strike: currentTranche.strike,
        interestRates: null,
        tranches: [currentTranche],
        sharePriceObj: null,
        volatilityPeriod: dateIntervalForVolatiltiy(
          lifetimeStart,
          toNumber(expectedLifetime)
        ),
        transactionKeysAndValues: {
          sharePrice: "",
          expectedLifetime,
          strike: changePunctuationForComma(currentTranche.strike),
          volatility: "0,5",
          interestRate: "",
          fairValue: "",
          dividend: "0",
          comment: "",
          valuationMethod: "Black-Scholes-Merton",
        } as TransactionKeysAndValues,
      });
    }
  }, []);
};

export enum MngTypeEnum {
  // ManagementTypeEnum
  fairValueGrantForCost = "fairValueGrantForCost",
  fairValueAdjustmentForCost = "fairValueAdjustmentForCost",
  fairValueGrantForSocSec = "fairValueGrantForSocSec",
  fairValueAdjustmentForSocSec = "fairValueAdjustmentForSocSec",
}

export type ManagementType =
  | "fairValueGrantForCost"
  | "fairValueAdjustmentForCost"
  | "fairValueGrantForSocSec"
  | "fairValueAdjustmentForSocSec";

interface ManagementTypesOption {
  key: ManagementType;
  value: ManagementType;
  text: string;
}

export const managementTypesOptions: ManagementTypesOption[] = [
  {
    key: "fairValueGrantForCost",
    value: "fairValueGrantForCost",
    text: "Fair Value Grant for Cost",
  },
  {
    key: "fairValueAdjustmentForCost",
    value: "fairValueAdjustmentForCost",
    text: "Fair Value Adjustment for Cost",
  },
  {
    key: "fairValueGrantForSocSec",
    value: "fairValueGrantForSocSec",
    text: "Fair Value Grant for Soc Sec",
  },
  {
    key: "fairValueAdjustmentForSocSec",
    value: "fairValueAdjustmentForSocSec",
    text: "Fair Value Adjustment for Soc Sec",
  },
];
export enum ExpectedLifetimeTypeEnum {
  expiry = "expiry",
  vestingPlusOneYear = "vestingPlusOneYear",
}
export type ExpectedLifetimeType = "expiry" | "vestingPlusOneYear";

interface ExpectedLifetimeTypeOption {
  key: ExpectedLifetimeType;
  value: ExpectedLifetimeType;
  text: string;
}

export const expectedLifetimeTypes: ExpectedLifetimeTypeOption[] = [
  {
    key: "expiry",
    value: "expiry",
    text: "Use Expiry date",
  },
  {
    key: "vestingPlusOneYear",
    value: "vestingPlusOneYear",
    text: "Use vesting + 1 year (or expiry if it's before)",
  },
];

export const isFairValueValid = fairValue => {
  if (fairValue === 0 || fairValue === "0") {
    return true;
  } else {
    return Boolean(
      typeof fairValue === "string"
        ? Number(changeCommaForPunctuation(fairValue))
        : fairValue
    );
  }
};

export const generateFairValueGrantForCostTransactions = (
  includedGroups: TrancheGroup[],
  apiShortDate: string
): Api.V1.Transaction[] =>
  includedGroups.reduce((acc, group) => {
    const {
      fairValue,
      sharePrice,
      dividend,
      expectedLifetime,
      strike,
      interestRate,
      volatility,
      comment,
      valuationMethod,
    } = group.transactionKeysAndValues;
    const grants = extractGrants(group.tranches, apiShortDate);
    const transactions = grants.map(grant => ({
      id: grant.id,
      transactionType: TransactionType.GRANT,
      fairValue: changeCommaForPunctuation(fairValue),
      fvSharePriceAtGrant: changeCommaForPunctuation(sharePrice),
      fvDividend: changeCommaForPunctuation(dividend),
      fvExpectedLifetime: changeCommaForPunctuation(expectedLifetime),
      fvStrikePrice: changeCommaForPunctuation(strike),
      fvInterestRate: changeCommaForPunctuation(interestRate),
      fvVolatility: changeCommaForPunctuation(volatility),
      fvValuationMethod: valuationMethod,
      comment,
    }));
    return [...acc, ...transactions];
  }, []);

export const generateFairValueAdjustmentForCostTransactions = (
  includedGroups: TrancheGroup[],
  transactionDate: string,
  normalDateFormat: string
): Array<{ trancheId: string; transaction: Api.V1.Transaction }> =>
  includedGroups.reduce((acc, group) => {
    const {
      fairValue,
      sharePrice,
      dividend,
      expectedLifetime,
      strike,
      interestRate,
      volatility,
      comment,
      valuationMethod,
    } = group.transactionKeysAndValues;

    const transactions = group.tranches.map(tranche => ({
      trancheId: tranche.id,
      transaction: {
        type: "transactions",
        transactionType: TransactionType.ADJUSTMENT_FAIR_VALUE,
        transactionDate: prepareDateForBackend(
          transactionDate,
          normalDateFormat
        ),
        fairValue: changeCommaForPunctuation(fairValue),
        fvSharePriceAtGrant: changeCommaForPunctuation(sharePrice),
        fvDividend: changeCommaForPunctuation(dividend),
        fvExpectedLifetime: changeCommaForPunctuation(expectedLifetime),
        fvStrikePrice: changeCommaForPunctuation(strike),
        fvInterestRate: changeCommaForPunctuation(interestRate),
        fvVolatility: changeCommaForPunctuation(volatility),
        fvValuationMethod: valuationMethod,
        comment,
      },
    }));
    return [...acc, ...transactions];
  }, []);

export const generateFairValueGrantForSocSecTransactions = (
  includedGroups: TrancheGroup[],
  apiShortDate: string
): Api.V1.Transaction[] =>
  includedGroups.reduce((acc, group) => {
    const {
      fairValue,
      sharePrice,
      dividend,
      expectedLifetime,
      strike,
      interestRate,
      volatility,
      comment,
      valuationMethod,
    } = group.transactionKeysAndValues;
    const grants = extractGrants(group.tranches, apiShortDate);
    const transactions = grants.map(grant => ({
      id: grant.id,
      transactionType: TransactionType.GRANT,
      fairValueForSocSec: changeCommaForPunctuation(fairValue),
      fvssSharePriceAtGrant: changeCommaForPunctuation(sharePrice),
      fvssDividend: changeCommaForPunctuation(dividend),
      fvssExpectedLifetime: changeCommaForPunctuation(expectedLifetime),
      fvssStrikePrice: changeCommaForPunctuation(strike),
      fvssInterestRate: changeCommaForPunctuation(interestRate),
      fvssVolatility: changeCommaForPunctuation(volatility),
      fvssValuationMethod: valuationMethod,
      comment,
    }));
    return [...acc, ...transactions];
  }, []);

export const generateFairValueAdjustmentForSocSecTransactions = (
  includedGroups: TrancheGroup[],
  transactionDate: string,
  normalDateFormat: string
): Array<{ trancheId: string; transaction: Api.V1.Transaction }> =>
  includedGroups.reduce((acc, group) => {
    const {
      fairValue,
      sharePrice,
      dividend,
      expectedLifetime,
      strike,
      interestRate,
      volatility,
      comment,
      valuationMethod,
    } = group.transactionKeysAndValues;

    const transactions = group.tranches.map(tranche => ({
      trancheId: tranche.id,
      transaction: {
        type: "transactions",
        transactionType: TransactionType.ADJUSTMENT_FVSS,
        transactionDate: prepareDateForBackend(
          transactionDate,
          normalDateFormat
        ),
        fairValueForSocSec: changeCommaForPunctuation(fairValue),
        fvssSharePriceAtGrant: changeCommaForPunctuation(sharePrice),
        fvssDividend: changeCommaForPunctuation(dividend),
        fvssExpectedLifetime: changeCommaForPunctuation(expectedLifetime),
        fvssStrikePrice: changeCommaForPunctuation(strike),
        fvssInterestRate: changeCommaForPunctuation(interestRate),
        fvssVolatility: changeCommaForPunctuation(volatility),
        fvssValuationMethod: valuationMethod,
        comment,
      },
    }));
    return [...acc, ...transactions];
  }, []);

export const updateGroupedTranchesWithFairValues = (
  groupedTranches: TrancheGroup[],
  sharePrices: { [key: string]: Api.V1.SharePrice[] },
  setGroupedTranches: (groupedTranches: TrancheGroup[]) => void
) => {
  const uniqueDates: string[] = Array.from(
    new Set(groupedTranches.map(group => group.forActionDate))
  );
  const sharePricesKeys = Object.keys(sharePrices);
  if (uniqueDates.length === sharePricesKeys.length) {
    const sharePriceObj = sharePrices[sharePricesKeys[0]][0];
    if (sharePricesKeys.length === 1) {
      setGroupedTranches(
        groupedTranches.map(group => ({
          ...group,
          sharePriceObj: sharePriceObj || null,
          transactionKeysAndValues: {
            ...group.transactionKeysAndValues,
            sharePrice: sharePriceObj
              ? changePunctuationForComma(sharePriceObj.price)
              : "",
          },
        }))
      );
    } else if (sharePricesKeys.length > 1) {
      setGroupedTranches(
        groupedTranches.map(group => {
          const matchingSharePriceKey = sharePricesKeys.find(key =>
            key.startsWith(group.grantDate)
          );
          const sharePriceObj = sharePrices[matchingSharePriceKey][0];

          return {
            ...group,
            sharePriceObj: sharePriceObj || null,
            transactionKeysAndValues: {
              ...group.transactionKeysAndValues,
              sharePrice: sharePriceObj
                ? changePunctuationForComma(sharePriceObj.price)
                : "",
            },
          };
        })
      );
    }
  }
};

export const findAvailableIR = (
  interestRatesAll: InterestRates[],
  earlierAvailableIRDate: Moment,
  valuationDate: string,
  normalDateFormat: string
): InterestRates => {
  let availableIR = null;
  let dateToCheckAgainst: string = moment(
    valuationDate,
    normalDateFormat
  ).format(apiShortDate);

  while (
    !availableIR &&
    earlierAvailableIRDate.isSameOrBefore(
      moment(dateToCheckAgainst, apiShortDate)
    )
  ) {
    availableIR = interestRatesAll.find(ir =>
      ir.date.isSame(moment(dateToCheckAgainst, apiShortDate))
    );
    dateToCheckAgainst = moment(dateToCheckAgainst, apiShortDate)
      .subtract(1, "days")
      .format(apiShortDate);
  }
  return availableIR;
};

export type UpdateGroupedTranchesWithInterestRates = (
  interestRatesAll: InterestRates[],
  normalDateFormat: string,
  groupedTranches: TrancheGroup[],
  setGroupedTranches: (groupedTranches: TrancheGroup[]) => void
) => void;

export const updateGroupedTranchesWithInterestRates: UpdateGroupedTranchesWithInterestRates = (
  interestRatesAll,
  normalDateFormat,
  groupedTranches,
  setGroupedTranches
) => {
  const uniqueDates: string[] = Array.from(
    new Set(groupedTranches.map(group => group.forActionDate))
  );
  const earlierAvailableIRDate = interestRatesAll[0].date;
  if (uniqueDates.length === 1) {
    const interestRates = findAvailableIR(
      interestRatesAll,
      earlierAvailableIRDate,
      uniqueDates[0],
      normalDateFormat
    );
    setGroupedTranches(
      groupedTranches.map(group => ({
        ...group,
        interestRates,
        transactionKeysAndValues: {
          ...group.transactionKeysAndValues,
          interestRate: changePunctuationForComma(
            calcInterestRate(
              interestRates,
              group.transactionKeysAndValues.expectedLifetime
            ).toString()
          ),
        },
      }))
    );
  } else if (uniqueDates.length > 1) {
    setGroupedTranches(
      groupedTranches.map(group => {
        const interestRates = findAvailableIR(
          interestRatesAll,
          earlierAvailableIRDate,
          group.forActionDate,
          normalDateFormat
        );

        return {
          ...group,
          interestRates,
          transactionKeysAndValues: {
            ...group.transactionKeysAndValues,
            interestRate: changePunctuationForComma(
              calcInterestRate(
                interestRates,
                group.transactionKeysAndValues.expectedLifetime
              ).toString()
            ),
          },
        };
      })
    );
  }
};

const interpolateInterestRate = (
  elt: number,
  lowerLifetime: number,
  lowerLifetimeVal: number,
  higherLifetime: number,
  higherLifetimeVal: number
) =>
  (lowerLifetimeVal +
    ((elt - lowerLifetime) / (higherLifetime - lowerLifetime)) *
      (higherLifetimeVal - lowerLifetimeVal)) /
  100;

const findLower = (sortedInterestRates: number[], lifetime: number) => {
  return (
    [...sortedInterestRates].reverse().find(ir => ir <= lifetime) ||
    sortedInterestRates[0] ||
    null
  );
};

const findHigher = (sortedInterestRates: number[], lifetime: number) => {
  return (
    sortedInterestRates.find(ir => ir >= lifetime) ||
    sortedInterestRates[sortedInterestRates.length - 1] ||
    null
  );
};

export const calcInterestRate = (
  interestRate: InterestRates,
  expectedLifetime: string
) => {
  if (!interestRate) {
    return "";
  }
  const elt = Number(changeCommaForPunctuation(expectedLifetime));
  const sortedIRs = Object.keys(interestRate.rates)
    .map(year => Number(year))
    .sort((a, b) => {
      if (a === b) {
        return 0;
      } else if (a > b) {
        return 1;
      } else {
        return -1;
      }
    });

  const lower = findLower(sortedIRs, elt);
  const higher = findHigher(sortedIRs, elt);
  return lower === higher
    ? interestRate.rates[lower] / 100
    : interpolateInterestRate(
        elt,
        lower,
        interestRate.rates[lower],
        higher,
        interestRate.rates[higher]
      );
};

export const dateIntervalForVolatiltiy = (
  endDate: string | Moment,
  lifetimeInYears: number
) => {
  const startDate = moment(endDate).subtract(
    Math.ceil(lifetimeInYears * 365.25 * 24 * 3600),
    "seconds"
  ); // 365,25 is what we normally use when calculating days in a year in Excel

  return {
    startDate: startDate.add(1, "day").format(apiShortDate),
    endDate: moment(endDate).format(apiShortDate),
  };
};

export const getGroupAnnualVolatilityState = (
  group: TrancheGroup,
  globalVolatilityState: GlobalAnnualVolatilityState
) => {
  const { startDate, endDate } = group.volatilityPeriod;
  const dates = [startDate, endDate].join("-");
  return globalVolatilityState[dates];
};

export const updateFairValueForGroups = (
  groups: TrancheGroup[]
): TrancheGroup[] =>
  groups.map(g => ({
    ...g,
    transactionKeysAndValues: {
      ...g.transactionKeysAndValues,
      fairValue: calcFairValueForGroup(g),
    },
  }));

export const calcFairValueForGroup = (group: TrancheGroup) => {
  const { transactionKeysAndValues } = group;
  const fairValue = blackScholesExcel(
    toNumber(transactionKeysAndValues.expectedLifetime),
    toNumber(transactionKeysAndValues.sharePrice),
    toNumber(transactionKeysAndValues.strike),
    toNumber(transactionKeysAndValues.interestRate),
    toNumber(transactionKeysAndValues.volatility),
    toNumber(transactionKeysAndValues.dividend)
  );
  return isNaN(fairValue)
    ? ""
    : changePunctuationForComma(
        toFixedAndRemoveScientificNotation(fairValue).toString()
      );
};
