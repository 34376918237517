import React, { FunctionComponent } from "react";
import { Icon } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import "src/employee-portal/documents/accept-documents/document-metadata-preview.less";

interface Props {
  downloadClicked: () => void;
  fileName: string;
  id: string;
  downloadLink: string;
}

const DocumentMetadataPreview: FunctionComponent<Props> = d => (
  <div key={d.id} className="block-m text-center margin-top-m">
    <a
      href={d.downloadLink}
      target="_blank"
      onClick={d.downloadClicked}
      className="document-metadata-big-link"
    >
      <div className="block-s">
        <Icon name={"file pdf outline"} size={"huge"} />
      </div>
      <h4 className="block-s">{d.fileName}</h4>
      <span className="block-s text-info">
        <Content id="global.downloadFileTxt" />
      </span>
    </a>
  </div>
);

export default DocumentMetadataPreview;
