import React, { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { Form, Message } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { formatMessageWithDebugger } from "src/admin-portal/texts/utils";
import CurrencyFormatter, {
  CurrencyFormatterProps,
} from "src/common/components/currency/currency-formatter";
import { Window } from "src/common/data/data";
import { TextTerm } from "src/common/utils/text-mappings";
import { ExerciseNavigationButtons } from "src/employee-portal/exercise/common/exercise-navigation-buttons";
import { totalStrike } from "src/employee-portal/exercise/exercise-utils";
import ExerciseQuantityTable from "src/employee-portal/exercise/quantity/exercise-quantity-table";
import {
  FlatAward,
  hasCapOnGain,
  hasVariableCost,
} from "src/employee-portal/instrument-page/instruments-reducer";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface Props {
  window: Window;
  match: any;
  quantities: string[];
  quantityForPlan: (quantities: string[]) => void;
  sharePrice: number;
  exercisibleAwards: FlatAward[];
  instrumentTerm: TextTerm;
  goBack: () => void;
  goForward: () => void;
  nextPath: string;
  dateFormat: DateFormatState;
}

interface State {
  vestedOptions: FlatAward[];
  quantities: string[];
  quantitiesValid: boolean[];
}

class Exercise extends Component<Props & InjectedIntlProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      vestedOptions: this.props.exercisibleAwards,
      quantities: this.props.quantities,
      quantitiesValid: this.props.quantities.map(() => true),
    };
  }

  public render() {
    const { sharePrice } = this.props;
    const totalSharesValue = this.state.quantities.reduce((accu, q, i) => {
      const award = this.state.vestedOptions[i];
      const cappedSharePrice = award.capOnGain
        ? Math.min(award.capOnGain + award.strike, sharePrice)
        : sharePrice;
      const sharesAmount = Math.floor(
        (parseInt(q, 10) || 0) * award.sharesConversionFactor
      );
      const totalVariableCost =
        ((sharePrice - award.strike) * award.socSecRate) /
        (1 + award.socSecRate);

      return award.employeePaysSocSec
        ? accu + sharesAmount * (cappedSharePrice - totalVariableCost)
        : accu + sharesAmount * cappedSharePrice;
    }, 0);

    const totalCostOfExercise = this.state.quantities.reduce((accu, q, i) => {
      const award = this.state.vestedOptions[i];
      const quantity = parseInt(q, 10) || 0;
      return accu + totalStrike(quantity, award);
    }, 0);

    const estimatedGainSum = Math.max(
      totalSharesValue - totalCostOfExercise,
      0
    );

    const showCapOnGain = this.state.vestedOptions.some(hasCapOnGain);
    const showVariableCost = this.state.vestedOptions.some(hasVariableCost);
    const {
      instrumentTerm,
      intl: { formatMessage },
    } = this.props;

    const showError: boolean = this.hasErrors() || estimatedGainSum === 0;

    return (
      <div>
        <div className="block-l">
          <h2 className="text-center block-m">
            <Content
              id="exercise.quantity.title"
              values={{ instrumentTerm: instrumentTerm.plural }}
            />
          </h2>
          <p className="text-content text-content-center block-m">
            <CurrencyFormatter>
              {(formatter: CurrencyFormatterProps) => (
                <Content
                  id="exercise.quantity.body"
                  values={{
                    sharePrice: formatter.formatSharePrice(
                      this.props.sharePrice
                    ),
                  }}
                />
              )}
            </CurrencyFormatter>
          </p>
          {showError && (
            <div className="text-content-center block-m">
              <Message
                header={formatMessageWithDebugger(
                  "exercise.quantity.errMessageHeader",
                  formatMessage
                )}
                error={true}
                content={formatMessageWithDebugger(
                  this.hasErrors()
                    ? "exercise.quantity.errMessageContent"
                    : "exercise.quantity.errMessageTotalBlankContent",
                  formatMessage
                )}
              />
            </div>
          )}
          <CurrencyFormatter>
            {(formatter: CurrencyFormatterProps) => (
              <Form>
                <div className="col-center">
                  <ExerciseQuantityTable
                    quantityChangedHandler={this.quantityChanged}
                    formatter={formatter}
                    estimatedGainSum={estimatedGainSum}
                    quantities={this.state.quantities}
                    quantitiesValid={this.state.quantitiesValid}
                    vestedOptions={this.state.vestedOptions}
                    dateFormat={this.props.dateFormat}
                    sharePrice={this.props.sharePrice}
                    showCapOnGain={showCapOnGain}
                    showVariableCost={showVariableCost}
                  />
                </div>
              </Form>
            )}
          </CurrencyFormatter>
        </div>
        <ExerciseNavigationButtons
          forwardDisabled={showError}
          goBack={this.props.goBack}
          goForward={this.props.goForward}
        />
      </div>
    );
  }

  private hasErrors = () =>
    this.state.quantitiesValid.some(isValid => !isValid);

  private quantityChanged = (index, event) => {
    const newQuantities = this.state.quantities.map((q, i) =>
      i === index ? event.target.value.replace(/\D/g, "") : q
    );
    this.props.quantityForPlan(newQuantities);
    const newQuantitiesValid = this.state.quantitiesValid.map(
      (valid, i) =>
        typeof parseInt(newQuantities[i] || 0, 10) === "number" &&
        parseInt(newQuantities[i] || 0, 10) <=
          (this.state.vestedOptions[i].originalQuantity ||
            this.state.vestedOptions[i].quantity)
    );
    this.setState({
      quantities: newQuantities,
      quantitiesValid: newQuantitiesValid,
    });
  };
}

export default injectIntl<Props>(Exercise);
