import moment from "moment";
import React, { FunctionComponent } from "react";
import { Card, Checkbox, Message } from "semantic-ui-react";

import FairValueManagementForm, {
  Props as FairValueManagementFormProps,
} from "src/admin-portal/tranches-page/actions/fair-value-management/fair-value-management-form";
import { TrancheGroup } from "src/admin-portal/tranches-page/actions/fair-value-management/utils";
import { blackScholesExcel } from "src/common/data/black-scholes";
import {
  changeCommaForPunctuation,
  changePunctuationForComma,
  toFixedAndRemoveScientificNotation,
  toNumber,
} from "src/common/utils/utils";
import { AnnualVolatilityState } from "src/reducers/share-price-reducer";

type FairValueManagementFormPickedProps = Pick<
  FairValueManagementFormProps,
  "updateGroup" | "updateGroupInterestRate"
>;
interface PassedProps {
  group: TrancheGroup;
  normalDateFormat: string;
  isGrantTypeActive: boolean;
  annualVolatilityState?: AnnualVolatilityState;
  fetchAnnualVolatility: (params: Api.V1.AnnualVolatilityQueryParams) => void;
}

type Props = FairValueManagementFormPickedProps & PassedProps;

const FairValueManangementGroup: FunctionComponent<Props> = ({
  group,
  normalDateFormat,
  isGrantTypeActive,
  updateGroup,
  fetchAnnualVolatility,
  updateGroupInterestRate,
  annualVolatilityState,
}) => {
  const calcFairValue = transactionKeysAndValues => {
    const fairValue = blackScholesExcel(
      toNumber(transactionKeysAndValues.expectedLifetime),
      toNumber(transactionKeysAndValues.sharePrice),
      toNumber(transactionKeysAndValues.strike),
      toNumber(transactionKeysAndValues.interestRate),
      toNumber(transactionKeysAndValues.volatility),
      toNumber(transactionKeysAndValues.dividend)
    );

    updateGroup(group.groupIndex, "transactionKeysAndValues", {
      ...group.transactionKeysAndValues,
      fairValue: isNaN(fairValue)
        ? ""
        : changePunctuationForComma(
            toFixedAndRemoveScientificNotation(fairValue).toString()
          ),
    });
  };

  const haveSomeFairValues = group.tranches.some(t => Boolean(t.fairValue));
  const fetchAnnualVolatilityForGroup = () => {
    const { startDate, endDate } = group.volatilityPeriod;
    fetchAnnualVolatility({
      startDate,
      endDate,
      timePeriodInYears: toNumber(
        group.transactionKeysAndValues.expectedLifetime
      ),
    });
  };

  return (
    <Card fluid={true}>
      <Card.Header className="bg-disabled text-off-white header-padding">
        <div className="flex-row space-between align-center">
          <Checkbox
            toggle={true}
            checked={group.isActive}
            onChange={() =>
              updateGroup(group.groupIndex, "isActive", !group.isActive)
            }
          />
          <span>Grant: {moment(group.grantDate).format(normalDateFormat)}</span>
          <span>
            Vested: {moment(group.vestedDate).format(normalDateFormat)}
          </span>
          <span>
            Expiry: {moment(group.expiryDate).format(normalDateFormat)}
          </span>
          <span>Strike: {group.strike}</span>
          <span>Tranches: {group.tranches.length}</span>
        </div>
      </Card.Header>
      <Card.Content>
        {haveSomeFairValues && isGrantTypeActive && (
          <Message
            negative={true}
            header="This group contains some tranches which have fairValue(s) already set."
          />
        )}

        <FairValueManagementForm
          onSubmit={e => calcFairValue(group.transactionKeysAndValues)}
          group={group}
          transactionKeysAndValues={group.transactionKeysAndValues}
          updateGroup={updateGroup}
          normalDateFormat={normalDateFormat}
          fetchAnnualVolatilityForGroup={fetchAnnualVolatilityForGroup}
          updateGroupInterestRate={updateGroupInterestRate}
          annualVolatilityState={annualVolatilityState}
        />
      </Card.Content>
    </Card>
  );
};

export default FairValueManangementGroup;
