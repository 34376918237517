import React, { FunctionComponent } from "react";
import { Button } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import CurrencyFormatter, {
  CurrencyFormatterProps,
} from "src/common/components/currency/currency-formatter";
import HelpPopup from "src/common/components/help-popup";
import If from "src/common/components/if";
import {
  InstrumentType,
  instrumentTypeText,
} from "src/common/utils/text-mappings";
import { formatNumber, formatPercentage } from "src/common/utils/utils";
import { PurchaseType } from "src/constants";
import { PurchaseInfo } from "src/employee-portal/purchase/duck/purchase-selectors";
import { PaymentInfo } from "src/employee-portal/purchase/purchase-router";
import dateFormat, { DateFormatState } from "src/reducers/date-format-reducer";

interface Props {
  placeOrder: () => void;
  goBack: () => void;
  isPlacingOrder: boolean;
  quantity: number;
  cashAmount: number;
  pricePerInstrument: number;
  paymentInfo: PaymentInfo;
  purchaseInfo: PurchaseInfo;
  instrument: InstrumentType;
  estMarketValue?: number;
  estNumInstruments?: number;
  estDiscount?: number;
  discountRate?: number;
  loan?: number;
  loanPerPeriod?: number;
  dateFormat: DateFormatState;
  investmentCurrencyCode: string;
}

const ConfirmPurchase: FunctionComponent<Props> = ({
  goBack,
  placeOrder,
  isPlacingOrder,
  quantity,
  pricePerInstrument,
  paymentInfo,
  purchaseInfo,
  instrument,
  cashAmount,
  estMarketValue,
  estNumInstruments,
  estDiscount,
  discountRate,
  dateFormat,
  loan,
  loanPerPeriod,
  investmentCurrencyCode,
}) => {
  const formatMessage = useFormatMessage();
  const instrumentTerm = instrumentTypeText(instrument, formatMessage);
  const paymentPrice =
    purchaseInfo.type !== PurchaseType.PURCHASE_OPPORTUNITY_CASH
      ? pricePerInstrument * quantity
      : cashAmount;
  const hasLoan = typeof loan === "number";
  const hasLoanTerms = typeof loanPerPeriod === "number";
  const toPay = paymentPrice - (loan || 0);
  return (
    <CurrencyFormatter code={investmentCurrencyCode}>
      {(formatter: CurrencyFormatterProps) => (
        <div>
          <div className="block-l">
            <h2 className="text-center block-m">
              <Content id="purchase.confirm.header" />
            </h2>
            <div className="block-m">
              <p className="purchase-narrow-width margin-center block-s">
                {purchaseInfo.type ===
                PurchaseType.PURCHASE_OPPORTUNITY_CASH ? (
                  <Content
                    id="purchase.cash.confirm.body.1"
                    values={{
                      purchaseCashAmount: formatter.formatCurrency(
                        cashAmount,
                        2
                      ),
                      instrumentTermPlural: instrumentTerm.plural,
                      instrumentTermSingular: instrumentTerm.singular,
                    }}
                  />
                ) : (
                  <Content
                    id="purchase.confirm.body.1"
                    values={{
                      pricePerInstrument: formatter.formatCurrency(
                        pricePerInstrument,
                        2
                      ),
                      numberOfInstruments: formatNumber(quantity),
                      totalAmount: formatter.formatCurrency(paymentPrice, 2),
                      instrumentTermPlural: instrumentTerm.plural,
                      instrumentTermSingular: instrumentTerm.singular,
                    }}
                  />
                )}
              </p>
              {purchaseInfo.type !== PurchaseType.PURCHASE_OPPORTUNITY_CASH && (
                <p className="purchase-narrow-width margin-center">
                  <Content
                    id="purchase.confirm.body.2"
                    values={{
                      paymentDeadline: paymentInfo.paymentDeadline
                        ? paymentInfo.paymentDeadline.format(
                            dateFormat.normalDateFormat
                          )
                        : undefined,
                      pricePerInstrument: formatter.formatCurrency(
                        pricePerInstrument,
                        2
                      ),
                      numberOfInstruments: formatNumber(quantity),
                      totalAmount: formatter.formatCurrency(paymentPrice, 2),
                      instrumentTermPlural: instrumentTerm.plural,
                      instrumentTermSingular: instrumentTerm.singular,
                    }}
                  />
                </p>
              )}
            </div>

            <div className="center-center">
              <div className="purchase-box">
                <div className="purchase-box-section">
                  <div className="purchase-box-section-item">
                    <div>
                      <strong>
                        <Content
                          id={"purchase.quantity.totalPurchasePrice"}
                          values={{
                            instrumentTermPlural: instrumentTerm.plural,
                          }}
                        />
                      </strong>
                    </div>
                    <strong>{formatter.formatCurrency(toPay, 0)}</strong>
                  </div>
                  <If condition={!!discountRate}>
                    <div className="block-xxs" />
                    <div className="purchase-box-section-item">
                      <div>
                        <Content id={"purchase.cash.marketValue.label"} />
                        <HelpPopup
                          textKey="purchase.cash.marketValue.popup"
                          values={{
                            instrumentTermPlural: instrumentTerm.plural,
                          }}
                        />
                      </div>
                      <span>
                        {`≈ ${formatter.formatCurrency(
                          Math.floor(estMarketValue),
                          0
                        )}`}
                      </span>
                    </div>
                  </If>
                </div>
                <div className="divider" />
                <div className="purchase-box-section">
                  {purchaseInfo.type ===
                  PurchaseType.PURCHASE_OPPORTUNITY_CASH ? (
                    <Content
                      id={"purchase.cash.confirm.content"}
                      values={{
                        purchaseCashAmount: formatter.formatCurrency(
                          cashAmount,
                          0
                        ),
                        estDiscount: formatter.formatCurrency(estDiscount, 0),
                        discountPercent: formatPercentage(discountRate),
                        estNumInstruments: formatNumber(estNumInstruments),
                        estMarketValue: formatter.formatCurrency(
                          estMarketValue,
                          0
                        ),
                        instrumentTermPlural: instrumentTerm.plural,
                        instrumentTermSingular: instrumentTerm.singular,
                        paymentDeadline: paymentInfo.paymentDeadline.format(
                          dateFormat.normalDateFormat
                        ),
                      }}
                    />
                  ) : (
                    <Content
                      id={"purchase.quantity.confirm.content"}
                      values={{
                        pricePerInstrument: formatter.formatCurrency(
                          pricePerInstrument,
                          2
                        ),
                        numberOfInstruments: formatNumber(quantity),
                        totalPrice: formatter.formatCurrency(paymentPrice, 2),
                        instrumentTermPlural: instrumentTerm.plural,
                        instrumentTermSingular: instrumentTerm.singular,
                        paymentDeadline: paymentInfo.paymentDeadline.format(
                          dateFormat.normalDateFormat
                        ),
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="page-action-container text-center">
            <Button
              content={formatMessage({ id: "global.backBtnTxt" })}
              size="big"
              onClick={goBack}
            />
            <Button
              positive={true}
              content={<Content id={"purchase.confirm.btnText"} />}
              icon="right arrow"
              labelPosition="right"
              size="big"
              onClick={placeOrder}
            />
          </div>
        </div>
      )}
    </CurrencyFormatter>
  );
};

export default ConfirmPurchase;
