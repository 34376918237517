import * as Yup from "yup";

const validateSchema = Yup.object().shape({
  bankAccountNumber: Yup.string().nullable(),
  bankBICNumber: Yup.string().nullable(),
  shareDepositoryAccount: Yup.string().nullable(),
  shareDepositoryBank: Yup.string().nullable(),
  shareDepositoryClearingCode: Yup.string().nullable(),
  shareDepositoryContact: Yup.string().nullable(),
  shareDepositoryDescription: Yup.string().nullable(),
  shareDepositoryNorwegian: Yup.string().nullable(),
});

const useValidationSchema = () => validateSchema;

export type FormValues = Yup.InferType<typeof validateSchema>;
export default useValidationSchema;
