import { pick, mapValues } from "lodash";
import { useSelector } from "react-redux";

import { Window } from "src/common/data/data";
import {
  canExerciseCurrentWindow,
  currentExerciseWindow,
} from "src/employee-portal/employee-portal-selectors";
import { Features } from "src/employee-portal/features/features-reducer";
import {
  calculateGains,
  calculateTotalGain,
  IndividualInstrumentState,
} from "src/employee-portal/instrument-page/instruments-reducer";

type UseSimulatedInstruments = (
  sharePrice: number
) => {
  simulatedInstruments: { [key: string]: IndividualInstrumentState };
  totalGain: number;
  features: Features;
  nextWindow: Window;
  currentWindow: Window;
  canExerciseCurrentWindow: boolean;
};

const useSimulatedInstruments: UseSimulatedInstruments = sharePrice => {
  const isCanExercise = useSelector(canExerciseCurrentWindow);
  const nextWindow = useSelector(({ user }) => user.nextExerciseWindow);
  const currentWindow = useSelector(currentExerciseWindow);
  const features = useSelector(({ features }) => features);
  const instruments = useSelector(({ instrument }) =>
    pick(instrument, [
      "option",
      "warrant",
      "psu",
      "rsu",
      "rsa",
      "subscriptionRightState",
      "convertibleLoan",
      "share",
    ])
  );

  const simulatedInstruments = mapValues(instruments, instrument => ({
    ...instrument,
    gain: calculateGains(instrument.allAwards, sharePrice),
  }));

  const totalGain = calculateTotalGain(
    Object.values(simulatedInstruments),
    instruments.psu.performanceAdjustedGain
  );

  return {
    simulatedInstruments,
    totalGain,
    features,
    nextWindow,
    currentWindow,
    canExerciseCurrentWindow: isCanExercise,
  };
};

export default useSimulatedInstruments;
