import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Icon, Grid, Message } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import CurrencyConversionView from "src/common/components/currency/currency-conversion-view";
import Slider from "src/common/components/Slider";
import "src/employee-portal/SharePriceSimulation/style.less";
import OverviewSections from "src/employee-portal/home-page/OverviewSections";
import useSimulatedInstruments from "src/employee-portal/SharePriceSimulation/useSimulatedInstruments";
import useSimulatedSharePrice from "src/employee-portal/SharePriceSimulation/useSimulatedSharePrice";

interface Props {
  goBack: () => void;
}

const SharePriceSimulationModal: FunctionComponent<Props> = ({ goBack }) => {
  const currentSharePrice = useSelector(
    ({ instrument }) => instrument.sharePrice.sharePrice
  );

  const [
    simulateSharePrice,
    { simulatedSharePrice, label, labelColor, labelIcon },
  ] = useSimulatedSharePrice(currentSharePrice);

  const {
    simulatedInstruments,
    totalGain,
    features,
    nextWindow,
    currentWindow,
    canExerciseCurrentWindow,
  } = useSimulatedInstruments(simulatedSharePrice);

  const buttonRates = {
    Reset: 1,
    "+10%": 1.1,
    "+25%": 1.25,
    "+50%": 1.5,
    "2x": 2,
    "5x": 5,
  };

  const formatMessage = useFormatMessage();

  return (
    <Modal
      open={true}
      onClose={goBack}
      closeIcon={true}
      closeOnDimmerClick={true}
      className="share-price-simulation-modal"
    >
      <Modal.Content>
        <Grid>
          <Grid.Row className="share-price-header" columns={2}>
            <Grid.Column>
              <div className="current-share-price">
                <CurrencyConversionView
                  value={simulatedSharePrice}
                  decimals={2}
                  showOverrideCurrencyOnly={true}
                />
              </div>
            </Grid.Column>

            <Grid.Column>
              <span className={`rate-label ${labelColor}`}>
                {labelIcon !== null && <Icon name={labelIcon} />} {label}
              </span>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Slider
          min={0}
          max={currentSharePrice * 5}
          value={simulatedSharePrice}
          step={0.01}
          color={labelColor}
          rulerDivisions={6}
          onChange={value => simulateSharePrice(value)}
        />

        <div className="simulation-rate-buttons">
          {Object.keys(buttonRates).map(label => (
            <Button
              key={label}
              onClick={() =>
                simulateSharePrice(currentSharePrice * buttonRates[label])
              }
              content={label}
            />
          ))}
        </div>

        <div className="overview-sections-wrapper">
          <div className="simulation-share-price-total">
            <div className="simulation-share-price-total-label">
              {formatMessage({ id: "instruments.frontpagebox.title" })}
            </div>
            <div className="simulation-share-price-total-value">
              <CurrencyConversionView
                value={totalGain}
                decimals={0}
                showOverrideCurrencyOnly={true}
              />
            </div>
          </div>

          <OverviewSections
            canExerciseCurrentWindow={canExerciseCurrentWindow}
            features={features}
            convertibleLoan={simulatedInstruments.convertibleLoan}
            share={simulatedInstruments.share}
            option={simulatedInstruments.option}
            warrant={simulatedInstruments.warrant}
            psu={simulatedInstruments.psu}
            rsu={simulatedInstruments.rsu}
            rsa={simulatedInstruments.rsa}
            subscriptionRights={simulatedInstruments.subscriptionRightState}
            nextWindow={nextWindow}
            currentWindow={currentWindow}
            hideNavigation={true}
          />
          <div className="block-s" />
          <div className="text-center" style={{ fontSize: "0.7rem" }}>
            <Content id="sharePriceSimulation.disclaimer" />
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default SharePriceSimulationModal;
