import { connectRouter, RouterState } from "connected-react-router";
import { combineReducers } from "redux";

import auditLogs, {
  AuditLogsState,
} from "src/admin-portal/audit-logs/audit-logs-reducer";
import award, { AwardState } from "src/admin-portal/awards/award-reducer";
import content, {
  ContentState,
} from "src/admin-portal/content/content-reducer";
import dividend, {
  DividendState,
} from "src/admin-portal/dividend/dividend-reducer";
import email, { EmailState } from "src/admin-portal/email/email-reducer";
import employee, {
  EmployeeState,
} from "src/admin-portal/employees/employee-reducer";
import entity, { EntityState } from "src/admin-portal/entity/entity-reducer";
import window, {
  WindowState,
} from "src/admin-portal/exercise-windows/window-reducer";
import importReducer, {
  ImportState,
} from "src/admin-portal/import/import-reducer";
import invoices, {
  InvoicesState,
} from "src/admin-portal/invoices/invoices-reducer";
import adminOrder, {
  OrderState as AdminOrderState,
} from "src/admin-portal/orders/ordersReducer";
import performanceRuleEntries, {
  PerformanceRuleEntriesState,
} from "src/admin-portal/performance-rules/performance-rule-entries-page/performance-rule-entries-reducer";
import performanceRules, {
  PerformanceRulesState,
} from "src/admin-portal/performance-rules/performance-rules-page/performance-rules-reducer";
import program, {
  ProgramState,
} from "src/admin-portal/programs/program-reducer";
import reportsConfigs, {
  ReportsConfigsState,
} from "src/admin-portal/reports/reports-config/reports-configs-reducer";
import subProgram, {
  SubProgramState,
} from "src/admin-portal/subprograms/subprogram-reducer";
import summary, {
  SummaryState,
} from "src/admin-portal/summary/summary-reducer";
import sysadmin, {
  SysadminState,
} from "src/admin-portal/sysadmin/sysadmin-reducer";
import tenant, { TenantState } from "src/admin-portal/tenant/tenant-reducer";
import employeePortalTexts, {
  TextState as EmployeePortalTextState,
} from "src/admin-portal/texts/employee-portal-texts-reducer";
import text, { TextState } from "src/admin-portal/texts/text-reducer";
import employeePortalProfile, {
  EmployeePortalProfileState,
} from "src/employee-portal/employee-portal-profile-reducer";
import features, {
  Features,
} from "src/employee-portal/features/features-reducer";
import instrument, {
  InstrumentState,
} from "src/employee-portal/instrument-page/instruments-reducer";
import purchase, {
  PurchaseState,
} from "src/employee-portal/purchase/duck/purchase-reducer";
import order, { OrderState } from "src/employee-portal/reducers/order";
import eventsTimeline, {
  TimelineEventsState,
} from "src/employee-portal/timeline/events-timeline-reducer";
import contract, { ContractState } from "src/reducers/contract-reducer";
import customRelationshipType, {
  CustomRelationshipTypeState,
} from "src/reducers/custom-relationship-types-reducer";
import dateFormat, { DateFormatState } from "src/reducers/date-format-reducer";
import { generalApiRequestReducer } from "src/reducers/general-api-request-reducer.ts";
import reports, { ReportsState } from "src/reducers/reports-reducer";
import sharePrice, { SharePriceState } from "src/reducers/share-price-reducer";
import user, { UserState } from "src/reducers/user";
import selfService, {
  SelfServiceState,
} from "src/self-service-portal/self-service-reducer";

export interface RootState {
  router: RouterState;
  user: UserState;
  sysadmin: SysadminState;
  tenant: TenantState;
  entity: EntityState;
  employee: EmployeeState;
  instrument: InstrumentState;
  order: OrderState;
  features: Features;
  program: ProgramState;
  subProgram: SubProgramState;
  award: AwardState;
  sharePrice: SharePriceState;
  window: WindowState;
  adminOrder: AdminOrderState;
  text: TextState;
  employeePortalTexts: EmployeePortalTextState;
  purchase: PurchaseState;
  content: ContentState;
  dividend: DividendState;
  import: ImportState;
  dateFormat: DateFormatState;
  employeePortalProfile: EmployeePortalProfileState;
  reports: ReportsState;
  reportsConfigs: ReportsConfigsState;
  performanceRules: PerformanceRulesState;
  performanceRuleEntries: PerformanceRuleEntriesState;
  email: EmailState;
  selfService: SelfServiceState;
  generalApiRequestFailed: boolean;
  auditLogs: AuditLogsState;
  eventsTimeline: TimelineEventsState;
  summary: SummaryState;
  invoices: InvoicesState;
  customRelationshipType: CustomRelationshipTypeState;
  contract: ContractState;
}

const rootReducer = history =>
  combineReducers<RootState>({
    router: connectRouter(history),
    generalApiRequestFailed: generalApiRequestReducer,
    content,
    user,
    sysadmin,
    tenant,
    entity,
    employee,
    instrument,
    eventsTimeline,
    order,
    features,
    program,
    subProgram,
    text,
    award,
    sharePrice,
    window,
    adminOrder,
    employeePortalTexts,
    import: importReducer,
    purchase,
    dividend,
    dateFormat,
    employeePortalProfile,
    reports,
    reportsConfigs,
    performanceRules,
    performanceRuleEntries,
    email,
    selfService,
    auditLogs,
    summary,
    invoices,
    customRelationshipType,
    contract,
  });

export default rootReducer;
