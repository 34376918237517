import React, { FunctionComponent } from "react";
import { Button, Modal } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { PaymentAddress } from "src/common/components/payment/payment-address";
import PaymentInfo from "src/common/components/payment/payment-info";

interface Props {
  paymentAccount: PaymentAccount;
  payableAmount: string;
  paymentDeadline: string;
  onClose: () => void;
}

const OrderPaymentInfoModal: FunctionComponent<Props> = ({
  paymentAccount,
  payableAmount,
  paymentDeadline,
  onClose,
}) => {
  return (
    <Modal
      open={true}
      onClose={onClose}
      size={"tiny"}
      closeIcon={true}
      closeOnDimmerClick={false}
    >
      <Modal.Header className="text-center">
        <Content id={"orders.paymentInfo.popup.header"} />
      </Modal.Header>
      <Modal.Content>
        <PaymentInfo
          ibanNumber={paymentAccount.iban}
          bicNumber={paymentAccount.bic}
          bankAccountNumber={paymentAccount.bank_account}
          address={<PaymentAddress addressString={paymentAccount.address} />}
          description={
            <Content
              id="orders.paymentInfo.description"
              values={{
                payableAmount,
                paymentDeadline,
              }}
            />
          }
        />
      </Modal.Content>
      <Modal.Actions style={{ textAlign: "center" }}>
        <Button color="green" onClick={onClose}>
          <Content id={"orders.paymentInfo.popup.button"} />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default OrderPaymentInfoModal;
