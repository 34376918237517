import React, { FunctionComponent } from "react";
import { Button, Header, Modal } from "semantic-ui-react";

import { uploadedFileDownloadUrl } from "src/common/utils/utils";

interface Props {
  file?: Api.V1.Document;
  handleClose: () => void;
  sameTab?: boolean;
}

const FileDownloadModal: FunctionComponent<Props> = ({
  handleClose,
  file,
  sameTab,
}) => (
  <Modal closeIcon={true} size="small" onClose={handleClose} open={true}>
    <Header>
      Document: <em style={{ fontWeight: 400 }}>{file && file.fileName}</em>
    </Header>
    <Modal.Content style={{ height: 80, textAlign: "center" }}>
      <Button
        as="a"
        target={sameTab ? false : "_blank"}
        content="Download File"
        href={file && uploadedFileDownloadUrl(file)}
      />
    </Modal.Content>
  </Modal>
);

export default FileDownloadModal;
