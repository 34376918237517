import React, { FunctionComponent } from "react";
import { Icon, Popup } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { CurrencyFormatterProps } from "src/common/components/currency/currency-formatter";
import { formatNumber, formatPercentage } from "src/common/utils/utils";
import { FlatAward } from "src/employee-portal/instrument-page/instruments-reducer";

interface Props {
  award: FlatAward;
  maximumQuantity: number;
  formatter: CurrencyFormatterProps;
}

const ThresholdsCell: FunctionComponent<Props> = ({
  award,
  maximumQuantity,
  formatter: { formatCurrencyAbbriviatedWithCurrencyCode },
}) => {
  return (
    <Popup
      trigger={
        <div>
          Thresholds
          <Icon name="question circle outline" />
        </div>
      }
    >
      <Popup.Content>
        <div>
          <div className="block-s">
            <strong>{award.performanceCriteria.name}</strong>
          </div>
          {award.performanceCriteria.thresholds.map((t, index, array) => (
            <div style={{ width: 250 }} className="block-s">
              <div className="block-xs">
                <strong>{t.name}</strong>
              </div>
              <div className="flex-row space-between block-xxs">
                <Content id={"psu.thresholds.goal"} />
                <span>
                  {formatCurrencyAbbriviatedWithCurrencyCode(
                    award.performanceCriteria.currencyCode
                  )(t.fulfillmentLevelNumber, 1).toUpperCase()}
                </span>
              </div>
              {array.length > 1 && (
                <>
                  <div className="flex-row space-between block-xxs">
                    <Content id={"psu.thresholds.percent"} />
                    <span>
                      {formatPercentage(
                        t.fulfillmentFactor *
                          award.performanceCriteria.displayFactor
                      )}
                    </span>
                  </div>
                  <div className="flex-row space-between block-xxs">
                    <Content id={"psu.thresholds.quantity"} />
                    <span>
                      {formatNumber(
                        Math.round(t.fulfillmentFactor * maximumQuantity)
                      )}
                    </span>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </Popup.Content>
    </Popup>
  );
};

export default ThresholdsCell;
