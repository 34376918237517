import { push } from "connected-react-router";
import Raven from "raven-js";
import { call, debounce, put, select, takeEvery } from "redux-saga/effects";

import * as selectors from "src/admin-portal/awards/award-selectors";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { DEBOUNCE_TIME_500_MS } from "src/constants";
import {
  CREATE_PURCHASE_ORDER,
  CREATE_PURCHASE_ORDER_FAILED,
  CREATE_PURCHASE_ORDER_SUCCEEDED,
  FETCH_PURCHASE_DOCUMENT,
  FETCH_PURCHASE_DOCUMENT_FAILED,
  FETCH_PURCHASE_DOCUMENT_SUCCEEDED,
} from "src/employee-portal/purchase/duck/purchase-actions";

const fetchDocumentUrl = (documentId: string) => `/documents/${documentId}`;

function* fetchPurchaseDocument(action) {
  try {
    const token = yield select(selectors.token);
    const response = yield call(
      callApi,
      fetchDocumentUrl(action.documentId),
      token,
      "GET"
    );
    yield put({
      type: FETCH_PURCHASE_DOCUMENT_SUCCEEDED,
      document: response.data,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({
        type: FETCH_PURCHASE_DOCUMENT_FAILED,
        message: e.message,
      });
    }
  }
}

export function* watchFetchPurchaseDocument() {
  yield takeEvery(FETCH_PURCHASE_DOCUMENT, fetchPurchaseDocument);
}

interface CreatePurchaseOrder {
  order_type: "PURCHASE";
  window_id: string;
  data: {
    purchase_amount?: number;
    purchase_cash_amount?: number;
    purchase_opportunity_id?: string;
    award_id?: string;
    share_depository_account?: string;
    share_depository_bank?: string;
  };
}

function* createPurchaseOrder(action) {
  try {
    const state = yield select();
    const { token, tenant } = state.user;

    const body = {
      data: {
        type: "orders",
        attributes: {
          orderType: "PURCHASE",
          windowId: action.window_id,
        },
      },
      payload: action.data,
    };

    const response = yield call(
      callApi,
      `/tenants/${tenant.id}/orders`,
      token,
      "POST",
      body
    );
    yield put({
      type: CREATE_PURCHASE_ORDER_SUCCEEDED,
      document: response.data,
    });
    yield put({ type: "FETCH_EMPLOYEE_PORTAL_WELCOME" });
    yield put(push("/orders/purchasecomplete"));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({
        type: CREATE_PURCHASE_ORDER_FAILED,
        message: e.message,
      });
    }
  }
}

export function* watchCreatePurchaseOrder() {
  yield debounce(
    DEBOUNCE_TIME_500_MS,
    CREATE_PURCHASE_ORDER,
    createPurchaseOrder
  );
}
