import React, { FunctionComponent } from "react";
import { Button, Form, Message } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import CurrencyFormatter, {
  CurrencyFormatterProps,
} from "src/common/components/currency/currency-formatter";
import HelpPopup from "src/common/components/help-popup";
import { useModal } from "src/common/hooks/useModal";
import {
  InstrumentType,
  useInstrumentTypeText,
} from "src/common/utils/text-mappings";
import {
  changePunctuationForComma,
  formatNumber,
  formatPercentage,
  simpleRound,
} from "src/common/utils/utils";
import { NO_VALUE } from "src/constants";
import { SharePrice } from "src/employee-portal/exercise/exercise-router";
import { LoanConfig } from "src/employee-portal/purchase/duck/purchase-selectors";
import LoanFormField from "src/employee-portal/purchase/steps/loan/loan-form-field";
import { PurchaseSetMinMax } from "src/employee-portal/purchase/steps/purchase-set-min-max";
import TaxModal from "src/employee-portal/purchase/steps/tax/tax-modal";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface Props {
  quantity: string;
  price: number;
  basePrice: number;
  quantityError: boolean;
  minimumQuantity: number;
  maximumQuantity: number;
  quantityChanged: (newValue: string) => void;
  goBack: () => void;
  goForward: () => void;
  instrument: InstrumentType;
  discount?: number;
  sharePrice: SharePrice;
  dateFormat: DateFormatState;
  loanConfig?: LoanConfig;
  loan: string;
  loanPeriods?: number;
  loanChanged: (newValue: string) => void;
  loanError: boolean;
  purchaseCurrencyFactor: number;
  showTaxExplanation: boolean;
  putDiscount: number;
}

const Quantity: FunctionComponent<Props> = ({
  quantity,
  quantityError,
  minimumQuantity,
  maximumQuantity,
  price,
  basePrice,
  instrument,
  quantityChanged,
  goBack,
  goForward,
  discount,
  sharePrice,
  loan,
  loanPeriods,
  loanConfig,
  loanChanged,
  loanError,
  purchaseCurrencyFactor,
  showTaxExplanation,
  putDiscount,
}) => {
  const formatMessage = useFormatMessage();
  const instrumentTerm = useInstrumentTypeText(instrument);
  const quantityNumber = parseInt(quantity, 10);
  const onChangeQuantity = event =>
    quantityChanged(event.target.value.replace(/\D/g, ""));
  const inputQuantityInteger = parseInt(quantity);
  const formattedInputQuantity = formatNumber(inputQuantityInteger);
  const marketValue = inputQuantityInteger * sharePrice.sharePrice;
  const error = quantityError || loanError;
  const [isTaxModalOpen, openTaxModal, closeTaxModal] = useModal();
  return (
    <CurrencyFormatter>
      {(formatter: CurrencyFormatterProps) => {
        const totalPrice = simpleRound(price * quantityNumber, 2);
        const totalBasePrice = simpleRound(
          (basePrice || price) * quantityNumber,
          2
        );
        const loanAmount = loanConfig ? parseFloat(loan || "0") : 0;
        const actualLoan = Math.min(loanAmount, totalPrice);
        const toPay = Math.max(simpleRound(totalPrice - actualLoan), 0);
        const toPayFormatted = formatter.formatCurrency(toPay, 2);
        const totalDiscount = Math.abs(totalPrice - totalBasePrice);
        const quantityMessageValues = {
          instrumentTermPlural: instrumentTerm.plural,
          maximumQuantity: formatNumber(maximumQuantity),
          minimumQuantity: formatNumber(minimumQuantity),
          minimumLoan:
            loanConfig && formatter.formatCurrency(loanConfig.min, 0),
          maximumLoan:
            loanConfig && formatter.formatCurrency(loanConfig.max, 0),
        };
        return (
          <>
            {isTaxModalOpen && (
              <TaxModal
                onClose={closeTaxModal}
                showNorwegianTaxExample={true}
                quantity={quantityNumber}
                totalPrice={totalPrice}
                discountPercent={discount}
                totalDiscount={totalDiscount}
                instrumentTermPlural={instrumentTerm.plural}
                instrumentTermSingular={instrumentTerm.singular}
                putDiscount={putDiscount}
              />
            )}
            <div className="block-l">
              <h2 className="text-center block-m">
                <Content id="purchase.quantity.title" />
              </h2>
              <p className="purchase-narrow-width margin-center block-m">
                <Content
                  id="purchase.quantity.body"
                  values={{
                    price: formatter.formatCurrency(price, 2),
                    basePrice: formatter.formatCurrency(basePrice, 2),
                    discount: formatter.formatCurrency(basePrice - price, 2),
                    discountPercent: formatPercentage(discount),
                    instrumentTermSingular: instrumentTerm.singular,
                    currency: formatter.currencyCode,
                    currencyFactor: purchaseCurrencyFactor
                      ? changePunctuationForComma(
                          simpleRound(purchaseCurrencyFactor, 5).toFixed(4)
                        )
                      : null,
                    ...quantityMessageValues,
                  }}
                />
              </p>
              {error && (
                <div className="purchase-narrow-width margin-center block-m">
                  <Message
                    header={
                      <Message.Header>
                        <Content id={"purchase.quantity.errMessageHeader"} />
                      </Message.Header>
                    }
                    error={true}
                    list={[
                      quantityError && (
                        <Content
                          id={"purchase.quantity.errMessageContent"}
                          values={{
                            minimumQuantity: formatNumber(minimumQuantity),
                            maximumQuantity: formatNumber(maximumQuantity),
                          }}
                        />
                      ),
                      loanError && (
                        <Content
                          id={"purchase.quantity.loan.errMessageContent"}
                          values={{
                            minimumQuantity: formatNumber(loanConfig.min),
                            maximumQuantity: formatNumber(loanConfig.max),
                          }}
                        />
                      ),
                    ]}
                  />
                </div>
              )}
              <div className="center-center">
                <div className="purchase-box">
                  <div className="purchase-box-form">
                    <Form>
                      <div>
                        <div className="purchase-box-form-input">
                          <Form.Field
                            label={
                              <label>
                                <Content
                                  id={"purchase.quantity.label"}
                                  values={quantityMessageValues}
                                />
                                <HelpPopup
                                  textKey="purchase.quantity.popup"
                                  values={quantityMessageValues}
                                />
                              </label>
                            }
                            control="input"
                            max={maximumQuantity}
                            // error={quantityError}
                            value={quantity}
                            onChange={onChangeQuantity}
                            style={{ textAlign: "right" }}
                            placeholder={formatMessage(
                              { id: "purchase.quantity.placeholder" },
                              quantityMessageValues
                            )}
                          />
                          <PurchaseSetMinMax
                            min={minimumQuantity}
                            max={maximumQuantity}
                            onChange={quantityChanged}
                          />
                        </div>
                      </div>
                      {loanConfig && (
                        <LoanFormField
                          formatter={formatter}
                          loanChanged={loanChanged}
                          loan={loan}
                          loanError={false}
                          // loanError={loanError}
                          loanConfig={loanConfig}
                          totalPrice={totalPrice}
                        />
                      )}
                    </Form>
                  </div>
                  <div className="divider" />
                  <div className="purchase-box-section">
                    <div className={`purchase-box-section-item`}>
                      <Content
                        id={"purchase.quantity.summary.amount.label"}
                        values={{ instrumentTypePlural: instrumentTerm.plural }}
                      />
                      <span className="purchase-box-section-item-value">
                        {quantityError ? NO_VALUE : formattedInputQuantity}
                      </span>
                    </div>
                    {discount ? (
                      <>
                        <div className="block-xxs" />
                        <div className={`purchase-box-section-item`}>
                          <div>
                            <Content
                              id={
                                "purchase.quantity.summary.totalBasePrice.label"
                              }
                              values={{
                                instrumentTermPlural: instrumentTerm.plural,
                                instrumentTermSingular: instrumentTerm.singular,
                                discountPercentage: formatPercentage(discount),
                                price: formatter.formatCurrency(price, 2),
                                basePrice: formatter.formatCurrency(
                                  basePrice,
                                  2
                                ),
                                totalPrice: formatter.formatCurrency(
                                  totalPrice,
                                  2
                                ),
                                totalBasePrice: formatter.formatCurrency(
                                  totalBasePrice,
                                  2
                                ),
                              }}
                            />
                            <HelpPopup
                              textKey="purchase.quantity.summary.totalBasePrice.popup"
                              values={{
                                instrumentTermPlural: instrumentTerm.plural,
                                instrumentTermSingular: instrumentTerm.singular,
                                discountPercentage: formatPercentage(discount),
                                price: formatter.formatCurrency(price, 2),
                                basePrice: formatter.formatCurrency(
                                  basePrice,
                                  2
                                ),
                                totalPrice: formatter.formatCurrency(
                                  totalPrice,
                                  2
                                ),
                                totalBasePrice: formatter.formatCurrency(
                                  totalBasePrice,
                                  2
                                ),
                              }}
                            />
                          </div>
                          <span className="purchase-box-section-item-value">
                            {quantityError
                              ? NO_VALUE
                              : `${formatter.formatCurrency(
                                  totalBasePrice,
                                  2
                                )}`}
                          </span>
                        </div>
                        <div className="block-xxs" />
                        <div className={`purchase-box-section-item`}>
                          <div>
                            <Content
                              id={"purchase.quantity.summary.discount.label"}
                              values={{
                                instrumentTermPlural: instrumentTerm.plural,
                                discountPercentage: formatPercentage(discount),
                              }}
                            />
                            <HelpPopup
                              textKey="purchase.quantity.summary.discount.popup"
                              values={{
                                instrumentTermPlural: instrumentTerm.plural,
                                discountPercentage: formatPercentage(discount),
                              }}
                            />
                          </div>
                          <span className="purchase-box-section-item-value">
                            {quantityError
                              ? NO_VALUE
                              : `${formatter.formatCurrency(totalDiscount, 2)}`}
                          </span>
                        </div>
                      </>
                    ) : null}

                    {showTaxExplanation && (
                      <>
                        <div className="block-s" />
                        <div className="text-center">
                          <Button
                            basic={true}
                            primary={!error}
                            onClick={openTaxModal}
                            disabled={error}
                          >
                            <Content id={"purchase.quantity.tax.button"} />
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="divider" />
                  {loanConfig ? (
                    <div className="purchase-box-section">
                      <div className={`purchase-box-section-item block-xxs`}>
                        <div>
                          <strong>
                            <Content
                              id={"purchase.quantity.summary.totalprice.label"}
                              values={{
                                price: formatter.formatCurrency(price, 2),
                              }}
                            />
                          </strong>
                          <HelpPopup
                            textKey="purchase.quantity.summary.totalprice.popup"
                            values={{
                              instrumentTermPlural: instrumentTerm.plural,
                            }}
                          />
                        </div>
                        <strong className="purchase-box-section-item-value">
                          <span>
                            {quantityError
                              ? NO_VALUE
                              : formatter.formatCurrency(totalPrice, 2)}
                          </span>
                        </strong>
                      </div>
                      <div className="divider-dark block-xxs" />
                      <div
                        className={`purchase-box-section-item ${
                          loanConfig ? "block-xxs" : ""
                        }`}
                      >
                        <div>
                          <Content
                            id={"purchase.quantity.totalPurchasePrice"}
                            values={{
                              instrumentTermPlural: instrumentTerm.plural,
                            }}
                          />
                        </div>
                        <span className="purchase-box-section-item-value">
                          {error || toPay === 0 ? NO_VALUE : toPayFormatted}
                        </span>
                      </div>
                      {loanConfig && (
                        <>
                          <div className="purchase-box-section-item block-xxs">
                            <div>
                              <Content
                                id={"purchase.quantity.summary.loan.label"}
                                values={{
                                  instrumentTermPlural: instrumentTerm.plural,
                                }}
                              />
                              <HelpPopup
                                textKey="purchase.quantity.summary.loan.popup"
                                values={{
                                  instrumentTermPlural: instrumentTerm.plural,
                                }}
                              />
                            </div>
                            <span className="purchase-box-section-item-value">
                              {error || actualLoan === 0
                                ? NO_VALUE
                                : formatter.formatCurrency(actualLoan, 2)}
                            </span>
                          </div>
                          {loanPeriods ? (
                            <>
                              <div className="divider-dark block-xxs" />
                              <div className="purchase-box-section-item">
                                <div>
                                  <Content
                                    id={
                                      "purchase.quantity.summary.loanperiod.label"
                                    }
                                    values={{
                                      instrumentTermPlural:
                                        instrumentTerm.plural,
                                    }}
                                  />
                                  <HelpPopup
                                    textKey="purchase.quantity.summary.loanperiod.popup"
                                    values={{
                                      instrumentTermPlural:
                                        instrumentTerm.plural,
                                    }}
                                  />
                                </div>
                                <span className="purchase-box-section-item-value">
                                  {error || actualLoan === 0
                                    ? NO_VALUE
                                    : formatter.formatCurrency(
                                        actualLoan / loanPeriods,
                                        2
                                      )}
                                </span>
                              </div>
                            </>
                          ) : null}
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="purchase-box-section">
                      <div className={`purchase-box-section-item`}>
                        <div>
                          <strong>
                            <Content
                              id={"purchase.quantity.summary.totalprice.label"}
                            />
                          </strong>
                          <HelpPopup
                            textKey="purchase.quantity.summary.totalprice.popup"
                            values={{
                              instrumentTermPlural: instrumentTerm.plural,
                            }}
                          />
                        </div>
                        <strong className="purchase-box-section-item-value">
                          <span>
                            {quantityError
                              ? NO_VALUE
                              : formatter.formatCurrency(totalPrice, 2)}
                          </span>
                        </strong>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="page-action-container text-center">
              <Button
                size="big"
                onClick={goBack}
                content={formatMessage({ id: "global.backBtnTxt" })}
              />
              <Button
                positive={true}
                content={formatMessage({ id: "global.nextBtnTxt" })}
                icon="right arrow"
                labelPosition="right"
                size="big"
                disabled={quantityError === null || error}
                onClick={() => {
                  const allowNext = true;
                  // if (loanConfig && parseInt(loan || "0") === 0) {
                  //   allowNext = confirm(
                  //     formatMessage({
                  //       id: "purchase.quantity.noloan.description",
                  //     })
                  //   );
                  // }

                  allowNext && goForward();
                }}
              />
            </div>
          </>
        );
      }}
    </CurrencyFormatter>
  );
};

export default Quantity;
