import React, { FunctionComponent, useState } from "react";
import { Input } from "semantic-ui-react";

interface NumberRangeProps {
  whichStateKey: string;
  fromLabel: string;
  toLabel: string;
  from: number;
  to: number;
  handleNumberRangeChange: (
    whichStateKey: string,
    from: number,
    to: number
  ) => void;
}

const style = { display: "inline-block", marginBottom: 5 };

const NumberRange: FunctionComponent<NumberRangeProps> = ({
  whichStateKey,
  fromLabel,
  toLabel,
  from,
  to,
  handleNumberRangeChange,
}) => {
  const [fromInput, setFromInput] = useState(from ? from.toString() : "");
  const [toInput, setToInput] = useState(to ? to.toString() : "");

  const parseNumber = value => {
    const onlyNumbers = value.replace(/[^\d|.|,]/gi, "");
    const parsed = parseFloat(onlyNumbers) || null;

    return [parsed, onlyNumbers];
  };

  return (
    <div>
      <label style={style}>{fromLabel}</label>
      <div style={style}>
        <Input
          placeholder="Insert Number"
          value={fromInput}
          onChange={(e, { value }) => {
            const [parsed, onlyNumbers] = parseNumber(value);

            if (!isNaN(parsed) || value === "") {
              setFromInput(onlyNumbers);
              handleNumberRangeChange(
                whichStateKey,
                parsed,
                parseNumber(toInput)[0]
              );
            }
          }}
        />
      </div>
      <label style={style}>{toLabel}</label>
      <div style={style}>
        <Input
          placeholder="Insert Number"
          value={toInput}
          onChange={(e, { value }) => {
            const [parsed, onlyNumbers] = parseNumber(value);

            if (!isNaN(parsed) || value === "") {
              setToInput(onlyNumbers);
              handleNumberRangeChange(
                whichStateKey,
                parseNumber(fromInput)[0],
                parsed
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export default NumberRange;
