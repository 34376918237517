import React, { FunctionComponent } from "react";

import { SharePrice } from "src/employee-portal/exercise/exercise-router";
import SharePriceMessage from "src/employee-portal/menu/SharePriceMessage";
import SharePriceSimulationButton from "src/employee-portal/menu/SharePriceSimulationButton";

interface Props {
  pathName: string;
  sharePriceSimulation: boolean;
  sharePrice: SharePrice;
  normalDateFormat: string;
}

const SubMenu: FunctionComponent<Props> = ({
  pathName,
  sharePriceSimulation,
  sharePrice,
  normalDateFormat,
}) => (
  <>
    <SharePriceMessage
      sharePrice={sharePrice}
      normalDateFormat={normalDateFormat}
    />

    <SharePriceSimulationButton
      pathName={pathName}
      sharePriceSimulation={sharePriceSimulation}
    />
  </>
);

export default SubMenu;
