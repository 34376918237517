import { FlatAward } from "src/employee-portal/instrument-page/instruments-reducer";

export const totalStrike = (quantity: number, award: FlatAward) => {
  if (award.strikeConversionFactor) {
    const shares = Math.floor(award.sharesConversionFactor * quantity);
    const strike = award.strike * award.strikeConversionFactor;
    return shares * strike;
  }
  return quantity * award.strike;
};
