import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

import { useFormatMessage } from "src/admin-portal/texts/utils";
import CurrencyConversionView from "src/common/components/currency/currency-conversion-view";
import TextWithHelperText from "src/common/components/text-with-helper-text";
import { Window } from "src/common/data/data";
import Text from "src/common/text/text";
import { formatNumber, removeNulls } from "src/common/utils/utils";
import { orderRoute } from "src/employee-portal/employee-portal-router";
import ExpandableSection from "src/employee-portal/home-page/instrument-overview-box/expandable-header";
import InstrumentOverviewContent from "src/employee-portal/home-page/instrument-overview-box/instrument-overview-content";
import { IndividualInstrumentState } from "src/employee-portal/instrument-page/instruments-reducer";

const SubscriptionRightsSection: FunctionComponent<{
  subscriptionRights: IndividualInstrumentState;
  exerciseFeature: boolean;
  canExerciseCurrentWindow: boolean;
  currentWindow?: Window;
  nextWindow?: Window;
  startExpanded?: boolean;
  hideNavigation?: boolean;
}> = ({
  subscriptionRights,
  exerciseFeature,
  startExpanded,
  hideNavigation,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <ExpandableSection
      headerText={formatMessage({
        id: "instruments.frontpagebox.subscriptionright.header",
      })}
      headerValue={subscriptionRights.gain.totalGain}
      startExpanded={startExpanded}
    >
      <InstrumentOverviewContent
        sections={[
          [
            {
              title: (
                <TextWithHelperText
                  textKey={
                    "instruments.frontpagebox.subscriptionright.totalgain"
                  }
                  popupTextKey={
                    "instruments.frontpagebox.subscriptionright.totalgain.popup"
                  }
                />
              ),
              value: (
                <CurrencyConversionView
                  value={subscriptionRights.gain.totalGain}
                  decimals={0}
                  showOverrideCurrencyOnly={true}
                />
              ),
            },
            {
              title: (
                <TextWithHelperText
                  textKey={
                    "instruments.frontpagebox.subscriptionright.totalinstruments"
                  }
                  popupTextKey={
                    "instruments.frontpagebox.subscriptionright.totalinstruments.popup"
                  }
                />
              ),
              value: formatNumber(subscriptionRights.instrumentQuantity),
            },
            subscriptionRights.sharesQuantity !==
            subscriptionRights.instrumentQuantity
              ? {
                  title: (
                    <Text
                      textKey={
                        "instruments.frontpagebox.subscriptionright.sharesequivalent"
                      }
                    />
                  ),
                  value: formatNumber(subscriptionRights.sharesQuantity),
                }
              : null,
          ].filter(removeNulls),
          [
            {
              title: (
                <TextWithHelperText
                  textKey={
                    "instruments.frontpagebox.subscriptionright.vestedgain"
                  }
                  popupTextKey={
                    "instruments.frontpagebox.subscriptionright.vestedgain.popup"
                  }
                />
              ),
              value: (
                <span className="text-positive">
                  <CurrencyConversionView
                    value={subscriptionRights.gain.vestedGain}
                    decimals={0}
                    showOverrideCurrencyOnly={true}
                  />
                </span>
              ),
            },
            {
              title: (
                <TextWithHelperText
                  textKey={
                    "instruments.frontpagebox.subscriptionright.vestedinstruments"
                  }
                  popupTextKey={
                    "instruments.frontpagebox.subscriptionright.vestedinstruments.popup"
                  }
                />
              ),
              value: (
                <span className="text-positive">
                  {formatNumber(subscriptionRights.vestedInstrumentQuantity)}
                </span>
              ),
            },
            subscriptionRights.sharesQuantity !==
            subscriptionRights.instrumentQuantity
              ? {
                  title: (
                    <Text
                      textKey={
                        "instruments.frontpagebox.subscriptionright.vestedsharesequivalent"
                      }
                    />
                  ),
                  value: formatNumber(subscriptionRights.sharesQuantity),
                }
              : null,
          ].filter(removeNulls),
        ]}
      />
      {!hideNavigation && (
        <div className="text-center">
          <Button
            content={formatMessage({ id: "global.viewDetailsBtn" })}
            as={Link}
            to="/instruments/subscription_rights"
            basic={true}
            primary={true}
          />
          {exerciseFeature && (
            <span className="margin-left-m">
              <Button
                content={formatMessage({ id: "global.viewOrdersBtn" })}
                as={Link}
                to={orderRoute}
                basic={true}
                primary={true}
              />
            </span>
          )}
        </div>
      )}
    </ExpandableSection>
  );
};

export default SubscriptionRightsSection;
