import { createAction } from "redux-act";

import { PaginationLinks } from "src/common/utils/pagination";

export interface AuditLogs {
  [pageNumber: string]: Api.V1.AuditLog[];
}

export const fetchAuditLogs = createAction(
  "FETCH_AUDIT_LOGS",
  (payload: { pageNumber: string }) => payload
);

export const fetchAuditLogsSucceeded = createAction(
  "FETCH_AUDIT_LOGS_SUCCEEDED",
  (payload: {
    auditLogs: AuditLogs;
    auditLogsPaginationLinks: PaginationLinks;
  }) => payload
);
