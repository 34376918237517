import React, { FunctionComponent } from "react";

import CommonShareDepositoryFields from "src/employee-portal/order/table/UpdateModal/Form/CommonShareDepositoryFields";
import ShareDepositoryNorwegian from "src/employee-portal/order/table/UpdateModal/Form/fields/ShareDepositoryNorwegian";
import { FieldsVisibility } from "src/employee-portal/order/table/UpdateModal/Form/UseFieldsVisibilityCheck";
import NorwegianShareDepoRadio, {
  HasNorwegianAccountAnswer,
} from "src/employee-portal/purchase/steps/share-depository/norwegian-share-depo-radio";

interface Props {
  purchaseOrder: Api.V1.PurchaseOrder;
  visibility: FieldsVisibility;
  hasNorwegianAccount: HasNorwegianAccountAnswer;
  setHasNorwegianAccount: (HasNorwegianAccountAnswer) => void;
}

const PurchaseFields: FunctionComponent<Props> = ({
  purchaseOrder,
  visibility,
  hasNorwegianAccount,
  setHasNorwegianAccount,
}) => {
  if (!purchaseOrder) {
    return null;
  }

  if (!visibility.isPurchasingQuestionsVisible)
    return (
      <>
        <ShareDepositoryNorwegian
          visible={visibility.isShareDepositoryNorwegianVisible}
        />

        <CommonShareDepositoryFields visibility={visibility} />
      </>
    );

  return (
    <>
      <NorwegianShareDepoRadio
        answer={hasNorwegianAccount}
        allowInProgress={false}
        allowForeignShareDepo={true}
        setHasNorwegianAccount={setHasNorwegianAccount}
      />

      {hasNorwegianAccount === HasNorwegianAccountAnswer.YES && (
        <ShareDepositoryNorwegian
          visible={visibility.isShareDepositoryNorwegianVisible}
        />
      )}

      {hasNorwegianAccount === HasNorwegianAccountAnswer.NO && (
        <CommonShareDepositoryFields visibility={visibility} />
      )}
    </>
  );
};

export default PurchaseFields;
