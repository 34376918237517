import { History } from "history";
import queryString from "query-string";
import React, { FunctionComponent } from "react";
import {
  Dropdown,
  Icon,
  Pagination as PaginationSemantic,
} from "semantic-ui-react";

import { PaginationLinks } from "src/common/utils/pagination";

interface Paginatable {
  pageSize: string;
}

interface Props {
  currentPageNumber: string;
  paginationLinks: PaginationLinks;
  history: History;
  baseUrl: string;
  sort?: string;
  hide?: boolean;
  searchFromUrl?: Paginatable;
  showPgSzDropdown?: boolean;
}

export const pageSizeOptions = [
  {
    key: "50",
    value: 50,
    text: "50",
  },
  {
    key: "100",
    value: 100,
    text: "100",
  },
  {
    key: "1000",
    value: 1000,
    text: "1000",
  },
  {
    key: "10000",
    value: 10000,
    text: "10000",
  },
];

const generateUrl = (
  searchFromUrl: any,
  baseUrl: string,
  pageNum: number,
  sort?: string
) =>
  `${baseUrl}?${queryString.stringify({
    ...searchFromUrl,
    pageNum,
    sort,
  })}`;

const Pagination: FunctionComponent<Props> = ({
  currentPageNumber,
  paginationLinks,
  history,
  baseUrl,
  sort,
  hide,
  searchFromUrl,
  showPgSzDropdown,
}) => {
  if (!paginationLinks || hide) {
    return null;
  }

  return (
    <div className="space-vertical text-center">
      <PaginationSemantic
        activePage={currentPageNumber}
        firstItem={{
          content: <Icon name="angle double left" />,
          icon: true,
          disabled: paginationLinks.first === currentPageNumber,
          onClick: () =>
            history.push(
              generateUrl(searchFromUrl, baseUrl, +paginationLinks.first, sort)
            ),
        }}
        lastItem={{
          content: <Icon name="angle double right" />,
          icon: true,
          disabled: paginationLinks.last === currentPageNumber,
          onClick: () =>
            history.push(
              generateUrl(searchFromUrl, baseUrl, +paginationLinks.last, sort)
            ),
        }}
        prevItem={{
          content: <Icon name="angle left" />,
          icon: true,
          disabled: paginationLinks.first === currentPageNumber,
          onClick: () =>
            history.push(
              generateUrl(searchFromUrl, baseUrl, +currentPageNumber - 1, sort)
            ),
        }}
        nextItem={{
          content: <Icon name="angle right" />,
          icon: true,
          disabled: paginationLinks.last === currentPageNumber,
          onClick: () =>
            history.push(
              generateUrl(searchFromUrl, baseUrl, +currentPageNumber + 1, sort)
            ),
        }}
        totalPages={paginationLinks.last || 0}
        pageItem={{
          onClick: (e, { content }) =>
            history.push(generateUrl(searchFromUrl, baseUrl, content, sort)),
        }}
        siblingRange={2}
      />
      {showPgSzDropdown && (
        <div style={{ marginTop: "20px" }}>
          <span style={{ marginRight: "5px" }}>Page size:</span>
          <Dropdown
            placeholder="Page size"
            selection={true}
            options={pageSizeOptions}
            value={+searchFromUrl.pageSize}
            onChange={(e, { value }) =>
              history.push(
                `${baseUrl}?${queryString.stringify({
                  ...searchFromUrl,
                  pageNum: 1,
                  pageSize: value,
                })}`
              )
            }
            name="instrumentType"
          />
        </div>
      )}
    </div>
  );
};

export default Pagination;
