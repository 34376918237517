import React, { FunctionComponent } from "react";

import { Icon, Popup, Table } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import CurrencyConversionView from "src/common/components/currency/currency-conversion-view";
import CurrencyFormatter, {
  CurrencyFormatterProps,
} from "src/common/components/currency/currency-formatter";
import { sortMultipleLevels } from "src/common/utils/sort";
import { formatNumber, sumNumbers } from "src/common/utils/utils";
import CapOnGainHeaderCell from "src/employee-portal/instrument-page/common/cap-on-gain-header-cell";
import {
  hasExpired,
  removeExpiredAwards,
} from "src/employee-portal/instrument-page/instruments-page";
import {
  AwardGainFunction,
  FlatAward,
  hasCapOnGain,
  hasOriginalQuantity,
  hasPerformanceCriteria,
} from "src/employee-portal/instrument-page/instruments-reducer";
import RSATableRow from "src/employee-portal/instrument-page/rsa-like/rsa-like-table-row";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface Props {
  rsas: FlatAward[];
  sharePriceToday: number;
  showExpired: boolean;
  totalGain: number;
  totalQuantity: number;
  dateFormat: DateFormatState;
  awardGain: AwardGainFunction;
}

const RSALikeTable: FunctionComponent<Props> = ({
  rsas,
  sharePriceToday,
  showExpired,
  totalGain,
  totalQuantity,
  awardGain,
  dateFormat,
}) => {
  const nonExpired = rsas.filter(removeExpiredAwards);
  const shownRsas = showExpired || nonExpired.length === 0 ? rsas : nonExpired;
  const sorted = sortMultipleLevels(shownRsas)("grantDate", "vestedDate");
  const showSharePrice = rsas.some(a => !!a.share_price);
  const showTaxableInputValuation = rsas.some(a => !!a.taxable_input_valuation);
  const sumQuantityExpired = rsas
    .filter(hasExpired)
    .map(rsa => rsa.quantity || 0)
    .reduce(sumNumbers, 0);
  const sumQuantity = showExpired
    ? totalQuantity + sumQuantityExpired
    : totalQuantity;
  const sumGainIncludingExpired = rsas
    .filter(hasExpired)
    .map(award => awardGain(sharePriceToday, award).gain)
    .reduce(sumNumbers, 0);
  const sumGain = showExpired ? totalGain + sumGainIncludingExpired : totalGain;
  return (
    <div className="sideways-scrollable block-l">
      <CurrencyFormatter>
        {(formatter: CurrencyFormatterProps) => (
          <Table celled={true} unstackable={true} textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Content id="global.tableLabel.program" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Content id="rsa.tableLabel.grantDate" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Content id="global.tableLabel.rsa.expiryDate" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Content id="global.tableLabel.purchasePrice" />
                </Table.HeaderCell>
                {showTaxableInputValuation && (
                  <Table.HeaderCell>
                    <Content id="global.tableLabel.inputValue" />
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell>
                  <Content id={"instruments.quantity.label"} />
                </Table.HeaderCell>
                {showSharePrice && (
                  <Table.HeaderCell>
                    <Content id="global.tableLabel.sharePrice" />
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell>
                  <Content id="global.tableLabel.totalValue" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {sorted.map(tranche => (
                <RSATableRow
                  award={tranche}
                  stockPriceToday={sharePriceToday}
                  key={tranche.trancheId}
                  showSharePrice={showSharePrice}
                  showTaxableInputValuation={showTaxableInputValuation}
                  hasExpired={hasExpired(tranche)}
                  formatter={formatter}
                  dateFormat={dateFormat}
                  totalGain={awardGain(sharePriceToday, tranche).gain}
                />
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell>
                  <Content id="global.tableLabel.sum" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right" />
                <Table.HeaderCell textAlign="right" />
                <Table.HeaderCell textAlign="right" />
                {showTaxableInputValuation && (
                  <Table.HeaderCell textAlign="right" />
                )}
                <Table.HeaderCell textAlign="right">
                  {formatNumber(sumQuantity)}
                </Table.HeaderCell>
                {showSharePrice && <Table.HeaderCell textAlign="right" />}
                <Table.HeaderCell textAlign="right">
                  <CurrencyConversionView value={sumGain} decimals={0} />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </CurrencyFormatter>
    </div>
  );
};

export default RSALikeTable;
