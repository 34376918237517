import React, { FunctionComponent } from "react";

import FAQItem from "src/employee-portal/support/faq-item";
import "src/employee-portal/support/faq.less";

export interface FAQItem {
  questionKey: string;
  answerKey: string;
}

const FAQList: FunctionComponent<{ items: FAQItem[] }> = ({ items }) => (
  <div className="faq-list-container">
    {items.map((qa, index) => (
      <FAQItem {...qa} key={index} />
    ))}
  </div>
);

export default FAQList;
