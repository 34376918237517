import React, { FunctionComponent, useState } from "react";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Message,
  Modal,
  Tab,
} from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import CurrencyFormatter from "src/common/components/currency/currency-formatter";
import HelpPopup from "src/common/components/help-popup";
import { currencyCode } from "src/common/selectors/currency-selectors";
import {
  approximately,
  formatNumber,
  formatPercentage,
} from "src/common/utils/utils";
import { NO_VALUE } from "src/constants";
import { usePurchaseByQuantity } from "src/employee-portal/purchase/purchase-by-quantity-contex";
import TaxTabMenu from "src/employee-portal/purchase/steps/tax/tax-tab-menu";

interface TaxModalProps {
  onClose: () => void;
  quantity: number;
  totalPrice: number;
  totalDiscount: number;
  discountPercent: number;
  showNorwegianTaxExample: boolean;
  instrumentTermPlural: string;
  instrumentTermSingular: string;
  putDiscount: number;
}

enum TaxModalMenuIndex {
  NORWEGIAN = 0,
  NON_NORWEGIAN = 1,
}

const createLanguageOptions = () => [
  { key: "NOK", value: "NOK", flag: "no", text: "Norway" },
  { key: "SEK", value: "SEK", flag: "se", text: "Sweden" },
  { key: "DKK", value: "DKK", flag: "dk", text: "Denmark" },
  { key: "GBP", value: "GBP", flag: "gb", text: "Great Britain" },
  { key: "PLN", value: "PLN", flag: "pl", text: "Poland" },
];

const TaxModal: FunctionComponent<TaxModalProps> = ({
  onClose,
  showNorwegianTaxExample,
  quantity,
  totalPrice,
  totalDiscount,
  discountPercent,
  instrumentTermPlural,
  instrumentTermSingular,
  putDiscount,
}) => {
  // const [tabIndex, setTabIndex] = useState(TaxModalMenuIndex.NORWEGIAN);
  const [taxPercentInput, setTaxPercent] = useState<string>(null);
  const purchase = usePurchaseByQuantity();
  const [selectedCurrency, setCurrency] = useState<string>(null);
  const isNorwegianTaxpayer = selectedCurrency === "NOK";
  const currencyFactor = (
    purchase.currencies.filter(c => c.code === selectedCurrency)[0] || {
      factor: 1,
    }
  ).factor;

  const totalPutDiscount = isNorwegianTaxpayer ? quantity * putDiscount : 0;
  const totalDiscountCurrencyAdjusted = totalDiscount * currencyFactor;
  const totalPriceCurrencyAdjusted = totalPrice * currencyFactor;
  const taxFreeAmount = isNorwegianTaxpayer
    ? Math.min(5000, totalDiscountCurrencyAdjusted - totalPutDiscount)
    : 0;
  const taxableAmount = Math.max(
    totalDiscountCurrencyAdjusted - taxFreeAmount - totalPutDiscount,
    0
  );
  const taxPercentToUse = parseInt(taxPercentInput);
  const formatMessage = useFormatMessage();
  console.log(purchase);
  const renderIt = () => (
    <CurrencyFormatter>
      {formatter => (
        <>
          <div
            style={{
              width: "50%",
              margin: "0 auto",
            }}
          >
            <Form>
              <Form.Field>
                <div className="block-xs">
                  <label>
                    <Content id={"purchase.quantity.taxpopup.country.label"} />
                  </label>
                </div>
                <Dropdown
                  value={selectedCurrency}
                  fluid={true}
                  selection={true}
                  options={createLanguageOptions()}
                  onChange={(event, { value, name }) =>
                    setCurrency(value as string)
                  }
                />
              </Form.Field>
            </Form>
          </div>
          {selectedCurrency && (
            <>
              <div className="block-m" />
              <div className="purchase-narrow-width margin-center block-m">
                <Content
                  id={`purchase.quantity.taxpopup.${selectedCurrency}.body`}
                  values={{
                    quantity: formatNumber(quantity),
                    totalPrice: formatter.formatCurrencyWithCurrencyCode(
                      selectedCurrency
                    )(totalPriceCurrencyAdjusted, 2),
                    totalDiscount: formatter.formatCurrencyWithCurrencyCode(
                      selectedCurrency
                    )(totalDiscountCurrencyAdjusted, 2),
                    discountPercent: formatPercentage(discountPercent),
                    instrumentTermSingular,
                  }}
                />
                {/*{tabIndex === TaxModalMenuIndex.NON_NORWEGIAN && (*/}
                {/*  <>*/}
                {/*    <div className="block-s" />*/}
                {/*    <Message*/}
                {/*      info={true}*/}
                {/*      content={*/}
                {/*        <Content*/}
                {/*          id={"purchase.quantity.taxpopup.foreign.info.content"}*/}
                {/*        />*/}
                {/*      }*/}
                {/*    />*/}
                {/*  </>*/}
                {/*)}*/}
              </div>
              <div className="purchase-box">
                <div className="purchase-box-section">
                  {/*<div className={`purchase-box-section-item`}>*/}
                  {/*  <Content*/}
                  {/*    id={"purchase.quantity.summary.amount.label"}*/}
                  {/*    values={{ instrumentTypePlural: instrumentTermPlural }}*/}
                  {/*  />*/}
                  {/*  <span>{formatNumber(quantity)}</span>*/}
                  {/*</div>*/}
                  {/*<div className="block-xxs" />*/}
                  {/*<div className="purchase-box-section-item">*/}
                  {/*  <div>*/}
                  {/*    <Content id={"purchase.quantity.summary.totalprice.label"} />*/}
                  {/*    <HelpPopup*/}
                  {/*      textKey="purchase.quantity.summary.totalprice.popup"*/}
                  {/*      values={{*/}
                  {/*        instrumentTermPlural,*/}
                  {/*      }}*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*  <span>{formatter.formatCurrency(totalPrice, 2)}</span>*/}
                  {/*</div>*/}
                  {/*<div className="block-xxs" />*/}
                  <div className="purchase-box-section-item">
                    <div>
                      <Content
                        id={"purchase.quantity.summary.discount.label"}
                        values={{
                          instrumentTermPlural,
                          discountPercentage: formatPercentage(discountPercent),
                        }}
                      />
                      <HelpPopup
                        textKey="purchase.quantity.summary.discount.popup"
                        values={{
                          instrumentTermPlural,
                          discountPercentage: formatPercentage(discountPercent),
                        }}
                      />
                    </div>
                    <span>
                      {formatter.formatCurrencyWithCurrencyCode(
                        selectedCurrency
                      )(totalDiscountCurrencyAdjusted, 2)}
                    </span>
                  </div>
                  {isNorwegianTaxpayer ? (
                    <>
                      <div className="block-xxs" />
                      <div className="purchase-box-section-item">
                        <div>
                          <Content
                            id={"purchase.quantity.summary.putdiscount.label"}
                            values={{
                              instrumentTermPlural,
                            }}
                          />
                          <HelpPopup
                            textKey="purchase.quantity.summary.putdiscount.popup"
                            values={{
                              instrumentTermPlural,
                              putDiscountPerInstrument: formatter.formatCurrencyWithCurrencyCode(
                                selectedCurrency
                              )(putDiscount, 2),
                            }}
                          />
                        </div>
                        <span>
                          {formatter.formatCurrencyWithCurrencyCode(
                            selectedCurrency
                          )(totalPutDiscount, 2)}
                        </span>
                      </div>
                      <div className="block-xxs" />
                      <div className="purchase-box-section-item">
                        <div>
                          <Content
                            id={
                              "purchase.quantity.taxpopup.norwegian.taxfree.label"
                            }
                            values={{
                              instrumentTermPlural,
                            }}
                          />
                          <HelpPopup
                            textKey="purchase.quantity.taxpopup.norwegian.taxfree.popup"
                            values={{
                              instrumentTermPlural,
                            }}
                          />
                        </div>
                        <span>
                          {formatter.formatCurrencyWithCurrencyCode(
                            selectedCurrency
                          )(taxFreeAmount, 2)}
                        </span>
                      </div>
                    </>
                  ) : null}
                </div>
                {isNorwegianTaxpayer && (
                  <>
                    <div className="divider" />
                    <div className="purchase-box-section">
                      <div className="purchase-box-section-item">
                        <div>
                          <Content
                            id={
                              "purchase.quantity.taxpopup.norwegian.taxable.label"
                            }
                            values={{
                              instrumentTermPlural,
                            }}
                          />
                          <HelpPopup
                            textKey="purchase.quantity.taxpopup.norwegian.taxable.popup"
                            values={{
                              instrumentTermPlural,
                            }}
                          />
                        </div>
                        <span>
                          {approximately(
                            formatter.formatCurrencyWithCurrencyCode(
                              selectedCurrency
                            )(taxableAmount, 2)
                          )}
                        </span>
                      </div>
                      {/*<div className="block-xxs"/>
                        <div className="purchase-box-section-item">
                            <div>
                                <Content
                                    id={"purchase.quantity.taxpopup.norwegian.taxpercent.label"}
                                    values={{
                                      instrumentTermPlural,
                                    }}
                                />
                                <HelpPopup
                                    textKey="purchase.quantity.taxpopup.norwegian.taxpercent.popup"
                                    values={{
                                      instrumentTermPlural,
                                    }}
                                />
                            </div>
                            <div style={{width: 120}}>
                                <Input
                                    placeholder={formatMessage({
                                      id: "purchase.quantity.taxpopup.taxpercent.placeholder",
                                    })}
                                    type="number"
                                    value={taxPercentInput}
                                    onChange={event => setTaxPercent(event.target.value)}
                                    size={"mini"}
                                    className="text-right"
                                    icon="percent"
                                    min="0"
                                    fluid={true}
                                />
                            </div>
                        </div>*/}
                    </div>
                  </>
                )}
                {/*<div className="divider"/>*/}
                {/*<div className="purchase-box-section">
                        <div className="purchase-box-section-item">
                            <div>
                                <Content
                                    id={"purchase.quantity.taxpopup.norwegian.totaltax.label"}
                                    values={{
                                      instrumentTermPlural,
                                    }}
                                />
                                <HelpPopup
                                    textKey="purchase.quantity.taxpopup.norwegian.totaltax.popup"
                                    values={{
                                      instrumentTermPlural,
                                    }}
                                />
                            </div>
                            <span>
                  {isNaN(taxPercentToUse)
                    ? NO_VALUE
                    : approximately(
                      formatter.formatCurrencyWithCurrencyCode(
                        selectedCurrency
                      )((taxableAmount * taxPercentToUse) / 100, 2)
                    )}
                </span>
                        </div>
                    </div>*/}
              </div>
            </>
          )}
        </>
      )}
    </CurrencyFormatter>
  );
  return (
    <Modal
      open={true}
      onClose={onClose}
      closeIcon={true}
      closeOnDimmerClick={false}
    >
      <Modal.Header className="text-center">
        <Content id={"purchase.quantity.taxpopup.header"} />
      </Modal.Header>
      <Modal.Content>
        <div className="purchase-narrow-width margin-center block-m">
          <div className="block-m" />
          {/*<TaxTabMenu*/}
          {/*  nonNorwegian={renderIt()}*/}
          {/*  norwegian={renderIt()}*/}
          {/*  activeIndex={tabIndex}*/}
          {/*  setActiveIndex={setTabIndex}*/}
          {/*/>*/}
          {renderIt()}
        </div>
      </Modal.Content>
      <Modal.Actions style={{ textAlign: "center" }}>
        <Button color="green" onClick={onClose}>
          <Content id={"purchase.quantity.taxpopup.button"} />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default TaxModal;
