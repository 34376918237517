import React, { FunctionComponent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { postDocumentAcceptance as postDocumentAcceptanceAction } from "src/admin-portal/actions/document-acceptances-actions";
import Content from "src/admin-portal/texts/content";
import SpinnerInline from "src/common/components/spinner-inline.tsx";
import DocumentsEmptyState from "src/employee-portal/documents/view-documents/documents-empty-state";
import DocumentsList from "src/employee-portal/documents/view-documents/documents-list";
import { RootState } from "src/reducers/all-reducers";
import useDocumentsDocumentablesResolver from "src/resolvers/useDocumentsDocumentablesResolver";

const DocumentsPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { dateFormat, employeeAccountId } = useSelector((state: RootState) => ({
    dateFormat: state.dateFormat,
    employeeAccountId: state.user.decodedToken.sub.split("|")[1],
  }));
  const { data: records = [], loading } = useDocumentsDocumentablesResolver(
    {
      include: ["document", "documentAcceptances"],
      sort: "document.fileName",
      filter: {
        employeeAccountId,
      },
    },
    true
  );

  if (loading) {
    return <SpinnerInline active={true} />;
  }

  const postDocumentAcceptance = bindActionCreators(
    postDocumentAcceptanceAction,
    dispatch
  );

  return (
    <div className="main-content">
      <h1 className="block-m">
        <Content id={"documentsPage.title"} />
      </h1>
      {records.length > 0 ? (
        <DocumentsList
          documentsDocumentables={records}
          postDocumentAcceptance={postDocumentAcceptance}
          dateFormat={dateFormat}
        />
      ) : (
        <DocumentsEmptyState />
      )}
    </div>
  );
};

export default DocumentsPage;
