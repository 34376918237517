import React, { ChangeEventHandler, FunctionComponent } from "react";
import { Input, Label } from "semantic-ui-react";

interface CurrencyInputProps {
  error: boolean;
  value: string;
  onChange: ChangeEventHandler;
  currencyCode: string;
  placeholder?: string;
}

const CurrencyInput: FunctionComponent<CurrencyInputProps> = ({
  error,
  value,
  onChange,
  currencyCode,
  placeholder,
}) => (
  <Input error={error} labelPosition={"left"}>
    <Label basic={true}>{currencyCode}</Label>
    <input
      value={value}
      onChange={onChange}
      style={{ textAlign: "right" }}
      placeholder={placeholder || ""}
    />
  </Input>
);

export default CurrencyInput;
