import { Moment } from "moment";

export enum TurnoverMethod {
  NORMAL = "NORMAL",
  FLAT = "FLAT",
}

export interface ReportInputParams {
  dayBeforeOB: Moment;
  openingBalanceDate: Moment;
  closingBalanceDate: Moment;
  sharePriceOB: number;
  sharePriceCB: number;
  sharePriceYearOB?: number;
  annualTurnover: number;
  periodName: string;
  turnoverMethod: TurnoverMethod;
  dilutionAvgSharePricePeriod: number;
  dilutionAvgSharePriceYtd: number;
}
