import React, { Component } from "react";
import { connect, MapStateToProps } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { Window } from "src/common/data/data";
import "src/common/less/banner.less";
import { exerciseRoute } from "src/employee-portal/employee-portal-router";
import {
  currentExerciseWindow,
  canExerciseCurrentWindow,
  canReleaseCurrentWindow,
} from "src/employee-portal/employee-portal-selectors";
import { RootState } from "src/reducers/all-reducers";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface Props {
  currentWindow: Window;
  nextWindow: Window;
  dateFormat: DateFormatState;
  canExerciseCurrentWindow: boolean;
  canReleaseCurrentWindow: boolean;
}

interface State {}

class ExerciseBanner extends Component<Props, State> {
  public render() {
    const {
      currentWindow,
      nextWindow,
      dateFormat,
      canReleaseCurrentWindow,
      canExerciseCurrentWindow,
    } = this.props;

    if (!currentWindow) {
      if (nextWindow) {
        return (
          <div className="banner">
            <Icon
              name="info circle"
              inverted={true}
              className="banner-icon"
              size="big"
            />
            <span>
              <Content
                id={
                  nextWindow.is_release_process
                    ? "banner.exercise-release.next.window"
                    : "banner.exercise.next.window"
                }
                values={{
                  startDate: nextWindow.from.format(dateFormat.dateTimeFormat),
                  endDate: nextWindow.to.format(dateFormat.dateTimeFormat),
                }}
              />
            </span>
          </div>
        );
      }

      return null;
    }

    if (!canExerciseCurrentWindow && !canReleaseCurrentWindow) {
      return null;
    }

    return (
      <div className="banner">
        <Icon
          name="info circle"
          inverted={true}
          className="banner-icon"
          size="big"
        />
        <span>
          <Content
            id={
              currentWindow.is_release_process
                ? "banner.exercise-release.window"
                : "banner.exercise.window"
            }
            values={{
              endDate: currentWindow.to.format(dateFormat.dateTimeFormat),
            }}
          />
        </span>
        <Button
          className="banner-button"
          color={"green"}
          as={Link}
          to={exerciseRoute}
        >
          <Content id="banner.exercise.button" />
        </Button>
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<Props, {}, RootState> = (
  state
): Props => ({
  canExerciseCurrentWindow: canExerciseCurrentWindow(state),
  canReleaseCurrentWindow: canReleaseCurrentWindow(state),
  currentWindow: currentExerciseWindow(state),
  nextWindow: state.user.nextExerciseWindow,
  dateFormat: state.dateFormat,
});

export default connect(mapStateToProps)(ExerciseBanner);
