import Jsona from "jsona";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  deleteSharePrice,
  deleteSharePriceSucceeded,
  fetchAnnualVolatility,
  fetchAnnualVolatilitySucceeded,
  fetchAverageSharePrice,
  fetchAverageSharePriceSucceeded,
  fetchPrimarySharePriceTicker,
  fetchPrimarySharePriceTickerSucceeded,
  fetchSharePrices,
  fetchSharePricesSucceeded,
  postSharePrice,
  postSharePriceSucceeded,
  putPrimarySharePriceTicker,
  putPrimarySharePriceTickerSucceeded,
  fetchAnnualVolatilityFailed,
  fetchAnnualVolatilityBulk,
  fetchAnnualVolatilityBulkSucceeded,
  fetchAnnualVolatilityBulkFailed,
} from "src/admin-portal/actions/share-price-actions";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { buildURL } from "src/common/utils/utils";
import * as selectors from "src/selectors";

const sharePricesUrl = (
  tenantId,
  params: Api.V1.SharePriceQueryParams = { sort: "-date,-createdAt" }
) => buildURL(`/tenants/${tenantId}/stock-prices`, params);

const sharePriceTickersUrl = tickerId => `/share-price-tickers/${tickerId}`;

const deleteSharePriceUrl = sharePriceId => `/stock-prices/${sharePriceId}`;
const dataFormatter = new Jsona();

function* fetchPrimarySharePriceTickerRequested(
  action: ReturnType<typeof fetchPrimarySharePriceTicker>
) {
  try {
    const token = yield select(selectors.token);
    const tickerId = yield select(
      selectors.selectedTenantPrimarySharePriceTickerId
    );

    const response = yield call(() =>
      callApi(sharePriceTickersUrl(tickerId), token)
    );

    yield put(
      fetchPrimarySharePriceTickerSucceeded(
        dataFormatter.deserialize(response) as Api.V1.SharePriceTicker
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchPrimarySharePriceTicker() {
  yield takeEvery(
    fetchPrimarySharePriceTicker.getType(),
    fetchPrimarySharePriceTickerRequested
  );
}

function* putPrimarySharePriceTickerRequested(
  action: ReturnType<typeof putPrimarySharePriceTicker>
) {
  try {
    const token = yield select(selectors.token);
    const tickerId = yield select(
      selectors.selectedTenantPrimarySharePriceTickerId
    );
    const method = "PUT";

    const body = {
      data: {
        type: "sharePriceTickers",
        id: tickerId,
        attributes: {
          tickerType: action.payload.tickerType,
          tickerValue: action.payload.tickerValue,
        },
      },
    };

    const response = yield call(() =>
      callApi(sharePriceTickersUrl(action.payload.id), token, method, body)
    );

    yield put(
      putPrimarySharePriceTickerSucceeded(
        dataFormatter.deserialize(response) as Api.V1.SharePriceTicker
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPutPrimarySharePriceTicker() {
  yield takeEvery(
    putPrimarySharePriceTicker.getType(),
    putPrimarySharePriceTickerRequested
  );
}

function* postSharePriceRequested(action: ReturnType<typeof postSharePrice>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";

    const body = {
      data: {
        type: "stockPrices",
        attributes: action.payload,
      },
    };

    const response = yield call(() =>
      callApi(sharePricesUrl(tenantId), token, method, body)
    );

    yield put(
      postSharePriceSucceeded(
        dataFormatter.deserialize(response) as Api.V1.SharePrice
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPostSharePrice() {
  yield takeEvery(postSharePrice.getType(), postSharePriceRequested);
}

function* fetchSharePriceRequested(
  action: ReturnType<typeof fetchSharePrices>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const response = yield call(() =>
      callApi(sharePricesUrl(tenantId, action.payload), token)
    );
    yield put(
      fetchSharePricesSucceeded({
        date:
          action.payload && action.payload.filter && action.payload.filter.date,
        sharePrices: dataFormatter.deserialize(response) as Api.V1.SharePrice[],
      })
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchSharePrice() {
  yield takeEvery(fetchSharePrices.getType(), fetchSharePriceRequested);
}

function* deleteSharePriceRequested(
  action: ReturnType<typeof deleteSharePrice>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "DELETE";

    yield call(() =>
      callApi(deleteSharePriceUrl(action.payload), token, method)
    );
    yield put(deleteSharePriceSucceeded(action.payload));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchDeleteSharePrice() {
  yield takeEvery(deleteSharePrice.getType(), deleteSharePriceRequested);
}

const annualVolatilityUrl = (
  tenantId,
  params: Api.V1.AnnualVolatilityQueryParams
) => buildURL(`/tenants/${tenantId}/stock-prices/annual-volatility`, params);

function* fetchAnnualVolatilityRequested(
  action: ReturnType<typeof fetchAnnualVolatility>
) {
  const dates = [action.payload.startDate, action.payload.endDate].join("-");
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const response = yield call(() =>
      callApi(annualVolatilityUrl(tenantId, action.payload), token)
    );

    yield put(
      fetchAnnualVolatilitySucceeded({
        dates,
        data: {
          annualVolatility: response.data.annual_volatility,
          observations: response.data.observations,
          isTimePeriodShortened: response.data.is_time_period_shortened,
          removedFromCalculation: response.data.removed_from_calculation,
        },
      })
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      yield put(
        fetchAnnualVolatilityFailed({
          dates,
          message: `${e.status} – ${e.message}`,
        })
      );
      Raven.captureException(e);
    }
  }
}

export function* watchFetchAnnualVolatility() {
  yield takeEvery(
    fetchAnnualVolatility.getType(),
    fetchAnnualVolatilityRequested
  );
}
const annualVolatilityBulkUrl = tenantId =>
  buildURL(`/tenants/${tenantId}/stock-prices/bulk-annual-volatility`);

export function* watchFetchAnnualVolatilityBulk() {
  yield takeEvery(
    fetchAnnualVolatilityBulk.getType(),
    fetchAnnualVolatilityBulkRequested
  );
}

function* fetchAnnualVolatilityBulkRequested(
  action: ReturnType<typeof fetchAnnualVolatilityBulk>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const response = yield call(() =>
      callApi(annualVolatilityBulkUrl(tenantId), token, "POST", {
        queries: action.payload.map(request => ({
          timePeriodInYears: request.timePeriodInYears.toString(),
          start_date: request.startDate,
          end_date: request.endDate,
        })),
      })
    );

    const result = response.data.results.map(entry => ({
      dates: [entry.start_date, entry.end_date].join("-"),
      data: {
        annualVolatility: entry.annual_volatility,
        observations: entry.observations,
        isTimePeriodShortened: entry.is_time_period_shortened,
        removedFromCalculation: entry.removed_from_calculation,
      },
    }));

    yield put(fetchAnnualVolatilityBulkSucceeded(result));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      yield put(
        fetchAnnualVolatilityBulkFailed({
          message: `${e.status} – ${e.message}`,
        })
      );
      Raven.captureException(e);
    }
  }
}

const averageSharePriceUrl = (
  tenantId,
  params: Api.V1.AverageSharePriceQueryParams
) => buildURL(`/tenants/${tenantId}/stock-prices/averages`, params);

function* fetchAverageSharePriceRequested(
  action: ReturnType<typeof fetchAverageSharePrice>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const response = yield call(() =>
      callApi(averageSharePriceUrl(tenantId, action.payload), token)
    );
    yield put(
      fetchAverageSharePriceSucceeded({
        dates: [action.payload.startDate, action.payload.endDate].join("-"),
        averageSharePrices: response.data,
      })
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchAverageSharePrice() {
  yield takeEvery(
    fetchAverageSharePrice.getType(),
    fetchAverageSharePriceRequested
  );
}
