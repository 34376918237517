import React, { FunctionComponent, useState } from "react";

import { Icon, Transition } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { FAQItem } from "src/employee-portal/support/faq-list";
import "src/employee-portal/support/faq.less";

const FAQItem: FunctionComponent<FAQItem> = ({ questionKey, answerKey }) => {
  const [visible, setVisible] = useState(false);
  const caretIcon = visible ? "caret up" : "caret down";
  return (
    <div className="faq-item block-xs">
      <div className="faq-item-question" onClick={() => setVisible(!visible)}>
        <Content id={questionKey} />
        <div>
          <Icon name={caretIcon} className="position-absolute" />
        </div>
      </div>
      <Transition visible={visible} animation={"slide down"} duration={350}>
        <div className="faq-item-answer">
          <Content id={answerKey} />
        </div>
      </Transition>
    </div>
  );
};

export default FAQItem;
