import moment from "moment";
import React, { FunctionComponent, useState } from "react";

import { connect, MapStateToProps } from "react-redux";
import {
  Button,
  Dropdown,
  Form,
  Header,
  Input,
  Message,
  Modal,
} from "semantic-ui-react";

import { CREATE_TRANSACTIONS } from "src/admin-portal/awards/transaction/transaction-actions";
import { quantityTransactionTypes } from "src/admin-portal/tranches-page/utils";
import {
  apiShortDate,
  norwegianShortDateLongYear,
} from "src/common/utils/utils";
import { RootState } from "src/reducers/all-reducers";

interface PassedProps {
  tranches: Api.V1.VestingEvent[];
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = PassedProps & StateProps & DispatchProps;

const ReverseQuantityModal: FunctionComponent<Props> = ({
  createTransactions,
  isCreatingTransactions,
  isCreatingTransactionsSuccess,
  tranches,
}) => {
  const [effectiveDate, setEffectiveDate] = useState(
    moment().format(norwegianShortDateLongYear)
  );
  const [transactionType, setTransactionType] = useState<string>(null);
  const handleCreate = () => {
    const transactionDate = moment(
      effectiveDate,
      norwegianShortDateLongYear
    ).format(apiShortDate);
    createTransactions(
      activeTranchesTransactions(tranches, transactionDate, transactionType)
    );
  };
  const numAffectedTranches = activeTranches(tranches).length;
  const parsedDate = moment(effectiveDate, norwegianShortDateLongYear);
  const formInvalid = !parsedDate.isValid() || !transactionType;
  return (
    <Modal
      trigger={<Button basic={true}>Remove Quantity</Button>}
      closeIcon={true}
      size="small"
    >
      <Header content="Add a 'remove quantity' transaction for selected tranches" />
      <Modal.Content>
        {isCreatingTransactionsSuccess ? (
          <Message
            positive={true}
            header="Success!"
            content={`${numAffectedTranches} transactions created`}
          />
        ) : (
          <div>
            <p>
              A transaction of the selected type will be added for all selected
              tranches that has quantity > 0. This transaction will reduce the
              quantity of the tranche to 0.
            </p>
            <p>{`This will affect ${numAffectedTranches} tranches`}</p>
            <div className="block-s">
              <Dropdown
                placeholder="Select transaction type"
                value={transactionType}
                options={quantityTransactionTypes.map(transactionType => ({
                  key: transactionType,
                  text: transactionType,
                  value: transactionType,
                }))}
                onChange={(_, { value }) => setTransactionType(value as string)}
              />
            </div>
            <div>
              <strong>Effective date (dd.mm.yyyy)</strong>
              <Input
                placeholder="dd.mm.yyyy"
                fluid={true}
                value={effectiveDate}
                onChange={(e, { value }) => setEffectiveDate(value)}
              />
              <span>Date: {parsedDate.format(norwegianShortDateLongYear)}</span>
            </div>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary={true}
          content="Create transactions"
          loading={isCreatingTransactions}
          onClick={handleCreate}
          disabled={isCreatingTransactionsSuccess || formInvalid}
        />
      </Modal.Actions>
    </Modal>
  );
};

const activeTranchesTransactions = (
  tranches: Api.V1.VestingEvent[],
  transactionDate: string,
  transactionType: string
) =>
  activeTranches(tranches).map(tranche => ({
    transaction: {
      type: "transactions",
      transactionType,
      transactionDate,
      quantity: -1 * (tranche.quantity + tranche.expiredQuantity),
    },
    trancheId: tranche.id,
  }));

const activeTranches = (tranches: Api.V1.VestingEvent[]) =>
  tranches.filter(tranche => tranche.quantity + tranche.expiredQuantity > 0);

const mapStateToProps = (state: RootState) => ({
  isCreatingTransactions: state.award.isCreatingTransactions,
  isCreatingTransactionsSuccess: state.award.isCreatingTransactionsSuccess,
});

const mapDispatchToProps = dispatch => ({
  createTransactions: transactions =>
    dispatch({ type: CREATE_TRANSACTIONS, transactions }),
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(ReverseQuantityModal);
