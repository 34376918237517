import { createReducer } from "redux-act";

import {
  fetchInvoices,
  fetchInvoicesFailed,
  fetchInvoicesSucceeded,
} from "src/admin-portal/invoices/invoices-actions";

export interface InvoicesState {
  allInvoices: Api.V1.InvoiceInfo[];
  isFetching: boolean;
}

const initialState: InvoicesState = {
  allInvoices: null,
  isFetching: false,
};

export default createReducer(on => {
  on(fetchInvoices, state => ({ ...state, isFetching: true }));
  on(fetchInvoicesFailed, state => ({
    ...state,
    isFetching: false,
  }));
  on(fetchInvoicesSucceeded, (state, payload) => ({
    ...state,
    allInvoices: payload,
    isFetching: false,
  }));
}, initialState);
