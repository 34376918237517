import { replace } from "connected-react-router";
import React, { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Header, Icon, Message, Modal } from "semantic-ui-react";

import { postDocumentAcceptance } from "src/admin-portal/actions/document-acceptances-actions";
import Content from "src/admin-portal/texts/content";
import IfContentExists from "src/common/texts/if-content-exists";
import DocumentMetadataPreview from "src/employee-portal/documents/accept-documents/document-metadata-preview";
import { RootState } from "src/reducers/all-reducers";

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & DispatchProps;

class AcceptDocumentsModal extends Component<Props & InjectedIntlProps> {
  public state = {
    waitingForUserToOpenDocument: true,
  };

  public UNSAFE_componentWillReceiveProps(newProps: Props) {
    if (newProps.currentDocumentIndex !== this.props.currentDocumentIndex) {
      this.setState({ waitingForUserToOpenDocument: true });
    }
  }
  public render() {
    if (
      !this.props.documentFeatureActivated ||
      !this.props.documents ||
      this.props.documents.length === 0
    ) {
      return null;
    }

    const isAllDocumentsAccepted =
      this.props.currentDocumentIndex === this.props.documents.length;
    const multipleDocuments = this.props.documents.length > 1;
    const {
      intl: { formatMessage },
      isAcceptingDocument,
    } = this.props;
    const currentDocument = this.props.documents[
      this.props.currentDocumentIndex
    ];
    return (
      <Modal
        open={true}
        closeIcon={<Icon name="close" />}
        onClose={this.props.redirectToRoot}
      >
        <Header
          content={`${formatMessage({
            id: "acceptdocuments.modal.title",
          })}${
            currentDocument && currentDocument.document_header
              ? `: ${currentDocument.document_header}`
              : ""
          } ${
            multipleDocuments
              ? `(${this.props.currentDocumentIndex + 1} / ${
                  this.props.documents.length
                })`
              : ""
          }`}
          textAlign={"center"}
        />
        <Modal.Content>
          {isAllDocumentsAccepted ? (
            <div className="block-m text-center margin-top-m">
              <Icon name="check circle" color="green" size={"huge"} />
              <h2 className="block-s">
                <Content id={"acceptdocuments.modal.accepted.title"} />
              </h2>
              <p>
                <Content id={"acceptdocuments.modal.accepted.content"} />
              </p>
            </div>
          ) : (
            <div>
              <div className="block-m">
                <DocumentMetadataPreview
                  {...currentDocument}
                  downloadClicked={() =>
                    this.setState({
                      waitingForUserToOpenDocument: false,
                    })
                  }
                />
              </div>
              <IfContentExists id={`acceptdocuments.modal.info.header`}>
                <div className="block-xs">
                  <Message>
                    <Message.Header style={{ marginBottom: 10 }}>
                      <Content id="acceptdocuments.modal.info.header" />
                    </Message.Header>
                    <Message.Content>
                      <Content id="acceptdocuments.modal.info.content" />
                    </Message.Content>
                  </Message>
                </div>
              </IfContentExists>
              {(currentDocument.message_header ||
                currentDocument.message_body) && (
                <div className="block-xs">
                  <Message>
                    <Message.Header style={{ marginBottom: 10 }}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: currentDocument.message_header,
                        }}
                      />
                    </Message.Header>
                    <Message.Content>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: currentDocument.message_body,
                        }}
                      />
                    </Message.Content>
                  </Message>
                </div>
              )}
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          {isAllDocumentsAccepted ? (
            <div className="text-center">
              <Button color="green" onClick={this.props.redirectToRoot}>
                Proceed to the portal
              </Button>
            </div>
          ) : (
            <div className="text-center">
              <Button
                color={this.state.waitingForUserToOpenDocument ? null : "green"}
                disabled={this.state.waitingForUserToOpenDocument}
                onClick={this.props.postDocumentAcceptance.bind(this, {
                  documentsDocumentableId: this.props.documents[
                    this.props.currentDocumentIndex
                  ].documents_documentable_id,
                })}
              >
                <Icon
                  name={isAcceptingDocument ? "refresh" : "checkmark"}
                  loading={isAcceptingDocument}
                />{" "}
                {isAcceptingDocument
                  ? "Accepting..."
                  : "Accept document and proceed"}
              </Button>
            </div>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  companyName: state.user.tenant && state.user.tenant.name,
  currentDocumentIndex: state.user.currentDocumentIndex,
  documentFeatureActivated: !!state.features.documents,
  documents: state.user.documentsNeedingAcceptance,
  isAcceptingDocument: state.user.isAcceptingDocument,
  tenantId: state.user.tenant && state.user.tenant.id,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      postDocumentAcceptance,
      redirectToRoot: () => replace("/"),
    },
    dispatch
  ),
});

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl<Props>(AcceptDocumentsModal));
