import { useSelector } from "react-redux";

import useJsonApiQuery from "src/common/api/useJsonApiQuery";
import { tenantIdBothPortals } from "src/selectors";

const paramsWithTenantFilter = (
  params,
  tenantFilter
): Api.V1.QueryParams.FetchDocumentsDocumentables => ({
  ...params,
  filter: { ...params.filter, ...tenantFilter },
});

const useDocumentsDocumentablesResolver = (
  params?: Api.V1.QueryParams.FetchDocumentsDocumentables,
  fetchOnMount?: boolean
) => {
  const endpoint = "/documents-documentables";
  const tenantId = useSelector(tenantIdBothPortals);
  const tenantFilter = tenantId ? { "document.tenantId": tenantId } : {};

  const { data, meta, loading, refetch, refresh } = useJsonApiQuery<
    Api.V1.DocumentsDocumentable[],
    { recordCount: number },
    Api.V1.QueryParams.FetchDocumentsDocumentables
  >(
    fetchOnMount ? endpoint : null,
    paramsWithTenantFilter(params, tenantFilter)
  );

  return {
    data,
    meta,
    loading,
    refetch: (
      refetchParams: Api.V1.QueryParams.FetchDocumentsDocumentables
    ) => {
      refetch(endpoint, paramsWithTenantFilter(refetchParams, tenantFilter));
    },
    refresh,
  };
};

export default useDocumentsDocumentablesResolver;
