import { get } from "lodash";
import moment from "moment";
import React, { FunctionComponent } from "react";

import { ContentContext } from "src/common/components/content/content-contex";
import { useModal } from "src/common/hooks/useModal";
import { apiShortDate } from "src/common/utils/utils";
import ExerciseRow from "src/employee-portal/order/table/exercise-row";
import OrderPaymentInfoModal from "src/employee-portal/order/table/order-payment-info-modal";
import PurchaseRow from "src/employee-portal/order/table/purchase-row";
import UpdateOrderModal from "src/employee-portal/order/table/UpdateModal";
import useFieldVisibilityCheck from "src/employee-portal/order/table/UpdateModal/Form/UseFieldsVisibilityCheck";
import { DateFormatState } from "src/reducers/date-format-reducer";

export type Props = {
  order: Api.V1.Order;
  orderNumber: number;
  dateFormat: DateFormatState;
  showLoan: boolean;
  paymentAccount: PaymentAccount;
};

const OrderTableRow: FunctionComponent<Props> = props => {
  const { order, dateFormat, paymentAccount } = props;
  const { orderType, window } = order;

  const [
    isPaymentInfoModalVisible,
    showPaymentInfoModal,
    hidePaymentInfoModal,
  ] = useModal(false);

  const [
    isUpdateOrderModalVisible,
    showUpdateOrderModal,
    hideUpdateOrderModal,
  ] = useModal(false);

  const { isAnyFieldVisible } = useFieldVisibilityCheck(order);

  const paymentDeadline = window
    ? moment(window.paymentDeadline, apiShortDate)
    : null;

  const contentPrefixObj =
    orderType === "PURCHASE"
      ? {
          contentPrefix: get(
            order,
            "purchaseOrder.purchaseOpportunity.purchaseConfig.id"
          ),
        }
      : undefined;

  return (
    <ContentContext.Provider value={contentPrefixObj}>
      {isPaymentInfoModalVisible && (
        <OrderPaymentInfoModal
          paymentAccount={paymentAccount}
          onClose={hidePaymentInfoModal}
          paymentDeadline={
            paymentDeadline?.isValid()
              ? paymentDeadline.format(dateFormat.normalDateFormat)
              : null
          }
          payableAmount={"asdasdasdas"}
        />
      )}
      {isUpdateOrderModalVisible && (
        <UpdateOrderModal order={props.order} onClose={hideUpdateOrderModal} />
      )}

      {orderType === "EXERCISE" && (
        <ExerciseRow
          {...props}
          showUpdateOrderModal={showUpdateOrderModal}
          showPaymentInfoModal={showPaymentInfoModal}
          isUpdateOrderButtonVisible={isAnyFieldVisible}
        />
      )}
      {orderType === "PURCHASE" && (
        <PurchaseRow
          {...props}
          paymentDeadline={paymentDeadline}
          showPaymentInfoModal={showPaymentInfoModal}
          showUpdateOrderModal={showUpdateOrderModal}
          isUpdateOrderButtonVisible={isAnyFieldVisible}
        />
      )}
    </ContentContext.Provider>
  );
};

export default OrderTableRow;
