import React, { FunctionComponent } from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  Label,
  Message,
} from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import CurrencyFormatter, {
  CurrencyFormatterProps,
} from "src/common/components/currency/currency-formatter";
import CurrencyInput from "src/common/components/form/currency-input";
import HelpPopup from "src/common/components/help-popup";
import If from "src/common/components/if";
import {
  InstrumentType,
  useInstrumentTypeText,
} from "src/common/utils/text-mappings";
import { formatNumber, formatPercentage } from "src/common/utils/utils";
import { NO_VALUE } from "src/constants";
import { SharePrice } from "src/employee-portal/exercise/exercise-router";
import {
  LoanConfig,
  PurchaseCurrencyConfig,
} from "src/employee-portal/purchase/duck/purchase-selectors";
import LoanFormField from "src/employee-portal/purchase/steps/loan/loan-form-field";
import { PurchaseSetMinMax } from "src/employee-portal/purchase/steps/purchase-set-min-max";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface Props {
  cashAmount: string;
  sharePrice: SharePrice;
  discount: number;
  amountError: boolean;
  maximumCashAmount: number;
  minimumCashAmount: number;
  onChanged: (newValue: string) => void;
  goBack: () => void;
  goForward: () => void;
  instrument: InstrumentType;
  dateFormat: DateFormatState;
  loanConfig?: LoanConfig;
  loan: string;
  loanChanged: (newValue: string) => void;
  loanError: boolean;
  estMarketValue: number;
  estNumInstruments: number;
  enterCashAmountBeforeDiscount: boolean;
  investmentCurrencyCode: string;
  purchaseCurrencyConfigs?: PurchaseCurrencyConfig[];
  investmentCurrencyChanged: (code: string) => void;
  showInstrumentEstimation: boolean;
}

const CashAmount: FunctionComponent<Props> = ({
  cashAmount,
  amountError,
  minimumCashAmount,
  maximumCashAmount,
  sharePrice,
  discount,
  dateFormat,
  instrument,
  onChanged,
  goBack,
  goForward,
  loan,
  loanConfig,
  loanChanged,
  loanError,
  estMarketValue,
  estNumInstruments,
  enterCashAmountBeforeDiscount,
  investmentCurrencyCode,
  purchaseCurrencyConfigs,
  investmentCurrencyChanged,
  showInstrumentEstimation,
}) => {
  const formatMessage = useFormatMessage();
  const instrumentTerm = useInstrumentTypeText(instrument);
  const quantityNumber = parseInt(cashAmount, 10);
  const onChangeQuantity = event =>
    onChanged(event.target.value.replace(/\D/g, ""));

  const estimatedNumOfInstruments = amountError
    ? NO_VALUE
    : `≈ ${formatNumber(estNumInstruments)}`;
  const error = amountError || loanError;
  return (
    <div>
      <CurrencyFormatter code={investmentCurrencyCode}>
        {(formatter: CurrencyFormatterProps) => {
          const priceBeforeLoan = enterCashAmountBeforeDiscount
            ? parseInt(cashAmount) * (1 - discount || 0)
            : parseInt(cashAmount);
          const loanAmount = loanConfig ? parseInt(loan || "0") : 0;
          const actualLoan = Math.min(loanAmount, priceBeforeLoan);
          const toPay = Math.max(priceBeforeLoan - actualLoan, 0);
          const toPayFormatted = formatter.formatCurrency(toPay, 0);
          const estDiscount = enterCashAmountBeforeDiscount
            ? parseInt(cashAmount) - priceBeforeLoan
            : estMarketValue - parseInt(cashAmount);
          const discountFormatted = `≈ ${formatter.formatCurrency(
            estDiscount,
            0
          )}`;
          return (
            <>
              <div className="block-l">
                <h2 className="text-center block-m">
                  <Content id="purchase.cash.title" />
                </h2>
                <p className="purchase-narrow-width margin-center block-m">
                  <Content
                    id="purchase.cash.body"
                    values={{
                      sharePrice: formatter.formatCurrency(
                        sharePrice.sharePrice,
                        2
                      ),
                      discount: formatPercentage(discount, 0),
                      maximumCashAmount: formatter.formatCurrency(
                        maximumCashAmount,
                        0
                      ),
                      minimumCashAmount: formatter.formatCurrency(
                        minimumCashAmount,
                        0
                      ),
                      instrumentTermPlural: instrumentTerm.plural,
                      instrumentTermSingular: instrumentTerm.singular,
                    }}
                  />
                </p>
                {error && (
                  <div className="purchase-narrow-width margin-center block-m">
                    <Message
                      header={formatMessage({
                        id: "purchase.cash.errMessageHeader",
                      })}
                      error={true}
                      list={[
                        amountError &&
                          formatMessage(
                            { id: "purchase.cash.errMessageContent" },
                            {
                              maximumCashAmount: formatter.formatCurrency(
                                maximumCashAmount,
                                0
                              ),
                              minimumCashAmount: formatter.formatCurrency(
                                minimumCashAmount,
                                0
                              ),
                            }
                          ),
                        loanError &&
                          formatMessage(
                            { id: "purchase.quantity.loan.errMessageContent" },
                            {
                              minimumQuantity: formatNumber(loanConfig.min),
                              maximumQuantity: formatNumber(loanConfig.max),
                            }
                          ),
                      ]}
                    />
                  </div>
                )}
                <div className="center-center">
                  <div className="purchase-box">
                    <div className="purchase-box-form">
                      <Form>
                        {purchaseCurrencyConfigs &&
                          purchaseCurrencyConfigs.length > 1 && (
                            <div className="purchase-box-form-input">
                              <Form.Field>
                                <label>
                                  {formatMessage({
                                    id: "purchase.cash.selectcurrency.label",
                                  })}
                                </label>
                                <div style={{ maxWidth: 100 }}>
                                  <Dropdown
                                    placeholder={formatMessage({
                                      id:
                                        "purchase.cash.selectcurrency.placeholder",
                                    })}
                                    fluid={true}
                                    selection={true}
                                    options={purchaseCurrencyConfigs.map(
                                      config => ({
                                        key: config.currencyCode,
                                        value: config.currencyCode,
                                        text: config.currencyCode,
                                      })
                                    )}
                                    value={investmentCurrencyCode || undefined}
                                    onChange={(event, { value }) =>
                                      investmentCurrencyChanged(
                                        value.toString()
                                      )
                                    }
                                  />
                                </div>
                              </Form.Field>
                            </div>
                          )}
                        <div className="purchase-box-form-input">
                          <Form.Field>
                            <label>
                              <Content
                                id={"purchase.cash.label"}
                                values={{
                                  maximumCashAmount: formatter.formatCurrency(
                                    maximumCashAmount,
                                    0
                                  ),
                                  minimumCashAmount: formatter.formatCurrency(
                                    minimumCashAmount,
                                    0
                                  ),
                                }}
                              />
                            </label>
                            <CurrencyInput
                              error={amountError}
                              currencyCode={formatter.currencyCode}
                              value={
                                cashAmount
                                  ? formatNumber(parseInt(cashAmount))
                                  : cashAmount
                              }
                              onChange={onChangeQuantity}
                            />
                          </Form.Field>
                          <PurchaseSetMinMax
                            min={minimumCashAmount}
                            max={maximumCashAmount}
                            onChange={onChanged}
                          />
                        </div>
                        <LoanFormField
                          formatter={formatter}
                          loanChanged={loanChanged}
                          loan={loan}
                          loanError={loanError}
                          loanConfig={loanConfig}
                        />
                      </Form>
                    </div>
                    {showInstrumentEstimation && (
                      <>
                        <div className="divider" />
                        <div className="purchase-box-section">
                          <div className="purchase-box-section-item">
                            <div>
                              <HelpPopup
                                textKey="purchase.cash.estimatedShares.popup"
                                values={{
                                  instrumentTermPlural: instrumentTerm.plural,
                                }}
                              />
                              <strong>
                                <Content
                                  id={"purchase.cash.estimatedShares.label"}
                                  values={{
                                    instrumentTermPlural: instrumentTerm.plural,
                                  }}
                                />
                              </strong>
                            </div>
                            <strong>{estimatedNumOfInstruments}</strong>
                          </div>
                        </div>
                      </>
                    )}
                    <If condition={!!discount || !!loanConfig}>
                      <div className="divider" />
                      <div className="purchase-box-section">
                        <If condition={!!discount}>
                          <div className="purchase-box-section-item block-xxs">
                            <div>
                              <HelpPopup
                                textKey="purchase.cash.marketValue.popup"
                                values={{
                                  instrumentTermPlural: instrumentTerm.plural,
                                }}
                              />
                              <Content id={"purchase.cash.marketValue.label"} />
                            </div>
                            <span>
                              {amountError
                                ? NO_VALUE
                                : `≈ ${formatter.formatCurrency(
                                    Math.floor(estMarketValue),
                                    0
                                  )}`}
                            </span>
                          </div>
                          <div
                            className={`purchase-box-section-item ${
                              loanConfig ? "block-xxs" : ""
                            }`}
                          >
                            <div>
                              <HelpPopup
                                textKey="purchase.cash.discount.popup"
                                values={{
                                  instrumentTermPlural: instrumentTerm.plural,
                                  discountPercentage: formatPercentage(
                                    discount
                                  ),
                                }}
                              />
                              <Content
                                id={"purchase.cash.discount.label"}
                                values={{
                                  instrumentTermPlural: instrumentTerm.plural,
                                  discountPercentage: formatPercentage(
                                    discount
                                  ),
                                }}
                              />
                            </div>
                            <span>
                              {amountError ? NO_VALUE : discountFormatted}
                            </span>
                          </div>
                        </If>
                        {loanConfig && (
                          <div className="purchase-box-section-item">
                            <div>
                              <HelpPopup
                                textKey="purchase.quantity.summary.loan.popup"
                                values={{
                                  instrumentTermPlural: instrumentTerm.plural,
                                }}
                              />
                              <Content
                                id={"purchase.quantity.summary.loan.label"}
                                values={{
                                  instrumentTermPlural: instrumentTerm.plural,
                                }}
                              />
                            </div>
                            <span>
                              {loanError
                                ? NO_VALUE
                                : formatter.formatCurrency(actualLoan, 0)}
                            </span>
                          </div>
                        )}
                      </div>
                    </If>
                    <div className="divider" />
                    <div className="purchase-box-section">
                      <div className="purchase-box-section-item">
                        <div>
                          <strong>
                            <Content
                              id={"purchase.quantity.totalPurchasePrice"}
                              values={{
                                instrumentTermPlural: instrumentTerm.plural,
                              }}
                            />
                          </strong>
                        </div>
                        <strong>{error ? NO_VALUE : toPayFormatted}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page-action-container text-center">
                <Button
                  size="big"
                  onClick={goBack}
                  content={formatMessage({ id: "global.backBtnTxt" })}
                />
                <Button
                  positive={true}
                  content={formatMessage({ id: "global.nextBtnTxt" })}
                  icon="right arrow"
                  labelPosition="right"
                  size="big"
                  disabled={error || cashAmount === ""}
                  onClick={goForward}
                />
              </div>
            </>
          );
        }}
      </CurrencyFormatter>
    </div>
  );
};

export default CashAmount;
