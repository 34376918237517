import { createAction } from "redux-act";

import { Tenant } from "src/admin-portal/tenant/tenant-reducer";

export const fetchTenants = createAction("FETCH_TENANTS");

export const fetchTenantsSucceeded = createAction(
  "FETCH_TENANTS_SUCCEEDED",
  (payload: Tenant[]) => payload
);

export const postTenant = createAction(
  "POST_TENANT",
  (payload: Tenant) => payload
);

export const postTenantSucceeded = createAction(
  "POST_TENANT_SUCCEEDED",
  (payload: Tenant) => payload
);

export const putTenant = createAction(
  "PUT_TENANT",
  (payload: Tenant) => payload
);

export const putTenantSucceeded = createAction(
  "PUT_TENANT_SUCCEEDED",
  (payload: Tenant) => payload
);

export const deleteTenant = createAction(
  "DELETE_TENANT",
  (payload: string) => payload
);

export const deleteTenantSucceeded = createAction(
  "DELETE_TENANT_SUCCEEDED",
  (payload: Tenant["id"]) => payload
);

export const selectTenant = createAction("SELECT_TENANT");

export const fetchLiveTenantsOverview = createAction(
  "FETCH_LIVE_TENANTS_OVERVIEW",
  (payload: string[]) => payload
);

export const fetchLiveTenantsOverviewSucceeded = createAction(
  "FETCH_LIVE_TENANTS_OVERVIEW_SUCCEEDED",
  (payload: Api.V1.TenantOverview[]) => payload
);
