import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import {
  Icon,
  Menu,
  SemanticICONS,
  Dimmer,
  Transition,
} from "semantic-ui-react";

import { useFormatMessage } from "src/admin-portal/texts/utils";
import { MenuConfig } from "src/employee-portal/menu/useMenuConfig";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  menuItems: MenuConfig[];
}

const MenuModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  menuItems,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <Dimmer
      active={isOpen}
      onClickOutside={onClose}
      className="employee-menu-mobile-dimmer"
    >
      <Transition
        visible={isOpen}
        animation="fly left"
        duration={{ hide: 0, show: 500 }}
      >
        <Menu className="employee-menu-mobile-menu" secondary vertical>
          {menuItems.map(menuItem => (
            <Menu.Item
              key={menuItem.to}
              className={menuItem.textKey === "menu.logout" ? "logout" : ""}
              active={location.pathname.startsWith(menuItem.to)}
              as={menuItem.textKey !== "menu.logout" ? Link : "a"}
              to={menuItem.to}
              href={menuItem.to}
            >
              <Icon name={menuItem.icon as SemanticICONS} />
              <span>{formatMessage({ id: menuItem.textKey })}</span>
            </Menu.Item>
          ))}
        </Menu>
      </Transition>
    </Dimmer>
  );
};

export default MenuModal;
