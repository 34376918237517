import { flatMap } from "lodash";
import { createReducer } from "redux-act";

import { calcStrike } from "src/admin-portal/orders/helpers";
import {
  fetchTenantOrder,
  fetchTenantOrderSucceeded,
  putTenantOrder,
} from "src/admin-portal/orders/ordersActions";
import { selectTenant } from "src/admin-portal/tenant/tenant-actions";
import { employeeName, sumNumbers, sum } from "src/common/utils/utils";

export interface ExerciseOrderLineRow {
  id: string;
  orderIdWithLine: string;
  exerciseType: string;
  exercisedQuantity: number;
  strike: number;
  totalStrike: number;
  employeeName: string;
  employeeEmail: string;
  windowId: string;
  orderId: string;
  orderType: Api.V1.Order["orderType"];
  orderCreatedAt: Api.V1.Order["createdAt"];
  orderStatus: Api.V1.Order["status"];
  orderTaxPercentage: Api.V1.ExerciseOrder["taxPercentage"];
  orderShareDepositoryAccount: Api.V1.ExerciseOrder["shareDepositoryAccount"];
  orderShareDepositoryBank: Api.V1.ExerciseOrder["shareDepositoryBank"];
  orderShareDepositoryClearingCode: Api.V1.ExerciseOrder["shareDepositoryClearingCode"];
  orderShareDepositoryContact: Api.V1.ExerciseOrder["shareDepositoryContact"];
  orderShareDepositoryDescription: Api.V1.ExerciseOrder["shareDepositoryDescription"];
  orderBankAccount: Api.V1.ExerciseOrder["bankAccount"];
  orderBicSwift: Api.V1.ExerciseOrder["bicSwiftNumber"];
  orderTotalQuantity: number;
  orderSharesQuantity: number;
  orderTotalStrike: number;
}

export const exerciseOrderLineRows = (
  orders: Api.V1.Order[]
): ExerciseOrderLineRow[] => {
  return flatMap(orders, order => {
    const orderTotalStrike = order.exerciseOrder.exerciseOrderLines
      .map(calcStrike)
      .reduce(sumNumbers, 0);
    const orderTotalQuantity = order.exerciseOrder.exerciseOrderLines.reduce(
      sum("exerciseQuantity"),
      0
    );
    const orderSharesQuantity = order.exerciseOrder.exerciseOrderLines.reduce(
      sum("sharesQuantity"),
      0
    );
    return order.exerciseOrder.exerciseOrderLines.map(
      (exerciseOrderLine, orderLineIndex) => ({
        id: exerciseOrderLine.id,
        orderIdWithLine: `${order.id} - ${orderLineIndex + 1}`,
        exerciseType: order.exerciseOrder.exerciseType,
        employeeName: employeeName(order.employee),
        employeeEmail: order.employee.email,
        windowId: order.windowId,
        exercisedQuantity: exerciseOrderLine.exerciseQuantity,
        strike: parseFloat(exerciseOrderLine.strike),
        totalStrike: calcStrike(exerciseOrderLine),
        orderId: order.id,
        orderType: order.orderType,
        orderCreatedAt: order.createdAt,
        orderStatus: order.status,
        orderTaxPercentage: order.exerciseOrder.taxPercentage,
        orderShareDepositoryAccount: order.exerciseOrder.shareDepositoryAccount,
        orderShareDepositoryBank: order.exerciseOrder.shareDepositoryBank,
        orderShareDepositoryClearingCode:
          order.exerciseOrder.shareDepositoryClearingCode,
        orderShareDepositoryContact: order.exerciseOrder.shareDepositoryContact,
        orderShareDepositoryDescription:
          order.exerciseOrder.shareDepositoryDescription,
        orderBankAccount: order.exerciseOrder.bankAccount,
        orderBicSwift: order.exerciseOrder.bicSwiftNumber,
        orderTotalQuantity,
        orderSharesQuantity,
        orderTotalStrike: orderTotalStrike,
      })
    );
  });
};

export interface OrderState {
  allExerciseOrders: Api.V1.Order[];
  allPurchaseOrders: Api.V1.Order[];
  exerciseOrders: Api.V1.Order[];
  purchaseOrders: Api.V1.Order[];
  order: Api.V1.Order;
  isFetching: boolean;
  exerciseOrdersMeta: { recordCount: number };
  purchaseOrdersMeta: { recordCount: number };
}

const initialState: OrderState = {
  allExerciseOrders: null,
  allPurchaseOrders: null,
  exerciseOrders: null,
  purchaseOrders: null,
  exerciseOrdersMeta: null,
  purchaseOrdersMeta: null,
  isFetching: false,
  order: null,
};

const setIsFetching = (state: OrderState) => ({
  ...state,
  isFetching: true,
});

export default createReducer(on => {
  on(selectTenant, () => initialState);
  on(fetchTenantOrder, setIsFetching);
  on(putTenantOrder, setIsFetching);

  on(fetchTenantOrderSucceeded, (state, payload) => ({
    ...state,
    isFetching: false,
    order: payload,
  }));
}, initialState);
