import moment, { Moment } from "moment";
import React, { FunctionComponent, useState } from "react";
import { connect } from "react-redux";
import { match, Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import { Button, Icon, Popup, Table } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import CurrencyConversionView from "src/common/components/currency/currency-conversion-view";
import CurrencyFormatter, {
  CurrencyFormatterProps,
} from "src/common/components/currency/currency-formatter";
import ShowItemsCheckBox from "src/common/components/show-items-checkbox";
import SpinnerInline from "src/common/components/spinner-inline.tsx";
import { sortMultipleLevels } from "src/common/utils/sort";
import {
  capitalizeFirstLetter,
  formatNumber,
  sumNumbers,
} from "src/common/utils/utils";
import { userCurrencyCode } from "src/employee-portal/employee-portal-selectors";
import {
  awardGain,
  FlatAward,
  IndividualInstrumentState,
} from "src/employee-portal/instrument-page/instruments-reducer";
import OptionLikeTable from "src/employee-portal/instrument-page/option-like/optionlike-table";
import PSULikeTable from "src/employee-portal/instrument-page/psu-like/psu-like-table";
import RSALikeTable from "src/employee-portal/instrument-page/rsa-like/rsa-like-table";
import RSULikeTable from "src/employee-portal/instrument-page/rsu-like/rsu-like-table";
import { RootState } from "src/reducers/all-reducers";
import "src/employee-portal/instrument-page/instruments.less";
import ShareLikeTable from "src/employee-portal/instrument-page/share-like/share-like-table";
import ConvertibleLoanLikeTable from "src/employee-portal/instrument-page/convertible-loan-like/convertible-loan-like-table";

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & {
  match: match<{}>;
};

export const vestedAwards = (award: FlatAward): boolean =>
  vestedBeforeOrSame(moment())(award);
export const vestedBeforeOrSame = (date: Moment) => (
  award: FlatAward
): boolean => {
  return award.vestedDate.isSameOrBefore(date, "days");
};
export const removeExpiredAwards = (award: FlatAward): boolean =>
  !hasExpired(award);
export const hasExpired = (award: FlatAward): boolean =>
  moment().isAfter(award.expiryDate, "days");
export const hasEmployeePaysSocSec = (award: FlatAward): boolean =>
  award.employeePaysSocSec;

const IndividualInstrumentSummaryRow: FunctionComponent<{
  instrument: IndividualInstrumentState;
  title: string | React.ReactElement;
  linkTo: string;
  showPerformanceColumns: boolean;
  showSharesQuantity: boolean;
  showOptionsVariableCost: boolean;
}> = ({
  instrument,
  title,
  linkTo,
  showPerformanceColumns,
  showSharesQuantity,
  showOptionsVariableCost,
}) => (
  <Table.Row>
    <Table.Cell>{title}</Table.Cell>
    <Table.Cell textAlign="right">
      {formatNumber(instrument.instrumentQuantity)}
    </Table.Cell>
    {showSharesQuantity && (
      <Table.Cell textAlign="right">
        {formatNumber(instrument.sharesQuantity)}
      </Table.Cell>
    )}
    <Table.Cell textAlign="right">
      <CurrencyConversionView value={instrument.gain.totalGain} decimals={0} />
    </Table.Cell>
    {showPerformanceColumns && (
      <>
        <Table.Cell textAlign="right">Not Applicable</Table.Cell>
        <Table.Cell textAlign="right">Not Applicable</Table.Cell>
      </>
    )}
    <Table.Cell textAlign="right">
      {formatNumber(instrument.vestedInstrumentQuantity)}
    </Table.Cell>
    {showSharesQuantity && (
      <Table.Cell textAlign="right">
        {formatNumber(instrument.vestedSharesQuantity)}
      </Table.Cell>
    )}
    {showOptionsVariableCost && (
      <CurrencyFormatter>
        {(formatter: CurrencyFormatterProps) => (
          <Table.Cell textAlign="right">
            {formatter.formatCurrency(instrument.totalVariableCost)}
          </Table.Cell>
        )}
      </CurrencyFormatter>
    )}
    <Table.Cell textAlign="right">
      <CurrencyConversionView value={instrument.gain.vestedGain} decimals={0} />
    </Table.Cell>
    <Table.Cell>
      <Button as={Link} to={linkTo} size="medium" primary={true} basic={true}>
        <Content id="global.viewDetailsBtn" />
      </Button>
    </Table.Cell>
  </Table.Row>
);

const ConvertibleLoanSummaryRow: FunctionComponent<{
  instrument: IndividualInstrumentState;
  title: string | React.ReactElement;
  linkTo: string;
  showPerformanceColumns: boolean;
  showSharesQuantity: boolean;
  showOptionsVariableCost: boolean;
}> = ({
  instrument,
  title,
  linkTo,
  showPerformanceColumns,
  showSharesQuantity,
  showOptionsVariableCost,
}) => (
  <Table.Row>
    <Table.Cell>{title}</Table.Cell>
    <Table.Cell textAlign="right">
      {formatNumber(instrument.instrumentQuantity)}
    </Table.Cell>
    {showSharesQuantity && (
      <Table.Cell textAlign="right">
        {formatNumber(instrument.sharesQuantity)}
      </Table.Cell>
    )}
    <Table.Cell textAlign="right">
      <CurrencyConversionView value={instrument.gain.totalGain} decimals={0} />
    </Table.Cell>
    {showPerformanceColumns && (
      <>
        <Table.Cell textAlign="right">
          <Content id={"global.notApplicable"} />
        </Table.Cell>
        <Table.Cell textAlign="right">
          <Content id={"global.notApplicable"} />
        </Table.Cell>
      </>
    )}
    <Table.Cell textAlign="right">
      <Content id={"global.notApplicable"} />
    </Table.Cell>
    {showSharesQuantity && (
      <Table.Cell textAlign="right">
        <Content id={"global.notApplicable"} />
      </Table.Cell>
    )}
    {showOptionsVariableCost && (
      <Table.Cell textAlign="right">
        <Content id={"global.notApplicable"} />
      </Table.Cell>
    )}
    <Table.Cell textAlign="right">
      <Content id={"global.notApplicable"} />
    </Table.Cell>
    <Table.Cell>
      <Button as={Link} to={linkTo} size="medium" primary={true} basic={true}>
        <Content id="global.viewDetailsBtn" />
      </Button>
    </Table.Cell>
  </Table.Row>
);

const PSUSummaryRow: FunctionComponent<{
  instrument: IndividualInstrumentState;
  title: string | React.ReactElement;
  linkTo: string;
  showSharesQuantity: boolean;
  showOptionsVariableCost: boolean;
}> = ({ instrument, title, linkTo, showSharesQuantity }) => (
  <Table.Row>
    <Table.Cell>{title}</Table.Cell>
    <Table.Cell textAlign="right">
      {formatNumber(instrument.performanceAdjustedQuantity)}
    </Table.Cell>
    <Table.Cell textAlign="right">
      <CurrencyConversionView
        value={instrument.performanceAdjustedGain}
        decimals={0}
      />
    </Table.Cell>
    {/*<Table.Cell textAlign="right">*/}
    {/*  {formatNumber(instrument.instrumentQuantity)}*/}
    {/*</Table.Cell>*/}
    {/*{showSharesQuantity && (*/}
    {/*  <Table.Cell textAlign="right">*/}
    {/*    {formatNumber(instrument.sharesQuantity)}*/}
    {/*  </Table.Cell>*/}
    {/*)}*/}
    {/*<Table.Cell textAlign="right">*/}
    {/*  <CurrencyConversionView value={instrument.gain.totalGain} decimals={0}/>*/}
    {/*</Table.Cell>*/}
    <Table.Cell textAlign="right">
      {formatNumber(instrument.vestedInstrumentQuantity)}
    </Table.Cell>
    {showSharesQuantity && (
      <Table.Cell textAlign="right">
        {formatNumber(instrument.vestedSharesQuantity)}
      </Table.Cell>
    )}
    <Table.Cell textAlign="right">
      <CurrencyConversionView value={instrument.gain.vestedGain} decimals={0} />
    </Table.Cell>
    <Table.Cell>
      <Button
        content="View details"
        as={Link}
        to={linkTo}
        size="medium"
        primary={true}
        basic={true}
      />
    </Table.Cell>
  </Table.Row>
);

const textKeys = {
  summaryAll: {
    header: "instruments.all.details.header",
    description: "instruments.all.details.description",
  },
  option: {
    header: "instruments.option.details.header",
    description: "instruments.option.details.description",
  },
  subcriptionRights: {
    header: "instruments.subcriptionrights.details.header",
    description: "instruments.subcriptionrights.details.description",
  },
  warrant: {
    header: "instruments.warrant.details.header",
    description: "instruments.warrant.details.description",
  },
  rsu: {
    header: "instruments.rsu.details.header",
    description: "instruments.rsu.details.description",
  },
  psu: {
    header: "instruments.psu.details.header",
    description: "instruments.psu.details.description",
  },
  fund: {
    header: "instruments.fund.details.header",
    description: "instruments.fund.details.description",
  },
  cash: {
    header: "instruments.cash.details.header",
    description: "instruments.cash.details.description",
  },
  rsa: {
    header: "instruments.rsa.details.header",
    description: "instruments.rsa.details.description",
  },
  shares: {
    header: "instruments.share.details.header",
    description: "instruments.share.details.description",
  },
  convertibleLoan: {
    header: "instruments.convertibleloan.details.header",
    description: "instruments.convertibleloan.details.description",
  },
};

const HeaderAndDescription: FunctionComponent<{
  header: string;
  description: string;
}> = ({ header, description }) => (
  <div className="instruments-header block-m">
    <h1 className="block-m">
      <Content id={header} />
    </h1>
    <p className="text-content text-content-center block-m">
      <Content id={description} />
    </p>
  </div>
);

const sorted = (awards: FlatAward[]): FlatAward[] =>
  sortMultipleLevels(awards)("grantDate", "vestedDate");

const InstrumentsPage: FunctionComponent<Props & StateProps> = props => {
  const {
    match,
    option,
    rsu,
    psu,
    rsa,
    warrant,
    funds,
    cash,
    subscriptionRights,
    convertibleLoan,
    share,
    awardGain,
    currencyCode,
    sharePrice,
    dateFormat,
    tenantId,
  } = props;

  const initiallyShowExpired = window.location.href.includes("/rsa");
  const [showExpired, setShowExpired] = useState(initiallyShowExpired);
  const formatMessage = useFormatMessage();

  if (!props.sharePrice) {
    return <SpinnerInline active={true} />;
  }

  const instruments = [
    rsu,
    option,
    psu,
    rsa,
    warrant,
    subscriptionRights,
    convertibleLoan,
    share,
  ];
  const totalGainIncludingMaxPSUgain = instruments
    .map(a =>
      typeof a.performanceAdjustedGain === "number"
        ? a.performanceAdjustedGain
        : a.gain.totalGain
    )
    .reduce(sumNumbers, 0);
  const totalQuantityIncPerfAdjusted = instruments
    .map(a => a.performanceAdjustedQuantity || a.instrumentQuantity)
    .reduce(sumNumbers, 0);
  const totalSharesQuantityIncPerfAdjusted = instruments
    .map(a => a.performanceAdjustedSharesQuantity || a.sharesQuantity)
    .reduce(sumNumbers, 0);

  const vestedGainIncludingMaxPSUgain = instruments
    .map(a => a.gain.vestedGain)
    .reduce(sumNumbers, 0);
  const vestedQuantityIncludingMaxPSUgain = instruments
    .map(a => a.vestedInstrumentQuantity)
    .reduce(sumNumbers, 0);
  const vestedSharesQuantityIncludingMaxPSUgain = instruments
    .map(a => a.vestedSharesQuantity)
    .reduce(sumNumbers, 0);

  // const showPerformanceColumns = psu.instrumentQuantity > 0;
  const showPerformanceColumns = false;
  const showSharesQuantity = instruments.some(
    i => i.sharesQuantity !== i.instrumentQuantity
  );

  const showOptionsVariableCost = option.employeePaysSocSecQuantity > 0;

  const showExpiredToggle = () => setShowExpired(!showExpired);
  return (
    <Switch>
      <Route
        path={`${match.path}/options`}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.option} />
            <div className="instruments-tables-container">
              <ShowItemsCheckBox
                checked={showExpired}
                clickHandler={showExpiredToggle}
                title={formatMessage({ id: "showExpired.label" })}
              />
              {option.allAwards.length > 0 && (
                <OptionLikeTable
                  options={sorted(option.allAwards)}
                  sharePriceToday={sharePrice.sharePrice}
                  showExpired={showExpired}
                  dateFormat={dateFormat}
                  awardGain={awardGain}
                  totalGain={option.gain.totalGain}
                  overrideCurrency={currencyCode}
                />
              )}
            </div>
          </div>
        )}
      />

      <Route
        path={`${match.path}/subscription_rights`}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.subcriptionRights} />
            <div className="instruments-tables-container">
              <ShowItemsCheckBox
                checked={showExpired}
                clickHandler={showExpiredToggle}
                title={formatMessage({ id: "showExpired.label" })}
              />
              {subscriptionRights.allAwards.length > 0 && (
                <OptionLikeTable
                  options={sorted(subscriptionRights.allAwards)}
                  sharePriceToday={sharePrice.sharePrice}
                  showExpired={showExpired}
                  dateFormat={dateFormat}
                  awardGain={awardGain}
                  totalGain={subscriptionRights.gain.totalGain}
                  overrideCurrency={currencyCode}
                />
              )}
            </div>
          </div>
        )}
      />

      <Route
        path={`${match.path}/warrants`}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.warrant} />
            <div className="instruments-tables-container">
              <ShowItemsCheckBox
                checked={showExpired}
                clickHandler={showExpiredToggle}
                title={formatMessage({ id: "showExpired.label" })}
              />
              {warrant.allAwards.length > 0 && (
                <OptionLikeTable
                  options={sorted(warrant.allAwards)}
                  showPurchasePrice={true}
                  sharePriceToday={sharePrice.sharePrice}
                  showExpired={showExpired}
                  dateFormat={dateFormat}
                  awardGain={awardGain}
                  totalGain={warrant.gain.totalGain}
                  overrideCurrency={currencyCode}
                />
              )}
            </div>
          </div>
        )}
      />

      <Route
        path={`${match.path}/rsus`}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.rsu} />
            <div className="instruments-tables-container">
              <ShowItemsCheckBox
                checked={showExpired}
                clickHandler={showExpiredToggle}
                title={formatMessage({ id: "showExpired.label" })}
              />
              {rsu.allAwards.length > 0 && (
                <RSULikeTable
                  rsus={sorted(rsu.allAwards)}
                  sharePriceToday={sharePrice.sharePrice}
                  showExpired={showExpired}
                  totalGain={rsu.gain.totalGain}
                  totalQuantity={rsu.sharesQuantity}
                  isReleasedAtVesting={false}
                  dateFormat={dateFormat}
                  awardGain={awardGain}
                  tenantId={tenantId}
                />
              )}
            </div>
          </div>
        )}
      />

      <Route
        path={`${match.path}/psus`}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.psu} />
            <div className="instruments-tables-container">
              <ShowItemsCheckBox
                checked={showExpired}
                clickHandler={showExpiredToggle}
                title={formatMessage({ id: "showReleased.label" })}
              />
              {psu.allAwards.length > 0 && (
                <PSULikeTable
                  psus={sorted(psu.allAwards)}
                  sharePriceToday={sharePrice.sharePrice}
                  showExpired={showExpired}
                  totalGain={psu.gain.totalGain}
                  totalQuantity={psu.sharesQuantity}
                  isReleasedAtVesting={false}
                  dateFormat={dateFormat}
                  performanceAdjustedGain={psu.performanceAdjustedGain}
                  performanceAdjustedQuantity={psu.performanceAdjustedQuantity}
                  overrideCurrency={currencyCode}
                  awardGain={awardGain}
                  tenantId={tenantId}
                />
              )}
            </div>
          </div>
        )}
      />

      <Route
        path={`${match.path}/funds`}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.fund} />
            <div className="instruments-tables-container">
              <ShowItemsCheckBox
                checked={showExpired}
                clickHandler={showExpiredToggle}
                title={formatMessage({ id: "showReleased.label" })}
              />
              {funds.allAwards.length > 0 && (
                <RSULikeTable
                  rsus={sorted(funds.allAwards)}
                  sharePriceToday={sharePrice.sharePrice}
                  showExpired={showExpired}
                  totalGain={funds.gain.totalGain}
                  totalQuantity={funds.instrumentQuantity}
                  isReleasedAtVesting={true}
                  dateFormat={dateFormat}
                  awardGain={awardGain}
                />
              )}
            </div>
          </div>
        )}
      />

      <Route
        path={`${match.path}/cash`}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.cash} />
            <div className="instruments-tables-container">
              <ShowItemsCheckBox
                checked={showExpired}
                clickHandler={showExpiredToggle}
                title={formatMessage({ id: "showReleased.label" })}
              />
              {cash.allAwards.length > 0 && (
                <RSULikeTable
                  rsus={sorted(cash.allAwards)}
                  sharePriceToday={1}
                  showExpired={showExpired}
                  totalGain={cash.gain.totalGain}
                  totalQuantity={cash.instrumentQuantity}
                  isReleasedAtVesting={true}
                  dateFormat={dateFormat}
                  awardGain={awardGain}
                />
              )}
            </div>
          </div>
        )}
      />

      <Route
        path={`${match.path}/rsas`}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.rsa} />
            <div className="instruments-tables-container">
              <ShowItemsCheckBox
                checked={showExpired}
                clickHandler={showExpiredToggle}
                title={formatMessage({ id: "showReleased.label" })}
              />
              {rsa.allAwards.length > 0 && (
                <RSALikeTable
                  rsas={sorted(rsa.allAwards)}
                  sharePriceToday={sharePrice.sharePrice}
                  showExpired={showExpired}
                  totalGain={rsa.gain.totalGain}
                  totalQuantity={rsa.instrumentQuantity}
                  dateFormat={dateFormat}
                  awardGain={awardGain}
                />
              )}
            </div>
          </div>
        )}
      />

      <Route
        path={`${match.path}/shares`}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.shares} />
            <div className="instruments-tables-container">
              {share.allAwards.length > 0 && (
                <ShareLikeTable
                  shares={sorted(share.allAwards)}
                  sharePriceToday={sharePrice.sharePrice}
                  totalGain={share.gain.totalGain}
                  totalQuantity={share.instrumentQuantity}
                  dateFormat={dateFormat}
                  awardGain={awardGain}
                />
              )}
            </div>
          </div>
        )}
      />

      <Route
        path={`${match.path}/convertible-loan`}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.convertibleLoan} />
            <div className="instruments-tables-container width-limit-small">
              {convertibleLoan.allAwards.length > 0 && (
                <ConvertibleLoanLikeTable
                  convertibles={sorted(convertibleLoan.allAwards)}
                  sharePriceToday={sharePrice.sharePrice}
                  totalGain={convertibleLoan.gain.totalGain}
                  totalQuantity={convertibleLoan.instrumentQuantity}
                  dateFormat={dateFormat}
                  awardGain={awardGain}
                />
              )}
            </div>
          </div>
        )}
      />

      <Route
        path={match.path}
        render={() => (
          <div className="main-content">
            <HeaderAndDescription {...textKeys.summaryAll} />
            <CurrencyFormatter>
              {(formatter: CurrencyFormatterProps) => (
                <div className="instruments-tables-container">
                  <Table celled={true} unstackable={true} textAlign="center">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          <Content id="global.tableLabel.instrumentType" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {" "}
                          <Content id="global.tableLabel.totalQuantity" />
                        </Table.HeaderCell>
                        {showSharesQuantity && (
                          <Table.HeaderCell textAlign="right">
                            <Content id="global.tableLabel.totalSharesQuantity" />
                          </Table.HeaderCell>
                        )}
                        <Table.HeaderCell>
                          <Content id="global.tableLabel.totalGain" />
                        </Table.HeaderCell>
                        {showPerformanceColumns && (
                          <>
                            <Table.HeaderCell>
                              <Content id="global.tableLabel.totalperfadjquantity" />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                              <Content id="global.tableLabel.totalperfadjgain" />
                            </Table.HeaderCell>
                          </>
                        )}
                        <Table.HeaderCell>
                          <Content id="global.tableLabel.vestedQuantity" />
                        </Table.HeaderCell>
                        {showOptionsVariableCost && (
                          <Table.HeaderCell textAlign="right">
                            <Content id="global.tableLabel.totalVariableCost" />
                          </Table.HeaderCell>
                        )}
                        {showSharesQuantity && (
                          <Table.HeaderCell textAlign="right">
                            <Content id="global.tableLabel.vestedSharesQuantity" />
                          </Table.HeaderCell>
                        )}
                        <Table.HeaderCell>
                          <Content id="global.tableLabel.vestedGain" />
                        </Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {option.allAwards.length > 0 && (
                        <IndividualInstrumentSummaryRow
                          instrument={option}
                          title={capitalizeFirstLetter(
                            formatMessage({ id: "instrument.option.plural" })
                          )}
                          linkTo={"/instruments/options"}
                          showPerformanceColumns={showPerformanceColumns}
                          showSharesQuantity={showSharesQuantity}
                          showOptionsVariableCost={showOptionsVariableCost}
                        />
                      )}
                      {subscriptionRights.allAwards.length > 0 && (
                        <IndividualInstrumentSummaryRow
                          instrument={subscriptionRights}
                          title={capitalizeFirstLetter(
                            formatMessage({
                              id: "instrument.subscription_rights.plural",
                            })
                          )}
                          linkTo={"/instruments/subscription_rights"}
                          showPerformanceColumns={showPerformanceColumns}
                          showSharesQuantity={showSharesQuantity}
                          showOptionsVariableCost={showOptionsVariableCost}
                        />
                      )}
                      {warrant.allAwards.length > 0 && (
                        <IndividualInstrumentSummaryRow
                          instrument={warrant}
                          title={capitalizeFirstLetter(
                            formatMessage({ id: "instrument.warrant.plural" })
                          )}
                          linkTo={"/instruments/warrants"}
                          showPerformanceColumns={showPerformanceColumns}
                          showSharesQuantity={showSharesQuantity}
                          showOptionsVariableCost={showOptionsVariableCost}
                        />
                      )}
                      {rsu.allAwards.length > 0 && (
                        <IndividualInstrumentSummaryRow
                          instrument={rsu}
                          title={capitalizeFirstLetter(
                            formatMessage({ id: "instrument.rsu.plural" })
                          )}
                          linkTo={"/instruments/rsus"}
                          showPerformanceColumns={showPerformanceColumns}
                          showSharesQuantity={showSharesQuantity}
                          showOptionsVariableCost={showOptionsVariableCost}
                        />
                      )}
                      {psu.allAwards.length > 0 && (
                        <PSUSummaryRow
                          instrument={psu}
                          title={capitalizeFirstLetter(
                            formatMessage({ id: "instrument.psu.plural" })
                          )}
                          linkTo={"/instruments/psus"}
                          showSharesQuantity={showSharesQuantity}
                          showOptionsVariableCost={showOptionsVariableCost}
                        />
                      )}
                      {rsa.allAwards.length > 0 && (
                        <IndividualInstrumentSummaryRow
                          instrument={rsa}
                          title={capitalizeFirstLetter(
                            formatMessage({ id: "instrument.rsa.plural" })
                          )}
                          linkTo={"/instruments/rsas"}
                          showPerformanceColumns={showPerformanceColumns}
                          showSharesQuantity={showSharesQuantity}
                          showOptionsVariableCost={showOptionsVariableCost}
                        />
                      )}
                      {funds.allAwards.length > 0 && (
                        <IndividualInstrumentSummaryRow
                          instrument={funds}
                          title={"Deferred Fund Units"}
                          linkTo={"/instruments/funds"}
                          showPerformanceColumns={showPerformanceColumns}
                          showSharesQuantity={showSharesQuantity}
                          showOptionsVariableCost={showOptionsVariableCost}
                        />
                      )}
                      {cash.allAwards.length > 0 && (
                        <IndividualInstrumentSummaryRow
                          instrument={cash}
                          title={"Deferred Cash"}
                          linkTo={"/instruments/cash"}
                          showPerformanceColumns={showPerformanceColumns}
                          showSharesQuantity={showSharesQuantity}
                          showOptionsVariableCost={showOptionsVariableCost}
                        />
                      )}
                      {convertibleLoan.allAwards.length > 0 && (
                        <ConvertibleLoanSummaryRow
                          instrument={convertibleLoan}
                          title={capitalizeFirstLetter(
                            formatMessage({
                              id: "instrument.convertible_loan.plural",
                            })
                          )}
                          linkTo={"/instruments/convertible-loan"}
                          showPerformanceColumns={showPerformanceColumns}
                          showSharesQuantity={showSharesQuantity}
                          showOptionsVariableCost={showOptionsVariableCost}
                        />
                      )}
                      {share.allAwards.length > 0 && (
                        <IndividualInstrumentSummaryRow
                          instrument={share}
                          title={capitalizeFirstLetter(
                            formatMessage({ id: "instrument.share.plural" })
                          )}
                          linkTo={"/instruments/shares"}
                          showPerformanceColumns={showPerformanceColumns}
                          showSharesQuantity={showSharesQuantity}
                          showOptionsVariableCost={showOptionsVariableCost}
                        />
                      )}
                    </Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell>
                          <Content id="global.tableLabel.sum" />
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          {formatNumber(totalQuantityIncPerfAdjusted)}
                        </Table.HeaderCell>
                        {showSharesQuantity && (
                          <Table.HeaderCell textAlign="right">
                            {formatNumber(totalSharesQuantityIncPerfAdjusted)}
                          </Table.HeaderCell>
                        )}
                        <Table.HeaderCell textAlign="right">
                          <CurrencyConversionView
                            value={totalGainIncludingMaxPSUgain}
                            decimals={0}
                          />
                        </Table.HeaderCell>
                        {showPerformanceColumns && (
                          <>
                            <Table.HeaderCell textAlign="right">
                              {formatNumber(psu.performanceAdjustedQuantity)}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="right">
                              <CurrencyConversionView
                                value={psu.performanceAdjustedGain}
                                decimals={0}
                              />
                            </Table.HeaderCell>
                          </>
                        )}
                        <Table.HeaderCell textAlign="right">
                          {formatNumber(vestedQuantityIncludingMaxPSUgain)}
                        </Table.HeaderCell>
                        {showSharesQuantity && (
                          <Table.HeaderCell textAlign="right">
                            {formatNumber(
                              vestedSharesQuantityIncludingMaxPSUgain
                            )}
                          </Table.HeaderCell>
                        )}
                        {showOptionsVariableCost && (
                          <Table.HeaderCell textAlign="right">
                            {formatter.formatCurrency(option.totalVariableCost)}
                          </Table.HeaderCell>
                        )}
                        <Table.HeaderCell textAlign="right">
                          <CurrencyConversionView
                            value={vestedGainIncludingMaxPSUgain}
                            decimals={0}
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </div>
              )}
            </CurrencyFormatter>
          </div>
        )}
      />
    </Switch>
  );
};

export const PerformanceYesPopup = () => (
  <Popup trigger={<Icon name="question circle outline" />}>
    <Popup.Content>
      <Content id="performace.information.question" />
    </Popup.Content>
  </Popup>
);

const mapStateToProps = (state: RootState) => ({
  convertibleLoan: state.instrument.convertibleLoan,
  share: state.instrument.share,
  warrant: state.instrument.warrant,
  rsa: state.instrument.rsa,
  rsu: state.instrument.rsu,
  psu: state.instrument.psu,
  option: state.instrument.option,
  funds: state.instrument.fundsState,
  cash: state.instrument.cashState,
  subscriptionRights: state.instrument.subscriptionRightState,
  totalGain: state.instrument.totalGain,
  totalVestedGain: state.instrument.totalVestedGain,
  totalQuantity: state.instrument.totalQuantity,
  totalVestedQuantity: state.instrument.totalVestedQuantity,
  isFetchingWelcomeData: state.instrument.isFetchingWelcomeData,
  sharePrice: state.instrument.sharePrice,
  dateFormat: state.dateFormat,
  tenantId: state.user.tenant?.id,
  awardGain,
  currencyCode: userCurrencyCode(state),
});

export default connect<StateProps>(mapStateToProps)(InstrumentsPage);
