import moment from "moment";
import React, { FunctionComponent } from "react";

import { useFormatMessage } from "src/admin-portal/texts/utils";
import CurrencyFormatter, {
  CurrencyFormatterProps,
} from "src/common/components/currency/currency-formatter";
import OptioTable, { TableData } from "src/common/components/table/optio-table";
import Text from "src/common/text/text";
import { formatNumber } from "src/common/utils/utils";
import { NO_VALUE } from "src/constants";
import {
  hasExpired,
  removeExpiredAwards,
} from "src/employee-portal/instrument-page/instruments-page";
import {
  awardGain,
  AwardGainFunction,
  FlatAward,
  hasCapOnGain,
  hasPerformanceCriteria,
  hasPurchasePrice,
  hasSharesConversion,
  hasVariableCost,
} from "src/employee-portal/instrument-page/instruments-reducer";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface Props {
  options: FlatAward[];
  sharePriceToday: number;
  showPurchasePrice?: boolean;
  showExpired: boolean;
  dateFormat: DateFormatState;
  awardGain: AwardGainFunction;
  overrideCurrency?: string;
  totalGain: number;
}

function numberTwoDecimals(formatter: CurrencyFormatterProps) {
  return value =>
    typeof value === "number" ? formatter.formatCurrency(value, 2) : NO_VALUE;
}

const createTableData = (
  formatMessage: ReturnType<typeof useFormatMessage>,
  normalDateFormat: string,
  formatter: CurrencyFormatterProps,
  sharePrice: number
) => (data: FlatAward[]): TableData<FlatAward> => {
  const showExercisedQuantity = data.some(o => o.exercisedQuantity !== 0);
  const showCapOnGain = data.some(hasCapOnGain);
  const showPerformance = data.some(hasPerformanceCriteria);
  const showPurchasePrice = data.some(hasPurchasePrice);
  const showSharesQuantity = data.some(hasSharesConversion);
  const showVariableCosts = data.some(hasVariableCost);

  return [
    {
      title: <Text textKey={"instruments.program.label"} />,
      value: obj => obj.programName,
      displayString: value => value,
      displayComponent: ({ programName, subProgramName }) => (
        <div>
          {" "}
          <div>{programName}</div>
          <div>{subProgramName}</div>
        </div>
      ),
    },
    {
      title: <Text textKey={"instruments.grant.label"} />,
      value: ({ grantDate }) => moment(grantDate).format(normalDateFormat),
      displayString: value => value,
    },
    {
      title: <Text textKey={"instruments.vesting.label"} />,
      value: ({ vestedDate }) => moment(vestedDate).format(normalDateFormat),
      displayString: value => value,
    },
    {
      title: <Text textKey={"instruments.expiry.label"} />,
      value: ({ expiryDate }) => moment(expiryDate).format(normalDateFormat),
      displayString: value => value,
    },
    {
      title: <Text textKey={"instruments.instrumentquantity.label"} />,
      value: ({ originalQuantity, quantity }) => originalQuantity || quantity,
      displayString: value =>
        typeof value === "number" ? formatNumber(value) : NO_VALUE,
      sumInFooter: true,
      displayOptions: { textAlign: "right" },
    },
    {
      title: <Text textKey={"instruments.sharesquantity.label"} />,
      value: ({ quantity }) => quantity,
      displayString: value => formatNumber(value),
      sumInFooter: true,
      displayOptions: { textAlign: "right" },
      hidden: !showSharesQuantity,
    },
    {
      title: <Text textKey={"instruments.exercisequantity.label"} />,
      value: ({ exercisedQuantity }) => -1 * exercisedQuantity,
      displayString: value => formatNumber(value),
      sumInFooter: true,
      hidden: !showExercisedQuantity,
      displayOptions: { textAlign: "right" },
    },
    {
      title: <Text textKey={"instruments.purchaseprice.label"} />,
      value: ({ purchasePrice }) => purchasePrice,
      displayString: numberTwoDecimals(formatter),
      hidden: !showPurchasePrice,
      displayOptions: { textAlign: "right" },
    },
    {
      title: <Text textKey={"instruments.strike.label"} />,
      value: ({ strike }) => strike,
      displayString: numberTwoDecimals(formatter),
      displayOptions: {
        textAlign: "right",
        showCurrencyConversion: true,
        currencyConversionDecimals: 2,
      },
    },
    {
      title: <Text textKey={"instruments.sharesstrike.label"} />,
      value: ({ sharesConversionFactor, strike, strikeConversionFactor }) =>
        strikeConversionFactor
          ? strike * strikeConversionFactor
          : strike / sharesConversionFactor,
      displayString: numberTwoDecimals(formatter),
      displayOptions: { textAlign: "right" },
      hidden: !showSharesQuantity,
    },
    {
      title: (
        <Text
          textKey={"instruments.totalstrike.label"}
          popupKey={"instruments.totalstrike.popup"}
        />
      ),
      value: award =>
        hasExpired(award) ? 0 : awardGain(sharePrice, award).costOfExercise,
      displayString: numberTwoDecimals(formatter),
      sumInFooter: true,
      displayOptions: { textAlign: "right", showCurrencyConversion: true },
    },
    {
      title: (
        <Text
          textKey={"instruments.capongain.label"}
          popupKey={"instruments.capongain.popup"}
        />
      ),
      value: ({ capOnGain }) => capOnGain,
      displayString: numberTwoDecimals(formatter),
      hidden: !showCapOnGain,
      displayOptions: {
        textAlign: "right",
        showCurrencyConversion: true,
        currencyConversionDecimals: 2,
      },
    },
    {
      title: (
        <Text
          textKey={"instruments.variablecost.label"}
          popupKey={"instruments.variablecost.popup"}
        />
      ),
      value: award =>
        ((sharePrice - award.strike) * award.socSecRate) /
        (1 + award.socSecRate),
      displayString: numberTwoDecimals(formatter),
      displayOptions: {
        textAlign: "right",
        showCurrencyConversion: true,
        currencyConversionDecimals: 2,
      },
      hidden: !showVariableCosts,
    },
    {
      title: <Text textKey={"instruments.totalvariablecost.label"} />,
      value: award =>
        hasExpired(award)
          ? 0
          : (((sharePrice - award.strike) * award.socSecRate) /
              (1 + award.socSecRate)) *
            award.quantity,
      displayString: value => formatter.formatCurrency(value, 0),
      displayOptions: { textAlign: "right", showCurrencyConversion: true },
      hidden: !showVariableCosts,
    },
    {
      title: <Text textKey={"instruments.totalvalue.label"} />,
      value: award =>
        hasExpired(award) ? 0 : awardGain(sharePrice, award).gain,
      displayString: value => formatter.formatCurrency(value, 0),
      sumInFooter: true,
      displayOptions: { textAlign: "right", showCurrencyConversion: true },
    },
    {
      title: (
        <Text
          textKey={"instruments.performance.label"}
          popupKey={"performace.information.question"}
        />
      ),
      value: award => award.performance,
      displayString: value => (value ? "Yes" : "No"),
      hidden: !showPerformance,
    },
  ];
};

const OptionLikeTable: FunctionComponent<Props> = ({
  options,
  sharePriceToday,
  showExpired,
  dateFormat,
}) => {
  const shownOptions = showExpired
    ? options
    : options.filter(removeExpiredAwards);
  const formatMessage = useFormatMessage();
  return (
    <div className="sideways-scrollable block-l">
      <CurrencyFormatter>
        {(formatter: CurrencyFormatterProps) => (
          <OptioTable
            mapper={createTableData(
              formatMessage,
              dateFormat.normalDateFormat,
              formatter,
              sharePriceToday
            )}
            data={shownOptions}
            showFooter={true}
            sortable={false}
          />
        )}
      </CurrencyFormatter>
    </div>
  );
};

export default OptionLikeTable;
