export const ADD_SUBPROGRAM_SUCCEEDED = "ADD_SUBPROGRAM_SUCCEEDED";
export const ADD_SUBPROGRAM_FAILED = "ADD_SUBPROGRAM_FAILED";
export const ADD_SUBPROGRAM = "ADD_SUBPROGRAM";

export const POST_SUBPROGRAM_SUCCEEDED = "POST_SUBPROGRAM_SUCCEEDED";
export const POST_SUBPROGRAM_FAILED = "POST_SUBPROGRAM_FAILED";
export const POST_SUBPROGRAM = "POST_SUBPROGRAM";

export const FETCH_SUBPROGRAMS_SUCCEEDED = "FETCH_SUBPROGRAMS_SUCCEEDED";
export const FETCH_SUBPROGRAM_FAILED = "FETCH_SUBPROGRAMS_FAILED";
export const FETCH_SUBPROGRAMS = "FETCH_SUBPROGRAMS";

export const ADD_VESTING_SUCCEEDED = "ADD_VESTING_SUCCEEDED";
export const ADD_VESTING_FAILED = "ADD_VESTING_FAILED";
export const ADD_VESTING = "ADD_VESTING";

export const PUT_SUBPROGRAM_SUCCEEDED = "PUT_SUBPROGRAM_SUCCEEDED";
export const PUT_SUBPROGRAM_FAILED = "PUT_SUBPROGRAM_FAILED";
export const PUT_SUBPROGRAM = "PUT_SUBPROGRAM";

export const DELETE_SUBPROGRAM_SUCCEEDED = "DELETE_SUBPROGRAM_SUCCEEDED";
export const DELETE_SUBPROGRAM_FAILED = "DELETE_SUBPROGRAM_FAILED";
export const DELETE_SUBPROGRAM = "DELETE_SUBPROGRAM";

export const GENERATE_SUBPROGRAM_AGREEMENTS = "GENERATE_SUBPROGRAM_AGREEMENTS";
export const GENERATE_SUBPROGRAM_AGREEMENTS_SUCCEEDED =
  "GENERATE_SUBPROGRAM_AGREEMENTS_SUCCEEDED";
