import { get } from "lodash";
import moment, { Moment } from "moment";
import React, { FunctionComponent } from "react";
import { Icon, Popup, Table } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import ClickableLink from "src/common/components/clickable-link";
import CurrencyFormatter, {
  CurrencyFormatterProps,
} from "src/common/components/currency/currency-formatter";
import { formatNumber } from "src/common/utils/utils";
import { PurchaseType, NO_VALUE } from "src/constants";
import { purchaseTypeTextKeys } from "src/employee-portal/order/order-page";
import OrderStatusView from "src/employee-portal/order/order-status";
import { Props as OrderProps } from "src/employee-portal/order/table/order-table-row";

interface Props extends OrderProps {
  paymentDeadline: Moment;
  showPaymentInfoModal: () => void;
  showUpdateOrderModal: () => void;
  isUpdateOrderButtonVisible: boolean;
}

const PurchaseRow: FunctionComponent<Props> = ({
  order,
  orderNumber,
  dateFormat,
  showLoan,
  showPaymentInfoModal,
  showUpdateOrderModal,
  isUpdateOrderButtonVisible,
  paymentDeadline,
}) => {
  const { purchaseOrder, createdAt, status } = order;

  const formatMessage = useFormatMessage();

  const instrumentTermPlural = formatMessage({
    id: `instrument.${purchaseOrder.instrumentType}.plural`,
  });
  const isInstrumentQuantity =
    get(purchaseOrder, "purchaseOpportunity.purchaseConfig.purchaseType") ===
    PurchaseType.PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY;

  const orderAmount = isInstrumentQuantity
    ? purchaseOrder.purchaseAmount *
      parseFloat(purchaseOrder.purchaseOpportunity.purchaseConfig.price)
    : parseFloat(purchaseOrder.purchaseCashAmount);

  const loan = purchaseOrder.loan;
  const payableAmount = Math.max(
    0,
    purchaseOrder.payableAmount
      ? parseFloat(purchaseOrder.payableAmount)
      : orderAmount - parseFloat(loan || "0")
  );

  return (
    <CurrencyFormatter code={purchaseOrder.currencyCode}>
      {(formatter: CurrencyFormatterProps) => {
        const orderAmountFormatted = isInstrumentQuantity
          ? formatNumber(purchaseOrder.purchaseAmount)
          : formatter.formatCurrency(
              parseFloat(purchaseOrder.purchaseCashAmount),
              0
            );

        const payableFormatted = formatter.formatCurrency(payableAmount, 2);
        const showPaymentInfoLink = status === "CREATED" && payableAmount > 0;

        return (
          <Table.Row>
            <Table.Cell>{orderNumber}</Table.Cell>
            <Table.Cell>
              {moment(createdAt).format(dateFormat.dateTimeFormat)}
            </Table.Cell>
            <Table.Cell>
              {formatMessage(
                {
                  id: purchaseTypeTextKeys(purchaseOrder.instrumentType),
                },
                { instrumentTermPlural }
              )}
            </Table.Cell>
            <Table.Cell singleLine={true}>{orderAmountFormatted}</Table.Cell>
            {showLoan && (
              <>
                <Table.Cell singleLine={true}>
                  {typeof loan === "number" && loan !== 0
                    ? formatter.formatCurrency(loan, 2)
                    : NO_VALUE}
                </Table.Cell>
                <Table.Cell singleLine={true}>{payableFormatted}</Table.Cell>
              </>
            )}
            <Table.Cell singleLine={true}>
              {purchaseOrder.shareDepositoryAccount ||
                purchaseOrder.shareDepositoryNorwegian ||
                NO_VALUE}
            </Table.Cell>
            <Table.Cell singleLine={true}>
              <OrderStatusView status={status} />{" "}
              <Popup
                trigger={<Icon name="question circle outline" />}
                content={<Content id={`orders.${status}.popup.text`} />}
              />
            </Table.Cell>
            <Table.Cell singleLine={true}>
              {showPaymentInfoLink && paymentDeadline ? (
                <ClickableLink onClick={showPaymentInfoModal}>
                  <Content id={"orders.table.paymentinfo.show"} />
                </ClickableLink>
              ) : (
                NO_VALUE
              )}
            </Table.Cell>

            <Table.Cell singleLine={true}>
              {isUpdateOrderButtonVisible ? (
                <ClickableLink onClick={showUpdateOrderModal}>
                  <Content id={"order.employeeUpdateModal.button"} />
                </ClickableLink>
              ) : (
                NO_VALUE
              )}
            </Table.Cell>
          </Table.Row>
        );
      }}
    </CurrencyFormatter>
  );
};

export default PurchaseRow;
