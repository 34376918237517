import React, { useContext } from "react";

type ContentContextType = {
  contentPrefix: string;
};

export const ContentContext = React.createContext<ContentContextType>(
  {} as ContentContextType
);

export const useContentContext = () => {
  return useContext(ContentContext);
};
