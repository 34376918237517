import { createAction } from "redux-act";

import { PaginationLinks } from "src/common/utils/pagination";

export interface DailySummaries {
  [pageNumber: string]: Api.V1.DailySummary[];
}

export const fetchDailySummaries = createAction(
  "FETCH_DAILY_SUMMARIES",
  (payload: { pageNumber: string; sort?: string }) => payload
);

export const fetchDailySummariesSucceeded = createAction(
  "FETCH_DAILY_SUMMARIES_SUCCEEDED",
  (payload: {
    dailySummaries: DailySummaries;
    dailySummariesPaginationLinks: PaginationLinks;
  }) => payload
);

export const fetchDailySummariesFailed = createAction(
  "FETCH_DAILY_SUMMARIES_FAILED",
  (payload: string) => payload
);

export const clearDailySummaries = createAction("CLEAR_DAILY_SUMMARIES");
