import moment from "moment";
import { createReducer } from "redux-act";

import {
  fetchOrders,
  fetchOrdersSucceeded,
  fetchOrdersFailed,
  putExerciseOrder,
  putExerciseOrderSucceeded,
  putExerciseOrderFailed,
  placeExerciseOrder,
  placeExerciseOrderSucceeded,
  placeExerciseOrderFailed,
  removeOrderError,
  putPurchaseOrder,
  putPurchaseOrderSucceeded,
  putPurchaseOrderFailed,
} from "src/employee-portal/actions/order";

export interface OrderState {
  readonly isPlacingOrder: boolean;
  readonly isFetchingOrders: boolean;
  readonly orders?: Api.V1.Order[];
  readonly errorFetchingOrders: boolean;
  readonly isUpdatingExerciseOrder: boolean;
  readonly isUpdatingPurchaseOrder: boolean;
  readonly error?: string;
  readonly errorUpdatingRelatedOrder: boolean;
}

export enum OrderStatus {
  CREATED = "CREATED",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

const initialState: OrderState = {
  isPlacingOrder: false,
  isFetchingOrders: false,
  errorFetchingOrders: false,
  isUpdatingExerciseOrder: false,
  isUpdatingPurchaseOrder: false,
  orders: null,
  errorUpdatingRelatedOrder: null,
};

export const mostRecentFirst = (
  orderA: Api.V1.Order,
  orderB: Api.V1.Order
): number =>
  moment(orderA.createdAt).isSameOrBefore(orderB.createdAt) ? 1 : -1;

export default createReducer(on => {
  on(fetchOrders, state => ({ ...state, isFetchingOrders: true }));
  on(fetchOrdersSucceeded, (state, action) => {
    const orders = action.orders.sort(mostRecentFirst);
    return {
      ...state,
      isFetchingOrders: false,
      orders,
      errorFetchingOrders: false,
    };
  });
  on(fetchOrdersFailed, state => ({
    ...state,
    isFetchingOrders: false,
    errorFetchingOrders: true,
  }));
  on(placeExerciseOrder, state => ({ ...state, isPlacingOrder: true }));
  on(placeExerciseOrderSucceeded, state => ({
    ...state,
    isPlacingOrder: false,
  }));
  on(placeExerciseOrderFailed, state => ({
    ...state,
    isPlacingOrder: false,
    error:
      "An error occurred while placing your order. Please contact us if the issue persists",
  }));
  on(putExerciseOrder, state => ({
    ...state,
    isUpdatingExerciseOrder: true,
  }));
  on(putExerciseOrderSucceeded, (state, payload) => {
    const orderIndex = state.orders.findIndex(
      order => order.id === payload.order.id
    );
    const order = { ...state.orders[orderIndex], exerciseOrder: payload };
    const orders = [...state.orders];
    orders[orderIndex] = order;

    return {
      ...state,
      orders,
      isUpdatingExerciseOrder: false,
      errorUpdatingRelatedOrder: false,
    };
  });
  on(putExerciseOrderFailed, state => ({
    ...state,
    isUpdatingExerciseOrder: false,
    errorUpdatingRelatedOrder: true,
  }));
  on(putPurchaseOrder, state => ({
    ...state,
    isUpdatingPurchaseOrder: true,
  }));
  on(putPurchaseOrderSucceeded, (state, payload) => {
    const orderIndex = state.orders.findIndex(
      order => order.id === payload.order.id
    );
    const order = { ...state.orders[orderIndex], purchaseOrder: payload };
    const orders = [...state.orders];
    orders[orderIndex] = order;

    return {
      ...state,
      orders,
      isUpdatingPurchaseOrder: false,
      errorUpdatingRelatedOrder: false,
    };
  });
  on(putPurchaseOrderFailed, state => ({
    ...state,
    isUpdatingPurchaseOrder: false,
    errorUpdatingRelatedOrder: true,
  }));
  on(removeOrderError, state => ({
    ...state,
    error: null,
    errorUpdatingRelatedOrder: null,
  }));
}, initialState);
