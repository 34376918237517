import { compact } from "lodash";
import moment, { Moment } from "moment";
import { Reducer } from "redux";

import { VestingEventApi } from "src/admin-portal/awards/award-reducer";
import { Window, PurchaseWindow } from "src/common/data/data";
import { sortBy } from "src/common/utils/sort";
import { apiShortDate, removeNulls } from "src/common/utils/utils";
import {
  InstrumentType,
  APIAward,
} from "src/employee-portal/instrument-page/instruments-reducer";
import { toWindow, createPurchaseWindowFromApiWindow } from "src/reducers/user";

const today = moment().format(apiShortDate);

export interface TimelineEvent {
  type: string;
  eventId: string;
  eventType: string;
  eventTime: Moment;
}

export interface TimelineWindowEvent extends TimelineEvent {
  type: "window";
  window: Window;
  processType: string;
}

export interface TimelineTrancheEvent extends TimelineEvent {
  type: "tranche";
  tranche: VestingEventApi;
  instrumentType: string;
}

const getWindowEvent = (
  window: Window | PurchaseWindow
): TimelineWindowEvent | null => {
  const today = moment();

  const eventType = today.isBefore(window.from)
    ? "upcoming"
    : today.isBetween(window.from, window.to)
    ? "closing"
    : null;

  const processType =
    window.type === "PURCHASE"
      ? "purchase"
      : window.is_release_process
      ? "release"
      : "exercise";

  const eventTime = eventType === "upcoming" ? window.from : window.to;

  return eventType
    ? {
        type: "window",
        eventId: window.id,
        eventType: eventType,
        eventTime: eventTime,
        window: window,
        processType: processType,
      }
    : null;
};

const getWindowsEvents = (
  windows: Window[],
  purchase_opportunities: any[],
  awards: APIAward[]
): TimelineWindowEvent[] => {
  const normalizedWindows: Array<Window | PurchaseWindow> = windows.map(
    window => {
      if (window.type !== "PURCHASE") return window;

      return createPurchaseWindowFromApiWindow(
        window,
        purchase_opportunities,
        awards
      );
    }
  );

  const windowEvents = normalizedWindows.map(window => getWindowEvent(window));

  return compact(windowEvents);
};

const getSharesEventType = (
  tranche: VestingEventApi,
  instrumentType: InstrumentType
): TimelineTrancheEvent => {
  if (!tranche.locked_to_date || tranche.locked_to_date <= today) {
    return null;
  }

  return {
    type: "tranche",
    eventId: tranche.id,
    eventType: "release",
    eventTime: moment(tranche.locked_to_date, apiShortDate),
    tranche: tranche,
    instrumentType,
  };
};

const getTrancheEvent = (
  tranche: VestingEventApi,
  instrumentType: InstrumentType
): TimelineTrancheEvent => {
  if (instrumentType === "share") {
    return getSharesEventType(tranche, instrumentType);
  }

  const eventType =
    tranche.vestedDate > today
      ? "vesting"
      : ["rsu", "rsa"].includes(instrumentType)
      ? "release"
      : "expiry";

  const eventDate =
    tranche.vestedDate > today ? tranche.vestedDate : tranche.expiry_date;

  return {
    type: "tranche",
    eventId: tranche.id,
    eventType: eventType,
    eventTime: moment(eventDate),
    tranche: tranche,
    instrumentType,
  };
};

const getTrancheEvents = (awards): TimelineTrancheEvent[] =>
  awards.reduce(
    (acc, aw) => [
      ...acc,
      ...aw.tranches
        .filter(t => t.expiry_date > today && t.quantity > 0)
        .map(t =>
          getTrancheEvent(t, aw.incentive_sub_program.instrument_type_id)
        )
        .filter(removeNulls),
    ],
    []
  );

export interface TimelineEventsState {
  events: TimelineEvent[];
}

const initialState = {
  events: null,
};

const eventsTimelineReducer: Reducer<TimelineEventsState> = (
  state = initialState,
  action
) => {
  if (action.type === "FETCH_EMPLOYEE_PORTAL_WELCOME_SUCCEEDED") {
    return {
      ...state,
      events: sortBy("eventTime", [
        ...getTrancheEvents(action.welcomeData.awards),
        ...getWindowsEvents(
          action.welcomeData.windows.map(toWindow),
          action.welcomeData.purchase_opportunities,
          action.welcomeData.awards
        ),
      ]),
    };
  }

  return state;
};

export default eventsTimelineReducer;
