import { createReducer } from "redux-act";

import { selectTenant } from "src/admin-portal/tenant/tenant-actions";

import { IStringStringMap } from "src/admin-portal/texts/employee-portal-texts-reducer";
import {
  fetchTexts,
  fetchTextsSucceeded,
  putTexts,
  putTextsSucceeded,
  updateDefaultTexts,
  updateDefaultTextsSucceeded,
} from "src/admin-portal/texts/text-actions";

export interface Text {
  [key: string]: string;
}

export interface TextObject {
  [key: string]: {
    value: string;
    defaultValue: string;
    overridden: boolean;
  };
}

export type SupportedLocale = "en" | "no" | "sv" | "da";

export type LocaledTexts = { [key in SupportedLocale]: IStringStringMap };

export interface TextState {
  allTexts?: Api.V1.Text[];
  defaultTexts?: IStringStringMap;
  defaultTextsLocaled?: LocaledTexts;
  locales: SupportedLocale[];
  isFetching: boolean;
  isUpdatingDefaultTexts: boolean;
}

const initialState: TextState = {
  allTexts: null,
  isFetching: false,
  isUpdatingDefaultTexts: false,
  locales: ["en", "no", "sv", "da"],
};

function createTextObject(defaultTexts, tenantSpecificTexts) {
  const texts: TextObject = {};

  Object.keys(defaultTexts).forEach(key => {
    texts[key] = {
      defaultValue: defaultTexts[key],
      value:
        (tenantSpecificTexts && tenantSpecificTexts[key]) || defaultTexts[key],
      overridden: tenantSpecificTexts && !!tenantSpecificTexts[key],
    };
  });

  Object.keys(tenantSpecificTexts || {}).forEach(key => {
    if (!texts[key]) {
      texts[key] = {
        defaultValue: undefined,
        value: tenantSpecificTexts[key],
        overridden: true,
      };
    }
  });

  return texts;
}

const setIsFetching = (state: TextState) => ({
  ...state,
  isFetching: true,
});

export default createReducer(on => {
  on(selectTenant, () => initialState);
  on(fetchTexts, setIsFetching);
  on(putTexts, setIsFetching);
  on(updateDefaultTexts, setIsFetching);

  on(fetchTextsSucceeded, (state, payload) => ({
    ...state,
    allTexts: payload.allTexts,
    defaultTexts: payload.defaultTexts,
    defaultTextsLocaled: payload.defaultTextsLocaled,
    isFetching: false,
  }));

  on(putTextsSucceeded, (state, payload) => {
    const updatedTestData = state.allTexts.map(textObj =>
      textObj.locale === payload.locale ? payload : textObj
    );
    return { ...state, isFetching: false, allTexts: updatedTestData };
  });
  on(updateDefaultTextsSucceeded, (state, payload) => {
    const texts = createTextObject(payload.texts, state.allTexts);
    return {
      ...state,
      defaultTexts: payload.texts,
      defaultTextsLocaled: {
        ...state.defaultTextsLocaled,
        [payload.locale]: payload.texts,
      },
      isFetching: false,
      texts,
    };
  });
}, initialState);
