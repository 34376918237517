import React, { FunctionComponent } from "react";
import { Button, Message } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import SpinnerInline from "src/common/components/spinner-inline";
import Form from "src/employee-portal/order/table/UpdateModal/Form";
import "src/employee-portal/order/table/UpdateModal/style.less";

interface Props {
  order: Api.V1.Order;
  isError: boolean;
  isLoading: boolean;
  onClose: () => void;
}

const Contents: FunctionComponent<Props> = ({
  order,
  isError,
  isLoading,
  onClose,
}) => {
  if (isError === false) {
    return (
      <>
        <Message positive>
          <Content id="order.employeeUpdateModal.success" />
        </Message>

        <div className="text-center">
          <Button onClick={onClose}>
            <Content id="global.closeBtnTxt" />
          </Button>
        </div>
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <SpinnerInline
          active={true}
          dimmer={true}
          dimmerProps={{ inverted: true }}
        />

        <Form order={order} />
      </>
    );
  }

  return <Form order={order} />;
};

export default Contents;
