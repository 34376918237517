import Jsona from "jsona";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";

import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import * as selectors from "src/selectors";
import {
  FETCH_SELF_SERVICE_OVERVIEW,
  FETCH_SELF_SERVICE_OVERVIEW_FAILED,
  FETCH_SELF_SERVICE_OVERVIEW_SUCCEEDED,
} from "src/self-service-portal/self-service-actions";

const overviewFetchUrl = tenantId => `/tenants/${tenantId}/overview`;
const dataFormatter = new Jsona();

function* fetchOverviewRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId =
      (yield select(selectors.tenantId)) ||
      (yield select(selectors.userTenantId));

    const response = yield call(() =>
      callApi(overviewFetchUrl(tenantId), token)
    );
    yield put({
      type: FETCH_SELF_SERVICE_OVERVIEW_SUCCEEDED,
      overview: dataFormatter.deserialize(response),
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({
        type: FETCH_SELF_SERVICE_OVERVIEW_FAILED,
        message: e.message,
      });
    }
  }
}

export function* watchFetchSelfServiceOverview() {
  yield takeEvery(FETCH_SELF_SERVICE_OVERVIEW, fetchOverviewRequested);
}
