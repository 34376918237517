export const TYPES = {
  FETCH_EMPLOYEE_PORTAL_PROFILE: "FETCH_EMPLOYEE_PORTAL_PROFILE",
  FETCH_EMPLOYEE_PORTAL_PROFILE_SUCCEEDED:
    "FETCH_EMPLOYEE_PORTAL_PROFILE_SUCCEEDED",
  FETCH_EMPLOYEE_PORTAL_PROFILE_FAILED: "FETCH_EMPLOYEE_PORTAL_PROFILE_FAILED",
  PUT_EMPLOYEE_PORTAL_PROFILE: "PUT_EMPLOYEE_PORTAL_PROFILE",
  PUT_EMPLOYEE_PORTAL_PROFILE_SUCCEEDED:
    "PUT_EMPLOYEE_PORTAL_PROFILE_SUCCEEDED",
  PUT_EMPLOYEE_PORTAL_PROFILE_FAILED: "PUT_EMPLOYEE_PORTAL_PROFILE_FAILED",
  USER_NOT_AUTHORIZED: "USER_NOT_AUTHORIZED",
};

export interface EmployeeProfile {
  type: string;
  user_id: string;
  share_depository_norwegian?: string;
  share_depository_account?: string;
  share_depository_bank?: string;
  share_depository_description?: string;
  share_depository_contact?: string;
  locale?: string;
  currency_code?: string;
}

export const putEmployeeProfileActionCreator = (
  user_id: string,
  share_depository_account?: string,
  share_depository_bank?: string,
  share_depository_description?: string,
  share_depository_contact?: string,
  share_depository_norwegian?: string,
  locale?: string,
  currency_code?: string
): EmployeeProfile => ({
  type: TYPES.PUT_EMPLOYEE_PORTAL_PROFILE,
  user_id,
  share_depository_account,
  share_depository_bank,
  share_depository_description,
  share_depository_contact,
  share_depository_norwegian,
  locale,
  currency_code,
});
