import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

const selfServiceRoute = "/selfservice";
const employeePortalHomeRoute = "/overview";

interface Props {
  logoUrl: string;
  isAdminUserWithoutInstruments: boolean;
  title: string;
}

const Logo: FunctionComponent<Props> = ({
  logoUrl,
  isAdminUserWithoutInstruments,
  title,
}) => {
  if (!logoUrl) {
    return null;
  }

  return (
    <>
      <Link
        to={
          isAdminUserWithoutInstruments
            ? selfServiceRoute
            : employeePortalHomeRoute
        }
        className="logo-link"
      >
        <img src={logoUrl} alt="" className="logo" />
      </Link>
      {title && (
        <Link
          to={
            isAdminUserWithoutInstruments
              ? selfServiceRoute
              : employeePortalHomeRoute
          }
          className="logo-link"
        >
          <h2 className="tenant-title">{title}</h2>
        </Link>
      )}
    </>
  );
};

export default Logo;
