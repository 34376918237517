import { createAction } from "redux-act";

export interface TrancheForPerformanceRule {
  trancheId: string;
  weightFactor: string;
}

export const fetchPerformanceRules = createAction("FETCH_PERFORMANCE_RULES");

export const fetchPerformanceRulesSucceeded = createAction(
  "FETCH_PERFORMANCE_RULES_SUCCEEDED",
  (payload: { performanceRules: Api.V1.PerformanceRule[] }) => payload
);

export const postPerformanceRule = createAction(
  "POST_PERFORMANCE_RULE",
  (payload: Api.V1.PerformanceRule) => payload
);

export const postPerformanceRuleSucceeded = createAction(
  "POST_PERFORMANCE_RULE_SUCCEEDED",
  (payload: Api.V1.PerformanceRule) => payload
);

export const patchPerformanceRule = createAction(
  "PATCH_PERFORMANCE_RULE",
  (payload: Api.V1.PerformanceRule) => payload
);

export const patchPerformanceRuleSucceeded = createAction(
  "PATCH_PERFORMANCE_RULE_SUCCEEDED",
  (payload: Api.V1.PerformanceRule) => payload
);

export const deletePerformanceRule = createAction(
  "DELETE_PERFORMANCE_RULE",
  (payload: string) => payload
);

export const deletePerformanceRuleSucceeded = createAction(
  "DELETE_PERFORMANCE_RULE_SUCCEEDED",
  (payload: string) => payload
);

export const patchTranchePerformanceRule = createAction(
  "PATCH_TRANCHE_PERFORMANCE_RULE",
  (payload: {
    performanceRuleId: string;
    tranches: TrancheForPerformanceRule[];
  }) => payload
);

export const patchTranchePerformanceRuleSucceeded = createAction(
  "PATCH_TRANCHE_PERFORMANCE_RULE_SUCCEEDED"
);

export const closePatchTranchePerformanceRuleSucceededNotification = createAction(
  "CLOSE_PATCH_TRANCHE_PERFORMANCE_RULE_SUCCEEDED_NOTIFICATION"
);
