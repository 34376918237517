import React, { FunctionComponent } from "react";
import { Table } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import HelpPopup from "src/common/components/help-popup";
import OrderTableRow from "src/employee-portal/order/table/order-table-row";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface OrderTableProps {
  dateFormat: DateFormatState;
  orders: Api.V1.Order[];
  paymentAccount: PaymentAccount;
}

const OrderTable: FunctionComponent<OrderTableProps> = ({
  dateFormat,
  orders,
  paymentAccount,
}) => {
  const showLoan = orders.some(
    o =>
      o.orderType === "PURCHASE" &&
      typeof o.purchaseOrder.loan === "number" &&
      o.purchaseOrder.loan > 0
  );

  return (
    <>
      <Table
        celled={true}
        textAlign="center"
        collapsing={true}
        padded={true}
        unstackable={true}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Content id={"orders.table.header.orderNumber"} />
            </Table.HeaderCell>
            <Table.HeaderCell singleLine={true}>
              <Content id={"orders.table.header.orderDate"} />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Content id={"orders.table.header.orderType"} />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Content id={"orders.table.header.orderQuantity"} />
            </Table.HeaderCell>
            {showLoan && (
              <>
                <Table.HeaderCell singleLine={true}>
                  <Content id={"orders.table.header.orderLoan"} />
                  <HelpPopup textKey={"orders.table.header.orderLoan.popup"} />
                </Table.HeaderCell>
                <Table.HeaderCell singleLine={true}>
                  <Content id={"orders.table.header.orderToPay"} />
                  <HelpPopup textKey={"orders.table.header.orderToPay.popup"} />
                </Table.HeaderCell>
              </>
            )}
            <Table.HeaderCell>
              <Content id={"orders.table.header.shareDepo"} />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Content id={"orders.table.header.orderStatus"} />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Content id={"orders.table.header.paymentInfo"} />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Content id={"orders.table.header.actions"} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {orders.map((order, index) => (
            <OrderTableRow
              order={order}
              orderNumber={orders.length - index}
              key={order.id}
              dateFormat={dateFormat}
              showLoan={showLoan}
              paymentAccount={paymentAccount}
            />
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

export default OrderTable;
