import moment from "moment";
import React, { Component } from "react";
import {
  Button,
  Dropdown,
  Image,
  Input,
  Label,
  Modal,
  Table,
} from "semantic-ui-react";

import { TrancheDataEntry } from "src/admin-portal/tranches-page/types";
import {
  editableKeys,
  EditableTransaction,
  EditedKeysAndValues,
  generateTranchesWithNormalDateFormat,
  initialTransaction,
  quantityTransactionTypes,
} from "src/admin-portal/tranches-page/utils";
import {
  changeCommaForPunctuation,
  apiShortDate,
} from "src/common/utils/utils";

const newTransactionTypeOptions = [
  ...quantityTransactionTypes,
  "ADJUSTMENT",
  "ADJUSTMENT_FAIR_VALUE",
  "ADJUSTMENT_STRIKE",
  "ADJUSTMENT_GRANT",
  "ADJUSTMENT_VESTING",
  "ADJUSTMENT_EXPIRY",
  "ADJUSTMENT_DIVIDEND",
  "ADJUSTMENT_PERFORMANCE",
  "ADJUSTMENT_FVSS",
  "ADJUSTMENT_FV_CASH",
  "MODIFICATION_CASH_ACCOUNTING",
  "MODIFICATION_STRIKE",
];

interface Props {
  grantTransactions: Api.V1.Transaction[];
  tranches: TrancheDataEntry[];
  dateFormat: string;
  updateTransactions: (transaction: Api.V1.Transaction[]) => void;
  handleAdjustmentTransaction: (transaction: Api.V1.Transaction) => void;
  closeModal: () => void;
}

interface State {
  tempTransaction: EditableTransaction | {};
  isCTA: boolean; // abbreviation for isCreatingTransactionActive (not "call to action")
}

class EditTransactionsModal extends Component<Props, State> {
  public state = {
    tempTransaction: initialTransaction,
    isCTA: false,
  };

  public handleEditedKeysChange = (key, val) => {
    const updatedValue =
      name !== "comment" ? changeCommaForPunctuation(val) : val;

    const tempTransaction = {
      ...this.state.tempTransaction,
      [key]: updatedValue,
    };

    this.setState({ tempTransaction });
  };

  public reset = () => {
    this.setState({ tempTransaction: initialTransaction, isCTA: false });
  };

  public handleSubmit = () => {
    const { tempTransaction, isCTA } = this.state;
    const {
      grantTransactions,
      updateTransactions,
      handleAdjustmentTransaction,
      dateFormat,
    } = this.props;
    const isTransactionTypeSet = Boolean(tempTransaction.transactionType);

    const initialState = {
      keysAndValues: {},
      haveBeenEditing: false,
    };

    const tempTransactionAndValues: EditedKeysAndValues = Object.keys(
      tempTransaction
    )
      .filter(key => tempTransaction[key] !== "" && tempTransaction[key] !== 0)
      .reduce((acc, key) => {
        const keysAndValues = {
          ...acc.keysAndValues,
          [key]: key.includes("Date")
            ? moment(this.state.tempTransaction[key], dateFormat).format(
                apiShortDate
              )
            : this.state.tempTransaction[key],
        };

        const haveBeenEditing = true;
        return { ...acc, keysAndValues, haveBeenEditing };
      }, initialState);

    if (tempTransactionAndValues.haveBeenEditing) {
      const isEditConfirmed = confirm(
        `You are about to change ${this.props.grantTransactions.length} items. Are you sure?`
      );

      const isValidGrant = isEditConfirmed && !isCTA;
      const isValidNewTransaction =
        isEditConfirmed && isCTA && isTransactionTypeSet;

      if (isValidGrant) {
        updateTransactions(
          grantTransactions.map(transaction => ({
            id: transaction.id,
            transactionType: "GRANT",
            ...tempTransactionAndValues.keysAndValues,
          }))
        );
      } else if (isValidNewTransaction) {
        handleAdjustmentTransaction(tempTransactionAndValues.keysAndValues);
      } else {
        alert("Transaction type isn't set.");
      }
    } else {
      alert("You haven't edited anything yet");
    }
  };

  public renderCheckbox = isCTA => (
    <Input
      type="checkbox"
      checked={isCTA}
      onChange={() =>
        this.setState({
          isCTA: !isCTA,
          tempTransaction: !isCTA && initialTransaction,
        })
      }
    />
  );

  public renderHeaderMessage = () => {
    const { tranches, grantTransactions } = this.props;
    const { isCTA } = this.state;
    // prettier-ignore
    if (isCTA) {
      return (
        <>
          Creating Adjustment/Exercise Transaction ( {tranches.length} items ) /{" "}
          <small>or deactivate checkbox if you want to Edit Grants Transactions</small>{" "}
          {this.renderCheckbox(isCTA)}
        </>
      );
    } else {
      return (
        <>
          Editing Grants Transactions ( {grantTransactions.length} items ) /{" "}
          <small>or activate checkbox if you want to Create Adjustment/Exercise Transaction</small>{" "}
          {this.renderCheckbox(isCTA)}
        </>
      );
    }
  };

  public handleDropdownChange = value => {
    this.setState({
      tempTransaction: {
        ...this.state.tempTransaction,
        transactionType: value,
        transactionDate: moment().format(this.props.dateFormat),
      },
    });
  };

  public handleNullifyInput = name => {
    const updatedValue = this.state.tempTransaction[name] === null ? "" : null;
    const tempTransaction = {
      ...this.state.tempTransaction,
      [name]: updatedValue,
    };
    this.setState({ tempTransaction });
  };

  public render() {
    const { grantTransactions, closeModal, tranches, dateFormat } = this.props;
    const tranchesWithNormalDateFormat = generateTranchesWithNormalDateFormat(
      tranches,
      dateFormat
    );
    const { isCTA } = this.state;

    return (
      <Modal open={true} size="fullscreen">
        <Modal.Header
          className={isCTA ? "info-modal-header" : "error-modal-header"}
        >
          {this.renderHeaderMessage()}
          <Button
            style={{ background: "#fff" }}
            onClick={closeModal}
            floated="right"
            icon="close"
          />
          <Button
            onClick={this.handleSubmit}
            floated="right"
            color="twitter"
            style={{ marginRight: 20 }}
            content="Submit"
          />
          <Button
            style={{ background: "#fff" }}
            onClick={this.reset}
            floated="right"
            content="Reset"
          />
        </Modal.Header>
        {/* prettier-ignore */}
        <Modal.Content image={true}>
          <Image wrapped={true} />
          <div className="sideways-scrollable">
            <Table celled={true} striped={true}>
              <Table.Header>
                <Table.Row>
                  {grantTransactions && grantTransactions.length && Object.keys(grantTransactions[0]).map(
                    key => editableKeys.includes(key) 
                      ? <Table.HeaderCell key={key} content={key} />
                      : null
                  )}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {grantTransactions && grantTransactions.length && (
                  <Table.Row>
                    {grantTransactions && grantTransactions.length && Object.keys(grantTransactions[0]).map(
                      key => editableKeys.includes(key) ? (
                        <Table.Cell collapsing={true} key={key}>
                          { ( isCTA && key === "transactionType" )
                            ? <Dropdown
                                placeholder="Select transaction type"
                                options={newTransactionTypeOptions.map(tType => ({ key: tType, text: tType, value: tType }))}
                                onChange={(e, { value }) => this.handleDropdownChange(value)}
                              />
                            : <Input
                                placeholder="Add new value"
                                value={this.state.tempTransaction[key] || ""}
                                disabled={key === "transactionType"}
                                onChange={(e, { value }) =>
                                  this.handleEditedKeysChange(key, value)
                                }
                                action={{
                                  color: this.state.tempTransaction[key] === null ? "red" : "",
                                  icon: "close",
                                  onClick: () => this.handleNullifyInput(key),
                                  disabled: key === "transactionType"
                                }}
                              />
                          }
                          
                        </Table.Cell>
                        ) : null
                      )}
                  </Table.Row>
                )}

                {grantTransactions && grantTransactions.length && grantTransactions.map(
                  (t, i) => (
                    <Table.Row key={t.id}>
                      {Object.keys(t).map(
                        key => {
                          const oldValue = isCTA ? tranchesWithNormalDateFormat[i][key] : grantTransactions[i][key];
                          return editableKeys.includes(key) ? (
                            <Table.Cell collapsing={true} key={key}>
                              {this.state.tempTransaction[key] ? (
                                <Label color="green">
                                  {this.state.tempTransaction[key]}
                                </Label>
                              ) : null}
                              {this.state.tempTransaction[key] === null ? (
                                <Label color="red">
                                  null
                                </Label>
                              ) : null}
                              <div className="block-xxxs" />
                              {(Boolean(oldValue) || oldValue === 0) &&
                                <Label color="grey">
                                  { oldValue }
                                </Label>
                              }
                            </Table.Cell>
                          ) : null
                        }  
                      )}
                    </Table.Row>
                  )
                )}
              </Table.Body>
            </Table>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default EditTransactionsModal;
