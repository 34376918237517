import { createReducer } from "redux-act";

import {
  fetchContracts,
  fetchContractsSucceeded,
  fetchContractsFailed,
  postContract,
  postContractSucceeded,
  postContractFailed,
} from "src/actions/contract-actions";
import { handleSortIdempotent } from "src/common/utils/sort";

export interface ContractState {
  readonly allContracts: Api.V1.Contract[];
  readonly isFetching: boolean;
  readonly isPosting: boolean;
}

const initialState: ContractState = {
  allContracts: null,
  isFetching: false,
  isPosting: false,
};

export default createReducer(on => {
  on(fetchContracts, state => ({
    ...state,
    isFetching: true,
  }));
  on(fetchContractsSucceeded, (state, payload) => ({
    ...state,
    allContracts: payload,
    isFetching: false,
  }));
  on(fetchContractsFailed, state => ({
    ...state,
    isFetching: false,
  }));
  on(postContract, state => ({
    ...state,
    isPosting: true,
  }));
  on(postContractSucceeded, (state, payload) => ({
    ...state,
    allContracts: handleSortIdempotent("activeFrom", "descending", [
      payload,
      ...state.allContracts,
    ]),
    isPosting: false,
  }));
  on(postContractFailed, state => ({
    ...state,
    isPosting: false,
  }));
}, initialState);
