import { Program } from "src/admin-portal/programs/program-reducer";
import { sortMultipleLevels } from "src/common/utils/sort";
import { sortAlphabetically } from "src/common/utils/utils";

export const instrumentOptions = [
  {
    key: "psu",
    value: "psu",
    text: "PSU",
  },
  {
    key: "rsu",
    value: "rsu",
    text: "RSU",
  },
  {
    key: "rsa",
    value: "rsa",
    text: "RSA",
  },
  {
    key: "option",
    value: "option",
    text: "Option",
  },
  {
    key: "warrant",
    value: "warrant",
    text: "Warrant",
  },
  {
    key: "deferred_fund_share",
    value: "deferred_fund_share",
    text: "Deferred Fund Units",
  },
  {
    key: "convertible_loan",
    value: "convertible_loan",
    text: "Convertible Loan",
  },
  {
    key: "share",
    value: "share",
    text: "Share",
  },
  {
    key: "deferred_cash",
    value: "deferred_cash",
    text: "Deferred Cash",
  },
  {
    key: "subscription_rights",
    value: "subscription_rights",
    text: "Subscription Rights",
  },
].sort(sortAlphabetically("text"));

export const settlementOptions = [
  {
    key: "equity",
    value: "equity",
    text: "Equity",
  },
  {
    key: "cash",
    value: "cash",
    text: "Cash",
  },
];

export const sortedProgramsAndSubprograms = (
  programs: Api.V1.IncentiveProgram[]
) => {
  const sortedPrograms = sortMultipleLevels(programs)("createdAt").reverse();
  return sortedPrograms.map(p => ({
    ...p,
    incentiveSubPrograms: sortMultipleLevels(p.incentiveSubPrograms)(
      "createdAt"
    ).reverse(),
  }));
};
