import moment from "moment";
import XLSX from "xlsx";

import { excelNumber } from "src/common/utils/excel";
import {
  apiShortDate,
  norwegianShortDateLongYear,
  norwegianShortDateLongYearHours,
  norwegianShortDateLongYearHoursSeconds,
} from "src/common/utils/utils";
import { NO_VALUE } from "src/constants";
import { AnnualVolatilityData } from "src/reducers/share-price-reducer";

const sheetData = (volatilityData: AnnualVolatilityData) => {
  const headers = ["Date", "Share price", "Change", "Removed?"];

  const rows = volatilityData.observations.map(observation => [
    moment(observation.date, apiShortDate).format(norwegianShortDateLongYear),
    excelNumber(observation.price),
    excelNumber(observation.daily_change),
    observation.removed_from_calculation ? "Yes" : NO_VALUE,
  ]);

  return [headers, ...rows];
};

export const generateVolatilityObservations = (
  volatilityData: AnnualVolatilityData
) => {
  const wb = XLSX.utils.book_new();

  const workSheet = XLSX.utils.aoa_to_sheet(sheetData(volatilityData));

  XLSX.utils.book_append_sheet(wb, workSheet, "Volatility Observations");
  XLSX.writeFile(
    wb,
    `Optio-Volatility-Observations-${moment().format(
      norwegianShortDateLongYearHoursSeconds
    )}.xlsx`
  );
};
