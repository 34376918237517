import React, { FunctionComponent } from "react";
import { Message } from "semantic-ui-react";

import { samePropValCounter } from "src/common/utils/utils";

interface Props {
  employees: Api.V1.Employee[];
}

const EmployeeImportEmailError: FunctionComponent<Props> = ({ employees }) => {
  const sameEmailsCounter = samePropValCounter(employees, "email");

  if (
    Object.keys(sameEmailsCounter).some(email => sameEmailsCounter[email] > 1)
  ) {
    return (
      <Message
        error={true}
        header="Detected import of the 2+ employees with the same email or with emails which are already in use"
        list={Object.keys(sameEmailsCounter)
          .filter(email => sameEmailsCounter[email] > 1)
          .map(email => email)}
      />
    );
  }

  return null;
};

export default EmployeeImportEmailError;
