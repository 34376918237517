import Jsona from "jsona";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";

import * as selectors from "src/admin-portal/awards/award-selectors";
import {
  CREATE_TRANSACTIONS,
  CREATE_TRANSACTIONS_FAILED,
  CREATE_TRANSACTIONS_SUCCEEDED,
  DELETE_TRANSACTION,
  DELETE_TRANSACTION_FAILED,
  DELETE_TRANSACTION_SUCCEEDED,
  UPDATE_TRANSACTIONS,
  UPDATE_TRANSACTIONS_FAILED,
  UPDATE_TRANSACTIONS_SUCCEEDED,
} from "src/admin-portal/awards/transaction/transaction-actions";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { batchRequests } from "src/common/sagas/batch-requests-saga";

const updateTransactionUrl = transactionId => `/transactions/${transactionId}`;
const createTransactionUrl = tenant_id => `/tenants/${tenant_id}/transactions`;

const dataFormatter = new Jsona();

const transactionIncludeParam = [
  "tranche.award.employee.entity",
  "tranche.tranchePerformanceRules.performanceRule",
  "tranche.award.incentiveSubProgram.incentiveProgram",
  "tranche.transactions",
].join(",");

function* updateTransactions(action) {
  try {
    const token = yield select(selectors.token);
    const method = "PATCH";

    const res = yield batchRequests(
      20,
      1000,
      action.transactions.map(transaction => () => {
        const { id, ...attributes } = transaction;
        return callApi(
          updateTransactionUrl(id) + "?include=" + transactionIncludeParam,
          token,
          method,
          {
            data: {
              id,
              type: "transactions",
              attributes,
            },
          }
        );
      })
    );

    yield put({
      type: UPDATE_TRANSACTIONS_SUCCEEDED,
      transactions: res.map(r => dataFormatter.deserialize(r)),
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({
        type: UPDATE_TRANSACTIONS_FAILED,
        message: e.message,
      });
    }
  }
}

export function* watchUpdateTransactions() {
  yield takeEvery(UPDATE_TRANSACTIONS, updateTransactions);
}

interface CreateTransactionsAction {
  type: "CREATE_TRANSACTIONS";
  transactions: Array<{
    trancheId: string;
    transaction: Api.V1.Transaction;
  }>;
}

function* createTransactions(action: CreateTransactionsAction) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);

    const method = "POST";

    const promiseCreators = action.transactions.map(transaction => () =>
      callApi(
        createTransactionUrl(tenantId) +
          `?tranche_id=${transaction.trancheId}&include=` +
          transactionIncludeParam,
        token,
        method,
        dataFormatter.serialize({ stuff: transaction.transaction })
      )
    );

    const res = yield batchRequests(25, 500, promiseCreators);

    yield put({
      type: CREATE_TRANSACTIONS_SUCCEEDED,
      transactions: res.map(r => dataFormatter.deserialize(r)),
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: CREATE_TRANSACTIONS_FAILED, message: e.message });
    }
  }
}

export function* watchCreateTransactions() {
  yield takeEvery(CREATE_TRANSACTIONS, createTransactions);
}

function* deleteTransactionRequested(action) {
  try {
    const token = yield select(selectors.token);
    const method = "DELETE";
    const { transactionId, trancheId } = action;
    yield call(() => callApi("/transactions/" + transactionId, token, method));

    yield put({
      type: DELETE_TRANSACTION_SUCCEEDED,
      transactionId,
      trancheId,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: DELETE_TRANSACTION_FAILED, message: e.message });
    }
  }
}

export function* watchDeleteTransaction() {
  yield takeEvery(DELETE_TRANSACTION, deleteTransactionRequested);
}
