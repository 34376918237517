import moment, { Moment } from "moment";
import numeral from "numeral";
import React, { Component, FunctionComponent } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect, MapStateToProps } from "react-redux";
import { bindActionCreators } from "redux";
import { Button } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import SpinnerFullScreen from "src/common/components/spinner-full-screen";
import { Window } from "src/common/data/data";
import { ErrorModal } from "src/common/error/error-modal";
import { sumNumbers } from "src/common/utils/utils";
import {
  placeExerciseOrder,
  removeOrderError,
} from "src/employee-portal/actions/order";
import { EmployeePortalProfileState } from "src/employee-portal/employee-portal-profile-reducer";
import {
  exercisibleInstrumentTerm,
  sortedExercisibleTranches,
} from "src/employee-portal/employee-portal-selectors";
import ExerciseNotPossiblePage, {
  ExerciseNotPossibleReason,
} from "src/employee-portal/exercise/common/exercise-not-possible-page";
import StepIndicator, {
  ExerciseStepPage,
  stepFromCurrentPage,
} from "src/employee-portal/exercise/common/exercise-step-indicator";
import OverviewAndConfirm from "src/employee-portal/exercise/confirm/exercise-confirm";
import Exercise from "src/employee-portal/exercise/quantity/exercise-quantity";
import Terms from "src/employee-portal/exercise/terms/exercise-terms";
import ExerciseTypeRoute from "src/employee-portal/exercise/type/exercise-type";
import { FlatAward } from "src/employee-portal/instrument-page/instruments-reducer";
import {
  ExerciseOrder,
  ExerciseOrderLine,
} from "src/employee-portal/sagas/order";
import { RootState } from "src/reducers/all-reducers";
import { DateFormatState } from "src/reducers/date-format-reducer";

export const OrderExerciseTypeComponent: FunctionComponent<{
  type: Api.V1.ExerciseOrder["exerciseType"];
}> = ({ type }) => {
  if (type === "EXERCISE_AND_SELL") {
    return <Content id={"exercise.type.exerciseandsell"} />;
  } else if (type === "EXERCISE_AND_SELL_TO_COVER") {
    return <Content id={"exercise.type.exerciseandselltocover"} />;
  } else if (type === "EXERCISE_AND_HOLD") {
    return <Content id={"exercise.type.exerciseandhold"} />;
  }
};

export interface SharePrice {
  sharePrice: number;
  sharePriceDate: Moment;
  manual: boolean;
}

export interface ExerciseOrderData {
  orderInstruments?: OrderInstrument[];
  exerciseType?: Api.V1.ExerciseOrder["exerciseType"];
  shareDepositoryAccountNumber?: string;
  bankAccountNumber?: string;
  bicNumber?: string;
  shareDepoBank?: string;
  shareDepoClearingCode?: string;
  shareDepoContact?: string;
  shareDepoDescription?: string;
  vpsNotReady: boolean;
  bankAccountNotReady: boolean;
  exerciseTypeFormHasErrors: boolean;
  taxPercentage: number;
}

interface OrderInstrument {
  planId: string;
  trancheId: string;
  orderAmount: number;
  costPrice: number;
  strikeConversionFactor?: number;
  award: FlatAward;
  lastClosePrice: number;
  sharesConversionFactor: number;
  sharesAmount: number;
  cappedSharesAmount: number;
  capFactor: number;
}

interface PassedProps {
  window: Window;
  match: any;
  sharePrice: SharePrice;
}

interface StateProps {
  exercisibleAwards: FlatAward[];
  isPlacingOrder: boolean;
  orderError?: string;
  bankAccountNumberForExercise?: string;
  bicNumberForExercise?: string;
  ibanNumberForExercise?: string;
  shareDepositoryAccountForExercise: string;
  shareDepositoryBankForExercise: string;
  shareDepositoryContactForExercise: string;
  shareDepositoryDescriptionForExercise: string;
  employeeName: string;
  companyName: string;
  payment_address?: string;
  currencyCode: string;
  dateFormat: DateFormatState;
  userProfile: EmployeePortalProfileState;
}

interface DispatchProps {
  placeExerciseOrder: ({ order: ExerciseOrder, window: Window }) => void;
  removeOrderError: () => void;
}

interface State extends ExerciseOrderData {
  currentPage: ExerciseStepPage;
  redirectToStart: boolean;
}

type Props = PassedProps & StateProps & DispatchProps;

const findDefaultSelectedExerciseType = (
  types: Api.V1.ExerciseOrder["exerciseType"][]
): Api.V1.ExerciseOrder["exerciseType"] => {
  if (types.includes("EXERCISE_AND_HOLD")) {
    return "EXERCISE_AND_HOLD";
  } else if (types.includes("EXERCISE_AND_SELL")) {
    return "EXERCISE_AND_SELL";
  } else {
    return "EXERCISE_AND_SELL_TO_COVER";
  }
};

const defaultCommission = 1.7;
export const brokerMinimumAmount = 350;

const setStateFromProfile = (profile: EmployeePortalProfileState) => ({
  shareDepositoryAccountNumber: profile.share_depository_account || "",
  shareDepoBank: profile.share_depository_bank || "",
  shareDepoContact: profile.share_depository_contact || "",
  shareDepoDescription: profile.share_depository_description || "",
  shareDepoClearingCode: "",
  bankAccountNumber: "",
});

class ExerciseRouter extends Component<Props & InjectedIntlProps, State> {
  constructor(props: Props & InjectedIntlProps) {
    super(props);
    const orderInstruments = this.props.exercisibleAwards.map(award => {
      const orderAmount = award.originalQuantity || award.quantity;
      const sharesAmount = Math.floor(
        orderAmount * award.sharesConversionFactor
      );
      const capFactor = award.capOnGain
        ? ((award.strike || 0) + (award.capOnGain || 0)) /
          props.sharePrice.sharePrice
        : 1;
      return {
        capFactor,
        planId: award.programId,
        trancheId: award.trancheId,
        orderAmount,
        sharesAmount,
        cappedSharesAmount: sharesAmount * capFactor,
        sharesConversionFactor: award.sharesConversionFactor,
        costPrice: award.strike,
        strikeConversionFactor: award.strikeConversionFactor,
        award,
        lastClosePrice: this.props.sharePrice.sharePrice,
      };
    });

    const type = this.props.window
      ? findDefaultSelectedExerciseType(
          this.props.window
            .allowed_exercise_types as Api.V1.ExerciseOrder["exerciseType"][]
        )
      : null;
    const bicNumber = "";
    const taxPercentage = 0.0;
    const vpsNotReady = false;
    const bankAccountNotReady = false;

    const state = {
      orderInstruments,
      exerciseType: type,
      exerciseTypeFormHasErrors: false,
      ...setStateFromProfile(props.userProfile),
      currentPage: ExerciseStepPage.QUANTITY,
      redirectToStart: false,
      vpsNotReady,
      bankAccountNotReady,
      bicNumber,
      taxPercentage,
    };

    this.state = {
      ...state,
      exerciseTypeFormHasErrors: !this.isFormValid(state),
    };
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.userProfile !== this.props.userProfile) {
      this.setState(
        {
          ...setStateFromProfile(this.props.userProfile),
        },
        this.updateValidationState
      );
    }
  }

  public render() {
    const {
      match,
      exercisibleAwards,
      window,
      currencyCode,
      intl: { formatMessage },
    } = this.props;

    const exercisibleInstrumentsTerm = exercisibleInstrumentTerm(
      exercisibleAwards,
      formatMessage
    );

    if (exercisibleAwards.length === 0) {
      return (
        <ExerciseNotPossiblePage
          reason={ExerciseNotPossibleReason.NO_EXERCISIBLE_OPTIONS}
        />
      );
    }

    if (!window) {
      return (
        <ExerciseNotPossiblePage
          reason={ExerciseNotPossibleReason.NOT_IN_AN_EXERCISE_WINDOW}
        />
      );
    }

    const totalQuantity = this.state.orderInstruments.reduce(
      (accu, op) => accu + op.orderAmount,
      0
    );
    const totalCost = this.state.orderInstruments.reduce(
      (accu, op) => accu + op.orderAmount * op.costPrice,
      0
    );
    const averageCost = totalCost / totalQuantity;
    const commission = numeral(this.commissionFactor()).format("0.00 %");
    return (
      <div className="main-content">
        {this.props.orderError && (
          <ErrorModal
            message={this.props.orderError}
            renderActions={() => (
              <Button
                primary={true}
                basic={true}
                size={"big"}
                content="OK"
                onClick={this.props.removeOrderError}
              />
            )}
          />
        )}
        <h1 className="block-m">
          <Content
            id="exercise.title"
            values={{ instrumentName: exercisibleInstrumentsTerm.plural }}
          />
        </h1>
        <div className="col-center block-l">
          <StepIndicator
            step={stepFromCurrentPage(this.state.currentPage)}
            path={this.props.match.path}
            onClick={this.handleStepIndicatorClick}
          />
        </div>
        {this.props.isPlacingOrder && (
          <SpinnerFullScreen text="Placing order" active={true} />
        )}
        {this.state.currentPage === ExerciseStepPage.QUANTITY && (
          <Exercise
            window={this.props.window}
            exercisibleAwards={exercisibleAwards}
            instrumentTerm={exercisibleInstrumentsTerm}
            sharePrice={this.props.sharePrice.sharePrice}
            quantities={this.state.orderInstruments.map(op =>
              op.orderAmount.toString()
            )}
            quantityForPlan={this.setQuantityForPlan}
            nextPath={`${this.props.match.path}/type`}
            goBack={() => history.back()}
            goForward={() =>
              this.setState({ currentPage: ExerciseStepPage.TYPE })
            }
            match={match}
            dateFormat={this.props.dateFormat}
          />
        )}
        {this.state.currentPage === ExerciseStepPage.TYPE && (
          <ExerciseTypeRoute
            window={this.props.window}
            selectOrderExerciseType={this.selectOrderExerciseType}
            instrumentTerm={exercisibleInstrumentsTerm}
            orderExerciseType={this.state.exerciseType}
            nextPath={`${this.props.match.path}/terms`}
            shareDepositoryAccountNumber={
              this.state.shareDepositoryAccountNumber
            }
            onshareDepositoryAccountNumberChanged={
              this.onshareDepositoryAccountNumberChanged
            }
            goBack={() =>
              this.setState({ currentPage: ExerciseStepPage.QUANTITY })
            }
            goForward={() =>
              this.setState({ currentPage: ExerciseStepPage.TERMS })
            }
            hasErrors={this.state.exerciseTypeFormHasErrors}
            userInstruments={[]}
            vpsNotReady={this.state.vpsNotReady}
            handleVpsToggle={this.handleVpsToggle}
            onBankAccountNumberChanged={this.onBankAccountNumberChanged}
            bankAccountNumber={this.state.bankAccountNumber}
            bankAccountNotReady={this.state.bankAccountNotReady}
            require_bank_account={this.requireBankAccount(
              this.state.exerciseType
            )}
            handleBankAccountToggle={this.handleBankAccountToggle}
            handleshareDepoBankChange={this.onShareDepoBankNumberChanged}
            handleShareDepoClearingCodeChange={
              this.onShareDepoClearingCodeNumberChanged
            }
            shareDepoBank={this.state.shareDepoBank}
            shareDepoClearingCode={this.state.shareDepoClearingCode}
            shareDepoContact={this.state.shareDepoContact}
            shareDepoDescription={this.state.shareDepoDescription}
            require_share_depository={this.requireShareDepo(
              this.state.exerciseType
            )}
            require_share_depository_bank={this.requireShareDepoBank(
              this.state.exerciseType
            )}
            require_share_depository_clearing_code={this.requireShareDepoClearingCode(
              this.state.exerciseType
            )}
            require_share_depository_contact={this.requireShareDepoContact(
              this.state.exerciseType
            )}
            require_share_depository_description={this.requireShareDepoDescription(
              this.state.exerciseType
            )}
            require_tax_percentage={this.requireTaxPercentage(
              this.state.exerciseType
            )}
            bicNumber={this.state.bicNumber}
            onBicChanged={this.onBicChanged}
            handleShareDepoContactChange={this.onShareDepoContactChanged}
            handleShareDepoDescriptionChange={
              this.onShareDepoDescriptionChanged
            }
            taxPercentage={this.state.taxPercentage}
            handleTaxPercentageChange={this.onTaxPercentageChanged}
          />
        )}
        {this.state.currentPage === ExerciseStepPage.TERMS && (
          <Terms
            window={this.props.window}
            userInstruments={[]}
            nextPath={`${this.props.match.path}/confirm`}
            goBack={() => this.setState({ currentPage: ExerciseStepPage.TYPE })}
            goForward={() =>
              this.setState({ currentPage: ExerciseStepPage.CONFIRM })
            }
            orderExerciseType={this.state.exerciseType}
            match={match}
            employeeName={this.props.employeeName}
            companyName={this.props.companyName}
            dateOfAgreement={moment()}
            instrumentsToExercise={this.state.orderInstruments.reduce(
              (accu, op) => accu + op.orderAmount,
              0
            )}
            averageStrikePrice={averageCost}
            commission={commission}
            shareDepositoryAccount={this.state.shareDepositoryAccountNumber}
            bankAccount={this.props.bankAccountNumberForExercise}
            currencyCode={currencyCode}
          />
        )}
        {this.state.currentPage === ExerciseStepPage.CONFIRM && (
          <OverviewAndConfirm
            {...this.state}
            {...this.props.sharePrice}
            instrumentTerm={exercisibleInstrumentsTerm}
            paymentBankAccountNumber={this.props.bankAccountNumberForExercise}
            bicNumber={this.props.bicNumberForExercise}
            ibanNumber={this.props.ibanNumberForExercise}
            goBack={() =>
              this.setState({ currentPage: ExerciseStepPage.TERMS })
            }
            confirmOrder={this.confirmOrder}
            exerciseWindow={window}
            payment_address={this.props.payment_address}
            require_share_depository={window.require_share_depository}
            display_bank_account_number={
              this.requireBankAccount() && !this.state.bankAccountNotReady
            }
            display_share_depository={this.requireShareDepo(
              this.state.exerciseType
            )}
            display_share_depository_bank={this.requireShareDepoBank(
              this.state.exerciseType
            )}
            display_share_depository_clearing_code={this.requireShareDepoClearingCode(
              this.state.exerciseType
            )}
            display_share_depository_contact={this.requireShareDepoContact(
              this.state.exerciseType
            )}
            display_share_depository_description={this.requireShareDepoDescription(
              this.state.exerciseType
            )}
            display_tax_percentage={this.requireTaxPercentage(
              this.state.exerciseType
            )}
            broker_fee={this.brokerFee()}
            dateFormat={this.props.dateFormat}
            taxPercentage={this.state.taxPercentage}
          />
        )}
      </div>
    );
  }

  private commissionFactor = () =>
    (this.props.window.commission_percentage
      ? this.props.window.commission_percentage
      : defaultCommission) / 100;

  private brokerFee = () => {
    const type = this.state.exerciseType;
    const isCashless =
      type === "EXERCISE_AND_SELL" || type === "EXERCISE_AND_SELL_TO_COVER";

    if (isCashless && this.props.window.commission_percentage) {
      const totalBrokerFee = this.state.orderInstruments
        .map(i => {
          const cappedSharePrice = i.award.capOnGain
            ? Math.min(
                this.props.sharePrice.sharePrice,
                i.costPrice + i.award.capOnGain
              )
            : this.props.sharePrice.sharePrice;

          return this.commissionFactor() * i.orderAmount * cappedSharePrice;
        })
        .reduce(sumNumbers, 0);

      return Math.max(brokerMinimumAmount, totalBrokerFee);
    }
  };

  private confirmOrder = () => {
    const orderLines: ExerciseOrderLine[] = this.state.orderInstruments
      .map(option => ({
        trancheId: option.trancheId,
        exerciseQuantity: option.orderAmount,
      }))
      .filter(orderLine => orderLine.exerciseQuantity > 0);

    const shareDepositoryAccount = this.requireShareDepo(
      this.state.exerciseType
    )
      ? { share_depository_account: this.state.shareDepositoryAccountNumber }
      : {};
    const shareDepositoryBank = this.requireShareDepoBank(
      this.state.exerciseType
    )
      ? { share_depository_bank: this.state.shareDepoBank }
      : {};
    const shareDepositoryClearingCode = this.requireShareDepoClearingCode(
      this.state.exerciseType
    )
      ? { share_depository_clearing_code: this.state.shareDepoClearingCode }
      : {};
    const shareDepoContact = this.requireShareDepoContact(
      this.state.exerciseType
    )
      ? { share_depository_contact: this.state.shareDepoContact }
      : {};
    const shareDepoDescription = this.requireShareDepoDescription(
      this.state.exerciseType
    )
      ? { share_depository_description: this.state.shareDepoDescription }
      : {};
    const taxPercentage = this.requireTaxPercentage(this.state.exerciseType)
      ? { tax_percentage: this.state.taxPercentage }
      : {};

    const bankAccount = this.requireBankAccount()
      ? { bank_account: this.state.bankAccountNumber }
      : {};
    const bicNumber = this.requireBankAccount()
      ? { bic_swift_number: this.state.bicNumber }
      : {};

    const order: ExerciseOrder = {
      exerciseType: this.state.exerciseType,
      exercise_order_lines: orderLines,
      ...shareDepositoryAccount,
      ...bankAccount,
      ...shareDepositoryBank,
      ...shareDepositoryClearingCode,
      ...bicNumber,
      ...shareDepoDescription,
      ...shareDepoContact,
      ...taxPercentage,
    };

    this.props.placeExerciseOrder({ order, window: this.props.window });
  };

  private requireShareDepo = (type: Api.V1.ExerciseOrder["exerciseType"]) =>
    (type === "EXERCISE_AND_HOLD" || type === "EXERCISE_AND_SELL_TO_COVER") &&
    this.props.window.require_share_depository;
  private requireShareDepoBank = (type: Api.V1.ExerciseOrder["exerciseType"]) =>
    this.requireShareDepo(type) &&
    this.props.window.require_share_depository_bank;
  private requireShareDepoClearingCode = (
    type: Api.V1.ExerciseOrder["exerciseType"]
  ) =>
    this.requireShareDepo(type) &&
    this.props.window.require_share_depository_clearing_code;
  private requireShareDepoContact = (
    type: Api.V1.ExerciseOrder["exerciseType"]
  ) =>
    this.requireShareDepo(type) &&
    this.props.window.require_share_depository_contact;
  private requireShareDepoDescription = (
    type: Api.V1.ExerciseOrder["exerciseType"]
  ) =>
    this.requireShareDepo(type) &&
    this.props.window.require_share_depository_description;
  private requireBankAccount = (
    type: Api.V1.ExerciseOrder["exerciseType"] = this.state.exerciseType
  ) =>
    (type === "EXERCISE_AND_SELL" && this.props.window.require_bank_account) ||
    (type === "EXERCISE_AND_SELL_TO_COVER" &&
      this.props.window.require_bank_account_for_tax_percentage);
  private requireTaxPercentage = (type: Api.V1.ExerciseOrder["exerciseType"]) =>
    type === "EXERCISE_AND_SELL_TO_COVER" &&
    this.props.window.require_tax_percentage;

  private handleVpsToggle = () => {
    this.setState(
      { vpsNotReady: !this.state.vpsNotReady },
      this.updateValidationState
    );
  };

  private handleBankAccountToggle = () => {
    const newValue = !this.state.bankAccountNotReady;
    this.setState(
      { bankAccountNotReady: newValue },
      this.updateValidationState
    );
  };

  private onshareDepositoryAccountNumberChanged = (
    shareDepositoryAccountNumber: string
  ) =>
    this.setState({ shareDepositoryAccountNumber }, this.updateValidationState);

  private onShareDepoBankNumberChanged = (shareDepoBank: string) =>
    this.setState({ shareDepoBank }, this.updateValidationState);

  private onShareDepoClearingCodeNumberChanged = (
    shareDepoClearingCode: string
  ) => this.setState({ shareDepoClearingCode }, this.updateValidationState);

  private onBankAccountNumberChanged = (bankAccountNumber: string) =>
    this.setState(
      {
        bankAccountNumber,
      },
      this.updateValidationState
    );

  private onShareDepoContactChanged = (shareDepoContact: string) =>
    this.setState({ shareDepoContact }, this.updateValidationState);

  private onShareDepoDescriptionChanged = (shareDepoDescription: string) =>
    this.setState({ shareDepoDescription }, this.updateValidationState);

  private onBicChanged = (bicNumber: string) =>
    this.setState({
      bicNumber,
    });

  private onTaxPercentageChanged = (taxPercentage: number) => {
    return this.setState(
      {
        taxPercentage,
      },
      this.updateValidationState
    );
  };

  private setQuantityForPlan = (quantities: string[]) => {
    const updatedPlans = this.state.orderInstruments.map((p, i) => {
      const orderAmount = parseInt(quantities[i], 10) || 0;
      const sharesAmount = Math.floor(orderAmount * p.sharesConversionFactor);
      return {
        ...p,
        orderAmount,
        sharesAmount,
        cappedSharesAmount: sharesAmount * p.capFactor,
      };
    });

    this.setState({ orderInstruments: updatedPlans });
  };

  private selectOrderExerciseType = (
    type: Api.V1.ExerciseOrder["exerciseType"]
  ) => this.setState({ exerciseType: type }, this.updateValidationState);

  private updateValidationState = () =>
    this.setState({ exerciseTypeFormHasErrors: !this.isFormValid(this.state) });

  private isFormValid = (state: State) => {
    const {
      exerciseType,
      shareDepoBank,
      shareDepoClearingCode,
      vpsNotReady,
      shareDepositoryAccountNumber,
      bankAccountNotReady,
      bankAccountNumber,
      shareDepoContact,
      shareDepoDescription,
      taxPercentage,
    } = state;

    const validTaxPercent =
      taxPercentage && taxPercentage >= 0 && taxPercentage <= 100;

    if (this.requireTaxPercentage(exerciseType) && validTaxPercent === false) {
      return false;
    }

    if (this.requireShareDepo(exerciseType)) {
      const shareDepoBankValid = this.requireShareDepoBank(exerciseType)
        ? shareDepoBank.length >= 3
        : true; // change to require shareDepoBank

      const shareDepoClearingCodeValid = this.requireShareDepoClearingCode(
        exerciseType
      )
        ? shareDepoClearingCode.length >= 3
        : true; // change to require shareDepoClearingCode

      const shareDepoContactValid = this.requireShareDepoContact(exerciseType)
        ? shareDepoContact.length >= 3
        : true; // change to require shareDepoContact

      const shareDepoDescriptionValid = this.requireShareDepoDescription(
        exerciseType
      )
        ? shareDepoDescription.length >= 3
        : true; // change to require shareDepoDescription

      const shareDepoValid =
        shareDepositoryAccountNumber &&
        shareDepositoryAccountNumber.length &&
        shareDepositoryAccountNumber.length >= 3;

      return (
        vpsNotReady ||
        (shareDepoValid &&
          shareDepoBankValid &&
          shareDepoClearingCodeValid &&
          shareDepoContactValid &&
          shareDepoDescriptionValid)
      );
    }

    if (this.requireBankAccount(exerciseType) && !bankAccountNotReady) {
      return (
        bankAccountNumber &&
        bankAccountNumber.length &&
        bankAccountNumber.length >= 3
      );
    }

    return true;
  };

  private handleStepIndicatorClick = (desitnationPage: ExerciseStepPage) =>
    this.setState({ currentPage: desitnationPage });
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      placeExerciseOrder,
      removeOrderError,
    },
    dispatch
  ),
});

const mapStateToProps: MapStateToProps<StateProps, null, RootState> = (
  state
): StateProps => ({
  userProfile: state.employeePortalProfile,
  isPlacingOrder: state.order.isPlacingOrder,
  orderError: state.order.error,
  exercisibleAwards: sortedExercisibleTranches(state),
  bankAccountNumberForExercise:
    state.user.tenant && state.user.tenant.bank_account_number,
  bicNumberForExercise: state.user.tenant && state.user.tenant.bic_number,
  ibanNumberForExercise: state.user.tenant && state.user.tenant.iban_number,
  shareDepositoryAccountForExercise:
    state.employeePortalProfile.share_depository_account,
  shareDepositoryContactForExercise:
    state.employeePortalProfile.share_depository_contact,
  shareDepositoryBankForExercise:
    state.employeePortalProfile.share_depository_bank,
  shareDepositoryDescriptionForExercise:
    state.employeePortalProfile.share_depository_description,
  payment_address: state.user.tenant && state.user.tenant.payment_address,
  employeeName: state.user.name,
  companyName: state.user.tenant && state.user.tenant.name,
  currencyCode: state.user.tenant.currency_code,
  dateFormat: state.dateFormat,
});

export default connect<StateProps, DispatchProps, PassedProps>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl<Props>(ExerciseRouter));
