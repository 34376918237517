import moment from "moment";
import React, { FunctionComponent } from "react";

import { Table } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import CurrencyConversionView from "src/common/components/currency/currency-conversion-view";
import { FlatAward } from "src/employee-portal/instrument-page/instruments-reducer";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface Props {
  award: FlatAward;
  showLockedTo: boolean;
  dateFormat: DateFormatState;
  totalGain: number;
}

const ConvertibleLoanLikeTableRow: FunctionComponent<Props> = ({
  award,
  showLockedTo,
  totalGain,
  dateFormat: { normalDateFormat },
}) => (
  <Table.Row>
    <Table.Cell>
      {" "}
      <div>{award.programName}</div>
      <div>{award.subProgramName}</div>
    </Table.Cell>
    <Table.Cell>{moment(award.grantDate).format(normalDateFormat)}</Table.Cell>
    {showLockedTo && (
      <Table.Cell>
        {award.lockedTo ? (
          award.lockedTo.format(normalDateFormat)
        ) : (
          <Content id="global.tableLabel.lockedTo.noLock" />
        )}
      </Table.Cell>
    )}
    <Table.Cell textAlign="right">
      <CurrencyConversionView value={totalGain} decimals={0} />
    </Table.Cell>
  </Table.Row>
);

export default ConvertibleLoanLikeTableRow;
