import Raven from "raven-js";
import { all, call, put, select, takeEvery } from "redux-saga/effects";

import {
  createEntities,
  createEntitiesSucceded,
  deleteEntities,
  deleteEntitiesSucceded,
  deleteEntity,
  deleteEntitySucceded,
  fetchEntities,
  fetchEntitiesSucceded,
  postEntity,
  postEntitySucceded,
  putEntity,
  putEntitySucceded,
} from "src/admin-portal/entity/entity-actions";
import * as selectors from "src/admin-portal/entity/entity-selectors";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import * as commonSelectors from "src/selectors";

const ENTITIES_REQUEST_URL = "/entities?tenantId=";
const OPTIONS_ENTITY_REQUEST_URL = "/entities/";

export function* fetchEntitiesStandalone() {
  const token = yield select(selectors.token);
  const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

  const entityResponse = yield call(() =>
    callApi(ENTITIES_REQUEST_URL + tenantId, token)
  );

  yield put(fetchEntitiesSucceded(entityResponse.data as Api.V1.Entity[]));
}

function* fetchEntitiesRequested(action: ReturnType<typeof fetchEntities>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(commonSelectors.tenantIdBothPortals);

    const entityResponse = yield call(() =>
      callApi(ENTITIES_REQUEST_URL + tenantId, token)
    );
    yield put(fetchEntitiesSucceded(entityResponse.data as Api.V1.Entity[]));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchEntities() {
  yield takeEvery(fetchEntities.getType(), fetchEntitiesRequested);
}

function* createEntitiesRequested(action: ReturnType<typeof createEntities>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";

    const entities = yield all(
      action.payload.map(entity =>
        call(() =>
          callApi(ENTITIES_REQUEST_URL + tenantId, token, method, entity)
        )
      )
    );
    yield put(
      createEntitiesSucceded(
        entities.map(entity => entity.data) as Api.V1.Entity[]
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchCreateEntities() {
  yield takeEvery(createEntities.getType(), createEntitiesRequested);
}

function* deleteEntitiesRequested(action: ReturnType<typeof deleteEntities>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "DELETE";

    const entities = yield all(
      action.payload.map(entity =>
        call(() =>
          callApi(
            OPTIONS_ENTITY_REQUEST_URL + entity.id + "?tenantId=" + tenantId,
            token,
            method
          )
        )
      )
    );

    yield put(
      deleteEntitiesSucceded(
        entities.map(entity => entity.data) as Array<Api.V1.Entity["id"]>
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchDeleteEntities() {
  yield takeEvery(deleteEntities.getType(), deleteEntitiesRequested);
}

function* postEntityRequested(action: ReturnType<typeof postEntity>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";

    const entityResponse = yield call(() =>
      callApi(ENTITIES_REQUEST_URL + tenantId, token, method, action.payload)
    );

    yield put(postEntitySucceded(entityResponse.data as Api.V1.Entity));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPostEntity() {
  yield takeEvery(postEntity.getType(), postEntityRequested);
}

function* deleteEntityRequested(action: ReturnType<typeof deleteEntity>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const entityId = action.payload;
    const method = "DELETE";

    yield call(() =>
      callApi(
        OPTIONS_ENTITY_REQUEST_URL + entityId + "?tenantId=" + tenantId,
        token,
        method
      )
    );
    yield put(deleteEntitySucceded(entityId));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchDeleteEntity() {
  yield takeEvery(deleteEntity.getType(), deleteEntityRequested);
}

function* putEntityRequested(action: ReturnType<typeof putEntity>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const entityId = action.payload.entityId;
    const method = "PUT";

    const entityResponse = yield call(() =>
      callApi(
        OPTIONS_ENTITY_REQUEST_URL + entityId + "?tenantId=" + tenantId,
        token,
        method,
        action.payload.entity
      )
    );
    yield put(putEntitySucceded(entityResponse.data as Api.V1.Entity));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPutEntity() {
  yield takeEvery(putEntity.getType(), putEntityRequested);
}
