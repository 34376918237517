import moment, { Moment } from "moment";
import React, { Component } from "react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Segment,
  Tab,
} from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { formatMessageWithDebugger } from "src/admin-portal/texts/utils";
import { ErrorModalClosable } from "src/common/error/error-modal-closable";
import { generateSupportedLanguagesOptions } from "src/common/utils/locale-utils";
import { validNorwegianShareDepoNumber } from "src/common/utils/share-depo";
import { putEmployeeProfileActionCreator } from "src/employee-portal/employee-portal-profile-actions";
import { tenantCurrencyCode } from "src/employee-portal/employee-portal-selectors";
import ShareDepoForm from "src/employee-portal/profile/share-depo-form";
import { RootState } from "src/reducers/all-reducers";

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = StateProps & DispatchProps;

interface State {
  share_depository_account?: string;
  share_depository_bank?: string;
  share_depository_contact?: string;
  share_depository_description?: string;
  share_depository_norwegian?: string;
  currency_code?: string;
  btnEnabled: boolean;
  btnLoader: boolean;
  fakeLastTimeSaved: Moment | string;
  locale?: string;
}

class ProfilePage extends Component<Props & InjectedIntlProps, State> {
  public state = {
    ...this.props.data,
    btnEnabled: false,
    btnLoader: false,
    fakeLastTimeSaved: "",
  };

  public UNSAFE_componentWillReceiveProps(nextProps) {
    !nextProps.data.error_put_profile && this.setState({ ...nextProps.data });

    if (
      this.state.share_depository_account ===
      nextProps.data.share_depository_account
    ) {
      this.setState({
        btnEnabled: false,
        btnLoader: false,
        fakeLastTimeSaved: moment().format("HH:mm:ss"),
      });
    }
  }

  public handleChange = ({ target }) => {
    this.setState(
      state =>
        ({
          [target.name]: target.value,
        } as Pick<State, keyof State>),
      () => {
        this.setState({ btnEnabled: this.hasValidChanges() });
      }
    );
  };

  public handleLocaleChange = locale => {
    this.setState(
      state => ({ locale }),
      () => {
        this.setState({ btnEnabled: this.hasValidChanges() });
      }
    );
  };

  public handleCurrencyChange = currency_code => {
    this.setState(
      state => ({ currency_code }),
      () => {
        this.setState({ btnEnabled: this.hasValidChanges() });
      }
    );
  };

  public handleSubmit = e => {
    this.setState({ btnLoader: true });
  };

  public generateLanguageOptions = () => {
    const { formatMessage } = this.props.intl;
    return generateSupportedLanguagesOptions(formatMessage).filter(
      o => o.value === "no" || o.value === "en"
    );
  };

  public generateCurrencyOptions = () => {
    const tenantCurrency = this.props.tenantCurrency;
    const USD = "USD";
    const EUR = "EUR";
    return [
      {
        key: tenantCurrency,
        value: tenantCurrency,
        text: tenantCurrency,
      },
      {
        key: USD,
        value: USD,
        text: USD,
      },
      {
        key: EUR,
        value: EUR,
        text: EUR,
      },
    ];
  };

  public render() {
    const {
      data,
      multipleLanguagesEnabled,
      currencyEnabled,
      intl: { formatMessage },
    } = this.props;
    const {
      share_depository_account,
      share_depository_bank,
      share_depository_description,
      share_depository_contact,
      share_depository_norwegian,
      btnEnabled,
      btnLoader,
      fakeLastTimeSaved,
      locale,
      currency_code,
    } = this.state;

    const isNorwegianCompany = currency_code === "NOK";

    return (
      <div className="main-content">
        <Grid container={true} stretched={true}>
          <Grid.Row centered={true}>
            <Grid.Column mobile={16} tablet={10} computer={7}>
              <h2 className="block-m text-center">
                <Content id="profile.title" />
              </h2>
              <Form onSubmit={this.handleSubmit}>
                <div className="block-l">
                  <Form.Field>
                    <label>
                      <Content id="profile.full.name.label" />
                    </label>
                    <input
                      name="full_name"
                      value={data.full_name}
                      disabled={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>
                      <Content id="profile.email.label" />
                    </label>
                    <input name="email" value={data.email} disabled={true} />
                  </Form.Field>
                  {multipleLanguagesEnabled && (
                    <Form.Field>
                      <label>
                        <Content id="profile.language.label" />
                      </label>
                      <Dropdown
                        placeholder={formatMessageWithDebugger(
                          "profile.language.placeholder",
                          formatMessage
                        )}
                        value={locale}
                        fluid={true}
                        selection={true}
                        options={this.generateLanguageOptions()}
                        onChange={(e, { value }) =>
                          this.handleLocaleChange(value)
                        }
                      />
                    </Form.Field>
                  )}
                  {currencyEnabled && (
                    <Form.Field>
                      <label>
                        <Content id="profile.currency.label" />
                      </label>
                      <Dropdown
                        placeholder={formatMessageWithDebugger(
                          "profile.currency.placeholder",
                          formatMessage
                        )}
                        value={currency_code}
                        fluid={true}
                        selection={true}
                        options={this.generateCurrencyOptions()}
                        onChange={(e, { value }) =>
                          this.handleCurrencyChange(value)
                        }
                      />
                    </Form.Field>
                  )}
                </div>
                <div className="block-l">
                  <Header>
                    <Content id={"profile.share.depository.header"} />
                  </Header>
                  <p>
                    <Content id={"profile.share.depository.description"} />
                  </p>
                  {isNorwegianCompany ? (
                    <Tab
                      menu={{ secondary: true, className: "row-center" }}
                      defaultActiveIndex={0}
                      panes={[
                        {
                          menuItem: {
                            name: formatMessage({
                              id: "profile.share.depository.tab.norwegian",
                            }),
                            content: formatMessage({
                              id: "profile.share.depository.tab.norwegian",
                            }),
                            className: "flex-1-0-auto-forced space-around",
                            color: "blue",
                          },
                          render: () => (
                            <>
                              <Form.Input
                                label={
                                  <label>
                                    <Content id="profile.share.depository.norwegian.label" />
                                  </label>
                                }
                                name="share_depository_account"
                                value={share_depository_account || ""}
                                placeholder={formatMessageWithDebugger(
                                  "profile.share.depository.norwegian.placeholder",
                                  formatMessage
                                )}
                                onChange={this.handleChange}
                              />
                            </>
                          ),
                        },
                        {
                          menuItem: {
                            name: formatMessage({
                              id: "profile.share.depository.tab.foreign",
                            }),
                            content: formatMessage({
                              id: "profile.share.depository.tab.foreign",
                            }),
                            className: "flex-1-0-auto-forced space-around",
                            color: "blue",
                          },
                          render: () => (
                            <ShareDepoForm
                              share_depository_account={
                                share_depository_account
                              }
                              share_depository_bank={share_depository_bank}
                              share_depository_contact={
                                share_depository_contact
                              }
                              share_depository_description={
                                share_depository_description
                              }
                              handleChange={this.handleChange}
                            />
                          ),
                        },
                      ]}
                    />
                  ) : (
                    <ShareDepoForm
                      share_depository_account={share_depository_account}
                      share_depository_bank={share_depository_bank}
                      share_depository_contact={share_depository_contact}
                      share_depository_description={
                        share_depository_description
                      }
                      handleChange={this.handleChange}
                    />
                  )}
                </div>
                <Button
                  type="submit"
                  loading={btnLoader && !data.error_put_profile}
                  onClick={this.updateEmployee}
                  color="blue"
                  disabled={!btnEnabled}
                >
                  <Content id="global.saveBtnTxt" />
                </Button>{" "}
                {fakeLastTimeSaved && (
                  <Content
                    id="profile.last.time.saved.message"
                    values={{ time: fakeLastTimeSaved }}
                  />
                )}
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered={true}>
            <Grid.Column mobile={16} tablet={10} computer={7}>
              <Segment basic={true}>
                <p>
                  <Content
                    id="profile.change.name.message"
                    values={{
                      email: formatMessageWithDebugger(
                        "global.contactEmail",
                        formatMessage
                      ),
                    }}
                  />
                </p>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {data.error_put_profile && (
          <ErrorModalClosable
            message="We're sorry, an error occurred while updating your profile"
            renderActions={() => (
              <div>
                <Link to={"/"} className="ui primary button">
                  <Icon name="home" /> Home
                </Link>
                <Button onClick={this.updateEmployee} color="blue">
                  <Icon name="refresh" /> Try Again
                </Button>
              </div>
            )}
          />
        )}
      </div>
    );
  }

  private hasValidChanges = () => {
    const {
      share_depository_account,
      share_depository_bank,
      share_depository_description,
      share_depository_contact,
      share_depository_norwegian,
      locale,
      currency_code,
    } = this.state;

    const isNorwegianChangedAndValid =
      share_depository_account !== this.props.data.share_depository_norwegian &&
      (validNorwegianShareDepoNumber(share_depository_account) ||
        share_depository_account === "");

    const isAccountChangedAndValid =
      share_depository_account !== this.props.data.share_depository_account &&
      share_depository_account.length > 2;

    const isBankChanged =
      share_depository_bank !== this.props.data.share_depository_bank;

    const isDescriptionChanged =
      share_depository_description !==
      this.props.data.share_depository_description;

    const isContactChanged =
      share_depository_contact !== this.props.data.share_depository_contact;

    const isLocaleChanged = locale !== this.props.data.locale;
    const isCurrencyChanged = currency_code !== this.props.data.currency_code;

    return (
      isNorwegianChangedAndValid ||
      isAccountChangedAndValid ||
      isBankChanged ||
      isDescriptionChanged ||
      isContactChanged ||
      isLocaleChanged ||
      isCurrencyChanged
    );
  };

  private updateEmployee = () => {
    const {
      share_depository_account,
      share_depository_bank,
      share_depository_description,
      share_depository_contact,
      share_depository_norwegian,
      locale,
      currency_code,
    } = this.state;

    this.props.putEmployeeProfile(
      this.props.data.user_id,
      share_depository_account,
      share_depository_bank,
      share_depository_description,
      share_depository_contact,
      share_depository_account,
      locale,
      currency_code
    );
  };
}

const mapStateToProps = (state: RootState) => ({
  data: state.employeePortalProfile,
  tenantCurrency: tenantCurrencyCode(state),
  multipleLanguagesEnabled: state.features.multiple_languages,
  currencyEnabled: state.features.currency,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  putEmployeeProfile: (
    userId,
    tempShareDepositoryAccount,
    tempShareDepositoryBank,
    tempShareDepositoryDescription,
    tempShareDepositoryContact,
    tempShareDepositoryNorwegian,
    locale,
    currency_code
  ) =>
    dispatch(
      putEmployeeProfileActionCreator(
        userId,
        tempShareDepositoryAccount,
        tempShareDepositoryBank,
        tempShareDepositoryDescription,
        tempShareDepositoryContact,
        tempShareDepositoryNorwegian,
        locale,
        currency_code
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl<Props>(ProfilePage));
