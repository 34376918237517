import React, { FunctionComponent, MouseEventHandler } from "react";

const ClickableLink: FunctionComponent<{ onClick: MouseEventHandler }> = ({
  onClick,
  children,
}) => (
  <a href="javascript:void(0)" onClick={onClick}>
    {children}
  </a>
);

export default ClickableLink;
