import { Jsona } from "jsona";
import { TJsonApiBody } from "jsona/lib/JsonaTypes";

import Raven from "raven-js";

import { store } from "src/app";
import { TQueryParams } from "src/common/api/types";
import { buildURL } from "src/common/utils/utils";
import { API_ROOT } from "src/env";
import { GENERAL_API_REQUEST_FAILED } from "src/reducers/general-api-request-reducer";

const HTTPMethod = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};
export enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export interface Request {
  method: Method;
  body?: RequestBody;
  url: string;
  token: string;
}

type RequestBody = CreateEmployeeDocumentBody;

interface CreateEmployeeDocumentBody {}

const settings = (method: string, body: any, token: string): RequestInit => {
  const bodyOrUndefined = body ? body : undefined; // Edge requires the body to be undefined on GET requests
  return {
    method: method ? method : HTTPMethod.GET,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    mode: "cors",
    body: bodyOrUndefined,
  };
};

export const NOT_AUTHORIZED = 401;
const checkHTTPStatus = (response): Promise<Response> => {
  return new Promise((resolve, reject) => {
    if (response.status == NOT_AUTHORIZED) {
      reject({
        errorMessage: "User not authorized",
        status: response.status,
        response,
      });
    }

    if (!response.ok) {
      reject({
        errorMessage: "Something wrong happened",
        status: response.status,
        response,
      });
      return;
    }

    resolve(response);
  });
};

export const callApi = <TResponse = {}>(
  endpoint: string,
  token?: string,
  method?: string,
  body?: object
): Promise<TResponse> => {
  const url = API_ROOT + endpoint;
  const jsonBody = body ? JSON.stringify(body) : null;

  return fetch(url, settings(method, jsonBody, token))
    .then(checkHTTPStatus)
    .then(response => {
      if (response.status === 204) {
        return {} as TResponse;
      }

      if (!response.headers.get("Content-Type").includes("json")) {
        return response;
      }

      return response.json();
    })
    .catch(error => {
      store.dispatch({ type: GENERAL_API_REQUEST_FAILED });
      console.error(error);
      throw error;
    });
};

type TCallJsonApiReturn<TReturn> = TReturn & {
  data: object;
  meta: object | null;
};

export type TJsonApiData = object | null;
export type TJsonApiMeta =
  | Record<string, string | number | Array<string | number>>
  | undefined;

export const callJsonApi = <
  TReturn extends {
    data: TJsonApiData;
    meta: TJsonApiMeta;
  }
>(
  endpoint: string | null,
  token?: string,
  method?: string,
  data?: object
): Promise<TReturn> => {
  if (endpoint === null) {
    return Promise.resolve({} as TReturn);
  }
  const body = data ? { data } : undefined;
  return callApi<TJsonApiBody & TReturn>(endpoint, token, method, body).then(
    response => {
      const dataFormatter = new Jsona();
      let data;
      try {
        data = dataFormatter.deserialize(response);
      } catch (e) {
        data = null;
      }
      return { data, meta: response.meta } as TReturn;
    }
  );
};

export function submitForm(endpoint: string, token: string, body: FormData) {
  const url = API_ROOT + endpoint;

  return fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body,
  })
    .then(checkHTTPStatus)
    .then(response => response.json())
    .catch(error => {
      console.error(error);
      throw error;
    });
}

export const composeApiUri = <TParams extends TQueryParams>(
  endpoint: string,
  params?: TParams
): string => {
  if (!params) {
    return endpoint;
  }

  return buildURL(endpoint, {
    ...params,
    include:
      params.include && Array.isArray(params.include)
        ? params.include.join(",")
        : undefined,
  });
};

export const swrKeyGetterFactory = token => (endpoint, params) => {
  if (!endpoint) {
    return null;
  }
  return [composeApiUri(endpoint, params), token];
};

export const errorHandlerFactory = dispatch => e => {
  if (e.status === NOT_AUTHORIZED) {
    dispatch({ type: "USER_NOT_AUTHORIZED" });
  } else {
    Raven.captureException(e);
  }
};
