import Jsona from "jsona";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  postDocumentAcceptance,
  postDocumentAcceptanceSucceeded,
  postDocumentAcceptanceFailed,
} from "src/admin-portal/actions/document-acceptances-actions";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { buildURL } from "src/common/utils/utils";
import * as selectors from "src/selectors";

const dataFormatter = new Jsona();

function* postDocumentAcceptanceRequested(
  action: ReturnType<typeof postDocumentAcceptance>
) {
  try {
    const token = yield select(selectors.token);
    const employeeId = yield select(state => state.user.id);
    const body = {
      data: {
        type: "documentAcceptances",
        attributes: {
          ...action.payload,
          employeeId,
        },
      },
    };

    const url = buildURL(
      `/documents-documentables/${action.payload.documentsDocumentableId}/document-acceptances`,
      {
        include: "documentsDocumentable",
      }
    );
    const response = yield call(() => callApi(url, token, "POST", body));
    yield put(
      postDocumentAcceptanceSucceeded(
        dataFormatter.deserialize(response) as Api.V1.DocumentAcceptance
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put(postDocumentAcceptanceFailed(e.message));
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPostDocumentAcceptance() {
  yield takeEvery(
    postDocumentAcceptance.getType(),
    postDocumentAcceptanceRequested
  );
}
