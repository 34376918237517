import Jsona from "jsona";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  fetchCustomRelationshipTypes,
  fetchCustomRelationshipTypesSucceeded,
  fetchCustomRelationshipTypesFailed,
  putCustomRelationshipType,
  putCustomRelationshipTypeSucceeded,
  deleteCustomRelationshipType,
  deleteCustomRelationshipTypeSucceeded,
  postCustomRelationshipType,
  postCustomRelationshipTypeSucceeded,
  putCustomRelationshipTypes,
  putCustomRelationshipTypesSucceeded,
} from "src/admin-portal/actions/custom-relationship-types-actions";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { batchRequests } from "src/common/sagas/batch-requests-saga";
import * as selectors from "src/selectors";

const dataFormatter = new Jsona();

const customRelationshipTypesUrl = (tenantId?: string, id?: string) =>
  id
    ? `/custom-relationship-types/${id}`
    : `/tenants/${tenantId}/custom-relationship-types`;

function* fetchCustomRelationshipTypesRequested(
  action: ReturnType<typeof fetchCustomRelationshipTypes>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const response = yield call(() =>
      callApi(customRelationshipTypesUrl(tenantId), token)
    );
    yield put(
      fetchCustomRelationshipTypesSucceeded(
        dataFormatter.deserialize(response) as Api.V1.CustomRelationshipType[]
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put(fetchCustomRelationshipTypesFailed(e.message));
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchCustomRelationshipTypes() {
  yield takeEvery(
    fetchCustomRelationshipTypes.getType(),
    fetchCustomRelationshipTypesRequested
  );
}

function* postCustomRelationshipTypeRequested(
  action: ReturnType<typeof postCustomRelationshipType>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";

    const body = {
      data: {
        type: "customRelationshipTypes",
        attributes: action.payload,
      },
    };

    const response = yield call(() =>
      callApi(customRelationshipTypesUrl(tenantId), token, method, body)
    );
    yield put(
      postCustomRelationshipTypeSucceeded(
        dataFormatter.deserialize(response) as Api.V1.CustomRelationshipType
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
    }
  }
}

export function* watchPostCustomRelationshipType() {
  yield takeEvery(
    postCustomRelationshipType.getType(),
    postCustomRelationshipTypeRequested
  );
}

function* putCustomRelationshipTypeRequested(
  action: ReturnType<typeof putCustomRelationshipType>
) {
  try {
    const token = yield select(selectors.token);
    const method = "PUT";

    const body = {
      data: {
        id: action.payload.id,
        type: "customRelationshipTypes",
        attributes: action.payload.data,
      },
    };

    const response = yield call(() =>
      callApi(
        customRelationshipTypesUrl(action.payload.id),
        token,
        method,
        body
      )
    );
    yield put(
      putCustomRelationshipTypeSucceeded(
        dataFormatter.deserialize(response) as Api.V1.CustomRelationshipType
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
    }
  }
}

export function* watchPutCustomRelationshipType() {
  yield takeEvery(
    putCustomRelationshipType.getType(),
    putCustomRelationshipTypeRequested
  );
}

function* deleteCustomRelationshipTypeRequested(
  action: ReturnType<typeof deleteCustomRelationshipType>
) {
  try {
    const token = yield select(selectors.token);
    const method = "DELETE";

    yield call(() =>
      callApi(customRelationshipTypesUrl(action.payload), token, method)
    );
    yield put(deleteCustomRelationshipTypeSucceeded(action.payload));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
    }
  }
}

export function* watchDeleteCustomRelationshipType() {
  yield takeEvery(
    deleteCustomRelationshipType.getType(),
    deleteCustomRelationshipTypeRequested
  );
}

function* putCustomRelationshipTypesRequested(
  action: ReturnType<typeof putCustomRelationshipTypes>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const promiseCreators = action.payload.map(c => () => {
      const body = {
        data: {
          id: c.id,
          type: "customRelationshipTypes",
          attributes: c.attributes,
        },
      };

      const request = callApi(
        customRelationshipTypesUrl(tenantId, c.id),
        token,
        c.method,
        body
      );

      return c.method === "delete"
        ? new Promise(resolve =>
            resolve({ data: { id: c.id, type: "customRelationshipTypes" } })
          )
        : request;
    });

    const response = yield batchRequests(20, 1000, promiseCreators);

    yield put(
      putCustomRelationshipTypesSucceeded(
        response.map(r =>
          dataFormatter.deserialize(r)
        ) as Api.V1.CustomRelationshipType[]
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
    }
  }
}

export function* watchPutCustomRelationshipTypes() {
  yield takeEvery(
    putCustomRelationshipTypes.getType(),
    putCustomRelationshipTypesRequested
  );
}
