import moment, { Moment } from "moment";
import { createReducer } from "redux-act";

import {
  deleteReport,
  deleteReportSucceeded,
  fetchReports,
  fetchReportsAllAwards,
  fetchReportsAllAwardsSucceeded,
  fetchReportsSucceeded,
  postReport,
  postReportSucceeded,
  updateReport,
  updateReportSucceeded,
} from "src/admin-portal/actions/reports-actions";
import { selectTenant } from "src/admin-portal/tenant/tenant-actions";

export interface ReportsState {
  readonly allReports: Api.V1.Report[];
  readonly tranches: Api.V1.VestingEvent[];
  readonly isFetching: boolean;
}

const mostRecentReportByToDate = (a, b) =>
  moment(b.toDate).unix() - moment(a.toDate).unix();

const sortReports = reports => reports.sort(mostRecentReportByToDate);

const initialState: ReportsState = {
  allReports: [],
  tranches: [],
  isFetching: false,
};

const setIsFetching = (state: ReportsState) => ({
  ...state,
  isFetching: true,
});

export default createReducer(on => {
  on(selectTenant, () => initialState);
  on(fetchReports, setIsFetching);
  on(postReport, setIsFetching);
  on(deleteReport, setIsFetching);
  on(updateReport, setIsFetching);
  on(fetchReportsAllAwards, setIsFetching);

  on(fetchReportsSucceeded, (state, payload) => {
    const allReports = sortReports(payload);
    return {
      ...state,
      ...{ allReports, isFetching: false },
    };
  });
  on(postReportSucceeded, (state, payload) => {
    const allReports = sortReports([payload, ...state.allReports]);
    return { ...state, allReports, isFetching: false };
  });
  on(updateReportSucceeded, (state, payload) => {
    const allReports = sortReports([
      payload,
      ...state.allReports.filter(r => r.id != payload.id),
    ]);
    return { ...state, allReports, isFetching: false };
  });
  on(deleteReportSucceeded, (state, payload) => {
    const allReports = sortReports(
      state.allReports.filter(r => r.id !== payload)
    );
    return { ...state, allReports, isFetching: false };
  });
  on(fetchReportsAllAwardsSucceeded, (state, payload) => ({
    ...state,
    tranches: payload,
    isFetching: false,
  }));
}, initialState);
