import moment from "moment";
import numeral from "numeral";
import React, { FunctionComponent } from "react";

import { Table } from "semantic-ui-react";

import CurrencyConversionView from "src/common/components/currency/currency-conversion-view";
import { CurrencyFormatterProps } from "src/common/components/currency/currency-formatter";
import { NO_VALUE } from "src/constants";
import { SettlementTypeCell } from "src/employee-portal/instrument-page/common/settlement-type-cell";
import { isFullyReleased } from "src/employee-portal/instrument-page/common/utils";
import { PerformanceYesPopup } from "src/employee-portal/instrument-page/instruments-page";
import { FlatAward } from "src/employee-portal/instrument-page/instruments-reducer";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface Props {
  award: FlatAward;
  stockPriceToday: number;
  showPerformance: boolean;
  showReleased: boolean;
  hasExpired: boolean;
  showSharePrice: boolean;
  isReleasedAtVesting: boolean;
  showSharesQuantity: boolean;
  formatter: CurrencyFormatterProps;
  dateFormat: DateFormatState;
  showCapOnGain: boolean;
  capped: boolean;
  totalGain: number;
  showSettlementType: boolean;
}

const RSUTableRow: FunctionComponent<Props> = ({
  award,
  stockPriceToday,
  showPerformance,
  hasExpired,
  showSharePrice,
  isReleasedAtVesting,
  showSharesQuantity,
  showReleased,
  showCapOnGain,
  totalGain,
  formatter: { formatCurrency, formatSharePrice },
  dateFormat: { normalDateFormat },
  capped,
  showSettlementType,
}) => (
  <Table.Row positive={isFullyReleased(award)}>
    <Table.Cell>
      {" "}
      <div>{award.programName}</div>
      <div>{award.subProgramName}</div>
    </Table.Cell>
    {showSettlementType && <SettlementTypeCell award={award} />}
    <Table.Cell>{moment(award.grantDate).format(normalDateFormat)}</Table.Cell>
    <Table.Cell>{award.vestedDate.format(normalDateFormat)}</Table.Cell>
    {!isReleasedAtVesting && (
      <Table.Cell>{award.expiryDate.format(normalDateFormat)}</Table.Cell>
    )}
    <Table.Cell textAlign="right">
      {award.originalQuantity > 0
        ? numeral(award.originalQuantity).format()
        : NO_VALUE}
    </Table.Cell>
    {showReleased && (
      <Table.Cell textAlign="right">
        {award.releasedQuantity < 0
          ? numeral(Math.abs(award.releasedQuantity)).format()
          : NO_VALUE}
      </Table.Cell>
    )}
    {showSharesQuantity && (
      <Table.Cell textAlign="right">
        {numeral(award.quantity).format()}
      </Table.Cell>
    )}
    {showSharePrice && (
      <Table.Cell>
        {formatSharePrice(award.share_price || stockPriceToday)}
      </Table.Cell>
    )}
    {showCapOnGain && (
      <Table.Cell singleLine={true} warning={capped}>
        {award.capOnGain ? (
          <CurrencyConversionView value={award.capOnGain} decimals={2} />
        ) : (
          NO_VALUE
        )}
      </Table.Cell>
    )}
    <Table.Cell textAlign="right">
      <CurrencyConversionView value={totalGain} decimals={0} />
    </Table.Cell>
    {showPerformance && (
      <Table.Cell>
        {award.performance ? (
          <span>
            Yes <PerformanceYesPopup />
          </span>
        ) : (
          "No"
        )}
      </Table.Cell>
    )}
  </Table.Row>
);

export default RSUTableRow;
