import moment, { DurationInputArg2, Moment } from "moment";

import { Tranche } from "src/admin-portal/awards/award-reducer";
import { TurnoverMethod } from "src/admin-portal/reports/constants";
import {
  Interval,
  overlappingDays,
} from "src/admin-portal/reports/generate-report/common/date";
import { calcEarnedRate } from "src/admin-portal/reports/generate-report/common/earned-calc";
import { calcPeriodLengths } from "src/admin-portal/reports/generate-report/common/period-length-calc";
import { TrancheState } from "src/admin-portal/reports/generate-report/common/types";
import {
  calcTotalTurnoverRate,
  turnoverEffectFromEarnedRate,
} from "src/admin-portal/reports/generate-report/ifrs-cost-spec/cost-spec-calc";
import { sortBy } from "src/common/utils/sort";
import {
  apiShortDate,
  norwegianShortDateLongYear,
  notNullOrUndefined,
  sumNumbers,
  toNumberOrNull,
} from "src/common/utils/utils";
import { TransactionType } from "src/constants";

export enum ReportType {
  IFRS_COST = "IFRS_COST",
  SOC_SEC = "SOC_SEC",
}

const hasFinalizedPerformance = (
  date: Moment,
  transactions: Api.V1.Transaction[]
) => {
  const validTransactions = transactions.filter(sameOrBefore(date));
  return validTransactions.some(
    transaction =>
      transaction.transactionType === TransactionType.ADJUSTMENT_PERFORMANCE
  );
};

const maxPerformanceFactorOfDate = (
  date: Moment,
  tranche: Tranche,
  reportType: ReportType
): number | null => {
  if (
    !tranche.tranchePerformanceRules ||
    !tranche.tranchePerformanceRules[0] ||
    !tranche.tranchePerformanceRules[0].performanceRule
  ) {
    return null;
  }

  const maxPerformanceFactorString =
    tranche.tranchePerformanceRules[0].performanceRule.maxPerformanceFactor;
  const maxPerfFactor = maxPerformanceFactorString
    ? parseFloat(maxPerformanceFactorString)
    : 1;
  return hasFinalizedPerformance(date, tranche.transactions)
    ? performanceFactorOfDate(date, tranche, reportType)
    : maxPerfFactor;
};

const mostRecentPerformanceRuleEntry = (
  performanceRule: Api.V1.PerformanceRule,
  date: Moment
) => {
  const sortedEntries = sortBy(
    "date",
    performanceRule.performanceRuleEntries
  ).reverse();

  return sortedEntries.find(entry =>
    moment(entry.date, apiShortDate).isSameOrBefore(date)
  );
};

const performanceFactorOfDate = (
  date: Moment,
  tranche: Tranche,
  reportType: ReportType
) => {
  if (
    !tranche.tranchePerformanceRules ||
    !tranche.tranchePerformanceRules[0] ||
    !tranche.tranchePerformanceRules[0].performanceRule
  ) {
    return null;
  }
  const performanceRule = tranche.tranchePerformanceRules[0].performanceRule;
  const performanceRuleEntry = mostRecentPerformanceRuleEntry(
    performanceRule,
    date
  );

  if (performanceRuleEntry) {
    return parseFloat(
      reportType === ReportType.IFRS_COST
        ? performanceRuleEntry.costAccountingProbabilityFactor
        : performanceRuleEntry.socSecAccountingProbabilityFactor
    );
  }

  return null;
};

const sameOrBefore = (date: Moment) => (transaction: Api.V1.Transaction) =>
  moment(transaction.transactionDate, apiShortDate).isSameOrBefore(date);

const toMoment = (date: string | null) =>
  date && moment(date).isValid() ? moment(date) : null;

export const trancheStateAtDate = (
  tranche: Tranche,
  date: Moment,
  reportType: ReportType
): TrancheState | null => {
  const validTransactions = tranche.transactions.filter(sameOrBefore(date));

  if (validTransactions.length === 0) {
    return null;
  }

  const grantDate = pickLatestProperty(validTransactions, "grantDate");
  const vestedDate = pickLatestProperty(validTransactions, "vestedDate");
  const expiryDate = pickLatestProperty(validTransactions, "expiryDate");
  const overriddenCostStartDate = pickLatestProperty(
    validTransactions,
    "costStartDate"
  );
  const overriddenCostEndDate = pickLatestProperty(
    validTransactions,
    "costEndDate"
  );

  const validModifications = validTransactions.filter(t =>
    t.transactionType.startsWith("MODIFICATION")
  );
  const modificationStartDate = toMoment(
    pickLatestProperty(validModifications, "modificationStartDate")
  );
  const modificationEndDate = toMoment(
    pickLatestProperty(validModifications, "modificationEndDate")
  );
  // Use all valid transactions here since Exercise transactions for cash-settled instruments should affect the quantity
  const modificationQuantity = sumProperties(
    validTransactions,
    "modificationQuantity",
    null
  );
  // Use all valid transactions here since adjustments to the FV is needed for cash-settled instruments
  const modificationFV = toNumberOrNull(
    pickLatestProperty(validTransactions, "modificationFairValue")
  );

  const defaultEarnedStartDate = grantDate && moment(grantDate, apiShortDate);
  const defaultEarnedEndDate = latestDate(
    moment(grantDate, apiShortDate),
    moment(vestedDate, apiShortDate)
  );

  const costStartDate = overriddenCostStartDate
    ? moment(overriddenCostStartDate, apiShortDate)
    : defaultEarnedStartDate;
  const costEndDate = overriddenCostEndDate
    ? moment(overriddenCostEndDate, apiShortDate)
    : defaultEarnedEndDate;

  const overriddenSocSecStartDate = pickLatestProperty(
    validTransactions,
    "socSecStartDate"
  );
  const overriddenSocSecEndDate = pickLatestProperty(
    validTransactions,
    "socSecEndDate"
  );

  const socSecStartDate = overriddenSocSecStartDate
    ? moment(overriddenSocSecStartDate, apiShortDate)
    : defaultEarnedStartDate;
  const socSecEndDate = overriddenSocSecEndDate
    ? moment(overriddenSocSecEndDate, apiShortDate)
    : defaultEarnedEndDate;

  const terminationDate = pickLatestProperty(
    validTransactions,
    "terminationDate"
  );
  const costAffectingTransactions = validTransactions.filter(
    (t, index) =>
      index === 0 ||
      moment(t.transactionDate, apiShortDate).isBefore(costEndDate) ||
      (tranche.isCashSettled &&
        moment(t.transactionDate, apiShortDate).isSameOrBefore(date)) ||
      (t.terminationDate &&
        moment(t.terminationDate, apiShortDate).isBefore(costEndDate)) // To allow cost reversing terminations where transaction date is after cost end. We can make this general for all transactions later if needed
  );

  const maxPerformanceFactor = maxPerformanceFactorOfDate(
    date,
    tranche,
    reportType
  );
  const finalisedPerformance = hasFinalizedPerformance(
    date,
    tranche.transactions
  );
  const trancheQuantity = sumProperties(validTransactions, "quantity");
  const quantity = Math.round(trancheQuantity * (maxPerformanceFactor || 1));
  const finalizedPerformanceReduction = finalisedPerformance
    ? trancheQuantity - quantity
    : 0;
  const performanceFactor = performanceFactorOfDate(date, tranche, reportType);
  const trancheQuantityCost = sumProperties(
    costAffectingTransactions,
    "quantity"
  );
  const quantityCost =
    typeof performanceFactor === "number"
      ? finalisedPerformance
        ? Math.round(performanceFactor * trancheQuantityCost)
        : performanceFactor * trancheQuantityCost
      : trancheQuantityCost;
  const strike = pickLatestProperty(validTransactions, "strike");
  const capOnGain = pickLatestProperty(validTransactions, "capOnGain");
  return {
    grantDate: grantDate && moment(grantDate, apiShortDate),
    vestedDate: vestedDate && moment(vestedDate, apiShortDate),
    expiryDate: expiryDate && moment(expiryDate, apiShortDate),
    costStartDate,
    costEndDate,
    socSecStartDate,
    socSecEndDate,
    terminationDate: terminationDate && moment(terminationDate, apiShortDate),
    capOnGain: capOnGain ? parseFloat(capOnGain) : undefined,
    quantity,
    quantityCost,
    strike: strike ? parseFloat(strike) : undefined,
    fairValue: parseFloat(pickLatestProperty(validTransactions, "fairValue")),
    fairValueCost: parseFloat(
      pickLatestProperty(costAffectingTransactions, "fairValue")
    ),
    fairValueForSocSec: parseFloat(
      pickLatestProperty(validTransactions, "fairValueForSocSec")
    ),
    fvssValuationMethod: pickLatestProperty(
      validTransactions,
      "fvssValuationMethod"
    ),
    fvssSharePriceAtGrant: pickLatestProperty(
      validTransactions,
      "fvssSharePriceAtGrant"
    ),
    fvssStrikePrice: pickLatestProperty(validTransactions, "fvssStrikePrice"),
    fvssExpectedLifetime: pickLatestProperty(
      validTransactions,
      "fvssExpectedLifetime"
    ),
    fvssVolatility: pickLatestProperty(validTransactions, "fvssVolatility"),
    fvssInterestRate: pickLatestProperty(validTransactions, "fvssInterestRate"),
    fvssDividend: pickLatestProperty(validTransactions, "fvssDividend"),
    newQuantityFactor: sumProperties(validTransactions, "newQuantityFactor", 1),
    instrumentName: tranche.instrumentName,
    performanceFactor: finalisedPerformance ? null : performanceFactor,
    maxPerformanceFactor,
    finalizedPerformanceReduction,
    modificationStartDate,
    modificationEndDate,
    modificationFV,
    modificationQuantity,
  };
};

export const pickLatestProperty = (
  transactions: Api.V1.Transaction[],
  property: keyof Api.V1.Transaction
): string | undefined => {
  const validProperties = transactions.filter(t =>
    notNullOrUndefined(t[property])
  );
  const latest = validProperties[validProperties.length - 1] as
    | Api.V1.Transaction
    | undefined;
  return latest && (latest[property] as string);
};

export const sumProperties = (
  transactions: Api.V1.Transaction[],
  property: keyof Api.V1.Transaction,
  defaultValue: number | undefined = null
): number => {
  const valueExists = transactions.some(t => notNullOrUndefined(t[property]));

  if (valueExists) {
    return transactions
      .map(t => (t[property] && Number(t[property])) || 0)
      .reduce(sumNumbers);
  }

  return defaultValue;
};

export const getCostInterval = (tranche: TrancheState): Interval => {
  return { start: tranche.costStartDate, end: tranche.costEndDate };
};

export const costEarnedRatesAtDate = (
  date: Moment,
  tranche: TrancheState,
  mobility: Api.V1.MobilityEntry
) =>
  earnedRatesAtDate(date, mobility, {
    start: tranche.costStartDate,
    end: tranche.costEndDate,
  });

export const socSecEarnedRatesAtDate = (
  date: Moment,
  tranche: TrancheState,
  mobility: Api.V1.MobilityEntry
) =>
  earnedRatesAtDate(date, mobility, {
    start: tranche.socSecStartDate,
    end: tranche.socSecEndDate,
  });

export const earnedRatesAtDate = (
  date: Moment,
  mobility: Api.V1.MobilityEntry,
  earnedInterval: Interval
) => {
  const totalEarningDays = overlappingDays(earnedInterval, {
    start: earnedInterval.start,
    end: date,
  });

  const mobilityInterval: Interval = {
    start: moment(mobility.fromDate, apiShortDate),
    end: moment(mobility.toDate, apiShortDate),
  };

  const mobilityEarningDays = overlappingDays(earnedInterval, mobilityInterval);

  const earnedMobilityStartDate = mobilityInterval.start.isAfter(
    earnedInterval.start
  )
    ? mobilityInterval.start
    : earnedInterval.start;

  const earnedMobilityEndDate =
    mobilityInterval.end.isAfter(earnedInterval.end) ||
    date.isBetween(mobilityInterval.start, mobilityInterval.end, null, "[]")
      ? earnedInterval.end
      : mobilityInterval.end;

  const { obDays } = calcPeriodLengths(
    earnedMobilityStartDate,
    earnedMobilityEndDate,
    date,
    date,
    mobilityInterval
  );

  const earnedRate = calcEarnedRate(
    earnedInterval.start,
    earnedInterval.end,
    obDays
  );

  const earnedRateMobility = calcEarnedRate(
    earnedMobilityStartDate,
    earnedMobilityEndDate,
    obDays
  );

  return {
    earnedRate,
    earnedRateMobility,
    earnedDays: totalEarningDays,
    earnedDaysMobility: mobilityEarningDays,
  };
};

export const intervalDays = (interval: Interval): number =>
  interval.end.diff(interval.start, "days") + 1;

export const latestDate = (date1: Moment, date2: Moment) =>
  date1.isBefore(date2) ? date2 : date1;

export const calculateTurnover = (
  tranche: TrancheState,
  turnoverStartDate: Moment,
  annualTurnover: number,
  turnoverMethod: TurnoverMethod,
  earnedRate: number
) => {
  const totalTurnoverRate =
    turnoverMethod === TurnoverMethod.FLAT
      ? null
      : calcTotalTurnoverRate(
          turnoverStartDate,
          tranche.costEndDate,
          annualTurnover
        );

  const turnoverEffect =
    turnoverMethod === TurnoverMethod.FLAT
      ? annualTurnover * (tranche.quantity && earnedRate !== 1 ? 1 : 0)
      : turnoverEffectFromEarnedRate(totalTurnoverRate, earnedRate) *
        (tranche.quantity ? 1 : 0);

  return {
    totalTurnoverRate,
    turnoverEffect,
  };
};

export const genConnectedDates = (
  date: Moment,
  numOfDates: number,
  durationStr: DurationInputArg2
): string => {
  let counter = 1;
  let out = `${date.format(apiShortDate)}`;
  while (counter < numOfDates) {
    out += `,${moment(date)
      .subtract(counter, durationStr)
      .format(apiShortDate)}`;
    counter++;
  }

  return out;
};
