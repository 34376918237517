import { createAction } from "redux-act";

import { ContentUpdateObject } from "src/admin-portal/content/content-reducer";

export const fetchContents = createAction("FETCH_CONTENTS");

export const fetchContentsSucceded = createAction(
  "FETCH_CONTENTS_SUCCEEDED",
  (payload: Api.V1.ContentTemplate[]) => payload
);

export const updateContent = createAction(
  "UPDATE_CONTENT",
  (payload: {
    contentId: Api.V1.ContentTemplate["id"];
    content: ContentUpdateObject;
  }) => payload
);

export const updateContentSucceeded = createAction(
  "UPDATE_CONTENT_SUCCEEDED",
  (payload: Api.V1.ContentTemplate) => payload
);

export const postContentTemplateValues = createAction(
  "POST_CONTENT_TEMPLATE_VALUES",
  (payload: {
    contentId: Api.V1.ContentTemplate["id"];
    contentTemplateValue: Api.V1.ContentTemplateValue;
  }) => payload
);

export const postContentTemplateValuesSucceeded = createAction(
  "POST_CONTENT_TEMPLATE_VALUES_SUCCEEDED",
  (payload: Api.V1.ContentTemplateValue) => payload
);

export const patchContentTemplateValues = createAction(
  "PATCH_CONTENT_TEMPLATE_VALUES",
  (payload: {
    contentTemplateValueId: Api.V1.ContentTemplateValue["id"];
    contentTemplateValue: Api.V1.ContentTemplateValue;
  }) => payload
);

export const patchContentTemplateValuesSucceeded = createAction(
  "PATCH_CONTENT_TEMPLATE_VALUES_SUCCEEDED",
  (payload: Api.V1.ContentTemplateValue) => payload
);

export const deleteContentTemplateValues = createAction(
  "DELETE_CONTENT_TEMPLATE_VALUES",
  (payload: {
    contentTemplateId: Api.V1.ContentTemplate["id"];
    contentTemplateValueId: Api.V1.ContentTemplateValue["id"];
  }) => payload
);

export const deleteContentTemplateValuesSucceeded = createAction(
  "DELETE_CONTENT_TEMPLATE_VALUES_SUCCEEDED",
  (payload: {
    contentTemplateId: Api.V1.ContentTemplate["id"];
    contentTemplateValueId: Api.V1.ContentTemplateValue["id"];
  }) => payload
);
