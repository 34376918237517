import React, { FunctionComponent } from "react";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";

import { useMessageExists } from "src/admin-portal/texts/utils";
import { useContentContext } from "src/common/components/content/content-contex";

export interface ContentProps extends FormattedMessage.Props {
  showKeys?: boolean;
}

const usePrefixedOrFallback = (id: string, prefix: string) => {
  const exists = useMessageExists();
  const prefixed = `${prefix}.${id}`;
  return exists(prefixed) ? prefixed : id;
};

const Content: FunctionComponent<ContentProps> = ({ id, ...restProps }) => {
  const contentContext = useContentContext();
  const finalId = usePrefixedOrFallback(id, contentContext?.contentPrefix);

  if (window.location.href.includes("showKeys=true")) {
    return (
      <div className="formatted-html-message-container">
        [{id}] <FormattedHTMLMessage {...restProps} id={finalId} />
      </div>
    );
  }

  return (
    <div className="formatted-html-message-container">
      <FormattedHTMLMessage {...restProps} id={finalId} />
    </div>
  );
};

export default Content;
