import { all } from "redux-saga/effects";

import { watchFetchAuditLogs } from "src/admin-portal/audit-logs/audit-logs-saga";
import {
  watchAddAwardVesting,
  watchDeleteAward,
  watchFetchTenantVestingEvents,
  watchFetchTrancheRequested,
  watchPostAward,
  watchPutAward,
  watchPutAwards,
} from "src/admin-portal/awards/award-saga";
import {
  watchCreateTransactions,
  watchDeleteTransaction,
  watchUpdateTransactions,
} from "src/admin-portal/awards/transaction/transaction-saga";
import {
  watchDeleteContentTemplateValues,
  watchFetchContent,
  watchPatchContentTemplateValues,
  watchPostContentTemplateValues,
  watchUpdateContent,
} from "src/admin-portal/content/content-saga";
import {
  watchCreateDividend,
  watchFetchDividends,
} from "src/admin-portal/dividend/dividend-saga";
import { watchPostEmail } from "src/admin-portal/email/send-email-saga";
import {
  watchCreateEmployees,
  watchDeleteEmployee,
  watchDeleteEmployees,
  watchFetchEmployee,
  watchFetchEmployeeAndEntities,
  watchFetchEmployees,
  watchFetchEmployeesForExport,
  watchFetchEmployeesPaginated,
  watchFetchEntitiesAndEmployees,
  watchImportEditedEmployees,
  watchPostEmployee,
  watchPutEmployee,
  watchReverseTerminateEmployee,
  watchTerminateEmployee,
  watchTerminateEmployeeCustom,
  watchFetchEmployeesWithPagination,
} from "src/admin-portal/employees/employee-saga";
import {
  watchCreateEntities,
  watchDeleteEntities,
  watchDeleteEntity,
  watchFetchEntities,
  watchPostEntity,
  watchPutEntity,
} from "src/admin-portal/entity/entity-saga";
import {
  watchDeleteExerciseWindow,
  watchFetchExerciseWindow,
  watchFetchExerciseWindowDetails,
  watchFetchWindowOrdersRequested,
  watchPostExerciseWindow,
  watchPutExerciseWindow,
  watchWindowCompleteCashlessOrders,
  watchWindowCompleteExerciseAndHoldOrders,
  watchWindowCompleteSaleCashlessOrders,
  watchWindowStateStartProcessingRequested,
} from "src/admin-portal/exercise-windows/window-saga";
import { watchImportAllModels } from "src/admin-portal/import/import-saga";
import { watchFetchInvoices } from "src/admin-portal/invoices/invoices-saga";
import {
  watchFetchTenantOrder,
  watchPutTenantOrder,
} from "src/admin-portal/orders/ordersSaga";
import {
  watchDeletePerformanceRuleEntry,
  watchPatchPerformanceRuleEntry,
  watchPerformanceRuleEntries,
  watchPostPerformanceRuleEntry,
} from "src/admin-portal/performance-rules/performance-rule-entries-page/performance-rule-entries-saga";
import {
  watchDeletePerformanceRule,
  watchPatchPerformanceRule,
  watchPatchTranchePerformanceRule,
  watchPerformanceRules,
  watchPostPerformanceRule,
} from "src/admin-portal/performance-rules/performance-rules-page/performance-rules-saga";
import {
  watchAddProgram,
  watchAddSubProgram,
  watchDeleteAllPrograms,
  watchDeleteProgram,
  watchFetchEmployeesAndPrograms,
  watchFetchPrograms,
  watchFetchProgramsAndSubprograms,
  watchImportAllProgramAwards,
  watchPostProgram,
  watchPutProgram,
} from "src/admin-portal/programs/program-saga";
import {
  watchDeleteReportConfig,
  watchFetchReportConfigs,
  watchPatchReportConfig,
  watchPostReportConfig,
} from "src/admin-portal/reports/reports-config/reports-configs-saga";
import {
  watchAddVesting,
  watchDeleteSubProgram,
  watchFetchSubProgram,
  watchGenerateSubProgramAgreements,
  watchPostSubProgram,
  watchPutSubProgram,
} from "src/admin-portal/subprograms/subprogram-saga";
import { watchFetchDailySummaries } from "src/admin-portal/summary/summary-saga";
import {
  watchDeleteSysadmin,
  watchFetchSysadmins,
  watchPostSysadmin,
} from "src/admin-portal/sysadmin/sysadmin-saga";
import {
  watchDeleteTenant,
  watchDeleteTenantSucceeded,
  watchFetchLiveTenantsOverview,
  watchFetchTenants,
  watchPostTenant,
  watchPutTenant,
  watchPutTenantSucceeded,
  watchSelectTenant,
} from "src/admin-portal/tenant/tenant-saga";
import {
  watchFetchTexts,
  watchPutTexts,
  watchUpdateDefaultTexts,
} from "src/admin-portal/texts/text-saga";
import {
  watchLoginRequested,
  watchLogoutRequested,
  watchParseAuthHash,
} from "src/common/auth/auth-saga";
import { watchKeepAlive } from "src/common/auth/keep-alive-saga";
import { watchPutEmployeeProfile } from "src/employee-portal/employee-portal-profile-saga";
import { watchFethEmployeePortalWelcome } from "src/employee-portal/employee-portal-welcome-saga";
import {
  watchCreatePurchaseOrder,
  watchFetchPurchaseDocument,
} from "src/employee-portal/purchase/duck/purchase-saga";
import {
  watchFetchUsersOrders,
  watchPlaceExerciseOrder,
  watchPutExerciseOrder,
  watchPutPurchaseOrder,
} from "src/employee-portal/sagas/order";
import {
  watchFetchContracts,
  watchPostContracts,
} from "src/sagas/contracts-saga";
import {
  watchFetchCustomRelationshipTypes,
  watchPutCustomRelationshipType,
  watchDeleteCustomRelationshipType,
  watchPostCustomRelationshipType,
  watchPutCustomRelationshipTypes,
} from "src/sagas/custom-relationship-types-saga";
import { watchPostDocumentAcceptance } from "src/sagas/document-acceptances-saga";
import {
  watchCreatePurchaseConfig,
  watchDeletePurchaseConfig,
  watchUpdatePurchaseConfig,
} from "src/sagas/purchase-saga";
import {
  watchDeleteReport,
  watchFetchReports,
  watchFetchReportsAllAwards,
  watchGenerateReport,
  watchPostReport,
  watchUpdateReport,
} from "src/sagas/reports-saga";
import {
  watchDeleteSharePrice,
  watchFetchPrimarySharePriceTicker,
  watchFetchSharePrice,
  watchPostSharePrice,
  watchPutPrimarySharePriceTicker,
  watchFetchAnnualVolatility,
  watchFetchAverageSharePrice,
  watchFetchAnnualVolatilityBulk,
} from "src/sagas/share-price-saga";
import { watchFetchSelfServiceOverview } from "src/self-service-portal/self-service-saga";

export default function* root() {
  yield all([
    watchFetchTenants(),
    watchPostTenant(),
    watchSelectTenant(),
    watchPutTenant(),
    watchPutTenantSucceeded(),
    watchDeleteTenant(),
    watchDeleteTenantSucceeded(),
    watchFetchLiveTenantsOverview(),
    watchFetchEntities(),
    watchPostEntity(),
    watchDeleteEntity(),
    watchPutEntity(),
    watchCreateEntities(),
    watchDeleteEntities(),
    watchFetchEmployees(),
    watchFetchEmployeesForExport(),
    watchFetchEmployeesPaginated(),
    watchFetchEmployee(),
    watchFetchEmployeeAndEntities(),
    watchPostEmployee(),
    watchDeleteEmployee(),
    watchPutEmployee(),
    watchCreateEmployees(),
    watchImportEditedEmployees(),
    watchDeleteEmployees(),
    watchFetchEntitiesAndEmployees(),
    watchFetchSysadmins(),
    watchPostSysadmin(),
    watchDeleteSysadmin(),
    watchAddProgram(),
    watchAddSubProgram(),
    watchAddVesting(),
    watchCreatePurchaseConfig(),
    watchUpdatePurchaseConfig(),
    watchUpdateDefaultTexts(),
    watchDeletePurchaseConfig(),
    watchFetchPrograms(),
    watchFetchProgramsAndSubprograms(),
    watchPostProgram(),
    watchPutProgram(),
    watchDeleteProgram(),
    watchDeleteAllPrograms(),
    watchFetchEmployeesAndPrograms(),
    watchImportAllProgramAwards(),
    watchPostSubProgram(),
    watchFetchSubProgram(),
    watchPutSubProgram(),
    watchDeleteSubProgram(),
    watchFethEmployeePortalWelcome(),
    watchPutEmployeeProfile(),
    watchPostAward(),
    watchPutAward(),
    watchPutAwards(),
    watchFetchTrancheRequested(),
    watchDeleteAward(),
    watchFetchDividends(),
    watchCreateDividend(),
    watchFetchTenantVestingEvents(),
    watchAddAwardVesting(),
    watchCreateTransactions(),
    watchUpdateTransactions(),
    watchDeleteTransaction(),
    watchPerformanceRules(),
    watchPostPerformanceRule(),
    watchPatchPerformanceRule(),
    watchDeletePerformanceRule(),
    watchPatchTranchePerformanceRule(),
    watchPerformanceRuleEntries(),
    watchPostPerformanceRuleEntry(),
    watchPatchPerformanceRuleEntry(),
    watchDeletePerformanceRuleEntry(),
    watchPostEmail(),
    watchParseAuthHash(),
    watchPostSharePrice(),
    watchPutPrimarySharePriceTicker(),
    watchCreatePurchaseOrder(),
    watchFetchPurchaseDocument(),
    watchReverseTerminateEmployee(),
    watchFetchContent(),
    watchImportAllModels(),
    watchUpdateContent(),
    watchPostContentTemplateValues(),
    watchDeleteContentTemplateValues(),
    watchPatchContentTemplateValues(),
    watchFetchPrimarySharePriceTicker(),
    watchFetchSharePrice(),
    watchWindowStateStartProcessingRequested(),
    watchTerminateEmployee(),
    watchTerminateEmployeeCustom(),
    watchDeleteSharePrice(),
    watchPlaceExerciseOrder(),
    watchFetchUsersOrders(),
    watchPostExerciseWindow(),
    watchFetchExerciseWindow(),
    watchFetchExerciseWindowDetails(),
    watchDeleteExerciseWindow(),
    watchPutExerciseWindow(),
    watchParseAuthHash(),
    watchKeepAlive(),
    watchFetchTenantOrder(),
    watchLoginRequested(),
    watchLogoutRequested(),
    watchPutTenantOrder(),
    watchFetchTexts(),
    watchPutTexts(),
    watchFetchReports(),
    watchPostReport(),
    watchDeleteReport(),
    watchUpdateReport(),
    watchFetchReportConfigs(),
    watchPostReportConfig(),
    watchPatchReportConfig(),
    watchDeleteReportConfig(),
    watchFetchWindowOrdersRequested(),
    watchFetchSelfServiceOverview(),
    watchFetchReportsAllAwards(),
    watchWindowCompleteExerciseAndHoldOrders(),
    watchWindowCompleteCashlessOrders(),
    watchWindowCompleteSaleCashlessOrders(),
    watchGenerateReport(),
    watchGenerateSubProgramAgreements(),
    watchFetchAuditLogs(),
    watchFetchAnnualVolatility(),
    watchFetchAnnualVolatilityBulk(),
    watchFetchAverageSharePrice(),
    watchFetchDailySummaries(),
    watchFetchInvoices(),
    watchFetchCustomRelationshipTypes(),
    watchFetchContracts(),
    watchPostContracts(),
    watchPostDocumentAcceptance(),
    watchPutExerciseOrder(),
    watchPutPurchaseOrder(),
    watchPutCustomRelationshipType(),
    watchDeleteCustomRelationshipType(),
    watchPostCustomRelationshipType(),
    watchPutCustomRelationshipTypes(),
    watchFetchEmployeesWithPagination(),
  ]);
}
