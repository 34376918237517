import { Employee } from "src/admin-portal/employees/employee-reducer";
import { RootState } from "src/reducers/all-reducers";

export const token = state => state.user.token;
export const tenant = state => state.tenant.selectedTenant;
export const tenantIdOnEmployerPortal = state =>
  state.tenant.selectedTenant && state.tenant.selectedTenant.id;
export const tenantIdOnEmployeePortal = state =>
  state.user && state.user.tenant.id;
export const tenantId = state =>
  tenantIdOnEmployerPortal(state) || tenantIdOnEmployeePortal(state);
export const isSysadmin = state => state.user.isSysadmin;

export const employeeForId = (emplyeeId: string) => (
  state: RootState
): Api.V1.Employee | undefined =>
  state.employee.allEmployees.filter(e => e.id === emplyeeId)[0];
