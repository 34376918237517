import { createReducer } from "redux-act";

import {
  closePostEmailSucceededNotification,
  postEmail,
  postEmailSucceeded,
} from "src/admin-portal/email/email-actions";

export interface EmailState {
  isSending: boolean;
  isSucceeded: boolean;
}

const initialState: EmailState = {
  isSending: false,
  isSucceeded: false,
};

export default createReducer(on => {
  on(postEmail, state => ({
    ...state,
    isSending: true,
  }));
  on(postEmailSucceeded, state => ({
    ...state,
    isSending: false,
    isSucceeded: true,
  }));
  on(closePostEmailSucceededNotification, state => ({
    ...state,
    isSucceeded: false,
  }));
}, initialState);
