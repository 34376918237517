import { get } from "lodash";
import { isMoment } from "moment";

export function sortBy<T>(propertyName: string, data: T[]): T[] {
  return [...data].sort((a, b) => {
    return compare(get(a, propertyName), get(b, propertyName));
  });
}

export const sortMultipleLevels = <T>(data: T[]) => (
  propertyName: string,
  ...restProperties
): T[] =>
  data.sort((a, b) => compareDeep(a, b, propertyName, ...restProperties));

const compareDeep = (
  a: any,
  b: any,
  propertyName: string,
  ...restProperties
): number => {
  const result = compare(a[propertyName], b[propertyName]);

  if (result === 0 && restProperties.length > 0) {
    const [next, ...rest] = restProperties;
    return compareDeep(a, b, next, ...rest);
  }

  return result;
};

export function stringSortBy<T>(propertyName: string, data: T[]): T[] {
  return [...data].sort((a, b) => {
    return compareString(get(a, propertyName), get(b, propertyName));
  });
}

const removeSpacesCommasAndPeriods = str =>
  typeof str === "string" && str.replace(/[ ,.]/g, "");

function isComparableLikeNumber(a: any): boolean {
  const maybeNumber = Number(removeSpacesCommasAndPeriods(a));
  if (maybeNumber) {
    return false;
  }
  return typeof a === "number" || Boolean(maybeNumber) || maybeNumber === 0;
}

export const compareString = (a: any, b: any): number => a.localeCompare(b);

export const compare = (a: any, b: any): number => {
  if (isComparableLikeNumber(a)) {
    return a - b;
  } else if (typeof a === "string") {
    return a.localeCompare(b);
  } else if (isMoment(a) || isMoment(b)) {
    if (isMoment(a) && isMoment(b)) {
      return a.diff(b);
    } else if (isMoment(a)) {
      return 1;
    } else {
      return -1;
    }
  } else if (typeof a === "boolean") {
    if (a && a !== b) {
      return 1;
    } else if (b && b !== a) {
      return -1;
    } else {
      return 0;
    }
  } else {
    console.log(`Comparing/sorting not possible for type ${typeof a}`);
    return 0;
  }
};

export interface SortState {
  column?: string;
  data: any[];
  direction: "ascending" | "descending";
}

export const updateSortState = (
  clickedColumn: string,
  sortState: SortState
): Pick<SortState, "column" | "direction"> => {
  const { column, direction } = sortState;
  const directions: { [key: string]: SortState["direction"] } = {
    ascending: "descending",
    descending: "ascending",
  };
  return {
    column: clickedColumn,
    direction: column == clickedColumn ? directions[direction] : "ascending",
  };
};

export const handleSortIdempotent = (
  column: string,
  direction: "ascending" | "descending",
  data: any[]
): any[] => {
  const sorted = sortBy(column, data);
  return direction === "descending" ? [...sorted].reverse() : sorted;
};

export const handleSortFunction = (
  clickedColumn: string,
  sortState: SortState
) => (): SortState => {
  const { column, data } = sortState;

  const sortedData =
    column == clickedColumn ? [...data].reverse() : sortBy(clickedColumn, data);
  return {
    ...updateSortState(clickedColumn, sortState),
    data: sortedData,
  };
};

export const sortAlphabetically = (a: string, b: string) => a.localeCompare(b);
