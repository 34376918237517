import { times } from "lodash";
import numeral from "numeral";
import React, { FunctionComponent } from "react";

import "src/common/components/Slider/style.less";

interface Props {
  min?: number;
  max: number;
  rulerDivisions: number;
}

const Ruler: FunctionComponent<Props> = ({ min, max, rulerDivisions }) => (
  <div className="ruler">
    {times(rulerDivisions, index => (
      <div key={index} className="item">
        <span>
          {numeral(index * ((max - min) / (rulerDivisions - 1))).format("0.00")}
        </span>
      </div>
    ))}
  </div>
);

export default Ruler;
