import React, { FunctionComponent } from "react";
import { Checkbox, Form } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import { CurrencyFormatterProps } from "src/common/components/currency/currency-formatter";
import CurrencyInput from "src/common/components/form/currency-input";
import HelpPopup from "src/common/components/help-popup";
import {
  changePunctuationForComma,
  formatNumber,
} from "src/common/utils/utils";
import { LoanConfig } from "src/employee-portal/purchase/duck/purchase-selectors";

interface LoanFormProps {
  loanConfig?: LoanConfig;
  loan: string;
  loanChanged: (newValue: string) => void;
  loanError: boolean;
  formatter: CurrencyFormatterProps;
  totalPrice?: number;
}

const LoanFormField: FunctionComponent<LoanFormProps> = ({
  loanConfig,
  loan,
  loanChanged,
  loanError,
  formatter,
  totalPrice,
}) => {
  const formatMessage = useFormatMessage();

  if (!loanConfig) {
    return null;
  }

  const hasBinaryLoan = loanConfig && loanConfig.min === loanConfig.max;
  const setZeroLoan = () => loanChanged("0");
  const setMaxLoan = () => {
    const newValue = totalPrice
      ? Math.min(loanConfig.max, totalPrice).toString()
      : loanConfig.max.toString();
    loanChanged(newValue);
  };
  const onChangeLoan = event =>
    loanChanged(event.target.value.replace(/[a-zA-Z]/g, ""));
  const loanMessageValues = {
    min: formatter.formatCurrency(loanConfig.min, 0),
    max: formatter.formatCurrency(loanConfig.max, 0),
  };
  return (
    <>
      {loanConfig && !hasBinaryLoan && (
        <div className="purchase-box-form-input">
          <Form.Field>
            <label>
              <Content
                id={"purchase.quantity.loan.label"}
                values={loanMessageValues}
              />
              <HelpPopup
                textKey="purchase.quantity.loan.popup"
                values={loanMessageValues}
              />
            </label>
            <CurrencyInput
              error={loanError}
              currencyCode={formatter.currencyCode}
              value={changePunctuationForComma(loan)}
              onChange={onChangeLoan}
              placeholder={formatMessage(
                { id: "purchase.quantity.loan.placeholder" },
                loanMessageValues
              )}
            />
          </Form.Field>
          <div className="text-center purchase-box-input-action-panel">
            <ul className="horizontal-list">
              <li>
                <a
                  href="javascript:void(0)"
                  onClick={() => loanChanged(loanConfig.min.toString())}
                >
                  <Content id={"purchase.quantity.loan.setmin.label"} />
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" onClick={setMaxLoan}>
                  <Content id={"purchase.quantity.loan.setmax.label"} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
      {hasBinaryLoan && (
        <div className="purchase-box-form-input">
          <Form.Field>
            <Checkbox
              label={formatMessage(
                {
                  id: "purchase.quantity.loancheckbox.label",
                },
                {
                  maxLoan: formatter.formatCurrency(loanConfig.max, 0),
                }
              )}
              checked={loan && parseFloat(loan) > 0}
              onChange={(event, { checked }) =>
                checked ? setMaxLoan() : setZeroLoan()
              }
            />
          </Form.Field>
        </div>
      )}
    </>
  );
};

export default LoanFormField;
