import { push } from "connected-react-router";
import Jsona from "jsona";
import { pickBy } from "lodash";
import Raven from "raven-js";

import { all, call, put, select, takeEvery } from "redux-saga/effects";

import {
  ADD_AWARD_VESTING,
  ADD_AWARD_VESTING_FAILED,
  ADD_AWARD_VESTING_SUCCEEDED,
  DELETE_AWARD,
  DELETE_AWARD_FAILED,
  DELETE_AWARD_SUCCEEDED,
  FETCH_TENANT_VESTING_EVENTS,
  FETCH_TENANT_VESTING_EVENTS_FAILED,
  FETCH_TENANT_VESTING_EVENTS_SUCCEEDED,
  fetchTranche,
  fetchTrancheSucceeded,
  POST_AWARD,
  POST_AWARD_FAILED,
  POST_AWARD_SUCCEEDED,
  PUT_AWARD,
  PUT_AWARD_FAILED,
  PUT_AWARD_SUCCEEDED,
  PUT_AWARDS,
  PUT_AWARDS_FAILED,
} from "src/admin-portal/awards/award-actions";
import {
  Award,
  ExcelSheetAwardLine,
  VestingEvent,
  VestingEventApi,
} from "src/admin-portal/awards/award-reducer";
import * as selectors from "src/admin-portal/awards/award-selectors";
import { FETCH_PROGRAMS } from "src/admin-portal/programs/program-actions";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { batchRequests } from "src/common/sagas/batch-requests-saga";
import { generatePaginationPagesWithNumbers } from "src/common/utils/pagination";
import { sortRelationships } from "src/common/utils/sort-relationships";
import { apiShortDate, buildURL } from "src/common/utils/utils";
import * as commonSelectors from "src/selectors";

const dataFormatter = new Jsona();
const AWARDS_REQUEST_URL = "/awards?tenantId=";
const OPTION_AWARDS_REQUEST_URL = "/awards/";

interface PostAwardAction {
  type: "POST_AWARD";
  award: Award;
}

const toVestingEventApi = (tranche: VestingEvent): VestingEventApi => ({
  grant_date: tranche.grant_date.format(apiShortDate),
  vestedDate: tranche.vestedDate.format(apiShortDate),
  expiry_date: tranche.expiry_date.format(apiShortDate),
  strike: tranche.strike,
  quantity: tranche.quantity,
  purchase_price: tranche.purchase_price,
  is_dividend: tranche.is_dividend,
});

function* postAwardRequested(action: PostAwardAction) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";

    const apiAward = {
      ...action.award,
      ...{
        tranches: action.award.tranches.map(toVestingEventApi),
      },
    };

    const awardResponse = yield call(() =>
      callApi(AWARDS_REQUEST_URL + tenantId, token, method, apiAward)
    );
    yield put({ type: POST_AWARD_SUCCEEDED, award: awardResponse.data });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: POST_AWARD_FAILED, message: e.message });
    }
  }
}

export function* watchPostAward() {
  yield takeEvery(POST_AWARD, postAwardRequested);
}

export function* addAwardVestingRequested(action) {
  try {
    yield put({
      type: ADD_AWARD_VESTING_SUCCEEDED,
      vesting: action.vesting,
    });
  } catch (e) {
    Raven.captureException(e);
    yield put({ type: ADD_AWARD_VESTING_FAILED, message: e.message });
  }
}

export function* watchAddAwardVesting() {
  yield takeEvery(ADD_AWARD_VESTING, addAwardVestingRequested);
}

function* putAwardRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const awardId = action.award.id;
    const method = "PUT";

    const apiAward = {
      ...action.award,
      ...{
        tranches: action.award.tranches.map(toVestingEventApi),
      },
    };

    const awardResponse = yield call(() =>
      callApi(
        OPTION_AWARDS_REQUEST_URL + awardId + "?tenantId=" + tenantId,
        token,
        method,
        apiAward
      )
    );

    yield put({ type: PUT_AWARD_SUCCEEDED, award: awardResponse.data });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: PUT_AWARD_FAILED, message: e.message });
    }
  }
}

export function* watchPutAward() {
  yield takeEvery(PUT_AWARD, putAwardRequested);
}

function* putAwardsRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "PUT";

    yield batchRequests(
      20,
      1000,
      action.awards.map(award => () => {
        const { id, ...attributes } = award;
        return callApi(
          OPTION_AWARDS_REQUEST_URL + id + "?tenantId=" + tenantId,
          token,
          method,
          attributes
        );
      })
    );
    // refetching programs because on /admin/awards
    // we are getting awards from store.program.allPrograms..
    yield put({ type: FETCH_PROGRAMS });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: PUT_AWARDS_FAILED, message: e.message });
    }
  }
}

export function* watchPutAwards() {
  yield takeEvery(PUT_AWARDS, putAwardsRequested);
}

function* deleteAwardRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const awardId = action.awardId;
    const award = action.award;
    const method = "DELETE";

    yield call(() =>
      callApi(
        OPTION_AWARDS_REQUEST_URL + awardId + "?tenantId=" + tenantId,
        token,
        method,
        action.award
      )
    );
    yield put({ type: DELETE_AWARD_SUCCEEDED, award });
    yield put(push(action.redirectAfterDeleteLink));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: DELETE_AWARD_FAILED, message: e.message });
    }
  }
}

export function* watchDeleteAward() {
  yield takeEvery(DELETE_AWARD, deleteAwardRequested);
}

function* fetchTenantVestingEventsRequested(action) {
  try {
    const token = yield select(commonSelectors.token);
    const tenantId =
      (yield select(commonSelectors.tenantId)) ||
      (yield select(commonSelectors.userTenantId));

    const page = {
      number: action.page || 1,
      size: action.pageSize,
    };
    const include = [
      "transactions",
      "tranchePerformanceRules.performanceRule",
      "award.employee",
      "award.employee.employeeCustomRelationships",
      "award.employee.employeeCustomRelationships.customRelationshipType",
      "award.employee.entity",
      "award.employee.mobilityEntries",
      "award.incentiveSubProgram.incentiveProgram",
    ].join(",");
    const filter = pickBy(action.filters);
    const url = buildURL(`/tenants/${tenantId}/tranches`, {
      include,
      page,
      filter,
      sort: action.sort,
    });
    const tranchesResponse = yield call(() => callApi(url, token));
    const tranches = dataFormatter
      .deserialize(tranchesResponse)
      .map(sortRelationships);

    yield put({
      type: FETCH_TENANT_VESTING_EVENTS_SUCCEEDED,
      tranches,
      recordCount: tranchesResponse.meta.recordCount,
      pageSize: action.pageSize,
    });
  } catch (e) {
    Raven.captureException(e);
    yield put({ type: FETCH_TENANT_VESTING_EVENTS_FAILED, message: e.message });
  }
}

export function* watchFetchTenantVestingEvents() {
  yield takeEvery(
    FETCH_TENANT_VESTING_EVENTS,
    fetchTenantVestingEventsRequested
  );
}

function* fetchTrancheRequested(action: ReturnType<typeof fetchTranche>) {
  try {
    const token = yield select(commonSelectors.token);
    const include = [
      "transactions",
      "award.employee",
      "award.employee.entity",
      "award.employee.mobilityEntries",
      "award.incentiveSubProgram.incentiveProgram",
    ].join(",");
    const url = buildURL(`/tranches/${action.payload}`, { include });
    const response = yield call(() => callApi(url, token));
    const tranche = dataFormatter.deserialize(response) as Api.V1.VestingEvent;
    yield put(fetchTrancheSucceeded(tranche));
  } catch (e) {
    Raven.captureException(e);
  }
}

export function* watchFetchTrancheRequested() {
  yield takeEvery(fetchTranche.getType(), fetchTrancheRequested);
}
