import { createAction } from "redux-act";

export const fetchInvoices = createAction("FETCH_INVOICES");
export const fetchInvoicesFailed = createAction(
  "FETCH_INVOICES_FAILED",
  (payload: string) => payload
);

export const fetchInvoicesSucceeded = createAction(
  "FETCH_INVOICES_SUCCEEDED",
  (payload: Api.V1.InvoiceInfo[]) => payload
);
