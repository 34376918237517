import React, { StatelessComponent } from "react";
import { Dimmer, Loader, DimmerProps } from "semantic-ui-react";

interface Props {
  active: boolean;
  dimmer?: boolean;
  dimmerProps?: DimmerProps;
}

const SpinnerInline: StatelessComponent<Props> = ({
  active,
  dimmer,
  dimmerProps,
}) => (
  <div className="loader-container">
    {dimmer ? (
      <Dimmer active={active} {...dimmerProps}>
        <Loader active={active} size="big" />
      </Dimmer>
    ) : (
      <Loader active={active} size="big" />
    )}
  </div>
);

export default SpinnerInline;
