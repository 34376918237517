import moment, { Moment } from "moment";

import { Tranche } from "src/admin-portal/awards/award-reducer";
import {
  Interval,
  overlappingDays,
} from "src/admin-portal/reports/generate-report/common/date";
import { TrancheState } from "src/admin-portal/reports/generate-report/common/types";
import { ReportLine } from "src/admin-portal/reports/generate-report/ifrs-cost-spec/types";
import {
  getCostInterval,
  ReportType,
  trancheStateAtDate,
} from "src/admin-portal/reports/generate-report/report-utils";
import { apiShortDate } from "src/common/utils/utils";

export type GroupByProperty<T> = (item: T) => string;

export function groupBy<T>(array: T[], groupBy: GroupByProperty<T>) {
  return array.reduce((groups, item): GroupedByLines<T> => {
    const val = groupBy(item);
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {});
}

export interface GroupedByLines<T> {
  [key: string]: T[];
}

export const mobilitiesBeforeDate = (
  mobilities: Api.V1.MobilityEntry[],
  date: Moment
) => mobilities.filter(m => moment(m.fromDate, apiShortDate).isBefore(date));

export const mobilitiesWithCostOrQuantity = (
  mobilities: Api.V1.MobilityEntry[],
  trancheOB: TrancheState,
  obDate: Moment,
  trancheCB: TrancheState,
  cbDate: Moment,
  tranche: Tranche
) => {
  const totalCostInterval = getCostInterval(trancheCB);
  return mobilities.filter(m => {
    const mobilityInterval = {
      start: moment(m.fromDate, apiShortDate),
      end: moment(m.toDate, apiShortDate),
    };

    const shouldHaveCost =
      overlappingDays(totalCostInterval, mobilityInterval) > 0;
    const trancheStateAtMobility = trancheStateAtDate(
      tranche,
      mobilityInterval.start,
      ReportType.IFRS_COST
    );
    const allCostReversedWhenMobilityHappens = trancheStateAtMobility
      ? trancheStateAtMobility.quantity === 0
      : false;

    const hasCost = shouldHaveCost && !allCostReversedWhenMobilityHappens;

    const mobilityOverlappingOB =
      overlappingDays(mobilityInterval, {
        start: obDate,
        end: obDate,
      }) > 0;

    const mobilityOverlappingCB =
      overlappingDays(mobilityInterval, {
        start: cbDate,
        end: cbDate,
      }) > 0;

    const hasQuantityOB =
      mobilityOverlappingOB && trancheOB && trancheOB.quantity > 0;
    const hasQuantityCB =
      mobilityOverlappingCB && trancheCB && trancheCB.quantity > 0;

    const hasTransactionInMobility =
      tranche.transactions.filter(t =>
        moment(t.transactionDate, apiShortDate).isBetween(
          mobilityInterval.start,
          mobilityInterval.end,
          null,
          "[]"
        )
      ).length > 0;

    return (
      hasCost || hasQuantityOB || hasQuantityCB || hasTransactionInMobility
    );
  });
};

export const parseBackendNumber = (number: string | undefined | null) =>
  isNaN(parseFloat(number)) ? null : parseFloat(number);
export const parseBackendDate = (date: string | undefined | null) =>
  date ? moment(date, apiShortDate) : null;
