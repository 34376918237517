import { createAction } from "redux-act";

import { EmployeeSheetImport } from "src/admin-portal/employees/employee-import";
import { EntitySheetImport } from "src/admin-portal/entity/entity-import";
import { SingleAwardImport } from "src/admin-portal/import/all-models-import";
import { Program } from "src/admin-portal/programs/program-reducer";

export interface ImportAllModelsAction {
  entities: EntitySheetImport[];
  employees: EmployeeSheetImport[];
  programs: Program[];
  awards: SingleAwardImport[];
}

export const importAllModels = createAction(
  "IMPORT_ALL_MODELS",
  (payload: ImportAllModelsAction) => payload
);
export const importAllModelsFailed = createAction(
  "IMPORT_ALL_MODELS_FAILED",
  (payload: string) => payload
);
export const importAllModelsSucceeded = createAction(
  "IMPORT_ALL_MODELS_SUCCEEDED"
);
