import { Jsona } from "jsona";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  fetchTenantOrder,
  fetchTenantOrderSucceeded,
  putTenantOrder,
  putTenantOrderSucceeded,
} from "src/admin-portal/orders/ordersActions";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import * as selectors from "src/selectors";

const dataFormatter = new Jsona();
const orderUrl = id => `/orders/${id}`;

function* fetchTenantsOrderRequested(
  action: ReturnType<typeof fetchTenantOrder>
) {
  try {
    const token = yield select(selectors.token);
    const includeParam = (action.payload.include || []).join(",");
    const response = yield call(() =>
      callApi(orderUrl(action.payload.id) + `?include=${includeParam}`, token)
    );
    yield put(
      fetchTenantOrderSucceeded(
        dataFormatter.deserialize(response) as Api.V1.Order
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
    }
  }
}

export function* watchFetchTenantOrder() {
  yield takeEvery(fetchTenantOrder.getType(), fetchTenantsOrderRequested);
}

function* putTenantOrderRequested(action: ReturnType<typeof putTenantOrder>) {
  try {
    const token = yield select(selectors.token);
    const method = "PUT";
    const { id, ...attributes } = action.payload.data;

    const includeParam = (action.payload.include || []).join(",");
    const body = {
      data: {
        id: id,
        type: "orders",
        attributes: attributes,
        relationships: action.payload.relationships,
      },
    };

    const response = yield call(() =>
      callApi(orderUrl(id) + `?include=${includeParam}`, token, method, body)
    );
    yield put(
      putTenantOrderSucceeded(
        dataFormatter.deserialize(response) as Api.V1.Order
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureException(e.errorMessage);
    }
  }
}

export function* watchPutTenantOrder() {
  yield takeEvery(putTenantOrder.getType(), putTenantOrderRequested);
}
