import moment from "moment";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Button, Checkbox, Flag, FlagProps, Table } from "semantic-ui-react";

import TrancheStatus from "src/admin-portal/tranches-page/tranche-status";
import TransactionRow from "src/admin-portal/tranches-page/transaction-row";
import { TrancheDataEntry } from "src/admin-portal/tranches-page/types";
import SortableHeaderCell from "src/common/components/sortable-header-cell";
import { countryEntry } from "src/common/data/common";
import { sortMultipleLevels } from "src/common/utils/sort";
import {
  apiShortDate,
  flatten,
  formatNumber,
  sumNumbers,
  yesOrNo,
} from "src/common/utils/utils";
import { NO_VALUE } from "src/constants";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface Props {
  isSysadmin: boolean;
  data: TrancheDataEntry[];
  dateFormat: DateFormatState;
  handleSort: (column: string) => () => void;
  toggleRowSelect: (trancheId: string) => void;
  sortedBy: string;
}

const TrancheTable: FunctionComponent<Props> = ({
  isSysadmin,
  data,
  dateFormat,
  handleSort,
  toggleRowSelect,
  sortedBy = "",
}) => {
  const totalQuantity = data
    .map(t => t.quantity + t.expiredQuantity)
    .reduce(sumNumbers, 0);
  const totalExercisedQuantity = data
    .map(t => t.exercisedQuantity * -1)
    .reduce(sumNumbers, 0);
  const totalReleasedQuantity = data
    .map(t => t.releasedQuantity * -1)
    .reduce(sumNumbers, 0);
  const totalTerminatedQuantity = data
    .map(t => t.terminationQuantity * -1)
    .reduce(sumNumbers, 0);
  const totalExpiredQuantity = data
    .map(t => t.expiredQuantity * -1)
    .reduce(sumNumbers, 0);

  const hasFairValueForSocSec = data.some(
    tranche => !isNaN(parseFloat(tranche.fairValueForSocSec))
  );
  const order = sortedBy.startsWith("-") ? "desc" : "asc";

  return (
    <Table celled={true} padded={true} sortable={true} compact={"very"}>
      <Table.Header>
        <Table.Row>
          {isSysadmin && <Table.HeaderCell />}
          {isSysadmin && <Table.HeaderCell />}
          <SortableHeaderCell
            handleSort={handleSort("award.employee.firstName")}
            active={sortedBy.includes("award.employee.firstName")}
            order={order}
            name="Name"
          />
          <Table.HeaderCell content="Entity" />
          <SortableHeaderCell
            handleSort={handleSort("award.employee.residence")}
            active={sortedBy.includes("award.employee.residence")}
            order={order}
            name="Country"
          />
          <SortableHeaderCell
            handleSort={handleSort(
              "award.incentiveSubProgram.incentiveProgram.name"
            )}
            active={sortedBy.includes(
              "award.incentiveSubProgram.incentiveProgram.name"
            )}
            order={order}
            name="Program"
          />
          <SortableHeaderCell
            handleSort={handleSort("award.incentiveSubProgram.name")}
            active={sortedBy.includes("award.incentiveSubProgram.name")}
            order={order}
            name="Subprogram"
          />
          {isSysadmin && [
            <Table.HeaderCell key="Tx type" content="Tx type" />,
            <Table.HeaderCell key="Tx date" content="Tx date" />,
          ]}
          <SortableHeaderCell
            handleSort={handleSort(
              "award.incentiveSubProgram.instrumentTypeId"
            )}
            active={sortedBy.includes(
              "award.incentiveSubProgram.instrumentTypeId"
            )}
            order={order}
            name="Instrument Type"
          />
          <SortableHeaderCell
            handleSort={handleSort(
              "award.incentiveSubProgram.settlementTypeId"
            )}
            active={sortedBy.includes(
              "award.incentiveSubProgram.settlementTypeId"
            )}
            order={order}
            name="Settlement Type"
          />
          <SortableHeaderCell
            handleSort={handleSort("award.incentiveSubProgram.performance")}
            active={sortedBy.includes("award.incentiveSubProgram.performance")}
            order={order}
            name="Performance"
          />
          <Table.HeaderCell content="Purchase price" />
          <Table.HeaderCell content="Input value" />
          <SortableHeaderCell
            handleSort={handleSort("strike")}
            active={sortedBy.includes("strike")}
            order={order}
            name="Strike"
          />
          <SortableHeaderCell
            handleSort={handleSort("quantity")}
            active={sortedBy.includes("quantity")}
            order={order}
            name="Quantity"
          />
          <Table.HeaderCell content="Status" />
          <SortableHeaderCell
            handleSort={handleSort("exercisedQuantity")}
            active={sortedBy.includes("exercisedQuantity")}
            order={order}
            name="Exercised"
          />
          <SortableHeaderCell
            handleSort={handleSort("releasedQuantity")}
            active={sortedBy.includes("releasedQuantity")}
            order={order}
            name="Released"
          />
          <SortableHeaderCell
            handleSort={handleSort("terminationQuantity")}
            active={sortedBy.includes("terminationQuantity")}
            order={order}
            name="Terminated"
          />
          <SortableHeaderCell
            handleSort={handleSort("expiredQuantity")}
            active={sortedBy.includes("expiredQuantity")}
            order={order}
            name="Expired"
          />
          <SortableHeaderCell
            handleSort={handleSort("grantDate")}
            active={sortedBy.includes("grantDate")}
            order={order}
            name="Grant Date"
          />
          <SortableHeaderCell
            handleSort={handleSort("vestedDate")}
            active={sortedBy.includes("vestedDate")}
            order={order}
            name="Vested Date"
          />
          <SortableHeaderCell
            handleSort={handleSort("expiryDate")}
            active={sortedBy.includes("expiryDate")}
            order={order}
            name="Expiry Date"
          />
          <SortableHeaderCell
            handleSort={handleSort("isDividend")}
            active={sortedBy.includes("isDividend")}
            order={order}
            name="Is dividend?"
          />
          <SortableHeaderCell
            handleSort={handleSort("fairValue")}
            active={
              sortedBy.includes("fairValue") &&
              !sortedBy.includes("fairValueForSocSec")
            }
            order={order}
            name="Fair value"
          />
          <SortableHeaderCell
            handleSort={handleSort("fairValueForSocSec")}
            active={sortedBy.includes("fairValueForSocSec")}
            order={order}
            name="FV for soc sec"
          />
          <SortableHeaderCell
            handleSort={handleSort("capOnGain")}
            active={sortedBy.includes("capOnGain")}
            order={order}
            name="Cap on gain"
          />
          <SortableHeaderCell
            handleSort={handleSort("performanceStartDate")}
            active={sortedBy.includes("performanceStartDate")}
            order={order}
            name="Perf. Start"
          />
          <SortableHeaderCell
            handleSort={handleSort("performanceEndDate")}
            active={sortedBy.includes("performanceEndDate")}
            order={order}
            name="Perf. End"
          />
          <SortableHeaderCell
            handleSort={handleSort(
              "tranche.tranchePerformanceRules[0].performanceRule.name"
            )}
            active={sortedBy.includes(
              "tranche.tranchePerformanceRules[0].performanceRule.name"
            )}
            order={order}
            name="Perf. Criteria"
          />
          <SortableHeaderCell
            handleSort={handleSort("lockedToDate")}
            active={sortedBy.includes("lockedToDate")}
            order={order}
            name="Locked to"
          />
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data.map(tranche =>
          flatten([
            [
              <Table.Row key={tranche.id}>
                {isSysadmin && (
                  <Table.Cell>
                    <Checkbox
                      onChange={() => toggleRowSelect(tranche.id)}
                      checked={tranche.selected}
                    />
                  </Table.Cell>
                )}
                {isSysadmin && (
                  <Table.Cell>
                    <Button
                      as={Link}
                      basic={true}
                      content="Manage"
                      size="mini"
                      to={`/admin/tranche/${tranche.id}/transactions`}
                    />
                  </Table.Cell>
                )}
                <Table.Cell>
                  {tranche.award.employee.firstName}{" "}
                  {tranche.award.employee.lastName}
                </Table.Cell>
                <Table.Cell>
                  {tranche.award.employee.entity &&
                    tranche.award.employee.entity.name}
                </Table.Cell>
                <Table.Cell>
                  <Flag
                    {...({
                      name: countryEntry(tranche.award.employee.residence).flag,
                    } as FlagProps)}
                  />
                  {countryEntry(tranche.award.employee.residence).text}
                </Table.Cell>
                <Table.Cell>
                  {tranche.award.incentiveSubProgram.incentiveProgram.name}
                </Table.Cell>
                <Table.Cell>
                  {tranche.award.incentiveSubProgram.name}
                </Table.Cell>
                {isSysadmin && [
                  <Table.Cell key="Tx type">{NO_VALUE}</Table.Cell>,
                  <Table.Cell key="Tx date">{NO_VALUE}</Table.Cell>,
                ]}
                <Table.Cell>
                  {tranche.award.incentiveSubProgram.instrumentTypeId}
                </Table.Cell>
                <Table.Cell>
                  {tranche.award.incentiveSubProgram.settlementTypeId}
                </Table.Cell>
                <Table.Cell>
                  {yesOrNo(tranche.award.incentiveSubProgram.performance)}
                </Table.Cell>
                <Table.Cell>
                  {tranche.purchasePrice
                    ? tranche.purchasePrice.replace(".", ",")
                    : NO_VALUE}
                </Table.Cell>
                <Table.Cell>
                  {tranche.taxableInputValuation
                    ? tranche.taxableInputValuation.replace(".", ",")
                    : NO_VALUE}
                </Table.Cell>
                <Table.Cell>
                  {tranche.strike ? tranche.strike.replace(".", ",") : NO_VALUE}
                </Table.Cell>
                <Table.Cell>
                  {formatNumber(tranche.quantity + tranche.expiredQuantity)}
                </Table.Cell>
                <Table.Cell singleLine={true}>
                  <TrancheStatus status={tranche.status} />
                </Table.Cell>
                <Table.Cell>
                  {formatNumber(-1 * tranche.exercisedQuantity)}
                </Table.Cell>
                <Table.Cell>
                  {formatNumber(-1 * tranche.releasedQuantity)}
                </Table.Cell>
                <Table.Cell>
                  {formatNumber(-1 * tranche.terminationQuantity)}
                </Table.Cell>
                <Table.Cell>
                  {formatNumber(-1 * tranche.expiredQuantity)}
                </Table.Cell>
                <Table.Cell>
                  {tranche.grantDate
                    ? moment(tranche.grantDate).format(
                        dateFormat.normalDateFormat
                      )
                    : NO_VALUE}
                </Table.Cell>
                <Table.Cell>
                  {tranche.vestedDate
                    ? moment(tranche.vestedDate).format(
                        dateFormat.normalDateFormat
                      )
                    : NO_VALUE}
                </Table.Cell>
                <Table.Cell>
                  {tranche.expiryDate
                    ? moment(tranche.expiryDate).format(
                        dateFormat.normalDateFormat
                      )
                    : NO_VALUE}
                </Table.Cell>
                <Table.Cell>{yesOrNo(tranche.isDividend)}</Table.Cell>
                <Table.Cell>
                  {tranche.fairValue ? tranche.fairValue : NO_VALUE}
                </Table.Cell>
                <Table.Cell>
                  {hasFairValueForSocSec &&
                  !isNaN(parseFloat(tranche.fairValueForSocSec))
                    ? parseFloat(tranche.fairValueForSocSec)
                    : NO_VALUE}
                </Table.Cell>
                <Table.Cell>
                  {tranche.capOnGain
                    ? tranche.capOnGain.replace(".", ",")
                    : NO_VALUE}
                </Table.Cell>
                <Table.Cell>
                  {tranche.performanceStartDate
                    ? moment(tranche.performanceStartDate, apiShortDate).format(
                        dateFormat.normalDateFormat
                      )
                    : NO_VALUE}
                </Table.Cell>
                <Table.Cell>
                  {tranche.performanceEndDate
                    ? moment(tranche.performanceEndDate, apiShortDate).format(
                        dateFormat.normalDateFormat
                      )
                    : NO_VALUE}
                </Table.Cell>
                <Table.Cell>
                  {tranche.tranchePerformanceRules &&
                  tranche.tranchePerformanceRules[0]
                    ? tranche.tranchePerformanceRules[0].performanceRule.name
                    : NO_VALUE}
                </Table.Cell>
                <Table.Cell>
                  {tranche.lockedToDate
                    ? moment(tranche.lockedToDate, apiShortDate).format(
                        dateFormat.normalDateFormat
                      )
                    : NO_VALUE}
                </Table.Cell>
              </Table.Row>,
            ],
            tranche.selected
              ? sortMultipleLevels(tranche.transactions)(
                  "transactionDate",
                  "createdAt"
                ).map(transaction => (
                  <TransactionRow
                    key={transaction.id}
                    dateFormat={dateFormat}
                    transaction={transaction}
                  />
                ))
              : [null],
          ])
        )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          {isSysadmin && <Table.HeaderCell />}
          {isSysadmin && <Table.HeaderCell />}
          <Table.HeaderCell />
          <Table.HeaderCell />
          <Table.HeaderCell />
          <Table.HeaderCell />
          <Table.HeaderCell />
          <Table.HeaderCell />
          {isSysadmin && <Table.HeaderCell />}
          {isSysadmin && <Table.HeaderCell />}
          <Table.HeaderCell />
          <Table.HeaderCell />
          <Table.HeaderCell />
          <Table.HeaderCell />
          <Table.HeaderCell />
          <Table.HeaderCell>{formatNumber(totalQuantity)}</Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell>
            {formatNumber(totalExercisedQuantity)}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {formatNumber(totalReleasedQuantity)}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {formatNumber(totalTerminatedQuantity)}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {formatNumber(totalExpiredQuantity)}
          </Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell />
          <Table.HeaderCell />
          <Table.HeaderCell />
          <Table.HeaderCell />
          <Table.HeaderCell />
          <Table.HeaderCell />
          <Table.HeaderCell />
          <Table.HeaderCell />
          <Table.HeaderCell />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export default React.memo(TrancheTable);
