import React, { FunctionComponent } from "react";
import { MessageValue } from "react-intl";
import { Icon, Popup } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";

interface Props {
  textKey: string;
  values?: { [key: string]: MessageValue | JSX.Element };
}

const HelpPopup: FunctionComponent<Props> = ({ textKey, values }) => (
  <Popup trigger={<Icon name="question circle outline" />}>
    <Popup.Content>
      <Content id={textKey} values={values} />
    </Popup.Content>
  </Popup>
);

export default HelpPopup;
