import { push } from "connected-react-router";
import Jsona from "jsona";
import { pickBy } from "lodash";
import Raven from "raven-js";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";

import {
  deleteEmployee,
  deleteEmployees,
  deleteEmployeesSucceeded,
  deleteEmployeeSucceeded,
  fetchEmployee,
  fetchEmployeeAndEntities,
  fetchEmployeeAndEntitiesSucceeded,
  fetchEmployees,
  fetchEmployeesPaginated,
  fetchEmployeesForExport,
  fetchEmployeesAndEntities,
  fetchEmployeesAndEntitiesSucceeded,
  fetchEmployeesSucceeded,
  fetchEmployeesPaginatedSucceeded,
  fetchEmployeesForExportSucceeded,
  fetchEmployeeSucceeded,
  importAllEmployees,
  importAllEmployeesSucceeded,
  importEditedEmployees,
  importEditedEmployeesSucceeded,
  postEmployee,
  postEmployeeSucceeded,
  putEmployee,
  putEmployeeSucceeded,
  reverseTerminateEmployee,
  reverseTerminateEmployeeSucceeded,
  terminateEmployee,
  terminateEmployeeCustom,
  terminateEmployeeSucceeded,
  importAllEmployeesProgress,
  fetchEmployeesWithPagination,
  fetchEmployeesWithPaginationSucceeded,
  fetchEmployeesWithPaginationFailed,
} from "src/admin-portal/employees/employee-actions";
import * as selectors from "src/admin-portal/employees/employee-selectors";
import { fetchEntitiesStandalone } from "src/admin-portal/entity/entity-saga";
import { employeesRoute } from "src/admin-portal/menu/admin-portal-menu";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { batchRequests } from "src/common/sagas/batch-requests-saga";
import { generatePaginationPagesWithNumbers } from "src/common/utils/pagination";
import { handleSortIdempotent } from "src/common/utils/sort";
import { buildURL } from "src/common/utils/utils";
import { MAXIMUM_PAGE_SIZE } from "src/constants";
import { AUTH0_BATCH_SIZE, AUTH0_DELAY_IN_MILLISECONDS } from "src/env";
import { tenantId as tID, userTenantId } from "src/selectors";

const tenantEmployeesUrl = tenantId =>
  `/tenants/${tenantId}/employees?include=entity,currentMobilityEntry,employeeCustomRelationships,employeeCustomRelationships.customRelationshipType&page[size]=${MAXIMUM_PAGE_SIZE}`;

const employeeUrlIncludesParam = [
  "entity",
  "mobilityEntries",
  "employeeCustomRelationships",
  "employeeCustomRelationships.customRelationshipType",
  "currentMobilityEntry",
  "tranches",
  "awards.tranches.award.incentiveSubProgram.incentiveProgram",
].join(",");

const employeeUrl = id =>
  `/employees/${id}?include=${employeeUrlIncludesParam}`;

const EMPLOYEES_OPTION_REQUEST_URL = "/employees/";
const terminateEmployeeUrl = (employeeId: string) =>
  `/employees/${employeeId}/termination`;
const reverseTerminateEmployeeUrl = (employeeId: string) =>
  `/employees/${employeeId}/reverse_termination`;
const dataFormatter = new Jsona();

export function* fetchEmployeesStandalone() {
  const token = yield select(selectors.token);
  const tenantId = (yield select(tID)) || (yield select(userTenantId));

  const response = yield call(() =>
    callApi(tenantEmployeesUrl(tenantId), token)
  );

  yield put(
    fetchEmployeesSucceeded(
      dataFormatter.deserialize(response) as Api.V1.Employee[]
    )
  );
}

function* fetchEmployeesRequested() {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const employeeResponse = yield call(() =>
      callApi(tenantEmployeesUrl(tenantId), token)
    );
    yield put(
      fetchEmployeesSucceeded(
        handleSortIdempotent(
          "firstName",
          "ascending",
          dataFormatter.deserialize(employeeResponse) as Api.V1.Employee[]
        )
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchEmployees() {
  yield takeEvery(fetchEmployees.getType(), fetchEmployeesRequested);
}

function* fetchAllEntitiesAndEmployees() {
  yield fork(fetchEntitiesStandalone);
  yield fork(fetchEmployeesRequested);
}

function* fetchEmployeesPaginatedRequested(
  action: ReturnType<typeof fetchEmployeesPaginated>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const include = [
      "entity",
      "currentMobilityEntry",
      "employeeCustomRelationships",
      "employeeCustomRelationships.customRelationshipType",
    ].join(",");
    const page = {
      number: action.payload.pageNumber || 1,
      size: action.payload.pageSize || 50,
    };
    const filter = pickBy(action.payload.filters);
    const url = buildURL(`/tenants/${tenantId}/employees`, {
      include,
      page,
      sort: action.payload.sort,
      filter,
    });
    const response = yield call(() => callApi(url, token));
    yield put(
      fetchEmployeesPaginatedSucceeded({
        employeesPaginated: {
          [action.payload.pageNumber]: dataFormatter.deserialize(
            response
          ) as Api.V1.Employee[],
        },
        employeesPaginationLinks: generatePaginationPagesWithNumbers(
          response.links
        ),
        employeesPaginationCurrentPage: action.payload.pageNumber,
        recordsCount: response.meta.recordCount,
      })
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchEmployeesPaginated() {
  yield takeEvery(
    fetchEmployeesPaginated.getType(),
    fetchEmployeesPaginatedRequested
  );
}

function* fetchEmployeesForExportRequested(
  action: ReturnType<typeof fetchEmployeesForExport>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const include = [
      "entity",
      "currentMobilityEntry",
      "employeeCustomRelationships",
      "employeeCustomRelationships.customRelationshipType",
    ].join(",");
    const filter = pickBy(action.payload.filters);
    const url = buildURL(`/tenants/${tenantId}/employees`, {
      include,
      filter,
      page: { size: MAXIMUM_PAGE_SIZE },
    });
    const response = yield call(() => callApi(url, token));
    yield put(
      fetchEmployeesForExportSucceeded(
        dataFormatter.deserialize(response) as Api.V1.Employee[]
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchEmployeesForExport() {
  yield takeEvery(
    fetchEmployeesForExport.getType(),
    fetchEmployeesForExportRequested
  );
}

function* fetchEntitiesAndEmployeesRequested() {
  try {
    yield call(fetchAllEntitiesAndEmployees);
    yield put(fetchEmployeesAndEntitiesSucceeded());
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchEntitiesAndEmployees() {
  yield takeEvery(
    fetchEmployeesAndEntities.getType(),
    fetchEntitiesAndEmployeesRequested
  );
}

function* fetchEmployeeRequested(action: ReturnType<typeof fetchEmployee>) {
  try {
    const token = yield select(selectors.token);
    const method = "GET";

    const response = yield call(() =>
      callApi(employeeUrl(action.payload), token, method)
    );
    yield put(
      fetchEmployeeSucceeded(
        dataFormatter.deserialize(response) as Api.V1.Employee
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchEmployee() {
  yield takeEvery(fetchEmployee.getType(), fetchEmployeeRequested);
}

function* fetchAllEmployeeAndEntities(action) {
  yield all([
    call(fetchEntitiesStandalone),
    call(fetchEmployeeRequested, action),
  ]);
}

function* fetchEmployeeAndEntitiesRequested(
  action: ReturnType<typeof fetchEmployeeAndEntities>
) {
  try {
    yield call(fetchAllEmployeeAndEntities, action);
    yield put(fetchEmployeeAndEntitiesSucceeded(action));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchEmployeeAndEntities() {
  yield takeEvery(
    fetchEmployeeAndEntities.getType(),
    fetchEmployeeAndEntitiesRequested
  );
}

function* postEmployeeRequested(action: ReturnType<typeof postEmployee>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";
    const { mobilityEntries, ...rest } = action.payload.employee;

    const body = dataFormatter.serialize({
      stuff: {
        ...rest,
        newMobilityEntries: mobilityEntries,
        dimensions: action.payload.dimensions,
        labels: action.payload.labels,
        type: "employees",
      },
    });

    const response = yield call(() =>
      callApi(tenantEmployeesUrl(tenantId), token, method, body)
    );
    yield put(
      postEmployeeSucceeded(
        dataFormatter.deserialize(response) as Api.V1.Employee
      )
    );
    yield put(push(employeesRoute));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPostEmployee() {
  yield takeEvery(postEmployee.getType(), postEmployeeRequested);
}

function* putEmployeeRequested(action: ReturnType<typeof putEmployee>) {
  try {
    const token = yield select(selectors.token);
    const employeeId = action.payload.id;
    const method = "PUT";
    const { mobilityEntries, ...rest } = action.payload.employee;

    const body = dataFormatter.serialize({
      stuff: {
        ...rest,
        id: employeeId,
        newMobilityEntries: mobilityEntries,
        dimensions: action.payload.dimensions,
        labels: action.payload.labels,
        type: "employees",
      },
    });

    const response = yield call(() =>
      callApi(employeeUrl(employeeId), token, method, body)
    );
    yield put(
      putEmployeeSucceeded(
        dataFormatter.deserialize(response) as Api.V1.Employee
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPutEmployee() {
  yield takeEvery(putEmployee.getType(), putEmployeeRequested);
}

function* terminateEmployeeRequested(
  action: ReturnType<typeof terminateEmployee>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";

    const body = {
      reverseCost: !!action.payload.reverseCost,
      termination_date: action.payload.terminationDate,
      termination_type: "TERMINATE_ALL_NOT_FULLY_VESTED",
      transaction_date: action.payload.transactionDate,
    };

    yield call(() =>
      callApi(
        terminateEmployeeUrl(action.payload.employeeId),
        token,
        method,
        body
      )
    );
    yield put(
      terminateEmployeeSucceeded({
        employeeId: action.payload.employeeId,
        terminationDate: action.payload.terminationDate,
      })
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchTerminateEmployee() {
  yield takeEvery(terminateEmployee.getType(), terminateEmployeeRequested);
}

function* terminateEmployeeCustomRequested(
  action: ReturnType<typeof terminateEmployeeCustom>
) {
  try {
    const token = yield select(selectors.token);
    const method = "POST";
    yield call(() =>
      callApi(
        terminateEmployeeUrl(action.payload.employeeId),
        token,
        method,
        action.payload
      )
    );
    yield all([put(fetchEmployees()), put(push(employeesRoute))]);
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchTerminateEmployeeCustom() {
  yield takeEvery(
    terminateEmployeeCustom.getType(),
    terminateEmployeeCustomRequested
  );
}

function* reverseTerminateEmployeeRequested(
  action: ReturnType<typeof reverseTerminateEmployee>
) {
  try {
    const token = yield select(selectors.token);
    const method = "POST";

    yield call(() =>
      callApi(reverseTerminateEmployeeUrl(action.payload), token, method)
    );
    yield put(reverseTerminateEmployeeSucceeded(action.payload));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchReverseTerminateEmployee() {
  yield takeEvery(
    reverseTerminateEmployee.getType(),
    reverseTerminateEmployeeRequested
  );
}

function* deleteEmployeeRequested(action: ReturnType<typeof deleteEmployee>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const employeeId = action.payload;
    const method = "DELETE";

    yield call(() =>
      callApi(
        EMPLOYEES_OPTION_REQUEST_URL + employeeId + "?tenantId=" + tenantId,
        token,
        method
      )
    );

    yield put(deleteEmployeeSucceeded(employeeId));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchDeleteEmployee() {
  yield takeEvery(deleteEmployee.getType(), deleteEmployeeRequested);
}

const createEmployeesAttributes = employee => {
  const { mobilityEntries, ...rest } = employee;
  return { ...rest, newMobilityEntries: mobilityEntries };
};

function* createEmployeesRequested(
  action: ReturnType<typeof importAllEmployees>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";

    const promiseCreators = action.payload.map(employee => () =>
      callApi(tenantEmployeesUrl(tenantId), token, method, {
        data: {
          attributes: createEmployeesAttributes(employee),
          type: "employees",
        },
      })
    );
    const total = promiseCreators.length;
    const response = yield batchRequests(20, 1000, promiseCreators, current =>
      put(importAllEmployeesProgress({ current, total }))
    );
    yield put(
      importAllEmployeesSucceeded(
        response.map(r => dataFormatter.deserialize(r))
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchCreateEmployees() {
  yield takeEvery(importAllEmployees.getType(), createEmployeesRequested);
}

function* importEditedEmployeesRequested(
  action: ReturnType<typeof importEditedEmployees>
) {
  try {
    const token = yield select(selectors.token);
    const method = "PATCH";

    const promiseCreators = action.payload.map(employee => () => {
      return callApi(
        employeeUrl(employee.id),
        token,
        method,
        dataFormatter.serialize({
          stuff: {
            ...employee,
            type: "employees",
          },
        })
      );
    });

    const response = yield batchRequests(
      AUTH0_BATCH_SIZE,
      AUTH0_DELAY_IN_MILLISECONDS,
      promiseCreators
    );

    yield put(
      importEditedEmployeesSucceeded(
        response.map(r => dataFormatter.deserialize(r))
      )
    );
    yield put(push(employeesRoute));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchImportEditedEmployees() {
  yield takeEvery(
    importEditedEmployees.getType(),
    importEditedEmployeesRequested
  );
}

function* deleteEmployeesRequested(action: ReturnType<typeof deleteEmployees>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "DELETE";
    const promiseCreators = action.payload.map(employeeId => () =>
      callApi(
        EMPLOYEES_OPTION_REQUEST_URL + employeeId + "?tenantId=" + tenantId,
        token,
        method
      )
    );

    yield batchRequests(
      AUTH0_BATCH_SIZE,
      AUTH0_DELAY_IN_MILLISECONDS,
      promiseCreators
    );

    yield put(deleteEmployeesSucceeded(action.payload));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchDeleteEmployees() {
  yield takeEvery(deleteEmployees.getType(), deleteEmployeesRequested);
}

function* fetchEmployeesWithPaginationRequested(
  action: ReturnType<typeof fetchEmployeesWithPagination>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const { include, filter, sort, page } = action.payload;

    const url = buildURL(`/tenants/${tenantId}/employees`, {
      include: (include || []).join(","),
      filter: { ...filter },
      sort,
      page,
    });

    const response = yield call(() => callApi(url, token));
    yield put(
      fetchEmployeesWithPaginationSucceeded({
        records: dataFormatter.deserialize(response) as Api.V1.Employee[],
        meta: response.meta,
      })
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put(fetchEmployeesWithPaginationFailed(e.message));
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchEmployeesWithPagination() {
  yield takeEvery(
    fetchEmployeesWithPagination.getType(),
    fetchEmployeesWithPaginationRequested
  );
}
