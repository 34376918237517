export interface PaginationLinks {
  first?: string;
  next?: string;
  prev?: string;
  last?: string;
}

export const getCurrentPageNumFromUrl = pathName => {
  const splitedLocationUrl = pathName.split("/");
  return splitedLocationUrl[splitedLocationUrl.length - 1];
};

const extractPageNumberFromRequestUrl = (url: string): string => {
  return url.split("%5Bnumber%5D=")[1].split("&")[0];
};

export const paginationBaseUrl = (match): string => {
  const index = match.url.lastIndexOf("/");
  return match.url.slice(0, index);
};

export const generatePaginationPagesWithNumbers = (
  linksObj: PaginationLinks
): PaginationLinks => {
  return Object.keys(linksObj).reduce(
    (acc, key) => {
      acc[key] = extractPageNumberFromRequestUrl(linksObj[key]);
      return acc;
    },
    { first: "", last: "" }
  );
};
