import React, { FunctionComponent, ReactNode } from "react";
import { Icon, Popup } from "semantic-ui-react";

import { useFormatMessage } from "src/admin-portal/texts/utils";
import CurrencyConversionView from "src/common/components/currency/currency-conversion-view";
import HelpPopup from "src/common/components/help-popup";

interface Props {
  totalGains: number;
  detailsLink?: ReactNode;
}

const OverviewBox: FunctionComponent<Props> = ({
  totalGains,
  children,
  detailsLink,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div className="overview-box-container">
      <h3 className="text-disabled">
        {formatMessage({ id: "instruments.frontpage.value" })}{" "}
        <Popup
          trigger={<Icon name="question circle outline" />}
          content={formatMessage({
            id: "instruments.frontpage.value.popover.content",
          })}
        />
      </h3>
      <div className="overview-box col-center">
        <div className="overview-box-header-container">
          <h2 className="overview-box-header text-center">
            {formatMessage({ id: "instruments.frontpagebox.title" })}
            <HelpPopup textKey="instruments.frontpagebox.popup" />
          </h2>
          <div className="overview-section-header text-center">
            <CurrencyConversionView
              value={totalGains}
              decimals={0}
              showOverrideCurrencyOnly={true}
            />{" "}
          </div>
          <div className="margin-top-s text-center">{detailsLink}</div>
        </div>
        {children}
      </div>
      <div className="overview-disclaimer">
        {formatMessage({ id: "instruments.frontpagebox.disclaimer" })}
      </div>
    </div>
  );
};

export default OverviewBox;
