import React, { FunctionComponent } from "react";
import { FallbackProps } from "react-error-boundary";
import { Button, Modal } from "semantic-ui-react";

const GeneralErrorFallback: FunctionComponent<FallbackProps> = () => (
  <div className="text-center">
    <Modal open={true} size={"tiny"}>
      <Modal.Header className="text-center error-modal-header">
        Oops...
      </Modal.Header>
      <Modal.Content className="text-center">
        <div className="block-m error-modal-description">
          <Modal.Description>
            An unknown error occured. Please try again. If the problem persists,
            please contact us at support@optioincentives.no or by using the chat
            in the bottom right corner
          </Modal.Description>
        </div>
        <div className="block-s">
          <Button
            primary={true}
            basic={true}
            size={"big"}
            icon="refresh"
            content="Try again"
            onClick={() => (window.location.href = "/")}
          />
        </div>
      </Modal.Content>
    </Modal>
  </div>
);

export default GeneralErrorFallback;
