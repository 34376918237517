import moment from "moment";
import { FlagNameValues } from "semantic-ui-react";

import { SupportedLocale } from "src/admin-portal/texts/text-reducer";

interface LanguageDropdownItem {
  key: SupportedLocale;
  value: SupportedLocale;
  flag: FlagNameValues;
  text: string;
}

type FormatMessageFuntion = (data: { id: string }) => string;

export const setMomentLocale = (locale: SupportedLocale): void => {
  if (locale === "en") {
    moment.locale("en-GB");
  } else if (locale === "no") {
    moment.locale("nb");
  } else if (locale === "sv") {
    moment.locale("sv");
  } else if (locale === "da") {
    moment.locale("da");
  }
};

export const languageFromLocale = {
  en: "English",
  no: "Norwegian",
  sv: "Swedish",
  da: "Danish",
};

export const generateSupportedLanguagesOptions = (
  formatMessage?: FormatMessageFuntion
): LanguageDropdownItem[] => {
  return [
    {
      key: "en",
      value: "en",
      flag: "gb",
      text: formatMessage
        ? formatMessage({
            id: "profile.language.english",
          })
        : "English",
    },
    {
      key: "no",
      value: "no",
      flag: "no",
      text: formatMessage
        ? formatMessage({
            id: "profile.language.norwegian",
          })
        : "Norwegian",
    },
    {
      key: "sv",
      value: "sv",
      flag: "se",
      text: formatMessage
        ? formatMessage({
            id: "profile.language.swedish",
          })
        : "Swedish",
    },
    {
      key: "da",
      value: "da",
      flag: "dk",
      text: formatMessage
        ? formatMessage({
            id: "profile.language.danish",
          })
        : "Danish",
    },
  ];
};
