import { createAction } from "redux-act";

import { SupportedLocale } from "src/admin-portal/texts/text-reducer";

export const fetchTexts = createAction("FETCH_TEXTS");

export const fetchTextsSucceeded = createAction(
  "FETCH_TEXTS_SUCCEEDED",
  (payload: {
    defaultTexts: any;
    defaultTextsLocaled: any;
    allTexts: Api.V1.Text[];
  }) => payload
);

export const putTexts = createAction(
  "PUT_TEXTS",
  (payload: Api.V1.Text) => payload
);

export const putTextsSucceeded = createAction(
  "PUT_TEXTS_SUCCEEDED",
  (payload: Api.V1.Text) => payload
);

export const updateDefaultTexts = createAction(
  "UPDATE_DEFAULT_TEXTS",
  (payload: { texts: any; locale: SupportedLocale }) => payload
);

export const updateDefaultTextsSucceeded = createAction(
  "UPDATE_DEFAULT_TEXTS_SUCCEEDED",
  (payload: { texts: any; locale: SupportedLocale }) => payload
);
