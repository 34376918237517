import numeral from "numeral";
import React, { FunctionComponent } from "react";
import { Form, Table } from "semantic-ui-react";

import FormatCurrency from "src/common/components/format-currency";
import FormatSharePrice from "src/common/components/format-share-price";
import { NO_VALUE } from "src/constants";
import { totalStrike } from "src/employee-portal/exercise/exercise-utils";
import {
  awardGain,
  FlatAward,
} from "src/employee-portal/instrument-page/instruments-reducer";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface Props {
  quantityInputState: string;
  quantityInputStateValid: boolean;
  changeHandler: () => void;
  dateFormat: DateFormatState;
  sharePrice: number;
  vested: FlatAward;
  showCapOnGain: boolean;
  showVariableCost: boolean;
  showStrike: boolean;
  showExpiryDate: boolean;
}

const ExerciseQuantityTableRow: FunctionComponent<Props> = ({
  quantityInputState,
  quantityInputStateValid,
  changeHandler,
  dateFormat: { normalDateFormat },
  sharePrice,
  vested,
  showCapOnGain,
  showVariableCost,
  showStrike,
  showExpiryDate,
}) => {
  const amount = parseInt(quantityInputState, 10);
  const gain = awardGain(sharePrice, vested);
  const sharesAmount = Math.floor(amount * vested.sharesConversionFactor);
  const totalExercisePrice = totalStrike(amount, vested);
  const cappedShareprice = vested.capOnGain
    ? Math.min(vested.capOnGain + vested.strike, sharePrice)
    : sharePrice;

  const totalGain = sharesAmount * cappedShareprice - totalExercisePrice;
  const variableCost = showVariableCost
    ? ((cappedShareprice - vested.strike) * vested.socSecRate) /
      (1 + vested.socSecRate)
    : 0;
  const totalVariableCost = showVariableCost ? amount * variableCost : 0;
  const value = totalGain - totalVariableCost;

  if (!vested.quantity) {
    return null;
  }

  return (
    <Table.Row key={vested.trancheId}>
      <Table.Cell>
        <div>{vested.programName}</div>
        <div>{vested.subProgramName}</div>
      </Table.Cell>
      {showStrike && (
        <Table.Cell>
          <FormatSharePrice number={vested.strike} />
        </Table.Cell>
      )}
      {showCapOnGain && (
        <Table.Cell warning={gain.capped}>
          {vested.capOnGain ? (
            <FormatSharePrice number={vested.capOnGain} />
          ) : (
            NO_VALUE
          )}
        </Table.Cell>
      )}
      <Table.Cell>{vested.grantDate.format(normalDateFormat)}</Table.Cell>
      <Table.Cell>{vested.vestedDate.format(normalDateFormat)}</Table.Cell>
      {showExpiryDate && (
        <Table.Cell>{vested.expiryDate.format(normalDateFormat)}</Table.Cell>
      )}
      <Table.Cell>
        {numeral(vested.originalQuantity || vested.quantity).format("0,0")}
      </Table.Cell>
      <Table.Cell>
        <Form.Field
          control="input"
          error={!quantityInputStateValid}
          value={quantityInputState}
          onChange={changeHandler}
        />
      </Table.Cell>
      <Table.Cell>
        {amount ? numeral(sharesAmount).format("0,0") : NO_VALUE}
      </Table.Cell>
      {showVariableCost && (
        <>
          <Table.Cell textAlign="right">
            ≈ <FormatCurrency number={variableCost} decimals={2} />
          </Table.Cell>
          <Table.Cell textAlign="right">
            ≈ <FormatCurrency number={totalVariableCost} />
          </Table.Cell>
        </>
      )}
      <Table.Cell textAlign="right">
        ≈ <FormatCurrency number={value} />
      </Table.Cell>
    </Table.Row>
  );
};

export default ExerciseQuantityTableRow;
