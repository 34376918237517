import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSWR from "swr";

import {
  callJsonApi,
  errorHandlerFactory,
  swrKeyGetterFactory,
  TJsonApiData,
  TJsonApiMeta,
} from "src/common/api/api-helper";
import {
  TFetcherFnReturn,
  TQueryParams,
  IUseJsonApiQueryReturn,
  TRefreshFn,
  TRefetchFn,
} from "src/common/api/types";
import { token as tokenSelector } from "src/selectors";

// Used only for get queries to JSON:API endpoints
const useJsonApiQuery = <
  TData extends TJsonApiData,
  TMeta extends TJsonApiMeta,
  TParams extends TQueryParams
>(
  endpoint?: string,
  params?: TParams
): IUseJsonApiQueryReturn<TData, TMeta, TParams> => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const [loading, setLoading] = useState(false);
  const getSWRKey = useCallback(swrKeyGetterFactory(token), [token]);

  const [swrKey, setSwrKey] = useState(getSWRKey(endpoint, params));

  const { data, error, mutate: boundMutate } = useSWR<
    TFetcherFnReturn<TData, TMeta>
  >(swrKey, callJsonApi, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    onError: errorHandlerFactory(dispatch),
  });

  useEffect(() => {
    if (endpoint || loading) {
      setLoading(!error && !data);
    }
  }, [swrKey, error, data]);

  const refresh: TRefreshFn<TFetcherFnReturn<
    TData,
    TMeta
  >> = useCallback(() => {
    setLoading(true);
    return boundMutate(null);
  }, [boundMutate]);

  const refetch: TRefetchFn<TParams> = useCallback(
    (endpoint, params) => {
      setLoading(true);
      setSwrKey(getSWRKey(endpoint, params));
    },
    [token]
  );

  return {
    data: data ? data.data : undefined,
    meta: data ? data.meta : undefined,
    error,
    refetch,
    refresh,
    loading,
  };
};

export default useJsonApiQuery;
