import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Link, withRouter } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";

import { Tenant } from "src/admin-portal/tenant/tenant-reducer";
import { defaultPageSize } from "src/admin-portal/tranches-page/tranches-page";
import { SharePrice } from "src/employee-portal/exercise/exercise-router";
import { Features } from "src/employee-portal/features/features-reducer";
import { AUTH0_LOGOUT_URL } from "src/env";
import { DateFormatState } from "src/reducers/date-format-reducer";
import { isAdminUserWithoutInstruments } from "src/selectors";

const employeePortalHomeRoute = "/";
const reportsRoute = "/admin/reports";
export const logoutRoute = "/logout";
export const entitiesRoute = "/admin/entities";
const sharePriceRoute = "/admin/share-price";
const tenantAwardsRoute = "/admin/all-awards";
const performanceRulesRoute = "/admin/performance-rules";
export const awardsRoute = "/admin/awards";
export const subprogramAwardsRoute = (subprogramId: string) =>
  `${awardsRoute}/subprogramawards/${subprogramId}`;
export const employeesRoute = "/admin/employees";
export const dividendsRoute = "/admin/dividends";
export const adminWindowsRoute = "/admin/windows";
const adminOrdersRoute = "/admin/orders";
const textsRoute = "/admin/texts/emails";
export const auditLogsRoute = "/admin/audit-logs";
const adminHomeRoute = "/admin";
const selfServiceAdminRoute = "/admin/selfservice";
const faqAdminRoute = "/admin/faq";
const summaryAdminRoute = "/admin/summary";
const dimensionsAdminRoute = "/admin/dimensions";
const transactionsRoute = "/admin/transactions";
const documentsRoute = "/admin/documents";

export interface SearchObj {
  pageNum: string;
  pageSize: string;
  sortBy: string;
  status: string;
  fairValue: string;
  "award.employee.name": string;
  "award.employee.entityId": string;
  "award.employee.locale": string;
  "award.incentiveSubProgramId": string;
  "award.incentiveSubProgram.incentiveProgramId": string;
  "award.incentiveSubProgram.instrumentTypeId": string;
  strikeGteq: number;
  strikeLteq: number;
  quantityGteq: number;
  quantityLteq: number;
  exercisedQuantityGteq: number;
  exercisedQuantityLteq: number;
  releasedQuantityGteq: number;
  releasedQuantityLteq: number;
  expiredQuantityGteq: number;
  expiredQuantityLteq: number;
  terminationQuantityGteq: number;
  terminationQuantityLteq: number;
  grantDateGteq: string;
  grantDateLteq: string;
  vestedDateGteq: string;
  vestedDateLteq: string;
  expiryDateGteq: string;
  expiryDateLteq: string;
}

export const initSearchObj: SearchObj = {
  pageNum: "1",
  pageSize: defaultPageSize.toString(),
  sortBy: "",
  status: "",
  fairValue: "",
  "award.employee.name": "",
  "award.employee.entityId": "",
  "award.employee.locale": "",
  "award.incentiveSubProgramId": "",
  "award.incentiveSubProgram.incentiveProgramId": "",
  "award.incentiveSubProgram.instrumentTypeId": "",
  strikeGteq: null,
  strikeLteq: null,
  quantityGteq: null,
  quantityLteq: null,
  exercisedQuantityGteq: null,
  exercisedQuantityLteq: null,
  releasedQuantityGteq: null,
  releasedQuantityLteq: null,
  expiredQuantityGteq: null,
  expiredQuantityLteq: null,
  terminationQuantityGteq: null,
  terminationQuantityLteq: null,
  grantDateGteq: null,
  grantDateLteq: null,
  vestedDateGteq: null,
  vestedDateLteq: null,
  expiryDateGteq: null,
  expiryDateLteq: null,
};

export const initSearchQuery = queryString.stringify(initSearchObj);

interface PassedProps extends RouteComponentProps<{}> {
  userName: string;
}

interface StateProps {
  showAdminMenu: boolean;
  isAdmin: boolean;
  isAdminUserWithoutInstruments: boolean;
  features: Features;
  logoUrl?: string;
  sharePrice: SharePrice;
  selectedTenant?: Tenant;
  dateFormat: DateFormatState;
}

type Props = PassedProps & StateProps;

class AdminPortalMenu extends Component<Props, {}> {
  public state = { activeItem: employeePortalHomeRoute };

  public renderAdminToolMenu() {
    const { location, selectedTenant } = this.props;
    return (
      <Menu size="tiny" className={"force-no-border-radius"}>
        {selectedTenant && (
          <div className="flex-row align-center space-between flex-1 left-menu-container">
            {selectedTenant.logo_url && (
              <Link to={adminHomeRoute}>
                <img src={selectedTenant.logo_url} alt="" className="logo" />
              </Link>
            )}
          </div>
        )}
        <Menu.Menu position="right">
          <Menu.Item
            active={location.pathname === adminHomeRoute}
            as={Link}
            to={adminHomeRoute}
          >
            Home
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname &&
              location.pathname.startsWith(selfServiceAdminRoute)
            }
            as={Link}
            to={selfServiceAdminRoute}
          >
            SS
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(faqAdminRoute)
            }
            as={Link}
            to={faqAdminRoute}
          >
            FAQ
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(reportsRoute)
            }
            as={Link}
            to={reportsRoute}
          >
            Reports
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(entitiesRoute)
            }
            as={Link}
            to={entitiesRoute}
          >
            Entities
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname &&
              location.pathname.startsWith(dimensionsAdminRoute)
            }
            as={Link}
            to={dimensionsAdminRoute}
          >
            Dimensions
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(employeesRoute)
            }
            as={Link}
            to={employeesRoute}
          >
            Employees
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(awardsRoute)
            }
            as={Link}
            to={awardsRoute}
          >
            Awards
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname &&
              location.pathname.startsWith(adminWindowsRoute)
            }
            as={Link}
            to={adminWindowsRoute}
          >
            Windows
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(sharePriceRoute)
            }
            as={Link}
            to={sharePriceRoute}
          >
            Share Price
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname &&
              location.pathname.startsWith(tenantAwardsRoute)
            }
            as={Link}
            to={`${tenantAwardsRoute}?${initSearchQuery}`}
          >
            All Awards
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname &&
              location.pathname.startsWith(transactionsRoute)
            }
            as={Link}
            to={transactionsRoute}
          >
            Transactions
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname &&
              location.pathname.startsWith(performanceRulesRoute)
            }
            as={Link}
            to={performanceRulesRoute}
          >
            Performance Rules
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname &&
              location.pathname.startsWith(adminOrdersRoute)
            }
            as={Link}
            to={adminOrdersRoute}
          >
            Orders
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(textsRoute)
            }
            as={Link}
            to={textsRoute}
          >
            Texts
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(documentsRoute)
            }
            as={Link}
            to={documentsRoute}
          >
            Documents
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(dividendsRoute)
            }
            as={Link}
            to={dividendsRoute}
          >
            Dividends
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname &&
              location.pathname.startsWith(summaryAdminRoute)
            }
            as={Link}
            to={summaryAdminRoute}
          >
            Summary
          </Menu.Item>
          <Menu.Item
            active={
              location.pathname && location.pathname.startsWith(auditLogsRoute)
            }
            as={Link}
            to={`${auditLogsRoute}?pageNum=1`}
          >
            Logs
          </Menu.Item>
          <Dropdown item={true} text={`${this.props.userName}`}>
            <Dropdown.Menu>
              <Dropdown.Item as={"a"} href={AUTH0_LOGOUT_URL}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    );
  }

  public render() {
    const { location } = this.props;
    return (
      <nav className="site-header">
        <div>
          {location.pathname.startsWith(adminHomeRoute) &&
            this.renderAdminToolMenu()}
        </div>
      </nav>
    );
  }
}

const mapStateToProps = state => ({
  showAdminMenu: state.user.isSysadmin || state.user.isAdmin,
  isAdmin: state.user.isAdmin,
  isAdminUserWithoutInstruments: isAdminUserWithoutInstruments(state),
  sharePrice: state.instrument.sharePrice,
  logoUrl: state.user.tenant && state.user.tenant.logo_url,
  selectedTenant: state.tenant.selectedTenant,
  features: state.features,
  dateFormat: state.dateFormat,
});

export default withRouter<PassedProps>(
  connect<StateProps, null, PassedProps>(mapStateToProps, null)(AdminPortalMenu)
);
