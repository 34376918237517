import React, { FunctionComponent } from "react";
import { addLocaleData, IntlProvider } from "react-intl";
import da from "react-intl/locale-data/da";
import en from "react-intl/locale-data/en";
import no from "react-intl/locale-data/no";
import sv from "react-intl/locale-data/sv";

import { SupportedLocale, Text } from "src/admin-portal/texts/text-reducer";
import { InjectIntlContext } from "src/admin-portal/texts/utils";

addLocaleData(en);
addLocaleData(no);
addLocaleData(sv);
addLocaleData(da);

interface Props {
  locale: SupportedLocale;
  messages: Text;
  children: JSX.Element | JSX.Element[];
}
const IntlProviderWithContext: FunctionComponent<Props> = ({
  locale,
  messages,
  children,
}) => (
  <IntlProvider locale={locale} messages={messages}>
    <InjectIntlContext>{children}</InjectIntlContext>
  </IntlProvider>
);

export default IntlProviderWithContext;
