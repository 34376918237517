import React, { FunctionComponent, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import {
  Button,
  Dimmer,
  Form,
  Input,
  Loader,
  Message,
  TextArea,
} from "semantic-ui-react";

import { toolbarOptions } from "src/admin-portal/content/quill-utils";
import { EmailState } from "src/admin-portal/email/email-reducer";

interface Props {
  recipients: string[];
  postEmail: (payload: Api.V1.Email) => void;
  email: EmailState;
  closeEmailNotification: () => void;
  onOpen?: () => any;
}

const EmailEmployeesForm: FunctionComponent<Props> = ({
  recipients,
  postEmail,
  email,
  closeEmailNotification,
}) => {
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [editableRecipients, setEditableRecipients] = useState("");

  const recipientsCount = editableRecipients.split(",").length;
  let timeoutId = null;

  useEffect(() => {
    if (email.isSucceeded) {
      timeoutId = setTimeout(closeEmailNotification, 3000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [email]);

  useEffect(() => {
    setEditableRecipients(recipients.join(", "));
  }, [recipients]);

  const sendEmail = () => {
    const payload: Api.V1.Email = {
      recipients: editableRecipients
        .split(",")
        .map(r => r.trim())
        .filter(r => r !== ""),
      email_html_content: content,
      email_subject: subject,
    };
    const isConfirmed = confirm(
      `You are about to send emails to ${payload.recipients.length} employees. Are you sure?`
    );
    isConfirmed && postEmail(payload);
  };

  const sendTestEmail = () => {
    const recipient = prompt("Please enter your email");
    recipient &&
      postEmail({
        recipients: [recipient],
        email_html_content: content,
        email_subject: subject,
      });
  };

  return (
    <>
      {email.isSucceeded && (
        <Message
          positive={true}
          header="Success!"
          content="Your emails are sent."
        />
      )}
      <>
        <strong>
          Recipients ({recipientsCount}
          ):
        </strong>
        <Form className="space-vertical-05">
          <TextArea
            placeholder="Add Recipients"
            value={editableRecipients}
            onChange={(_, { value }) => setEditableRecipients(value.toString())}
          />
        </Form>
        <strong>Subject:</strong>
        <Input
          className="space-vertical-05"
          placeholder="Email subject"
          fluid={true}
          value={subject}
          onChange={(e, { value }) => setSubject(value)}
        />
        <div>
          <div>
            <strong>
              Available variables (if the email corresponds to an employee in
              our system)
            </strong>
          </div>
          <div>{"{{share_depository_account}}"}</div>
          <div>{"{{first_name}}"}</div>
          <div>{"{{last_name}}"}</div>
        </div>
        <strong>Message</strong>
        <ReactQuill
          value={content}
          modules={{ toolbar: toolbarOptions }}
          onChange={value => setContent(value)}
          className="space-vertical-05"
        />
        <div className="text-center margin-top-s">
          <Button
            basic={true}
            color="blue"
            content="Send test emails"
            onClick={sendTestEmail}
          />
          <Button
            basic={true}
            color="green"
            content="Send emails"
            onClick={sendEmail}
          />
        </div>
      </>
      {email.isSending && (
        <Dimmer active={true} inverted={true}>
          <Loader inverted={true} inline={true} content="Sending" />
        </Dimmer>
      )}
    </>
  );
};

export default EmailEmployeesForm;
