import { push } from "connected-react-router";
import React, { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Button, Form, Header, Message, Modal } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import { callApi } from "src/common/api/api-helper";
import HelpPopup from "src/common/components/help-popup";
import { validShareDepoNumber } from "src/common/utils/share-depo";
import { CREATE_PURCHASE_ORDER } from "src/employee-portal/purchase/duck/purchase-actions";
import { RootState } from "src/reducers/all-reducers";
import "src/employee-portal/transfer/transfer.less";

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateProps = ReturnType<typeof mapStateToProps>;
type Props = DispatchProps & StateProps;
enum LoadingState {
  FETCHING = "FETCHING",
  DATA = "DATA",
  ERROR = "ERROR",
}
const TransferPage: FunctionComponent<Props> = ({
  isPlacingOrder,
  placeOrder,
  closeModal,
  profileShareDepo,
  token,
}) => {
  const formatMessage = useFormatMessage();
  const [shareDepo, setShareDepo] = useState(profileShareDepo);
  const [phoneNumber, setPhoneNumber] = useState("");
  const shareDepoValid = validShareDepoNumber(shareDepo);
  const phoneNumberValid = phoneNumber && phoneNumber.length >= 8;
  const formValid = shareDepoValid && phoneNumberValid;

  const [state, setState] = useState<LoadingState>(null);
  useEffect(() => {
    if (state === LoadingState.FETCHING) {
      callApi("/orders", token, "POST", {
        order_type: "SHARE_TRANSFER",
        data: {
          phone_number: phoneNumber,
          share_depository_account: shareDepo,
        },
      })
        .then(() => {
          setState(LoadingState.DATA);
        })
        .catch(() => {
          setState(LoadingState.ERROR);
        });
    }
  }, [state]);

  const handleOrder = () => {
    setState(LoadingState.FETCHING);
  };

  return (
    <Modal
      open={true}
      onClose={closeModal}
      closeIcon={true}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      size="tiny"
    >
      <Header>
        <Content id="transfer.header" />
      </Header>
      <Modal.Content>
        {state === LoadingState.DATA ? (
          <Message success={true}>
            <Content id="transfer.success" />
          </Message>
        ) : (
          <>
            <div className="block-s">
              <Content id={"transfer.description"} />
            </div>
            {state === LoadingState.ERROR && (
              <div className="block-s">
                <Message error={true}>
                  <Content id="transfer.error" />
                </Message>
              </div>
            )}
            <div className="transfer-form">
              <Form loading={state === LoadingState.FETCHING}>
                <Form.Field
                  label={
                    <label>
                      <Content id={"transfer.quantity.label"} />
                      <HelpPopup textKey="transfer.quantity.popup" />
                    </label>
                  }
                  control="input"
                  value={phoneNumber}
                  onChange={event => setPhoneNumber(event.target.value)}
                />
                <Form.Field>
                  <label>
                    <Content id="transfer.sharedepositoryaccount.label" />
                    <HelpPopup textKey="transfer.sharedepositoryaccount.popup" />
                  </label>
                  <input
                    value={shareDepo}
                    onChange={event => setShareDepo(event.target.value)}
                  />
                </Form.Field>
              </Form>
            </div>
          </>
        )}
      </Modal.Content>
      {state !== LoadingState.DATA && (
        <Modal.Actions>
          <div className="text-center">
            <Button
              content={formatMessage({ id: "transfer.button.close" })}
              onClick={closeModal}
            />
            <Button
              positive={true}
              content={formatMessage({ id: "transfer.button.confirm" })}
              disabled={!formValid}
              onClick={handleOrder}
              loading={isPlacingOrder}
            />
          </div>
        </Modal.Actions>
      )}
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  isPlacingOrder: state.purchase.isPlacingOrder,
  profileShareDepo: state.employeePortalProfile.share_depository_account,
  token: state.user.token,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  placeOrder: (data: {
    phone_number: number;
    share_depository_account: string | undefined;
    // share_depository_bank: string | undefined;
    // share_depository_clearing_code: string | undefined;
    // share_depository_contact: string | undefined;
    // share_depository_description: string | undefined;
  }) =>
    dispatch({
      type: CREATE_PURCHASE_ORDER,
      data,
      order_type: "SHARE_TRANSFER",
    }),
  closeModal: () => dispatch(push("/")),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransferPage);
