import { createReducer } from "redux-act";

import {
  deleteEmployee,
  deleteEmployees,
  fetchEmployee,
  fetchEmployeeAndEntities,
  fetchEmployeeAndEntitiesSucceeded,
  fetchEmployees,
  fetchEmployeesForExport,
  fetchEmployeesPaginated,
  fetchEmployeesPaginatedSucceeded,
  fetchEmployeesAndEntities,
  fetchEmployeesAndEntitiesSucceeded,
  fetchEmployeesSucceeded,
  fetchEmployeesForExportSucceeded,
  clearEmployeesForExport,
  fetchEmployeeSucceeded,
  importAllEmployees,
  importAllEmployeesSucceeded,
  importEditedEmployees,
  importEditedEmployeesSucceeded,
  postEmployee,
  postEmployeeSucceeded,
  putEmployee,
  putEmployeeSucceeded,
  reverseTerminateEmployee,
  reverseTerminateEmployeeFailed,
  reverseTerminateEmployeeSucceeded,
  selectEmployee,
  terminateEmployee,
  terminateEmployeeSucceeded,
  importAllEmployeesProgress,
  fetchEmployeesWithPagination,
  fetchEmployeesWithPaginationSucceeded,
  fetchEmployeesWithPaginationFailed,
} from "src/admin-portal/employees/employee-actions";
import { Entity } from "src/admin-portal/entity/entity-reducer";
import { selectTenant } from "src/admin-portal/tenant/tenant-actions";
import { PaginationLinks } from "src/common/utils/pagination";

export interface EmployeesPaginated {
  [pageNumber: string]: Api.V1.Employee[];
}

export interface Employee {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  residence: string;
  entity_id: string;
  entityName?: string;
  account_id?: string;
  created_at?: string;
  updated_at?: string;
  termination_date?: string;
  insider: boolean;
  soc_sec?: string;
  overrideEntitySocSec?: string;
  share_depository_account?: string;
  internal_identification?: string;
  is_admin?: boolean;
  mobility_entries: MobilityEntry[];
  entity?: Entity;
}

export interface MobilityEntry {
  from_date: string;
  to_date: string;
  override_entity_soc_sec?: string;
  entity_id: string;
  entity?: Entity;
  employee_id?: string;
}

export interface EmployeeState {
  allEmployees: Api.V1.Employee[];
  allEmployeesPaginated: EmployeesPaginated;
  recordsCount: number;
  allEmployeesForExport: Api.V1.Employee[];
  employee: Api.V1.Employee;
  isFetching: boolean;
  isFetchingEmployee: boolean;
  isFetchingEmployeeAndEntities: boolean;
  isFetchingEmployees: boolean;
  isImportingEmployees: boolean;
  isTerminatingEmployee: boolean;
  employeesPaginationLinks: PaginationLinks;
  employeesPaginationCurrentPage: number;
  importAllEmployeesProgress?: { current: number; total: number };
  allRecords: Api.V1.Employee[];
  meta: any;
}

const initialState: EmployeeState = {
  employee: null,
  allEmployees: [],
  allEmployeesPaginated: null,
  recordsCount: null,
  allEmployeesForExport: null,
  isFetching: false,
  isFetchingEmployee: false,
  isFetchingEmployeeAndEntities: false,
  isFetchingEmployees: false,
  isTerminatingEmployee: false,
  isImportingEmployees: false,
  employeesPaginationLinks: null,
  employeesPaginationCurrentPage: null,
  importAllEmployeesProgress: null,
  allRecords: null,
  meta: null,
};

const updatePaginatedEmployees = (
  state: EmployeeState,
  mapFunction,
  filterFunction?
) => {
  const currentPage = state.employeesPaginationCurrentPage;
  if (state.allEmployeesPaginated) {
    const newEmployeesPaginatedPage: Api.V1.Employee[] = filterFunction
      ? state.allEmployeesPaginated[currentPage].filter(filterFunction)
      : state.allEmployeesPaginated[currentPage].map(mapFunction);

    const newEmployeesPaginated: EmployeesPaginated =
      state.allEmployeesPaginated;
    newEmployeesPaginated[currentPage] = newEmployeesPaginatedPage;

    return newEmployeesPaginated;
  } else return null;
};

const setIsFetching = (state: EmployeeState) => ({
  ...state,
  isFetching: true,
});

export default createReducer(on => {
  on(selectTenant, () => initialState);
  on(fetchEmployee, setIsFetching);
  on(fetchEmployeesAndEntities, setIsFetching);
  on(postEmployee, setIsFetching);
  on(putEmployee, setIsFetching);
  on(importEditedEmployees, setIsFetching);
  on(importAllEmployees, (state, payload) => ({
    ...state,
    isImportingEmployees: true,
    importAllEmployeesProgress: {
      current: 0,
      total: payload.length,
    },
  }));
  on(deleteEmployee, setIsFetching);
  on(deleteEmployees, setIsFetching);

  on(fetchEmployee, (state, _) => ({ ...state, isFetchingEmployee: true }));

  on(fetchEmployeeSucceeded, (state, payload) => {
    if (state.allEmployees.length > 0) {
      const allEmployees = state.allEmployees.map(employee =>
        employee.id === payload.id ? payload : employee
      );

      return {
        ...state,
        employee: payload,
        allEmployees,
        isFetchingEmployee: false,
      };
    } else {
      return {
        ...state,
        employee: payload,
        isFetchingEmployee: false,
      };
    }
  });

  on(fetchEmployeeAndEntities, (state, _) => ({
    ...state,
    ...{ isFetchingEmployeeAndEntities: true },
  }));

  on(fetchEmployeeAndEntitiesSucceeded, (state, _) => ({
    ...state,
    ...{ isFetchingEmployeeAndEntities: false },
  }));

  on(postEmployeeSucceeded, (state, payload) => ({
    ...state,
    allEmployees: [...state.allEmployees, payload],
    isFetching: false,
  }));

  on(putEmployeeSucceeded, (state, payload) => {
    const employeeIndex = state.allEmployees.findIndex(
      employee => employee.id === payload.id
    );
    const employee = {
      ...state.allEmployees[employeeIndex],
      ...payload,
    };
    const employees = [...state.allEmployees];
    employees[employeeIndex] = employee;

    const newPaginatedEmployees = updatePaginatedEmployees(state, e =>
      e.id === payload.id ? payload : e
    );

    if (state.employee) {
      return {
        ...state,
        employee: payload,
        allEmployees: employees,
        allEmployeesPaginated: newPaginatedEmployees,
        isFetching: false,
      };
    } else {
      return {
        ...state,
        allEmployees: employees,
        allEmployeesPaginated: newPaginatedEmployees,
        isFetching: false,
      };
    }
  });

  on(deleteEmployee, (state, payload) => {
    const allEmployees = state.allEmployees.filter(
      employee => employee.id !== payload
    );

    const newPaginatedEmployees = updatePaginatedEmployees(
      state,
      null,
      e => e.id !== payload
    );

    return {
      ...state,
      allEmployees: [...allEmployees],
      allEmployeesPaginated: newPaginatedEmployees,
      isFetching: false,
    };
  });

  on(selectEmployee, (state, payload) => ({
    ...state,
    employee: payload,
  }));

  on(fetchEmployees, (state, _) => ({
    ...state,
    ...{ isFetchingEmployees: true },
  }));

  on(fetchEmployeesSucceeded, (state, payload) => ({
    ...state,
    ...{ allEmployees: payload },
    isFetchingEmployees: false,
  }));

  on(fetchEmployeesForExport, (state, _) => ({
    ...state,
    ...{ isFetchingEmployees: true },
  }));

  on(fetchEmployeesForExportSucceeded, (state, payload) => ({
    ...state,
    ...{ allEmployeesForExport: payload },
    isFetchingEmployees: false,
  }));

  on(clearEmployeesForExport, state => ({
    ...state,
    ...{ allEmployeesForExport: null },
  }));

  on(fetchEmployeesPaginated, state => ({
    ...state,
    ...{ isFetchingEmployees: true },
  }));

  on(
    fetchEmployeesPaginatedSucceeded,
    (
      state,
      {
        employeesPaginated,
        employeesPaginationLinks,
        employeesPaginationCurrentPage,
        recordsCount,
      }
    ) => ({
      ...state,
      allEmployeesPaginated: {
        ...state.allEmployeesPaginated,
        ...employeesPaginated,
      },
      isFetchingEmployees: false,
      employeesPaginationLinks,
      employeesPaginationCurrentPage,
      recordsCount,
    })
  );

  on(fetchEmployeesAndEntitiesSucceeded, (state, _) => ({
    ...state,
    ...{ isFetching: false },
  }));

  on(deleteEmployees, (state, payload) => {
    const allEmployees = state.allEmployees.filter(
      employee => !payload.includes(employee.id)
    );

    const newPaginatedEmployees = updatePaginatedEmployees(
      state,
      null,
      e => !payload.includes(e.id)
    );

    return {
      ...state,
      allEmployees,
      allEmployeesPaginated: newPaginatedEmployees,
      isFetching: false,
    };
  });

  on(terminateEmployee, (state, _) => ({
    ...state,
    ...{ isTerminatingEmployee: true },
  }));

  on(terminateEmployeeSucceeded, (state, payload) => {
    const newEmployees = state.allEmployees.map(e =>
      e.id === payload.employeeId
        ? { ...e, terminationDate: payload.terminationDate }
        : e
    );

    const newPaginatedEmployees = updatePaginatedEmployees(state, e =>
      e.id === payload.employeeId
        ? { ...e, terminationDate: payload.terminationDate }
        : e
    );

    return {
      ...state,
      isTerminatingEmployee: false,
      allEmployees: newEmployees,
      allEmployeesPaginated: newPaginatedEmployees,
    };
  });

  on(reverseTerminateEmployee, (state, _) => ({
    ...state,
    ...{ isTerminatingEmployee: true },
  }));

  on(reverseTerminateEmployeeSucceeded, (state, payload) => {
    const newEmployees = state.allEmployees.map(e =>
      e.id === payload ? { ...e, terminationDate: null } : e
    );

    const newPaginatedEmployees = updatePaginatedEmployees(state, e =>
      e.id === payload ? { ...e, terminationDate: null } : e
    );

    return {
      ...state,
      isTerminatingEmployee: false,
      allEmployees: newEmployees,
      allEmployeesPaginated: newPaginatedEmployees,
    };
  });

  on(importEditedEmployeesSucceeded, (state, payload) => {
    const allEmployees = state.allEmployees.map(employee => {
      const matchedEmployee = payload.find(ae => ae.id === employee.id);

      return matchedEmployee ? matchedEmployee : employee;
    });

    return { ...state, allEmployees, isFetching: false };
  });

  on(reverseTerminateEmployeeFailed, (state, _) => ({
    ...state,
    ...{ isTerminatingEmployee: false },
  }));

  on(importAllEmployeesSucceeded, (state, payload) => ({
    ...state,
    allEmployees: [...state.allEmployees, ...payload],
    isImportingEmployees: false,
  }));

  on(importAllEmployeesProgress, (state, payload) => ({
    ...state,
    importAllEmployeesProgress: payload,
  }));

  on(fetchEmployeesWithPagination, state => ({
    ...state,
    ...{ isFetching: true },
  }));
  on(fetchEmployeesWithPaginationSucceeded, (state, payload) => ({
    ...state,
    allRecords: payload.records,
    meta: payload.meta,
    isFetching: false,
  }));
  on(fetchEmployeesWithPaginationFailed, state => ({
    ...state,
    isFetching: false,
  }));
}, initialState);
