import React, { FunctionComponent } from "react";
import { Icon, Popup, Table } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";

interface Props {}

const CapOnGainHeaderCell: FunctionComponent<Props> = () => {
  return (
    <Table.HeaderCell>
      <Content id={"instruments.capongain.label"} />{" "}
      <Popup
        trigger={<Icon name="question circle outline" />}
        content={<Content id={"instruments.capongain.popup"} />}
      />
    </Table.HeaderCell>
  );
};

export default CapOnGainHeaderCell;
