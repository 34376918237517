import { replace } from "connected-react-router";
import Jsona from "jsona";
import Raven from "raven-js";
import { all, call, put, select, takeEvery } from "redux-saga/effects";

import {
  deleteReport,
  deleteReportSucceeded,
  fetchReports,
  fetchReportsAllAwards,
  fetchReportsAllAwardsSucceeded,
  fetchReportsSucceeded,
  generateReport,
  postReport,
  postReportSucceeded,
  updateReport,
  updateReportSucceeded,
} from "src/admin-portal/actions/reports-actions";
import { callApi, NOT_AUTHORIZED, submitForm } from "src/common/api/api-helper";
import { sortRelationships } from "src/common/utils/sort-relationships";
import { apiShortDate, buildURL } from "src/common/utils/utils";
import * as selectors from "src/selectors";

const reportsFetchUrl = tenantId =>
  `/tenants/${tenantId}/reports?include=uploadedReports`;
const reportsPostUrl = tenantId =>
  `/tenants/${tenantId}/reports?include=uploadedReports`;
const uploadedReportPostUrl = tenantId =>
  `/tenants/${tenantId}/uploaded-reports`;
const reportUrl = reportId => `/reports/${reportId}`;
const dataFormatter = new Jsona();

function* fetchReportsRequested() {
  try {
    const token = yield select(selectors.token);
    const tenantId =
      (yield select(selectors.tenantId)) ||
      (yield select(selectors.userTenantId));

    const response = yield call(() =>
      callApi(reportsFetchUrl(tenantId), token)
    );
    yield put(
      fetchReportsSucceeded(
        dataFormatter.deserialize(response) as Api.V1.Report[]
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchFetchReports() {
  yield takeEvery(fetchReports.getType(), fetchReportsRequested);
}

function* postReportRequested(action: ReturnType<typeof postReport>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";
    const { files, ...attributes } = action.payload.report;

    const uploadedReports = yield all(
      Array.from(files).map(async (file: File) => {
        const uploadReportBody = new FormData();
        uploadReportBody.append("data[type]", "uploaded_reports");
        uploadReportBody.append("data[attributes][file]", file);

        const uploadReportResponse = await submitForm(
          uploadedReportPostUrl(tenantId),
          token,
          uploadReportBody
        );
        const uploadReportSerialized: any = dataFormatter.deserialize(
          uploadReportResponse
        );
        return {
          type: uploadReportSerialized.type,
          id: uploadReportSerialized.id,
        };
      })
    );

    const body = {
      data: {
        type: "reports",
        attributes,
        relationships: {
          uploadedReports: {
            data: uploadedReports,
          },
        },
      },
    };

    const response = yield call(() =>
      callApi(reportsPostUrl(tenantId), token, method, body)
    );
    yield put(
      postReportSucceeded(dataFormatter.deserialize(response) as Api.V1.Report)
    );
    if (action.payload.redirectUrl)
      yield put(replace(action.payload.redirectUrl));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPostReport() {
  yield takeEvery(postReport.getType(), postReportRequested);
}

function* updateReportRequested(action: ReturnType<typeof updateReport>) {
  try {
    const token = yield select(selectors.token);
    const method = "PATCH";
    const { files, id, ...attributes } = action.payload;

    const body = {
      data: {
        id,
        type: "reports",
        attributes,
      },
    };

    const response = yield call(() =>
      callApi(reportUrl(id) + "?include=uploadedReports", token, method, body)
    );
    yield put(
      updateReportSucceeded(
        dataFormatter.deserialize(response) as Api.V1.Report
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchUpdateReport() {
  yield takeEvery(updateReport.getType(), updateReportRequested);
}

function* deleteReportRequested(action: ReturnType<typeof deleteReport>) {
  try {
    const token = yield select(selectors.token);
    const method = "DELETE";

    yield call(() => callApi(reportUrl(action.payload), token, method));
    yield put(deleteReportSucceeded(action.payload));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchDeleteReport() {
  yield takeEvery(deleteReport.getType(), deleteReportRequested);
}

const awardIncludes = [
  "transactions",
  "tranchePerformanceRules.performanceRule",
  "tranchePerformanceRules.performanceRule.performanceRuleEntries",
  "award.employee.entity",
  "award.employee.mobilityEntries",
  "award.employee.mobilityEntries.entity",
  "award.employee.employeeCustomRelationships",
  "award.employee.employeeCustomRelationships.customRelationshipType",
  "award.incentiveSubProgram.incentiveProgram",
];
export const tenantAwardsRequestUrl = (tenantId: string) =>
  buildURL(`/tenants/${tenantId}/tranches`, {
    include: awardIncludes.join(","),
    filter: { "award.incentiveSubProgram.archived": "false" },
    page: {
      number: 1,
      size: 1000000,
    },
  });

function* fetchReportsAllAwardsRequested() {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const awardResponse = yield call(() =>
      callApi(tenantAwardsRequestUrl(tenantId), token)
    );

    const awards = dataFormatter
      .deserialize(awardResponse)
      .map(sortRelationships) as Api.V1.VestingEvent[];

    yield put(fetchReportsAllAwardsSucceeded(awards));
  } catch (e) {
    Raven.captureException(e);
  }
}

export function* watchFetchReportsAllAwards() {
  yield takeEvery(
    fetchReportsAllAwards.getType(),
    fetchReportsAllAwardsRequested
  );
}

function* generateReportRequested(action: ReturnType<typeof generateReport>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const {
      overviewData,
      ifrsData,
      ifrsYtdData,
      socSecData,
      socSecYtdData,
      dilutionData,
      notesData,
      employeeNotesData,
      fromDate,
      toDate,
      filename,
    } = action.payload;
    const response = yield call(() =>
      callApi(`/tenants/${tenantId}/generate_report`, token, "POST", {
        overview_data: overviewData,
        ifrs_data: ifrsData,
        ifrs_ytd_data: ifrsYtdData,
        soc_sec_data: socSecData,
        soc_sec_ytd_data: socSecYtdData,
        dilution_data: dilutionData,
        notes_data: notesData,
        employee_notes_data: employeeNotesData,
        from_date: fromDate.format(apiShortDate),
        to_date: toDate.format(apiShortDate),
        filename,
      })
    );
    const blob = yield response.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  } catch (e) {
    Raven.captureException(e);
  }
}

export function* watchGenerateReport() {
  yield takeEvery(generateReport.getType(), generateReportRequested);
}
