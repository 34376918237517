import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

import { useFormatMessage } from "src/admin-portal/texts/utils";
import CurrencyConversionView from "src/common/components/currency/currency-conversion-view";
import TextWithHelperText from "src/common/components/text-with-helper-text";
import { Window } from "src/common/data/data";
import Text from "src/common/text/text";
import { formatNumber, removeNulls } from "src/common/utils/utils";
import ExpandableSection from "src/employee-portal/home-page/instrument-overview-box/expandable-header";
import InstrumentOverviewContent from "src/employee-portal/home-page/instrument-overview-box/instrument-overview-content";
import { IndividualInstrumentState } from "src/employee-portal/instrument-page/instruments-reducer";

const RSUSection: FunctionComponent<{
  rsu: IndividualInstrumentState;
  exerciseFeature: boolean;
  canExerciseCurrentWindow: boolean;
  currentWindow?: Window;
  nextWindow?: Window;
  startExpanded?: boolean;
  hideNavigation?: boolean;
}> = ({ rsu, startExpanded, hideNavigation }) => {
  const formatMessage = useFormatMessage();

  return (
    <ExpandableSection
      headerText={formatMessage({ id: "instruments.frontpagebox.rsu.header" })}
      headerValue={rsu.gain.totalGain}
      startExpanded={startExpanded}
      compact={true}
    >
      <InstrumentOverviewContent
        sections={[
          [
            {
              title: (
                <TextWithHelperText
                  textKey={"instruments.frontpagebox.rsu.totalgain"}
                  popupTextKey={"instruments.frontpagebox.rsu.totalgain.popup"}
                />
              ),
              value: (
                <CurrencyConversionView
                  value={rsu.gain.totalGain}
                  decimals={0}
                  showOverrideCurrencyOnly={true}
                />
              ),
            },
            {
              title: (
                <TextWithHelperText
                  textKey={"instruments.frontpagebox.rsu.totalinstruments"}
                  popupTextKey={
                    "instruments.frontpagebox.rsu.totalinstruments.popup"
                  }
                />
              ),
              value: formatNumber(rsu.instrumentQuantity),
            },
            rsu.sharesQuantity !== rsu.instrumentQuantity
              ? {
                  title: (
                    <Text
                      textKey={"instruments.frontpagebox.rsu.sharesequivalent"}
                    />
                  ),
                  value: formatNumber(rsu.sharesQuantity),
                }
              : null,
          ].filter(removeNulls),
        ]}
      />
      {!hideNavigation && (
        <div className="text-center">
          <Button
            content={formatMessage({ id: "global.viewDetailsBtn" })}
            as={Link}
            to="/instruments/rsus"
            basic={true}
            primary={true}
          />
        </div>
      )}
    </ExpandableSection>
  );
};

export default RSUSection;
