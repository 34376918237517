import React, { FunctionComponent } from "react";
import { List, Message } from "semantic-ui-react";

import { TrancheGroup } from "src/admin-portal/tranches-page/actions/fair-value-management/utils";

interface Props {
  groupedTranches: TrancheGroup[];
  includedGroups: TrancheGroup[];
  isGrantTypeActive: boolean;
}

const FairValueManagementSummary: FunctionComponent<Props> = ({
  groupedTranches,
  includedGroups,
  isGrantTypeActive,
}) => {
  const transactionsCount = includedGroups.reduce(
    (acc, group) => acc + group.tranches.length,
    0
  );
  return (
    <Message>
      <Message.Header
        content={`Information about what will be changed if user hit the "Save button":`}
      />
      <List ordered={true}>
        <List.Item>
          Count of groups which will be updated: {includedGroups.length}
        </List.Item>
        <List.Item>
          Count of transactions which will be{" "}
          <strong>{isGrantTypeActive ? "updated" : "created"}</strong>:{" "}
          {transactionsCount}
        </List.Item>
        <List.Item>
          Count of groups which will be ignored:{" "}
          {groupedTranches.length - includedGroups.length}
        </List.Item>
      </List>
      <Message.Content
        className="text-warning"
        content='If "Save" button is disabled, than you don&apos;t have fair value set in any of the groups, or you have selected adjustment transaction but date is not valid.'
      />
    </Message>
  );
};

export default FairValueManagementSummary;
