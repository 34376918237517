import { ENGLISH_DATE, NORWEGIAN_DATE } from "src/constants";

export interface DateFormatState {
  normalDateFormat: string;
  dateTimeFormat: string;
}

const initialState: DateFormatState = {
  normalDateFormat: "DD.MM.YYYY",
  dateTimeFormat: "DD.MM.YYYY HH:mm",
};

const dateFormat = (state = initialState, action) => {
  switch (action.type) {
    case NORWEGIAN_DATE:
      return initialState;
    case ENGLISH_DATE:
      return {
        normalDateFormat: "DD MMM YYYY",
        dateTimeFormat: "lll",
      };
    default:
      return state;
  }
};

export default dateFormat;
