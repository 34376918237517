import React from "react";
import { Message } from "semantic-ui-react";

import { useFormatMessage } from "src/admin-portal/texts/utils";

const NoOrdersMessage = () => {
  const formatMessage = useFormatMessage();
  return (
    <div className="text-content-center">
      <Message
        header={formatMessage({ id: "orders.noorders.header" })}
        content={formatMessage({ id: "orders.noorders.description" })}
      />
    </div>
  );
};

export default NoOrdersMessage;
