import moment from "moment";
import numeral from "numeral";
import React, { StatelessComponent } from "react";

import { Table } from "semantic-ui-react";

import CurrencyConversionView from "src/common/components/currency/currency-conversion-view";
import { CurrencyFormatterProps } from "src/common/components/currency/currency-formatter";
import { NO_VALUE } from "src/constants";
import { FlatAward } from "src/employee-portal/instrument-page/instruments-reducer";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface Props {
  award: FlatAward;
  stockPriceToday: number;
  hasExpired: boolean;
  showSharePrice: boolean;
  showTaxableInputValuation: boolean;
  formatter: CurrencyFormatterProps;
  dateFormat: DateFormatState;
  totalGain: number;
}

const RSATableRow: StatelessComponent<Props> = ({
  award,
  stockPriceToday,
  hasExpired,
  showSharePrice,
  showTaxableInputValuation,
  totalGain,
  formatter: { formatCurrency, formatSharePrice },
  dateFormat: { normalDateFormat },
}) => (
  <Table.Row warning={hasExpired}>
    <Table.Cell>
      {" "}
      <div>{award.programName}</div>
      <div>{award.subProgramName}</div>
    </Table.Cell>
    <Table.Cell>{moment(award.grantDate).format(normalDateFormat)}</Table.Cell>
    <Table.Cell>{award.expiryDate.format(normalDateFormat)}</Table.Cell>
    <Table.Cell textAlign="right">
      {award.purchasePrice ? formatCurrency(award.purchasePrice, 2) : NO_VALUE}
    </Table.Cell>
    {showTaxableInputValuation && (
      <Table.Cell textAlign="right">
        {award.taxable_input_valuation
          ? formatCurrency(award.taxable_input_valuation, 2)
          : NO_VALUE}
      </Table.Cell>
    )}
    <Table.Cell textAlign="right">
      {numeral(award.quantity).format()}
    </Table.Cell>
    {showSharePrice && (
      <Table.Cell>
        {formatSharePrice(award.share_price || stockPriceToday)}
      </Table.Cell>
    )}
    <Table.Cell textAlign="right">
      <CurrencyConversionView value={totalGain} decimals={0} />
    </Table.Cell>
  </Table.Row>
);

export default RSATableRow;
