import { createReducer } from "redux-act";

import {
  createDividend,
  createDividendSucceeded,
  fetchDividends,
  fetchDividendsSucceeded,
} from "src/admin-portal/dividend/dividend-actions";
import { selectTenant } from "src/admin-portal/tenant/tenant-actions";

export interface Dividend {
  dividend_date: string;
  dividend_per_share: string;
  share_price_at_dividend_date: string;
}

export interface DividendState {
  dividends: Dividend[];
  isFetchingDividends: boolean;
}

const initialState: DividendState = {
  dividends: [],
  isFetchingDividends: false,
};

const setIsFetching = (state: DividendState) => ({
  ...state,
  isFetchingDividents: true,
});

export default createReducer(on => {
  on(selectTenant, () => initialState);
  on(fetchDividends, setIsFetching);
  on(createDividend, setIsFetching);

  on(fetchDividendsSucceeded, (state, payload) => ({
    ...state,
    dividends: payload,
    isFetchingDividends: false,
  }));

  on(createDividendSucceeded, (state, payload) => ({
    ...state,
    dividends: [...state.dividends, payload],
    isFetchingDividends: false,
  }));
}, initialState);
