import { push } from "connected-react-router";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";

import { fetchTexts } from "src/admin-portal/texts/employee-portal-texts-saga";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { ENGLISH_DATE, NORWEGIAN_DATE } from "src/constants";
import {
  documentsRoute,
  helpRoute,
  orderRoute,
} from "src/employee-portal/employee-portal-router";
import { purchaseAvailableAndPrestine } from "src/employee-portal/purchase/duck/purchase-selectors";
import { RootState } from "src/reducers/all-reducers";
import { isAdminUserWithoutInstruments } from "src/selectors";

const WELCOME_URL = "/welcomes";

function* fetchWelcome(action) {
  try {
    const beforeState: RootState = yield select();
    const token = beforeState.user.token;
    const welcomeResponse = yield call(() => callApi(WELCOME_URL, token));

    yield put({
      type: "FETCH_EMPLOYEE_PORTAL_WELCOME_SUCCEEDED",
      welcomeData: welcomeResponse.data,
    });

    yield call(() => fetchTexts(welcomeResponse.data.profile.locale));
    yield put({
      type:
        welcomeResponse.data.profile.locale === "en"
          ? ENGLISH_DATE
          : NORWEGIAN_DATE,
    });
    yield put({
      type: "FETCH_EMPLOYEE_PORTAL_PROFILE_SUCCEEDED",
      employeeData: welcomeResponse.data.profile,
    });

    const skipRedirects = ["/download/", helpRoute, documentsRoute, orderRoute];
    if (skipRedirects.some(route => location.href.includes(route))) {
      return;
    }

    const afterState: RootState = yield select();
    if (
      afterState.features.documents &&
      afterState.user.documents.some(
        doc => doc.requires_acceptance && !doc.accepted_at
      )
    ) {
      yield put(push("/acceptdocuments"));
    } else if (
      afterState.features.purchase &&
      purchaseAvailableAndPrestine(afterState)
    ) {
      yield put(push("/purchasepopup"));
    } else if (isAdminUserWithoutInstruments(afterState)) {
      yield put(push("/selfservice"));
    }
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else if (e.errorMessage) {
      Raven.captureMessage(e.errorMessage);
      yield put({
        type: "FETCH_EMPLOYEE_PORTAL_WELCOME_FAILED",
        message: e.message,
        error: e,
      });
    } else {
      Raven.captureException(e);
      yield put({
        type: "FETCH_EMPLOYEE_PORTAL_WELCOME_FAILED",
        message: e.message,
        error: e,
      });
    }
  }
}

export function* watchFethEmployeePortalWelcome() {
  yield takeEvery("FETCH_EMPLOYEE_PORTAL_WELCOME", fetchWelcome);
}
