import { createReducer } from "redux-act";

import {
  deleteSharePrice,
  deleteSharePriceSucceeded,
  fetchAnnualVolatilitySucceeded,
  fetchAverageSharePriceSucceeded,
  fetchPrimarySharePriceTicker,
  fetchPrimarySharePriceTickerSucceeded,
  fetchSharePrices,
  fetchSharePricesSucceeded,
  postSharePrice,
  postSharePriceSucceeded,
  putPrimarySharePriceTicker,
  putPrimarySharePriceTickerSucceeded,
  resetSharePrices,
  fetchAnnualVolatility,
  fetchAnnualVolatilityFailed,
  fetchAnnualVolatilityBulk,
  fetchAnnualVolatilityBulkFailed,
  fetchAnnualVolatilityBulkSucceeded,
} from "src/admin-portal/actions/share-price-actions";
import { selectTenant } from "src/admin-portal/tenant/tenant-actions";

type VolatilityObservation = {
  date: string;
  price: string;
  daily_change: undefined | string;
  removed_from_calculation: undefined | boolean;
};
export type AnnualVolatilityData = {
  annualVolatility: string;
  observations: VolatilityObservation[];
  isTimePeriodShortened: boolean;
  removedFromCalculation: number;
};

export type AnnualVolatilityState = {
  isFetching: boolean;
  error?: string;
  data: AnnualVolatilityData;
};

export type GlobalAnnualVolatilityState = {
  [key: string]: AnnualVolatilityState;
};

export interface SharePriceState {
  allSharePrice: Api.V1.SharePrice[];
  primarySharePriceTicker: Api.V1.SharePriceTicker;
  isFetching: boolean;
  isFetchingBulkVolatilities: boolean;
  isFetchingPrimarySharePriceTicker: boolean;
  filtered: {
    [key: string]: Api.V1.SharePrice[];
  };
  annualVolatilities: GlobalAnnualVolatilityState;
  averageSharePrices: {
    [key: string]: Api.V1.AverageSharePrice;
  };
}

const initialState: SharePriceState = {
  allSharePrice: [],
  primarySharePriceTicker: null,
  isFetching: false,
  isFetchingBulkVolatilities: false,
  isFetchingPrimarySharePriceTicker: false,
  filtered: {},
  annualVolatilities: {},
  averageSharePrices: {},
};

const setIsFetching = (state: SharePriceState) => ({
  ...state,
  isFetching: true,
});

export default createReducer(on => {
  on(selectTenant, () => initialState);
  on(fetchSharePrices, setIsFetching);
  on(deleteSharePrice, setIsFetching);
  on(postSharePrice, setIsFetching);
  on(postSharePriceSucceeded, (state, payload) => ({
    ...state,
    allSharePrice: [...state.allSharePrice, payload],
    isFetching: false,
  }));
  on(fetchPrimarySharePriceTicker, state => ({
    ...state,
    isFetchingPrimarySharePriceTicker: true,
  }));
  on(fetchPrimarySharePriceTickerSucceeded, (state, payload) => {
    return {
      ...state,
      primarySharePriceTicker: payload,
      isFetchingPrimarySharePriceTicker: false,
    };
  });
  on(putPrimarySharePriceTicker, state => ({
    ...state,
    isFetchingPrimarySharePriceTicker: true,
  }));
  on(putPrimarySharePriceTickerSucceeded, (state, payload) => ({
    ...state,
    primarySharePriceTicker: payload,
    isFetchingPrimarySharePriceTicker: false,
  }));
  on(fetchSharePricesSucceeded, (state, payload) => {
    return {
      ...state,
      ...{ allSharePrice: payload.sharePrices },
      filtered: payload.date
        ? { ...state.filtered, [payload.date]: payload.sharePrices }
        : { ...state.filtered },
      isFetching: false,
    };
  });
  on(deleteSharePriceSucceeded, (state, payload) => ({
    ...state,
    allSharePrice: [
      ...state.allSharePrice.filter(sharePrice => sharePrice.id !== payload),
    ],
    isFetching: false,
  }));
  on(resetSharePrices || selectTenant, () => initialState);
  on(fetchAnnualVolatilitySucceeded, (state, payload) => ({
    ...state,
    annualVolatilities: {
      ...state.annualVolatilities,
      [payload.dates]: {
        isFetching: false,
        data: payload.data,
      },
    },
  }));
  on(fetchAnnualVolatility, (state, payload) => ({
    ...state,
    annualVolatilities: {
      ...state.annualVolatilities,
      [[payload.startDate, payload.endDate].join("-")]: {
        isFetching: true,
        data: null,
        error: null,
      },
    },
  }));
  on(fetchAnnualVolatilityFailed, (state, payload) => ({
    ...state,
    annualVolatilities: {
      ...state.annualVolatilities,
      [payload.dates]: {
        isFetching: false,
        data: null,
        error: payload.message,
      },
    },
  }));
  on(fetchAnnualVolatilityBulk, (state, payload) => ({
    ...state,
    isFetchingBulkVolatilities: true,
  }));
  on(fetchAnnualVolatilityBulkSucceeded, (state, payload) => ({
    ...state,
    annualVolatilities: {
      ...state.annualVolatilities,
      ...payload.reduce(
        (accu, current) => ({
          ...accu,
          [current.dates]: { isFetching: false, data: current.data },
        }),
        {}
      ),
    },
    isFetchingBulkVolatilities: false,
  }));
  on(fetchAnnualVolatilityBulkFailed, (state, payload) => ({
    ...state,
    isFetchingBulkVolatilities: false,
  }));
  on(fetchAverageSharePriceSucceeded, (state, payload) => ({
    ...state,
    averageSharePrices: {
      ...state.averageSharePrices,
      [payload.dates]: payload.averageSharePrices,
    },
  }));
}, initialState);
