import React, { FunctionComponent } from "react";

import Content from "src/admin-portal/texts/content";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import CurrencyFormatter, {
  CurrencyFormatterProps,
} from "src/common/components/currency/currency-formatter";
import { Window } from "src/common/data/data";
import { TextTerm } from "src/common/utils/text-mappings";
import { ExerciseNavigationButtons } from "src/employee-portal/exercise/common/exercise-navigation-buttons";
import OverviewInfo from "src/employee-portal/exercise/confirm/components/info-section";
import OrderSummaryTable from "src/employee-portal/exercise/confirm/components/order-summary-table";
import {
  ExerciseOrderData,
  SharePrice,
} from "src/employee-portal/exercise/exercise-router";
import { awardGain } from "src/employee-portal/instrument-page/instruments-reducer";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface PassedProps {
  goBack: () => void;
  confirmOrder: () => void;
  paymentBankAccountNumber?: string;
  bicNumber?: string;
  ibanNumber?: string;
  instrumentTerm: TextTerm;
  exerciseWindow: Window;
  payment_address?: string;
  require_share_depository: boolean;
  display_bank_account_number: boolean;
  broker_fee?: number;
  display_share_depository: boolean;
  display_share_depository_bank: boolean;
  display_share_depository_clearing_code: boolean;
  display_share_depository_contact: boolean;
  display_share_depository_description: boolean;
  display_tax_percentage: boolean;
  dateFormat: DateFormatState;
  taxPercentage: number;
}

type Props = ExerciseOrderData & SharePrice & PassedProps;

const ExerciseConfirm: FunctionComponent<Props> = props => {
  const {
    paymentBankAccountNumber,
    instrumentTerm,
    bicNumber,
    ibanNumber,
    exerciseWindow,
    payment_address,
    broker_fee,
    sharePrice,
    exerciseType,
    display_tax_percentage,
    taxPercentage,
  } = props;

  const orderTotal = order =>
    order.orderAmount *
    (order.award.capOnGain
      ? Math.min(order.award.capOnGain + order.costPrice, sharePrice)
      : sharePrice);

  const calcTotalVariableCost = instruments =>
    instruments.reduce((accu, op) => {
      return op.award.employeePaysSocSec
        ? accu +
            (op.orderAmount *
              (sharePrice - op.award.strike) *
              op.award.socSecRate) /
              (1 + op.award.socSecRate)
        : accu;
    }, 0);

  const formatMessage = useFormatMessage();

  const totalQuantity = props.orderInstruments.reduce(
    (accu, op) => accu + op.orderAmount,
    0
  );
  const totalCostOfExercise = props.orderInstruments.reduce((accu, op) => {
    const orderAmount = op.strikeConversionFactor
      ? op.sharesAmount
      : op.orderAmount;
    const costPrice = op.strikeConversionFactor
      ? op.costPrice * op.strikeConversionFactor
      : op.costPrice;
    return accu + orderAmount * costPrice;
  }, 0);
  const averageCost = totalCostOfExercise / totalQuantity;
  const totalSharesEquivalent = props.orderInstruments.reduce(
    (accu, op) => accu + op.cappedSharesAmount,
    0
  );

  const sharesValue = totalSharesEquivalent * sharePrice;

  const estimatedValue = props.orderInstruments.reduce(
    (accu, op) =>
      accu + op.orderAmount * awardGain(sharePrice, op.award).gainPerInstrument,
    0
  );

  const cappedInstrumentsExists = props.orderInstruments.some(
    op => op.orderAmount > 0 && awardGain(sharePrice, op.award).capped
  );

  const cappedAndWillEndInShares =
    cappedInstrumentsExists && exerciseType !== "EXERCISE_AND_SELL";

  const showEstNumberOfShares =
    cappedAndWillEndInShares || exerciseType === "EXERCISE_AND_SELL_TO_COVER";

  const estTotalVariableCost =
    exerciseType === "EXERCISE_AND_HOLD"
      ? calcTotalVariableCost(props.orderInstruments)
      : null;

  const estimatedSalesProceeds =
    sharesValue -
    (broker_fee || 0) -
    totalCostOfExercise -
    (estTotalVariableCost || 0);

  const estSharesValueBasis =
    exerciseType === "EXERCISE_AND_SELL_TO_COVER"
      ? estimatedSalesProceeds
      : props.orderInstruments.reduce((accu, op) => accu + orderTotal(op), 0);

  const estNumberOfSharesWithoutTaxPercentage = showEstNumberOfShares
    ? Math.floor(estSharesValueBasis / sharePrice)
    : null;

  const estNumberOfShares = display_tax_percentage
    ? Math.floor(
        (estNumberOfSharesWithoutTaxPercentage * (100 - taxPercentage)) / 100
      )
    : estNumberOfSharesWithoutTaxPercentage;

  const estimatedNetCash = display_tax_percentage
    ? (estimatedSalesProceeds * taxPercentage) / 100
    : null;

  const showTotalCostOfExercise = totalCostOfExercise !== 0;

  return (
    <CurrencyFormatter>
      {(formatter: CurrencyFormatterProps) => (
        <div className="block-m">
          <h2 className="text-center">
            <Content id="exercise.confirm.header" />
          </h2>
          <OverviewInfo
            type={exerciseType}
            instrumentTerm={instrumentTerm}
            averageCost={averageCost}
            totalCostOfExercise={totalCostOfExercise}
            totalQuantity={totalQuantity}
            totalSharesEquivalent={totalSharesEquivalent}
            bankAccountNumber={paymentBankAccountNumber}
            bicNumber={bicNumber}
            ibanNumber={ibanNumber}
            sharePrice={sharePrice}
            paymentDeadline={exerciseWindow.paymentDeadline}
            payment_address={payment_address}
            formatter={formatter}
            cappedInstrumentsExists={cappedInstrumentsExists}
          />
          <OrderSummaryTable
            estimatedSalesProceeds={estimatedSalesProceeds}
            averageCost={averageCost}
            totalQuantity={totalQuantity}
            totalCostOfExercise={totalCostOfExercise}
            formatter={formatter}
            estNumberOfShares={estNumberOfShares}
            totalSharesEqivalent={totalSharesEquivalent}
            estimatedNetCash={estimatedNetCash}
            estTotalVariableCost={estTotalVariableCost}
            showTotalCostOfExercise={showTotalCostOfExercise}
            {...props}
          />
          <ExerciseNavigationButtons
            forwardDisabled={false}
            goBack={props.goBack}
            goForward={props.confirmOrder}
            overrideForwardButtonText={formatMessage({
              id: "exercise.confirm.button",
            })}
          />
        </div>
      )}
    </CurrencyFormatter>
  );
};

export default ExerciseConfirm;
