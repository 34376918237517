import { useState } from "react";

export const useModal = (
  initiallyOpen = false
): [boolean, () => void, () => void] => {
  const [open, setOpen] = useState(initiallyOpen);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return [open, openModal, closeModal];
};
