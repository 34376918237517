import moment from "moment";
import React, { FunctionComponent } from "react";
import { Icon, Popup, Table } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import ClickableLink from "src/common/components/clickable-link";
import CurrencyFormatter, {
  CurrencyFormatterProps,
} from "src/common/components/currency/currency-formatter";
import { formatNumber } from "src/common/utils/utils";
import { NO_VALUE } from "src/constants";
import { OrderExerciseTypeComponent } from "src/employee-portal/exercise/exercise-router";
import OrderStatusView from "src/employee-portal/order/order-status";
import { Props as OrderProps } from "src/employee-portal/order/table/order-table-row";
import { OrderStatus } from "src/employee-portal/reducers/order";

interface Props extends OrderProps {
  showPaymentInfoModal: () => void;
  showUpdateOrderModal: () => void;
  isUpdateOrderButtonVisible: boolean;
}

const ExerciseRow: FunctionComponent<Props> = ({
  order,
  orderNumber,
  dateFormat,
  showLoan,
  showPaymentInfoModal,
  showUpdateOrderModal,
  isUpdateOrderButtonVisible,
}) => {
  const { exerciseOrder, createdAt, status } = order;

  const {
    exerciseType,
    exerciseOrderLines,
    shareDepositoryAccount,
  } = exerciseOrder;

  return (
    <CurrencyFormatter>
      {(formatter: CurrencyFormatterProps) => {
        return (
          <Table.Row>
            <Table.Cell>{orderNumber}</Table.Cell>
            <Table.Cell>
              {moment(createdAt).format(dateFormat.dateTimeFormat)}
            </Table.Cell>
            <Table.Cell>
              <OrderExerciseTypeComponent type={exerciseType} />
            </Table.Cell>
            <Table.Cell>
              {formatNumber(
                exerciseOrderLines.reduce(
                  (accu, current) => accu + current.exerciseQuantity,
                  0
                )
              )}
            </Table.Cell>
            {showLoan && (
              <>
                <Table.Cell />
                <Table.Cell />
              </>
            )}
            <Table.Cell singleLine={true}>
              {shareDepositoryAccount || NO_VALUE}
            </Table.Cell>
            <Table.Cell singleLine={true}>
              <OrderStatusView status={status} />{" "}
              <Popup
                trigger={<Icon name="question circle outline" />}
                content={<Content id={`orders.${status}.popup.text`} />}
              />
            </Table.Cell>
            <Table.Cell singleLine={true}>
              {exerciseType === "EXERCISE_AND_HOLD" &&
              status === OrderStatus.CREATED ? (
                <ClickableLink onClick={showPaymentInfoModal}>
                  <Content id={"orders.table.paymentinfo.show"} />
                </ClickableLink>
              ) : (
                NO_VALUE
              )}
            </Table.Cell>

            <Table.Cell singleLine={true}>
              {isUpdateOrderButtonVisible ? (
                <ClickableLink onClick={showUpdateOrderModal}>
                  <Content id={"order.employeeUpdateModal.button"} />
                </ClickableLink>
              ) : (
                NO_VALUE
              )}
            </Table.Cell>
          </Table.Row>
        );
      }}
    </CurrencyFormatter>
  );
};

export default ExerciseRow;
