import React, { useContext } from "react";

import { PurchaseInfo } from "src/employee-portal/purchase/duck/purchase-selectors";

interface Currency {
  code: string;
  factor: number;
}

interface PurchaseByQuantity {
  currencies: Currency[];
  purchaseInfo: PurchaseInfo;
}

export const PurchaseByQuantityContext = React.createContext<
  PurchaseByQuantity
>({} as PurchaseByQuantity);

export const usePurchaseByQuantity = () => {
  return useContext(PurchaseByQuantityContext);
};
