import React, { FunctionComponent } from "react";
import { Route, RouteComponentProps, Switch } from "react-router";

interface Props {
  showOnEveryRouteExcept: string[];
  render: (props: RouteComponentProps<any>) => React.ReactNode;
}

const InvertedRoute: FunctionComponent<Props> = props => {
  const { showOnEveryRouteExcept, render } = props;
  return (
    <Switch>
      {showOnEveryRouteExcept.map((path, index) => (
        <Route exact={true} path={path} key={index} />
      ))}
      <Route path="/" render={render} />
    </Switch>
  );
};

export default InvertedRoute;
