import React, {
  FunctionComponent,
  StatelessComponent,
  useContext,
} from "react";
import { connect, useSelector } from "react-redux";

import { currencyCode } from "src/common/selectors/currency-selectors";
import {
  createFormatCurrencyFunction,
  createFormatSharePriceFunction,
} from "src/common/utils/utils";
import { RootState } from "src/reducers/all-reducers";

interface CurrencyContextProps {
  currencyCode: string;
}
export const CurrencyContext = React.createContext<CurrencyContextProps>(
  {} as CurrencyContextProps
);

export interface CurrencyFormatterProps {
  formatCurrency: (number: number, decimals?: number) => string;
  formatCurrencyWithCurrencyCode: (
    currencyCode: string
  ) => (number: number, decimals?: number) => string;
  formatCurrencyAbbriviated: (number: number, decimals?: number) => string;
  formatCurrencyAbbriviatedWithCurrencyCode: (
    currencyCode: string
  ) => (number: number, decimals?: number) => string;
  formatSharePrice: (number: number) => string;
  currencyCode: string;
}

const mapStateToProps = (state: RootState): CurrencyFormatterProps =>
  createCurrencyFormatterProps(currencyCode(state));

const createCurrencyFormatterProps = (
  currencyCode: string
): CurrencyFormatterProps => ({
  formatCurrency: createFormatCurrencyFunction(currencyCode),
  formatCurrencyWithCurrencyCode: (code?: string) =>
    createFormatCurrencyFunction(code || currencyCode),
  formatCurrencyAbbriviated: createFormatCurrencyFunction(currencyCode, true),
  formatCurrencyAbbriviatedWithCurrencyCode: (currencyCode: string) =>
    createFormatCurrencyFunction(currencyCode, true),
  formatSharePrice: createFormatSharePriceFunction(currencyCode),
  currencyCode,
});

interface RenderProp {
  children(props: CurrencyFormatterProps): JSX.Element;
}

const CurrencyFormatter: FunctionComponent<{ code?: string } & RenderProp> = ({
  children,
  code,
}) => {
  const context = useContext(CurrencyContext);
  const fromState = useSelector(currencyCode);
  const currencyCodeToUse = code || context.currencyCode || fromState;
  return <>{children(createCurrencyFormatterProps(currencyCodeToUse))}</>;
};

export default connect<CurrencyFormatterProps>(mapStateToProps)(
  CurrencyFormatter
);
