import React, { FunctionComponent } from "react";
import { Icon, Table } from "semantic-ui-react";

interface Props {
  handleSort: () => void;
  name: string;
  active: boolean;
  order: "asc" | "desc";
}

const SortableHeaderCell: FunctionComponent<Props> = ({
  handleSort,
  active,
  name,
  order,
}) => {
  const iconName = order === "desc" && active ? "caret up" : "caret down";
  return (
    <Table.HeaderCell
      onClick={handleSort}
      className={active ? "text-info" : ""}
    >
      {name} <Icon name={iconName} />
    </Table.HeaderCell>
  );
};

export default SortableHeaderCell;
