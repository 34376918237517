import { Moment } from "moment";
import React, { StatelessComponent } from "react";
import DatePicker from "react-datepicker";

interface DateRangeProps {
  whichStateKey: string;
  startLabel: string;
  endLabel: string;
  startDate: Moment;
  endDate: Moment;
  dateFormat: string;
  handleDateRangeChangeStart: (whichStateKey: string, m: Moment) => void;
  handleDateRangeChangeEnd: (whichStateKey: string, m: Moment) => void;
}

const style = { display: "inline-block", marginBottom: 5 };

const DateRange: StatelessComponent<DateRangeProps> = ({
  whichStateKey,
  startLabel,
  endLabel,
  startDate,
  endDate,
  dateFormat,
  handleDateRangeChangeStart,
  handleDateRangeChangeEnd,
}) => (
  <div>
    <label style={style}>{startLabel}</label>
    <div className="reset-datapicker-container-width" style={style}>
      <DatePicker
        placeholderText="Select date"
        className="ui fluid search selection dropdown"
        selected={startDate}
        selectsStart={true}
        startDate={startDate}
        endDate={endDate}
        onChange={m => {
          handleDateRangeChangeStart(whichStateKey, m);
        }}
        dateFormat={dateFormat}
        todayButton={"Today"}
      />
    </div>
    <label style={style}>{endLabel}</label>
    <div className="reset-datapicker-container-width" style={style}>
      <DatePicker
        placeholderText="Select date"
        className="ui fluid search selection dropdown"
        selected={endDate}
        selectsEnd={true}
        startDate={startDate}
        endDate={endDate}
        onChange={m => {
          handleDateRangeChangeEnd(whichStateKey, m);
        }}
        dateFormat={dateFormat}
        todayButton={"Today"}
      />
    </div>
  </div>
);

export default DateRange;
