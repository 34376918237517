import moment from "moment";
import { DropdownItemProps } from "semantic-ui-react";

import { SearchObj } from "src/admin-portal/menu/admin-portal-menu";
import { allSubprogramsJsonapi } from "src/admin-portal/programs/program-selectors";
import { State as TranchesPageState } from "src/admin-portal/tranches-page/tranches-page";
import { TrancheDataEntry } from "src/admin-portal/tranches-page/types";
import { PaginationLinks } from "src/common/utils/pagination";
import {
  apiShortDate,
  capitalizeFirstLetter,
  sortAlphabetically,
} from "src/common/utils/utils";

export const initialTransaction = {
  transactionType: "",
  comment: "",
  expiryDate: "",
  fairValue: "",
  fvDividend: "",
  fvExpectedLifetime: "",
  fvInterestRate: "",
  fvSharePriceAtGrant: "",
  fvStrikePrice: "",
  fvValuationMethod: "",
  fvVolatility: "",
  grantDate: "",
  purchasePrice: "",
  quantity: 0,
  newQuantityFactor: 0,
  strikeConversionFactor: "",
  strike: "",
  transactionDate: "",
  vestedDate: "",
  costStartDate: "",
  costEndDate: "",
  socSecStartDate: "",
  socSecEndDate: "",
  performanceStartDate: "",
  performanceEndDate: "",
  capOnGain: "",
  taxableInputValuation: "",
  fairValueForSocSec: "",
  fvssDividend: "",
  fvssExpectedLifetime: "",
  fvssInterestRate: "",
  fvssSharePriceAtGrant: "",
  fvssStrikePrice: "",
  fvssValuationMethod: "",
  fvssVolatility: "",
  modificationStartDate: "",
  modificationEndDate: "",
  modificationQuantity: "",
  modificationFairValue: "",
  calculatedFairValue: "",
  lockedToDate: "",
};

export const editableKeys = Object.keys(initialTransaction);

export type EditableTransaction = Exclude<
  Api.V1.Transaction,
  | "id"
  | "type"
  | "terminationQuantity"
  | "terminationDate"
  | "accountId"
  | "trancheId"
  | "tranche"
>;

export interface EditedKeysAndValues {
  keysAndValues: EditableTransaction | any;
  haveBeenEditing: boolean;
}

export const getTrancheStatus = (tranche: Api.V1.VestingEvent): string[] => {
  const {
    quantity,
    exercisedQuantity,
    expiredQuantity,
    terminationQuantity,
    releasedQuantity,
  } = tranche;
  const isActive = quantity > 0;
  const isExercised = exercisedQuantity !== 0;
  const isReleased = releasedQuantity !== 0;
  const isExpired = expiredQuantity !== 0;
  const isTerminated = terminationQuantity !== 0;
  const status = [];

  if (isActive && !isExpired) {
    status.push("active");
  }
  if (isExercised) {
    status.push("exercised");
  }
  if (isExpired) {
    status.push("expired");
  }
  if (isReleased) {
    status.push("released");
  }
  if (isTerminated) {
    status.push("terminated");
  }
  if (status.length === 0) {
    status.push("notActive");
  }

  return status;
};

export const attachStatusKeyToTranche = (
  tranche: Api.V1.VestingEvent
): TrancheDataEntry => ({ ...tranche, status: getTrancheStatus(tranche) });

export const attachStatusKeyToPaginatedTranches = allTranches =>
  allTranches.map(attachStatusKeyToTranche);

const toDropdownObj = (obj): DropdownItemProps => ({
  key: obj.id,
  value: obj.id,
  text: obj.name,
});

export const emptyOptionForDropdown: DropdownItemProps = {
  key: "00",
  value: "",
  text: "- clear on click",
};

export const generateProgramsOptions = (programs: Api.V1.IncentiveProgram[]) =>
  [emptyOptionForDropdown, ...programs.map(p => toDropdownObj(p))].sort(
    sortAlphabetically("text")
  );

export const generateSubProgramsOptions = (
  programs: Api.V1.IncentiveProgram[],
  programId: string
) =>
  [
    emptyOptionForDropdown,
    ...allSubprogramsJsonapi(programs)
      .filter(s => programId === "" || s.incentiveProgram.id === programId)
      .map(s => toDropdownObj(s)),
  ].sort(sortAlphabetically("text"));

export const generateEntitiesOptions = entities => {
  let options = [];
  if (entities) {
    options = entities.map(e => toDropdownObj(e));
  }
  return [emptyOptionForDropdown, ...options].sort(sortAlphabetically("text"));
};

export const generateStatusOptions = (): DropdownItemProps[] => {
  return [
    "active",
    "exercised",
    "released",
    "released_expired",
    "terminated",
    "notActive",
  ].map(status => ({
    key: status,
    value: status,
    text: statusToTitle(status),
  }));
};

export const extractGrants = (tranches: Api.V1.VestingEvent[], dateFormat) =>
  tranches
    .map(
      tranche =>
        tranche.transactions.filter(t => t.transactionType === "GRANT")[0]
    )
    .map(transaction => {
      const { ...tempTransaction } = transaction;
      for (const key in tempTransaction) {
        if (key.includes("Date") && tempTransaction[key]) {
          tempTransaction[key] = moment(
            tempTransaction[key],
            apiShortDate
          ).format(dateFormat);
        }
      }
      return tempTransaction;
    });

export const getRecipients = (tranches: Api.V1.VestingEvent[]): string[] => {
  const emailSet = new Set(tranches.map(t => t.award.employee.email));
  return Array.from(emailSet);
};

export const flagFromLocale = locale => {
  switch (locale) {
    case "en":
      return "us";
    case "no":
      return "no";
    case "sv":
      return "se";
    case "da":
      return "dk";
  }
};

export const languageFromLocale = locale => {
  switch (locale) {
    case "en":
      return "English";
    case "no":
      return "Norwegian";
    case "sv":
      return "Swedish";
    case "da":
      return "Danish";
  }
};

export function statusToTitle(status: string): string {
  return (
    {
      notActive: "Not Active",
      released_expired: "Released/Expired",
    }[status] || capitalizeFirstLetter(status)
  );
}

export const generateTranchesWithNormalDateFormat = (
  tranches: TrancheDataEntry[],
  dateFormat: string
): TrancheDataEntry[] =>
  tranches.map(t => {
    const { ...trancheCopy } = t;
    for (const key in trancheCopy) {
      if (key.includes("Date")) {
        trancheCopy[key] = moment(trancheCopy[key], apiShortDate).format(
          dateFormat
        );
      }
    }

    return trancheCopy;
  });

export const genTranchesPaginationLinks = (
  pageSize,
  recordCount
): PaginationLinks => {
  return {
    first: "1",
    last: Math.ceil(recordCount / pageSize).toString(),
  };
};

type ParseSearchData = Pick<
  TranchesPageState,
  | "name"
  | "instrumentType"
  | "locale"
  | "entity_id"
  | "program_id"
  | "subProgramId"
  | "fair_value"
  | "status"
  | "strikeNumberRange"
  | "quantityNumberRange"
  | "exercisedQuantityNumberRange"
  | "releasedQuantityNumberRange"
  | "expiredQuantityNumberRange"
  | "terminationQuantityNumberRange"
  | "grantDateRange"
  | "vestingDateRange"
  | "expiryDateRange"
>;

export const parseSearchData = (searchFromUrl: SearchObj): ParseSearchData => ({
  name: searchFromUrl["award.employee.name"],
  instrumentType: searchFromUrl["award.incentiveSubProgram.instrumentTypeId"],
  locale: searchFromUrl["award.employee.locale"],
  entity_id: searchFromUrl["award.employee.entityId"],
  program_id: searchFromUrl["award.incentiveSubProgram.incentiveProgramId"],
  subProgramId: searchFromUrl["award.incentiveSubProgramId"],
  fair_value: searchFromUrl.fairValue,
  status: searchFromUrl.status,
  strikeNumberRange: {
    from: searchFromUrl.strikeGteq,
    to: searchFromUrl.strikeLteq,
  },
  quantityNumberRange: {
    from: searchFromUrl.quantityGteq,
    to: searchFromUrl.quantityLteq,
  },
  exercisedQuantityNumberRange: {
    from: -1 * searchFromUrl.exercisedQuantityLteq,
    to: -1 * searchFromUrl.exercisedQuantityGteq,
  },
  expiredQuantityNumberRange: {
    from: -1 * searchFromUrl.expiredQuantityLteq,
    to: -1 * searchFromUrl.expiredQuantityGteq,
  },
  releasedQuantityNumberRange: {
    from: -1 * searchFromUrl.releasedQuantityLteq,
    to: -1 * searchFromUrl.releasedQuantityGteq,
  },
  terminationQuantityNumberRange: {
    from: -1 * searchFromUrl.terminationQuantityLteq,
    to: -1 * searchFromUrl.terminationQuantityGteq,
  },
  grantDateRange: {
    startDate: searchFromUrl.grantDateGteq
      ? moment(searchFromUrl.grantDateGteq, apiShortDate)
      : null,
    endDate: searchFromUrl.grantDateLteq
      ? moment(searchFromUrl.grantDateLteq, apiShortDate)
      : null,
  },
  vestingDateRange: {
    startDate: searchFromUrl.vestedDateGteq
      ? moment(searchFromUrl.vestedDateGteq, apiShortDate)
      : null,
    endDate: searchFromUrl.vestedDateLteq
      ? moment(searchFromUrl.vestedDateLteq, apiShortDate)
      : null,
  },
  expiryDateRange: {
    startDate: searchFromUrl.expiryDateGteq
      ? moment(searchFromUrl.expiryDateGteq, apiShortDate)
      : null,
    endDate: searchFromUrl.expiryDateLteq
      ? moment(searchFromUrl.expiryDateLteq, apiShortDate)
      : null,
  },
});

export const quantityTransactionTypes = [
  "EXERCISE",
  "EXPIRY",
  "TERMINATION",
  "RELEASE",
  "CANCEL",
  "FORFEIT",
];
