import { createReducer } from "redux-act";

import {
  DailySummaries,
  fetchDailySummaries,
  fetchDailySummariesSucceeded,
  fetchDailySummariesFailed,
  clearDailySummaries,
} from "src/admin-portal/summary/summary-actions";
import { PaginationLinks } from "src/common/utils/pagination";

export interface SummaryState {
  readonly allDailySummaries: DailySummaries;
  readonly dailySummariesPaginationLinks: PaginationLinks;
  readonly isFetching: boolean;
}

const initialState: SummaryState = {
  allDailySummaries: null,
  dailySummariesPaginationLinks: null,
  isFetching: false,
};

export default createReducer(on => {
  on(fetchDailySummaries, state => ({ ...state, ...{ isFetching: true } }));
  on(
    fetchDailySummariesSucceeded,
    (state, { dailySummaries, dailySummariesPaginationLinks }) => ({
      ...state,
      allDailySummaries: { ...state.allDailySummaries, ...dailySummaries },
      dailySummariesPaginationLinks,
      isFetching: false,
    })
  );
  on(clearDailySummaries, state => ({
    ...state,
    allDailySummaries: null,
    dailySummariesPaginationLinks: null,
  }));
  on(fetchDailySummariesFailed, state => ({
    ...state,
    isFetching: false,
  }));
}, initialState);
