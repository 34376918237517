import { createReducer } from "redux-act";

import {
  deleteContentTemplateValues,
  deleteContentTemplateValuesSucceeded,
  fetchContents,
  fetchContentsSucceded,
  patchContentTemplateValues,
  patchContentTemplateValuesSucceeded,
  postContentTemplateValues,
  postContentTemplateValuesSucceeded,
} from "src/admin-portal/content/content-actions";
import { selectTenant } from "src/admin-portal/tenant/tenant-actions";

export interface ContentState {
  allContents: Api.V1.ContentTemplate[];
  isFetchingContents: boolean;
}

const initialState: ContentState = {
  allContents: [],
  isFetchingContents: false,
};

export interface ContentUpdateObject {
  name?: string;
  description?: string;
  type?: string;
  content_type?: string;
  raw_content?: string;
  locale?: string;
}

const setIsFetching = (state: ContentState) => ({
  ...state,
  isFetchingContents: true,
});

export default createReducer(on => {
  on(selectTenant, () => initialState);
  on(fetchContents, setIsFetching);
  on(postContentTemplateValues, setIsFetching);
  on(patchContentTemplateValues, setIsFetching);
  on(deleteContentTemplateValues, setIsFetching);
  on(fetchContentsSucceded, (state, payload) => ({
    ...state,
    isFetchingContents: false,
    allContents: payload,
  }));

  on(postContentTemplateValuesSucceeded, (state, payload) => ({
    ...state,
    isFetchingContents: false,
    allContents: state.allContents.map(contents =>
      contents.id === payload.contentTemplate.id
        ? {
            ...contents,
            contentTemplateValues: [...contents.contentTemplateValues, payload],
          }
        : contents
    ),
  }));

  on(patchContentTemplateValuesSucceeded, (state, payload) => ({
    ...state,
    isFetchingContents: false,
    allContents: state.allContents.map(contents =>
      contents.id === payload.contentTemplate.id
        ? {
            ...contents,
            contentTemplateValues: contents.contentTemplateValues.map(ctv =>
              ctv.id === payload.id ? { ...ctv, value: payload.value } : ctv
            ),
          }
        : contents
    ),
  }));

  on(deleteContentTemplateValuesSucceeded, (state, payload) => ({
    ...state,
    isFetchingContents: false,
    allContents: state.allContents.map(contents =>
      contents.id === payload.contentTemplateId
        ? {
            ...contents,
            contentTemplateValues: contents.contentTemplateValues.filter(
              ctv => ctv.id !== payload.contentTemplateValueId
            ),
          }
        : contents
    ),
  }));
}, initialState);
