import React, { FunctionComponent } from "react";
import { connect } from "react-redux";

import { Message } from "semantic-ui-react";

import { useFormatMessage } from "src/admin-portal/texts/utils";
import { availableEvents } from "src/employee-portal/employee-portal-selectors";
import TimelineEventsList from "src/employee-portal/timeline/timeline-events-list";
import { RootState } from "src/reducers/all-reducers";

type StateProps = ReturnType<typeof mapStateToProps>;

const EventsTimelinePage: FunctionComponent<StateProps> = ({
  timelineEvents,
  dateFormat,
}) => {
  const formatMessage = useFormatMessage();

  return (
    <div className="width-limit width-limit-very-small main-content padding-horizontal">
      <h2 className="block-m text-center">
        {formatMessage({ id: "timelinePage.title" })}
      </h2>

      {timelineEvents.length === 0 && (
        <Message
          header={formatMessage({
            id: "timelinePage.no.events.title",
          })}
          content={formatMessage({
            id: "timelinePage.no.events.content",
          })}
        />
      )}

      <TimelineEventsList
        timelineEvents={timelineEvents}
        dateFormat={dateFormat}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  dateFormat: state.dateFormat,
  timelineEvents: availableEvents(state),
});

export default connect<StateProps>(mapStateToProps)(EventsTimelinePage);
