import moment from "moment";
import React, { FunctionComponent } from "react";
import { Icon, Button } from "semantic-ui-react";

import { useFormatMessage } from "src/admin-portal/texts/utils";
import { apiShortDate } from "src/common/utils/utils";
import {
  exerciseRoute,
  purchaseRoute,
} from "src/employee-portal/employee-portal-router";
import { TimelineWindowEvent } from "src/employee-portal/timeline/events-timeline-reducer";
import { DateFormatState } from "src/reducers/date-format-reducer";

import "src/employee-portal/timeline/timeline.less";
import { Link } from "react-router-dom";

interface PassedProps {
  event: TimelineWindowEvent;
  dateFormat: DateFormatState;
}

const WindowTimelineEvent: FunctionComponent<PassedProps> = ({
  event,
  dateFormat,
}) => {
  const formatMessage = useFormatMessage();
  return (
    <div className="timeline-event">
      <div className="timeline-event-title">
        {formatMessage({
          id: `timelineEvent.window.${event.processType}.${event.eventType}.title`,
        })}
      </div>
      <div className="timeline-event-meta">
        <Icon name="clock outline" />
        {formatMessage(
          {
            id: `timelineEvent.window.${event.processType}.${event.eventType}.meta`,
          },
          {
            eventTime: moment(event.eventTime).format(
              dateFormat.dateTimeFormat
            ),
            numberOfDays: moment(event.eventTime).diff(
              moment().format(apiShortDate),
              "days"
            ),
          }
        )}
      </div>
      {event.eventType === "closing" ? (
        <div className="margin-top-xs">
          <Button
            color={"green"}
            as={Link}
            size={"tiny"}
            to={
              event.processType === "purchase" ? purchaseRoute : exerciseRoute
            }
          >
            {formatMessage({
              id: `timelineEvent.window.${event.processType}.${event.eventType}.description`,
            })}
          </Button>
        </div>
      ) : (
        <div className="timeline-event-description">
          {formatMessage({
            id: `timelineEvent.window.${event.processType}.${event.eventType}.description`,
          })}
        </div>
      )}
    </div>
  );
};

export default WindowTimelineEvent;
