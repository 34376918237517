import React, { FunctionComponent } from "react";

import { useMessageExists } from "src/admin-portal/texts/utils";
import If from "src/common/components/if";

interface Props {
  id: string;
}

const IfContentExists: FunctionComponent<Props> = ({ id, children }) => {
  const exists = useMessageExists();
  return <If condition={exists(id)}>{children}</If>;
};

export default IfContentExists;
