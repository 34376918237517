import { RootState } from "src/reducers/all-reducers.ts";

export const token = (state: RootState) => state.user.token;
export const isSysadmin = (state: RootState) => state.user.isSysadmin;
export const isAdminUserWithoutInstruments = (state: RootState) =>
  state.user.isAdmin && !state.instrument.totalQuantity;

export const tenantId = (state: RootState) =>
  state.tenant.selectedTenant ? state.tenant.selectedTenant.id : null;

export const userTenantId = (state: RootState) =>
  state.user.tenant ? state.user.tenant.id : null;

export const tenantIdBothPortals = (state: RootState) =>
  userTenantId(state) || tenantId(state);

export const selectedTenant = (state: RootState) =>
  (state.tenant.selectedTenant && state.tenant.selectedTenant.name) ||
  state.user.tenant.name;

export const selectedTenantCurrency = (state: RootState) =>
  (state.tenant.selectedTenant && state.tenant.selectedTenant.currency_code) ||
  state.user.tenant.currency_code;

export const selectedTenantPrimarySharePriceTickerId = (state: RootState) =>
  (state.tenant.selectedTenant &&
    state.tenant.selectedTenant.primary_share_price_ticker_id) ||
  state.user.tenant.primary_share_price_ticker_id;
