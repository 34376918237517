import React, { StatelessComponent } from "react";
import { connect, MapStateToProps } from "react-redux";

import { createFormatCurrencyFunction } from "src/common/utils/utils";
import { RootState } from "src/reducers/all-reducers";

interface PassedProps {
  number: number;
  decimals?: number;
  overrideCurrencyCode?: string;
  className?: string;
}

interface StateProps {
  currencyCode: string;
}

type Props = PassedProps & StateProps;

const FormatCurrency: StatelessComponent<Props> = ({
  number,
  currencyCode,
  decimals,
  overrideCurrencyCode,
  className,
}) => (
  <span className={className}>
    {createFormatCurrencyFunction(overrideCurrencyCode || currencyCode)(
      number,
      decimals
    )}
  </span>
);

const mapStateToProps: MapStateToProps<StateProps, null, RootState> = (
  state
): StateProps => ({
  currencyCode: state.user.tenant.currency_code,
});

export default connect<StateProps, null, PassedProps, RootState>(
  mapStateToProps
)(FormatCurrency);
