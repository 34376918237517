import moment from "moment";
import React, { FunctionComponent } from "react";
import { Table } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { FileIcon } from "src/common/components/file/file-types";
import { uploadedFileDownloadUrl } from "src/common/utils/utils";
import { DateFormatState } from "src/reducers/date-format-reducer";

interface Props {
  documentsDocumentables: Api.V1.DocumentsDocumentable[];
  postDocumentAcceptance: ({ documentsDocumentableId: string }) => void;
  dateFormat: DateFormatState;
}

const hasAcceptances = (documentsDocumentable: Api.V1.DocumentsDocumentable) =>
  documentsDocumentable.documentAcceptances &&
  documentsDocumentable.documentAcceptances.length > 0;

const DocumentsList: FunctionComponent<Props> = ({
  postDocumentAcceptance,
  documentsDocumentables,
  dateFormat,
}) => (
  <div className="center-center">
    <Table collapsing={true}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <Content id={"global.tableLabel.fileName"} />
          </Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {documentsDocumentables.map((d, i) => (
          <Table.Row key={d.id + i}>
            <Table.Cell>
              <FileIcon fileName={d.document.fileName} /> {d.document.fileName}
            </Table.Cell>
            <Table.Cell>
              {d.requiresAcceptance && !hasAcceptances(d) && (
                <a
                  onClick={postDocumentAcceptance.bind(this, {
                    documentsDocumentableId: d.id,
                  })}
                  href="javascript:void(0)"
                >
                  <Content id={"global.markAsAccepted"} />
                </a>
              )}
              {hasAcceptances(d) && (
                <span>
                  <Content id={"global.accepted"} />
                  {moment(d.documentAcceptances[0].createdAt).format(
                    dateFormat.dateTimeFormat
                  )}
                </span>
              )}
            </Table.Cell>
            <Table.Cell>
              <a
                href={uploadedFileDownloadUrl(d.document)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Content id={"global.downloadTxt"} />
              </a>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </div>
);

export default DocumentsList;
