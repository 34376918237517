import moment from "moment";

import { PurchaseWindow } from "src/common/data/data";
import { apiShortDate, simpleRound, sumNumbers } from "src/common/utils/utils";
import { PurchaseType } from "src/constants";
import { SharePrice } from "src/employee-portal/exercise/exercise-router";
import { RootState } from "src/reducers/all-reducers";

export const purchaseAvailable = (state: RootState): boolean => {
  const window = state.user.currentPurchaseWindow;
  if (!window) {
    return false;
  }
  const { purchase_opportunity, purchasable_award } = window;

  const hasValidInstrumentQuantityType =
    purchase_opportunity &&
    purchase_opportunity.purchase_type ===
      PurchaseType.PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY &&
    purchase_opportunity.purchasedAmount < purchase_opportunity.maximumAmount;

  const hasValidCashType =
    purchase_opportunity &&
    purchase_opportunity.purchase_type ===
      PurchaseType.PURCHASE_OPPORTUNITY_CASH &&
    parseFloat(purchase_opportunity.cash_amount_used || "0") <
      parseFloat(purchase_opportunity.maximum_cash_amount);
  const hasValidPurchasableAward = !!purchasable_award;

  return (
    hasValidInstrumentQuantityType ||
    hasValidCashType ||
    hasValidPurchasableAward
  );
};

/**
 * Returns true if no purchase has happened, false if partly of fully purchase has occurred (or no purchase is available)
 * @param state
 */
export const purchaseAvailableAndPrestine = (state: RootState): boolean => {
  const isPurchaseAvailable = purchaseAvailable(state);
  if (!isPurchaseAvailable) {
    return false;
  }

  const { purchase_opportunity } = state.user.currentPurchaseWindow;
  const partlyPurchased =
    purchase_opportunity &&
    purchase_opportunity.purchase_type ===
      PurchaseType.PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY &&
    purchase_opportunity.purchasedAmount !==
      purchase_opportunity.maximumAmount &&
    purchase_opportunity.purchasedAmount !== 0;

  const partlyPurchasedCashPurchase =
    purchase_opportunity &&
    purchase_opportunity.purchase_type ===
      PurchaseType.PURCHASE_OPPORTUNITY_CASH &&
    parseFloat(purchase_opportunity.cash_amount_used) !==
      parseFloat(purchase_opportunity.maximum_cash_amount) &&
    parseFloat(purchase_opportunity.cash_amount_used) !== 0;

  return (
    !partlyPurchased &&
    !partlyPurchasedCashPurchase &&
    purchase_opportunity.showPopup
  );
};

export interface PurchaseInfo {
  type: PurchaseType;
  purchaseConfigId: string;
  showQuantityStep: boolean;
  showCashAmountStep: boolean;
  showShareDepositoryStep: boolean;
  showBankAccountStep: boolean;
  showNorwegianShareDepositoryQuestion: boolean;
  purchasePrice?: number;
  purchaseBasePrice?: number;
  discount?: number;
  maxCashAmount?: number;
  instrumentType: string;
  documentId: string;
  purchasableQuantity?: number;
  minimumPurchasableQuantity?: number;
  minimumPurchasableCashAmount?: number;
  purchasableCashAmount?: number;
  purchaseOpportunityId?: string;
  awardId?: string;
  videoUrl?: string;
  loan?: LoanConfig;
  enforceShareDepo?: boolean;
  enterCashAmountBeforeDiscount?: boolean;
  purchaseCurrency?: string;
  purchaseCurrencyFactor?: number;
  otherCurrencies?: Array<{
    code: string;
    factor: number;
  }>;
  putDiscount?: string;
  hideForeignAccountQuestion?: boolean;
  hideCongratsIcon?: boolean;
  showTaxExplanation?: boolean;
  overrideSharePrice?: SharePrice;
  showInvestmentEntityStep: boolean;
  purchaseCurrencyConfigs?: Array<PurchaseCurrencyConfig>;
  showInstrumentEstimation: boolean;
}

export type PurchaseCurrencyConfig = {
  currencyCode: string;
  minimumCashAmount: number;
};

export interface LoanConfig {
  min: number;
  max: number;
  loanPeriods: number;
}

export const getPurchaseInfoForCurrentWindow = (
  state: RootState
): PurchaseInfo => {
  const window = state.user.currentPurchaseWindow;
  return getPurchaseInfo(window);
};

export const getPurchaseInfoForNextWindow = (
  state: RootState
): PurchaseInfo => {
  const window = state.user.nextPurchaseWindow;
  return getPurchaseInfo(window);
};

const getPurchaseInfo = (window: PurchaseWindow): PurchaseInfo => {
  if (!window) {
    return null;
  }
  const { purchase_opportunity, purchasable_award } = window;

  if (purchase_opportunity) {
    const common = {
      videoUrl: purchase_opportunity.videoUrl,
      hideCongratsIcon: purchase_opportunity.hideCongratsIcon,
      showBankAccountStep: window.require_bank_account,
      showInvestmentEntityStep: purchase_opportunity.showInvestmentEntityStep,
      showInstrumentEstimation: purchase_opportunity.showInstrumentEstimation,
      overrideSharePrice: purchase_opportunity.overrideSharePrice
        ? {
            sharePrice: Number(purchase_opportunity.overrideSharePrice.price),
            sharePriceDate: moment(
              purchase_opportunity.overrideSharePrice.date,
              apiShortDate
            ),
            manual: purchase_opportunity.overrideSharePrice.manual,
          }
        : null,
    };
    if (
      purchase_opportunity.purchase_type ===
      PurchaseType.PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY
    ) {
      const minimumQuantity = Math.max(
        purchase_opportunity.minimumQuantity -
          purchase_opportunity.purchasedAmount,
        1
      );
      const { code, factor } = purchase_opportunity.purchaseCurrency;
      const hasLoan =
        purchase_opportunity.maximumLoan &&
        purchase_opportunity.maximumLoan !== 0;
      const loan = hasLoan
        ? {
            min: Math.round(purchase_opportunity.minimumLoan * factor),
            max: Math.round(purchase_opportunity.maximumLoan * factor),
            loanPeriods: purchase_opportunity.loanPeriods,
          }
        : null;
      return {
        type: PurchaseType.PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY,
        purchaseConfigId: purchase_opportunity.purchaseConfigId,
        showShareDepositoryStep: purchase_opportunity.showShareDepository,
        showNorwegianShareDepositoryQuestion:
          purchase_opportunity.showNorwegianShareDepositoryQuestion,
        enforceShareDepo: purchase_opportunity.enforceShareDepo,
        showQuantityStep: true,
        showCashAmountStep: false,
        purchasePrice: simpleRound(
          parseFloat(purchase_opportunity.price) * factor,
          2
        ),
        purchaseBasePrice: simpleRound(
          parseFloat(purchase_opportunity.basePrice) * factor,
          2
        ),
        instrumentType: purchase_opportunity.instrument,
        documentId: purchase_opportunity.documentId,
        discount:
          purchase_opportunity.discount &&
          parseFloat(purchase_opportunity.discount),
        purchaseOpportunityId: purchase_opportunity.id,
        minimumPurchasableQuantity: minimumQuantity || 1,
        purchasableQuantity:
          purchase_opportunity.purchasedAmount > 0
            ? 0
            : purchase_opportunity.maximumAmount,
        ...common,
        loan,
        purchaseCurrency: code,
        purchaseCurrencyFactor: factor,
        otherCurrencies: purchase_opportunity.purchaseCurrency.other,
        putDiscount: purchase_opportunity.putDiscount,
        hideForeignAccountQuestion:
          purchase_opportunity.hideForeignAccountQuestion,
        showTaxExplanation: purchase_opportunity.showTaxExplanation,
      };
    }
    const hasLoan =
      purchase_opportunity.maximumLoan &&
      purchase_opportunity.maximumLoan !== 0;
    const loan = hasLoan
      ? {
          min: purchase_opportunity.minimumLoan,
          max: purchase_opportunity.maximumLoan,
          loanPeriods: purchase_opportunity.loanPeriods,
        }
      : null;
    const maxCashAmount = parseFloat(purchase_opportunity.maximum_cash_amount);
    const cashSpent = parseFloat(purchase_opportunity.cash_amount_used || "0");
    const purchasableCashAmount = cashSpent > 0 ? 0 : maxCashAmount;
    return {
      type: PurchaseType.PURCHASE_OPPORTUNITY_CASH,
      purchaseConfigId: purchase_opportunity.purchaseConfigId,
      showShareDepositoryStep: purchase_opportunity.showShareDepository,
      showNorwegianShareDepositoryQuestion:
        purchase_opportunity.showNorwegianShareDepositoryQuestion,
      enforceShareDepo: purchase_opportunity.enforceShareDepo,
      showQuantityStep: false,
      showCashAmountStep: true,
      discount: parseFloat(purchase_opportunity.discount),
      maxCashAmount,
      instrumentType: purchase_opportunity.instrument,
      documentId: purchase_opportunity.documentId,
      purchaseOpportunityId: purchase_opportunity.id,
      purchasableCashAmount,
      minimumPurchasableCashAmount: purchase_opportunity.minimumCashAmount || 1,
      enterCashAmountBeforeDiscount: purchase_opportunity.reverseDiscount,
      purchaseCurrencyConfigs: purchase_opportunity.purchaseCurrencyConfigs.map(
        config => ({
          currencyCode: config.currency_code,
          minimumCashAmount: parseFloat(config.minimum_cash_amount),
        })
      ),
      ...common,
      loan,
    };
  } else if (purchasable_award && purchasable_award.documents[0]) {
    const price = purchasable_award.incentive_sub_program.purchase_config.price;

    const quantity = purchasable_award.tranches
      .map(t => t.quantity)
      .reduce(sumNumbers);
    return {
      type: PurchaseType.PURCHASABLE_AWARD,
      purchaseConfigId: purchase_opportunity.purchaseConfigId,
      showBankAccountStep: false,
      showShareDepositoryStep: false,
      showInvestmentEntityStep: false,
      showNorwegianShareDepositoryQuestion: false,
      showQuantityStep: false,
      showCashAmountStep: false,
      purchasePrice: parseFloat(price),
      instrumentType:
        purchasable_award.incentive_sub_program.instrument_type_id,
      documentId: purchasable_award.documents[0].id,
      purchasableQuantity: quantity,
      awardId: purchasable_award.id,
      showInstrumentEstimation: purchase_opportunity.showInstrumentEstimation,
    };
  } else {
    return null;
  }
};

export const canPurchaseWindow = (window: PurchaseWindow): boolean => {
  const purchaseInfo = getPurchaseInfo(window);

  return (
    purchaseInfo &&
    (purchaseInfo.purchasableQuantity > 0 ||
      purchaseInfo.purchasableCashAmount > 0)
  );
};

export const canPurchaseCurrentWindow = (state: RootState): boolean =>
  canPurchaseWindow(state.user.currentPurchaseWindow);
