import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Icon, Popup } from "semantic-ui-react";

import { useFormatMessage } from "src/admin-portal/texts/utils";
import { TimelineEvent } from "src/employee-portal/timeline/events-timeline-reducer";
import TimelineEventsList from "src/employee-portal/timeline/timeline-events-list";
import { DateFormatState } from "src/reducers/date-format-reducer";

const TimelineBox: FunctionComponent<{
  timelineEvents: TimelineEvent[];
  dateFormat: DateFormatState;
}> = ({ timelineEvents, dateFormat }) => {
  const formatMessage = useFormatMessage();

  return (
    <div className="timeline-box-container">
      <h3 className="text-disabled">
        {formatMessage({ id: "instruments.frontpage.events" })}{" "}
        <Popup
          trigger={<Icon name="question circle outline" />}
          content={formatMessage({
            id: "instruments.frontpage.events.popover.content",
          })}
        />
      </h3>
      <div className="timeline-box">
        {timelineEvents.length === 0 &&
          formatMessage({
            id: "timelineBox.no.events.message",
          })}

        <TimelineEventsList
          timelineEvents={timelineEvents.slice(0, 4)}
          dateFormat={dateFormat}
        />

        {timelineEvents.length > 3 && (
          <div className="timeline-event-btn-container">
            <Link to="/timeline">
              {formatMessage({ id: "global.showMore" })}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimelineBox;
