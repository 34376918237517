import React, { StatelessComponent } from "react";
import { Checkbox, Form } from "semantic-ui-react";

interface Props {
  checked: boolean;
  clickHandler: () => void;
  title: string;
  className?: string;
}

const ShowItemsCheckBox: StatelessComponent<Props> = ({
  checked,
  clickHandler,
  title,
  className,
}) => (
  <div className={className || "text-right block-xxs"}>
    <Form.Field>
      <Checkbox label={title} checked={checked} onChange={clickHandler} />
    </Form.Field>
  </div>
);

export default ShowItemsCheckBox;
