import React, { StatelessComponent } from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";

interface Props {
  goBack: () => void;
  goForward: () => void;
  forwardDisabled: boolean;
  overrideForwardButtonText?: string;
}

export const ExerciseNavigationButtons: StatelessComponent<Props> = ({
  goBack,
  goForward,
  forwardDisabled,
  overrideForwardButtonText,
}) => (
  <div className="page-action-container text-center">
    <div className="block-s">
      <Button size="big" onClick={goBack}>
        <Content id="exerciseNavigationButtons.back" />
      </Button>
      <Button
        positive={true}
        content={
          overrideForwardButtonText ? (
            overrideForwardButtonText
          ) : (
            <Content id="exerciseNavigationButtons.next" />
          )
        }
        icon="right arrow"
        labelPosition="right"
        size="big"
        disabled={forwardDisabled}
        onClick={goForward}
      />
    </div>
    <div>
      <Link to="/">
        <Content id="exerciseNavigationButtons.cancel" />
      </Link>
    </div>
  </div>
);
