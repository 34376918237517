import { get } from "lodash";

export type FieldsVisibility = {
  isAnyFieldVisible: boolean;
  isPurchasingQuestionsVisible: boolean;
  isShareDepositoryNorwegianVisible: boolean;
  isBankAccountVisible: boolean;
  isBICSwiftNumberVisible: boolean;
  isShareDepositoryAccountVisible: boolean;
  isShareDepositoryBankVisible: boolean;
  isShareDepositoryContactVisible: boolean;
  isShareDepositoryDescriptionVisible: boolean;
  isShareDepositoryClearingCodeVisible: boolean;
};

type UseFieldsVisibilityCheck = (order: Api.V1.Order) => FieldsVisibility;

type Predicator = (order: Api.V1.Order) => boolean;

const isShareDepositoryNorwegianVisible: Predicator = order =>
  order.window &&
  order.orderType === "PURCHASE" &&
  get(
    order.purchaseOrder,
    "purchaseOpportunity.purchaseConfig.showNorwegianShareDepoQuestion"
  );

const isPurchasingQuestionsVisible: Predicator = order =>
  order.window &&
  isShareDepositoryNorwegianVisible(order) &&
  !get(
    order.purchaseOrder,
    "purchaseOpportunity.purchaseConfig.hideForeignAccountQuestion"
  );

const isBankAccountVisible: Predicator = order =>
  order.window?.requireBankAccount &&
  order.orderType === "EXERCISE" &&
  order.exerciseOrder.exerciseType === "EXERCISE_AND_SELL";

const isShareDepositoryVisible: Predicator = order => {
  if (
    order.window &&
    order.orderType === "EXERCISE" &&
    order.exerciseOrder.exerciseType !== "EXERCISE_AND_SELL"
  ) {
    return true;
  }

  if (
    order.window &&
    order.orderType === "PURCHASE" &&
    order.purchaseOrder.purchaseOpportunity.purchaseConfig
      .requireShareDepository &&
    !order.purchaseOrder.purchaseOpportunity.purchaseConfig
      .hideForeignAccountQuestion
  ) {
    return true;
  }

  return false;
};

const isShareDepositoryAccountVisible: Predicator = order =>
  order.window?.requireShareDepository && isShareDepositoryVisible(order);

const isShareDepositoryBankVisible: Predicator = order =>
  order.window?.requireShareDepositoryBank && isShareDepositoryVisible(order);

const isShareDepositoryContactVisible: Predicator = order =>
  order.window?.requireShareDepositoryContact &&
  isShareDepositoryVisible(order);

const isShareDepositoryDescriptionVisible: Predicator = order =>
  order.window?.requireShareDepositoryDescription &&
  isShareDepositoryVisible(order);

const isShareDepositoryClearingCodeVisible: Predicator = order =>
  order.window?.requireShareDepositoryClearingCode &&
  isShareDepositoryVisible(order);

const useFieldsVisibilityCheck: UseFieldsVisibilityCheck = order => {
  const fieldsVisibility = {
    isShareDepositoryAccountVisible: isShareDepositoryAccountVisible(order),
    isShareDepositoryBankVisible: isShareDepositoryBankVisible(order),
    isShareDepositoryContactVisible: isShareDepositoryContactVisible(order),
    isShareDepositoryDescriptionVisible: isShareDepositoryDescriptionVisible(
      order
    ),
    isShareDepositoryClearingCodeVisible: isShareDepositoryClearingCodeVisible(
      order
    ),
    isBankAccountVisible: isBankAccountVisible(order),
    isBICSwiftNumberVisible: isBankAccountVisible(order),
    isShareDepositoryNorwegianVisible: isShareDepositoryNorwegianVisible(order),
  };

  return {
    ...fieldsVisibility,
    isPurchasingQuestionsVisible: isPurchasingQuestionsVisible(order),
    isAnyFieldVisible:
      order.window &&
      order.status === "CREATED" &&
      Object.values(fieldsVisibility).some(i => i),
  };
};

export default useFieldsVisibilityCheck;
