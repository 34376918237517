import { createAction } from "redux-act";

export interface TrancheForPerformanceRule {
  trancheId: string;
  weightFactor: string;
}

export const postEmail = createAction(
  "POST_EMAIL",
  (payload: Api.V1.Email) => payload
);

export const postEmailSucceeded = createAction("POST_EMAIL_SUCCEEDED");

export const closePostEmailSucceededNotification = createAction(
  "CLOSE_POST_EMAIL_SUCCEEDED_NOTIFICATION"
);
