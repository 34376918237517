import Jsona from "jsona";
import { get, pickBy } from "lodash";
import Raven from "raven-js";
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";

import { fetchEmployeesStandalone } from "src/admin-portal/employees/employee-saga";
import { toVestingEventAPI } from "src/admin-portal/import/import-saga";
import {
  ADD_PROGRAM,
  ADD_PROGRAM_FAILED,
  ADD_PROGRAM_SUCCEEDED,
  DELETE_ALL_PROGRAMS,
  DELETE_ALL_PROGRAMS_FAILED,
  DELETE_ALL_PROGRAMS_SUCCEEDED,
  DELETE_PROGRAM,
  DELETE_PROGRAM_FAILED,
  DELETE_PROGRAM_SUCCEEDED,
  FETCH_EMPLOYEES_AND_PROGRAMS,
  FETCH_EMPLOYEES_AND_PROGRAMS_FAILED,
  FETCH_EMPLOYEES_AND_PROGRAMS_SUCCEEDED,
  FETCH_PROGRAMS,
  FETCH_PROGRAMS_AND_SUBPROGRAMS,
  FETCH_PROGRAMS_AND_SUBPROGRAMS_FAILED,
  FETCH_PROGRAMS_AND_SUBPROGRAMS_SUCCEEDED,
  FETCH_PROGRAMS_FAILED,
  FETCH_PROGRAMS_SUCCEEDED,
  IMPORT_ALL_PROGRAM_AWARDS,
  IMPORT_ALL_PROGRAM_AWARDS_FAILED,
  IMPORT_ALL_PROGRAM_AWARDS_SUCCEEDED,
  POST_PROGRAM,
  POST_PROGRAM_FAILED,
  POST_PROGRAM_SUCCEEDED,
  PUT_PROGRAM,
  PUT_PROGRAM_FAILED,
  PUT_PROGRAM_SUCCEEDED,
} from "src/admin-portal/programs/program-actions";
import {
  ProgramAndSubProgramImport,
  SingleAwardImport,
} from "src/admin-portal/programs/program-import";
import { Program } from "src/admin-portal/programs/program-reducer";
import * as selectors from "src/admin-portal/programs/program-selectors";
import { sortedProgramsAndSubprograms } from "src/admin-portal/programs/program-utils";
import {
  ADD_SUBPROGRAM,
  ADD_SUBPROGRAM_FAILED,
  ADD_SUBPROGRAM_SUCCEEDED,
  POST_SUBPROGRAM,
} from "src/admin-portal/subprograms/subprogram-actions";
import { postSubProgramRequested } from "src/admin-portal/subprograms/subprogram-saga";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { batchRequests } from "src/common/sagas/batch-requests-saga";
import { sortMultipleLevels } from "src/common/utils/sort";
import { buildURL, sumNumbers } from "src/common/utils/utils";
import * as commonSelectors from "src/selectors";

const dataFormatter = new Jsona();

const PROGRAM_REQUEST_URL = "/incentive_programs?tenantId=";
const PROGRAM_OPTION_REQUEST_URL = "/incentive_programs/";
const AWARDS_REQUEST_URL = "/awards?tenantId=";

function* addProgramRequested(action) {
  try {
    yield put({ type: ADD_PROGRAM_SUCCEEDED, program: action.program });
  } catch (e) {
    Raven.captureException(e);
    yield put({ type: ADD_PROGRAM_FAILED, message: e.message });
  }
}

export function* watchAddProgram() {
  yield takeEvery(ADD_PROGRAM, addProgramRequested);
}

function* addSubProgramRequested(action) {
  try {
    yield put({
      type: ADD_SUBPROGRAM_SUCCEEDED,
      subProgram: action.subProgram,
    });
  } catch (e) {
    Raven.captureException(e);
    yield put({ type: ADD_SUBPROGRAM_FAILED, message: e.message });
  }
}

export function* watchAddSubProgram() {
  yield takeEvery(ADD_SUBPROGRAM, addSubProgramRequested);
}

function* postProgramRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";

    const programResponse = yield call(() =>
      callApi(PROGRAM_REQUEST_URL + tenantId, token, method, action.program)
    );
    yield put({
      type: POST_PROGRAM_SUCCEEDED,
      program: programResponse.data,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: POST_PROGRAM_FAILED, message: e.message });
    }
  }
}

export function* watchPostProgram() {
  yield takeEvery(POST_PROGRAM, postProgramRequested);
}

interface ImportAllProgramAwardsAction {
  type: "IMPORT_ALL_PROGRAM_AWARDS";
  programs: ProgramAndSubProgramImport[];
  awards: SingleAwardImport[];
}

const isNewProgram = (actionProgram, allPrograms) =>
  !allPrograms.find(p => p.name === actionProgram.name);

function* importAllProgramAwardsRequested(
  action: ImportAllProgramAwardsAction
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const allPrograms = yield select(selectors.allProgramsIncludingSubPrograms);
    const method = "POST";
    const programs = action.programs || [];

    // programs which already exists and maybe new subprograms or awards
    // should be attached to them
    const oldProgramsImport = programs.filter(
      actionProgram => !isNewProgram(actionProgram, allPrograms)
    );

    // new subPrograms which should be added to some of
    // pre-existing programs
    const newSubProgramsImport = selectors.extractNewSubProgramsFromImport(
      allPrograms,
      oldProgramsImport
    );

    yield all(
      newSubProgramsImport.map(newSubProgram => {
        const programId = allPrograms.find(
          program => program.name === newSubProgram.programName
        ).id;
        const { fakeId, programName, ...rest } = newSubProgram;

        return call(() =>
          postSubProgramRequested({
            type: POST_SUBPROGRAM,
            subProgram: {
              ...rest,
              incentive_sub_program_template: {
                tranche_templates: [],
              },
            },
            programId,
          })
        );
      })
    );

    // programs which didn't existed before import (with their related new subprograms)
    const newProgramsImport = programs.filter(actionProgram =>
      isNewProgram(actionProgram, allPrograms)
    );
    const newProgramsImportBodies = newProgramsImport.map(program => ({
      name: program.name,
      startDate: program.startDate,
      endDate: program.endDate,
      capacity: program.capacity,
      incentive_sub_programs: program.incentive_sub_programs.map(
        subProgram => ({
          name: subProgram.name,
          instrument_type_id: subProgram.instrument_type_id,
          settlement_type_id: subProgram.settlement_type_id,
          performance: subProgram.performance,
          use_fair_value_for_soc_sec: subProgram.use_fair_value_for_soc_sec,
          incentive_sub_program_template: {
            tranche_templates: [],
          },
          awards: [],
        })
      ),
    }));

    yield all(
      newProgramsImportBodies.map(program =>
        call(() =>
          callApi(PROGRAM_REQUEST_URL + tenantId, token, method, program)
        )
      )
    );

    yield put({
      type: FETCH_PROGRAMS_AND_SUBPROGRAMS,
      payload: {
        include: [
          "incentiveSubPrograms.awards",
          "incentiveSubPrograms.incentiveSubProgramTemplate.trancheTemplates",
          "incentiveSubPrograms.purchaseConfig",
        ],
      },
    });

    yield take(FETCH_PROGRAMS_AND_SUBPROGRAMS_SUCCEEDED);

    const allProgramsAfterUpdate: Api.V1.IncentiveProgram[] = yield select(
      selectors.allProgramsIncludingSubPrograms
    );

    // after handling all old/new programs and subPrograms
    // start handling awards
    const awards = action.awards.map((a: SingleAwardImport) => {
      const subProgram = allProgramsAfterUpdate
        .find(program => program.name === a.programName)
        .incentiveSubPrograms.find(sp => sp.name === a.subProgramName);

      return {
        employee_id: a.employee_id,
        incentive_sub_program_id: subProgram.id,
        quantity: a.tranches.map(ve => ve.quantity).reduce(sumNumbers, 0),
        tranches: a.tranches.map(toVestingEventAPI),
      };
    });

    yield batchRequests(
      20,
      3000,
      awards.map(award => () =>
        callApi(AWARDS_REQUEST_URL + tenantId, token, method, award)
      )
    );

    yield put({ type: IMPORT_ALL_PROGRAM_AWARDS_SUCCEEDED });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({
        type: IMPORT_ALL_PROGRAM_AWARDS_FAILED,
        message: e.message,
      });
    }
  }
}

export function* watchImportAllProgramAwards() {
  yield takeEvery(IMPORT_ALL_PROGRAM_AWARDS, importAllProgramAwardsRequested);
}

export function* fetchPrograms() {
  const token = yield select(selectors.token);
  const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

  const programResponse = yield call(() =>
    callApi(PROGRAM_REQUEST_URL + tenantId, token)
  );
  yield put({
    type: FETCH_PROGRAMS_SUCCEEDED,
    programs: sortedProgramsAndSubprograms(programResponse.data),
  });
}

function* fetchAllEmployeesAndPrograms() {
  yield fork(fetchEmployeesStandalone);
  yield fork(fetchPrograms);
}

function* fetchEmployeesAndPrograms() {
  try {
    yield call(fetchAllEmployeesAndPrograms);
    yield put({ type: FETCH_EMPLOYEES_AND_PROGRAMS_SUCCEEDED });
  } catch (e) {
    if (e.status == NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({
        type: FETCH_EMPLOYEES_AND_PROGRAMS_FAILED,
        message: e.message,
      });
    }
  }
}

export function* watchFetchEmployeesAndPrograms() {
  yield takeEvery(FETCH_EMPLOYEES_AND_PROGRAMS, fetchEmployeesAndPrograms);
}

function* fetchProgramsAndSubprogramsRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(commonSelectors.tenantIdBothPortals);

    const include = (
      get(action, "payload.include") || ["incentiveSubPrograms"]
    ).join(",");
    const url = buildURL(`/tenants/${tenantId}/incentive-programs`, {
      filter: pickBy(action.filters),
      include,
    });
    const programResponse = yield call(() => callApi(url, token));

    yield put({
      programs: sortedProgramsAndSubprograms(
        dataFormatter.deserialize(programResponse) as Api.V1.IncentiveProgram[]
      ),
      type: FETCH_PROGRAMS_AND_SUBPROGRAMS_SUCCEEDED,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({
        type: FETCH_PROGRAMS_AND_SUBPROGRAMS_FAILED,
        message: e.message,
      });
    }
  }
}

export function* watchFetchProgramsAndSubprograms() {
  yield takeEvery(
    FETCH_PROGRAMS_AND_SUBPROGRAMS,
    fetchProgramsAndSubprogramsRequested
  );
}

function* fetchProgramsRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);

    const programResponse = yield call(() =>
      callApi(PROGRAM_REQUEST_URL + tenantId, token)
    );
    yield put({
      type: FETCH_PROGRAMS_SUCCEEDED,
      programs: programResponse.data,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: FETCH_PROGRAMS_FAILED, message: e.message });
    }
  }
}

export function* watchFetchPrograms() {
  yield takeEvery(FETCH_PROGRAMS, fetchProgramsRequested);
}

function* putProgramRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const programId = action.programId;
    const method = "PUT";

    const programResponse = yield call(() =>
      callApi(
        PROGRAM_OPTION_REQUEST_URL + programId + "?tenantId=" + tenantId,
        token,
        method,
        action.program
      )
    );
    yield put({
      type: PUT_PROGRAM_SUCCEEDED,
      program: programResponse.data,
    });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: PUT_PROGRAM_FAILED, message: e.message });
    }
  }
}

export function* watchPutProgram() {
  yield takeEvery(PUT_PROGRAM, putProgramRequested);
}

function* deleteProgramRequested(action) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const programId = action.programId;
    const method = "DELETE";

    yield call(() =>
      callApi(
        PROGRAM_OPTION_REQUEST_URL + programId + "?tenantId=" + tenantId,
        token,
        method
      )
    );
    yield put({ type: DELETE_PROGRAM_SUCCEEDED, programId });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: DELETE_PROGRAM_FAILED, message: e.message });
    }
  }
}

export function* watchDeleteProgram() {
  yield takeEvery(DELETE_PROGRAM, deleteProgramRequested);
}

interface DeleteAllProgramsAction {
  type: "DELETE_ALL_PROGRAMS";
  programs: Program[];
}

function* deleteAllProgramsRequested(action: DeleteAllProgramsAction) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "DELETE";

    const programs = yield all(
      action.programs.map(program =>
        call(() =>
          callApi(
            PROGRAM_OPTION_REQUEST_URL + program.id + "?tenantId=" + tenantId,
            token,
            method
          )
        )
      )
    );
    yield put({ type: DELETE_ALL_PROGRAMS_SUCCEEDED, programs });
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put({ type: DELETE_ALL_PROGRAMS_FAILED, message: e.message });
    }
  }
}

export function* watchDeleteAllPrograms() {
  yield takeEvery(DELETE_ALL_PROGRAMS, deleteAllProgramsRequested);
}
