import { createReducer } from "redux-act";

import {
  fetchCustomRelationshipTypes,
  fetchCustomRelationshipTypesSucceeded,
  fetchCustomRelationshipTypesFailed,
  putCustomRelationshipTypesSucceeded,
  putCustomRelationshipTypes,
  putCustomRelationshipTypeSucceeded,
  putCustomRelationshipType,
  putCustomRelationshipTypeFailed,
  postCustomRelationshipType,
  postCustomRelationshipTypeSucceeded,
  postCustomRelationshipTypeFailed,
  deleteCustomRelationshipType,
  deleteCustomRelationshipTypeSucceeded,
  deleteCustomRelationshipTypeFailed,
} from "src/admin-portal/actions/custom-relationship-types-actions";
import { selectTenant } from "src/admin-portal/tenant/tenant-actions";

export interface CustomRelationshipTypeState {
  readonly allCustomRelationshipTypes: Api.V1.CustomRelationshipType[];
  readonly isUpdatingCustomRelationshipTypes: boolean;
  readonly isFetching: boolean;
  readonly isError: boolean;
}

const initialState: CustomRelationshipTypeState = {
  allCustomRelationshipTypes: null,
  isUpdatingCustomRelationshipTypes: false,
  isFetching: false,
  isError: null,
};

export default createReducer(on => {
  on(selectTenant, () => initialState);
  on(fetchCustomRelationshipTypes, state => ({
    ...state,
    ...{ isFetching: true },
  }));
  on(fetchCustomRelationshipTypesSucceeded, (state, payload) => ({
    ...state,
    allCustomRelationshipTypes: payload,
    isFetching: false,
  }));
  on(fetchCustomRelationshipTypesFailed, state => ({
    ...state,
    isFetching: false,
  }));

  on(postCustomRelationshipType, state => ({
    ...state,
    isFetching: true,
  }));
  on(postCustomRelationshipTypeSucceeded, (state, payload) => {
    const updated = { ...state.allCustomRelationshipTypes, ...payload };

    return {
      ...state,
      allCustomRelationshipTypes: updated,
      isFetching: false,
    };
  });
  on(postCustomRelationshipTypeFailed, state => ({
    ...state,
    isFetching: false,
    isError: true,
  }));

  on(putCustomRelationshipType, state => ({
    ...state,
    isFetching: true,
  }));
  on(putCustomRelationshipTypeSucceeded, (state, payload) => {
    const withoutUpdated = state.allCustomRelationshipTypes.filter(
      c => c.id === payload.id
    );
    const updated = { ...withoutUpdated, ...payload };

    return {
      ...state,
      allCustomRelationshipTypes: updated,
      isFetching: false,
    };
  });
  on(putCustomRelationshipTypeFailed, state => ({
    ...state,
    isFetching: false,
    isError: true,
  }));

  on(deleteCustomRelationshipType, state => ({
    ...state,
    isFetching: true,
  }));
  on(deleteCustomRelationshipTypeSucceeded, (state, payload) => {
    const withoutDeleted = state.allCustomRelationshipTypes.filter(
      c => c.id === payload
    );

    return {
      ...state,
      allCustomRelationshipTypes: withoutDeleted,
      isFetching: false,
    };
  });
  on(deleteCustomRelationshipTypeFailed, state => ({
    ...state,
    isFetching: false,
    isError: true,
  }));

  on(putCustomRelationshipTypes, state => ({
    ...state,
    isUpdatingCustomRelationshipTypes: true,
  }));

  on(putCustomRelationshipTypesSucceeded, (state, payload) => {
    const ids = payload.map(c => c.id);
    const filtered = state.allCustomRelationshipTypes.filter(
      c => !ids.includes(c.id)
    );
    const updated = [...filtered, ...payload].filter(c => c.typeCustom);

    return {
      ...state,
      allCustomRelationshipTypes: updated,
      isUpdatingCustomRelationshipTypes: false,
    };
  });
}, initialState);
