import { createReducer } from "redux-act";

import {
  deleteSysadminFailed,
  deleteSysadminSucceeded,
  fetchSysadmins,
  fetchSysadminsFailed,
  fetchSysadminsSucceeded,
  postSysadminFailed,
  postSysadminSucceeded,
} from "src/admin-portal/sysadmin/sysadmin-actions";

export interface Sysadmin {
  id?: string; // optional so it can be reused in the SysAdminManagementPage
  firstName: string;
  lastName: string;
  email: string;
  role?: string;
  avatar?: string;
}

export interface SysadminState {
  allSysadmins: Sysadmin[];
  isFetching: boolean;
  hasFetchingErr: boolean;
  hasPostErr: boolean;
  hasDeleteErr: boolean;
}

const initialState: SysadminState = {
  allSysadmins: [],
  hasDeleteErr: false,
  hasFetchingErr: false,
  hasPostErr: false,
  isFetching: false,
};

export default createReducer(on => {
  on(fetchSysadmins, state => ({ ...state, isFetching: true }));
  on(fetchSysadminsSucceeded, (state, payload) => ({
    ...state,
    allSysadmins: payload,
    isFetching: false,
    hasFetchingErr: false,
  }));
  on(fetchSysadminsFailed, state => ({
    ...state,
    hasFetchingErr: true,
    isFetching: false,
  }));
  on(postSysadminSucceeded, (state, payload) => ({
    ...state,
    allSysadmins: [...state.allSysadmins, payload],
    hasDeleteErr: false,
    hasPostErr: false,
  }));
  on(postSysadminFailed, state => ({
    ...state,
    hasDeleteErr: false,
    hasPostErr: true,
  }));

  on(deleteSysadminSucceeded, (state, payload) => {
    const newAllSysadmins = state.allSysadmins.filter(
      sysadmin => sysadmin.id !== payload
    );
    return {
      ...state,
      allSysadmins: newAllSysadmins,
      hasDeleteErr: false,
      hasPostErr: false,
    };
  });

  on(deleteSysadminFailed, state => ({
    ...state,
    hasDeleteErr: true,
    hasPostErr: false,
  }));
}, initialState);
