import { push } from "connected-react-router";
import React, { FunctionComponent, useEffect, useState } from "react";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { Button, Header, Icon, Modal } from "semantic-ui-react";

import { useFormatMessage } from "src/admin-portal/texts/utils";
import { KEEP_ALIVE_ACTION } from "src/common/auth/keep-alive-saga";
import { useInterval } from "src/common/hooks/use-interval";
import { DISABLE_SESSION_EXPIRATION } from "src/env";
import { RootState } from "src/reducers/all-reducers";

const conditionInSeconds = 60;

interface StateProps {
  isUpdatingSession: boolean;
  exp: number;
}

interface DispatchProps {
  resetSession: () => void;
  goToLogin: () => void;
}

type Props = StateProps & DispatchProps;

const SessionExpirationModal: FunctionComponent<Props> = ({
  resetSession,
  exp,
  isUpdatingSession,
  goToLogin,
}) => {
  const [counter, setCounter] = useState(conditionInSeconds);
  const [isUpdateSessionCanceled, setIsUpdateSessionCanceled] = useState(false);
  const formatMessage = useFormatMessage();

  useEffect(() => {
    setCounter(Math.floor((+new Date(exp * 1000) - +new Date()) / 1000));
  }, [exp]);

  useInterval(() => {
    setCounter(counter - 1);
  }, 1000);

  if (DISABLE_SESSION_EXPIRATION) {
    return null;
  }

  const minutes = Math.floor(counter / 60);
  const seconds = Math.floor(counter - minutes * 60);
  const shouldModalShow =
    counter < conditionInSeconds || !isUpdateSessionCanceled;

  if (counter > conditionInSeconds || isUpdateSessionCanceled) {
    return null;
  }

  const isExpired = minutes < 0;

  return (
    <Modal size="tiny" open={shouldModalShow}>
      <Modal.Header>
        <Icon name="clock outline" />
        Session about to expire
      </Modal.Header>
      <Modal.Content>
        {isExpired
          ? formatMessage({ id: "sessionExpirationBanner.expired" })
          : formatMessage(
              { id: "sessionExpirationBanner.headerText" },
              { minutes, seconds }
            )}
      </Modal.Content>
      <Modal.Actions style={{ textAlign: "center" }}>
        <Button
          loading={isUpdatingSession}
          color="green"
          content={
            isExpired
              ? formatMessage({ id: "sessionExpirationBanner.expired.btnText" })
              : formatMessage({ id: "sessionExpirationBanner.btnText" })
          }
          onClick={isExpired ? goToLogin : resetSession}
        />
      </Modal.Actions>
    </Modal>
  );
};

const mapStateToProps: MapStateToProps<StateProps, {}, RootState> = ({
  generalApiRequestFailed,
  user,
}): StateProps => ({
  exp: user.decodedToken.exp,
  isUpdatingSession: user.isUpdatingSession,
});

const mapDispatchToProps: MapDispatchToProps<
  DispatchProps,
  null
> = dispatch => ({
  resetSession: () => dispatch({ type: KEEP_ALIVE_ACTION }),
  goToLogin: () => dispatch(push("/login")),
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(SessionExpirationModal);
