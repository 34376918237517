import { get, round } from "lodash";
import moment, { Moment } from "moment";
import { Reducer } from "redux";

import { apiShortDate, flatten, sumNumbers } from "src/common/utils/utils";
import { SharePrice } from "src/employee-portal/exercise/exercise-router";
import {
  removeExpiredAwards,
  vestedAwards,
  hasEmployeePaysSocSec,
} from "src/employee-portal/instrument-page/instruments-page";

export interface PerformanceCriteria {
  name: string;
  performanceFactor: number;
  performanceStatus: string;
  displayFactor: number;
  date: Moment;
  estimatedPerformanceProgress: number;
  currencyCode: string;
  thresholds: PerformanceCriteriaThreshold[];
}

export interface PerformanceCriteriaThreshold {
  fulfillmentFactor: number;
  fulfillmentLevel: string;
  fulfillmentLevelNumber: number;
  name: string;
}

export interface Currency {
  conversionFactor: number;
  currencyCode: string;
}

export interface Gain {
  totalGain: number;
  vestedGain: number;
}

export interface IndividualInstrumentState {
  allAwards: FlatAward[];
  gain: Gain;
  gainIncludingExpired?: Gain;
  instrumentQuantity: number;
  sharesQuantity: number;
  totalQuantityIncludingExpired?: number;
  vestedInstrumentQuantity: number;
  vestedSharesQuantity: number;
  performance: boolean;
  performanceAdjustedSharesQuantity?: number;
  performanceAdjustedQuantity?: number;
  performanceAdjustedGain?: number;
  employeePaysSocSecQuantity?: number;
  totalVariableCost?: number;
}

export interface InstrumentState {
  readonly option: IndividualInstrumentState;
  readonly warrant: IndividualInstrumentState;
  readonly rsu: IndividualInstrumentState;
  readonly psu: IndividualInstrumentState;
  readonly rsa: IndividualInstrumentState;
  readonly convertibleLoan: IndividualInstrumentState;
  readonly share: IndividualInstrumentState;
  readonly fundsState: IndividualInstrumentState;
  readonly cashState: IndividualInstrumentState;
  readonly subscriptionRightState: IndividualInstrumentState;
  readonly totalQuantity: number;
  readonly totalVestedQuantity: number;
  readonly totalGain: number;
  readonly totalVestedGain: number;
  readonly isFetchingWelcomeData: boolean;
  readonly sharePrice?: SharePrice;
  readonly errorFetchingWelcomeData?: boolean;
  readonly hasPerformance?: boolean;
  readonly error?: any;
  readonly currency?: Currency;
}

const createDefaultIndividualInstrumentState = () => ({
  allAwards: [],
  gain: {
    totalGain: 0,
    vestedGain: 0,
    totalGainOriginalCurrency: 0,
    vestedGainOriginalCurrency: 0,
  },
  totalQuantity: 0,
  vestedInstrumentQuantity: 0,
  vestedSharesQuantity: 0,
  performance: false,
  instrumentQuantity: 0,
  sharesQuantity: 0,
  employeePaysSocSecQuantity: 0,
});

const initialState: InstrumentState = {
  option: createDefaultIndividualInstrumentState(),
  warrant: createDefaultIndividualInstrumentState(),
  rsa: createDefaultIndividualInstrumentState(),
  rsu: createDefaultIndividualInstrumentState(),
  psu: createDefaultIndividualInstrumentState(),
  convertibleLoan: createDefaultIndividualInstrumentState(),
  share: createDefaultIndividualInstrumentState(),
  fundsState: createDefaultIndividualInstrumentState(),
  cashState: createDefaultIndividualInstrumentState(),
  subscriptionRightState: createDefaultIndividualInstrumentState(),
  totalQuantity: 0,
  totalVestedQuantity: 0,
  totalGain: 0,
  totalVestedGain: 0,
  isFetchingWelcomeData: false,
  errorFetchingWelcomeData: false,
  sharePrice: null,
};

export type InstrumentType =
  | "option"
  | "rsu"
  | "psu"
  | "rsa"
  | "warrant"
  | "deferred_cash"
  | "convertible_loan"
  | "share"
  | "deferred_fund_shares";
type SettlementType = "equity" | "cash";

interface InstrumentAward {
  programId: string;
  programName: string;
  subProgramName: string;
  tranches: Vesting[];
  instrumentType: InstrumentType;
  settlementType: SettlementType;
  performance: boolean;
  employeePaysSocSec: boolean;
  ticker?: Ticker;
}

interface Ticker {
  name: string;
  latest_share_price: number;
  latest_share_price_date: Moment;
}

interface Vesting {
  sharesQuantity: number;
  strike: number;
  capOnGain?: number;
  id: string;
  exercisedQuantity: number;
  releasedQuantity: number;
  grantDate: Moment;
  vestedDate: Moment;
  expiryDate: Moment;
  lockedTo?: string;
  purchasePrice?: number;
  taxable_input_valuation?: number;
  instrumentQuantity: number;
  performanceFactor?: number;
  performanceStatus: string;
  performance_rules?: APIPerformanceRule[];
  performance_start_date?: string;
  performance_end_date?: string;
  new_quantity_factor?: string;
  strike_conversion_factor?: string;
}

export interface APIAward {
  id: string;
  grantDate: string;
  expiryDate: string;
  vesting: any;
  quantity: number;
  employee_id: string;
  is_purchasable: boolean;
  incentive_sub_program: {
    id: string;
    name: string;
    instrument_type_id: InstrumentType;
    settlement_type_id: SettlementType;
    incentive_program_id: string;
    performance: boolean;
    employee_pays_soc_sec: boolean;
    incentive_program: {
      name: string;
      id: string;
    };
    purchase_config?: {
      id: string;
      price?: string;
      discount?: string;
      window_id: string;
      require_share_depository: boolean;
    };
    share_price_ticker?: {
      ticker_name: string;
      latest_share_price?: {
        date: string;
        price: string;
      };
    };
  };
  tranches: APIVestingEvent[];
  documents?: Api.V1.Document[];
}

interface APIPerformanceRule {
  name: string;
  description: string;
  last_performance_rule_entry: {
    date: string;
    probability_factor: string;
    prognosis: number;
  };
  currency_code: string;
  display_factor: string;
  thresholds: Array<{
    fulfillmentFactor: number;
    fulfillmentLevel: string;
    fulfillmentLevelNumber: number;
    name: string;
  }>;
}

interface APIVestingEvent {
  quantity: number;
  strike: string;
  cap_on_gain: string | null;
  grant_date: string;
  vestedDate: string;
  expiry_date: string;
  locked_to_date?: string;
  exercised_quantity: number;
  released_quantity: number;
  id: string;
  purchase_price: string;
  taxable_input_valuation?: string;
  new_quantity_factor?: string;
  strike_conversion_factor?: string;
  performance_factor?: string;
  performance_status: string;
  performance_rules?: APIPerformanceRule[];
  performance_start_date?: string;
  performance_end_date?: string;
}

const getSharesQuantityForTranche = (ve: APIVestingEvent) =>
  isNaN(parseFloat(ve.new_quantity_factor))
    ? ve.quantity
    : Math.floor(parseFloat(ve.new_quantity_factor) * ve.quantity);
const getInstrumentQuantityForTranche = (ve: APIVestingEvent) => ve.quantity;

const toInstrumentAward = (award: APIAward): InstrumentAward => ({
  programId: award.incentive_sub_program.incentive_program.id,
  programName: award.incentive_sub_program.incentive_program.name,
  subProgramName: award.incentive_sub_program.name,
  tranches: award.tranches.map(ve => ({
    sharesQuantity: getSharesQuantityForTranche(ve),
    capOnGain: isNaN(parseFloat(ve.cap_on_gain))
      ? null
      : parseFloat(ve.cap_on_gain),
    strike: isNaN(parseFloat(ve.strike)) ? 0 : parseFloat(ve.strike),
    id: ve.id,
    exercisedQuantity: ve.exercised_quantity,
    releasedQuantity: ve.released_quantity,
    grantDate: moment(ve.grant_date),
    vestedDate: moment(ve.vestedDate),
    expiryDate: moment(ve.expiry_date),
    lockedTo: ve.locked_to_date,
    purchasePrice: parseFloat(ve.purchase_price),
    taxable_input_valuation: parseFloat(ve.taxable_input_valuation),
    instrumentQuantity: getInstrumentQuantityForTranche(ve),
    new_quantity_factor: ve.new_quantity_factor,
    strike_conversion_factor: ve.strike_conversion_factor,
    performance_rules: ve.performance_rules,
    performance_start_date: ve.performance_start_date,
    performance_end_date: ve.performance_end_date,
    performanceFactor: ve.performance_factor
      ? parseFloat(ve.performance_factor)
      : null,
    performanceStatus: ve.performance_status,
  })),
  instrumentType: award.incentive_sub_program.instrument_type_id,
  settlementType: award.incentive_sub_program.settlement_type_id,
  performance: award.incentive_sub_program.performance,
  employeePaysSocSec: award.incentive_sub_program.employee_pays_soc_sec,
  ticker: award.incentive_sub_program.share_price_ticker &&
    award.incentive_sub_program.share_price_ticker.latest_share_price && {
      name: award.incentive_sub_program.share_price_ticker.ticker_name,
      latest_share_price: parseFloat(
        award.incentive_sub_program.share_price_ticker.latest_share_price.price
      ),
      latest_share_price_date: moment(
        award.incentive_sub_program.share_price_ticker.latest_share_price.date,
        apiShortDate
      ),
    },
});

export interface FlatAward {
  grantDate: Moment;
  expiryDate: Moment;
  vestedDate: Moment;
  performanceStartDate?: Moment;
  performanceEndDate?: Moment;
  quantity: number;
  exercisedQuantity: number;
  releasedQuantity: number;
  strike: number;
  capOnGain?: number;
  purchasePrice?: number;
  taxable_input_valuation?: number;
  subProgramName: string;
  programName: string;
  programId: string;
  trancheId: string;
  instrumentType: InstrumentType;
  settlementType: SettlementType;
  performance: boolean;
  employeePaysSocSec: boolean;
  socSecRate?: number;
  share_price?: number;
  share_price_date?: Moment;
  originalQuantity: number;
  sharesConversionFactor: number;
  strikeConversionFactor?: number;
  performanceCriteria?: PerformanceCriteria;
  lockedTo?: Moment;
}

const toFlatAwards = (
  award: InstrumentAward,
  socSecRate?: number
): FlatAward[] =>
  award.tranches.map(tranche => ({
    grantDate: tranche.grantDate,
    vestedDate: moment(tranche.vestedDate, apiShortDate),
    expiryDate: moment(tranche.expiryDate, apiShortDate),
    lockedTo: tranche.lockedTo ? moment(tranche.lockedTo, apiShortDate) : null,
    quantity:
      award.instrumentType === "option" &&
      tranche.performance_rules &&
      tranche.performance_rules.length > 0
        ? parseFloat(
            tranche.performance_rules[0].last_performance_rule_entry
              .probability_factor
          ) * tranche.sharesQuantity
        : tranche.sharesQuantity,
    exercisedQuantity: tranche.exercisedQuantity,
    releasedQuantity: tranche.releasedQuantity,
    strike: tranche.strike,
    capOnGain: tranche.capOnGain,
    programId: award.programId,
    trancheId: tranche.id,
    subProgramName: award.subProgramName,
    programName: award.programName,
    instrumentType: award.instrumentType,
    performance: award.performance,
    employeePaysSocSec: award.employeePaysSocSec,
    socSecRate: socSecRate,
    settlementType: award.settlementType,
    purchasePrice: tranche.purchasePrice,
    taxable_input_valuation: tranche.taxable_input_valuation,
    share_price: award.ticker && award.ticker.latest_share_price,
    share_price_date: award.ticker && award.ticker.latest_share_price_date,
    originalQuantity: tranche.instrumentQuantity,
    sharesConversionFactor:
      tranche.new_quantity_factor &&
      !isNaN(parseFloat(tranche.new_quantity_factor))
        ? parseFloat(tranche.new_quantity_factor)
        : 1,
    strikeConversionFactor:
      tranche.strike_conversion_factor &&
      !isNaN(parseFloat(tranche.strike_conversion_factor))
        ? parseFloat(tranche.strike_conversion_factor)
        : null,
    performanceStartDate: tranche.performance_start_date
      ? moment(tranche.performance_start_date, apiShortDate)
      : null,
    performanceEndDate: tranche.performance_end_date
      ? moment(tranche.performance_end_date, apiShortDate)
      : null,
    performanceCriteria:
      tranche.performance_rules && tranche.performance_rules.length > 0
        ? {
            name: tranche.performance_rules[0].name,
            performanceFactor: parseFloat(
              tranche.performance_rules[0].last_performance_rule_entry
                .probability_factor
            ),
            date: moment(
              tranche.performance_rules[0].last_performance_rule_entry.date,
              apiShortDate
            ),
            thresholds: tranche.performance_rules[0].thresholds,
            // estimatedPerformanceProgress: tranche.performance_rules[0].last_performance_rule_entry.estimated_performance_progress,
            estimatedPerformanceProgress:
              tranche.performance_rules[0].last_performance_rule_entry
                .prognosis,
            displayFactor: parseFloat(
              tranche.performance_rules[0].display_factor
            ),
            currencyCode: tranche.performance_rules[0].currency_code,
            performanceStatus: tranche.performanceStatus,
          }
        : undefined,
  }));

const isOfInstrumentType = (
  instrumentType: string,
  instrumentAward: InstrumentAward
) => instrumentAward.instrumentType === instrumentType;

const keepShares = (instrumentAward: InstrumentAward) =>
  isOfInstrumentType("share", instrumentAward);
const keepConvertibleLoanUnits = (instrumentAward: InstrumentAward) =>
  isOfInstrumentType("convertible_loan", instrumentAward);
const keepFundShares = (instrumentAward: InstrumentAward) =>
  isOfInstrumentType("deferred_fund_share", instrumentAward);
const keepDeferredCash = (instrumentAward: InstrumentAward) =>
  isOfInstrumentType("deferred_cash", instrumentAward);
const keepOptions = (instrumentAward: InstrumentAward) =>
  isOfInstrumentType("option", instrumentAward);
const keepSubsctiptionRights = (instrumentAward: InstrumentAward) =>
  isOfInstrumentType("subscription_rights", instrumentAward);
const keepRsus = (instrumentAward: InstrumentAward) =>
  isOfInstrumentType("rsu", instrumentAward);
const keepPsus = (instrumentAward: InstrumentAward) =>
  isOfInstrumentType("psu", instrumentAward);
const keepRsas = (instrumentAward: InstrumentAward) =>
  isOfInstrumentType("rsa", instrumentAward);
const keepWarrants = (instrumentAward: InstrumentAward) =>
  isOfInstrumentType("warrant", instrumentAward);

function getSharesQuantity(awards: FlatAward[]) {
  return awards.filter(removeExpiredAwards).reduce(sharesQuantity, 0);
}

function getVestedInstrumentQuantity(awards: FlatAward[]) {
  return awards
    .filter(removeExpiredAwards)
    .filter(vestedAwards)
    .reduce(instrumentQuantity, 0);
}

function getVestedSharesQuantity(awards: FlatAward[]) {
  return awards
    .filter(removeExpiredAwards)
    .filter(vestedAwards)
    .reduce(sharesQuantity, 0);
}

const getInstrumentQuantity = (awards: FlatAward[]) =>
  awards.filter(removeExpiredAwards).reduce(instrumentQuantity, 0);

const getEmployeePaysSocSecInstrumentQuantity = (awards: FlatAward[]) =>
  awards
    .filter(removeExpiredAwards)
    .filter(a => a.instrumentType === "option")
    .filter(hasEmployeePaysSocSec)
    .reduce((acc, a) => {
      return acc + a.quantity;
    }, 0);

const getTotalVariableCost = (awards: FlatAward[], sharePrice: number) => {
  return awards.reduce((acc, a) => {
    return a.employeePaysSocSec
      ? acc +
          ((sharePrice - a.strike) * a.socSecRate * a.quantity) /
            (1 + a.socSecRate)
      : acc;
  }, 0);
};

const instrumentReducer: Reducer<InstrumentState> = (
  state = initialState,
  action
): InstrumentState => {
  if (action.type === "FETCH_EMPLOYEE_PORTAL_WELCOME_SUCCEEDED") {
    const { welcomeData } = action;
    const currencyConversionFactor = parseFloat(
      welcomeData.profile.currency_exchange_ratio
    );

    const socSecRate = parseFloat(welcomeData.profile.soc_sec_rate);

    const conversionIndicator = currencyConversionFactor !== 1 ? "≈ " : "";
    const currency = {
      conversionFactor: currencyConversionFactor,
      currencyCode: `${conversionIndicator}${welcomeData.profile.currency_code}`,
    };
    const awards = welcomeData.awards.map(toInstrumentAward);
    const funds: FlatAward[] = flatten(
      awards.filter(keepFundShares).map(a => toFlatAwards(a, socSecRate))
    );
    const convertibleLoanUnits: FlatAward[] = flatten(
      awards
        .filter(keepConvertibleLoanUnits)
        .map(a => toFlatAwards(a, socSecRate))
    );
    const shares: FlatAward[] = flatten(
      awards.filter(keepShares).map(a => toFlatAwards(a, socSecRate))
    );
    const deferredCash = flatten(
      awards.filter(keepDeferredCash).map(a => toFlatAwards(a, socSecRate))
    );

    const options = flatten(
      awards.filter(keepOptions).map(a => toFlatAwards(a, socSecRate))
    );
    const subscriptionRights = flatten(
      awards
        .filter(keepSubsctiptionRights)
        .map(a => toFlatAwards(a, socSecRate))
    );
    const rsus = flatten(awards.filter(keepRsus).map(toFlatAwards));
    const psus = flatten(awards.filter(keepPsus).map(toFlatAwards));
    const rsas = flatten(awards.filter(keepRsas).map(toFlatAwards));
    const warrants = flatten(awards.filter(keepWarrants).map(toFlatAwards));

    const sharePrice: SharePrice = {
      sharePrice: parseFloat(get(welcomeData, "stockPrice.price")),
      sharePriceDate: moment(get(welcomeData, "stockPrice.date")),
      manual: get(welcomeData, "stockPrice.manual"),
    };

    const convertibleLoanGain = calculateGains(
      convertibleLoanUnits,
      sharePrice.sharePrice
    );
    const sharesGain = calculateGains(shares, sharePrice.sharePrice);

    const deferredCashGain = {
      totalGain: deferredCash
        .map((a: FlatAward) => a.quantity)
        .reduce(sumNumbers, 0),
      totalGainOriginalCurrency: deferredCash
        .map((a: FlatAward) => a.quantity)
        .reduce(sumNumbers, 0),
      vestedGain: 0,
      vestedGainOriginalCurrency: 0,
    };
    const fundsGain = calculateGains(funds, sharePrice.sharePrice);
    const optionsGain = calculateGains(options, sharePrice.sharePrice);
    const subscriptionRightGain = calculateGains(
      subscriptionRights,
      sharePrice.sharePrice
    );
    const rsuGain = calculateGains(rsus, sharePrice.sharePrice);
    const psuGain = calculateGains(psus, sharePrice.sharePrice);
    const rsaGain = calculateGains(rsas, sharePrice.sharePrice);
    const warrantsGain = calculateGains(warrants, sharePrice.sharePrice);

    const convertibleLoan: IndividualInstrumentState = getInstrumentState(
      convertibleLoanUnits,
      convertibleLoanGain
    );
    const share: IndividualInstrumentState = getInstrumentState(
      shares,
      sharesGain
    );
    const fundsState: IndividualInstrumentState = getInstrumentState(
      funds,
      fundsGain
    );
    const deferredCashState: IndividualInstrumentState = getInstrumentState(
      deferredCash,
      deferredCashGain
    );
    const subscriptionRightState: IndividualInstrumentState = getInstrumentState(
      subscriptionRights,
      subscriptionRightGain
    );

    const optionState: IndividualInstrumentState =
      options.length > 0
        ? {
            allAwards: options,
            gain: optionsGain,
            instrumentQuantity: getInstrumentQuantity(options),
            sharesQuantity: getSharesQuantity(options),
            vestedInstrumentQuantity: getVestedInstrumentQuantity(options),
            vestedSharesQuantity: getVestedSharesQuantity(options),
            performance: options.some(hasPerformanceCriteria),
            employeePaysSocSecQuantity: getEmployeePaysSocSecInstrumentQuantity(
              options
            ),
            totalVariableCost: getTotalVariableCost(
              options,
              sharePrice.sharePrice
            ),
          }
        : createDefaultIndividualInstrumentState();

    const warrantState: IndividualInstrumentState =
      warrants.length > 0
        ? {
            allAwards: warrants,
            gain: warrantsGain,
            instrumentQuantity: getInstrumentQuantity(warrants),
            sharesQuantity: getSharesQuantity(warrants),
            vestedInstrumentQuantity: getVestedInstrumentQuantity(warrants),
            vestedSharesQuantity: getVestedSharesQuantity(warrants),
            performance: warrants.some(hasPerformanceCriteria),
          }
        : createDefaultIndividualInstrumentState();

    const rsuState: IndividualInstrumentState =
      rsus.length > 0
        ? {
            allAwards: rsus,
            gain: rsuGain,
            instrumentQuantity: getInstrumentQuantity(rsus),
            sharesQuantity: getSharesQuantity(rsus),
            vestedInstrumentQuantity: getVestedInstrumentQuantity(rsus),
            vestedSharesQuantity: getVestedSharesQuantity(rsus),
            performance: rsus.some(hasPerformanceCriteria),
          }
        : createDefaultIndividualInstrumentState();

    const psuTotalSharesQuantity = getSharesQuantity(psus);

    const performanceAdjustedQuantity = psus
      .filter(removeExpiredAwards)
      .map(psu =>
        Math.floor(psu.performanceCriteria.performanceFactor * psu.quantity)
      )
      .reduce(sumNumbers, 0);
    const performanceFactor =
      performanceAdjustedQuantity / psuTotalSharesQuantity;
    const performanceAdjustedGain = performanceFactor * psuGain.totalGain;
    const psuState: IndividualInstrumentState =
      psus.length > 0
        ? {
            allAwards: psus,
            gain: psuGain,
            instrumentQuantity: getInstrumentQuantity(psus),
            sharesQuantity: psuTotalSharesQuantity,
            vestedInstrumentQuantity: getVestedInstrumentQuantity(psus),
            vestedSharesQuantity: getVestedSharesQuantity(psus),
            performance: psus.some(hasPerformanceCriteria),
            performanceAdjustedSharesQuantity: Math.floor(
              psuTotalSharesQuantity * performanceFactor
            ),
            performanceAdjustedQuantity,
            performanceAdjustedGain,
          }
        : createDefaultIndividualInstrumentState();

    const rsaState: IndividualInstrumentState =
      rsas.length > 0
        ? {
            allAwards: rsas,
            gain: rsaGain,
            instrumentQuantity: getInstrumentQuantity(rsas),
            sharesQuantity: getSharesQuantity(rsas),
            vestedInstrumentQuantity: getVestedInstrumentQuantity(rsas),
            vestedSharesQuantity: getVestedSharesQuantity(rsas),
            performance: rsas.some(hasPerformanceCriteria),
          }
        : createDefaultIndividualInstrumentState();

    const allInstruments = [
      optionState,
      warrantState,
      rsaState,
      rsuState,
      fundsState,
      deferredCashState,
      subscriptionRightState,
      convertibleLoan,
      share,
    ];

    const hasPerformance =
      allInstruments.some(x => x.performance) ||
      psuState.instrumentQuantity > 0;

    return {
      ...state,
      currency,
      isFetchingWelcomeData: false,
      sharePrice,
      option: optionState,
      warrant: warrantState,
      rsa: rsaState,
      rsu: rsuState,
      psu: psuState,
      convertibleLoan,
      share,
      fundsState,
      subscriptionRightState,
      cashState: deferredCashState,
      totalGain: calculateTotalGain(
        allInstruments,
        psuState.performanceAdjustedGain
      ),
      totalVestedGain: allInstruments.reduce(
        (accu, instrument) => instrument.gain.vestedGain + accu,
        0
      ),
      totalQuantity: allInstruments.reduce(
        (accu, instrument) => instrument.sharesQuantity + accu,
        psuState.performanceAdjustedQuantity || 0
      ),
      totalVestedQuantity: allInstruments.reduce(
        (accu, instrument) => instrument.vestedInstrumentQuantity + accu,
        0
      ),
      errorFetchingWelcomeData: false,
      hasPerformance,
    };
  } else if (action.type === "FETCH_EMPLOYEE_PORTAL_WELCOME_FAILED") {
    return {
      ...state,
      ...{
        isFetchingWelcomeData: false,
        error: action.error,
        errorFetchingWelcomeData: true,
      },
    };
  } else if (action.type === "FETCH_EMPLOYEE_PORTAL_WELCOME") {
    return { ...state, ...{ isFetchingWelcomeData: true } };
  }
  return state;
};

export type AwardGainFunction = (
  sharePrice: number,
  award: FlatAward
) => AwardGain;

export interface AwardGain {
  gain: number;
  gainPerInstrument: number;
  costOfExercise: number;
  capped: boolean;
}

export const awardGain: AwardGainFunction = (
  sharePrice: number,
  award: FlatAward
): AwardGain => {
  if (award.quantity === 0) {
    return {
      gain: 0,
      costOfExercise: 0,
      gainPerInstrument: 0,
      capped: false,
    };
  }
  const sharePriceToUse = award.share_price || sharePrice;
  const totalGain = getGain(award, sharePriceToUse);
  const totalCostOfExercise = getCostOfExercise(award);
  const instrumentQuantity = award.originalQuantity;
  const maxGainPerInstrument = Math.max(totalGain / instrumentQuantity, 0);
  const gainPerInstrument = award.capOnGain
    ? Math.min(maxGainPerInstrument, award.capOnGain)
    : maxGainPerInstrument;

  const capped = award.capOnGain && maxGainPerInstrument > award.capOnGain;

  return {
    gain: round(instrumentQuantity * gainPerInstrument),
    costOfExercise: totalCostOfExercise,
    gainPerInstrument,
    capped,
  };
};

export const calculateGains = (
  awards: FlatAward[],
  sharePrice: number
): Gain => {
  const nonExpired = awards.filter(removeExpiredAwards);

  const totalGain = nonExpired
    .map(award => awardGain(sharePrice, award).gain)
    .reduce(sumNumbers, 0);

  const vestedGain = nonExpired
    .filter(vestedAwards)
    .map(award => awardGain(sharePrice, award).gain)
    .reduce(sumNumbers, 0);

  return {
    totalGain,
    vestedGain,
  };
};

export const calculateTotalGain = (instruments: any, performanceAdjustedGain) =>
  instruments.reduce(
    (accu, instrument) => instrument.gain.totalGain + accu,
    performanceAdjustedGain || 0
  );

export const instrumentQuantity = (accu: number, current: FlatAward) =>
  accu + current.originalQuantity;
export const sharesQuantity = (accu: number, current: FlatAward) =>
  accu + current.quantity;
export const hasPerformanceCriteria = (award: FlatAward) => award.performance;
export const hasPurchasePrice = (award: FlatAward) =>
  typeof award.purchasePrice === "number" && award.purchasePrice !== 0;
export const hasOriginalQuantity = (award: FlatAward) =>
  award.originalQuantity !== null;
export const hasSharesConversion = (award: FlatAward) =>
  award.originalQuantity !== award.quantity;
export const hasCapOnGain = (award: FlatAward) => award.capOnGain !== null;
export const hasVariableCost = (award: FlatAward) => award.employeePaysSocSec;
export const hasThreshold = (award: FlatAward) =>
  !!award.performanceCriteria.thresholds;
export const hasPrognosis = (award: FlatAward) =>
  !!award.performanceCriteria.estimatedPerformanceProgress;
export const hasStrike = (award: FlatAward) => !!award.strike;

const getCostOfExercise = (award: FlatAward): number => {
  const strike = award.strike || 0;
  return award.strikeConversionFactor
    ? award.quantity * strike * award.strikeConversionFactor
    : award.originalQuantity * strike;
};

const getGain = (award: FlatAward, sharePrice: number): number => {
  const cost = getCostOfExercise(award);
  const share = award.share_price || sharePrice;
  const variableCost =
    ((share - award.strike) * award.quantity * award.socSecRate) /
    (1 + award.socSecRate);
  const value = award.employeePaysSocSec
    ? (award.share_price || sharePrice) * award.quantity - variableCost
    : (award.share_price || sharePrice) * award.quantity;

  return Math.max(0, value - cost);
};

const getInstrumentState = (instruments, gain) =>
  instruments.length > 0
    ? {
        allAwards: instruments,
        gain: gain,
        instrumentQuantity: getInstrumentQuantity(instruments),
        sharesQuantity: getSharesQuantity(instruments),
        vestedInstrumentQuantity: getVestedInstrumentQuantity(instruments),
        vestedSharesQuantity: getVestedSharesQuantity(instruments),
        performance: instruments.some(hasPerformanceCriteria),
      }
    : createDefaultIndividualInstrumentState();

export default instrumentReducer;
