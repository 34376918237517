import { createAction } from "redux-act";

import { tenantId } from "src/admin-portal/employees/employee-selectors";
import { Tenant, UpdateTenant } from "src/admin-portal/tenant/tenant-reducer";

export const USER_NOT_AUTHORIZED = "USER_NOT_AUTHORIZED";

export const fetchReportConfigs = createAction("FETCH_REPORT_CONFIGS");

export const fetchReportConfigsSucceeded = createAction(
  "FETCH_REPORT_CONFIGS_SUCCEEDED",
  (payload: { configs: Api.V1.ReportConfig[] }) => payload
);

export const postReportConfig = createAction(
  "POST_REPORT_CONFIG",
  (payload: Api.V1.ReportConfig) => payload
);

export const postReportConfigSucceeded = createAction(
  "POST_REPORT_CONFIG_SUCCEEDED",
  (payload: Api.V1.ReportConfig) => payload
);

export const patchReportConfig = createAction(
  "PATCH_REPORT_CONFIG",
  (payload: Api.V1.ReportConfig) => payload
);

export const patchReportConfigSucceeded = createAction(
  "PATCH_REPORT_CONFIG_SUCCEEDED",
  (payload: Api.V1.ReportConfig) => payload
);

export const deleteReportConfig = createAction(
  "DELETE_REPORT_CONFIG",
  (payload: string) => payload
);

export const deleteReportConfigSucceeded = createAction(
  "DELETE_REPORT_CONFIG_SUCCEEDED",
  (payload: string) => payload
);
