import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import "src/employee-portal/menu/style.less";
import DesktopMenu from "src/employee-portal/menu/DesktopMenu";
import MobileMenu from "src/employee-portal/menu/MobileMenu";
import SubMenu from "src/employee-portal/menu/SubMenu";
import useMenuConfig, {
  Args as UseMenuConfig,
} from "src/employee-portal/menu/useMenuConfig";
import { RootState } from "src/reducers/all-reducers";
import { isAdminUserWithoutInstruments } from "src/selectors";

const EmployeePortalMenu: FunctionComponent = () => {
  const props: UseMenuConfig = useSelector((state: RootState) => ({
    showAdminMenu: state.user.isSysadmin || state.user.isAdmin,
    adminUserWithoutInstruments: isAdminUserWithoutInstruments(state),
    sharePrice: state.instrument.sharePrice,
    logoUrl: state.user.tenant && state.user.tenant.logo_url,
    features: state.features,
    dateFormat: state.dateFormat.normalDateFormat,
    userName: state.user.name,
    title: state.user.tenant.employee_portal_title,
  }));

  const pathName = useSelector(({ router }) => router.location.pathname);
  const sharePriceSimulation = useSelector(
    ({ features }) => features.share_price_simulation
  );

  const {
    userName,
    adminUserWithoutInstruments,
    logoUrl,
    sharePrice,
    dateFormat,
    title,
  } = props;

  const menuItems = useMenuConfig(props);

  return (
    <nav className="site-header">
      <DesktopMenu
        menuItems={menuItems}
        userName={userName}
        logoUrl={logoUrl}
        isAdminUserWithoutInstruments={adminUserWithoutInstruments}
        title={title}
      >
        <SubMenu
          sharePrice={sharePrice}
          normalDateFormat={dateFormat}
          pathName={pathName}
          sharePriceSimulation={sharePriceSimulation}
        />
      </DesktopMenu>

      <MobileMenu
        menuItems={menuItems}
        logoUrl={logoUrl}
        isAdminUserWithoutInstruments={adminUserWithoutInstruments}
        title={title}
      >
        <SubMenu
          sharePrice={sharePrice}
          normalDateFormat={dateFormat}
          pathName={pathName}
          sharePriceSimulation={sharePriceSimulation}
        />
      </MobileMenu>
    </nav>
  );
};

export default EmployeePortalMenu;
