import React, { FunctionComponent } from "react";

import { useSelector } from "react-redux";

import FormatCurrency from "src/common/components/format-currency";
import { RootState } from "src/reducers/all-reducers";

interface Props {
  number: number;
  className?: string;
}

const FormatSharePrice: FunctionComponent<Props> = ({ number, className }) => {
  const numDecimals =
    useSelector<RootState, number>(
      state => state.user.tenant?.num_share_price_decimals
    ) || 2;
  return (
    <FormatCurrency
      number={number}
      decimals={numDecimals}
      className={className}
    />
  );
};

export default FormatSharePrice;
