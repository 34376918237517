import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  postEmail,
  postEmailSucceeded,
} from "src/admin-portal/email/email-actions";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { USER_NOT_AUTHORIZED } from "src/reducers/user";
import * as selectors from "src/selectors";

const postEmailUrl = tenantId => `/email?tenantId=${tenantId}`;

function* postEmailRequested(action: ReturnType<typeof postEmail>) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.isSysadmin && selectors.tenantId);
    const method = "POST";

    yield call(() =>
      callApi(postEmailUrl(tenantId), token, method, action.payload)
    );
    yield put(postEmailSucceeded());
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: USER_NOT_AUTHORIZED });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPostEmail() {
  yield takeEvery(postEmail.getType(), postEmailRequested);
}
