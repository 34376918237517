import { Formik, Form as FormikForm } from "formik";
import { Button } from "formik-semantic-ui";
import React, { FunctionComponent, useState } from "react";

import Content from "src/admin-portal/texts/content";
import ExerciseFields from "src/employee-portal/order/table/UpdateModal/Form/ExerciseFields";
import PurchaseFields from "src/employee-portal/order/table/UpdateModal/Form/PurchaseFields";
import useFieldsVisibilityCheck from "src/employee-portal/order/table/UpdateModal/Form/UseFieldsVisibilityCheck";
import useFormSubmit from "src/employee-portal/order/table/UpdateModal/Form/useFormSubmit";
import useFormValues from "src/employee-portal/order/table/UpdateModal/Form/useFormValues";
import useValidationSchema from "src/employee-portal/order/table/UpdateModal/Form/useValidationSchema";
import { HasNorwegianAccountAnswer } from "src/employee-portal/purchase/steps/share-depository/norwegian-share-depo-radio";

interface Props {
  order: Api.V1.Order;
}

const Form: FunctionComponent<Props> = ({ order }) => {
  const initialQuestionAnswer =
    order && order.purchaseOrder && order.purchaseOrder.shareDepositoryNorwegian
      ? HasNorwegianAccountAnswer.YES
      : HasNorwegianAccountAnswer.NO;

  const [hasNorwegianAccount, setHasNorwegianAccount] = useState(
    initialQuestionAnswer as HasNorwegianAccountAnswer
  );

  const initialFormValues = useFormValues(order);
  const validationSchema = useValidationSchema();
  const formSubmit = useFormSubmit({ order, hasNorwegianAccount });

  const visibility = useFieldsVisibilityCheck(order);

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema}
      onSubmit={formSubmit}
    >
      <FormikForm className={"form"}>
        <ExerciseFields
          visibility={visibility}
          exerciseOrder={order.exerciseOrder}
        />
        <PurchaseFields
          visibility={visibility}
          purchaseOrder={order.purchaseOrder}
          hasNorwegianAccount={hasNorwegianAccount}
          setHasNorwegianAccount={setHasNorwegianAccount}
        />

        <div className="text-center">
          <Button.Submit color="green" primary={false}>
            <Content id="global.saveBtnTxt" />
          </Button.Submit>
        </div>
      </FormikForm>
    </Formik>
  );
};

export default Form;
