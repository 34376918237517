import { Moment } from "moment";
import React, { StatelessComponent } from "react";
import { Table } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { CurrencyFormatterProps } from "src/common/components/currency/currency-formatter";
import HelpPopup from "src/common/components/help-popup";
import IfContentExists from "src/common/texts/if-content-exists";
import { TextTerm } from "src/common/utils/text-mappings";
import { formatNumber } from "src/common/utils/utils";
import {
  ExerciseOrderData,
  OrderExerciseTypeComponent,
} from "src/employee-portal/exercise/exercise-router";
import { DateFormatState } from "src/reducers/date-format-reducer";

type Props = ExerciseOrderData & {
  instrumentTerm: TextTerm;
  display_share_depository: boolean;
  display_share_depository_bank: boolean;
  display_share_depository_clearing_code: boolean;
  display_share_depository_contact: boolean;
  display_share_depository_description: boolean;
  display_bank_account_number: boolean;
  display_tax_percentage: boolean;
  dateFormat: DateFormatState;
  totalQuantity: number;
  averageCost: number;
  totalCostOfExercise: number;
  estimatedSalesProceeds: number;
  broker_fee?: number;
  formatter: CurrencyFormatterProps;
  sharePrice: number;
  totalSharesEqivalent: number;
  estNumberOfShares?: number;
  estTotalVariableCost?: number;
  sharePriceDate: Moment;
  taxPercentage: number;
  estimatedNetCash: number;
  strikeConversionFactor?: number;
  showTotalCostOfExercise: boolean;
};

const OrderSummaryTable: StatelessComponent<Props> = ({
  instrumentTerm,
  totalQuantity,
  averageCost,
  formatter,
  totalCostOfExercise,
  shareDepoDescription,
  sharePriceDate,
  shareDepoContact,
  display_share_depository,
  display_share_depository_bank,
  display_share_depository_clearing_code,
  display_share_depository_contact,
  display_tax_percentage,
  sharePrice,
  bicNumber,
  display_share_depository_description,
  display_bank_account_number,
  dateFormat,
  broker_fee,
  estimatedSalesProceeds,
  exerciseType,
  shareDepositoryAccountNumber,
  shareDepoBank,
  shareDepoClearingCode,
  bankAccountNumber,
  estNumberOfShares,
  totalSharesEqivalent,
  taxPercentage,
  estimatedNetCash,
  estTotalVariableCost,
  strikeConversionFactor,
  showTotalCostOfExercise,
}) => (
  <div className="order-summary-table">
    <Table className="order-summary-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="2">
            <Content id="exercise.confirm.table.header" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <Content
              id={"exercise.confirm.table.quantityexercised.label"}
              values={{ instrumentTerm: instrumentTerm.plural }}
            />
          </Table.Cell>
          <Table.Cell textAlign={"right"}>
            {formatNumber(totalQuantity)}
          </Table.Cell>
        </Table.Row>
        {strikeConversionFactor === null && (
          <Table.Row>
            <Table.Cell>
              <Content
                id={"exercise.confirm.table.avgprice.label"}
                values={{ instrumentTerm: instrumentTerm.singular }}
              />
            </Table.Cell>
            <Table.Cell textAlign={"right"}>
              {formatter.formatSharePrice(averageCost)}
            </Table.Cell>
          </Table.Row>
        )}
        {showTotalCostOfExercise && (
          <Table.Row>
            <Table.Cell>
              <Content id={"exercise.confirm.table.totalcost.label"} />
            </Table.Cell>
            <Table.Cell textAlign={"right"}>
              {formatter.formatCurrency(totalCostOfExercise, 2)}
            </Table.Cell>
          </Table.Row>
        )}
        <Table.Row>
          <Table.Cell>
            <Content id={"exercise.confirm.table.exercisetype.label"} />
          </Table.Cell>
          <Table.Cell textAlign={"right"}>
            <OrderExerciseTypeComponent type={exerciseType} />
          </Table.Cell>
        </Table.Row>
        {display_share_depository && (
          <Table.Row>
            <Table.Cell>
              <Content id="exercise.confirm.table.sharedepositoryaccount.label" />
            </Table.Cell>
            <Table.Cell textAlign={"right"}>
              {shareDepositoryAccountNumber}
            </Table.Cell>
          </Table.Row>
        )}
        {display_share_depository_bank && (
          <Table.Row>
            <Table.Cell>
              <Content id="exercise.confirm.table.sharedepositorybank.label" />
            </Table.Cell>
            <Table.Cell textAlign={"right"}>{shareDepoBank}</Table.Cell>
          </Table.Row>
        )}
        {display_share_depository_clearing_code && (
          <Table.Row>
            <Table.Cell>
              <Content id="exercise.confirm.table.sharedepositoryclearingcode.label" />
            </Table.Cell>
            <Table.Cell textAlign={"right"}>{shareDepoClearingCode}</Table.Cell>
          </Table.Row>
        )}
        {display_share_depository_contact && (
          <Table.Row>
            <Table.Cell>
              <Content id="exercise.confirm.table.sharedepositorycontact.label" />
            </Table.Cell>
            <Table.Cell textAlign={"right"}>{shareDepoContact}</Table.Cell>
          </Table.Row>
        )}
        {display_share_depository_description && (
          <Table.Row>
            <Table.Cell>
              <Content id="exercise.confirm.table.sharedepositorydescription.label" />
            </Table.Cell>
            <Table.Cell textAlign={"right"}>{shareDepoDescription}</Table.Cell>
          </Table.Row>
        )}
        {display_bank_account_number && (
          <Table.Row>
            <Table.Cell>
              <Content id="exercise.confirm.table.bankaccount.label" />
            </Table.Cell>
            <Table.Cell textAlign={"right"}>{bankAccountNumber}</Table.Cell>
          </Table.Row>
        )}
        {display_bank_account_number && bicNumber && (
          <Table.Row>
            <Table.Cell>
              <Content id="exercise.confirm.table.bic.label" />
            </Table.Cell>
            <Table.Cell textAlign={"right"}>{bicNumber}</Table.Cell>
          </Table.Row>
        )}
        <Table.Row>
          <Table.Cell>
            <Content
              id="exercise.confirm.table.shareEquivalent.label"
              values={
                {
                  // conversionFactor: simpleRound(shareConversionFactor, 4)
                }
              }
            />
          </Table.Cell>
          <Table.Cell textAlign={"right"}>
            {formatNumber(Math.floor(totalSharesEqivalent))}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <Content id="exercise.confirm.table.sharePrice.label" /> (
            {sharePriceDate.format(dateFormat.normalDateFormat)})
          </Table.Cell>
          <Table.Cell textAlign={"right"}>
            {formatter.formatSharePrice(sharePrice)}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <Content id="exercise.confirm.table.shareValue.label" values={{}} />
          </Table.Cell>
          <Table.Cell textAlign={"right"}>
            {formatter.formatCurrency(
              Math.floor(totalSharesEqivalent) * sharePrice,
              2
            )}
          </Table.Cell>
        </Table.Row>
        {broker_fee && (
          <Table.Row>
            <Table.Cell>
              <Content id="exercise.confirm.table.brokerfee.label" />
            </Table.Cell>
            <Table.Cell textAlign={"right"}>
              ≈ {formatter.formatCurrency(broker_fee)}
            </Table.Cell>
          </Table.Row>
        )}
        {estTotalVariableCost ? (
          <Table.Row>
            <Table.Cell>
              <Content id="exercise.confirm.table.estimatedTotalVariableCost.label" />
              <IfContentExists
                id={`exercise.confirm.table.estimatedTotalVariableCost.label.popup`}
              >
                <HelpPopup
                  textKey={`exercise.confirm.table.estimatedTotalVariableCost.label.popup`}
                />
              </IfContentExists>
            </Table.Cell>
            <Table.Cell textAlign={"right"}>
              ≈ {formatter.formatCurrency(estTotalVariableCost)}
            </Table.Cell>
          </Table.Row>
        ) : null}
        <Table.Row>
          <Table.Cell>
            <Content id="exercise.confirm.table.estimatedNetGain.label" />
          </Table.Cell>
          <Table.Cell textAlign={"right"}>
            <strong>
              ≈ {formatter.formatCurrency(estimatedSalesProceeds)}
            </strong>
          </Table.Cell>
        </Table.Row>
        {display_tax_percentage && taxPercentage > 0 && (
          <Table.Row>
            <Table.Cell>
              <Content id="exercise.confirm.table.taxpercentage.label" />
            </Table.Cell>
            <Table.Cell textAlign={"right"}>{taxPercentage} %</Table.Cell>
          </Table.Row>
        )}
        {estNumberOfShares ? (
          <Table.Row>
            <Table.Cell>
              <Content id="exercise.confirm.table.estimatedNetShares.label" />
              <IfContentExists
                id={`exercise.confirm.table.estimatedNetShares.label.popup`}
              >
                <HelpPopup
                  textKey={`exercise.confirm.table.estimatedNetShares.label.popup`}
                />
              </IfContentExists>
            </Table.Cell>
            <Table.Cell textAlign={"right"}>
              <strong>≈ {formatNumber(estNumberOfShares)}</strong>
            </Table.Cell>
          </Table.Row>
        ) : null}
        {estimatedNetCash ? (
          <Table.Row>
            <Table.Cell>
              <Content id="exercise.confirm.table.estimatedNetCash.label" />
            </Table.Cell>
            <Table.Cell textAlign={"right"}>
              <strong>≈ {formatter.formatCurrency(estimatedNetCash)}</strong>
            </Table.Cell>
          </Table.Row>
        ) : null}
      </Table.Body>
    </Table>
    <div className="required text-right">
      <Content id="exercise.confirm.table.note" />
    </div>
  </div>
);

export default OrderSummaryTable;
