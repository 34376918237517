import React, { FunctionComponent } from "react";
import { Button, Message } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import HelpPopup from "src/common/components/help-popup";
import { TextTerm } from "src/common/utils/text-mappings";
import DocumentMetadataPreview from "src/employee-portal/documents/accept-documents/document-metadata-preview";
import { DocumentMetadata } from "src/employee-portal/purchase/duck/purchase-reducer";

interface Props {
  goBack: () => void;
  goForward: () => void;
  downloadClicked: () => void;
  enableProceedButton: boolean;
  instrumentTerm: TextTerm;
  document: DocumentMetadata;
}

const AcceptDocument: FunctionComponent<Props> = ({
  goBack,
  goForward,
  downloadClicked,
  enableProceedButton,
  document,
  instrumentTerm,
}) => (
  <div>
    <div className="block-m">
      <div className="text-center block-m">
        <h2 className="block-m">
          <Content id="purchase.acceptdocument.header" />
          <HelpPopup textKey={"purchase.acceptdocument.header.popup"} />
        </h2>
      </div>
      <div className="purchase-document-container-width margin-center block-m">
        <div className="block-s">
          <Message info={true}>
            <Content id={"purchase.acceptdocument.info"} />
          </Message>
        </div>
        <div className="doc-meta-preview-box flex-row center-center">
          <DocumentMetadataPreview
            id={document.id}
            downloadLink={document.downloadLink}
            fileName={document.fileName}
            downloadClicked={downloadClicked}
          />
        </div>
      </div>
      <div className="block-s" />
      <div className="text-center">
        <Content id="purchase.acceptdocument.footer" />
      </div>
    </div>
    <div className="page-action-container text-center">
      <Button
        content={<Content id="global.backBtnTxt" />}
        size="big"
        onClick={goBack}
      />
      <Button
        positive={enableProceedButton}
        disabled={!enableProceedButton}
        content={
          <Content
            id={
              enableProceedButton
                ? "global.acceptAndProceedBtnTxt"
                : "global.acceptAndProceedBtnTxtDisabled"
            }
          />
        }
        icon="right arrow"
        labelPosition="right"
        size="big"
        onClick={goForward}
      />
    </div>
  </div>
);

export default AcceptDocument;
