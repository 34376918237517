import React, { FunctionComponent } from "react";
import { Header, Modal, Button } from "semantic-ui-react";

import { EmailState } from "src/admin-portal/email/email-reducer";
import EmailEmployeesForm from "src/admin-portal/email/Form";

interface Props {
  recipients: string[];
  postEmail: (payload: Api.V1.Email) => void;
  email: EmailState;
  closeEmailNotification: () => void;
  onOpen?: () => any;
}

const EmailEmployeesModal: FunctionComponent<Props> = ({
  recipients,
  postEmail,
  email,
  closeEmailNotification,
  onOpen = () => {},
}) => {
  return (
    <Modal
      closeIcon={true}
      size="large"
      onOpen={onOpen}
      trigger={
        <Button basic={true} icon="mail outline" content="Email employees" />
      }
    >
      <Header content="Email employees" />
      <Modal.Content style={{ padding: "20px 100px" }}>
        <EmailEmployeesForm
          recipients={recipients}
          postEmail={postEmail}
          email={email}
          closeEmailNotification={closeEmailNotification}
        />
      </Modal.Content>
    </Modal>
  );
};

export default EmailEmployeesModal;
