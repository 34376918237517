import React, { FunctionComponent } from "react";
import { Table } from "semantic-ui-react";

import Content from "src/admin-portal/texts/content";
import { FlatAward } from "src/employee-portal/instrument-page/instruments-reducer";

export const SettlementTypeCell: FunctionComponent<{ award: FlatAward }> = ({
  award,
}) => (
  <Table.Cell>
    {award.settlementType === "equity" ? (
      <Content id={"instruments.settlementtype.equity.label"} />
    ) : (
      <Content id={"instruments.settlementtype.cash.label"} />
    )}
  </Table.Cell>
);
