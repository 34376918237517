import Jsona from "jsona";
import moment from "moment";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  fetchInvoices,
  fetchInvoicesFailed,
  fetchInvoicesSucceeded,
} from "src/admin-portal/invoices/invoices-actions";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { apiShortDate } from "src/common/utils/utils";
import * as selectors from "src/selectors";

const dataFormatter = new Jsona();

const getInvoicesRequestUrl = endDate => `/invoices_info?end_date=${endDate}`;

function* fetchInvoicesRequested() {
  try {
    const token = yield select(selectors.token);
    const endDate = moment().format(apiShortDate);

    const response = yield call(() =>
      callApi(getInvoicesRequestUrl(endDate), token)
    );

    const parsedResponse = response.data.map(info => ({
      ...info,
      quarters: info.quarters.map(quarter => ({
        ...quarter,
        contract: quarter.contract
          ? (dataFormatter.deserialize(quarter.contract) as Api.V1.Contract)
          : null,
      })),
    }));

    yield put(fetchInvoicesSucceeded(parsedResponse as Api.V1.InvoiceInfo[]));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: "USER_NOT_AUTHORIZED" });
    } else {
      Raven.captureException(e);
      yield put(fetchInvoicesFailed(e.message));
    }
  }
}

export function* watchFetchInvoices() {
  yield takeEvery(fetchInvoices.getType(), fetchInvoicesRequested);
}
