import Jsona from "jsona";
import Raven from "raven-js";
import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  deletePerformanceRule,
  deletePerformanceRuleSucceeded,
  fetchPerformanceRules,
  fetchPerformanceRulesSucceeded,
  patchPerformanceRule,
  patchPerformanceRuleSucceeded,
  patchTranchePerformanceRule,
  patchTranchePerformanceRuleSucceeded,
  postPerformanceRule,
  postPerformanceRuleSucceeded,
} from "src/admin-portal/performance-rules/performance-rules-page/performance-rules-actions";
import * as selectors from "src/admin-portal/tenant/tenant-selectors";
import { callApi, NOT_AUTHORIZED } from "src/common/api/api-helper";
import { batchRequests } from "src/common/sagas/batch-requests-saga";
import { USER_NOT_AUTHORIZED } from "src/reducers/user";

const dataFormatter = new Jsona();

const performanceRulesUrl = (tenantId: string) =>
  `/tenants/${tenantId}/performance-rules?include=performanceRuleEntries`;
const patchAndDeletePerformanceRuleUrl = (id: string) =>
  `/performance-rules/${id}`;
const patchTranchePerformanceRuleUrl = (id: string) =>
  `/performance-rules/${id}/tranche-performance-rules`;

function* fetchPerformanceRulesRequested() {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);

    const response = yield call(() =>
      callApi(performanceRulesUrl(tenantId), token)
    );

    yield put(
      fetchPerformanceRulesSucceeded({
        performanceRules: dataFormatter.deserialize(
          response
        ) as Api.V1.PerformanceRule[],
      })
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: USER_NOT_AUTHORIZED });
    } else if (e.errorMessage) {
      Raven.captureException(e);
    }
  }
}

export function* watchPerformanceRules() {
  yield takeEvery(
    fetchPerformanceRules.getType(),
    fetchPerformanceRulesRequested
  );
}

function* postPerformanceRuleRequested(
  action: ReturnType<typeof postPerformanceRule>
) {
  try {
    const token = yield select(selectors.token);
    const tenantId = yield select(selectors.tenantId);
    const method = "POST";
    const response = yield call(() =>
      callApi(
        performanceRulesUrl(tenantId),
        token,
        method,
        dataFormatter.serialize({
          stuff: { ...action.payload, type: "performanceRules" },
        })
      )
    );
    yield put(
      postPerformanceRuleSucceeded(
        dataFormatter.deserialize(response) as Api.V1.PerformanceRule
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: USER_NOT_AUTHORIZED });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPostPerformanceRule() {
  yield takeEvery(postPerformanceRule.getType(), postPerformanceRuleRequested);
}

const getTrimmedPatchPayload = payload => {
  const {
    links,
    relationshipNames,
    tenant,
    performanceRuleEntries,
    tranchePerformanceRules,
    meta,
    ...trimmedPayload
  } = payload;

  return trimmedPayload;
};

function* patchPerformanceRuleRequested(
  action: ReturnType<typeof patchPerformanceRule>
) {
  try {
    const token = yield select(selectors.token);
    const method = "PATCH";
    const response = yield call(() =>
      callApi(
        patchAndDeletePerformanceRuleUrl(action.payload.id),
        token,
        method,
        dataFormatter.serialize({
          stuff: getTrimmedPatchPayload(action.payload),
        })
      )
    );
    yield put(
      patchPerformanceRuleSucceeded(
        dataFormatter.deserialize(response) as Api.V1.PerformanceRule
      )
    );
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: USER_NOT_AUTHORIZED });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPatchPerformanceRule() {
  yield takeEvery(
    patchPerformanceRule.getType(),
    patchPerformanceRuleRequested
  );
}

function* deletePerformanceRuleRequested(
  action: ReturnType<typeof deletePerformanceRule>
) {
  try {
    const token = yield select(selectors.token);
    const method = "DELETE";
    yield call(() =>
      callApi(
        patchAndDeletePerformanceRuleUrl(action.payload),
        token,
        method,
        dataFormatter.serialize({
          stuff: { id: action.payload, type: "performanceRules" },
        })
      )
    );
    yield put(deletePerformanceRuleSucceeded(action.payload));
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: USER_NOT_AUTHORIZED });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchDeletePerformanceRule() {
  yield takeEvery(
    deletePerformanceRule.getType(),
    deletePerformanceRuleRequested
  );
}

function* patchTranchePerformanceRuleRequested(
  action: ReturnType<typeof patchTranchePerformanceRule>
) {
  try {
    const token = yield select(selectors.token);
    const method = "PATCH";

    yield batchRequests(
      15,
      2000,
      action.payload.tranches.map(tranche => () => {
        return callApi(
          `/tranches/${tranche.trancheId}/tranche-performance-rules`,
          token,
          method,
          {
            data: action.payload.tranches.map(obj => ({
              ...obj,
              performanceRuleId: action.payload.performanceRuleId,
            })),
          }
        );
      })
    );
    yield put(patchTranchePerformanceRuleSucceeded());
  } catch (e) {
    if (e.status === NOT_AUTHORIZED) {
      yield put({ type: USER_NOT_AUTHORIZED });
    } else {
      Raven.captureException(e);
    }
  }
}

export function* watchPatchTranchePerformanceRule() {
  yield takeEvery(
    patchTranchePerformanceRule.getType(),
    patchTranchePerformanceRuleRequested
  );
}
