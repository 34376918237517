import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";

import { ErrorModalClosable } from "src/common/error/error-modal-closable";
import { CONTACT_EMAIL } from "src/index";

interface ErrorFetchingWelcomeDataProps {
  pathname: string;
  refreshHandler: () => void;
}

const ErrorFetchingWelcomeData: FunctionComponent<ErrorFetchingWelcomeDataProps> = ({
  pathname,
  refreshHandler,
}) => (
  <ErrorModalClosable
    message={`An unknown error occured. Please try again. If the problem persists, please contact us at ${CONTACT_EMAIL} or by using the chat in the bottom right corner`}
    renderActions={() => (
      <div>
        {pathname !== "/" && (
          <Link to={"/"} className="ui primary button">
            <Icon name="home" /> Home
          </Link>
        )}
        <Button onClick={refreshHandler} color="blue">
          <Icon name="refresh" /> Try Again
        </Button>
      </div>
    )}
  />
);

export default ErrorFetchingWelcomeData;
