import React, { FunctionComponent } from "react";
import { Tab } from "semantic-ui-react";

import { Tenant } from "src/admin-portal/tenant/tenant-reducer";
import Content from "src/admin-portal/texts/content";
import { useFormatMessage } from "src/admin-portal/texts/utils";
import FAQList, { FAQItem } from "src/employee-portal/support/faq-list";

const findGroup = (formatMessage): FAQItem[][] => {
  const acc = [];
  let groupCounter = 1,
    qaCounter = 1;

  while (
    formatMessage({ id: `faq.group.${groupCounter}.title` }) !==
    `faq.group.${groupCounter}.title`
  ) {
    acc[groupCounter] = [];

    while (
      formatMessage({
        id: `faq.group.${groupCounter}.item.${qaCounter}.header`,
      }) !== `faq.group.${groupCounter}.item.${qaCounter}.header`
    ) {
      acc[groupCounter].push({
        questionKey: `faq.group.${groupCounter}.item.${qaCounter}.header`,
        answerKey: `faq.group.${groupCounter}.item.${qaCounter}.answer`,
      });
      qaCounter++;
    }

    groupCounter++;
    qaCounter = 1;
  }

  return acc.slice(1); // because acc[0] is empty - we don't have keys like "faq.group.0.title" to populate accumulator in index 0
};

const findNoGroupQuestions = (formatMessage): FAQItem[] => {
  const res = [];
  let counter = 1;

  while (
    formatMessage({ id: `faq.${counter}.header` }) !== `faq.${counter}.header`
  ) {
    res.push({
      questionKey: `faq.${counter}.header`,
      answerKey: `faq.${counter}.answer`,
    });
    counter++;
  }

  return res;
};

const FAQPage: FunctionComponent<{ tenant: Tenant }> = ({ tenant }) => {
  const formatMessage = useFormatMessage();
  const groups = findGroup(formatMessage);
  const noGroupQuestions = findNoGroupQuestions(formatMessage);

  return (
    <div className="faq-page-container block-xxl">
      <div className="space-vertical text-center">
        <h2 className="">
          <Content id="faq.title" />
        </h2>
        <div className="faq-page-ingress">
          <Content
            id="faq.content"
            values={{
              email: formatMessage({ id: "global.contactEmail" }),
              phone: formatMessage({ id: "global.contactPhone" }),
            }}
          />
        </div>
      </div>
      {tenant.feature.faq && groups.length === 0 && (
        <FAQList items={noGroupQuestions} />
      )}
      {tenant.feature.faq && groups.length > 0 && (
        <Tab
          menu={{ secondary: true, className: "row-center" }}
          panes={groups.map((group, i) => ({
            menuItem: {
              name: formatMessage({ id: `faq.group.${i + 1}.title` }),
              content: formatMessage({ id: `faq.group.${i + 1}.title` }),
              className: "flex-1-0-auto-forced space-around",
              color: "blue",
              key: i,
            },
            render: () => <FAQList items={group} />,
          }))}
        />
      )}
    </div>
  );
};

export default FAQPage;
