import { FormValues } from "src/employee-portal/order/table/UpdateModal/Form/useValidationSchema";

type UseFormValues = (order: Api.V1.Order) => FormValues;

const useFormValues: UseFormValues = order => {
  const {
    shareDepositoryAccount,
    shareDepositoryBank,
    shareDepositoryClearingCode,
    shareDepositoryContact,
    shareDepositoryDescription,
  } = order.exerciseOrder || order.purchaseOrder;

  const { shareDepositoryNorwegian } = order.purchaseOrder || {};

  const { bankAccount, bicSwiftNumber } = order.exerciseOrder || {};

  return {
    shareDepositoryAccount,
    shareDepositoryBank,
    shareDepositoryClearingCode,
    shareDepositoryContact,
    shareDepositoryDescription,
    shareDepositoryNorwegian,
    bankAccount,
    bicSwiftNumber,
  } as FormValues;
};

export default useFormValues;
