export const NO_VALUE = "-";

export const NORWEGIAN_DATE = "NORWEGIAN_DATE";
export const ENGLISH_DATE = "ENGLISH_DATE";

export enum TransactionType {
  TRANCHE = "TRANCHE",
  TRANCHE_MOBILITY = "TRANCHE_MOBILITY",
  GRANT = "GRANT",
  GRANT_MOBILITY = "GRANT-MOBILITY",
  TERMINATION = "TERMINATION",
  TERMINATION_MOBILITY = "TERMINATION-MOBILITY",
  ADJUSTMENT = "ADJUSTMENT",
  ADJUSTMENT_FAIR_VALUE = "ADJUSTMENT_FAIR_VALUE",
  ADJUSTMENT_FVSS = "ADJUSTMENT_FVSS", // Fair value for soc sec adjustments
  ADJUSTMENT_DIVIDEND = "ADJUSTMENT_DIVIDEND",
  EXERCISE = "EXERCISE",
  RELEASE = "RELEASE",
  EXPIRY = "EXPIRY",
  CANCEL = "CANCEL",
  ADJUSTMENT_STRIKE = "ADJUSTMENT_STRIKE",
  ADJUSTMENT_EXPIRY = "ADJUSTMENT_EXPIRY",
  ADJUSTMENT_GRANT = "ADJUSTMENT_GRANT",
  ADJUSTMENT_VESTING = "ADJUSTMENT_VESTING",
  ADJUSTMENT_REVERSED_SPLIT = "ADJUSTMENT_REVERSED_SPLIT",
  ADJUSTMENT_PERFORMANCE = "ADJUSTMENT_PERFORMANCE",
  ADJUSTMENT_FV_CASH = "ADJUSTMENT_FV_CASH",
  MODIFICATION_CASH_ACCOUNTING = "MODIFICATION_CASH_ACCOUNTING",
  MODIFICATION_STRIKE = "MODIFICATION_STRIKE",
}

export enum PurchaseType {
  PURCHASABLE_AWARD = "PURCHASABLE_AWARD",
  PURCHASE_OPPORTUNITY_CASH = "PURCHASE_OPPORTUNITY_CASH",
  PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY = "PURCHASE_OPPORTUNITY_INSTRUMENT_QUANTITY",
}

export const STANDARD_LONG_PRECISION = 4;

export const OPTIO_INCENTIVES_BEGINNING_OF_TIME = "1970-01-01";
export const OPTIO_INCENTIVES_END_OF_TIME = "4000-01-01";

export const DEBOUNCE_TIME_500_MS = 500;

export const MAXIMUM_PAGE_SIZE = 100000;
